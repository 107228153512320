import React from 'react';
import i18n from 'i18next';
import { useState } from 'react'
import ImageComponent from 'src/components/base/ControllerVer2/image/ImageComponent'
import { Button } from 'antd';

function ChangeLanguageComp({ t }) {
    const [lag, setLag] = useState(() => {
        i18n.changeLanguage(localStorage.getItem("lag") ?? 'vi');
        return localStorage.getItem("lag");
    });
    const changeLanguage = (lng) => {
        localStorage.setItem("lag", lng);
        i18n.changeLanguage(lng);
        setLag(lng);
    }

    return (
        <Button onClick={() => {
            if (lag == 'vi') {
                changeLanguage('en')

            } else {
                changeLanguage('vi')

            }
        }} className="border-button btn-custom mr-5 mt-1" icon={(<ImageComponent className="cursor-pointer language-button" url={lag == 'vi' ? "/assets/img/home/english.svg" : "/assets/img/home/vietnam.svg"} />)} />
    )
}

export default ChangeLanguageComp;