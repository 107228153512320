export const navbarTime = {
    language: "vn",
    navbartime: {
        navbar: {
            work_schedule_management: "Quản lý lịch làm việc",
            set_up_a_schedule: "Cài đặt lịch làm",
            working_shift: "Ca làm việc",
            type_of_working_day: "Loại ngày làm việc",
            group_of_working_days: "Nhóm ngày làm việc"
        }
    },
};