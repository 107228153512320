import _ from "lodash";

export const uniqueArray = (inputArray) => {
  return [...new Set(inputArray)];
};

export const createMapFromArray = (inputArray, key) => {
  const outputMap = new Map();

  if (!Array.isArray(inputArray)) {
    return outputMap;
  }

  inputArray.forEach((element) => {
    outputMap.set(element[key], element);
  });

  return outputMap;
}


export const getDifferentElements = (arr1, arr2) => {
  const differentElements = [];

  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      differentElements.push(arr1[i]);
    }
  }

  for (let j = 0; j < arr2.length; j++) {
    if (!arr1.includes(arr2[j])) {
      differentElements.push(arr2[j]);
    }
  }

  return differentElements;
}

export const getArrayFromInput = (input) => {
  if (_.isNil(input)) {
    return [];
  }

  if (Array.isArray(input)) {
    return input;
  }

  return [input]
}

export const duplicateElements = (array, times) => {
  return array.reduce((res, current) => {
      return res.concat(Array(times).fill(current));
  }, []);
}

export const isTwoArrayEqual = (arr1, arr2) => {
  return _.isEqual(arr1.sort(), arr2.sort())
}

export const getRandomElement = (arr) => {
  if (arr.length === 0) {
    return undefined;
  }

  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}