import * as type from "./enum/RepairTypeAction";
const RepairAction = {
    getDataPagingSaga: (ListSearchTerm) => {
        return {
            type: type.GET_DATA_REPAIR_PAGING_SAGA,
            ListSearchTerm: ListSearchTerm
        }
    },
    setPagingReducer: (data) => {
        return {
            type: type.SET_PAGING_REDUCER,
            data : data,
        }
    },
    getChangeStatusSaga: (data) => {
        return {
            type: type.GET_CHANGE_STATUS_REPAIR_SAGA,
            data:data,
        }
    },
    getDataRepairDetailSaga: (value,action,dataPage) => {
        return {
            type: type.GET_DATA_REPAIR_DETAIL_SAGA,
            value: value,
            action: action,
            dataPage: dataPage,
        }
    },
    setDataRepairDetailReducer: (data) => {
        return {
            type: type.SET_DETAIL_REPAIR_REDUCER,
            data:data,
        }
    },
    getDataByItemCodeSaga: (id,isAction) => {
        return {
            type: type.GET_DATA_BY_ITEM_CODE_SAGA,
            id: id,
            isAction:isAction,
        }
    },
    CallFalseDataByItemCodeReducer: () => {
        return {
            type: type.CALL_FALSE_DATA_BY_ITEM_CODE_REDUCER,
        }
    },
    setDataByItemCodeReducer: (data) => {
        return {
            type: type.SET_DATA_BY_ITEM_CODE_REDUCER,
            data: data,
        }
    },
    getDropdownStepRepairSaga: (data) => {
        return {
            type: type.GET_DROPDOWN_STEP_REPAIR_SAGA,
            data: data,
        }
    },
    setDropdownStepRepairReducer: (data) => {
        return {
            type: type.SET_DROPDOWN_STEP_REPAIR_REDUCER,
            data: data,
        }
    },
    createErrorRecordingSaga: (data,dataPage) => {
        return {
            type: type.CREATE_ERROR_RECORDING_SAGA,
            data: data,
            dataPage:dataPage,
        }
    },
    callResultFromReducer: () => {
        return {
            type:type.CALL_RESULT_FROM_REDUCER,
        }
    },
    callDefaultResultFromReducer: () => {
        return {
            type: type.CALL_DEFAULT_RESULT_FROM_REDUCER,
        }
    },
    createRepairActionSaga: (data, dataPage) => {
        return {
            type: type.CREATE_REPAIR_ACTION_SAGA,
            data: data,
            dataPage:dataPage,
        }
    },
    callResultActionFromReducer: () => {
        return {
            type:type.CALL_RESULT_ACTION_FROM_REDUCER,
        }
    },
    callDefaultResultActionFromReducer: () => {
        return {
            type: type.CALL_DEFAULT_RESULT_ACTION_FROM_REDUCER,
        }
    },
    clearDataByItemCodeReducer: () => {
        return {
            type: type.CLEAR_DATA_BY_ITEM_CODE_REPAIR_REDUCER,
        }
    },
    callDefaultResultReducer: () => {
        return {
            type: type.CALL_DEFAULT_RESULT_REDUCER,
        }
    }
}
export default RepairAction;