import {
    DELETE_INVENTORY_PLAN_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    GET_COST_CENTER_EV5_BY_SEGMENT_REDUCER,
    CALL_CREATE_SUCCESS_REDUCER,
    CALL_UPDATE_SUCCESS_REDUCER,
    GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_REDUCER
} from "./enum/TypeAssetmentStocktakingAction";

const stateDefault = {
    getCostCenterEV5BySegmentData: [],
    isGetCostCenterEV5BySegmentData: false,
    isDeleteSuccess: false,
    idCreateInventory: 0,
    isCreateSuccess: false,
    isUpdateSuccess: false,
    detailUpdateData: {},
    isGetdetailUpdateDataSuccess: false,
}
const AssetmentStocktakingReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case GET_COST_CENTER_EV5_BY_SEGMENT_REDUCER:
            if (action.data) {
                state.getCostCenterEV5BySegmentData = action.data;
                state.isGetCostCenterEV5BySegmentData = true;
            }
            return {
                ...state,
            };
        case DELETE_INVENTORY_PLAN_REDUCER:
            state.isDeleteSuccess = true;
            return {
                ...state,
            };
        case CALL_CREATE_SUCCESS_REDUCER:
            if (action.data) {
                state.idCreateInventory = action.data?.InventoryPlanId;
                state.isCreateSuccess = true;
            }
            return {
                ...state,
            };
        case CALL_UPDATE_SUCCESS_REDUCER:
            state.isUpdateSuccess = true;
            return {
                ...state,
            };
        case GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_REDUCER:
            if (action.data) {
                state.detailUpdateData = action.data;
                state.isGetdetailUpdateDataSuccess = true
            }
            return {
                ...state,
            };
        case CALL_DEFAULT_REDUCER:
            state.isDeleteSuccess = false;
            state.isGetCostCenterEV5BySegmentData = false;
            state.isCreateSuccess = false;
            state.isUpdateSuccess = false;
            state.isGetdetailUpdateDataSuccess = false;
            return {
                ...state,
            };
        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };

        default:
            return { ...state };
    }
};

export default AssetmentStocktakingReducer;
