export const GET_PAGING_SUPPLIER_CATEGORY_SAGA = "GET_PAGING_SUPPLIER_CATEGORY_SAGA";
export const GET_PAGING_SUPPLIER_CATEGORY_REDUCER = "GET_PAGING_SUPPLIER_CATEGORY_REDUCER";

export const DELETE_SUPPLIER_CATEGORY_SAGA = "DELETE_SUPPLIER_CATEGORY_SAGA";
export const DELETE_SUPPLIER_CATEGORY_REDUCER = "DELETE_SUPPLIER_CATEGORY_REDUCER";

export const CREATE_SUPPLIER_CATEGORY_SAGA = "CREATE_SUPPLIER_CATEGORY_SAGA";
export const UPDATE_SUPPLIER_CATEGORY_SAGA = "UPDATE_SUPPLIER_CATEGORY_SAGA";
export const CALL_CREATE_UPDATE_SUCCESS_REDUCER =
  "CALL_CREATE_UPDATE_SUCCESS_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
