export const WorkareaLinkProccessLag = {
    language: "en",
    wokarealinkproccess: {
        //index
        addworkunit: "Create new Work unit",
        titleenter: "Press 'Enter' to search",
        code: "Work center code",
        name: "Work center name",
        productcode: "Product code",
        productname: "Product name",
        process: "Process",
        version: "Version",
        productivity: "Productivity 1 hour",
        unit: "Unit",
        time: "Setting time (seconds)",
        action: "Action",
        //form
        ttsxselect: "Workcenter is not select",
        addsuccesss: "New link added successfully",
        deletesuccess: "Delete link successfully",
        fixsuccess: "Successful link fix",
        installation: "Installation time to work",
        seconds: "seconds",
        ttsxuat: "Work center",
        productionProccess: "Work process",
        product: "Product",
        day: " day",
        hour: " hour",
        week: " week",
        create: "Create new Workcenter & Process Link",
        update: "Update Workcenter & Process Link",
        details: "View details Workcenter & Process Link",
        linkedlist: "Linked list",
        productionunit: "Work unit",
        productionunitname: "Work unit name",
        stage: "Stage",
        execution: "Execution time (seconds)",
        save: "Save",
        linked: "Linked (",
        unlinked: "Unlinked",
        placeholder: {
            code: "Work center code",
            name: "Work center name",
            productcode: "Product code",
            productname: "Product name",
            process: "Process",
            version: "Version",
            productivity: "Productivity",
            unit: "Unit",
            time: "Setting time",
            step: "Step",
        },
        delete: {
            title: "Delete link",
            description: "Are you sure you want to delete this link?"
        },
        validate: {
            noComplete: "Workcenter link creation not completed - Process",
            productrequired: "Product cannot be left blank",
            processrequired: "Process cannot be left blank",
            ttsxrequired: "Workcenter cannot be left blank",
            yield: "Yield must be a positive integer",
            yield0: "Yield must be greater than 0",
            yieldrequired: "Productivity cannot be left blank",
            timeint: "The setting time must be a positive integer",
            time0: "Setting time must be greater than 0",
            timerequired: "The setting time cannot be left blank",
        }
    }
}