import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import CheckListAction from "./CheckListAction";
import i18next from 'i18next';
import { getDataPagingCheckListService, getDetailDataCheckListService, createCheckListService, updateCheckListService } from "./CheckListService";
import * as type from "./enum/CheckListTypeAction";
import toastJs from 'src/shared/common/toastJs';

export function* getDataPagingCheckListSaga(request) {
    try {
        const response = yield call(getDataPagingCheckListService, request.data)
        if (response.isSuccess) {
            yield put(CheckListAction.setDataPagingCheckListReducer(response.data))
        }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
}
export function* getDetailDataCheckListSaga(request) {
    try {
        const response = yield call(getDetailDataCheckListService, request.data)
        if (response.isSuccess) {
            yield put(CheckListAction.setDetailDataCheckListReducer(response.data))
        }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
}
export function* createCheckListSaga(request) {
    try {
        const result = yield call(createCheckListService, request.data)
        if (result.isSuccess) {
            yield put(CheckListAction.backToCheckListPage(true))
            toastJs.success(i18next.t("checklist.toast.create_sucess"));
        }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
}
export function* updateCheckListSaga(request) {
    try {
        const result = yield call(updateCheckListService, request.data)
        if (result.isSuccess) {
            yield put(CheckListAction.backToCheckListPage(true))
            toastJs.success(i18next.t("checklist.toast.update_sucess"));
        }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
}
export default function* CheckListSaga() {
    yield takeEvery(type.GET_DATA_PAGING_CHECKLIST_SAGA, getDataPagingCheckListSaga);
    yield takeEvery(type.GET_DETAIL_DATA_CHECKLIST_SAGA, getDetailDataCheckListSaga);
    yield takeEvery(type.CREATE_CHECKLIST_SAGA, createCheckListSaga);
    yield takeEvery(type.UPDATE_CHECKLIST_SAGA, updateCheckListSaga)
}