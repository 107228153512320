import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_PAGING_CONFIG_PLANNING_SAGA,
    CREATE_CONFIG_PLANNING_SAGA,
    UPDATE_CONFIG_PLANNING_SAGA,
    GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_SAGA
} from "./enum/TypeConfigPlanningAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import ConfigPlanningAction from "./ConfigPlanningAction";
import {
    getPagingService,createConfigPlanningService,updateConfigPlanningService,getDetailConfigPlanningByHospitalIdService
  } from "./ConfigPlanningService";
export function* getPagingSaga(data) {
    try {
        const pageSize = data.pageSize;
        const pageIndex = data.pageIndex;
        const searchTerm = data.searchTerm ? data.searchTerm : "";
        const request = {
            PageIndex: pageIndex,
            PageSize: pageSize,
            searchTerm: searchTerm,
        };
        const response = yield call(getPagingService, request);
        if (response) {
            if (response.Items.length <= 0 && request.PageIndex > 1) {
                yield put(
                    ConfigPlanningAction.getPagingSaga(
                        request.PageIndex - 1,
                        request.PageSize,
                        request.searchTerm
                    )
                );
            } else {
                yield put(ConfigPlanningAction.getPagingReducer(response));
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();
    }
}

export function* createConfigPlanningSaga(requet) {
    try {
      if (requet) {

        const response = yield call(createConfigPlanningService, requet?.data);
        if (response.isSuccess) {
          yield put(ConfigPlanningAction.createDataReducer());
        }
        closeLoading();
      }
    } catch (error) {
      closeLoading();
    }
  }

  export function* updateConfigPlanningSaga(requet) {
    try {
      if (requet) {

        const response = yield call(updateConfigPlanningService, requet?.data);
        if (response.isSuccess) {
          yield put(ConfigPlanningAction.updateDataReducer());
        }
        closeLoading();
      }
    } catch (error) {
      closeLoading();
    }
  }

  export function* getDetailConfigPlanningByHospitalIdSaga(request) {
    try {
      const response = yield call(getDetailConfigPlanningByHospitalIdService,request?.data);
      if (response.isSuccess) {
        yield put(ConfigPlanningAction.getDetailConfigPlanningByHospitalIdReducer(response.data));
      }
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  }

export default function* ConfigPlanningSaga(data) {
    yield takeEvery(GET_PAGING_CONFIG_PLANNING_SAGA, getPagingSaga);
    yield takeEvery(CREATE_CONFIG_PLANNING_SAGA, createConfigPlanningSaga);
    yield takeEvery(UPDATE_CONFIG_PLANNING_SAGA, updateConfigPlanningSaga);
    yield takeEvery(GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_SAGA, getDetailConfigPlanningByHospitalIdSaga);
}
