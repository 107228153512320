import * as type from "./enum/CheckListTypeAction";
const CheckListAction = {
    getDataPagingCheckListSaga: (data) => {
        return {
            type: type.GET_DATA_PAGING_CHECKLIST_SAGA,
            data: data,
        }
    },
    setDataPagingCheckListReducer: (data) => {
        return {
            type: type.SET_DATA_PAGING_CHECKLIST_REDUCER,
            data: data,
        }
    },
    getDetailDataCheckListSaga: (data) => {
        return {
            type: type.GET_DETAIL_DATA_CHECKLIST_SAGA,
            data: data,
        }
    },
    setDetailDataCheckListReducer: (data) => {
        return {
            type: type.SET_DETAIL_DATA_CHECKLIST_REDUCER,
            data: data,
        }
    },
    createCheckListSaga: (data) => {
        return {
            type: type.CREATE_CHECKLIST_SAGA,
            data: data,
        }
    },
    updateCheckListSaga: (data) => {
        return {
            type: type.UPDATE_CHECKLIST_SAGA,
            data: data,
        }
    },
    callResultFromApi: () => {
        return {
            type: type.CALL_RESULT_FROM_API,
        }
    },
    callDefaultResultFromApi: () => {
        return {
            type: type.CALL_DEFAULT_RESULT_FROM_API,
        }
    },
    clearDataReducer: () => {
        return {
            type: type.CLEAR_ALL_DATA_CHECKLIST_REDUCER,
        }
    },
    backToCheckListPage(status = false) {
        return {
            type: type.BACK_TO_CHECKLIST_PAGE,
            status
        }
    }
}
export default CheckListAction;