export const Standardpacking = {
    language: "vi",
    standardPacking: {
        search: "Tên sản phẩm, tên đối tác, loại package",
        table: {
            productName: "Tên sản phẩm",
            partner: "Đối tác",
            packageType: "Loại package",
            amount: "Số lượng",
            unit: "Đơn vị",
            height: "Chiều cao (cm)",
            length: "Chiều dài (cm)",
            width: "Chiều rộng (cm)",
            stacking: "Xếp chồng",
        },
        formSP: {
            stackingQuantity: "Số lượng xếp chồng",
        },
        deleteSP: {
            title: "Xóa tiêu chuẩn đóng gói",
            description: "Bạn có chắc chắn muốn xóa tiêu chuẩn đóng gói này?"
        },
        ruleForm: {
            goodsCode: "Tên sản phẩm không được để trống",
            partnerCode: "Thông tin đối tác không được để trống",
            packageType: "Loại package không được để trống",
            numberErr: "Sai định dạng số tự nhiên",
            qtyPackRequire: "Số lượng không được để trống",
            standardHeight: "Chiều cao không được để trống",
            standardLength: "Chiều dài không được để trống",
            standardWidth: "Chiều rộng không được để trống",
            stakingNumber: "Số lượng xếp chồng không được để trống",
            numberMin: "Số lượng phải lớn hơn 0",
            stakingNumberMin: "Số lượng xếp chồng phải lớn hơn 0",
            heightMin: "Chiều cao phải lớn hơn 0",
            lengthMin: "Chiều dài phải lớn hơn 0",
            widthMin: "Chiều rộng phải lớn hơn 0",
        },
        titleForm: {
            create: "Thêm tiêu chuẩn đóng gói",
            update: "Chỉnh sửa tiêu chuẩn đóng gói",
            view: "Thông tin tiêu chuẩn đóng gói"
        },
        placeholder: {
            productName: "Nhập tên sản phẩm",
            partner: "Nhập đối tác",
            packageType: "Nhập loại package",
            amount: "Nhập số lượng",
            unit: "Nhập đơn vị",
            height: "Nhập chiều cao (cm)",
            length: "Nhập chiều dài (cm)",
            width: "Nhập chiều rộng (cm)",
            stackingNumber: "Nhập số lượng xếp chồng"
        },
        toastJs: {
            createOK: "Thêm mới dữ liệu tiêu chuẩn đóng gói thành công",
            updateOk: "Cập nhật dữ liệu tiêu chuẩn đóng gói thành công",
            deleteOK: "Xóa dữ liệu tiêu chuẩn đóng gói thành công"
        }
    }
}