import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import UploadFileAction from "./UploadFileAction";
import { uploadfileService } from "./UploadFileService";
import * as type from "./enum/actionType";

export function* uploadfileSaga(request) {
    try {
        openLoading();
        const response = yield call(uploadfileService, request.files);
        if (response.isSuccess) {
            yield put(UploadFileAction.uploadFileReducer(response.files, request.controlNum));
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};
export default function* UploadFileSaga() {
    yield takeEvery(type.UPLOAD_FILE_SAGA, uploadfileSaga);
}