
// import { scheduleValidation } from 'src/components/page/MMProduction/LinkWorkCenterWithProcess/data/rule';
import {
    UPDATE_WORK_ORDER_REQUIREMENT,
    UPDATE_DROPDOWN_PRODUCTION_REQUIREMENT,
    UPDATE_DROPDOWN_DEFAULT_PRODUCTION_REQUIREMENT,
    UPDATE_WORK_ORDER_DATA,
    UPDATE_DROPDOWN_PRODUCT,
    UPDATE_DROPDOWN_WORKCENTER,
    CONFIRM_SAVE_DATA_SUCCESS,

    IS_DATA_CLEAR_PRO_REQUIREMENT_DONE,
    IS_DATA_CLEAR_WORK_ORDER_DONE,

    IS_CHECK_VALIDATE,
    IS_DROPDOWN_PRO_REQUIREMENT_UPDATE_DONE,

    IS_SCHEDULE_UPDATE,
    SCHEDULE_UPDATE_DONE,
    LET_SCHEDULE_UPDATE_WORK_ORDER,
    LET_SCHEDULE_UPDATE_WORK_ORDER_DONE,
    IS_SAVE_API,
    IS_SAVE_API_DONE,
    SET_PRODUCTION_REQUIRE_DETAIL_REDUCER,
    SET_LIST_WORKCENTER_REDUCER,
    INSERT_DATA_REDUCER,
    UPDATE_WORKORDER_TABLE_REDUCER,
    UPDATE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION,
    REFRESH_PRODUCT_REQUIRED_INFORMAITON_SELECT,
    DELETE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION,
    SELECTED_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION,
    CLEAR_LINK_PRODUCT_REQUIRED_INFORMATION,
    CREATE_LINK_PRODUCT_TO_PROCESS_SCHEDULE,
    UPDATE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem,
    DELETE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem,
    CLEAR_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem,
    UPDATE_SCHEDULE_REFIX_V2,
    HANDLE_DELETE_ROW_TABLE_ORDER_PRODUCE,
    HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_REDUCER
} from './enum/typeAction'
const revertState = {
    productRequirementList: [],
    isGetProductRequirement: false,
    isProductRequirementUpdate: false,

    defaultDropdownProductionReuirement: [],
    dropdownProductionReuirement: [],
    isDropDownSelectedPR: false,

    workorderData: [],
    isUpdateWorkOrder: false,
    productDropdown: [],
    workCenterDropdown: [],

    isDataSaveSuccess: false,
    isClearProRequired: false,
    isClearWorkOrder: false,
    isClearSchedule: false,

    isCheckValidate: false,

    isScheduleUpdate: false,
    scheduleData: {},

    letScheduleUpdate: false,

    isSaveDataApi: false,

    // reducer kiem soat state sau khi call api
    // state khi ma lay chi tiet yeu cau san xuat thanh cong
    isCallProRequireDetailSuccess: false,
    // chi tiet yeu cau
    proRequireDetail: {},
    // state check neu lay du liey dropdown center thanh cong hay khong
    isGetListWorkcenterSuccess: false,
    // dropdown workcenter 
    dropdownWorkcenter: [],
    indexRow: "",
    // trang thai true neu nhu insert data thanh cong
    isInsertDataSuccess: false,
    // update workorder data table
    dataTableWorkOrder: [],
    isDataTableWorkOrderUpdate: false,
    typeIndentifierLstProcessWC: "",

    //----------------------- udpate code by document------------------------
    isRefreshProductionRequirementInformation: false,
    // ----------------------- schedule data ---------------------------------
    scheduleDataV2: [],
    isScheduleUpdateV2: false,
    isClearScheduleData: false,
}
const stateDefault = {
    productRequirementList: [],
    isGetProductRequirement: false,
    isProductRequirementUpdate: false,

    defaultDropdownProductionReuirement: [],
    dropdownProductionReuirement: [],
    isDropDownSelectedPR: false,

    workorderData: [],
    isUpdateWorkOrder: false,
    productDropdown: [],
    workCenterDropdown: [],

    isDataSaveSuccess: false,
    isClearProRequired: false,
    isClearWorkOrder: false,
    isClearSchedule: false,

    isCheckValidate: false,

    isScheduleUpdate: false,
    scheduleData: {},

    letScheduleUpdate: false,

    isSaveDataApi: false,

    // reducer kiem soat state sau khi call api
    // state khi ma lay chi tiet yeu cau san xuat thanh cong
    isCallProRequireDetailSuccess: false,
    // chi tiet yeu cau
    proRequireDetail: {},
    // state check neu lay du liey dropdown center thanh cong hay khong
    isGetListWorkcenterSuccess: false,
    // dropdown workcenter 
    dropdownWorkcenter: [],
    indexRow: "",
    // trang thai true neu nhu insert data thanh cong
    isInsertDataSuccess: false,
    // update workorder data table
    dataTableWorkOrder: [],
    isDataTableWorkOrderUpdate: false,
    typeIndentifierLstProcessWC: "",

    //----------------------- udpate code by document------------------------
    isRefreshProductionRequirementInformation: false,

    // ----------------------- schedule data ---------------------------------
    scheduleDataV2: [],
    isScheduleUpdateV2: false,
    isClearScheduleData: false,

    scheduleDataRefix: {},
    isDataScheduleUpdateRefixV2: false,

    //---------------------------
    isDeleteWoTable: false,
}
const reducerLinkProductRequirement = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case UPDATE_WORK_ORDER_REQUIREMENT:
            state.isGetProductRequirement = true;
            state.productRequirementList.unshift(action.detail) // danh sách thông tin yêu cầu sản xuất
            state.isProductRequirementUpdate = true;
            return {
                ...state,
            };
        case UPDATE_DROPDOWN_PRODUCTION_REQUIREMENT:
            state.isDropDownSelectedPR = true;
            state.dropdownProductionReuirement.push(action.option)
            return {
                ...state,
            };
        case UPDATE_DROPDOWN_DEFAULT_PRODUCTION_REQUIREMENT:
            state.defaultDropdownProductionReuirement = action.dropdowns
            return {
                ...state,
            };
        case UPDATE_WORK_ORDER_DATA:
            state.workorderData = action.data;
            state.isUpdateWorkOrder = true;
            return {
                ...state,
            };
        case UPDATE_DROPDOWN_PRODUCT:
            state.productDropdown = action.data;
            return {
                ...state,
            };
        case UPDATE_DROPDOWN_WORKCENTER:
            state.workCenterDropdown = action.data;
            return {
                ...state,
            };
        case CONFIRM_SAVE_DATA_SUCCESS:
            state.isDataSaveSuccess = true;
            state.productRequirementList = [];
            state.defaultDropdownProductionReuirement = [];
            state.dropdownProductionReuirement = [];
            state.workorderData = [];
            state.isClearWorkOrder = true;
            state.isClearProRequired = true;

            return {
                ...state,
            };
        case IS_DATA_CLEAR_PRO_REQUIREMENT_DONE:
            state.isClearProRequired = false;
            return {
                ...state,
            };
        case IS_DATA_CLEAR_WORK_ORDER_DONE:
            state.isClearWorkOrder = false;
            return {
                ...state,
            };
        case IS_CHECK_VALIDATE:
            state.isCheckValidate = true;
            return {
                ...state,
            };
        case IS_DROPDOWN_PRO_REQUIREMENT_UPDATE_DONE:
            state.isProductRequirementUpdate = false;
            return {
                ...state,
            };
        case IS_SCHEDULE_UPDATE:
            state.isScheduleUpdate = true;
            state.scheduleData = action.data;

            return {
                ...state,
            };
        case SCHEDULE_UPDATE_DONE:
            state.isScheduleUpdate = false;
            return {
                ...state,
            };
        case LET_SCHEDULE_UPDATE_WORK_ORDER:
            state.letScheduleUpdate = true;
            return {
                ...state,
            };
        case LET_SCHEDULE_UPDATE_WORK_ORDER_DONE:
            state.letScheduleUpdate = false;

            return {
                ...state,
            };
        case IS_SAVE_API:
            state.isSaveDataApi = true;

            return {
                ...state,
            };
        case IS_SAVE_API_DONE:
            state.isSaveDataApi = false;

            return {
                ...state,
            };
        case SET_PRODUCTION_REQUIRE_DETAIL_REDUCER:
            state.isCallProRequireDetailSuccess = true;
            state.proRequireDetail = action.data;
            return {
                ...state,
            }
        case SET_LIST_WORKCENTER_REDUCER:
            state.isGetListWorkcenterSuccess = true;
            state.dropdownWorkcenter = action.data;
            state.indexRow = action.index;
            if (action.typeIndentifer) {
                state.typeIndentifierLstProcessWC = action.typeIndentifer;
            }
            return {
                ...state,
            }

        case INSERT_DATA_REDUCER:
            state.isInsertDataSuccess = true;
            return {
                ...state,
            }
        case UPDATE_WORKORDER_TABLE_REDUCER:
            state.isDataTableWorkOrderUpdate = action.status;
            if (state.isDataTableWorkOrderUpdate) {
                state.dataTableWorkOrder = action.data
            };
            return {
                ...state,
            }
        case REFRESH_PRODUCT_REQUIRED_INFORMAITON_SELECT:
            state.isRefreshProductionRequirementInformation = action.status;
            return {
                ...state,
            }
        case UPDATE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION:
            let data = state.productRequirementList;
            state.productRequirementList = data;
            state.isGetProductRequirement = true;
            state.isProductRequirementUpdate = true;

            return {
                ...state,
            }
        case DELETE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION:
            state.productRequirementList.forEach(po => {
                if (po.Productionrequirementcode == action.woCode) {
                    po.Qtyproduced = parseInt(po.Qtyproduced) - parseInt(action.qty);
                }
            });
            state.isGetProductRequirement = true;
            state.isProductRequirementUpdate = true;
            return {
                ...state,
            }
        case SELECTED_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION:
            state.productRequirementList.forEach(po => {
                if (po.Productionrequirementcode == action.poCode) {
                    po.Qtyproduced = parseInt(po.Qtyproduced) + parseInt(action.qty);
                }
            });
            state.isGetProductRequirement = true;
            state.isProductRequirementUpdate = true;
            return {
                ...state,
            }
        case CLEAR_LINK_PRODUCT_REQUIRED_INFORMATION:
            state = revertState;
            state.dataTableWorkOrder = [];
            console.log(state)
            return {
                ...state,
            }

        case CREATE_LINK_PRODUCT_TO_PROCESS_SCHEDULE:
            state.isScheduleUpdateV2 = action.isCreate;
            if (state.isScheduleUpdateV2) {
                state.scheduleDataV2 = action.data;
            }
            return {
                ...state,
            }
        case UPDATE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem:
            state.isDataTableWorkOrderUpdate = true;
            let isAvailableToUpdateWorkcenter = false;
            state.dataTableWorkOrder.forEach(item => {
                if (action.dragItem.Workordercode == item.Workordercode) {
                    item.Esproductiontimefrom = action.dragItem.start;
                    item.Esproductiontimeto = action.dragItem.end;
                    if (action.dragItem.Productionrequirementcode == "" && action.dragItem.Workcenterid != null) {
                        isAvailableToUpdateWorkcenter = true;
                        item.Workcenterid = action.dragItem.Workcenterid;
                    }
                }
            })
            state.isScheduleUpdateV2 = true;
            state.scheduleDataV2.forEach(item => {
                if (action.dragItem.Workordercode == item.Workordercode) {
                    item.Esproductiontimefrom = action.dragItem.start;
                    item.Esproductiontimeto = action.dragItem.end;
                    if (isAvailableToUpdateWorkcenter) {
                        item.Workcenterid = action.dragItem.Workcenterid;
                    }
                }
            });
            return {
                ...state,
            }
        case DELETE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem:
            state.isScheduleUpdateV2 = true;
            state.scheduleDataV2.forEach((item, i) => {
                if (action.wo == item.Workordercode) {
                    state.scheduleDataV2.splice(i, 1);
                }
            });
            return {
                ...state,
            }
        case CLEAR_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem:
            state.isClearScheduleData = action.status;
            return {
                ...state,
            }

        case UPDATE_SCHEDULE_REFIX_V2:
            state.isDataScheduleUpdateRefixV2 = action.statusUpdate;
            if (action.statusUpdate && action.data !== 'undefined') {
                state.scheduleDataRefix = action.data;
            }
            return {
                ...state,
            }
        case HANDLE_DELETE_ROW_TABLE_ORDER_PRODUCE: {
            let productRequirementList = state.productRequirementList;
            let dataTableWorkOrderTemp = state.dataTableWorkOrder;
            if (action.code) {
                productRequirementList.splice(productRequirementList.findIndex(item => item.Productionrequirementcode === action.code), 1);
                if (dataTableWorkOrderTemp.length > 0) {
                    dataTableWorkOrderTemp.forEach(item => {
                        if (item.Productionrequirementcode === action.code) {
                            item.Productionrequirementcode = null;
                        }
                    })
                }
                state.productRequirementList = productRequirementList;
                state.isGetProductRequirement = true;
                state.isProductRequirementUpdate = true;
            }
            return {
                ...state,
            }
        }
        case "XoaYeuCauSanXuat": {
            state.isDeleteWoTable = action.status;
            return {
                ...state
            }
        }
        case HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_REDUCER: {
            let apiDataRequirement = action.data;
            apiDataRequirement.forEach(item => {
                item.value = item.Productionrequirementcode;
                item.label = item.Productionrequirementcode;
            })
            if (apiDataRequirement.length > 0) {
                const arr = apiDataRequirement.filter(i => !state.productRequirementList.includes(i.Productionrequirementcode));
                if (arr.length > 0) {
                    state.productRequirementList = arr;
                }
            } else {
                state.productRequirementList = [];
            }
            state.isProductRequirementUpdate = true;
            state.isGetProductRequirement = true;
            // 
            // if (state.proRequireDetail.length == 0) {
            //     if (apiDataRequirement.length > 0) {
            //         state.productRequirementList = apiDataRequirement // danh sách thông tin yêu cầu sản xuất
            //     }
            //     
            //     state.isProductRequirementUpdate = true;
            //     state.isGetProductRequirement = true;
            // } else {
            // }
            return {
                ...state,
            }
        }

        default:
            state.isGetProductRequirement = false;
            state.isDropDownSelectedPR = false;
            state.isDataSaveSuccess = false;
            state.isUpdateWorkOrder = false;
            state.isCheckValidate = false;
            state.isCallProRequireDetailSuccess = false;
            state.isGetListWorkcenterSuccess = false;
            state.isInsertDataSuccess = false;

            return { ...state }
    }
}

export default reducerLinkProductRequirement
