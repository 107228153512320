export const reprintStampsLag = {
    language: "vi",
    reprintStamps: {
        packTitle: "In lại tem",
        stampsInfo: {
            name: "Thông tin đóng gói",
            code: "Mã thành phẩm:",
            element: "Thành phần:",
            packingLevel: "Cấp độ đóng gói:",
            table: {
                tableName: "Danh sách đóng gói",
                code:"Mã định danh",
                unit:"Đơn vị",
            }
        },
        btn: {
            search: "Truy vấn",
            sprint:"In tem lại",
        },
        placeHolder: {
            search:"Nhập / scan mã định danh",
        },
    },
}