const { SET_UPLOAD_FILE, CLEAR_UPLOAD_FILE_REDUCER } = require("./enum/TypeAction")

const stateDefault = {
  dataUpload: []
}

const UploadFileReducer = (
  state = stateDefault,
  action
) => {
  switch (action.type) {
    case SET_UPLOAD_FILE:
      if (action.dataUpload) {
        state.dataUpload = action.dataUpload;
      }
      return {
        ...state,
      };

    case CLEAR_UPLOAD_FILE_REDUCER:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default UploadFileReducer;
