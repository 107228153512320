export const GET_ALL_MASTER_DATA_BY_GROUP_CODE_API =
  "/api/service/Suggestion/GetAllMasterDataByGroupCode?groupCode=";
export const GET_APPROVAL_FLOW_TEMPLATE_API =
  "/api/service/Suggestion/GetApprovalFlowTemplate";
export const GET_LIST_USER_API = "/api/service/Suggestion/GetUsers";
//export const GET_LIST_ROLE_API = "/api/service/Suggestion/GetRoles";
export const GET_APPROVAL_FLOW_TEMPLATE_BY_ID_API =
  "/api/service/Suggestion/GetApprovalFlowTemplateById?approvalFlowTemplateId=";

//list dropdown asset code information
export const GET_LIST_FOCAL_DEPARTMENT_G5_API =
  "/api/service/Suggestion/GetAllEvaluationGroup5";
export const GET_LIST_BP_THE_LEDGER_API =
  "/api/service/Suggestion/GetConstCenter";
export const GET_LIST_ASSET_CLASS_API =
  "/api/service/Suggestion/GetAssetClass";
export const GET_LIST_ASSET_API = "/api/service/Suggestion/GetAsset";
export const GET_TRANSFER_ASSET_API =
  "/api/service/Suggestion/GetTransferAsset";
export const GET_LIST_SEGMENT_FUND_CENTER_API =
  "/api/service/Suggestion/GetAllSegmentFundCenter";
export const GET_LIST_STANDARD_ASSET_NAME_API =
  "/api/service/Suggestion/GetAssetStandard";
export const GET_LIST_BUDGET_CI_API =
  "/api/service/Suggestion/GetAllCICommitmentEvaluationGroup4";
export const GET_LIST_EVALUATION_GROUP_1_API =
  "/api/service/Suggestion/GetAllEvaluationGroup1";
export const GET_LIST_EVALUATION_GROUP_2_API =
  "/api/service/Suggestion/GetAllEvaluationGroup2";
export const GET_LIST_EVALUATION_GROUP_3_API =
  "/api/service/Suggestion/GetAllEvaluationGroup3";
export const GET_LIST_EVALUATION_GROUP_4_API =
  "/api/service/Suggestion/GetAllEvaluationGroup4";
export const GET_LIST_EVALUATION_GROUP_5_API =
  "/api/service/Suggestion/GetAllEvaluationGroup5";
export const GET_ALL_FUND_CENTER_API =
  "/api/service/Suggestion/GetAllFundCenter";
export const GET_GROUP_BPC_API = "/api/service/Suggestion/GetGroupBPC";
export const GET_LIST_VENDOR_API = "/api/service/Suggestion/GetVendor";
export const GET_LIST_ASSET_NUMBER_SUPPER_API =
  "/api/service/Suggestion/GetAllAssetSuperNumber";
export const GET_LIST_ALL_SEGMENT_API =
  "/api/service/Suggestion/GetAllSegment";

export const GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_API =
  "/api/service/Suggestion/GetAllCostCenterBySegmentFundCenter";
export const GET_LIST_COST_CENTER_BY_SEGMENT_API =
  "/api/service/Suggestion/GetAllCostCenterBySegment";
export const GET_LIST_INVENTORY_CODE_API =
  "/api/service/Suggestion/GetCodeInventory";

export const GET_USER_SEGMENT_API =
  "/api/service/RequestTransferAsset/GetUserSegment";

export const GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_API =
  "/api/service/Suggestion/GetAllEvaluationGroup5BySegment?segmentId=";
export const GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_API =
  "/api/service/Suggestion/GetAllSegmentRequestCreateAsset";
export const GET_ALL_ASSET_SOURCE_API =
  "/api/service/Suggestion/GetAllAssetSource";

export const GET_ALL_SEGMENT_INVENTORY_PLAN_API =
  "/api/service/Suggestion/GetAllSegmentInventoryPlan";

export const GET_USER_MONITOR1_API =
  "/api/service/Suggestion/GetUserMonitor1";
