import { call, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import {
    GET_LIST_REPORT,
    GET_BILL_INFO,
    GET_REPORT_INFO,
    GET_ASSET_INFO,
    GET_RESULT_REPORT,
    GET_SUMMARY_REPORT,
    EXPORT_DATA_INVENTORY_REPORT_BILL,
    EXPORT_DATA_INVENTORY_REPORT_INFO,
    EXPORT_DATA_INVENTORY_REPORT_ASSETMENT,
} from "./enum/ReportActionEnum";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import AssetmentStocktakingReportAction from "./ReportAction";
import {
    getListPlanReport,
    getInventoryPlanReportById,
    getAssetInventoryGroupByAssetClass,
    getReportInventoryAsset,
    getResultReportInventoryAsset,
    searchReportInventoryAsset,
    exportDataInventoryReportBillService,
    exportDataInventoryReportInfoService,
    exportDataInventoryReportAssetmentService,
    getSummaryAssetReport
} from "./ReportService";
import dayjs from "dayjs";
import toastJs from "src/shared/common/toastJs";
import { omit } from "lodash";

export function* getListReportSaga(data) {
    try {
        const request = {
            PageIndex: data.PageIndex,
            PageSize: data.PageSize,
            SearchTerm: data.SearchTerm
        }
        const result = yield call(getListPlanReport, request);
        if (result) {
            yield put(AssetmentStocktakingReportAction.setReport(result))
            closeLoading()
        }

    } catch (error) {
        closeLoading();
    }
}

export function* getBillInfoSaga(data) {
    openLoading();
    try {
        const result = yield call(getInventoryPlanReportById, data?.inventoryPlanId);
        if (result) {
            yield put(AssetmentStocktakingReportAction.setBillInfo(result));
            closeLoading()
        }
    }
    catch (error) {
        closeLoading()
    }
}

export function* getReportInfoSaga(data) {
    openLoading();
    try {
        const result = yield call(getAssetInventoryGroupByAssetClass, data?.inventoryPlanId);
        if (result) {
            yield put(AssetmentStocktakingReportAction.setReportInfo(result));
            closeLoading()
        }
    }
    catch (error) {
        closeLoading()
    }
}

export function* getAssetInfoSaga(data) {
    openLoading();
    try {
        const result = yield call(getReportInventoryAsset, data);
        if (result) {
            yield put(AssetmentStocktakingReportAction.setAssetInfo(result));
            closeLoading()
        }
    }
    catch (error) {
        closeLoading()
    }
}

export function* getResultReportDataSaga(data) {
    openLoading()
    try {
        const result = yield call(searchReportInventoryAsset, data);
        if (result) {
            yield put(AssetmentStocktakingReportAction.setResultReport(result));
            closeLoading()
        }
    }
    catch (error) {
        console.warn(error);
        closeLoading()
    }
}

function* getSummaryReportSaga(data) {
    openLoading()
    try {
        const result = yield call(getSummaryAssetReport, data?.data);
        if (result) {
            closeLoading()
            yield put(AssetmentStocktakingReportAction.setSummaryReport(result))
        }
    }
    catch (error) {
        closeLoading()
        console.warn(error)
    }
}

export function* exportDataInventoryReportBill(data) {
    try {
        const inventoryPlanId = parseInt(data?.inventoryPlanId);
        if (isNaN(inventoryPlanId)) {
            return;
        }

        let result = yield call(exportDataInventoryReportBillService, inventoryPlanId);

        if (!result?.isSuccess) {
            return;
        }

        let url = window.URL
            .createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = `thong-tin-phieu-kiem-ke_${data?.inventoryCode}.xlsx`;

        link.click();
        toastJs.success("Xuất báo cáo thành công")
        if (url) {
            url = URL.revokeObjectURL(url);
        }
        closeLoading()
    } catch (error) {
        closeLoading()
    }
}
export function* exportDataInventoryReportInfo(data) {
    try {
        const inventoryPlanId = parseInt(data?.inventoryPlanId);
        if (isNaN(inventoryPlanId)) {
            return;
        }

        let result = yield call(exportDataInventoryReportInfoService, inventoryPlanId);

        if (!result?.isSuccess) {
            return;
        }

        let url = window.URL
            .createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = `thong-tin-bao-cao-kiem-ke_${data?.inventoryCode}.xlsx`;

        link.click();
        toastJs.success("Xuất báo cáo thành công")
        if (url) {
            url = URL.revokeObjectURL(url);
        }
        closeLoading()
    } catch (error) {
        closeLoading()
    }
}
export function* exportDataInventoryReportAssetment(data) {
    try {
        let result = yield call(exportDataInventoryReportAssetmentService, data);

        if (!result?.isSuccess) {
            return;
        }

        let url = window.URL
            .createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = `thong-tin-tai-san-kiem-ke_${data?.inventoryCode}.xlsx`;
        link.click();
        toastJs.success("Xuất báo cáo thành công")
        if (url) {
            url = URL.revokeObjectURL(url);
        }
        closeLoading()
    } catch (error) {
        closeLoading()
    }
}
export default function* AssetmentStocktakingReportSaga(data) {
    yield takeEvery(GET_LIST_REPORT, getListReportSaga);
    yield takeEvery(GET_BILL_INFO, getBillInfoSaga);
    yield takeEvery(GET_REPORT_INFO, getReportInfoSaga);
    yield takeEvery(GET_ASSET_INFO, getAssetInfoSaga);
    yield takeEvery(GET_RESULT_REPORT, getResultReportDataSaga);
    yield takeEvery(GET_SUMMARY_REPORT, getSummaryReportSaga)
    yield takeEvery(EXPORT_DATA_INVENTORY_REPORT_BILL, exportDataInventoryReportBill)
    yield takeEvery(EXPORT_DATA_INVENTORY_REPORT_INFO, exportDataInventoryReportInfo)
    yield takeEvery(EXPORT_DATA_INVENTORY_REPORT_ASSETMENT, exportDataInventoryReportAssetment)

}