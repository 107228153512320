import {
  GET_PAGING_PRODUCT_REDUCER,
  GET_PAGING_MATERIAL_BY_ROLE_USER_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_PRODUCT_REDUCER,
  CALL_CREATE_UPDATE_SUCCESS_REDUCER,
  GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_REDUCER,
  GET_LIST_PRODUCT_REDUCER
} from "./enum/TypeCatProductAction";

const stateDefault = {
  listData: [],
  listMaterialByRoleUser: [],
  isGetLstData: false,
  isGetLstMaterialByRoleUser: false,
  isDeleteSuccess: false,
  isCreateUpdateSuccess: false,
  listDataProduct: [],
};

const CatProductReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_PRODUCT_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case GET_PAGING_MATERIAL_BY_ROLE_USER_REDUCER:
      if (action.data) {
        state.listMaterialByRoleUser = action.data;
        state.isGetLstMaterialByRoleUser = true;
      }
      return { ...state };
    case GET_LIST_PRODUCT_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_REDUCER:
      state.listDataProduct = action.data?.data;
      return { ...state };
    case DELETE_PRODUCT_REDUCER:
      state.isDeleteSuccess = true;
      return { ...state };

    case CALL_CREATE_UPDATE_SUCCESS_REDUCER:
      state.isCreateUpdateSuccess = true;
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.listDataProduct = [];
      state.listMaterialByRoleUser = [];
      state.isGetLstData = false;
      state.isGetLstMaterialByRoleUser = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      return {
        ...state,
      };
    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };

    default:
      return { ...state };
  }
};

export default CatProductReducer;
