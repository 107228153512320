import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  CREATE_CHECKPOINT_API,
  DELETE_CHECKPOINT_API,
  GET_DATA_CHECK_POINT_LIST_API,
  GET_DETAIL_CHECKPOINT_API,
  UPDATE_CHECKPOINT_API
} from "./enum/apiEnum";

export async function getPagingService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_DATA_CHECK_POINT_LIST_API,
    data
  )
};

export async function deleteCheckpointService(checkpointCode) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + DELETE_CHECKPOINT_API + checkpointCode,
    ""
  )
};

export async function getDetailCheckpointService(checkpointCode) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_DETAIL_CHECKPOINT_API + checkpointCode,
    ""
  )
};
export async function createCheckpointService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_CHECKPOINT_API,
    data
  )
};
export async function updateCheckpointService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_CHECKPOINT_API,
    data
  )
};