import {
  GET_REPORT_TRANSACTION_SAGA,
  GET_REPORT_TRANSACTION_REDUCER,
  EXPORT_REPORT_TRANSACTION_SAGA,
  GET_REPORT_RECONCILE_SAGA,
  GET_REPORT_RECONCILE_REDUCER,
  EXPORT_REPORT_RECONCILE_SAGA,
  GET_REPORT_INVENTORY_SAGA,
  GET_REPORT_INVENTORY_REDUCER,
  EXPORT_REPORT_INVENTORY_SAGA,
  CLEAR_DATA_REDUCER,
  CALL_DEFAULT_REDUCER,

} from "./enum/TypeReportAction";


const ReportAction = {
  getReportTransactionSaga: (
    pageIndex,
    pageSize,
    hospitalId,
    warehouseId,
    productGroupId = [],
    pID,
    listProductId=[],
    billCode,
    startDateExport,
    endDateExport,
    movementTypeOH = [],
    searchTerm = ""
  ) => {
    return {
      type: GET_REPORT_TRANSACTION_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseId: warehouseId,
      productGroupId: productGroupId,
      pID: pID,
      listProductId: listProductId,
      movementTypeOH: movementTypeOH,
      billCode: billCode,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      searchTerm: searchTerm
    };
  },
  getReportTransactionReducer: (data) => {
    return {
      type: GET_REPORT_TRANSACTION_REDUCER,
      data: data,
    };
  },
 
  exportReportTransactionSaga: (
    pageIndex,
    pageSize,
    hospitalId,
    warehouseId,
    productGroupId = [],
    pID,
    listProductId = [],
    billCode,
    startDateExport,
    endDateExport,
    movementTypeOH = [],
    searchTerm = ''
  ) => {
    return {
      type: EXPORT_REPORT_TRANSACTION_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseId: warehouseId,
      productGroupId: productGroupId,
      pID: pID,
      listProductId: listProductId,
      billCode: billCode,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      movementTypeOH: movementTypeOH,
      searchTerm:searchTerm
    };
  },
  getReportReconcileSaga: (
    pageIndex,
    pageSize,
    hospitalId,
    warehouseId,
    productGroupId = [],
    listProductId= [],
    movementTypeOH = [],
    startDateExport,
    endDateExport,
    searchTerm
  ) => {
    return {
      type: GET_REPORT_RECONCILE_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseId: warehouseId,
      productGroupId: productGroupId,
      listProductId: listProductId,
      movementTypeOH: movementTypeOH,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      searchTerm:searchTerm
    };
  },
  getReportReconcileReducer: (data) => {
    return {
      type: GET_REPORT_RECONCILE_REDUCER,
      data: data,
    };
  },
  
  exportReportReconcileSaga: (
    pageIndex,
    pageSize,
    hospitalId,
    warehouseId,
    productGroupId = [],
    listProductId= [],
    movementTypeOH = [],
    startDateExport,
    endDateExport,
    searchTerm = ""
  ) => {
    return {
      type: EXPORT_REPORT_RECONCILE_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseId: warehouseId,
      productGroupId: productGroupId,
      listProductId: listProductId,
      movementTypeOH: movementTypeOH,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      searchTerm: searchTerm
    };
  },
  getReportInventorySaga: (
    pageIndex,
    pageSize,
    hospitalId,
    warehouseId,
    productGroupId = [],
    listProductId,
    startDateExport,
    endDateExport,
    searchTerm = ''

  ) => {
    return {
      type: GET_REPORT_INVENTORY_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseId: warehouseId,
      productGroupId: productGroupId,
      listProductId: listProductId,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      searchTerm:searchTerm,
    };
  },
  getReportInventoryReducer: (data) => {
    return {
      type: GET_REPORT_INVENTORY_REDUCER,
      data: data,
    };
  },
  
  exportReportInventorySaga: (
    pageIndex,
    pageSize,
    hospitalId,
    warehouseId,
    productGroupId = [],
    listProductId,
    startDateExport,
    endDateExport,
  ) => {
    return {
      type: EXPORT_REPORT_INVENTORY_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseId: warehouseId,
      productGroupId: productGroupId,
      listProductId: listProductId,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
    };
  },
 
  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },

}

export default ReportAction;
