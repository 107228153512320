// *** dữ liệu phân quyền không được chỉnh sửa ***

import { lazy } from "react";

// *** dữ liệu phân quyền không được chỉnh sửa ***
export const listPageHiddenOnMenu = [
    //danh sach phe duyet
    {
        path: '/danh-sach-phe-duyet/xu-ly-phe-duyet/:id',
        comPath: "src/components/page/Administration/ApprovalsList/ApprovalFlow",
        permissionRequired: "danhsachpheduyet/xemchitiet"
    },
    // yeu cau tao ma tai san
    
    //danh sach tai san
    {
        path: '/danh-sach-tai-san/chi-tiet/:id',
        comPath: "src/components/page/AssetManagement/AssetmentPortfolio/AssetmentList/form",
        permissionRequired: "danhsachtaisan/chitiet"
    },
    {
        path: '/danh-sach-tai-san/chinh-sua/:id',
        comPath: "src/components/page/AssetManagement/AssetmentPortfolio/AssetmentList/form",
        permissionRequired: "danhsachtaisan/chinhsua"

    },
    // {
    //     path: '/chinh-sua-thong-tin-nhieu-tai-san',
    //     comPath: "src/components/page/AssetManagement/AssetmentPortfolio/AssetmentList/UpdateManyAssetment",
    //     permissionRequired: "chinhsuathongtinnhieutaisan"
    // },
    // dieu chuyen tai san
    
    // Kiem ke tai san
    

    // {
    //     path: "/tao-moi-phieu-kiem-ke",
    //     comPath: "src/components/page/AssetManagement/AssetmentStocktaking/Planning/CreateNewInvetorySheet",
    //     permissionRequired: "kiemketaisan/taomoiphieukiemke",
    // },
    // {
    //     path: "/sua-phieu-kiem-ke/:id",
    //     comPath: "src/components/page/AssetManagement/AssetmentStocktaking/Planning/CreateNewInvetorySheet",
    //     permissionRequired: "kiemketaisan/suaphieukiemke",
    // },
    {
        path: "/ke-hoach-kiem-ke/xem-chi-tiet/:id",
        comPath: "src/components/page/AssetManagement/AssetmentStocktaking/Planning/ViewInventorySession",
        permissionRequired: "kiemketaisan/xemphieukiemke",
    },
    
    // cau hinh quy trinh phe duyet
    {
        path: '/cau-hinh-quy-trinh-phe-duyet/tao-moi',
        comPath: "src/components/page/Administration/ApprovalSetting/ApprovalConfig/form",
        permissionRequired: "cauhinhquytrinhpheduyet/taomoi"
    },
    {
        path: '/cau-hinh-quy-trinh-phe-duyet/chinh-sua/:id',
        comPath: "src/components/page/Administration/ApprovalSetting/ApprovalConfig/form",
        permissionRequired: "cauhinhquytrinhpheduyet/chinhsua"
    },
    // {
    //     path: '/huong-dan-su-dung',
    //     comPath: "src/components/page/Administration/UsersManual/index.js",
    //     permissionRequired: "kiemketaisan/xemchitietthongtintaisan"
    // },
    // {
    //     path: '/kiem-ke/tao-moi',
    //     comPath: "src/components/page/AssetManagement/AssetmentStocktaking/PlanningList/index.js",
    //     permissionRequired: "kiemketaisan/taomoiphieukiemke"
    // },
    // {
    //     path: '/kiem-ke/chinh-sua/:id',
    //     comPath: "src/components/page/AssetManagement/AssetmentStocktaking/PlanningList/index.js",
    //     permissionRequired: "kiemketaisan/suaphieukiemke"
    // },
    // {
    //     path: '/test-table-child',
    //     comPath: "src/components/page/TestTableChild/index.js",
    //     permissionRequired: "kiemketaisan/xemchitietthongtintaisan"
    // },
    
    // SAP Err
    {
        path: '/intergrate-SAP-error',
        comPath: "src/components/page/Administration/IntergrateSAPError/index",
        permissionRequired: "SAPError/handleErrById"
    },
    {
        path: '/intergrate-SAP-error',
        comPath: "src/components/page/Administration/IntergrateSAPError/index",
        permissionRequired: "SAPError/handleAllErr"
    },

    //PIS system
    {
        path: '/phieu-kiem-ke/tao-moi',
        comPath: "src/components/page/InventoryManagement/InventoryTickets/CreateOrUpdateTicket",
        permissionRequired: "phieukiemke/taomoi"
    },
    {
        path: '/phieu-kiem-ke/chinh-sua/:id',
        comPath: "src/components/page/InventoryManagement/InventoryTickets/CreateOrUpdateTicket",
        permissionRequired: "phieukiemke/chinhsua"
    },
    {
        path: '/phieu-kiem-ke/xem-chi-tiet/:id',
        comPath: "src/components/page/InventoryManagement/InventoryTickets/CreateOrUpdateTicket",
        permissionRequired: "phieukiemke/xemchitiet"
    },

]
// *** dữ liệu phân quyền không được chỉnh sửa ***
