import { call, put, takeEvery } from 'redux-saga/effects';
import { closeLoading } from 'src/shared/common/LoadingAction';
import {
    GET_ITEMCODE_BY_SAGA, GET_PAGING_ITEM_ALLOCATION_SAGA, START_ALLOCATION_ITEMCODE_SAGA
} from "./enum/ItemAllocationTypeAction";
import ItemAlloctionAction from './ItemAllocationAction';
import { getItemByWO, getPagingService, startAllocationItemCode } from './ItemAllocationService';


export function* getPagingSaga() {
    try {
        const response = yield call(getPagingService);
        if (response.isSuccess) {
            yield put(ItemAlloctionAction.getPagingReducer(response.data))
        } else { }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
};

export function* getItemCodeBySaga(request) {
    try {
        const response = yield call(getItemByWO, request.data);
        if (response.isSuccess) {
            yield put(ItemAlloctionAction.getItemCodeByWoReducer(response.data.Data))
        } else { }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
};

export function* startAllocationItemCodeSaga(request) {
    try {
        const response = yield call(startAllocationItemCode, request.data);
        // if (response) {
        //     yield put(ItemAlloctionAction.getItemCodeByWoReducer(response.data))
        // } else { }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
};


export default function* ItemAllocationSaga() {
    yield takeEvery(GET_PAGING_ITEM_ALLOCATION_SAGA, getPagingSaga);
    yield takeEvery(GET_ITEMCODE_BY_SAGA, getItemCodeBySaga);
    yield takeEvery(START_ALLOCATION_ITEMCODE_SAGA, startAllocationItemCodeSaga);

}
