import {
    GET_ALL_MASTER_DATA_BY_GROUP_CODE_REDUCER,
    GET_APPROVAL_FLOW_TEMPLATE_REDUCER,
    CALL_DEFAULT_COMMON_DROPDOWN_VINMEC_REDUCER,
    CLEAR_DATA_DROPDOWN_VINMEC_REDUCER,
    GET_LIST_USER_REDUCER,
    //GET_LIST_ROLE_REDUCER,
    GET_APPROVAL_FLOW_TEMPLATE_BY_ID_REDUCER,

    GET_LIST_FOCAL_DEPARTMENT_G5_REDUCER,
    GET_LIST_BP_THE_LEDGER_REDUCER,
    GET_LIST_ASSET_CLASS_REDUCER,
    GET_LIST_SEGMENT_FUND_CENTER_REDUCER,
    GET_LIST_UNIT_REDUCER,
    GET_LIST_STANDARD_ASSET_NAME_REDUCER,
    GET_LIST_BUDGET_CI_REDUCER,
    GET_LIST_EVALUATION_GROUP_1_REDUCER,
    GET_LIST_EVALUATION_GROUP_2_REDUCER,
    GET_LIST_EVALUATION_GROUP_3_REDUCER,
    GET_LIST_EVALUATION_GROUP_4_REDUCER,
    GET_LIST_EVALUATION_GROUP_5_REDUCER,
    GET_ALL_FUND_CENTER_REDUCER,
    GET_GROUP_BPC_REDUCER,
    GET_LIST_VENDOR_REDUCER,
    GET_LIST_ASSET_NUMBER_SUPPER_REDUCER,
    GET_LIST_ALL_SEGMENT_REDUCER,
    GET_LIST_COST_CENTER_BY_SEGMENT_REDUCER,
    GET_LIST_INVENTORY_CODE_REDUCER,
    GET_USER_SEGMENT_REDUCER,
    GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_REDUCER,
    GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_REDUCER,
    GET_ALL_ASSET_SOURCE_REDUCER,
    GET_ALL_SEGMENT_INVENTORY_PLAN_REDUCER,
    GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_REDUCER

} from "./enum/TypeCommonDropdownVinmecAction";

const stateDefault = {
    listMasterDataByGroupCodeData: [],
    isGetListMasterDataByGroupCodeData: false,
    listGetApprovalFlowTemplateData: [],
    isGetApprovalFlowTemplateData: false,
    listUserData: [],
    isGetListUserData: false,
    // listRoleData: [],
    // isGetListRoleData: false,
    detailApprovalFlowTemplate: {},
    isGetDetailApprovalFlowTemplate: false,
    listFocalDepartmentG5: [],
    isGetListFocalDepartmentG5Data: false,
    listBPTheLedger: [],
    isListBPTheLedgerData: false,
    ListAssetClass: [],
    isListAssetClassData: false,
    ListSegentFundCenter: [],
    isListSegentFundCenterData: false,
    listUnit: [],
    isGetListUnitData: false,
    listStandardAssetName: [],
    isGetListStandardAssetNameData: false,
    listBudgetCI: [],
    isGetListBudgetCIData: false,
    listEvaluationGroup_1: [],
    isGetEvaluationGroup_1_Data: false,
    listEvaluationGroup_2: [],
    isGetEvaluationGroup_2_Data: false,
    listEvaluationGroup_3: [],
    isGetEvaluationGroup_3_Data: false,
    listEvaluationGroup_4: [],
    isGetEvaluationGroup_4_Data: false,
    listEvaluationGroup_5: [],
    isGetEvaluationGroup_5_Data: false,
    listAllFundCenter: [],
    isGetListAllFundCenter: false,
    listGroupBPC: [],
    isGetListGroupBPC: false,
    listVendor: [],
    isGetListVendor: false,
    listAssetSuperNumber: [],
    isGetListAssetSuperNumber: false,
    listAllsegment: [],
    isGetListAllsegment: false,
    isGetListCostCenterbySegment: false,
    listCostCenterbySegment: [],
    isGetListCostCenterbySegmentFundCenter: false,
    listCostCenterbySegmentFundCenter: [],
    listInventoryCode: [],
    isListInventoryCodeData: false,
    isGetUserSegment: false,
    segmentIdUser: null,
    listAllEvaluationGroup5BySegment: [],
    isGetListAllEvaluationGroup5BySegment: false,
    ListSegentFundCodeRequestCenter: [],
    isListSegentFundCodeRequestCenterData: false,
    listAllAssetSource: [],
    isGetListAllAssetSource: false,
    listAllSegmentInventoryPlan: [],
    isGetListAllSegmentInventoryPlan: false,
}
const CommonDropdownVinmecReducer = (
    state = stateDefault,
    action
) => {

    switch (action.type) {
        case GET_ALL_MASTER_DATA_BY_GROUP_CODE_REDUCER:
            if (action.data) {
                state.listMasterDataByGroupCodeData = action.data;
                state.isGetListMasterDataByGroupCodeData = true;
            }
            return { ...state };

        case GET_APPROVAL_FLOW_TEMPLATE_REDUCER:
            if (action.data) {
                state.listGetApprovalFlowTemplateData = action.data;
                state.isGetApprovalFlowTemplateData = true;
            }
            return { ...state };
        case GET_LIST_USER_REDUCER:
            if (action.data) {
                state.listUserData = action.data.data;
                state.isGetListUserData = true;
            }
            return { ...state };
        // case GET_LIST_ROLE_REDUCER:
        //     if (action.data) {
        //         state.listRoleData = action.data;
        //         state.isGetListRoleData = true;
        //     }
        //     return { ...state };
        case GET_APPROVAL_FLOW_TEMPLATE_BY_ID_REDUCER:

            if (action.data) {
                state.detailApprovalFlowTemplate = action.data;
                state.isGetDetailApprovalFlowTemplate = true;
            }
            return { ...state };
        case GET_LIST_FOCAL_DEPARTMENT_G5_REDUCER:
            if (action.data) {
                state.listFocalDepartmentG5 = action.data;
                state.isGetListFocalDepartmentG5Data = true;
            }
            return { ...state };
        case GET_LIST_BP_THE_LEDGER_REDUCER:
            if (action.data) {
                state.listBPTheLedger = action.data;
                state.isListBPTheLedgerData = true;
            }
            return { ...state };
        case GET_LIST_ASSET_CLASS_REDUCER:
            if (action.data) {
                state.ListAssetClass = action.data;
                state.isListAssetClassData = true;
            }
            return { ...state };
        case GET_LIST_SEGMENT_FUND_CENTER_REDUCER:
            if (action.data) {
                state.ListSegentFundCenter = action.data;
                state.isListSegentFundCenterData = true;
            }
            return { ...state };

        case GET_LIST_UNIT_REDUCER:
            if (action.data) {
                state.listUnit = action.data;
                state.isGetListUnitData = true;
            }
            return { ...state };
        case GET_LIST_STANDARD_ASSET_NAME_REDUCER:
            if (action.data) {
                state.listStandardAssetName = action.data;
                state.isGetListStandardAssetNameData = true;
            }
            return { ...state };
        case GET_LIST_BUDGET_CI_REDUCER:
            if (action.data) {
                state.listBudgetCI = action.data;
                state.isGetListBudgetCIData = true;
            }
            return { ...state };
        case GET_LIST_EVALUATION_GROUP_1_REDUCER:
            if (action.data) {
                state.listEvaluationGroup_1 = action.data;
                state.isGetEvaluationGroup_1_Data = true;
            }
            return { ...state };
        case GET_LIST_EVALUATION_GROUP_2_REDUCER:
            if (action.data) {
                state.listEvaluationGroup_2 = action.data;
                state.isGetEvaluationGroup_2_Data = true;
            }
            return { ...state };
        case GET_LIST_EVALUATION_GROUP_3_REDUCER:
            if (action.data) {
                state.listEvaluationGroup_3 = action.data;
                state.isGetEvaluationGroup_3_Data = true;
            }
            return { ...state };
        case GET_LIST_EVALUATION_GROUP_4_REDUCER:
            if (action.data) {
                state.listEvaluationGroup_4 = action.data;
                state.isGetEvaluationGroup_4_Data = true;
            }
            return { ...state };
        case GET_LIST_EVALUATION_GROUP_5_REDUCER:
            if (action.data) {
                state.listEvaluationGroup_5 = action.data;
                state.isGetEvaluationGroup_5_Data = true;
            }
            return { ...state };
        case GET_ALL_FUND_CENTER_REDUCER:
            if (action.data) {
                state.listAllFundCenter = action.data;
                state.isGetListAllFundCenter = true;
            }
            return { ...state };
        case GET_GROUP_BPC_REDUCER:
            if (action.data) {
                state.listGroupBPC = action.data;
                state.isGetListGroupBPC = true;
            }
            return { ...state };
        case GET_LIST_VENDOR_REDUCER:
            if (action.data) {
                state.listVendor = action.data;
                state.isGetListVendor = true;
            }
            return { ...state };

        case GET_LIST_ASSET_NUMBER_SUPPER_REDUCER:
            if (action.data) {
                state.listAssetSuperNumber = action.data;
                state.isGetListAssetSuperNumber = true;
            }
            return { ...state };
        case GET_LIST_ALL_SEGMENT_REDUCER:
            if (action.data) {
                state.listAllsegment = action.data;
                state.isGetListAllsegment = true;
            }
            return { ...state };

        case GET_LIST_COST_CENTER_BY_SEGMENT_REDUCER:
            if (action.data) {
                state.listCostCenterbySegment = action.data;
                state.isGetListCostCenterbySegment = true;
            }
            return { ...state };
        case GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_REDUCER:
            if (action.data) {
                state.listCostCenterbySegmentFundCenter = action.data;
                state.isGetListCostCenterbySegmentFundCenter = true;
            }
            return { ...state };

        case GET_LIST_INVENTORY_CODE_REDUCER:
            if (action.data) {
                state.listInventoryCode = action.data;
                state.isListInventoryCodeData = true;
            }
            return { ...state };

        case GET_USER_SEGMENT_REDUCER:
            if (action.data) {
                state.segmentIdUser = action.data.SegmentId;
                state.isGetUserSegment = true;
            }
            return { ...state };

        case GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_REDUCER:
            if (action.data) {
                state.listAllEvaluationGroup5BySegment = action.data;
                state.isGetListAllEvaluationGroup5BySegment = true;
            }
            return { ...state };

        case GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_REDUCER:
            if (action.data) {
                state.ListSegentFundCodeRequestCenter = action.data;
                state.isListSegentFundCodeRequestCenterData = true;
            }
            return { ...state };
        case GET_ALL_ASSET_SOURCE_REDUCER:
            if (action.data) {
                state.listAllAssetSource = action.data;
                state.isGetListAllAssetSource = true;
            }
            return { ...state };
        case GET_ALL_SEGMENT_INVENTORY_PLAN_REDUCER:
            if (action.data) {
                state.listAllSegmentInventoryPlan = action.data;
                state.isGetListAllSegmentInventoryPlan = true;
            }
            return { ...state };

        case CALL_DEFAULT_COMMON_DROPDOWN_VINMEC_REDUCER:
            state.isGetListMasterDataByGroupCodeData = false;
            state.isGetApprovalFlowTemplateData = false;
            state.isGetListUserData = false;
            //state.isGetListRoleData = false;
            state.isGetListFocalDepartmentG5Data = false;
            state.isListBPTheLedgerData = false;
            state.isListAssetClassData = false;
            state.isListSegentFundCenterData = false;
            state.isGetListUnitData = false;
            state.isGetListStandardAssetNameData = false;
            state.isGetListBudgetCIData = false;
            state.isGetEvaluationGroup_1_Data = false;
            state.isGetEvaluationGroup_2_Data = false;
            state.isGetEvaluationGroup_3_Data = false;
            state.isGetEvaluationGroup_4_Data = false;
            state.isGetEvaluationGroup_5_Data = false;
            state.isGetListFocalDepartmentG5Data = false;
            state.isListAssetClassData = false;
            state.isListSegentFundCenterData = false;
            state.isGetListAllFundCenter = false;
            state.isGetListGroupBPC = false;
            state.isGetListVendor = false;
            state.isGetListAssetSuperNumber = false;
            state.isGetListAllsegment = false;
            state.isGetListCostCenterbySegment = false;
            state.isGetListCostCenterbySegmentFundCenter = false;
            state.isListInventoryCodeData = false;
            state.isGetUserSegment = false;
            state.isGetListAllEvaluationGroup5BySegment = false;
            state.isListSegentFundCodeRequestCenterData = false;
            state.isGetListAllAssetSource = false;
            state.isGetListAllSegmentInventoryPlan = false;
            return {
                ...state,
            };
        case CLEAR_DATA_DROPDOWN_VINMEC_REDUCER:
            return {
                state: stateDefault,
            };
        default:
            return { ...state };
    }
};

export default CommonDropdownVinmecReducer;
