import { SET_CHECK_DUPLICATED_ERROR_REDUCER } from "./enum/CheckduplicatedAction";
const CommonCheckduplicatedReducer = (
    state = {
        errorDuplicated: {},
        checkDuplicatedSuccess: false,
    },
    action
) => {
    switch (action.type) {
        case SET_CHECK_DUPLICATED_ERROR_REDUCER:
            state.checkDuplicatedSuccess = true;
            state.errorDuplicated = action.data;
            return {
                ...state,
            };
        case "default":
            state.checkDuplicatedSuccess = false;
            return {
                ...state,
            };
        default:
            return {
                ...state,
            };
    }
};

export default CommonCheckduplicatedReducer;