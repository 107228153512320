import {
  APPROVE_REJECT_DONE,
  APPROVE_REJECT_REDUCER,
  GET_APPROVAL_DETAIL_REDUCER, GET_PAGING_APPROVAL_REDUCER, GET_WORKER_FROM_OF_WORKAREA_DONE, GET_WORKER_FROM_OF_WORKAREA_REDUCER, SET_STEP_BY_ACTION_APPROVE_DONE, SET_STEP_BY_ACTION_APPROVE_REDUCER
} from "./enum/ApprovalTypeAction";

const stateDefault = {
  isFetching: false,
  isFetchingMore: false,
  listData: [],
  approvalDetail: {},
  listApprovalStepGetMap: [],
  isCallApprovalDetail: false,


  // state approve reject api action
  Approvalid: "",
  TypeApproval: "",
  isApproveReject: false,
  // rowstep api get worker by workarea id
  workerDropdown: [],
  isGetWorker: false,
  idAction: "",
  // set step
  stepLevel: "",
  stepApproveType: "",
  isSetStepAction: false,
}

const ApprovalReducer = (
  state = stateDefault,
  action
) => {
  switch (action.type) {
    case 'default':
      state.isCallApprovalDetail = false;
      return {
        ...state,
      };
    case 'clearData':
      return {
        state: stateDefault,
      }

    // approval
    case GET_PAGING_APPROVAL_REDUCER:
      if (action.data) {
        state.listData = action.data;
      }
      return {
        ...state,
      };
    case GET_APPROVAL_DETAIL_REDUCER:
      if (action.data) {
        state.approvalDetail = action.data;
        state.listApprovalStepGetMap = action.data.ListApprovalStepGetMap;
        state.isCallApprovalDetail = true;
      }
      return {
        ...state,
      };
    case APPROVE_REJECT_REDUCER:
      state.Approvalid = action.Approvalid;
      state.TypeApproval = action.TypeApproval;
      state.isApproveReject = true;

      return {
        ...state,
      };
    case SET_STEP_BY_ACTION_APPROVE_REDUCER:
      state.stepLevel = action.stepLevel;
      state.stepApproveType = action.stepApproveType;
      state.isSetStepAction = true;
      return {
        ...state,
      };
    case SET_STEP_BY_ACTION_APPROVE_DONE:
      state.isSetStepAction = false;

      return {
        ...state,
      };
    case GET_WORKER_FROM_OF_WORKAREA_REDUCER:
      state.workerDropdown = action.data;
      state.isGetWorker = true;
      state.idAction = action.id;

      return {
        ...state,
      };
    case GET_WORKER_FROM_OF_WORKAREA_DONE:
      state.isGetWorker = false;
      return {
        ...state,
      };
    case APPROVE_REJECT_DONE:
      state.isApproveReject = false;

      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ApprovalReducer;
