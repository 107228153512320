import * as type from "./enum/ProductPackagingTypeAction";
const ProductPackagingAction = {
    getDropdownProductPackagingSaga: () => {
        return {
            type: type.GET_DROPDOWN_PRODUCT_PACKAGING_SAGA,
        }
    },
    setDropdownProductPackaging: (data) => {
        return {
            type: type.SET_DROPDOWN_PRODUCT_PACKAGING_REDUCER,
            data: data,
        }
    },
    getDropdownPackingLevelSaga: (request) => {
        return {
            type: type.GET_DROPDOWN_PACKING_LEVEL_SAGA,
            request: request,
        }
    },
    setDropdownPackingLevelReducer: (data) => {
        return {
            type: type.SET_DROPDOWN_PACKING_LEVEL_REDUCER,
            data: data,
        }
    },
    getPackageInfoSaga: (request) => {
        return {
            type: type.GET_PACKAGE_INFO_SAGA,
            request: request,
        }
    },
    getPackageDetail: (request) => {
        return {
            type: type.GET_PACKAGE_DETAIL_SAGA,
            request: request,
        }
    },
    setPackageDetailReducer: (data) => {
        return {
            type: type.SET_PACKAGE_DETAIL_REDUCER,
            data: data,
        }
    },
    getBelongInfomationSaga: (beLongCode, level) => {
        return {
            type: type.GET_BELONG_INFO_SAGA,
            beLongCode: beLongCode,
            level: level,
        }
    },
    setBelongInfoReducer: (data) => {
        return {
            type: type.SET_BELONG_INFO_REDUCER,
            data: data,
        }
    },
    deletePackageSaga: (request) => {
        return {
            type: type.DELETE_PACKAGE_SAGA,
            request: request,
        }
    },
    isCallSuccessFromApi: () => {
        return {
            type: type.CALL_SUCCESS_FROM_API_REDUCER,
        }
    },
    callResultFromApiReducer: () => {
        return {
            type:type.CALL_RESULT_FROM_API_REDUCER,
        }
    },
    callFalseFromApi: () => {
        return {
            type: type.CALL_FALSE_FROM_API_REDUCER,
        }
    },
    isCallDefaultSuccess: () => {
        return {
            type: type.CALL_DEFAULT_SUCCES_REDUCER,
        }
    },
    clearDataReducer: () => {
        return {
            type: type.CLER_DATA_REDUCER,
        }
    },
    callDefault: () => {
        return {
            type: type.CALL_DEFAULT_PRODUCT_PACKAGING_REDUCER,
        }
    }
}
export default ProductPackagingAction;