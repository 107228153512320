export const TakenPackageTicketVI = {
    language: "vi",
    takenPackageTicket: {
        search: "Code, khu vực, người lấy hàng, trạng thái, phiếu yêu cầu",
        searchDetail: "Sloc lấy hàng, mã hàng hóa",
        table: {
            area: "Chuyển hàng tới khu vực",
            worker: "Người lấy hàng",
            status: "Trạng thái",
            requestForm: "Phiếu yêu cầu",
        },
        form: {
            petitioner: "Người yêu cầu",
            reason: "Lý do chuyển",
        },
        formDetail: {
            ticket: "Phiếu lấy hàng",
            location: "Vị trí lấy hàng",
            sloc: "Sloc lấy hàng",
            productCode: "Mã hàng hóa",
            quantity: "Số lượng cần lấy",
            quantityTake: "Số lượng đã lấy",
            unit: "Đơn vị",
            status: "Trạng thái"
        },
        titleForm: {
            create: "Thêm phiếu lấy hàng",
            view: "Chi tiết phiếu lấy hàng"
        },
        delete: {
            title: "Xóa phiếu lấy hàng",
            description: "Bạn có chắc chắn muốn xóa phiếu lấy hàng này?"
        },
        confirmCreate: {
            title: "Xác nhận tạo phiếu lấy hàng",
            description: "Bạn có chắc chắn muốn tạo phiếu lấy hàng này?",
            none: "Chưa có phiếu nào được chọn"
        },
        toastJs: {
            createOK: "Tạo phiếu lấy hàng thành công",
            deleteOK: "Xóa thành công phiếu lấy hàng",
        }
    }
}