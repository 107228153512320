export const workCenterLag = {
    language: "en",
    workCenter: {
        workTitle: "Work Center",
        table: {
            code: "Work center code",
            name: "Work center name",
            SlocworkcenterCode: "Work center store code",
            nameWorker: "Manager",
            type: "Type",
            status: "Active",
            note: "Note",
            affiliatedArea: "Work area directly",
            action: "Action",
            dropdownStatus: {
                all: "All",
                active: "Active",
                nonActive: "Non Active",
            },
            noneDataTable: "No data to show",
        },
        search: {
            placeHolder: {
                code: "Work center code",
                name: "Work center name",
                SlocworkcenterCode: "Work center store code",
                nameWorker: "Manager",
                type: "Tyoe",
                note: "Note",
                affiliatedArea: "Work area directly",
            },
        },
        form: {
            add_new: "Create new work center",
            update: "Update work center",
            see_detail: "Work center information",
            code: "Work center code",
            name: "Work center name",
            nameWorker: "Manager",
            affiliatedArea: "Work area directly",
            type: "Type",
            note: "Note",
            status: "Active",
            listProduct: {
                listProductName: "List product",
                code: "Product code",
                name: "Product name",
                processName: "Process name",
                productivity: "Productivity",
                time: "Setup time (hours)",
                noneDataTable: "No data to show"
            },
        },
        validate: {
            name: {
                required: "Work center name cannot be empty",
                maxLength: "Work center name should not exceed 100 characters",
            },
            code: {
                type: "Work center code cannot contains special characters or spaces",
                text: "Work center code is up to 9 characters long UPPER, only numbers and letters, no spaces",
                required: "Work center code cannot be empty",
                maxLength: "Work center code should not exceed 9 characters",
            },
            worker: {
                required: "Manager cannot be empty",
            },
            workerarea: {
                required: "Work area cannot be empty",
            },
            type: {
                required: "Type cannot be empty",
            },
            note: {
                maxLength: "Work center node should not exceed 255 characters",
                required: "Note cannot be empty",
            },
        },
        delete: {
            title: "Delete work center",
            description: "Are you sure you want to delete this work center?",
        },
        btn: {
            add_new: "Create new",
            close: "Close",
            cancel: "Cancel",
            add: "Add",
            update: "Update"
        },
        toastjs: {
            createSuccess: "Create Work Center successfully",
            editSuccess: "Edit Work Center successfully",
            deleteSuccess: "Delete Work Center successfully",
            codeRepeat: "Work center code has been duplicated, can't get this Work center code",
            codeCharacters: "Work center code maximum length 9 characters UPPER, only numbers and letters, no spaces"
        },
        confirm: {
            createSuccess: "Successfully created new work center",
            updateSuccess: "Work center update successful",
            deleteSuccess: "Successfully deleted work center",
            //comfirmText: "Version đang được sử dụng, không được xóa",
            comfirmTextDeleted: "The work center already has linked data, which cannot be deleted",
        }
    },
};