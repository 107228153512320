export const GET_PAGING_LOTQA_SAGA = "GET_PAGING_LOTQA_SAGA";
export const GET_PAGING_LOTQA_REDUCER = "GET_PAGING_LOTQA_REDUCER";
export const CREATE_LOTQA_SAGA = "CREATE_LOTQA_SAGA";
export const CALL_RESULT_LOTQA_REDUCER = "CALL_RESULT_LOTQA_REDUCER";
export const UPDATE_LOTQA_SAGA = "UPDATE_LOTQA_SAGA";
export const GET_DETAIL_LOTQA_SAGA = "GET_DETAIL_LOTQA_SAGA";
export const GET_DETAIL_LOTQA_REDUCER = "GET_DETAIL_LOTQA_REDUCER";
export const CALL_DEFAULT_LOTQA_REDUCER = "CALL_DEFAULT_LOTQA_REDUCER";
export const SET_DROPDOWN_WO_REDUCER = "SET_DROPDOWN_WO_REDUCER";
export const GET_DROPDOWN_WO_SAGA = "GET_DROPDOWN_WO_SAGA";
export const CALL_CHANGE_DATA = "CALL_CHANGE_DATA";
export const GET_CODE_FROM_API_SAGA = "GET_CODE_FROM_API";
export const GET_CODE_FROM_API_REDUCER = "GET_CODE_FROM_API_REDUCER";
export const GET_LIST_ITEM_CODE_SAGA = "GET_LIST_ITEM_CODE_SAGA";
export const GET_LIST_ITEM_CODE_REDUCER = "GET_LIST_ITEM_CODE_REDUCER";

