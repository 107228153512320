import { Select, Space, Tooltip } from "antd"
import { Fragment, memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { baseListData } from "src/shared/constants/enum/constants";
import { bindActionCreators } from "redux";
import CatWarehouseAction from "src/redux-saga-v2/MedicalSuppliesManagement/CatWarehouse/CatWarehouseAction";
import { openLoading } from "src/shared/common/LoadingAction";
import { withTranslation } from "react-i18next";
import { hasPermission, listActionEnum } from "src/shared/common/permistionUtils";
import StringUtils from "src/shared/common/StringUtils";
import { InformationUser } from "src/shared/common/informationUser";
const MultipleWarehouseSelect = memo((props) => {
  const [listCBBDataAsset, setListCBBDataAsset] = useState([]);
  const [hasPermissionGetData, setHasPermissionGetData] = useState(false);
  const  handleOnChange = (value) => {
    props.onChange(value);
  };

  useEffect(() => {
    //Nếu user hiện tại có quyền xem danh sách kho thì mới được gọi
    let checkPer = hasPermission(props?.listActionAccessible, listActionEnum.danhmuckho.listbyhospital)
    setHasPermissionGetData(checkPer);

    if (checkPer) {
      openLoading();
      const request = {
        listHospitalId: props?.listHospitalId, 
        isGetAll: props?.isGetAll 
      };
      props.CatWarehouseAction.getListWarehouseByListHospitalIdSaga(request);
    }
    else if (props?.dataUserHospitalWarehouse) {
      //Nếu không có quyền thì chỉ lấy kho hiện tại thôi
      let tempArr = [
        {
          Id: InformationUser.getInfoUserByKey("WarehouseId"),
          Name: InformationUser.getInfoUserByKey("WarehouseName") ,
        }
      ];
      setListCBBDataAsset(tempArr)
    }
  }, []);

  //Nếu mã bệnh viện thay đổi, và có quyền lấy danh sách kho thì cần lấy lại danh sách kho
  useEffect(() => {
    if(props?.listHospitalId != null && props?.listHospitalId?.length > 0 && hasPermissionGetData)
    {
      openLoading();
      const request = {
        listHospitalId: props?.listHospitalId, 
        isGetAll: props?.isGetAll
      }
      props.CatWarehouseAction.getListWarehouseByListHospitalIdSaga(request);
    }
    else if((props?.listHospitalId != null || props?.listHospitalId?.length == 0)  && hasPermissionGetData && !props?.notResetList)
    {
      setListCBBDataAsset([]);
    }
  },[props?.listHospitalId])
  //Gọi api lấy danh sách kho theo bệnh viện xong, thì cập nhật list combobox
  useEffect(() => {
    if (props?.listWarehouse?.length > 0) {
      let lst = props?.listWarehouse.map((item) => {
        return { ...item, key: item.Id };
      });      
      setListCBBDataAsset(lst);
    }
  },[props?.listWarehouse])
  return (
    <Select
      mode="multiple"
      name="StockCode1"
      size={props?.size != null ? props?.size :  "large"}
      maxTagCount={1}
      // maxTagPlaceholder={(omittedValues) => (
      //   <Tooltip
      //     overlayStyle={{
      //       pointerEvents: 'none',
      //     }}
      //     title={omittedValues.map((item, index) => (
      //       <Fragment key={index}>
      //          {/* <span aria-label={item.CodeOH}>{item.CodeOH}</span> -  */}
      //          <span aria-label={item.label}>{item.label}</span>
      //         {index < omittedValues.length - 1 && <br />}
      //       </Fragment>
      //     ))}
      //   >
      //     <span style={{ color: 'blue' }}>Xem thêm</span>
      //   </Tooltip>
      // )}
      className={props?.classCSS}

      // className="select-ams-multi-search"
      // mode="multiple"
      placeholder={props?.placeholder}
      listItemHeight={10}
      listHeight={150}
      allowClear
      showSearch
      optionFilterProp="label"
      disabled = {props?.isDisabled}
      value={props?.value}
      options={listCBBDataAsset?.map((item) => ({
        CodeOH: item.Code,
        value: item.Id,
        label: item.Name,
      }))}
      onChange={(value) => handleOnChange(value)}
      optionRender={(option) => (
        <Space>
          <span  aria-label={option.data.label}>
            {option.data.CodeOH}
          </span>
          <span  aria-label={option.data.label}>
            {option.data.label}
          </span>
        </Space>
      )}
      filterOption={(input, option) =>
        (StringUtils.removeAccents((option?.label).toLowerCase()) ?? '').includes(StringUtils.removeAccents(input.toLowerCase())) ||
        (StringUtils.removeAccents((option?.CodeOH).toLowerCase()) ?? '').includes(StringUtils.removeAccents(input.toLowerCase()))
      } //tìm kiếm theo label
    />
  )
})

const mapDispatchToProps = (dispatch) => ({
  CatWarehouseAction: bindActionCreators(CatWarehouseAction, dispatch),
});

const mapStateToProps = (state) => ({
  listWarehouse: state.CatWarehouseReducer.listDataWarehouseByListId,
  isGetLstCBBData: state.CatWarehouseReducer.isGetLstData,
  dataUserHospitalWarehouse:state.UserHospitalWarehouseReducer.dataUserHospitalWarehouse,
  listActionAccessible: state.PermissionReducer.listActionAccessible,

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MultipleWarehouseSelect));

