import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import { toast } from 'react-toastify';
import PackingLevelAction from "./PackingLevelAction";
import * as type from "./enum/PackingLevelTypeAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import { getPagingService, createDataService, getPackingLevelById, updateDataService, deletePackingService, changeLevelService } from "./PackingService";

export function* getPagingPackingLevelSaga() {
    try {

        const response = yield call(getPagingService);
        if (response?.isSuccess) {
            yield put(PackingLevelAction.setPagingReducer(response.data));
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* createPackingLevelSaga(data) {
    try {
        const result = yield call(createDataService, data.request);
        if (result.isSuccess) {
            toast.success(i18next.t("packingLevel.toast.createSuccess"), {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(PackingLevelAction.getPagingSaga());
            yield put(PackingLevelAction.callResultFromApiReducer());
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* updatePackingLevelSaga(data) {
    try {
        const result = yield call(updateDataService, data.request);
        if (result.isSuccess) {
            toast.success(i18next.t("packingLevel.toast.updateSuccess"), {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(PackingLevelAction.getPagingSaga());
            yield put(PackingLevelAction.callResultFromApiReducer());
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* deletePackingLevelSaga(data) {
    try {
        const result = yield call(deletePackingService, data.request);
        if (result.isSuccess) {
            toast.success(i18next.t("packingLevel.toast.deleteSuccess"), {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(PackingLevelAction.getPagingSaga());
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* changeLevelPackingLevel(request) {
    try {
        const result = yield call(changeLevelService, request.id, request.level);
        if (result.isSuccess) {
            toast.success(i18next.t("packingLevel.toast.changeLevelSuccess"), {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(PackingLevelAction.getPagingSaga());
            yield put(PackingLevelAction.callResultFromApiReducer());
        }
    } catch (error) {
        closeLoading();

    }
}
export function* getPackingLevelByIdSaga(request) {
    try {
        const response = yield call(getPackingLevelById, request.id)
        if (response?.isSuccess) {
            yield put(PackingLevelAction.setPackingLevelByIdReducer(response.data))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export default function* PackingLevelSaga() {
    yield takeEvery(type.GET_PAGING_PACKING_LEVEL_SAGA, getPagingPackingLevelSaga);
    yield takeEvery(type.CREATE_PACKING_LEVEL_SAGA, createPackingLevelSaga);
    yield takeEvery(type.UPDATE_PACKING_LEVEL_SAGA, updatePackingLevelSaga);
    yield takeEvery(type.DELETE_PACKING_LEVEL_SAGA, deletePackingLevelSaga);
    yield takeEvery(type.CHANGE_LEVEL_PACKING_LEVEL, changeLevelPackingLevel);
    yield takeEvery(type.GET_PACKING_LEVEL_BY_ID, getPackingLevelByIdSaga);
}
