import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { DROPDOWN_MMPRODUCTION, DROPDOWN_MATERIAL, DROPDOWN_MATAINER, GET_WORK_COMMON_DROPDOWN_BY_CODE_API } from "./enum/CommonDropdownApi";


export async function getDropDownMMProduction(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DROPDOWN_MMPRODUCTION,
    data
  );
}

export async function getDropDownMaterial(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DROPDOWN_MATERIAL,
    data
  );
}

export async function getDropDownMatainer(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DROPDOWN_MATAINER + data?.type,
    data
  );
}

export async function getCommonDropdownByCode(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_WORK_COMMON_DROPDOWN_BY_CODE_API,
    data
  );
}