import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import loginAction from "../../../../redux-saga/action/Login/loginAction";
import NotificationAction from "src/redux-saga/action/Notification/NotificationAction";
import {
    generatePath, useHistory
} from "react-router-dom";
import { openLoading } from "src/shared/common/LoadingAction";
import ImageComponent from "src/components/base/ControllerVer2/image/ImageComponent";
import { Button, Dropdown, Menu, Space, Tooltip } from "antd";
const typeUpdateNotifile = {
    updateOnly: 1,
    updateAll: 2
}

const Notification = (props) => {
    const history = useHistory();
    const [dataNotification, setDataNotification] = useState([]);
    const [isShowNotification, setIsShowNotification] = useState(false);
    const [userInfo, setUserinfo] = useState();
    const handelClickNotification = () => {
        setIsShowNotification(!isShowNotification);
        var elems = document.getElementsByTagName('thead');
        var elems2 = document.getElementsByTagName('td');
        for (var i = 0; i < elems.length; i++) {
            if (!isShowNotification) {
                elems[i].style.position = "unset";
            } else {
                elems[i].style.position = "sticky";
            }
        }
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < elems2.length; i++) {
            if (!isShowNotification) {
                elems2[i].style.position = "unset";
            } else {
                elems2[i].style.position = "relative";
            }
        }
    }

    const clostMenuNotification = () => {
        var elems = document.querySelectorAll(".notification-menu");

        [].forEach.call(elems, function (el) {
            el.classList.remove("show");
        });
    }
    const update = (type, id) => {
        openLoading();
        if (type == typeUpdateNotifile.updateOnly) {
            id && history.push(generatePath("/thong-tin-phe-duyet/:id", { id }));
            props.notificationAction.updateNotification(id, userInfo);
        }
        else {
            props.notificationAction.updateNotification('all', userInfo);
        }
        clostMenuNotification();
    }
    useEffect(() => {
        if (props.redecersLogin?.listNotificaltion?.length > 0) {
            setDataNotification(props.redecersLogin.listNotificaltion);
            let userID = props.redecersLogin.userInfo.profile.sub;
            setUserinfo(userID);
        }
        // eslint-disable-next-line
    }, [props.redecersLogin.listNotificaltion])
    return (
        <div className="header-notification">
            <Dropdown
                dropdownRender={() => (
                    <div className="notification-menu">
                        <div className="notification">
                            <div className="noti-title">
                                <div className="left">
                                    <span>NOTIFICATIONS</span>
                                </div>

                                <div className="right" style={{ cursor: 'pointer' }}>
                                    <Button onClick={() => {
                                        update(typeUpdateNotifile.updateAll, 'all');
                                    }} className="blue-button btn-custom"> Mark all as read</Button>

                                </div>
                            </div>
                            {dataNotification && dataNotification.length > 0
                                ? dataNotification.map((item, index) => {
                                    return (
                                        <div key={index} style={{ cursor: 'pointer' }}>
                                            <Tooltip title={item.Type + item.Name} >
                                                <span className="item" onClick={() => {
                                                    if (item.status != "done") {
                                                        update(typeUpdateNotifile.updateOnly, item.EntityId)
                                                    } else {
                                                        let id = item.EntityId;
                                                        id && history.push(generatePath("/thong-tin-phe-duyet/:id", { id }));
                                                        clostMenuNotification();
                                                    }
                                                }}>
                                                    <div className={"avatar " + (item.status != "done" ? "unread" : "readed")} style={{ backgroundImage: 'url("./assets/img/home/avantar-notification.avif")', backgroundSize: 'cover' }} />
                                                    <span className={item.status != "done" ? "fw-bold text" : "text"}>{item.Type + " " + item.Name}</span>
                                                </span>
                                            </Tooltip>
                                            <hr className="mb-0 mt-0" />

                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>
                )}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <div className="mr-5" > 
                        <span className={dataNotification.filter(x => x.status != "done").length > 0 ? "something-new" : ""}>
                            <ImageComponent url="/assets/img/home/bell-notification1.svg" className="cursor-pointer bell me-2 " />
                        </span>
                    </div>
                </a>

            </Dropdown>

        </div>

    )
}
const mapDispatchToProps = (dispatch) => ({
    loginAction: bindActionCreators(loginAction, dispatch),
    notificationAction: bindActionCreators(NotificationAction, dispatch),
});

const mapStateToProps = (state) => {
    return {
        redecersLogin: {
            listNotificaltion: state.NotificaionReducer.listNotificaltion,
            userInfo: state.loginReducer.userInfo,
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(Notification)));