export const listDataFake = {
    "Items": [
        {
            "Code": "PH_001",
            "Name": "Quản lý dự trù 1",
            "Description": "Mô tả quản lý dự trù 1",
            "CreateAt": "2023-11-29T14:19:15.675699"
        },
        {
            "Code": "PH_002",
            "Name": "Quản lý dự trù 2",
            "Description": "Mô tả quản lý dự trù 2",
            "CreateAt": "2021-10-05T14:19:15.675699"
        },
    ],
    "TotalCount": 2,
    "PageIndex": 1,
    "PageSize": 2
};

export const detailBudget = {
    "Code": "PH_001",
    "Name": "Quản lý dự trù 1",
    "Description": "Mô tả quản lý dự trù 1",
    "CreateAt": "15/01/2024"
}