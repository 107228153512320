export const GET_PAGING_CAT_BARCODE_SAGA = "GETCAT_BARCODE_PAGING_CAT_BARCODE_SAGA";
export const GET_PAGING_CAT_BARCODE_REDUCER =
  "GET_PAGING_CAT_BARCODE_REDUCER";

export const DELETE_CAT_BARCODE_SAGA = "DELETE_CAT_BARCODE_SAGA";
export const DELETE_CAT_BARCODE_REDUCER = "DELETE_CAT_BARCODE_REDUCER";

export const CREATE_CAT_BARCODE_SAGA = "CREATE_CAT_BARCODE_SAGA";
export const CREATE_LIST_CAT_BARCODE_SAGA = "CREATE_LIST_CAT_BARCODE_SAGA";
export const UPDATE_CAT_BARCODE_SAGA = "UPDATE_CAT_BARCODE_SAGA";

export const CALL_CREATE_UPDATE_SUCCESS_REDUCER =
  "CALL_CREATE_UPDATE_SUCCESS_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";

export const GET_LIST_CAT_BARCODE_REDUCER = "GET_LIST_CAT_BARCODE_REDUCER";
export const GET_LIST_CAT_BARCODE_SAGA = "GET_LIST_CAT_BARCODE_SAGA";

export const DOWNLOAD_TEMPLATE_CAT_BARCODE_SAGA = "DOWNLOAD_TEMPLATE_CAT_BARCODE_SAGA";

export const GET_LIST_ALL_FIELD_CAT_BARCODE_REDUCER = "GET_LIST_ALL_FIELD_CAT_BARCODE_REDUCER";
export const GET_LIST_ALL_FIELD_CAT_BARCODE_SAGA = "GET_LIST_ALL_FIELD_CAT_BARCODE_SAGA";
