export const inventoryCheckLag = {
    language: "vi",
    inventorychecklag: {
        chooseplan: "Hãy chọn kế hoạch để bắt đầu hoặc kết thúc",
        reasonrequired: "Lý do không được để trống",
        unconfirmedex: "Chưa xác nhận tồn hoặc chưa xác nhận thông tin package",
        validationsuccess: "Xác nhận tồn thành công",
        packconfirmsuccess: "Xác nhận thông tin package thành công",
        enterlocation: "Nhập vị trí",
        enteritemcode: "Nhập mã hàng",
        enternameitem: "Nhập tên mã hàng",
        location: "Vị trí",
        PLU: "Mã hàng",
        goodsname: "Tên hàng hóa",
        numberbefor: "Số lượng trước kiểm kê",
        enternumberbefor: "Nhập số lượng trước kiểm kê",
        chcekqty: "Số lượng kiểm được",
        entercheckqty: "Nhập số lượng kiểm được",
        difference: "Chênh lệch",
        enterdifference: "Nhập chênh lệch",
        storedlocation: "Vị trí lưu trữ",
        enterstoredlocation: "Nhập vị trí lưu trữ",
        belongregion: "Trực thuộc khu vực",
        enterbelongregion: "Nhập trực thuộc khu vực",
        entergoodscode: "Nhập goodcode",
        entergoodsname: "Nhập tên hàng hóa",
        counter: "Người kiểm kê, kiểm đếm",
        entercounter: "Nhập người kiểm kê, kiểm đếm",
        tiem: "Thời gian",
        entertime: "Nhập thời gian",
        reason: "Lý do",
        enterreason: "Nhập lý do",
        enterpack: "Nhập package code",
        system: "Vị trí hệ thống",
        entersystem: "Nhập vị trí hệ thống",
        locationfond: "Vị trí tìm thấy",
        enterlocationfond: "Nhập vị trí tìm thấy",
        confirmseason: "Xác nhận lý do chung",
        missingitem: "Xác nhận 1 lý do chung cho toàn bộ hàng còn thiếu?",
        detailsinventory: "Chi tiết kiểm kê",
        endinventory: "Kết thúc kiểm kê",
        inforgeneral: "Thông tin chung",
        inforbysloc: "Thông tin tồn theo vị trí",
        inforpack: "Thông tin package",
        confirmex: "Xác nhận tồn",
        end: "Kết thúc",
        confirmpackinfor: "Xác nhận thông tin package",
        status: "Trạng thái",
        classify: "Phân loại",
        inventory: "Kiểm kê",
        tally: "Kiểm đếm",
        rank: "Cấp bậc thống kê",
        exlocation: "Vị trí thực hiện",
        person: "Người phụ trách",
        esexcutiontime: "Thời gian thực hiện dự kiến",
        realtime: "Thời gian thực hiện thực tế",
        purpose: "Mục đích",
        Statistical: "Thống kê",
    }
}
