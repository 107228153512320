import ColumnGroup from "antd/es/table/ColumnGroup";

export const base = {
    language: "vn",
    base: {
        default: "Mặc định",
        apply: "Áp dụng",
        filter: "Bộ lọc",
        btn: {
            create: 'Tạo mới',
            update: 'Cập nhật',
            close: 'Đóng',
            temporarySave: "Lưu tạm",
            save: "Lưu",
            add: "Thêm",
            agree: "Đồng ý",
            cancel: "Đóng",
            delete: "Xóa",
            back: "Quay lại",
            shortData: "Thu gọn",
            changeVersion: "Đổi version",
            choose: "Chọn",
            view: "Xem chi tiết",
            edit: "Sửa",
            complete: "Hoàn thành",
            print: "In",
            enforcement: "Nghiệm thu",
        },
        from: "Từ",
        to: "Đến",
        pagination: {
            display: 'Hiển thị',
            in: 'trong'
        },
        table: {
            action: 'Thao tác',
            nothingToDisplay: 'Không có bản ghi nào'
        },
        importfile: {
            maxsize: "Dung lượng file quá ",
            fileformat: "File không đúng định dạng",
        },
        insertData: "Nhập dữ liệu",
        select: "Chọn",
        sureDelete: "Bạn có chắc muốn xóa",
        cancel: "Hủy",
        ok: "Đồng ý",
        close: "Đóng",
        upload: "Tải file lên",
        deleteFileSuccess: "Xóa file thành công",
        length: "Dài",
        width: "Rộng",
        height: "Cao",
        uploadFile: {
            title: "Tải file",
            overFileAllow: " Không thể upload do quá số lượng file",
            fileTypeAllow: " Không hợp lệ để upload",
            overSizeAllow: " Dung lượng file không hợp lệ để upload"
        }
    },
    worker_skill: {
        placeholderskill: "Tìm kiếm theo kỹ năng",
        title_table: "Thông tin đào tạo kỹ năng",
        deletedegree: "Xoá bằng cấp",
        deletedegreequest: "Bạn có chắc chắn muốn xóa bằng cấp này không?",
        form: {
            create: "Thêm mới chuyên ngành - kỹ năng",
            update: "Cập nhật chuyên ngành - kỹ năng",
            form: {
                skill: "Chuyên ngành / kỹ năng",
                unit: "Đơn vị đào tạo",
                start: "Thời gian bắt đầu",
                end: "Thời gian kết thúc",
                rank: "Xếp loại",
                cost: "Chi phí",
                note: "Ghi chú",
                degree: "Bằng cấp",
                traningByGoverment: "Đào tạo nội bộ (do nhà máy tổ chức)",
            },
            placeHolder: {
                f1: "Chọn chuyên ngành",
                f2: "Nhập đơn vị đào tạo",
                f3: "Chọn thời gian bắt đầu",
                f4: "Chọn thời gian kết thúc",
                f5: "Nhập xếp lại",
                f6: "Nhập chi phí",
                f7: "Bấm vào biểu tưởng tải file bên phải để upload",
                f8: "Nhập ghi chú",
            }
        },
        table: {
            time: "Thời gian đào tạo",
            traningBy: "Đào tạo nội bộ",
            cost: "Chi phí đào tạo (VND)",
        },

    },
    requestTransfer: {
        search: "Tìm kiếm theo mã nguồn yêu cầu , sloc chuyển đến , mô tả  ,.... ",
        table: {
            code: "Mã nguồn yêu cầu",
            moveSloc: "Sloc chuyển đến",
            des: "Mô tả",
            active: "Đang hoạt động"
        },
        form: {
            codeexist: "Mã nguồn yêu cầu đã tồn tại",
            create: "Thêm mới nguồn yêu cầu",
            update: "Cập nhật nguồn yêu cầu",
            view: "Chi tiết nguồn yêu cầu",
            placeHolderSlocTo: "Chọn sloc chuyển đến"
        },
        delete: {
            title: "Xoá nguồn yêu cầu",
            confirmText: "Bạn chắc chắn muốn xóa nguồn yêu cầu này?"
        }
    },
    do: {
        code_exist: "Mã code này đã tồn tại",
        searchPH: "Mã phiếu giao hàng, mã đơn hàng, người giao hàng, tình trạng giao hàng",
        table: {
            Datedelivery: "Ngày giao hàng",
            Dateleavefactory: "Ngày xuất kho",
            Deliveryordercode: "Mã phiếu giao hàng",
            Purchaseordercode: "Mã đơn đặt hàng",
            Statusdeliveryname: "Tình trạng giao hàng",
            Userdeliveryname: "Người giao hàng",
            icon: {
                hasBeenDelivery: "Đơn giao hàng DO đã giao không thể sửa",
                cannotDelete: "Đơn giao hàng DO đã giao không thể xóa"
            }
        },
        form: {
            title: {
                create: "Tạo mới đơn giao hàng DO",
                update: "Cập nhật đơn giao hàng DO",
                view: "Thông tin chi tiết đơn giao hàng DO",
            },
            field: {
                code: "Tạo đơn giao hàng DO độc lập",
                poCode: "Mã đơn hàng PO",
                partnerCode: "Mã đối tác",
                transferCode: "Mã phiếu giao hàng",
                transferPerson: "Người giao hàng",
                dayExpo: "Ngày xuất kho",
                dayTransfer: "Ngày giao hàng",
                status: "Trạng thái",
            },
            table: {
                proCode: "Mã hàng hóa",
                desPro: "Mô tả hàng hóa",
                numberProTransfered: "Số lượng đã giao/ Số lượng đặt",
                transferedAmount: "Số lượng giao",
                unit: "Đơn vị",
                dayProduce: "Ngày sản xuất",
                dayExpire: "Ngày hết hạn",
            }
        },
        delete: {
            title: "Xác nhận xoá thông tin giao hàng",
            confirmText: "Bạn có chắc chắn muốn xóa thông tin giao hàng này?",
            form: {
                title: "Xóa mã hàng",
                confirmText: "Bạn có chắc chắn muốn xóa mã hàng hóa này?",
            }
        },
        btn: {
            addPack: "Thêm hàng",
        },
        placeHolder: {
            form: {
                po: "Chọn mã đơn hàng PO",
                partner: "Chọn mã đối tác",
                shiping: "Chọn mã phiếu giao hàng",
                shiper: "Chọn người giao hàng",
                dateExpo: "Nhập ngày xuất kho",
                shipDate: "Nhập ngày giao hàng",
                status: "Chọn trạng thái",
            },
            table: {
                good: {
                    code: "Nhập mã hàng hóa",
                    name: "Mô tả hàng hóa"
                },
                amount: {
                    hasExpo: "Nhập số lượng giao",
                    order: "Nhập số lượng đặt",
                    ship: "Nhập số lượng đặt"
                },
                unit: "Đơn vị",
                produceDate: "Nhập ngày sản xuất",
                dateExpire: "Nhập ngày hết hạn",
            }
        },
        toastJs: {
            createOK: "Tạo mới thông tin giao hàng thành công",
            updateOK: "Cập nhật thông tin giao hàng thành công",
            deleteOK: "Xóa thành công thông tin giao hàng",
            noDelete: "Phiếu giao hàng đã được nhận, không thể xóa"
        },
        rulesFormDO: {
            orderCodeErr: "Mã sai định dạng",
            orderCode: "Mã phiếu giao hàng không được bỏ trống",
            orderCodeLength: "Mã phiếu giao hàng không được quá 50 kí tự",
            partnerCode: "Mã đối tác không được bỏ trống",
            PO: "PO không được bỏ trống",
            dateLeave: "Ngày xuất kho không được bỏ trống",
            dateDelivery: "Ngày giao hàng không được bỏ trống",
            userDelivery: "Người giao hàng không được bỏ trống",
            statusDelivery: "Trạng thái không được bỏ trống",
        },
        rulesListData: {
            numberThan0: "Số lượng thực tế phải lớn hơn  0",
            qtyDelivery: "Số lượng giao không được bỏ trống",
            dateofManufacture: "Ngày sản xuất không được bỏ trống",
            qtyDeliveryed: "Số lượng đã giao không được bỏ trống",
            numberEqual0: "Số lượng thực tế phải lớn hơn hoặc bằng 0",
            expriedDate: "Ngày hết hạn không được bỏ trống",
            goodsCode: "Mã hàng hóa không được để trống",
            qtyOrder: "Số lượng đặt không được bỏ trống",
            qtyOder: "Số lượng đặt không được nhỏ hơn hoặc bằng 0",
            qtyDeliveryMin: "Số lượng giao không được nhỏ hơn 0"
        },
        ruleForm: {
            Datedelivery: "Ngày giao hàng không được diễn ra trước ngày xuất kho",
            Qtydelivery: "Số lượng giao không được lớn hơn số lượng đặt - số lượng đã giao",
            QtyDeliveried: "Số lượng đã giao không được lớn hơn số lượng đặt",
            Exprieddate: "Ngày hết hạn không được diễn ra trước ngày sản xuất",
            ExprieddateAffter: "Ngày hết hạn phải diễn ra sau ngày sản xuất",
            Dateofmanufacture: "Ngày sản xuất không được bỏ trống",
            ExprieddateErr: "Ngày hết hạn không được bỏ trống"
        }
    },
    receiveGoodFromNcss: {
        searchField: {
            placeHolder: "Nhập mã code",
        },
        btn: {
            receive: "Nhận",
            reset: "Khai báo lại",
            print: "In tem",
            storePackage: "Nhập kho",
            addPackage: "Thêm package"
        },
        infomationForm: {
            code: "Mã đơn hàng",
            supplier: "Nhà cung cấp",
            orderBy: "Người đặt hàng",
            orderDate: "Ngày đặt hàng",
            transferDate: "Ngày giao hàng dự kiến",
        },
        packingInfo: {
            title: "Thông tin đóng gói",
            table: {
                packageCode: "Package code",
                packingType: "Kiểu cách đóng gói",
                amount: "Số lượng",
                unit: "Đơn vị",
                dateExpire: "Ngày hết hạn",
                length: "Chiều dài",
                width: "Chiều rộng",
                height: "Chiều cao",
                stacked: "Được xếp chồng",
                amountStacked: "Số lượng được xếp chồng",
                order: "đặt",
                receive: "thực nhận",
                column: {
                    packingType: "Chọn mã package",
                    amount: "Nhập số lượng",
                    unit: "Nhập đơn vị",
                    dateExpire: "Nhập ngày hết hạn",
                    length: "Nhập chiều dài",
                    width: "Nhập chiều rộng",
                    height: "Nhập chiều cao",
                    amountStacked: "Nhập số ngăn sếp chồng",
                    print: "In",
                    reprint: "In lại"
                }
            }
        },
        detailInfoPackage: {
            title: "Thông tin chi tiết đơn hàng",
            table: {
                goodCode: "Mã hàng",
                goodName: "Tên hàng",
                receive: "nhận thực tế",
            }
        },
        popup: {
            printTem: {
                title: "Xác nhận in tem",
                content: "Bạn có chắc chắn muốn In tem cho lô hàng này?"
            },
            storeConfirm: {
                title: "Xác nhận nhập kho",
                content: "Bạn có chắc chắn muốn Nhập kho lô hàng này?"
            },
            reset: {
                title: "Xác nhận Khai báo lại",
                content: "Bạn có chắc chắn muốn khai báo lại dữ liệu?"
            },
            deletePackage: {
                title: "Xác nhận xóa package",
                content: "Bạn có chắc chắn muốn Xóa package này?"
            },
            printItemTem: {
                title: "Xác nhận In tem",
                content: "Bạn có chắc chắn muốn In tem cho package này?"
            },
            printItemTemBack: {
                title: "Xác nhận In lại tem",
                content: "Bạn có chắc chắn muốn In lại tem của package này?"
            }
        },
        error: {
            stackNotAvaible: "Số lượng xếp chồng không hợp lệ",
            stackNotOverZero: "Số lượng xếp chồng không được bé hơn 1",
            amountReciveIsOverRealityAmount: "Số lượng nhận đang lớn hơn số lượng thực tế",
            amountReciveIsOverPermissionAmount: "Số lượng nhận đang quá số lượng quy định",
            amountReciveMustBiggerThanZero: "Số lượng nhận phải lớn hơn 0",
            amountReciveNotEmpty: "Số lượng nhận không được bỏ trống",
            heightNotEmpty: "Chiều cao không được bỏ trống",
            heightBiggerThanZero: "Chiều cao phải lớn hơn hoặc nhỏ hơn 0",
            widthNotEmpty: "Chiều rộng không được bỏ trống",
            widthBiggerThanZero: "rộng cao phải lớn hơn hoặc nhỏ hơn 0",
            longNotEmpty: "Chiều dài không được bỏ trống",
            longBiggerThanZero: "Chiều dài phải lớn hơn hoặc nhỏ hơn 0",
            dateExpireNotEmpty: "Ngày hết hạn không được bỏ trống",
            packageTypeNotEmpty: "Kiểu đóng gói không được bỏ trống",
            unitNotEmpty: "Đơn vị không được bỏ trống",
            dateExpireNotSmallerThanCurrentDay: "Ngày hết hạn không được nhỏ hơn ngày hiện tại",
            DataErr: "Dữ liệu đang không hợp lệ để tiến hành in vui lòng kiểm tra lại các trường nhập dữ liệu tại dòng số "
        },
        notification: {
            delete: "Xóa thành công",
            deletePackage: "Xóa package thành công"
        },
        validate: {
            qtyOrder: "Số lượng đặt đang không hợp lệ ở dòng số",
            dateExpire: "Số ngày hết hạn chưa được điền đầy đủ",
            orderCode: "Mã đơn hàng không được bỏ trống",
            QtyReal: "Số lượng thực tế phải lớn hơn hoặc bằng 0",
            QtyRealErr: "Số lượng thực tế không được bỏ trống",
            actualReceivedAmount: "Số lượng nhận thực tế không được lớn hơn số lượng đặt",
            actualAmountEquals: "Số lượng thực tế đang không bằng với số lượng nhận",
        },
        toastJs: {
            printAllOk: "In tất cả tem thành công",
            printOk: "In tem thành công",
            rePrintOK: "In lại tem thành công",
            importedOk: "Nhập kho thành công",
        }
    },
    producePlan: {
        requiredProduceCode: "Yêu cầu sản xuất",
        codeTable1: "Thông tin yêu cầu sản xuất",
        codeTable2: "Lệnh sản xuất",
        code: "Yêu cầu sản xuất",
        product: "Sản phẩm",
        amountOrder: "Số lượng yêu cầu",
        amountHasInOrder: "Số lượng yêu cầu đã vào lệnh",
        date: "Ngày bàn giao",
        ttsx: "Trung tâm sản xuất",
        start: "Bắt đầu dự kiến",
        end: "Kết thúc dự kiến",
        amount: "Số lượng",
        expect: "Dự kiến",
        real: "Thực tế",
        isRuning: "Đang chạy",
        hasPublish: "Đang chạy",
        isCreate: "Đang khởi tạo",
        notification: {
            orderNotValid: "Lỗi Số lượng đã vào lệnh không được phép lớn hơn số lượng yêu cầu"
        },
        popup: {
            deleteWo: {
                title: "Xác nhận xóa lệnh sản xuất",
                content: "Bạn chắc chắn muốn xóa lệnh sản xuất"
            }
        },
        placeHolder: {
            dropdown: "Chọn yêu cầu sản xuất",
            wocode: "Nhập lệnh sản xuất",
            product: "Chọn sản phẩm",
            workCenter: "Chọn trung tâm sản xuất",
            start: "chọn ngày bắt đầu dự kiến",
            end: "chọn ngày kết thúc dự kiến",
        },
        status: {
            new: "Đang khởi tạo",
            publish: "Đã phát hành",
            running: "Đang chạy",
            errors: "Lệnh sản xuất đang bị trùng",
        }
    },

    pCoordination: {
        startReal: "Bắt đầu thực tế",
        endReal: "Kết thúc thực tế",
        changeStatus: "Đổi trạng thái",
        searchField: "Tìm kiếm theo lệnh sản xuất , sản phẩm , trung tâm sản xuất .....",
        status: "Chọn trạng thái tìm kiếm",
        date: "Chọn thời gian tìm kiếm",
        run: "Chạy",
        pause: "Dừng",
        complete: "Hoàn thành",
        stop: "Hủy",
        expectAmount: "Số lượng dự kiến",
        realAmount: "Số lượng thực tế",
        popup: {
            emptyHuman: {
                title: "Cảnh báo! Thiếu nhân công hoặc thiết bị máy móc",
                content: "Bạn vẫn muốn xác nhận lập lịch sản xuất?"
            },
            pause: {
                title: "Tạm dừng lệnh",
                content: "Bạn chắc chắn muốn tạm dừng các lệnh làm việc này?"
            },
            run: {
                title: "Chạy lệnh",
                content: "Bạn chắc chắn muốn chạy các lệnh làm việc này?"
            },
            stop: {
                title: "Huỷ lệnh làm việc",
                content: "Bạn chắc chắn muốn huỷ lệnh làm việc này?"
            },
            form: {
                complete: "Hoàn thành lệnh sản xuất",
                update: "Cập nhật lệnh sản xuất",
                view: "Chi tiết lệnh sản xuất"
            }
        },
        placeHolder: {
            search: "Nhập lệnh sản xuất"
        }
    },
    calendarComp: {
        monday: "Thứ 2",
        tueday: "Thứ 3",
        wenesday: "Thứ 4",
        thurday: "Thứ 5",
        friday: "Thứ 6",
        saturday: "Thứ 7",
        sunday: "Chủ nhật",
        hello: "hello"
    },
    inventoryCheck: {
        start: "Bắt đầu",
        end: "Kết thúc",
        table: {
            code: "Mã kiểm kê",
            expect: {
                start: "Thời gian dự kiến bắt đầu",
                end: "Thời gian dự kiến kết thúc",
            },
            location: "Vị trí thực hiện",
            start: "Thời gian bắt đầu",
            end: "Thời gian kết thúc",
            type: "Phân loại",
            user: "Người phụ trách",
            purpose: "Mục đích",
            progress: "Tiến độ",
        },
        form: {
            level: "Cấp độ thống kê",
            create: "Tạo mới kiểm kê, kiểm đếm",
            update: "Cập nhật kiểm kê, kiểm đếm",
            view: "Thông tin kiểm kê, kiểm đếm"
        },
        validate: {
            noChooseEnd: "Chưa chọn kế hoạch để kết thúc",
            noEnd: "Không thể kết thúc 1 lệnh kiểm kê đã hoặc đang thực hiện",
            noChooseStart: "Chưa chọn kế hoạch để bắt đầu",
            noStart: "Không thể bắt đầu 1 lệnh kiểm kê đã hoặc đang thực hiện",
            noUpdate: "Kiểm kê không thể sửa do đã hoặc đang thực hiện",
            noDelete: "Kiểm kê không thể xóa do đã hoặc đang thực hiện",
            timeErr: "Thời gian dự kiến không hợp lý",
            inventoryCode: "Mã kiểm kê không đúng định dạng",
            inventoryCodeNull: "Mã kiểm kê không được bỏ trống",
            inventoryCodeLength: "Mã kiểm kê không được quá 50 kí tự",
            Typechecking: "Phân loại không được bỏ trống",
            Pic: "Người phụ trách không được bỏ trống",
            Areachecking: "Vị trí thực hiện không được bỏ trống",
            Levelreport: "Cấp độ thống kê không được bỏ trống",
            Dateplanfrom: "Chọn thời gian bắt đầu",
            Dateplanto: "Chọn thời gian kết thúc",
            Objective: "Mục đích kiểm kê không quá 225 ký tự"
        },
        delete: {
            title: "Xóa kiểm kê",
            des: "Bạn có chắc chắn muốn xóa kiểm kê này?"
        }
    },
    doingCheck: {
        location: "Phạm vi kiểm kê",
        locationDoingCheck: "Khu vực thực hiện",
        locationStoreCheckInformation: "Vị trí lưu trữ kiểm kê",
        packageCode: "Mã package",
        amount: "Số lượng",
        notOpen: "Chưa mở",
        amountGet: "Số lượng kiểm được",
        positionStore: "Vị trí lưu trữ kiểm kê",
        packageName: "Tên hàng hóa",
        amountDeclareBeforeCheck: "Số lượng chốt trước kiểm kê",
        placeHolder: {
            inventoryCode: "Chọn mã kiểm kê",
            scopeInventory: "Chọn phạm vi kiểm kê",
            ImplementationArea: "Chọn khu vực thực hiện",
            positionStore: "Nhập vị trí lưu trữ kiểm kê",
        }
    },
    checkpoint: {
        table: {
            code: 'Mã checkpoint',
            name: 'Tên checkpoint',
            product: 'Sản phẩm',
            process: 'Quy trình',
            lower: 'Giá trị cận dưới',
            higher: 'Giá trị cận trên',
            equal: 'Giá trị so sánh bằng',
            status: 'Trạng thái',
            iqc: "Cho IQC",
            oqc: "Cho OQC"
        },
        search: {
            placeHolder: 'Tìm kiếm theo mã checkpoint, tên checkpoint'
        },
        delete_successful: 'Xóa checkpoint thành công',
        popup: {
            delete: {
                title: "Xác nhận xoá checkpoint",
                content: "Bạn có chắc chắn muốn xóa checkpoint này?",
            }
        },
        form: {
            create: "Tạo mới checkpoint",
            update: "Cập nhật checkpoint",
            view: "Thông tin chi tiết checkpoint",
            code: "Mã code",
            name: "Tên checkpoint",
            status: "Trạng thái",
            isCheckInRange: "Kiểm soát giá trị số theo khoảng",
            isCheckEqual: "Kiểm soát giá trị bằng",
            qtyUpper: "Giá trị cận trên",
            qtyLower: "Giá trị cận dưới",
            value: "Giá trị bằng"
        }
    },
    checklist: {
        btn: {
            add_btn_title: "Thêm mới",
        },
        table: {
            code: 'Mã checklist',
            name: 'Tên checklist',
            product: 'Sản phẩm',
            process: 'Quy trình',
            stage: 'Công đoạn',
            oqc: 'Cho OQC',
            iqc: 'Cho IQC',
            status: 'Trạng thái',
        },
        form: {
            title: {
                title_create: "Tạo mới checklist",
                title_update: "Cập nhật checklist",
                title_see_detail: "Chi tiết checklist",
            },
            lable: {
                code: "Mã code",
                name: "Tên",
                product: "Sản phẩm"
            },
            placeHolder: {
                checklist_name: "Nhập tên checklist",
                product: "Chọn sản phẩm",
            },
            code: "Mã checkpoint",
            name: "Tên checkpoint",
            action: "Thao tác",
            checklist_for: {
                checklist_for_title: "Checklist dùng cho",
                stage: 'Cho công đoạn',
                stage_option: {
                    stage_option_process_label: "Quy trình",
                    stage_option_step_process_label: "Công đoạn",
                },
                placeHolder: {
                    stage_option_process: "Chọn quy trình",
                    stage_option_step_process: "Chọn công đoạn",
                },
                oqc: 'Cho OQC',
                iqc: 'Cho IQC',
            },
            table: {
                placeHolder: "Chọn checkpoint",
            },
            btn: {
                add: "Thêm checkpoint",
                save: "Lưu",
                back: "Quay lại",
            },
            status: "Trạng thái",
        },
        search: {
            placeHolder: 'Tìm kiếm theo mã checklist , tên checklist , mã sản phẩm , tên sản phẩm , cho OQC , trạng thái'
        },
        toast: {
            create_sucess: "Tạo mới checklist thành công",
            update_sucess: "Cập nhật checklist thành công",
        },
        rule: {
            checklist_code: {
                required: "Mã checklist không được bỏ trống",
            },
            product_code: {
                required: "Sản phẩm không được bỏ trống",
            },
            checklist_name: {
                required: "Tên checklist không được bỏ trống",
                max_length: "Tên checklist không được quá 150 kí tự",
            },
            processid: {
                required: "Quy trình không được bỏ trống",
            },
            stepid: {
                required: "Công đoạn không được bỏ trống",
            },
            checkpoint_code: {
                required: "Checkpoint không được bỏ trống",
            },
            approvefor: {
                required: "Vui lòng chọn checkbox",
            },
        }
    },
    traceReport: {
        placeHolderSearch: "Nhập mã barcode",
        confirmTrackingBtn: "Truy vết",
        table: {
            hisid: "HISID",
            itemcode: "Mã Sản Phẩm",
            wocode: "WO",
            process: "Mã quy trình",
            version: "Version",
            stepver: "Công đoạn",
            workcenter: "TTSX",
            workunit: "ĐVSX",
            starttime: "Thời gian bắt đầu",
            endtime: "Thời gian kết thúc",
            machineid: "Máy móc",
            workerid: "Nhân viên",
            status: "Trạng thái",
            wuname: "Vị trí hiện tại",
            packagecode: "Mã đóng gói",
            lotQA: "Lot QA",
            startTimeItem: "Ngày bắt đầu sản xuất"
        },
        popup: {
            delete: {
                title: "Xác nhận xoá checkpoint",
                confirmText: "Bạn có chắc chắn muốn xóa checkpoint này?"
            }
        }
    },
    inventoryLg: {
        placeHolder: {
            Checkinventorycode: "Nhập mã kiểm kê",
            Dateplanfrom: "Chọn thời gian dự kiến bắt đầu",
            Dateplanto: "Chọn thời gian dự kiến kết thúc",
            AreacheckingName: "Nhập vị trí thực hiện",
            Datefrom: "Chọn thời gian bắt đầu",
            Dateto: "Chọn thời điểm kết thúc",
            Typechecking: "Chọn phân loại",
            PicName: "Nhập người phụ trách",
            Objective: "Nhập mục đích",
            StatusName: "Chọn loại tiến độ",

        }
    },
    placeHolder: {
        date: "Thời gian",
    },
    itemstatistics: {
        from: "Từ",
        to: "Đến",
        choosettsx: "Chọn trung tâm sản xuất",
        bywo: "Tìm theo lệnh sản xuất",
        thongke: "Thống kê",
        truyvet: "Truy vết bằng mã sản phẩm",
        itemstatic: "Thống kê item",
    }
};
