import { call, put, takeEvery } from "redux-saga/effects";
import { CHECK_DUPLICATED_MATERIAL_SAGA, CHECK_DUPLICATED_PRODUCTION_SAGA } from "./enum/CheckduplicatedAction";
import { checkDuplicatedMaterialService, checkDuplicatedProductionService } from "./CommonCheckduplicatedService";
import CommonCheckduplicatedAction from "./CommonCheckduplicatedAction";
export function* checkDuplicatedMaterial(data) {
    try {
        let request = data.request;
        const response = yield call(checkDuplicatedMaterialService, request);
        if (response.isSuccess == true) {
            yield put(CommonCheckduplicatedAction.setCheckDuplicatedMaterial(response.data))
        }
    } catch (error) {
    }
}
export function* checkDuplicatedProduction(data) {
    try {
        let request = data.request;
        const response = yield call(checkDuplicatedProductionService, request);
        if (response.isSuccess == true) {
            yield put(CommonCheckduplicatedAction.setCheckDuplicatedMaterial(response.data))
        }
    } catch (error) {
    }
}
export default function* CommonCheckduplicatedSaga() {
    yield takeEvery(CHECK_DUPLICATED_MATERIAL_SAGA, checkDuplicatedMaterial);
    yield takeEvery(CHECK_DUPLICATED_PRODUCTION_SAGA, checkDuplicatedProduction);
}