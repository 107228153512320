// import {
//     CALL_DEFAULT_REDUCER,
//     CLEAR_DATA_REDUCER
// } from "../InventoryManagement/Inventory/enum/TypeInventoryAction";
import {
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    GET_LOG_MANAGEMENT_DATA_PAGINATION_CLEAR,
    GET_LOG_MANAGEMENT_DATA_PAGINATION_REDUCER
} from "./enum/LogManagementTypeAction";

const stateDefault = {
    dataPagination: [],
    isLoadingDataPagination: false,
};

const LogManagementReducer = (state = stateDefault, action) => {
    switch (action.type) {
        case GET_LOG_MANAGEMENT_DATA_PAGINATION_REDUCER:
            state.dataPagination = action.response;
            state.isLoading = true;
            return {...state};

        case GET_LOG_MANAGEMENT_DATA_PAGINATION_CLEAR:
            state.isLoading = false
            return {...state};

        case CALL_DEFAULT_REDUCER:
            state.isLoading = false
            return {
                ...state,
            };

        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };

        default:
            return {...state};
    }
}

export default LogManagementReducer