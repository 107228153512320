export const timeManagerType = {
    language: 'vn',
    table_time_type: {
        name: "Tên loại ngày làm việc",
        percent: "Phần trăm lương",
        enjoy_bhxh: "Có hưởng BHXH",
        color: "Màu đại điện",
        group_day: "Nhóm ngày làm việc"
    },
    placeholder_type_time: "Nhập tên loại ngày cần tìm",
    form_type_time: {
        title_create: "Thêm mới loại ngày làm việc",
        title_infor: "Thông tin loại ngày làm việc",
        title_edit: "Cập nhật loại ngày làm việc",
        title_delete: "Xóa loại ngày làm việc",
        text_confirm_delete: "Bạn có muốn xóa loại ngày làm việc này?",
        start_date: "Áp dụng từ ngày",
        end_date: "Áp dụng đến ngày",
        title_form_time_shif: "Chọn ca làm việc",
        btn_add_time_shift: "Thêm ca"
    },
    mes_type_time: {
        required_name: "Tên không thể để trống",
        maxLenght_name: "Tên không thể dài quá 100 kí tự",
        maxLenght_sign: "Ký hiệu không thể dài quá 20 kí tự",
        required_sign: "Ký hiệu không thể để trống",
        required_group_day: "Nhóm ngày làm việc không thể để trống",
        maxLenght_percent: "Phần trăm lương không thể dài quá 100 ký tự",
        required_percent: "Phần trăm lương không thể để trống",
        create_success: "Tạo mới loại ngày làm việc thành công",
        update_success: "Cập nhật loại ngày làm việc thành công",
        delete_success: "Xóa loại ngày làm việc thành công",
        percent_less_zero: "Phần trăm lương phải lớn hơn 0",
        Enddate_required: "Ngày kết thúc áp dụng không được để trống",
        startdate_required: "Ngày bắt đầu áp dụng không được để trống",

    }
}