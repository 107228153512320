import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_PAGING_SUPPLIER_CATEGORY_API,
  DELETE_SUPPLIER_CATEGORY_API,
  CREATE_SUPPLIER_CATEGORY_API,
  UPDATE_SUPPLIER_CATEGORY_API,
} from "./enum/TypeCatSupplierAPI.js";

export async function getPagingService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_PAGING_SUPPLIER_CATEGORY_API,
    data
  );
}

export async function deleteCatSupplierService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_SUPPLIER_CATEGORY_API + data
  );
}
export async function updateCatSupplierService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_SUPPLIER_CATEGORY_API,
    data
  );
}
export async function createCatSupplierService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_SUPPLIER_CATEGORY_API,
    data
  );
}

