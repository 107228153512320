import { type } from "@testing-library/user-event/dist/type";
import {
    CALL_UPDATE_SUCCESS_REDUCER,
    CALL_UPDATE_FAIL_REDUCER,
    SEARCH_PRODUCT_BY_CODEOH,
    SEARCH_PRODUCT_BY_CODEOH_SAGA,
    SEARCH_PRODUCT_BY_CODEOH_SUSSESS_REDUCER,
    RESET_SEARCH_PRODUCT_BY_CODEOH_REDUCER,
    EXPORT_REPORT_BY_LIST_ID_SAGA,
    GET_PAGING_BY_PLANNING_SAGA,
    GET_PAGING_BY_PANNING_ID_REDUCER,
    CALL_CLEAR_DETAIL,
    CALL_DEFAULT_LST_DATA_BY_PLANING_REDUCER
} from "./enum/TypeCatWarehouseProductAction"

import {
    GET_PAGING_CAT_WAREHOUSE_PRODUCT_SAGA,
    GET_PAGING_PRODUCT_BY_ROLE_USER_SAGA,
    UPDATE_CAT_WAREHOUSE_PRODUCT_SAGA,
    GET_PAGING_CAT_WAREHOUSE_PRODUCT_REDUCER,
    GET_PAGING_PRODUCT_BY_ROLE_USER_REDUCER,
    EXPORT_LIST_DATA_SAGA,
    CALL_DEFAULT_REDUCER,
    LOAD_MORE_DATA,
    CLEAR_DATA_REDUCER,
    GET_DETAIL_CAT_WAREHOUSE_PRODUCT_REDUCER,
    GET_DETAIL_CAT_WAREHOUSE_PRODUCT_SAGA

} from "./enum/TypeCatWarehouseProductAction"

const CatWarehouseProductAction = {
    getPagingSaga: (pageIndex, pageSize, searchTerm = "", catWarehouseIDList = [], IsRetailDispensingList  ) => {
        return {
            type: GET_PAGING_CAT_WAREHOUSE_PRODUCT_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
            catWarehouseIDList: catWarehouseIDList,
            IsRetailDispensingList: IsRetailDispensingList
            
        };
    },
    getPagingByRoleUserSaga: (pageIndex, pageSize,listIdNotSelect = [], searchTerm = "", productGroupId = [] , hospitalId , warehouseId, listCodeOHSelected = [], ConsumablePackageId = null, typeFunction = null  ) => {
        return {
          type: GET_PAGING_PRODUCT_BY_ROLE_USER_SAGA,
          pageIndex: pageIndex,
          pageSize: pageSize,
          searchTerm: searchTerm,
          listIdNotSelect:listIdNotSelect,
          productGroupId:productGroupId,
          hospitalId:hospitalId,
          warehouseId: warehouseId,
          listCodeOHSelected: listCodeOHSelected,
          ConsumablePackageId:ConsumablePackageId,
          typeFunction:typeFunction,
        };
      },
    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_CAT_WAREHOUSE_PRODUCT_REDUCER,
            data: data,
        };
    },
    searchProductByCodeOH: (codeOH, warehouseId, hospitalId, typeCodeFunction) =>{
        return {
            type: SEARCH_PRODUCT_BY_CODEOH_SAGA,
            codeOH: codeOH,
            warehouseId:warehouseId,
            hospitalId:hospitalId,
            typeCodeFunction: typeCodeFunction,
          };
    },
    searchProductByCodeOHSuccessReducer:(data) => {
        return {
            type: SEARCH_PRODUCT_BY_CODEOH_SUSSESS_REDUCER,
            data:data
          };
    },
    resetSearchProductByCodeOH:() => {
        return {
            type: RESET_SEARCH_PRODUCT_BY_CODEOH_REDUCER
          };
    },
    getPagingByRoleUserReducer: (data) => {
        return {
            type: GET_PAGING_PRODUCT_BY_ROLE_USER_REDUCER,
            data: data,
        };
    },
    callLoadMoreReducer:()=>{
        return{
            type:LOAD_MORE_DATA
        }
    },
    updateDataSaga: (data) => {
        return {
            type: UPDATE_CAT_WAREHOUSE_PRODUCT_SAGA,
            data: data
        }
    },
    callUpdateSuccessReducer: (data) => {
        return {
            type: CALL_UPDATE_SUCCESS_REDUCER,
            data: data
        }
    },
    callUpdateFailReducer: (data) => {
        return {
            type: CALL_UPDATE_FAIL_REDUCER,
            data: data

        }
    },
    exportListDataSaga: (pageIndex, pageSize, searchTerm = "", catWarehouseIDList = []) => {
        return {
            type: EXPORT_LIST_DATA_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
            catWarehouseIDList: catWarehouseIDList
        };
    },
    exportReportByListIdSaga: (searchTerm = '', catWarehouseIDList = [], IsRetailDispensingList = [] ) => {
        return {
            type: EXPORT_REPORT_BY_LIST_ID_SAGA,
            searchTerm: searchTerm,
            catWarehouseIDList: catWarehouseIDList,
            IsRetailDispensingList: IsRetailDispensingList
        }
    },
    getPagingByPlanningSaga: (pageIndex, pageSize, searchTerm = "", lstProductGroupId = [], warehouseId, planningId = 0) => {
        return {
            type: GET_PAGING_BY_PLANNING_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
            lstProductGroupId:lstProductGroupId,
            warehouseId:warehouseId,
            planningId: planningId,
        };
    },
    getPagingByPlanningReducer: (data) =>{
        return {
            type: GET_PAGING_BY_PANNING_ID_REDUCER,
            data:data
        }
    },
    callDefaultLstDataByPlanningReducer:() =>{
        return {
            type: CALL_DEFAULT_LST_DATA_BY_PLANING_REDUCER,
        };
    },
    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER,
        };
    },
    clearDetailReducer: () => {
        return {
            type: CALL_CLEAR_DETAIL,
        };
    },
    getDetailCatWarehouseProductSaga: (Id) =>{
        return {
            type: GET_DETAIL_CAT_WAREHOUSE_PRODUCT_SAGA,
            Id: Id
        }
    },
    getDetailCatWarehouseProductReducer: (data) =>{
        console.log("Action ", data);
        return {
            type: GET_DETAIL_CAT_WAREHOUSE_PRODUCT_REDUCER,
            data: data
        }
    }
}
export default CatWarehouseProductAction;