import {
    UPDATE_ITEM_CODE_REDUCER,
    UPDATE_ITEM_CODE_DONE,
    //-------------------

    SET_UP_ERROR,
    SET_UP_ERROR_DONE,
    //-------------------

    SET_UP_DATA_RESULT,
    SET_UP_DATA_RESULT_DONE,
    //-------------------

    CALL_API_TO_GET_INFORMATION_DATA_SAGA,
    CALL_API_TO_GET_INFORMATION_DATA_REDUCER,
    CALL_API_TO_GET_INFORMATION_DATA_DONE,
    SCAN_ITEM_CODE_UPDATE_TABLE,
    //-------------------
    SCAN_ITEM_CODE_SAGA,
    SCAN_DATA_AFTER_SCAN_ITEM_CODE_REDUCER,
    SCAN_ITEM_CODE_SAGA_DONE,
    CALL_API_RECORD_PRODUCT_EXCUTE_DATA_SAGA,
    CALL_API_RECORD_PRODUCT_EXCUTE_DATA_REDUCER,
    CALL_API_RECORD_PRODUCT_EXCUTE_DATA_DONE,
    GET_WORK_ORDER_BY_CODE_SAGA,
    GET_WORK_ORDER_BY_CODE_REDUCER,
    GET_WORK_ORDER_BY_CODE_DONE,
    GET_MACHINE_SAGA,
    GET_MACHINE_REDUCER,
    GET_MACHINE_DONE,
    SET_UPDATE_INFORMATION,
} from "./enum/actionEnum";

const recordResultV2Action = {
    updateCodeRecordResult: (code = "") => {
        return {
            type: UPDATE_ITEM_CODE_REDUCER,
            code
        }
    },
    updateCodeRecordResultDone: () => {
        return {
            type: UPDATE_ITEM_CODE_DONE,
        }
    },
    //-----------------------------------------------------------

    setupErrorReducer: (code = "") => {
        return {
            type: SET_UP_ERROR,
            code
        }
    },
    setupErrorDone: () => {
        return {
            type: SET_UP_ERROR_DONE,
        }
    },
    //-----------------------------------------------------------

    setupDataResultReducer: (code = "") => {
        return {
            type: SET_UP_DATA_RESULT,
            code
        }
    },
    setupDataResultReducerDone: () => {
        return {
            type: SET_UP_DATA_RESULT_DONE,
        }
    },
    //-----------------------------------------------------------

    callInfoApiSaga: () => {
        return {
            type: CALL_API_TO_GET_INFORMATION_DATA_SAGA,
        }
    },
    setInfoDataReducer: (data) => {
        return {
            type: CALL_API_TO_GET_INFORMATION_DATA_REDUCER,
            data
        }
    },
    callInfoApiDoneReducer: () => {
        return {
            type: CALL_API_TO_GET_INFORMATION_DATA_DONE,
        }
    },
    callApiToDisplayTable: (status) => {
        return {
            type: SCAN_ITEM_CODE_UPDATE_TABLE,
            status
        }
    },
    //-----------------------------------------------------------
    scanItemCodeSaga: (ItemCode, workOderCode, stepId, isReadingDoc) => {

        return {
            type: SCAN_ITEM_CODE_SAGA,
            ItemCode,
            workOderCode,
            stepId,
            isReadingDoc
        }
    },
    setScanItemCodeReducer: (data) => {
        return {
            type: SCAN_DATA_AFTER_SCAN_ITEM_CODE_REDUCER,
            data
        }
    },
    scanItemCodeSagaDone: () => {
        return {
            type: SCAN_ITEM_CODE_SAGA_DONE,
        }
    },
    excute_data_saga: (entity) => {
        return {
            type: CALL_API_RECORD_PRODUCT_EXCUTE_DATA_SAGA,
            entity
        }
    },
    excute_data_reducer: () => {
        return {
            type: CALL_API_RECORD_PRODUCT_EXCUTE_DATA_REDUCER,
        }
    },
    excute_data_done: () => {
        return {
            type: CALL_API_RECORD_PRODUCT_EXCUTE_DATA_DONE,
        }
    },
    //-----------------------------------------------------------
    getwosaga: (woCode) => {
        return {
            type: GET_WORK_ORDER_BY_CODE_SAGA,
            woCode
        }
    },
    getworeducer: (response) => {
        return {
            type: GET_WORK_ORDER_BY_CODE_REDUCER,
            response
        }
    },
    getwodone: () => {
        return {
            type: GET_WORK_ORDER_BY_CODE_DONE,
        }
    },
    //-----------------------------------------------------------
    getMachinesaga: (stepid, workunitid) => {
        return {
            type: GET_MACHINE_SAGA,
            stepid,
            workunitid
        }
    },
    getMachinereducer: (data) => {
        return {
            type: GET_MACHINE_REDUCER,
            data
        }
    },
    getMachinedone: () => {
        return {
            type: GET_MACHINE_DONE,
        }
    },
    setUpdateInfomationData: (data) => {
        return {
            type: SET_UPDATE_INFORMATION,
            data
        }
    },
}

export default recordResultV2Action;