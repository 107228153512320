export const topbarData = [
    {
        to: '/control-mes',
        title: 'demo chung dùng antd',
    },
    {
        to: '/base-uploadFile',
        title: 'base upload',
    },
    {
        to: '/base-datePicker',
        title: 'base datePicker',
    },
    {
        to: '/control-mes-using',
        title: 'control',
    },
    {
        to: '/control-timeline',
        title: 'timeline',
    },
    {
        to: '/base-popup',
        title: 'các popup sẽ dùng',
    },
    {
        to: '/trang-chu',
        title: 'Trang chủ',
    },

    {
        to: '/quan-ly-nguoi-lao-dong/ky-nang',
        title: 'Quản lý kỹ năng',
    },
    {
        to: '/quan-ly-nguoi-lao-dong',
        title: 'Quản lý người lao động',
    },
    {
        to: '/phan-quyen',
        title: 'Phân quyền',
    },
    {
        to: '/quy-trinh-phe-duyet',
        title: 'Quy trình phê duyệt',
    },
    {
        to: '/thong-tin-phe-duyet',
        title: 'DS tài liệu phê duyệt',
    },

    {
        to: '/quan-ly-thong-tin-doi-tac',
        title: 'Đối tác',
    },
    {
        to: '/thong-tin-location',
        title: 'Vị trí lưu trữ',
    },
    {
        to: '/quan-ly-linh-kien',
        title: 'Nguyên vật liệu',
    },
    {
        to: '/quan-ly-don-vi',
        title: 'Đơn vị',
    },
    {
        to: '/quan-ly-kieu-dong-goi',
        title: 'Kiểu đóng gói tiêu chuẩn',
    },
    {
        to: '/thong-tin-tieu-chuan-dong-goi',
        title: 'Quy định đóng gói theo từng NVL',
    },
    {
        to: '/quan-ly-kieu-van-chuyen',
        title: 'Loại dịch chuyển trong kho',
    },
    {
        to: '/quan-ly-nguon-yeu-cau',
        title: 'Nguồn yêu cầu xuất - chuyển kho',
    },

    {
        to: '/danh-muc-dat-hang',
        title: 'Đơn đặt hàng PO',
    },
    {
        to: '/danh-muc-giao-hang',
        title: 'Đơn giao hàng DO',
    },
    {
        to: '/quan-ly-packageinfo',
        title: 'Lô hàng đã nhận',
    },
    {
        to: '/quan-ly-kho-hang',
        title: 'Nhận hàng từ NCC',
    },
    {
        to: '/cat-kho',
        title: 'Cất hàng vào kho',
    },

    {
        to: '/chuyen-kho-noi-bo',
        title: 'Chuyển kho nội bộ',
    },

    {
        to: '/quan-ly-phieu-yeu-cau',
        title: 'Yêu cầu xuất - chuyển kho',
    },
    {
        to: '/phieu-lay-hang',
        title: 'Tạo phiếu xuất - chuyển kho',
    },
    {
        to: '/lay-hang-theo-phieu',
        title: 'Xuất - chuyển kho theo phiếu',
    },

    {
        to: '/kiem-ke',
        title: 'Lập kế hoạch',
    },
    {
        to: '/thuc-hien-kiem-ke',
        title: 'Thực hiện',
    },

    {
        to: '/cai-dat-lich-lam-viec',
        title: 'Lịch làm việc',
    },
    {
        to: '/quan-ly-san-pham',
        title: 'Sản phẩm',
    },
    {
        to: '/quan-ly-bom',
        title: 'Định mức tiêu hao',
    },
    {
        to: '/quan-ly-sop',
        title: 'Tài liệu HD SX',
    },
    {
        to: '/quy-trinh-san-xuat',
        title: 'Quy trình sản xuất',
    },

    {
        to: '/quan-ly-khu-vuc-san-xuat',
        title: 'Khu vực sản xuất',
    },
    {
        to: '/quan-ly-work-unit',
        title: 'Đơn vị sản xuất',
    },
    {
        to: '/quan-ly-trung-tam-san-xuat',
        title: 'Trung tâm sản xuất',
    },
    {
        to: '/lien-ket-kvsx-quy-trinh',
        title: 'Quy trình trên dây chuyền',
    },
    {
        to: '/cai-dat-day-chuyen-san-xuat',
        title: 'Gán công nhân - gán máy móc vào nơi làm việc',
    },

    {
        to: '/yeu-cau-san-xuat',
        title: 'Yêu cầu sản xuất',
    },
    {
        to: '/lap-ke-hoach-san-xuat',
        title: 'Lập kế hoạch sản xuất',
    },
    {
        to: '/lap-lich-san-xuat-lai',
        title: 'Lập kế hoạch sản xuất lại',
    },
    {
        to: '/dieu-phoi-san-xuat',
        title: 'Điều phối sản xuất',
    },
    {
        to: '/dieu-phoi-san-xuat/table',
        title: 'Điều phối sản xuất',
    },
    {
        to: '/dieu-phoi-san-xuat/calendar',
        title: 'Điều phối sản xuất',
    },

    {
        to: '/cap-phat-item',
        title: 'Tạo item cho máy in tự động',
    },
    {
        to: '/dinh-danh-san-pham/in-tem',
        title: 'Định danh sản phẩm',
    },
    {
        to: '/dinh-danh-san-pham/quan-ly',
        title: 'Quản lý mã định danh',
    },
    {
        to: '/ghi-nhan-ket-qua',
        title: 'Ghi nhận kết quả SX',
    },
    {
        to: '/dong-goi/thuc-thi-dong-goi',
        title: 'Đóng gói',
    },
    {
        to: '/dong-goi/quy-cach-dong-goi',
        title: 'Đóng gói',
    },
    {
        to: '/dong-goi/in-lai-tem',
        title: 'Đóng gói',
    },
    {
        to: '/dong-goi/huy-tem',
        title: 'Đóng gói',
    },
    {
        to: '/dong-goi/cap-do-dong-goi',
        title: 'Đóng gói',
    },
    {
        to: '/quan-ly-chat-luong/check-point',
        title: 'Điểm kiểm tra QC ',
    },
    {
        to: '/quan-ly-chat-luong/check-list',
        title: 'Bài kiểm tra QC',
    },
    {
        to: '/sua-chua',
        title: 'Sửa chữa trên chuyền',
    },
    {
        to: '/quan-ly-chat-luong/quan-ly-lotQA',
        title: 'Quản lý Lot Qa',
    },

    {
        to: '/quan-ly-may-moc',
        title: 'Thông tin máy móc',
    },
    {
        to: '/sua-chua-may-moc',
        title: 'Sửa chữa máy móc',
    },
    {
        to: '/lich-bao-duong',
        title: 'Bảo dưỡng máy móc',
    },
    {
        to: '/chu-ky-bao-duong',
        title: 'Chu kỳ bảo dưỡng',
    },
    {
        to: '/dang-ky-lich-bao-duong',
        title: 'Đăng ký lịch bảo dưỡng',
    },
    {
        to: '/quan-ly-ton-kho-theo-ky',
        title: 'Báo cáo tồn kỳ',
    },
    {
        to: '/quan-ly-ton-kho-theo-thoi-diem',
        title: 'Báo cáo tồn kho',
    },
    {
        to: '/bao-cao-day-chuyen',
        title: 'Báo cáo đầu chuyền',
    },
    {
        to: '/bao-cao-san-luong',
        title: 'Báo cáo sản lượng',
    },
    {
        to: '/bao-cao-truy-vet/ma-san-pham',
        title: 'Báo cáo truy vết',
    },
    {
        to: '/bao-cao-truy-vet/thong-ke',
        title: 'Thống kê item',
    },
    {
        to: '/bao-cao-chat-luong',
        title: 'Báo cáo chất lượng',
    },
    {
        to: '/bao-cao-sua-chua',
        title: 'Báo cáo sửa chữa',
    },
    {
        to: '/bao-cao-oee',
        title: 'Báo cáo OEE',
    },
    {
        to: '/quan-ly-chat-luong/check-point/cap-nhat/:id',
        title: 'Cập nhật checkpoint',
    },
    {
        to: '/quan-ly-chat-luong/check-point/tao-moi',
        title: 'Tạo mới checkpoint',
    },
    {
        to: '/quan-ly-chat-luong/check-list/tao-moi',
        title: 'Tạo mới checklist',
    },
    {
        to: '/quan-ly-chat-luong/check-list/cap-nhat/:id',
        title: 'Cập nhật checklist',
    },
    {
        to: '/quan-ly-chat-luong/check-list/chi-tiet/:id',
        title: 'Xem thông tin checklist',
    },
    {
        to: '/quan-ly-chat-luong/quan-ly-lotQA/tao-moi',
        title: 'Quản lý chất lượng - Quản lý Lot Qa - Tạo mới Lot QA',
    },
    {
        to: '/quan-ly-chat-luong/quan-ly-lotQA/cap-nhat/:id',
        title: 'Quản lý chất lượng - Quản lý Lot Qa - Cập nhật Lot QA',
    },
    {
        to: '/quan-ly-chat-luong/quan-ly-lotQA/chi-tiet/:id',
        title: 'Quản lý chất lượng - Quản lý Lot Qa - Chi tiết Lot QA',
    },
    {
        to: '/quan-ly-chat-luong/ghi-nhan-ket-qua/:id',
        title: 'Quản lý chất lượng - Quản lý Lot Qa - Ghi nhận kết quả',
    },
    {
        to: '/quan-ly-chat-luong/danh-gia-ket-qua/:id',
        title: 'Quản lý chất lượng - Quản lý Lot Qa - Đánh giá kết quả',
    },
    {
        to: '/bang-quy-doi',
        title: 'Bảng quy đổi',
    },
    {
        to: '/quan-ly-kieu-linh-kien',
        title: 'Quản lý kiểu linh kiện'
    },
    {
        to: '/quan-ly-ca-lam-viec',
        title: 'Quản lý ca làm việc'
    },
    {
        to: '/quan-ly-lich-lam-viec',
        title: 'Quản lý lịch làm việc'
    },
    {
        to: '/quan-ly-loai-ngay-lam-viec',
        title: 'Quản lý loại ngày làm việc'
    },
    {
        to: '/quan-ly-nhom-loai-ngay-lam-viec',
        title: 'Quản lý nhóm ngày làm việc'
    },
    {
        to: '/thong-tin-phe-duyet/:id',
        title: 'Phê duyệt tài liệu'
    },
    {
        to: '/quan-ly-phan-loai-may-moc',
        title: "Quản lý phân loại máy móc"
    },
    {
        to: '/cap-bac-location',
        title: 'Cấp bậc location'
    }
]

export const topbarIconByModule = {
    trangchu: {
        id: 'trangchu',
        icon: '/assets/img/icon/home-icon-active.svg',
    },
    quanlytaisan: {
        id: 'quanlytaisan',
        icon: '/assets/img/icon/asset-management-active.svg'
    },
    quantri: {
        id: 'quantri',
        icon: '/assets/img/icon/window-icon-active.svg'
    }
}