import {
  GET_PAGING_SOP_SAGA,
  CREATE_SOP_SAGA,
  UPDATE_SOP_SAGA,
  CHANGE_VERSION_SOP_SAGA,
  GET_DETAIL_SOP_SAGA,
  GET_DROPDOWN_VERSION_SAGA,
  DELETED_VERSION_SAGA,
  ADD_NEW_VESION_SAGA,
  GET_PAGING_SOP_REDUCER,
  CALL_DEFAULT_SOP_REDUCER,
  CALL_RESULT_SOP_CREATE_REDUCER,
  CALL_RESULT_SOP_UPDATE_REDUCER,
  CALL_RESULT_ADD_VERSION_REDUCER,
  GET_DETAIL_SOP_REDUCER,
  CALL_CHANGE_VERSION_REDUCER,
  GET_DROPDOWN_VERSION_REDUCER,
  DELETE_VERSION_REDUCER,
  CLEAR_ALL_SOP_REDUCER_STATE
} from "./enum/SOPTypeAction.js";

const SOPAction = {
  getPagingSaga: (pageIndex, pageSize, searchTerm = "") => {
    return {
      type: GET_PAGING_SOP_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
    };
  },
  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_SOP_REDUCER,
      data: data,
    };
  },
  createDataSaga: (request) => {
    return {
      type: CREATE_SOP_SAGA,
      request: request,
    }
  },
  updateDataSaga: (request, pageIndex, pageSize) => {
    return {
      type: UPDATE_SOP_SAGA,
      request: request,
      pageIndex: pageIndex,
      pageSize: pageSize,
    }
  },
  getDetailSaga: (sopCode) => {
    return {
      type: GET_DETAIL_SOP_SAGA,
      sopCode: sopCode
    }
  },

  returnDetailReducer: (obj) => {
    return {
      type: GET_DETAIL_SOP_REDUCER,
      obj: obj,
    }
  },
  changeVersion: (sopId) => {

    return {
      type: CHANGE_VERSION_SOP_SAGA,
      sopId: sopId,
    }
  },
  callResultFromApiCreateReducer: () => {
    return {
      type: CALL_RESULT_SOP_CREATE_REDUCER,
    }
  },
  callResultFromApiUpdateReducer: () => {
    return {
      type: CALL_RESULT_SOP_UPDATE_REDUCER,
    }
  },
  callResultAddVersionReducer: () => {
    return {
      type: CALL_RESULT_ADD_VERSION_REDUCER,
    }
  },
  callChangeVersionReducer: () => {
    return {
      type: CALL_CHANGE_VERSION_REDUCER,
    }
  },
  getDropdownVersion: (sopId) => {
    return {
      type: GET_DROPDOWN_VERSION_SAGA,
      sopId: sopId,
    }
  },
  getDropdownVersionReducer: (data) => {
    return {
      type: GET_DROPDOWN_VERSION_REDUCER,
      data: data,
    };
  },
  deletedVersionSaga: (versionId) => {
    return {
      type: DELETED_VERSION_SAGA,
      versionId: versionId,
    }
  },
  deleteVersionReducer: () => {
    return {
      type: DELETE_VERSION_REDUCER,
    }
  },
  addNewVersionSaga: (data) => {
    return {
      type: ADD_NEW_VESION_SAGA,
      data: data,
    }
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_ALL_SOP_REDUCER_STATE,
    }
  },
  callDefault: () => {
    return {
      type: CALL_DEFAULT_SOP_REDUCER,
    };
  },
};

export default SOPAction;
