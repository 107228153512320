import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PAGING_PRODUCT_SAGA,
  GET_PAGING_MATERIAL_BY_ROLE_USER_SAGA,
  DELETE_PRODUCT_SAGA,
  CREATE_PRODUCT_SAGA,
  UPDATE_PRODUCT_SAGA,
  GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_SAGA,
  GET_LIST_DATA_PRODUCT_SAGA, GET_LIST_PRODUCT_SAGA,
  GET_LIST_PRODUCT_NOT_IN_BARCODE_SAGA
} from "./enum/TypeCatProductAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import CatProductAction from "./CatProductAction";
import {
  getPagingService,
  getPagingByRoleUserService,
  deleteCatProductService,
  createCatProductService,
  updateCatProductService,
  getListDataProductByUnitCodeService, getListCatProductService, getListCatProductNotInBarcodeService
} from "./CatProductService";

export function* getPagingSaga(data) {
  try {
    const request = {
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
      SearchTerm: data.searchTerm,
      ListProductGroup: data.productGroupId,
      ListActive: data.isActive,
      ListProductType: data.productType,
      PlanningId:data.planningId
    };
    const response = yield call(getPagingService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          CatProductAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm
          )
        );
      } else {
        yield put(CatProductAction.getPagingReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

//Saga lấy danh sách VTYT có phân quyền dữ liệu
export function* getPagingByRoleUserSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      searchTerm: searchTerm
    };
    const response = yield call(getPagingByRoleUserService, request);
    if (response) {
      if (response.data.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
            CatProductAction.getPagingByRoleUserSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm
          )
        );
      } else {
        // yield put(CatProductAction.getPagingReducer(response));
        yield put(CatProductAction.getPagingByRoleUserReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* deleteCatProductSaga(request) {
  try {
    if (request.id) {
      const response = yield call(deleteCatProductService, request.id);
      if (response.isSuccess) {
        yield put(CatProductAction.deleteDataReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* createCatProductSaga(requet) {
  try {
    if (requet) {
      const requestAPI = {
        Id: requet?.data?.Id,
        Code: requet?.data?.Code?.trim(),
        Name: requet?.data?.Name?.trim(),
        UnitCode: requet?.data?.UnitCode?.trim(),
        Type: requet?.data?.Type,
      };
      const response = yield call(createCatProductService, requestAPI);
      if (response.isSuccess) {
        yield put(CatProductAction.callCreateUpdateSuccessReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export function* updateCatProductSaga(requet) {
  try {
    if (requet?.data) {
      const requestAPI = {
        Id: requet?.data?.Id,
        Code: requet?.data?.Code?.trim(),
        Name: requet?.data?.Name?.trim(),
        UnitCode: requet?.data?.UnitCode?.trim(),
        Type: requet?.data?.Type,
      };
      const response = yield call(updateCatProductService, requestAPI);
      if (response.isSuccess) {
        yield put(CatProductAction.callCreateUpdateSuccessReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListDataProductByUnitCodeSaga(request) {
  try {
    const response = yield call(
      getListDataProductByUnitCodeService,
      request.data
    );
    if (response.isSuccess) {
      yield put(
        CatProductAction.getListDataProductByUnitCodeReducer(response)
      );
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* getListSaga(data) {
  try {
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    const id = data.id ? data.id : "";
    let listIdNotSelect = data.listIdNotSelect ? data.listIdNotSelect : []
    const request = {
      searchTerm: searchTerm,
      listIdNotSelect: listIdNotSelect,
      id: id
    };
    const response = yield call(getListCatProductService, request);
    if (response) {
      yield put(CatProductAction.getListReducer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}


export function* getListProductNotInBarcodeSaga(data) {
  try {
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    const id = data.id ? data.id : "";
    let listIdNotSelect = data.listIdNotSelect ? data.listIdNotSelect : []
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const request = {
      pageSize: pageSize,
      pageIndex: pageIndex,
      searchTerm: searchTerm,
      listIdNotSelect: listIdNotSelect,
      id: id
    };
    const response = yield call(getListCatProductNotInBarcodeService, request);
    if (response) {
      yield put(CatProductAction.getListReducer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}




export default function* CatProductManagementSaga(data) {
  yield takeEvery(GET_PAGING_PRODUCT_SAGA, getPagingSaga);
  yield takeEvery(GET_PAGING_MATERIAL_BY_ROLE_USER_SAGA, getPagingByRoleUserSaga);
  yield takeEvery(DELETE_PRODUCT_SAGA, deleteCatProductSaga);
  yield takeEvery(CREATE_PRODUCT_SAGA, createCatProductSaga);
  yield takeEvery(UPDATE_PRODUCT_SAGA, updateCatProductSaga);
  yield takeEvery(
    GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_SAGA,
    getListDataProductByUnitCodeSaga
  );
  yield takeEvery(GET_LIST_PRODUCT_SAGA, getListSaga);
  yield takeEvery(GET_LIST_PRODUCT_NOT_IN_BARCODE_SAGA, getListProductNotInBarcodeSaga);
}
