export const packingLevelLag = {
    language: "vi",
    packingLevel: {
        packTitle: "Cấp độ đóng gói",
        notify:"Cấp độ đóng gói được xếp hạng cao có thể chứa các cấp độ xếp hạng thấp hơn",
        table: {            
            rank: "Xếp hạng",
            nameLevel:"Tên cấp độ",
            description:"Mô tả",
            action: "Thao tác",
            nodata: "Không có dữ liệu để hiển thị",
            title:"Thay đổi xếp hạng đóng gói"
        },
        form: {
            add_new:"Thêm mới cấp độ đóng gói",
            update:"Chỉnh sửa cấp độ đóng gói",
            nameLevel:"Tên cấp độ",
            description: "Mô tả",
            placeholder: {
                nameLevel: "Nhập tên cấp độ",
                description:"Nhập mô tả",
            }
        },
        btn: {
            add_new: "Thêm mới",
            add: "Thêm",
            update:"Cập nhật",
            close: "Huỷ",
            deleteBack: "Quay lại",
            deleteSave:"Xoá",
        },
        validate: {
            name: {
                required: "Tên cấp độ không được bỏ trống",
                maxLength:"Tên cấp độ không được quá 50 kí tự"
            },
            description: {
                required: "Mô tả không được bỏ trống",
                maxLength:"Mô tả không được quá 255 kí tự",
            }
        },
        toast: {
            createSuccess: "Tạo mới cấp độ đóng gói thành công",
            updateSuccess: "Cập nhật cấp độ đóng gói thành công",
            deleteSuccess: "Xoá cấp độ đóng gói thành công",
            changeLevelSuccess: "Thay đổi xếp hạng thành công",
        },
        delete: {
            title:"Xoá cấp độ đóng gói",
            description:"Bạn có chắc chắn muốn xoá cấp độ đóng gói ",
        },
    }
}