import {
  GET_PAGING_CatUnitMeasure_SAGA,
  GET_PAGING_CatUnitMeasure_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
} from "./enum/TypeCatUnitMeasureAction";

const CatUnitMeasureAction = {
  getPagingSaga: (pageIndex, pageSize, searchTerm = "") => {
    return {
      type: GET_PAGING_CatUnitMeasure_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,

    };
  },

  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_CatUnitMeasure_REDUCER,
      data: data,
    };
  },

  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },

}

export default CatUnitMeasureAction;
