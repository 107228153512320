export const inventoryCheckLag = {
    language: "en",
    inventorychecklag: {
        chooseplan: "Choose a plan to start or end",
        reasonrequired: "Reason cannot be blank",
        unconfirmedex: "Unconfirmed or unconfirmed package information",
        validationsuccess: "Verification successful",
        packconfirmsuccess: "Confirmation of package information successful",
        enterlocation: "Enter location",
        enteritemcode: "Enter item code",
        enternameitem: "Enter item code",
        location: "Location",
        PLU: "Item number",
        goodsname: "Commodity name",
        numberbefor: "Number before inventory",
        enternumberbefor: "Enter pre-inventory quantity",
        chcekqty: "Checkable count",
        entercheckqty: "Enter checkable quantity",
        difference: "Difference",
        enterdifference: "Enter difference",
        storedlocation: "Storage Location",
        enterstoredlocation: "Enter storage location",
        belongregion: "Belonging to the region",
        enterbelongregion: "Enter region dependent",
        entergoodscode: "Enter goodcode",
        entergoodsname: "Enter commodity name",
        counter: "Counter, tally",
        entercounter: "Enter inventory and tally",
        tiem: "Time",
        entertime: "Enter time",
        reason: "Reason",
        enterreason: "Enter reason",
        enterpack: "Enter package code",
        system: "System Location",
        entersystem: "Enter system location",
        locationfond: "Location found",
        enterlocationfond: "Enter found location",
        confirmseason: "Confirm the general reason",
        missingitem: "Confirm 1 common reason for all missing items?",
        detailsinventory: "Inventory details",
        endinventory: "End inventory",
        infogeneral: "General Information",
        infobysloc: "Information persists by location",
        infopack: "Package information",
        confirmex: "Confirm Existence",
        end: "End",
        confirmpackinfor: "Confirm package information",
        status: "Status",
        classify: "Classify",
        inventory: "Inventory",
        tally: "Tally",
        rank: "Statistical rank",
        exlocation: "Execution Location",
        person: "The person in charge",
        esexcutiontime: "Estimated execution time",
        realtime: "Actual execution time",
        purpose: "Purpose",
        Statistical: "Statistics",
    }
}