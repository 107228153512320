import { REMOUNT_ROUTE_COMPONENT } from "./actionTypes";

const generatePostfix = () => {
  return new Date().getTime();
};

const initialState = {
  routeKeyPostfix: generatePostfix(),
};

export default function routerReducer (state = initialState, action) {
  switch (action.type) {
    case REMOUNT_ROUTE_COMPONENT:
      return {
        ...state,
        routeKeyPostfix: generatePostfix(),
      };
    default:
      return state;
  }
}
