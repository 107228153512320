export const accessoriesTypeLag = {
    language: "en",
    accessoriesType: {
        title:"Component Type Management",
        table: {
            Typename:"Component type",
            Description:"Description",
            Status:"Active",
            Action:"Action",
        },
        btnTitle:{
            seeDetail:"See detail",
            edit:"Edit",
            delete:"Delete",
        },
        search:{
            placeHolder:"Component type, description"
        },
        form: {
            create:"Create new type of components",
            update:"Update type of components",
            getDetail:"See detail type of components",
            Typename:"Component model name",
            Description:"Description",
            Status:"Active",
            placeHolder:{
                Typename:"Enter component model name",
                Description:"Enter description"
            }
        },
        delete: {
            title: "Delete component type",
            description: "Are you sure you want to delete this component type?",
        },
        error: {
            Typename: {
                specialCharacters:"Component model name Does not contain special symbols",
                required: "Component model name cannot be left blank",
                maxLength: "Component type name must not exceed 255 characters",
            },
            Description:{
                maxLength: "Description can't be more than 255 characters",
            },
        },
        toastJS: {
            creatSuccess:"Create a new successful component type",
            updateSuccess:"Component type update successful",
            deleteSuccess:"Successfully deleted the component type",
        }
    }
}