export const GET_LIST_ASSETMENT_TRANSFER = "GET_LIST_ASSETMENT_TRANSFER";
export const SAVE_LIST_ASSETMENT_TRANSFER = "SAVE_LIST_ASSETMENT_TRANSFER";

export const GET_DETAIL_ASSETMENT_TRANSFER = "GET_DETAIL_ASSETMENT_TRANSFER";
export const SAVE_DETAIL_ASSETMENT_TRANSFER = "SAVE_DETAIL_ASSETMENT_TRANSFER";
export const CLEAR_DETAIL_ASSETMENT_TRANSFER = "CLEAR_DETAIL_ASSETMENT_TRANSFER"

export const CREATE_ASSETMENT_TRANSFER = "CREATE_ASSETMENT_TRANSFER";
export const UPDATE_ASSETMENT_TRANSFER = "UPDATE_ASSETMENT_TRANSFER";
export const DELETE_ASSETMENT_TRANSFER = "DELETE_ASSETMENT_TRANSFER";
export const DELETE_ASSETMENT_TRANSFER_REDUCER = "DELETE_ASSETMENT_TRANSFER_REDUCER";