import axios from "axios";
import { closeLoading } from "./LoadingAction";
import toastJs from "./toastJs";
import { mgCookiesHelper } from '../utils/MESCookiesHelper';
import {
  loadUserFromStorage,
} from "src/shared/oidc-client-base/userService";
import { v4 as uuidv4 } from "uuid";
import { getApiErrorMessage } from "../utils/data";
let source = axios.CancelToken.source();

var CommonBase = {
  get(api, data, onSuccess, onError) {
    axios
      .get(api, {
        cancelToken: source.token,
        headers: {
          "RId": uuidv4()
        },
        params: data,
      })
      .then(function (response) {
        if (onSuccess) onSuccess(response.data);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return;
        }

        if (onError) onError(error);
        return error
      });
  },

  abort(message) {
    source.cancel(`${message}`);
    source = axios.CancelToken.source();
  },
  post(api, data, onSuccess, onError) {
    axios
      .post(api, data, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "application/json",
          // "RId": uuidv4()
        }
      })
      .then(function (response) {
        if (onSuccess) onSuccess(response.data);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return;
        }

        if (onError) onError(error);
      });
  },
  async postFile(api, data, onSuccess, onError) {
    let formData = new FormData();
    formData.append("files", data.files[0]);
    formData.append("typeFile", data.typeFile);
    axios
      .post(api, formData, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "multipart/form-data",
          "RId": uuidv4()
        }
      })
      .then(function (response) {
        if (onSuccess) onSuccess(response.data);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return;
        }

        if (onError) onError(error);
      });
  },
  async getAsync(api, data) {
    try {
      var result = await axios.get(
        api,
        {
          cancelToken: source.token,
          params: data,
          headers: {
            "Content-Type": "application/json", "RId": uuidv4()
          }
        }
      );
      return result.data;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      if (e.response.status == 401) {
        await loadUserFromStorage();
      } else {
        toastJs.error(getApiErrorMessage(e, "Lỗi hệ thống"));
        closeLoading();
      }
      return e;
    }
  },

  async getBlobAsync(api, data) {
    try {
      var result = await axios.get(
        api,
        {
          responseType: 'blob',
          cancelToken: source.token,
          params: data,
          headers: {
            "Content-Type": "application/json", "RId": uuidv4()
          }
        }
      );
      return result.data;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      if (e.response.status == 401) {
        await loadUserFromStorage();
      } else {
        toastJs.error(getApiErrorMessage(e, "Lỗi hệ thống"));
        closeLoading();
      }
      return e;
    }
  },

  async postAsync(api, data) {
    try {
      let result = await axios.post(api, data, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "application/json",
          "RId": uuidv4()
        }
      });
      return result.data;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      if (e?.response?.status == 401) {
        await loadUserFromStorage();
      } else {
        toastJs.error(getApiErrorMessage(e, "Lỗi hệ thống"));
        closeLoading();
      }
      // eslint-disable-next-line no-undef
      return e;
    }
  },
  async putAsync(api, data) {
    try {
      let result = await axios.put(api, data, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "application/json",
          "RId": uuidv4()
        }
      });
      return result.data;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      if (e?.response?.status == 401) {
        await loadUserFromStorage();
      } else {
        toastJs.error(getApiErrorMessage(e, "Lỗi hệ thống"));
        closeLoading();
      }
      // eslint-disable-next-line no-undef
      return e;
    }
  },
  async deleteAsync(api, data) {
    try {
      var result = await axios.delete(
        api,
        {
          params: data,
          cancelToken: source.token,
          headers: {
            "Content-Type": "application/json", "RId": uuidv4()
          }
        }
      );
      return result.data;
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      if (e?.response?.status == 401) {
        await loadUserFromStorage();
      } else {
        toastJs.error(getApiErrorMessage(e, "Lỗi hệ thống"));
        closeLoading();
      }
      // eslint-disable-next-line no-undef
      return e;
    }
  },
  setAuthHeader(token) {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : "";
  },
  setLanguaueHeader(lang) {
    mgCookiesHelper.set("language", lang, 30);
    axios.defaults.headers.common["language"] = lang;
  },
  async postAsyncExportExcel(api, data) {
    try {
      let result = await axios.post(api, data, {
        cancelToken: source.token,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });

      return {
        data: result.data,
        isSuccess: true,
      };
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }

      if (e.response.status == 401) {
        await loadUserFromStorage();
      } else {
        let mesError = e?.response?.data?.message
          ? e?.response?.data?.message
          : "lỗi";
        toastJs.error(mesError);
        closeLoading();
      }
      // eslint-disable-next-line no-undef
      return {
        data: "",
        isSuccess: false,
      };
    }
  },
};

export default CommonBase;
