import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_DROPDOWN_PRODUCT_PACKAGING_API,
    GET_DROPDOWN_PACKING_LEVEL_API,
    GET_PACKAGE_INFO_API,
    GET_PACKAGE_DETAIL_API,
    GET_BELONG_INFO_API,
    DELETE_PACKAGE_API,
} from "./enum/ProductPackagingTypeApi"
export async function getDropdownProductPackagingService() {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROPDOWN_PRODUCT_PACKAGING_API)
}
export async function getDropDownPackingLevelService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROPDOWN_PACKING_LEVEL_API + "/" + data)
}
export async function getPackageInfoService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_PACKAGE_INFO_API, data);
}
export async function getPackageDetailService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_PACKAGE_DETAIL_API + "/"+ data);
}
export async function getBelongInfoService(beLongCode, level) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_BELONG_INFO_API + "/" + beLongCode + "/" + level);
}
export async function deletePackageService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + DELETE_PACKAGE_API + "/" + data )
}