export const StoreLocationScreen = {
    language: "vi",
    StorageLang: {
        listgoodsempty: "Danh sách hàng hóa trống!",
        page: {
            StoreVoucher: "Phiếu cất hàng",
            CreateVoucher: "Tạo phiếu cất hàng",
            StoreGoods: "Cất hàng",
            TimeStoreGoods: "Thời gian cất hàng",
            hour: " giờ",
            minute: " phút",
            second: " giây",
            btn_success: "Hoàn tất",
            palaceHolderLocation: "Nhập vị trí cất",
            palaceHolderESL: "Nhập vị trí cất dự kiến",
            palaceHoderASL: "Nhập vị tí cất thực tế",
            palaceHoderPackage: "Nhập mã package",
            palaceHoderGoods: "Nhập mã hàng hoá",
            palaceHoderGoodsName: "Nhập mã tên hoá",
            backtitle: "Cất hàng vào",
            numberVoucher: "Phiếu cất kho số",
            staffName: "Nhân viên cất hàng",
            Undefined: "Chưa xác định",
            completed: "Hoàn tất",
        },
        form: {
            titleDelete: "Xác nhận cất hàng",
            textConfirm: "Bạn có chắc muốn cất hàng vào kho?",
        },
        table: {
            ExpectedStorageLocation: "Vị trí cất dự kiến",
            ActualStorageLocation: "Vị trí thực tế",
            PackageCode: "Mã package",
            GoodsCode: "Mã hàng hoá",
            GoodsName: "Tên hàng hoá",
            ChosenToTakeAway: "Đã chọn để mang đi cất",
            ScanPackage: "Số package đã lấy",
            EstimatedTimeOfStorage: "Thời gian dự kiến",
            NumberOfLocationsToMoveToForStorage: "Số vị trí di chuyển tới để cất hàng",
            titleTableCurren: "Package thực tế được cất tại",
            titleTableLastest: "Package dự kiến được cất tại",
            packageandlocation: "Package cất tại vị trí",
            packageScanLastest: "Package code scan gần nhất",
            Action: "Thao tác",
        },
        notification: {
            f1: "Hiện tại có 1 phiếu kho đang được thực hiện"
        },
        toastJs: {
            includedNo: "không thuộc phiếu cất hàng",
            Scanned: "Đã được quét trước đó",
            Package: "Package này đã có trong danh sách cất hàng",
            complete: "Đã hoàn thành phiếu cất hàng",
            storageLocation: "Vị trí cất không được bỏ trống",
            packageNo: "Package này không tồn tại",
            successfulStorage: "Thành công cất hàng vào vị trí"
        },
        storeLocationCheck: {
            packageNo: "Package code không hợp lệ",
            noChoosePackage: "Chưa có package được chọn để đi cất",
            noList: "không có trong danh sách hoặc đã được chọn trước đó",
        }
    }
}