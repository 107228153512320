export const GET_PAGING_RETURN_SAGA = "GET_PAGING_RETURN_SAGA";

export const GET_PAGING_RETURN_REDUCER =
  "GET_PAGING_RETURN_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";


export const DELETE_RETURN_SAGA = "DELETE_RETURN_SAGA";
export const DELETE_RETURN_REDUCER = "DELETE_RETURN_REDUCER";


export const CREATE_RETURN_REDUCER = "CREATE_RETURN_REDUCER"
export const CREATE_RETURN_SAGA = "CREATE_RETURN_SAGA"
export const UPDATE_RETURN_REDUCER = "UPDATE_RETURN_REDUCER"
export const UPDATE_RETURN_SAGA = "UPDATE_RETURN_SAGA"

export const GET_INFO_RETURN_BY_ID_REDUCER = 'GET_INFO_RETURN_BY_ID_REDUCER'
export const RESET_GET_INFO_RETURN_BY_ID_REDUCER = 'RESET_GET_INFO_RETURN_BY_ID_REDUCER'
export const GET_INFO_RETURN_BY_ID_SAGA = 'GET_INFO_RETURN_BY_ID_SAGA'

export const GET_PAGING_RETAIL_RETURN_SAGA = "GET_PAGING_RETAIL_RETURN_SAGA";

export const GET_PAGING_RETAIL_RETURN_REDUCER =
  "GET_PAGING_RETAIL_RETURN_REDUCER";

export const SET_TRANSACTION_DETAIL_LIST_REDUCER = "SET_TRANSACTION_DETAIL_LIST_REDUCER";

export const RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA = "RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA";

export const RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER =
  "RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER";