import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_PAGING_WLP_API, GET_DROPDOWN_PROCESS_API, GET_DROPDOWN_PRODUCT_PROCESS_API, GET_DATA_TABLE_API, GET_NEW_LINK_API, GET_DETAIL_LINK_API, DELETE_LINK_API,
    UPDTATE_LINK_API
} from "./enum/WorkareaLinkProccessTypeApi";
export async function getPagingService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_PAGING_WLP_API, request);
}
export async function getDropdownProcessService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROPDOWN_PROCESS_API + request);
}
export async function getDropdownProductService() {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROPDOWN_PRODUCT_PROCESS_API);
}
export async function getDataTableService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DATA_TABLE_API, request);
}
export async function createNewService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_NEW_LINK_API, request);
}
export async function getDetailService(id) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DETAIL_LINK_API + id);
}
export async function deleteLinkService(id) {
    return await CommonBase.postAsync(configApp.apiGateWay + DELETE_LINK_API + id);
}
export async function updateLinkService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + UPDTATE_LINK_API, request);
}
