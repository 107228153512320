import {
    GET_MENU_PERMISSION_REDUCER,
    GET_MENU_PERMISSION_SAGA, SET_MENU_SELECT_PERMISSION_REDUCER
} from "./enum/permissionEnumAction";

const PermissionAction = {

    getMenuPermissionSaga: (userID) => {
        return {
            type: GET_MENU_PERMISSION_SAGA,
            request: userID,
        };
    },
    getMenuPermissionReducer: (userID) => {
        return {
            type: GET_MENU_PERMISSION_REDUCER,
            response: userID,
        };
    },

    setMenuSelect: (data) => {
        return {
            type: SET_MENU_SELECT_PERMISSION_REDUCER,
            data
        };
    },



}

export default PermissionAction;
