// //-------------- approve default api ------------
export const GET_ALL_APPROVE_DEFAULT_API = "/api/production-management-service/approvalflow-default/get-all"
export const CREATE_APPROVE_DEFAULT_API = "/api/production-management-service/approvalflow-default/create-approvalflow-default"
export const UPDATE_APPROVE_DEFAULT_API = "/api/production-management-service/approvalflow-default/update-approvalflow-default"
export const DELETE_APPROVE_DEFAULT = "/api/production-management-service/approvalflow-default/delete-approvalflow-default/"
export const GET_DETAIL_APPROVE_DEFAULT = "/api/production-management-service/approvalflow-default/get-detail/"
export const CREATE_APPROVAL = "/api/production-management-service/approval/create-approval"



