import {
  GET_PAGING_WLP_SAGA, GET_PAGING_WLP_REDUCER, GET_DROPDOWN_PROCESS_BY_PRODUCT_CODE_SAGA, GET_DROPDOWN_PROCESS_REDUCER, CREATE_NEW_SAGA, GET_DETAIL_WLP_SAGA,
  GET_DROPDOWN_PRODUCT_PROCESS, GET_DROPDOWN_PRODUCT_PROCESS_REDUCER, GET_DATA_TABLE_SAGA, GET_DATA_TABLE_REDUCER, CHANGE_DATA_REDUCER, CALL_API_CREATE_OR_UPDATE,
  SET_OBJ_DETAIL, CHANGE_DATA_DEFAULT_REDUCER, DELETE_WLP_SAGA, UPDATE_LINK_SAGA
} from "./enum/WorkareaLinkProccessTypeAction";

const WorkareaLinkProccessAction = {
  getPagingSaga: (request) => {
    return {
      type: GET_PAGING_WLP_SAGA,
      request: request,
    };
  },
  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_WLP_REDUCER,
      data: data,
    }
  },
  getDetailSaga: (id) => {
    return {
      type: GET_DETAIL_WLP_SAGA,
      id: id,
    }
  },
  setObjDetail: (data) => {
    return {
      type: SET_OBJ_DETAIL,
      data: data,
    }
  },
  getDropdownProcessSaga: (code) => {
    return {
      type: GET_DROPDOWN_PROCESS_BY_PRODUCT_CODE_SAGA,
      code: code,
    }
  },
  setDropdownProcessReducer: (data) => {
    return {
      type: GET_DROPDOWN_PROCESS_REDUCER,
      data: data,
    }
  },
  getDropdownProductSaga: () => {
    return {
      type: GET_DROPDOWN_PRODUCT_PROCESS,
    }
  },
  setDropdownProductReducer: (data) => {
    return {
      type: GET_DROPDOWN_PRODUCT_PROCESS_REDUCER,
      data: data,
    }
  },
  getDataTableSaga: (ProcessId, WorkcenterId) => {
    return {
      type: GET_DATA_TABLE_SAGA,
      ProcessId: ProcessId,
      WorkcenterId: WorkcenterId,
    }
  },
  setDataTableReducer: (data) => {
    return {
      type: GET_DATA_TABLE_REDUCER,
      data: data,
    }
  },
  renderNewInfor: (data) => {
    return {
      type: CHANGE_DATA_REDUCER,
      data: data,
    }
  },
  createNewSaga: (data, request) => {
    return {
      type: CREATE_NEW_SAGA,
      data: data,
      request: request,
    }
  },
  setCallAPIsuccess: () => {
    return {
      type: CALL_API_CREATE_OR_UPDATE,
    }
  },
  setDefaultReducer: () => {
    return {
      type: CHANGE_DATA_DEFAULT_REDUCER,
    }
  },
  deleteLinkSaga: (id, request) => {
    return {
      type: DELETE_WLP_SAGA,
      id: id,
      request: request,
    }
  },
  updateLinkSaga: (obj, request) => {
    return {
      type: UPDATE_LINK_SAGA,
      obj: obj,
      request: request,
    }
  },
  callDefault: () => {
    return {
      type: "default",
    }
  },
}
export default WorkareaLinkProccessAction;