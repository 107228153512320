import { type } from "@testing-library/user-event/dist/type";
import {
  CREATE_USER_HOSPITAL_WAREHOUSE_REDUCER,
  CREATE_USER_HOSPITAL_WAREHOUSE_SAGA,
  CALL_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER,
  CLEAR_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER,
  UPDATE_USER_HOSPITAL_WAREHOUSE_REDUCER,
  UPDATE_USER_HOSPITAL_WAREHOUSE_SAGA,
  GET_USER_HOSPITAL_WAREHOUSE_BY_USER_SAGA,
  GET_USER_HOSPITAL_WAREHOUSE_BY_USER_REDUCER,
  GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_SAGA,
  GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_REDUCER,
  GET_USER_LIST_SAGA,
  GET_USER_LIST_REDUCER,
  GET_HOSPITAL_WAREHOUSE_LIST_SAGA,
  GET_HOSPITAL_WAREHOUSE_LIST_REDUCER,
  DELETE_USER_HOSPITAL_WAREHOUSE_SAGA,
  DELETE_USER_HOSPITAL_WAREHOUSE_REDUCER,
  GET_USER_LIST_BY_RULE_REDUCER,
  GET_USER_LIST_BY_RULE_SAGA,
  SET_LIST_USER_REDUCER,
} from "./enum/TypeUserHospitalWarehouseAction";
const UserHospitalWarehouseAction = {
  createUserHospitalWarehouseSaga: (data) => {
    return {
      type: CREATE_USER_HOSPITAL_WAREHOUSE_SAGA,
      data: data,
    };
  },
  createUserHospitalWarehouseReducer: () => {
    return {
      type: CREATE_USER_HOSPITAL_WAREHOUSE_REDUCER,
    };
  },

  updateUserHospitalWarehouseSaga: (data) => {
    return {
      type: UPDATE_USER_HOSPITAL_WAREHOUSE_SAGA,
      data: data,
    };
  },
  updateUserHospitalWarehouseReducer: () => {
    return {
      type: UPDATE_USER_HOSPITAL_WAREHOUSE_REDUCER,
    };
  },
  
  deleteUserHospitalWarehouseSaga: (data) => {
    return {
      type: DELETE_USER_HOSPITAL_WAREHOUSE_SAGA,
      data: data,
    };
  },
  deleteUserHospitalWarehouseReducer: () => {
    return {
      type: DELETE_USER_HOSPITAL_WAREHOUSE_REDUCER,
    };
  },

  getUserHospitalWarehouseByUserSaga: (data) => {
    return {
      type: GET_USER_HOSPITAL_WAREHOUSE_BY_USER_SAGA,
      data: data,
    };
  },
  getUserHospitalWarehouseByUserReducer: (data) => {
    return {
      type: GET_USER_HOSPITAL_WAREHOUSE_BY_USER_REDUCER,
      data: data,
    };
  },

  getUserHospitalWarehouseNameByUserSaga: (data) => {
    return {
      type: GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_SAGA,
      data: data,
    };
  },
  getUserHospitalWarehouseNameByUserReducer: (data) => {
    return {
      type: GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_REDUCER,
      data: data,
    };
  },

  //Lấy danh sách user
  getUserListSaga:(pageIndex, pageSize, searchTerm = "" ) => {
    return{
      type : GET_USER_LIST_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm
    }
  },
  getUserListReducer:(data) => {
    return{
      type : GET_USER_LIST_REDUCER,
      data:data
    }
  },
  getUserListByRoleSaga:(pageIndex, pageSize, searchTerm = "" ) => {
    return{
      type : GET_USER_LIST_BY_RULE_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm
    }
  },
  getUserListByRuleReducer:(data) => {
    return{
      type : GET_USER_LIST_BY_RULE_REDUCER,
      data:data
    }
  },
  getHospitalWarehouseListSaga:(userId) => {
    return{
      type : GET_HOSPITAL_WAREHOUSE_LIST_SAGA,
      UserId: userId
    }
  },
  getHospitalWarehouseListReducer:(data) => {
    return{
      type : GET_HOSPITAL_WAREHOUSE_LIST_REDUCER,
      data:data
    }
  },
  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER,
    };
  },
};

export default UserHospitalWarehouseAction;
