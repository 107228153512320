import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import * as typesApi from "./enum/AccessoriesTypeApi";

export async function getAll(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + typesApi.GET_ALL_ACCESSORIES_API,data);
}

export async function createData(data) {
  return await CommonBase.postAsync(configApp.apiGateWay + typesApi.CREATE_ACCESSORIES_API,data);
}

export async function updateData(data) {
  return await CommonBase.postAsync(configApp.apiGateWay + typesApi.UPDATE_ACCESSORIES_API,data);
}

export async function deleteData(productCode) {
  return await CommonBase.getAsync(configApp.apiGateWay + typesApi.DELETE_ACCESSORIES_API + productCode,"");
}
export async function getDetail(productCode) {
  return await CommonBase.getAsync(configApp.apiGateWay + typesApi.GET_DETAIL_ACCESSORIES_API + productCode,"");
}

