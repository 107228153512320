import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_ALL_BOM_API,
    CREATE_BOM_API,
    GET_DETAIL_BOM_API,
    GET_DROPDOWN_PRODUCT_API,
    GET_DROPDOWN_VERSION_BOM_API,
    DELETE_VERSION_BOM_API,
    ADD_VERSION_BOM_API,
    CHANGE_VERSION_BOM_API,
    GET_DESCRIPTION_MATERIAL_API
} from "./enum/BOMTypeApi";

export async function getPagingService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_ALL_BOM_API,
        data
    )
}
export async function createDataService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + CREATE_BOM_API,
        data
    )
}
export async function getDetailService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_DETAIL_BOM_API + request.bomid + '/' + request.verid + '?Keyseach=' + request.Keyseach
    );
}
export async function getDropdownProductService(searchtemp) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_DROPDOWN_PRODUCT_API + '?searchtemp=' + searchtemp
    );
}
export async function getDropdownVersionService(bomCode) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_DROPDOWN_VERSION_BOM_API + bomCode,
        ""
    );
}
export async function deleteVersionService(versionId) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + DELETE_VERSION_BOM_API + versionId,
        ""
    );
}
export async function addNewVersionBomService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + ADD_VERSION_BOM_API,
        request
    )
}
export async function changeVersionService(bomCode) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + CHANGE_VERSION_BOM_API + bomCode,
        ""
    );
}
export async function getDescriptionMarterialService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_DESCRIPTION_MATERIAL_API, request,
        ""
    );
}

