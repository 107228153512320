export const BomLag = {
    language: "en",
    bom: {
        searchBom: "BOM name, product name, version, status version",
        searchSlot: "Search Slot",
        table: {
            name: "Name BOM",
            product: "Product",
            version: "Current Version",
            createdDate: "Created Date",
            effectiveDate: "Effective Date",
            latestVersion: "Newest Version",
            lastestStatus: "Newest Status",
            slot: "Slot",
            descSlot: "Description Slot",
            code: "Code product or NVL",
            desc: "Description",
            amountConsumed: "Amount Consumed",
            amountCreated: "Amount Created",
            unit: "Unit",
            action: "Action",
            componentNumber: "Component number",
            emptyData: "No Data",
            changeversion: "Change conditions"
        },
        create: "Create",
        update: "Update BOM",
        detail: "Information detail BOM",
        changeVersion: "Change version",
        createNewBom: "Create new Bom",
        bomforProduct: "BOM for Product",
        description: "Description",
        createNewSlot: "Create new Slot",
        bomProduct: "Bom for Product",
        save: "Save",
        close: "Close",
        delete: "Delete Version",
        placeholder: "Select product",
        placeholderProduct: "Product description",
        confirm: "Confirm",
        cancel: "Cancel",
        version: "Version",
        rules: {
            productcode: "Product is required",
            descriptionSlot: "Slot description is required",
            length_DescriptionSlot: "Slot description should not exceed 500 characters",
            materialCode: "Material code is required",
            amount: "Amount is required",
            timeChange: "Change date is required",
            number1: "Quantity must be a number",
            number2: "Quantity must be greater than 0",
            length_number: "Quantity cannot exceed 25 characters"
        },
        slot: {
            descriptionSlot: "Enter description slot",
            amount: " Enter number ",
            marterial: "Select marterial",
            descriptionMaterial: "Material description",
            unit: " Enter unit"
        },
        toastjs: {
            no_browser_bom_one: "Only versions with a new or rejected status will be browsed",
            no_browser_bom_two: "There is no new version for browsing",
            noVersionChange: "There is no new version to change",
            no_choose_version: "Haven't selected BOM to change version",
            no_list_slot: "Slot list is required",
            create_success: "Successfully created new BOM",
            change_version_success: "Change version successfully",
            add_verion_success: "Successfully add new version",
            delete_version_success: "Deleted version successfully",
            no_change_version: "The new version has no changes compared to the old version",
            change_by_Operation: "Change only BOM conditional change by operation"
        },
        formVersion: {
            title: "Create a new version",
            marterialNew: "Code product or NVL new",
            descriptionNew: "New slot description"
        },
        changeVersionForm: {
            title: "Conditions to change version",
            material: "Product code or material",
            number: "Amount of materials",
            type1: "Change by operation",
            type2: "Change by time line",
            type3: "Change according to stock condition",
            error: "Haven't selected conditions to change version",
            addCondition: "More conditions",
            error_checkbox3: " Add stock condition"
        }
    }
}
