export const UPDATE_WORK_ORDER_REQUIREMENT = "UPDATE_WORK_ORDER_REQUIREMENT";
export const UPDATE_DROPDOWN_PRODUCTION_REQUIREMENT = "UPDATE_DROPDOWN_PRODUCTION_REQUIREMENT";
export const UPDATE_DROPDOWN_DEFAULT_PRODUCTION_REQUIREMENT = "UPDATE_DROPDOWN_DEFAULT_PRODUCTION_REQUIREMENT";
export const UPDATE_WORK_ORDER_DATA = "UPDATE_WORK_ORDER_DATA";

export const UPDATE_DROPDOWN_PRODUCT = "UPDATE_DROPDOWN_PRODUCT";
export const UPDATE_DROPDOWN_WORKCENTER = "UPDATE_DROPDOWN_WORKCENTER";

export const CONFIRM_SAVE_DATA_SUCCESS = "CONFIRM_SAVE_DATA_SUCCESS";
export const IS_DATA_CLEAR_PRO_REQUIREMENT_DONE = "IS_DATA_CLEAR_PRO_REQUIREMENT_DONE";
export const IS_DATA_CLEAR_WORK_ORDER_DONE = "IS_DATA_CLEAR_WORK_ORDER_DONE";
export const IS_CHECK_VALIDATE = "IS_CHECK_VALIDATE";
export const IS_DROPDOWN_PRO_REQUIREMENT_UPDATE_DONE = "IS_DROPDOWN_PRO_REQUIREMENT_UPDATE_DONE";
export const IS_SCHEDULE_UPDATE = "IS_SCHEDULE_UPDATE";
export const SCHEDULE_UPDATE_DONE = "SCHEDULE_UPDATE_DONE";
export const LET_SCHEDULE_UPDATE_WORK_ORDER = "LET_SCHEDULE_UPDATE_WORK_ORDER";
export const LET_SCHEDULE_UPDATE_WORK_ORDER_DONE = "LET_SCHEDULE_UPDATE_WORK_ORDER_DONE";

export const IS_SAVE_API = "IS_SAVE_API";

export const IS_SAVE_API_DONE = "IS_SAVE_API_DONE";

// enum action to call api
export const GET_PRODUCTION_REQUIRE_DETAIL_SAGA = "GET_PRODUCTION_REQUIRE_DETAIL_SAGA";
export const SET_PRODUCTION_REQUIRE_DETAIL_REDUCER = "SET_PRODUCTION_REQUIRE_DETAIL_REDUCER";
export const GET_LIST_WORKCENTER_SAGA = "GET_LIST_WORKCENTER_SAGA";
export const SET_LIST_WORKCENTER_REDUCER = "SET_LIST_WORKCENTER_REDUCER";
export const INSERT_DATA_SAGA = "INSERT_DATA_SAGA";
export const INSERT_DATA_REDUCER = "INSERT_DATA_REDUCER";

// update dữ liệu workorder : update code

export const UPDATE_WORKORDER_TABLE_REDUCER = "UPDATE_WORKORDER_TABLE_REDUCER";
//------------------ update code bổ sung tài liệu ---------------------------------------
export const UPDATE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION = "UPDATE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION";
export const DELETE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION = "DELETE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION";
export const SELECTED_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION = "SELECTED_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION";
export const REFRESH_PRODUCT_REQUIRED_INFORMAITON_SELECT = "REFRESH_PRODUCT_REQUIRED_INFORMAITON_SELECT";
export const CLEAR_LINK_PRODUCT_REQUIRED_INFORMATION = "CLEAR_LINK_PRODUCT_REQUIRED_INFORMATION";
export const UPDATE_CODE_WO = "UPDATE_CODE_WO_link_product_to_order";
//------------------ TẠO BẢNG THÔNG KÊ LỆNH SẢN XUẤT --------------------------------------
export const CREATE_LINK_PRODUCT_TO_PROCESS_SCHEDULE = "CREATE_LINK_PRODUCT_TO_PROCESS_SCHEDULE";
export const UPDATE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem = "UPDATE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem";
export const DELETE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem = "DELETE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem";
export const CLEAR_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem = "CLEAR_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem";

export const UPDATE_SCHEDULE_REFIX_V2 = "UPDATE_SCHEDULE_REFIX_V2_linkProduct_drag_item";
export const HANDLE_DELETE_ROW_TABLE_ORDER_PRODUCE = "HANDLE_DELETE_ROW_TABLE_ORDER_PRODUCE_PLAN_PRODUCE_SCHEDULE";

export const HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_SAGA = "HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_SAGA_linkProduct_drag_item";
export const HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_REDUCER = "HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_REDUCER_linkProduct_drag_item";



