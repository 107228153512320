import { call, put, takeEvery } from "redux-saga/effects";
import {
  UPDATE_INVENTORY_AUDIT_SAGA,
} from "./enum/TypeInventoryTicketsAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import {
  fetchInventoryAuditUpdateService,
} from "./InventoryTicketsService"

export function* updateSaga(data) {
  try {
    console.log("Data", data);
    
    const response = yield call(fetchInventoryAuditUpdateService, data.data);
    // if (response) {
    //   if (response.Items.length <= 0 && request.PageIndex > 1) {
    //     yield put(
    //       InventoryTicketsAction.getPagingSaga(
    //         request.PageIndex - 1,
    //         request.PageSize,
    //         request.searchTerm
    //       )
    //     );
    //   } else {
    //     yield put(InventoryTicketsAction.getPagingReducer(response));
    //   }
    // }
    // closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export default function* InventoryTicketSaga(data) {
  yield takeEvery(UPDATE_INVENTORY_AUDIT_SAGA, updateSaga);
}
