import { Skeleton } from "antd";
import _ from "lodash";
import React, { lazy, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import PermissionAction from "src/redux-saga-v2/Permission/permissionAction";
import "src/shared/scss/baseComponentMes/page.scss";
import LoadingJs from "../src/components/base/loadingJs";
import DefaultRoute from "../src/components/layout/routes/default-route";
import PrivateRoute from "../src/components/layout/routes/private-route";
import PublicRoute from "../src/components/layout/routes/public-route";
import CommonBase from "../src/shared/common/base";
import StringUtils from "../src/shared/common/StringUtils";
import Layout from "./components/layout/layout-main";
import OidcProvider from "./components/layout/OidcProvider";
import { listPageHiddenOnMenu } from "./PermissionData";
import loginAction from "./redux-saga/action/Login/loginAction";
import NotificationAction from "./redux-saga/action/Notification/NotificationAction";
import UserHospitalWarehouseAction from "../src/redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseAction";
import { useEffect, useState } from "react";
import userManager, {
  loadUserFromStorage,
  signinRedirect,
} from "./shared/oidc-client-base/userService";
import "./shared/scss/App.scss";
import { mgCookiesHelper } from "./shared/utils/MESCookiesHelper";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import {InformationUser} from "./shared/common/informationUser";
// import ReconcileReportListPage from "./components/page/ReconcileReportListPage";
// import ReconcileReportDetailsPage from "./components/page/ReconcileReportDetailsPage";

dayjs.extend(isSameOrAfter);

const SigninOidc = lazy(() => import("./components/page/Login/signin-oidc"));
const SignoutOidc = lazy(() => import("./components/page/Login/signout-oidc"));
const NotFoundPage = lazy(() => import("./components/page/NotFound/Index"));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      authenticated: true,
      listNotificaltion: [],
      pathName: "",
      GetmenuSuccess: 0,
      isOpenForm: true,
    };
    window.mesGetNewAccessToken = this.mesGetNewAccessToken.bind(this);
    this.routeKeyPostfixRef = React.createRef();
  }

  async componentDidMount() {
    if (typeof window !== "undefined") {
      window.document.title = "Hệ thống quản lý tài sản Vinmec";
    }
    if (window.location.pathname != "/signin-oidc") {
      let user = await loadUserFromStorage();
      if (user && user.access_token) {
        localStorage.setItem("access_token", user.access_token);
      }

      if (!user) {
        this.props.loginAction.unauthUser();
        signinRedirect();
      }
      else {
        this.props.loginAction.authUser(user);
        let userID = user.profile.sub;
        this.props.notificationAction.getAllNotifactionSaga(userID);
        // call get menu
        this.props.permissionAction.getMenuPermissionSaga(userID);
        //Lấy bệnh viện và kho được phân quyền ngay sau khi được mounted
        this.props.userHospitalWarehouseAction.getUserHospitalWarehouseByUserSaga(userID);
        await InformationUser.fetch(userID)
      }
    }
    this.setLanguaue();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.routerReducer.routeKeyPostfix !==
      this.routeKeyPostfixRef.current
    ) {
      this.routeKeyPostfixRef.current =
        this.props.routerReducer.routeKeyPostfix;
      return true;
    }

    let { authenticated } = nextProps.redecersLogin;
    let { listDataCompData } = nextProps.permissionReducer;
    if (authenticated != nextState.authenticated) {
      this.setState({ authenticated });
      return true;
    }
    if (
      listDataCompData &&
      listDataCompData.length > 0 &&
      nextState.GetmenuSuccess == 0
    ) {
      this.setState({ GetmenuSuccess: 1 });
      return true;
    }
    return false;
  }
  setLanguaue() {
    let lang = mgCookiesHelper.get("language");
    if (StringUtils.isNullOrEmpty(lang)) {
      lang = "vi";
    }
    CommonBase.setLanguaueHeader(localStorage.getItem("lag") ?? "vi");
    // CommonBase.setLanguaueHeader(lang);
  }

  async mesGetNewAccessToken() {
    let user = await userManager.signinSilent();
    if (!user) {
      this.props.loginAction.unauthUser();
      signinRedirect();
    } else {
      this.props.loginAction.authUser(user);
    }
  }

  componentDidUpdate() {
    if(!this.props.dataUserHospitalWarehouse) {
      loadUserFromStorage().then(user => {
        if(!user) {
          console.log("Không có dữ liệu user App.js")
          return
        }
        const userId = user.profile.sub;
        this.props.userHospitalWarehouseAction.getUserHospitalWarehouseByUserSaga(userId);
      })
    }
  }
  render() {
    let { authenticated } = this.state;
    const listAction = this.props?.permissionReducer.listDataCompData.map(
      (item) => item?.actionMenus
    );
    const flattenListAction = _.flattenDeep(listAction);
    const listAllAction = flattenListAction.map((action) => action?.ActionName);
    const listHiddenAccessiblePage = _.filter(listPageHiddenOnMenu, (page) =>
      _.includes(listAllAction, page.permissionRequired)
    );
    const listAllMenu = _.concat(
      listHiddenAccessiblePage,
      this.props?.permissionReducer.listDataCompData
    );

    return this.state.isLoading ? null : (
      <BrowserRouter>
        <LoadingJs />

        <OidcProvider userManager={userManager} />
        {/* <Chat /> */}
        <Switch>
          <DefaultRoute exact path="/" authenticated={authenticated} />
          <Route exact path="/signout-oidc" RouteComp={SignoutOidc} />

          <Route path="/signin-oidc" component={SigninOidc} />
          <Layout exact>
            <Suspense fallback={<Skeleton active />}>
              <Switch>
                {listAllMenu && listAllMenu.length > 0
                  ? listAllMenu
                      .filter((comp) => typeof comp.comPath === "string")
                      .map((comp) => {
                        let pathcomR = comp.comPath.replace("src/", "./");
                        return (
                          <PrivateRoute
                            key={`${comp.path}-${this.props.routerReducer.routeKeyPostfix}`}
                            exact
                            path={comp.path}
                            RouteComp={lazy(() => import(`${pathcomR}`))}
                            authenticated={authenticated}
                          />
                        );
                      })
                  : null}

                <PrivateRoute
                  exact
                  RouteComp={NotFoundPage}
                  authenticated={authenticated}
                />
              </Switch>
            </Suspense>
          </Layout>
        </Switch>
      </BrowserRouter>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loginAction: bindActionCreators(loginAction, dispatch),
  notificationAction: bindActionCreators(NotificationAction, dispatch),
  permissionAction: bindActionCreators(PermissionAction, dispatch),
  userHospitalWarehouseAction: bindActionCreators(
    UserHospitalWarehouseAction,
    dispatch
  ),
});

const mapStateToProps = (state) => {
  return {
    redecersLogin: {
      authenticated: state.loginReducer.authenticated,
      userInfo: state.loginReducer.userInfo,
      isCallResult: state.NotificaionReducer.isCallResult,
      listNotificaltion: state.NotificaionReducer.listNotificaltion,
    },
    permissionReducer: {
      listDataCompData: state.PermissionReducer.listDataCompData,
    },
    routerReducer: {
      routeKeyPostfix: state.routerReducer.routeKeyPostfix,
    },
    dataUserHospitalWarehouse:
      state.UserHospitalWarehouseReducer.dataUserHospitalWarehouse,
    dataDataUnitWarehouse: state.UserHospitalWarehouseReducer.dataDataUnitWarehouse,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
