export const poLag = {
    language: "en",
    po: {
        ordercode: "Order code",
        supplier: "Supplier",
        dateorder: "Order date",
        esdate: "Estimated delivery date",
        orderer: "Orderer",
        deleverystatus: "Delivery Status",
        createdoerror: "PO has completed delivery, cannot generate DO",
        canteditpo: "PO has a transaction that cannot be corrected",
        cantdeletepo: "PO that already has a transaction that cannot be deleted",
        ordermanager: "Order Management - PO",
        placerhoder: "Order number, supplier name, delivery status",
        confirmdelete: "Delete PO",
        deletequestions: "Are you sure you want to delete this PO?",
        form: {
            validatepocode: "The generated code contains no special characters or spaces",
            pocoderequired: "Order code cannot be blank",
            pocodelength: "Order code cannot exceed 50 characters",
            partnercoderequired: "Providers cannot be empty",
            dateorderrequired: "Order date cannot be blank",
            datadeleveryrequired: "Delivery date cannot be blank",
            qtyorderwrong: "Must enter a positive integer",
            qtyorderrequired: "The order quantity cannot be left blank",
            goodscoderequied: "Item number cannot be blank",
            number0: "The number of orders must be greater than 0",
            numberthannumber: "The quantity received must be less than or equal to the quantity ordered",
            comparedate: "Estimated delivery date cannot be before order date",
            listnull: "Order requires items",
            commoncode: "Commodity codes",
            description: "Description of goods",
            qtyorder: "Order Quantity",
            qtyreveiced: "Amount received",
            createpo: "Create Order - PO",
            updatepo: "Update Order - PO",
            detailpo: "View Details - PO",
            addrow: "Item added",
        },
        toastJs: {
            createOK: "New PO data added successfully",
            updateOK: "PO data update successful",
            deleteOK: "Deleted PO data successfully"
        }
    }
}