import * as type from "./enum/enumAction";
const stateDefault = {
    // action api
    isActionCallApi: false,

    // check action api
    isCheckActionApi: false,
    checkActionResult: false,
    checkActionAction: "",
    checkActionWorkordercode: "",
    // update wo
    isUpdateWo: false,
    // getDetail wo
    isGetDetailWo: false,
    entity: {},
    typeRemained: "",
    //temp
    dataSaved: [],
    isDataSaved: false,
    isDataPagingCallsuccess: false,
    dataPaging: [],

    isUpdateByDragItemSuccess: false,

    isScheduleUpdate: false,
    scheduleUpdateData: {},
}
const POCoordinateReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case type.SET_DATA_PAGING_REDUCER:
            state.isDataPagingCallsuccess = true;
            state.dataPaging = action.data;
            return {
                ...state
            }
        case type.CALL_ACTION_REDUCER:
            state.isActionCallApi = true;
            return {
                ...state
            }
        case type.CHECK_ACTION_REDUCER:
            state.isCheckActionApi = true;
            state.checkActionResult = action.result;
            state.checkActionAction = action.Action;
            state.checkActionWorkordercode = action.Workordercode;

            return {
                ...state
            }
        case type.GET_WORK_ORDER_DETAIL_REDUCER:
            state.isGetDetailWo = true;
            state.entity = action.entity;
            state.typeRemained = action.typeRemained;
            return {
                ...state
            }
        case type.SAVED_DATA:
            state.isDataSaved = action.isSaved;
            if (state.isDataSaved) state.dataSaved = action.data;
            return {
                ...state
            }

        case type.UPDATE_WORK_ORDER_REDUCER:
            state.isUpdateWo = true;
            return {
                ...state
            }
        case type.UPDATE_BY_DRAG_ITEM_REDUCER:
            state.isUpdateByDragItemSuccess = true;
            return {
                ...state
            }
        case type.UPDATE_BY_DRAG_ITEM_DONE:
            state.isUpdateByDragItemSuccess = false;
            return {
                ...state
            }
        case type.UPDATE_SCHEDULE_REDUCER:
            state.isScheduleUpdate = true;
            state.scheduleUpdateData = action.data;
            return {
                ...state
            }
        case type.UPDATE_SCHEDULE_DONE:
            state.isScheduleUpdate = false;
            return {
                ...state
            }
        default:
            state = stateDefault;
            return {
                ...state
            }
    }
}
export default POCoordinateReducer;