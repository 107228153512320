import { toast } from 'react-toastify';
import i18next from 'i18next';
import { call, put, take, takeEvery } from 'redux-saga/effects';
import { closeLoading } from 'src/shared/common/LoadingAction';
import toastJs from 'src/shared/common/toastJs';
import ResultInventoryAction from './ResultInventoryAction';
import {
    getAssetmentInventoryResultService,
    getDetailInventoryResultService,
    getAssetmentInventoryInfoService,
    updateAssetmentInventoryInfoService,
    getSummaryResultInventoryService,
    getInventoryAssetScanService,
    submitReportService,
    confirmationInventoryCompletionService,
    createManualInventoryAssetService,
    exportExcelInventoryResultDetailService,
    exportExcelInventoryAssetInformationService,
} from './ResultInventoryService.js';
import dayjs from "dayjs";
import {
    GET_ASSETMENT_INVENTORY_RESULTS_SAGA,
    GET_DETAIL_INVENTORY_RESULT_SAGA,
    GET_ASSETMENT_INVENTORY_INFO_SAGA,
    UPDATE_ASSETMENT_INVENTORY_INFO_SAGA,
    GET_SUMMARY_RESULT_INVENTORY_SAGA,
    GET_INVENTORY_ASSET_SCAN_SAGA,
    INVENTORY_ASSET_SUBMIT_REPORT_SAGA,
    INVENTORY_COMPLETION_CONFIRMATION_SAGA,
    CREATE_MANUAL_INVENTORY_ASSET_SAGA,
    EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_SAGA,
    EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_SAGA
} from './enum/TypeResultInventoryAction.js';
import _ from 'lodash';
import queryString from 'query-string';
import { workFlowTypeList } from 'src/shared/constants/enum/constants';
import moment from 'moment';

export function* getAssetmentInventoryResultSaga(data) {
    try {
        const request = {
            SearchTerm: data.searchText,
            PageIndex: data.pageIndex,
            PageSize: data.pageSize,
            ListStatus: data.objectFilter.ListStatus,
            CreatedDateFrom: data.objectFilter.CreatedDateFrom ? data.objectFilter.CreatedDateFrom : "",
            CreatedDateTo: data.objectFilter.CreatedDateTo ? data.objectFilter.CreatedDateTo : "",
            ListSegmentId: data.objectFilter.ListSegmentId
        }
        const response = yield call(getAssetmentInventoryResultService, request);
        if (response) {
            yield put(ResultInventoryAction.getAssetmentInventoryResultReducer(response))
        }
    }
    catch (error) {
        closeLoading();
    }
}
export function* getDetailInventoryResultSaga(data) {
    try {
        const request = {
            PageIndex: data.pageIndex,
            PageSize: data.pageSize,
            ListStatus: data.objectFilter.ListStatus,
            ListEvaluationGroup5Id: data.objectFilter.ListEvaluationGroup5Id,
            ListCostCenterId: data.objectFilter.ListCostCenterId,
            InventoryPlanId: parseInt(data.InventoryPlanId)
        }
        const response = yield call(getDetailInventoryResultService, request);
        if (response) {
            yield put(ResultInventoryAction.getDetailInventoryResultReducer(response))
        }
    }
    catch (error) {
        closeLoading();
    }
}

export function* getAssetmentInventoryInfoSaga(data) {
    try {
        const request = {
            InventoryPlanSessionItemId: parseInt(data.Id),
            PageSize: data.PageSize,
            PageIndex: data.PageIndex,
            SearchTerm: data.SearchTerm,
            TypeDifference: data.TypeDifference == null ? 0 : data.TypeDifference
        }
        const response = yield call(getAssetmentInventoryInfoService, request);
        if (response) {
            yield put(ResultInventoryAction.getAssetmentInventoryInfoReducer(response))
        }
    }
    catch (error) {
        closeLoading();
    }
}
export function* updateAssetmentInventoryInfoSaga(data) {
    try {
        const request = data.data;
        const response = yield call(updateAssetmentInventoryInfoService, request);
        if (response.isSuccess) {
            yield put(ResultInventoryAction.updateAssetmentInventoryInfoReducer())
        }
    }
    catch (error) {
        closeLoading();
    }
}
export function* getSummaryResultInventorySaga(data) {
    try {
        const response = yield call(getSummaryResultInventoryService, data.Id);
        if (response.isSuccess) {
            yield put(ResultInventoryAction.getSummaryResultInventoryReducer(response))
        }
    }
    catch (error) {
        closeLoading();
    }
}

export function* getInventoryAssetScanSaga(data) {
    try {
        const request = {
            inventoryPlanSessionItemId: data.inventoryPlanSessionItemId,
            assetId: data.assetId,
            type: data.types,
        }
        const response = yield call(getInventoryAssetScanService, request);
        if (response.isSuccess) {
            yield put(ResultInventoryAction.getInventoryAssetScanReducer(response))
        }
    }
    catch (error) {
        closeLoading();
    }
}

export function* submitReportSaga(data) {
    try {
        const request = {
            InventoryPlanId: parseInt(data.InventoryPlanId)
        }
        const response = yield call(submitReportService, request);
        if (response.isSuccess) {
            yield put(ResultInventoryAction.submitReportReducer())
        }
    }
    catch (error) {
        closeLoading();
    }
}
export function* confirmationInventoryCompletionSaga(data) {
    try {
        const request = {
            InventoryPlanSessionItemId: data.InventoryPlanSessionItemId
        }
        const response = yield call(confirmationInventoryCompletionService, request);
        if (response.isSuccess) {
            yield put(ResultInventoryAction.confirmationInventoryCompletionReducer())
        }
    }
    catch (error) {
        closeLoading();
    }
}
export function* createManualInventoryAssetSaga(data) {
    try {
        const request = {
            InventoryPlanSessionItemId: data.InventoryPlanSessionItemId,
            AssetId: data.AssetId
        }
        const response = yield call(createManualInventoryAssetService, request);
        if (response.isSuccess) {
            yield put(ResultInventoryAction.createManualInventoryAssetReducer())
        }
    }
    catch (error) {
        closeLoading();
    }
}

export function* exportExcelInventoryResultDetailSaga(data) {
    try {
        let request = {
            SearchTerm: "",
            PageIndex: 1,
            PageSize: 10,
            InventoryPlanId: parseInt(data?.request?.InventoryPlanId),
            ListEvaluationGroup5Id: data?.request?.ListEvaluationGroup5Id,
            ListCostCenterId: data?.request?.ListCostCenterId,
            ListStatus: data?.request?.ListStatus
        };

        let result = yield call(exportExcelInventoryResultDetailService, request);
        let url = window.URL
            .createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = `AMS_Chi_Tiet_Ket_Qua_Kiem_Ke_${data?.request?.InventoryPlanCode}_${moment().format("DDMMYYYY")}.xlsx`;

        link.click();
        if (url) {
            url = URL.revokeObjectURL(url);
        }
        closeLoading();
    }
    catch (error) {
        closeLoading();
    }
}

export function* exportExcelInventoryAssetInformationSaga(data) {
    try {
        let request = {
            SearchTerm: data?.request?.SearchTerm,
            InventoryPlanSessionItemId: data?.request?.InventoryPlanSessionItemId,
            TypeDifference: data?.request?.TypeDifference == null ? 0 : data?.request?.TypeDifference,
            PageIndex: 1,
            PageSize: 10,
        };

        let result = yield call(exportExcelInventoryAssetInformationService, request);
        let url = window.URL
            .createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = `AMS_Thong_Tin_Tai_San_Kiem_Ke_${data?.request?.InventoryPlanCode}_${moment().format("DDMMYYYY")}.xlsx`;

        link.click();
        if (url) {
            url = URL.revokeObjectURL(url);
        }
        closeLoading();
    }
    catch (error) {
        closeLoading();
    }
}

export default function* ResultInventorySaga() {
    yield takeEvery(GET_ASSETMENT_INVENTORY_RESULTS_SAGA, getAssetmentInventoryResultSaga);
    yield takeEvery(GET_DETAIL_INVENTORY_RESULT_SAGA, getDetailInventoryResultSaga);
    yield takeEvery(GET_ASSETMENT_INVENTORY_INFO_SAGA, getAssetmentInventoryInfoSaga);
    yield takeEvery(UPDATE_ASSETMENT_INVENTORY_INFO_SAGA, updateAssetmentInventoryInfoSaga)
    yield takeEvery(GET_SUMMARY_RESULT_INVENTORY_SAGA, getSummaryResultInventorySaga)
    yield takeEvery(GET_INVENTORY_ASSET_SCAN_SAGA, getInventoryAssetScanSaga)
    yield takeEvery(INVENTORY_ASSET_SUBMIT_REPORT_SAGA, submitReportSaga)
    yield takeEvery(INVENTORY_COMPLETION_CONFIRMATION_SAGA, confirmationInventoryCompletionSaga)
    yield takeEvery(CREATE_MANUAL_INVENTORY_ASSET_SAGA, createManualInventoryAssetSaga)
    yield takeEvery(EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_SAGA, exportExcelInventoryResultDetailSaga)
    yield takeEvery(EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_SAGA, exportExcelInventoryAssetInformationSaga)
}
