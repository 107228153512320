export const BomLag = {
    language: "vi",
    bom: {
        searchBom: "Tên BOM, sản phẩm, version, trạng thái version",
        searchSlot: "Mô tả Slot, mã sản phẩm",
        table: {
            name: "Tên BOM",
            product: "Sản phẩm",
            version: "Version đang sử dụng",
            createdDate: "Ngày tạo",
            effectiveDate: "Ngày hiệu lực",
            latestVersion: "Version mới nhất",
            lastestStatus: "Trạng thái version mới nhất",
            action: "Thao tác",
            slot: "Slot",
            descSlot: "Mô tả Slot",
            code: "Mã sản phẩm hoặc NVL",
            desc: "Mô tả",
            amountConsumed: "Sl tiêu hao",
            amountCreated: "Sl sản phẩm tạo ra",
            unit: "Đơn vị",
            componentNumber: "Linh kiện số",
            emptyData: "Không có dữ liệu để hiển thị",
            changeversion: "ĐK đổi version"
        },
        create: "Thêm mới",
        update: "Cập nhật BOM",
        detail: "Xem thông tin BOM",
        changeVersion: "Đổi version",
        createNewBom: "Tạo mới BOM",
        bomProduct: "BOM cho sản phẩm",
        description: "Mô tả ",
        createNewSlot: "Thêm mới Slot",
        save: "Lưu",
        close: "Đóng",
        delete: "Xóa phiên bản",
        placeholder: "Chọn sản phẩm",
        placeholderProduct: "Mô tả sản phẩm",
        confirm: "Xác nhận",
        cancel: "Hủy",
        version: "Phiên bản",
        rules: {
            productcode: "Sản phẩm không được bỏ trống",
            descriptionSlot: "Mô tả slot không được bỏ trống",
            length_DescriptionSlot: "Mô tả slot không được quá 500 ký tự",
            materialCode: "Mã NVL không được bỏ trống",
            amount: "SL không được bỏ trống",
            timeChange: "Thời gian không được bỏ trống",
            number1: "Số lượng phải là một số ",
            number2: "Số lượng phải lớn hơn 0 ",
            length_number: "Số lượng không được quá 25 ký tự"
        },
        slot: {
            descriptionSlot: "Nhập mô tả slot",
            amount: "Nhập số lượng",
            marterial: "Chọn nguyên vật liệu",
            descriptionMaterial: "Mô tả NVL",
            unit: "Đơn vị"
        },
        toastjs: {
            no_browser_bom_one: "Chỉ những version có trạng thái tạo mới hoặc từ chối mới được trình duyệt",
            no_browser_bom_two: "Không có version mới để trình duyệt",
            noVersionChange: "Không có version mới để đổi",
            no_choose_version: "Chưa chọn BOM để đổi version",
            no_list_slot: "Danh sách slot không được để trống",
            create_success: "Tạo mới Bom thành công",
            change_version_success: "Đổi version thành công",
            add_verion_success: "Thêm mới version thành công",
            delete_version_success: "Xóa version thành công",
            no_change_version: "Version mới chưa có sự thay đổi so với version cũ",
            change_by_Operation: "Chỉ đổi BOM có điều kiện đổi bằng thao tác"
        },
        formVersion: {
            title: "Tạo mới version",
            marterialNew: "Mã sản phẩm hoặc NVL mới",
            descriptionNew: "Mô tả slot mới"
        },
        changeVersionForm: {
            title: "Điều kiện để đổi version",
            material: "Mã sản phẩm hoặc nguyên vật liệu",
            number: "Lượng vật tư",
            type1: "Đổi bằng thao tác",
            type2: "Đổi theo mốc thời gian",
            type3: "Đổi theo điều kiện tồn kho",
            error: "Chưa chọn điều kiện để đổi version",
            addCondition: "Thêm điều kiện",
            error_checkbox3: "Thêm điều kiện tồn kho"
        }
    }
}
