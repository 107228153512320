import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_LIST_DATA_BY_UNITCODE_API,
  CREATE_USER_HOSPITAL_WAREHOUSE_API,
  GET_USER_HOSPITAL_WAREHOUSE_BY_USER_API,
  UPDATE_USER_HOSPITAL_WAREHOUSE_API,
  GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_API,
  GET_USER_LIST_API,
  GET_HOSPITAL_WAREHOUSE_LIST_API,
  DELETE_USER_HOSPITAL_WAREHOUSE_API,
  GET_USER_LIST_BY_RULE_API,
} from "./enum/TypeUserHospitalWarehouseApi.js";

//insert thông tin người dùng đơn vị kho
export async function createUserHospitalWarehouseService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_USER_HOSPITAL_WAREHOUSE_API,
    data
  );
}
//cập nhật người dùng đơn vị kho
export async function updateUserHospitalWarehouseService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_USER_HOSPITAL_WAREHOUSE_API,
    data
  );
}

//cập nhật người dùng đơn vị kho
export async function deleteUserHospitalWarehouseService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_USER_HOSPITAL_WAREHOUSE_API +
    "?id=" +
    data
  );
}

//lấy thông tin đơn vị kho bởi người dùng
export async function getByUserIdService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay +
    GET_USER_HOSPITAL_WAREHOUSE_BY_USER_API +
    "?userId=" +
    data
  );
}

//lấy thông tin các kho bởi người dùng
export async function getWarehouseByUserService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay +
    GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_API +
    "?userId=" +
    data
  );
}

export async function getUserListService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay +
    GET_USER_LIST_API,
    data
  );
}

export async function getUserListByRoleService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay +
    GET_USER_LIST_BY_RULE_API,
    data
  );
}

export async function getHospitalWarehouseListService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay +
    GET_HOSPITAL_WAREHOUSE_LIST_API,
    data
  );
}