import {
  SET_COMMON_DROPDOWN_MMPRODUCTION,
  SET_COMMON_DROPDOWN_MATERIAL,
  SET_COMMON_DROPDOWN_MATAINER,
  GET_WORK_COMMON_DROPDOWN_BY_CODE_REDUCER,
} from "./enum/CommonDropdownAction";
import {
  DropDownType,
  DropDownTypeMaterial,
  DropDownTypeMatainer,
  DropDownByCodeType
} from "src/shared/constants/enum/constants";
const stateTemp = {
  // Nguyên vật liệu
  unitDropdown: [],
  callUnitDropdown: false,
  typeMaterialDropdown: [],
  callTypeMaterialDropdown: false,
  materialDropdown: [],
  callMaterialDropdown: false,
  configgenCodeDropdown: [],
  callConfiggenCodeDropdown: false,
  locationMotherActiveDropdown: [],
  callLocationMotherActiveDropdown: false,
  locationDropdown: [],
  callLocationDropdown: false,
  packageTypeDropdown: [],
  callPackageTypeDropdown: false,
  partnerDropdown: [],
  callPartnerDropdown: false,
  PODropdown: [],
  callPODropdown: false,
  petitionerDropdown: [],
  callPetitionerDropdown: false,
  locationSystemDropdown: [],
  callLocationSystemDropdown: false,
  locationLevelDropdown: [],
  callLocationLevelDropdown: false,
  inventoryCheckInfoDropdown: [],
  callInventoryCheckInfoDropdown: false,
  materialV2Dropdown: [],
  callMaterialV2Dropdown: false,
  // quản lý sản xuất
  workAreaDropdown: [],
  callWorkAreaDropdown: false,
  workCenterDropdown: [],
  callWorkCenterDropdown: false,
  workUnitDropdown: [],
  callWorkUnitDropdown: false,
  workerDropdown: [],
  callWorkerDropdown: false,
  typeDropdown: [],
  callTypeDropdown: false,
  functionDropdown: [],
  callFunctionDropdown: false,
  groupWorkDayDropdown: [],
  callGroupWorkDayDropdown: false,
  workAreaOnlyChildDropdown: [],
  callWorkAreaOnlyChildDropdown: false,
  skillDropdown: [],
  callSkillDropdown: false,
  provincesDropdown: [],
  callProvincesDropdown: false,
  districtsDropdown: [],
  callDistrictsDropdown: false,
  positionDropdown: [],
  callPositionDropdown: false,
  departmentDropdown: [],
  callDepartmentDropdown: false,
  productDropdown: [],
  callProductDropdown: false,
  calenderDropdown: [],
  callCalenderDropdown: false,
  bomDropdown: [],
  callBomDropdown: false,
  sopDropdown: [],
  callSopDropdown: false,
  processDropdown: [],
  callProcessDropdown: false,
  ProductionrequirementDropdown: [],
  callProductionrequirementDropdown: false,
  workorderDropdown: [],
  callWorkorderDropdown: false,
  // bảo trì , bảo dưỡng 
  machineTypeDropdown: [],
  callMachineTypeDropdown: false,
  // lấy danh sách quy trình phê duyệt default 
  approvalStepDefault: [],
  callApprovalStepDefault: false,
  machineDropdown: [],
  callMachineDropdown: false,

  // get dropdown common by code
  dropdownCommonWorkcenterByCode: [],
  isDropdownCommonWorkcenterByCode: false,

  dropdownCommonWorkUnitByCode: [],
  isDropdownCommonWorkUnitByCode: false,
  dropdownCommonstepByCode: [],
  isDropdownCommonstepByCode: false,
  dropdownCommonWoByCode: [],
  isDropdownCommonWoByCode: false,
  typeIndentifier: "",
  key: null,
}
const CommonDropDownReducerV2 = (
  state = stateTemp,
  action
) => {
  switch (action.type) {
    case SET_COMMON_DROPDOWN_MATERIAL:
      if (action.typeDropdown == DropDownTypeMaterial.Unit) {
        state.unitDropdown = action.response;
        state.callUnitDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.TypeMaterial) {
        state.typeMaterialDropdown = action.response;
        state.callTypeMaterialDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.Material) {
        state.materialDropdown = action.response;
        state.callMaterialDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.configgenCode) {
        state.configgenCodeDropdown = action.response;
        state.callConfiggenCodeDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.locationMotherActive) {
        state.locationMotherActiveDropdown = action.response;
        state.callLocationMotherActiveDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.Location) {
        state.locationDropdown = action.response;
        state.callLocationDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.packageType) {
        state.packageTypeDropdown = action.response;
        state.callPackageTypeDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.Partner) {
        state.partnerDropdown = action.response;
        state.callPartnerDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.PO) {
        state.PODropdown = action.response;
        state.callPODropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.Petitioner) {
        state.petitionerDropdown = action.response;
        state.callPetitionerDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.LocationSystem) {
        state.locationSystemDropdown = action.response;
        state.callLocationSystemDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.LocationLevel) {
        state.locationLevelDropdown = action.response;
        state.callLocationLevelDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.InventoryCheckInfo) {
        state.inventoryCheckInfoDropdown = action.response;
        state.callInventoryCheckInfoDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMaterial.MaterialVer2) {
        state.materialV2Dropdown = action.response;
        state.callMaterialV2Dropdown = true;
      }
      return {
        ...state,
      };
    case SET_COMMON_DROPDOWN_MMPRODUCTION:
      if (action.typeDropdown == DropDownType.WorkArea) {
        state.workAreaDropdown = action.response;
        state.callWorkAreaDropdown = true;
      }
      if (action.typeDropdown == DropDownType.WorkCenter) {
        state.workCenterDropdown = action.response;
        state.callWorkCenterDropdown = true;
      }
      if (action.typeDropdown == DropDownType.WorkUnit) {
        state.workUnitDropdown = action.response;
        state.callWorkUnitDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Worker) {
        state.workerDropdown = action.response;
        state.callWorkerDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Type) {
        state.typeDropdown = action.response;
        state.callTypeDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Function) {
        state.functionDropdown = action.response;
        state.callFunctionDropdown = true;
      }
      if (action.typeDropdown == DropDownType.GroupWorkDay) {
        state.groupWorkDayDropdown = action.response;
        state.callGroupWorkDayDropdown = true;
      }
      if (action.typeDropdown == DropDownType.WorkAreaOnlyChild) {
        state.workAreaOnlyChildDropdown = action.response;
        state.callWorkAreaOnlyChildDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Skill) {
        state.skillDropdown = action.response;
        state.callSkillDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Provinces) {
        state.provincesDropdown = action.response;
        state.callProvincesDropdown = true;
      }
      if (action.typeDropdown == DropDownType.District) {
        state.districtsDropdown = action.response;
        state.callDistrictsDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Position) {
        state.positionDropdown = action.response;
        state.callPositionDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Department) {
        state.departmentDropdown = action.response;
        state.callDepartmentDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Product) {
        state.productDropdown = action.response;
        state.callProductDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Calender) {
        state.calenderDropdown = action.response;
        state.callCalenderDropdown = true;
      }
      if (action.typeDropdown == DropDownType.BomSlot) {
        state.bomDropdown = action.response;
        state.callBomDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Sop) {
        state.sopDropdown = action.response;
        state.callSopDropdown = true;
      }
      if (action.typeDropdown == DropDownType.ApprvalDefaultStep) {
        state.approvalStepDefault = action.response;
        state.callApprovalStepDefault = true;
      }
      if (action.typeDropdown == DropDownType.Process) {
        state.processDropdown = action.response;
        state.callProcessDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Productionrequirement) {
        state.ProductionrequirementDropdown = action.response;
        state.callProductionrequirementDropdown = true;
      }
      if (action.typeDropdown == DropDownType.Workorder) {
        state.workorderDropdown = action.response;
        state.callWorkorderDropdown = true;
      }
      state.typeIndentifier = action.typeIndentifier;
      return {
        ...state,
      };
    case SET_COMMON_DROPDOWN_MATAINER:
      if (action.typeDropdown == DropDownTypeMatainer.MachineType) {
        state.machineTypeDropdown = action.response;
        state.callMachineTypeDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMatainer.Machine) {
        state.machineDropdown = action.response;
        state.callMachineDropdown = true;
      }
      if (action.typeDropdown == DropDownTypeMatainer.MachineV2) {
        state.machineDropdown = action.response;
        state.callMachineDropdown = true;
      }
      return {
        ...state,
      };
    case GET_WORK_COMMON_DROPDOWN_BY_CODE_REDUCER:
      if (action.typeDropdown == DropDownType.WorkCenter) {
        state.dropdownCommonWorkcenterByCode = action.response;
        state.isDropdownCommonWorkcenterByCode = true;
      } else if (action.typeDropdown == DropDownByCodeType.workUnit) {
        state.dropdownCommonWorkUnitByCode = action.response;
        state.isDropdownCommonWorkUnitByCode = true;
      } else if (action.typeDropdown == DropDownByCodeType.workOrder) {
        state.dropdownCommonWoByCode = action.response;
        state.isDropdownCommonWoByCode = true;
      } else if (action.typeDropdown == DropDownByCodeType.stepByWu) {
        state.dropdownCommonstepByCode = action.response;
        state.isDropdownCommonstepByCode = true;
      }
      state.key = action.key;
      return {
        ...state,
      };
    case 'default':
      state = stateTemp;
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default CommonDropDownReducerV2;
