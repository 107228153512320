export const GET_DATA_PAING_MACHINERY_REPAIR_SAGA = "GET_DATA_PAING_MACHINERY_REPAIR_SAGA";
export const SET_DATA_PAING_MACHINERY_REPAIR_REDUCER = "SET_DATA_PAING_MACHINERY_REPAIR_REDUCER";
export const GET_DETAIL_MACHINERY_REPAIR_SAGA = "GET_DETAIL_MACHINERY_REPAIR_SAGA";
export const GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_SAGA ="GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_SAGA";
export const GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_REDUCER = "GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_REDUCER";
export const SET_DETAIL_MACHINERY_REPAIR_REDUCER ="SET_DETAIL_MACHINERY_REPAIR_REDUCER";
export const SAVE_MACHINERY_REPAIR_SAGA = "SAVE_MACHINERY_REPAIR_SAGA";
export const UPDATE_MACHINERY_REPAIR_SAGA = "UPDATE_MACHINERY_REPAIR_SAGA";
export const SAVE_ACCEPTANCE_AND_REPAIR_MACHINERY_SAGA = "SAVE_ACCEPTANCE_AND_REPAIR_MACHINERY_SAGA";
export const DELETE_MACHINERY_REPAIR_SAGA = "DELETE_MACHINERY_REPAIR_SAGA";
export const CALL_RESULT_FROM_REDUCER_MACHINER_REPAIR = "CALL_RESULT_FROM_REDUCER_MACHINER_REPAIR";
export const CALL_DEFAULT_RESULT_FROM_REDUCER_MACHINER_REPAIR = "CALL_DEFAULT_RESULT_FROM_REDUCER_MACHINER_REPAIR";