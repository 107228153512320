import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import * as constant from "src/shared/constants/enum/constants";
import {
    GET_LIST_SAMPLE_DETAIL,
    GET_LIST_SAMPLE_CHECK, GET_LIST_SAMLE,
    SAVE_CHECK_LIST_SAMPLE_API,
    SAVE_VALUATION_API,
    GET_DROPDOWN_BY_CODE
} from "./enum/RecordResultTypeApi";
export async function getListSampleDetailService(request) {
    let api = ""
    if (request.type == constant.modalStatus.CREATE) {
        api = GET_LIST_SAMPLE_CHECK;
    }
    else {
        api = GET_LIST_SAMPLE_DETAIL;
    }
    return await CommonBase.getAsync(configApp.apiGateWay + api + "/" + request.code);
}
export async function getListSampleService(itemcode, lotcode) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_LIST_SAMLE + "/" + itemcode + "/" + lotcode);
}
export async function saveListSampleServie(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + SAVE_CHECK_LIST_SAMPLE_API, data);
}
export async function saveEvluationService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + SAVE_VALUATION_API + "/" + data.lotcode + "/" + data.status)
}
export async function getDropDownCheckListByCode(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROPDOWN_BY_CODE, data)
}