export const PetitionerManager = {
    language: "en",
    petitoner: {
        palaceholderSearch: "Petitioner code, Sloc transfer, Description",
        table: {
            code: "Petitioner code",
            sloc: "Sloc transfer",
            des: "Description",
            status: "Status",
            active: "Active",
        },
        form: {
            code: "Request Code",
            sloc: "Incoming Sloc",
            des: "Description",
            active: "Active",
        },
        mes: {
            emptycode: "Petitioner code type not be empty",
            emptysloc: "Sloc transfer to not be empty",
            codeLenght: "The required source code must not exceed 50 characters",
            sloclenght: "Sloc cannot exceed 50 characters",
            desLenght: "Description should not exceed 255 characters",
            codeErr: "Incorrect code"
        },
        delete: {
            tittle: "Confirm to delete this petitioner",
            confirmLabel: "Are you sure want to delete this petitioner",
        },
        toastJs: {
            createOk: "Create new request source successfully",
            updateOk: "Successfully updated request source",
            deteleOk: "Successfully deleted request source"
        }
    }
}