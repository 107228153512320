export const GET_REPORT_TRANSACTION_SAGA = "GET_REPORT_TRANSACTION_SAGA";
export const GET_REPORT_TRANSACTION_REDUCER = "GET_REPORT_TRANSACTION_REDUCER";

export const EXPORT_REPORT_TRANSACTION_SAGA = "EXPORT_REPORT_TRANSACTION_SAGA";
export const EXPORT_REPORT_TRANSACTION_REDUCER = "EXPORT_REPORT_TRANSACTION_REDUCER";

export const GET_REPORT_RECONCILE_SAGA = "GET_REPORT_RECONCILE_SAGA";
export const GET_REPORT_RECONCILE_REDUCER = "GET_REPORT_RECONCILE_REDUCER";

export const EXPORT_REPORT_RECONCILE_SAGA = "EXPORT_REPORT_RECONCILE_SAGA";
export const EXPORT_REPORT_RECONCILE_REDUCER = "EXPORT_REPORT_RECONCILE_REDUCER";

export const GET_REPORT_INVENTORY_SAGA = "GET_REPORT_INVENTORY_SAGA";
export const GET_REPORT_INVENTORY_REDUCER = "GET_REPORT_INVENTORY_REDUCER";

export const EXPORT_REPORT_INVENTORY_SAGA = "EXPORT_REPORT_INVENTORY_SAGA";
export const EXPORT_REPORT_INVENTORY_REDUCER = "EXPORT_REPORT_INVENTORY_REDUCER";

export const GET_SUM_REPORT_REDUCER = "GET_SUM_REPORT_REDUCER";
export const RESET_GET_SUM_REPORT_REDUCER = "RESET_GET_SUM_REPORT_REDUCER";

export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";

export const TYPE_REPORT_ENUM = {
  TRANSACTION_REPORT: 1,
  RECONCILE_REPORT:2,
  INVENTORY_REPORT:3
}