export const calendarManager = {
    language: "en",
    calendar: {
        btn: {
            is_select_all: "Select all",
            cancel: "Cancel ",
            choose_date_type: "Choose date type",
            save: "Save",
        },
        day: {
            sunday: "Sunday",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
        },
        month: {
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December"
        },
        errorCancel: {
            no_choose_to_cancel: "Haven't selected a date to cancel",
            all_date_no_scheduled_to_cancel: "All selected dates have not yet been scheduled to cancel",
            the_selected_date_no_scheduled_to_cancel: "The selected date has not been scheduled to cancel",
        },
        errorSave: {
            no_selected_work_schedule: "Haven't selected a work schedule yet",
            no_set_calendar_for_the_day: "Haven't set a calendar for the day"
        },
        successful: {
            successful_calendar_setting: "Successful calendar setting"
        },
        chooseDay: {
            choose_day: "Select type of working day",
            name_type_working_day: "Name of type of working day",
            symbol: "Symbol",
            description: "Description",
            salary_percentage: "Salary percentage (%)",
            representative_color: "Representative color",
            working_shift: "Working shift",
            choose: "Choose"
        }
    },
};
