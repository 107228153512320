import {
    GET_PAGING_RESCHEDULE_PRODUCT_SAGA, GET_PAGING_RESCHEDULE_PRODUCT_REDUCER, CREATE_RESCHEDULE_PRODUCT_SAGA, CALL_RESULT_RESCHEDULE_PRODUCT_REDUCER,
    CALL_DEFAULT_RESCHEDULE_PRODUCT_REDUCER, GET_ALL_DROP_DOWN_SAGA, SET_ALL_DROP_DOWN_REDUCER, RE_RENDER_RESCHEDULE, GET_DROPDOWN_PROCESS_REWORK,
    SET_DROPDOWN_PROCESS_REWORK, CHANGE_DATA_RENDER
} from "./enum/typeRescheduleProductionAction";

const RescheduleProductionAction = {
    getPagingSaga: (request) => {
        return {
            type: GET_PAGING_RESCHEDULE_PRODUCT_SAGA,
            request: request,
        };
    },
    getDropdown: () => {
        return {
            type: GET_ALL_DROP_DOWN_SAGA,
        };
    },
    setDropdownReducer: (data) => {
        return {
            type: SET_ALL_DROP_DOWN_REDUCER,
            data: data,
        };
    },
    createDataSaga: (dataModal, requestPaging) => {
        return {
            type: CREATE_RESCHEDULE_PRODUCT_SAGA,
            dataModal: dataModal,
            requestPaging: requestPaging,
        };
    },

    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_RESCHEDULE_PRODUCT_REDUCER,
            data: data,
        };
    },

    callResultFromApiReducer: () => {
        return {
            type: CALL_RESULT_RESCHEDULE_PRODUCT_REDUCER,
        };
    },

    callDefault: () => {
        return {
            type: CALL_DEFAULT_RESCHEDULE_PRODUCT_REDUCER,
        };
    },
    reRender: () => {
        return {
            type: RE_RENDER_RESCHEDULE,
        };
    },
    getDropdownProcessRework: (productcode) => {
        return {
            type: GET_DROPDOWN_PROCESS_REWORK,
            productcode: productcode,
        };
    },
    setDropdownProcessReworkReducer: (data) => {
        return {
            type: SET_DROPDOWN_PROCESS_REWORK,
            data: data,
        };
    },
    callChangeData: () => {
        return {
            type: CHANGE_DATA_RENDER,
        };
    },
    isDefault: () => {
        return {
            type: "default",
        };
    },
};

export default RescheduleProductionAction;
