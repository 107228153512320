export const MaintenanceScheduleLag = {
    language: "vn",
    MaintenanceScheduleLag: {
        Search: "Mã lệnh bảo dưỡng",
        btn: {
            create: "Đăng ký lịch bảo dưỡng",
            CreateCalendar: "Tạo lịch",
            Skip: "Bỏ qua",
            Auto: "Auto"
        },
        table: {
            MaintenanceCode: "Mã lệnh bảo dưỡng",
            CycleCode: "Mã chu kỳ",
            MaintenanceType: "Loại bảo dưỡng",
            ExpectedStartDate: "Ngày bắt đầu (dự kiến)",
            ExpectedEndDate: "Ngày kết thúc (dự kiến)",
            ActualStartTime: "Thời gian bắt đầu (thực tế)",
            ActualEndTime: "Thời gian kết thúc (thực tế)",
            Status: "Trạng thái",
            Action: "Thao tác"
        },
        form: {
            title: "Thực hiện lệnh bảo dưỡng",
            titleDetail: "Chi tiết lệnh bảo dưỡng",
            MachineName: "Tên máy",
            MachineCode: "Mã máy",
            ExpectedStartTime: "Thời gian bắt đầu dự kiến",
            ExpectedEndTime: "Thời gian kết thúc dự kiến",
            Executor: "Người thực hiện",
            ImplementationDate: "Ngày thực hiện",
            ExecutionTime: "Thời gian thực hiện(giờ)",
            MaterialTable: {
                title: "Vật tư tiêu hao",
                Accessory: "Linh kiện",
                Unit: "Đơn vị",
                ExpectedNumber: "Số lượng dự kiến",
                ActualNumber: "Số lượng thực tế",
                MaintenanceCost: "Chi phí bảo dưỡng(VND)"
            }
        },
        delete: {
            Title: "Bỏ qua bảo dưỡng",
            des: "Bạn có chắc chắn bỏ qua bảo dưỡng bảo dưỡng đối với máy móc/ linh kiện này?",
            reason: "Nhập lý do bỏ qua",
            validate: "Lý do bỏ qua không được để trống",
            validateLength: "Lý do không được quá 225 ký tự",
            onlyMaintenanceOrder: "Chỉ có một lệnh bảo dưỡng cho mã máy, mã chu kỳ này, không được bỏ qua",
            onlyUnfulfilled: "Chỉ được bỏ qua lệnh bảo dưỡng chờ thực hiện",
            noSkip: "Không được bỏ qua lệnh bảo dưỡng này"
        },
        rules: {
            material: "Linh kiện không thể trùng lặp"
        },
        createSchedule: {
            toastjs: "Không có lịch bảo dưỡng nào để tạo",
            ExpectedMaintenanceDate: "Ngày bảo dưỡng dự kiến",
            ExpectedCompletionDate: "Ngày hoàn thành dự kiến",
            MaintenanceType: "Loại bảo trì",
        }
    }
};