import {
    DELETE_INVENTORY_PLAN_SAGA,
    DELETE_INVENTORY_PLAN_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    GET_COST_CENTER_EV5_BY_SEGMENT_REDUCER,
    GET_COST_CENTER_EV5_BY_SEGMENT_SAGA,
    CREATE_INVENTORY_PLAN_SAGA,
    CALL_CREATE_SUCCESS_REDUCER,
    CALL_UPDATE_SUCCESS_REDUCER,
    GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_REDUCER,
    GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_SAGA,
    UPDATE_INVENTORY_PLAN_SAGA
} from "./enum/TypeAssetmentStocktakingAction";

const AssetmentStocktakingAction = {
    getCostCenterEV5BySegmentReducer: (data) => {
        return {
            type: GET_COST_CENTER_EV5_BY_SEGMENT_REDUCER,
            data: data,
        };
    },
    getCostCenterEV5BySegmentSaga(request) {
        return {
            type: GET_COST_CENTER_EV5_BY_SEGMENT_SAGA,
            request: request,
        };
    },
    deleteDataSaga(id) {
        return {
            type: DELETE_INVENTORY_PLAN_SAGA,
            id: id,
        };
    },
    deleteDataReducer: () => {
        return {
            type: DELETE_INVENTORY_PLAN_REDUCER,
        };
    },
    CreateInventoryPlanSaga(request) {
        return {
            type: CREATE_INVENTORY_PLAN_SAGA,
            request: request,
        };
    },
    UpdateInventoryPlanSaga(request) {
        return {
            type: UPDATE_INVENTORY_PLAN_SAGA,
            request: request,
        };
    },
    callAPICreateSuccessReducer: (data) => {
        return {
            type: CALL_CREATE_SUCCESS_REDUCER,
            data: data
        };
    },
    callAPIUpdateSuccessReducer: (data) => {
        return {
            type: CALL_UPDATE_SUCCESS_REDUCER,
            data: data
        };
    },
    GetInventoryPlanByIdForEditSaga(id) {
        return {
            type: GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_SAGA,
            id: id,
        };
    },
    GetInventoryPlanByIdForEditReducer: (data) => {
        return {
            type: GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_REDUCER,
            data: data
        };
    },
    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER,
        };
    },
};

export default AssetmentStocktakingAction;
