// package
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { convertBinaryToBase64 } from "./data";

export const getFileType = (type) => {
  if (type.includes("image")) {
    return "image";
  }

  if (type.includes("pdf")) {
    return "pdf";
  }

  if (
    type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
    type === "application/msword"
  ) {
    return "word";
  }

  if (
    type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    type === "application/vnd.ms-excel"
  ) {
    return "excel";
  }

  return "file";
};

export const getFullFileNameFromUrl = (url) => {
  const parts = url.split("/");
  if (parts.length < 1) {
    return undefined;
  }
  return parts[parts.length - 1];
};

export const triggerDownload = (downloadUrl, downloadAttributeValue) => {
  const link = document.createElement("a");
  link.href = downloadUrl;
  if (!_.isNil(downloadAttributeValue)) {
    link.setAttribute("download", downloadAttributeValue);
  }
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// Hàm để chuyển đổi base64 thành Blob
const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
    
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

// Hàm để tải file xuống
export const triggerDownloadFromBase64 = (fileErr) => {
  const blob = b64toBlob(fileErr.FileContents, fileErr.ContentType);
  const downloadUrl = URL.createObjectURL(blob);
  triggerDownload(downloadUrl, fileErr.FileDownloadName);
  URL.revokeObjectURL(downloadUrl);  // Giải phóng bộ nhớ sau khi tải xong
};


const downloadOtherFile = (fileUrl, downloadAttributeValue) => {
  axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      triggerDownload(downloadUrl, downloadAttributeValue);
    })
    .catch(() => {
      triggerDownload(fileUrl);
    });
};

const downloadImageFile = (fileUrl, downloadAttributeValue) => {
  axios
    .get(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const downloadUrl = window.URL.createObjectURL(blob);
      triggerDownload(downloadUrl, downloadAttributeValue);
    })
    .catch(() => {
      triggerDownload(fileUrl);
    });
};

export const downloadBase64File = (blob, downloadAttributeValue) => {
  const downloadUrl = window.URL.createObjectURL(blob);
  triggerDownload(downloadUrl, downloadAttributeValue);
};

export const downloadFile = (fileUrl, fileName) => {
  const downloadAttributeValue = !_.isNil(fileName)
    ? fileName
    : getFullFileNameFromUrl(fileUrl);

  const fileType = getFileTypeByFilePath(fileUrl);

  if (fileType === "image") {
    downloadImageFile(fileUrl, downloadAttributeValue);
    return;
  }

  downloadOtherFile(fileUrl, downloadAttributeValue);
};

export const getFileTypeByFilePath = (filePath) => {
  const fileExtension = filePath.split(".").pop().toLowerCase();

  if (fileExtension.match(/(jpg|jpeg|png|gif|jfif|webp|svg|bmp)$/)) {
    return "image";
  } else if (fileExtension.match(/(doc|docx)$/)) {
    return "word";
  } else if (fileExtension.match(/(xls|xlsx)$/)) {
    return "excel";
  } else if (fileExtension.match(/(pdf)$/)) {
    return "pdf";
  } else {
    return "file";
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getUrlFromImageData = (imageData) => {
  const base64 = convertBinaryToBase64(imageData);
  return URL.createObjectURL(base64);
};

export const createAndDownloadExcelFile = async ({
  workbook,
  fileNameWithoutExtension = "file",
  extension = ".xlsx",
  prefix = "ERROR_",
}) => {
  const excelBuffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const downloadUrl = URL.createObjectURL(blob);
  const downloadAttributeValue = `${prefix}${fileNameWithoutExtension}_${moment().format(
    "DD_MM_YYYY_hh_mm_ss"
  )}${extension}`;
  triggerDownload(downloadUrl, downloadAttributeValue);
};

export const getFileNameWithoutExtension = (fileName) => {
  if (typeof fileName !== "string") {
    return undefined;
  }

  const parts = fileName.split(".");

  if (parts.length === 1) {
    return fileName;
  }

  parts.pop();
  return parts.join("");
};

export const getFileNameWithDate = ({
  prefix,
  fileNameWithoutExtension,
  extension,
}) => {
  return `${prefix}${fileNameWithoutExtension}_${moment().format(
    "DD_MM_YYYY_hh_mm_ss"
  )}${extension}`;
};
