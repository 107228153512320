export const GET_ALL_PACKING_PROCESS = "GET_ALL_PACKING_PROCESS";
export const GET_PACKING_PROCESS_ID_SAGA = "GET_PACKING_PROCESS_ID_SAGA";
export const SET_PACKING_PROCESS_ID_REDUCER = "SET_PACKING_PROCESS_ID_REDUCER";
export const CREATE_PACKING_PROCESS_SAGA = "CREATE_PACKING_PROCESS_SAGA";
export const UPDATE_PACKING_PROCESS_SAGA = "UPDATE_PACKING_PROCESS_SAGA";
export const DELETE_PACKING_PROCESS_SAGA = "DELETE_PACKING_PROCESS_SAGA";
export const SET_ALL_PACKING_PROCESS_REDUCER = "SET_ALL_PACKING_PROCESS_REDUCER";
export const GET_DROP_DOWN_PACKING_LEVEL_SAGA = "GET_DROP_DOWN_PACKING_LEVEL_SAGA";
export const SET_DROP_DOWN_PACKING_LEVEL_REDUCER = "SET_DROP_DOWN_PACKING_LEVEL_REDUCER";
export const CALL_RESULT_PACKING_PROCESS_REDUCER = "CALL_RESULT_PACKING_PROCESS_REDUCER";
export const CALL_FALSE_FROM_REDUCER = "CALL_FALSE_FROM_REDUCER";
export const SET_ERROR_TO_REDUCER = "SET_ERROR_TO_REDUCER";
export const SET_DEFAULT_ERROR_FROM_REDUCER = "SET_DEFAULT_ERROR_FROM_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
export const CALL_DEFAULT_PACKING_PROCESS_REDUCER = "CALL_DEFAULT_PACKING_PROCESS_REDUCER";