export const ApprovalProcess = {
    language: "vn",
    approvalProcess: {
        search: {
            placeHolder:
                "Tìm kiếm theo tên, mô tả hoặc người tạo",
        },
        table: {
            name: 'Tên quy trình',
            description: "Mô tả",
            createdBy: "Người tạo",
            createdAt: "Ngày tạo"
        },
        form: {
            title: {
                create: 'Tạo quy trình phê duyệt',
                update: 'Chỉnh sửa quy trình phê duyệt',
            },
            content: {
                name: {
                    title: "Tên quy trình",
                    placeHolder: "Nhập tên quy trình..."
                },
                description: {
                    title: "Mô tả",
                    placeHolder: "Nhập nội dung..."
                },
                lstStep: {
                    title: "Bước quy trình",
                    btnCreate: "Thêm bước mới",
                    step: "bước",
                    noData: "Bạn chưa tạo các bước quy trình",
                    itemStep: {
                        name: {
                            placeHolder: "Nhập tên bước"
                        },
                        duration: {
                            placeHolder: "Giờ"
                        },
                        user: {
                            title: "Người phê duyệt",
                            placeHolder: "Nhập tên hoặc email"
                        },
                        role: {
                            title: "Vai trò (Role)",
                            placeHolder: "Chọn vai trò"
                        }
                    },
                }
            },

            rule: {
                name: {
                    required: "Tên quy trình không được bỏ trống",
                    maxLength: "Tên quy trình không được vượt quá 250 ký tự"
                },
                description: {
                    maxLength: "Mô tả không được vượt quá 500 ký tự"
                },
                lstStep: {
                    name: {
                        required: "Tên bước không được bỏ trống",
                        maxLength: "Tên bước không được vượt quá 250 ký tự"
                    },
                    duration: {
                        required: "Thời gian duyệt không được để trống",
                        min: "Thời gian duyệt phải lớn hơn 0.5 giờ"
                    },
                    userRole: {
                        required: "Bắt buộc chọn người phê duyệt",
                    }
                }
            }
        },
        popup: {
            delete: {
                title: 'Xoá quy trình phê duyệt',
                content: 'Bạn có chắc chắn muốn xoá quy trình phê duyệt không?',
            }
        },
        toastjs: {
            createSuccess: "Tạo mới quy trình phê duyệt thành công",
            updateSuccess: "Cập nhật quy trình phê duyệt thành công",
            deleteSuccess: "Xoá quy trình phê duyệt thành công",
        },
    },
};
