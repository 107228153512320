export const GET_PAGING_WLP_API = "/api/production-management-service/link-process-with-work-center/get-paging";
export const GET_DROPDOWN_PROCESS_API = "/api/production-management-service/link-process-with-work-center/get-dropdown-process-by-product-code/";
export const GET_DROPDOWN_PRODUCT_PROCESS_API = "/api/production-management-service/link-process-with-work-center/get-product-dropdown";
export const GET_DATA_TABLE_API = "/api/production-management-service/link-process-with-work-center/get-versions-by-process-id";
export const GET_NEW_LINK_API = "/api/production-management-service/link-process-with-work-center/create";
export const GET_DETAIL_LINK_API = "/api/production-management-service/link-process-with-work-center/get-detail/";
export const DELETE_LINK_API = "/api/production-management-service/link-process-with-work-center/delete/";
export const UPDTATE_LINK_API = "/api/production-management-service/link-process-with-work-center/update";


