import {
  GET_PAGING_MEDICAL_MATERIAL_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER
} from "./enum/TypeCatMedicalMaterialAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,
  isDeleteSuccess: false,
  isCreateUpdateSuccess: false,
  listUnits: [],
};

const CatMedicalMaterialReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_MEDICAL_MATERIAL_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.listDataWarehouse = [];
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      return {
        ...state,
      };

    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };
    default:
      return { ...state };
  }
};

export default CatMedicalMaterialReducer;
