import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_LIST_DELIVERY_API,
    DELETE_DELIVERY_API,
    CREATE_DELIVERY_API,
    UPDATE_DELIVERY_API,
    GET_DETAIL_DELIVERY_API,
    GET_INFO_DELIVERY_BY_ID,
    DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_API,
    DOWNLOAD_EXCEL_API
} from "./enum/TypeDeliveryAPI";

export async function getListDeliveryService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_LIST_DELIVERY_API,
        data
    )
}
export async function createDeliveryService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_DELIVERY_API,
        data
    )
}
export async function updateDeliveryService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_DELIVERY_API,
        data
    )
}
export async function deleteDeliveryService(deleteId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        DELETE_DELIVERY_API +
        "?Id=" + deleteId
    )
}
export async function getDetailDeliveryService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_DETAIL_DELIVERY_API +
        "?Id=" + Id
    )
}

export async function getInfoDeliveryByIdService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_INFO_DELIVERY_BY_ID +
        "?Id=" + Id
    )
}

export async function DeliveryPredictabilityRetailByListProductService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_API,
        data
    )
}

export async function downloadExcelService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + DOWNLOAD_EXCEL_API + "?Id=" + data.Id + "&&TypeExport=" + data.typeExport 
    )
}
