export const ProductPackagingLag = {
    language: "en",
    ProductPackaging: {
        packTitle: "Encapsulation enforcement",
        finishedProducts: "Finished product packaging",
        packagingLevel: "Packing level",
        table: {
                tableName: "Packing list",
                code:"Identifiers",
                unit: "Unit",
                nodata:"No items yet!"
            },
        btn: {
            search: "Create",
            sprint:"Print stamps",
        },
        placeHolder: {
            searchProduct: "Choose finished products to pack",
            searchTable: "Enter / scan the identifier",            
            packagingLevel: "Select packing level",
        },
        delete: {
            title:"Remove identifier",
            confirm:"Are you sure you want to delete this identifier?",
        },
        toast: {
            createSuccess: "Successful stamp printing",
            reprintSuccess: "Successfully reprinted stamps",
            deleteSuccess: "Unsuccessful stamp removal",
            matchError: "Identifier already exists",
            overQtyError:"Do not enter more than the number of packages",
        },
    },
}