import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_REQUEST_INCREASE_ASSET_API,
    GET_DETAIL_INCREASE_ASSET_API
} from "./enum/TypeRequestIncreaseAssetApi.js";

export async function getRequestAssetIncreaseService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_REQUEST_INCREASE_ASSET_API,
        data
    )
}
export async function getDetailRequestAssetIncreaseService(Id) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_DETAIL_INCREASE_ASSET_API + "?InventoryAssetId=" +Id
    )
}

