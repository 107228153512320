import { call, put, takeEvery } from "redux-saga/effects";
// import ProductionRequirement from "src/components/page/MMProduction/ProductionRequirement";
import {
  CREATE_PRODUCTION_REQUIRMENT_SAGA,
  GET_PAGING_PRODUCTION_REQUIRMENT_SAGA,
  GET_DETAIL_PRODUCTION_REQUIRMENT_SAGA,
  UPDATE_PRODUCTION_REQUIRMENT_SAGA,
  DELETE_PRODUCTION_REQUIRMENT_SAGA,
} from "./enum/typeProductRequirementAction";
import { closeLoading } from "../../../shared/common/LoadingAction";
import toastJs from "../../../shared/common/toastJs";
import ProductRequirmentAction from "./ProductionRequirementAction";
import {
  getPagingService,
  createDataService,
  getDetailService,
  updateDataService,
  deleteDataService,
} from "./ProductionRequirementService";
import i18next from "i18next";
const FirstPage = 1;
export function* getPagingProductionRequirmentSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ?? " ";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      searchTerm: searchTerm,
    };
    const response = yield call(getPagingService, request);
    if (response.isSuccess) {
      if (response.data.Items.length <= 0 && request.PageIndex > 1) {
        yield put(ProductRequirmentAction.getPagingSaga(request.PageIndex - 1, request.PageSize, request.searchTerm));
      } else {
        yield put(ProductRequirmentAction.getPagingReducer(response.data));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}

export function* createProductionRequirmentSaga(data) {
  try {
    const res = yield call(createDataService, data.data);
    if (res.isSuccess) {
      toastJs.success(i18next.t("requirement.toastjs.createSuccess"));
      yield put(ProductRequirmentAction.callResultFromApiReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}

export function* getDetailDataSaga(data) {
  try {
    const objData = yield call(getDetailService, data.codeId);
    if (objData.isSuccess) {
      yield put(ProductRequirmentAction.returnDetailReducer(objData));
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* updateDataSaga(data) {
  try {
    const result = yield call(updateDataService, data.request);
    if (result.isSuccess) {
      toastJs.success(i18next.t("requirement.toastjs.updateSuccess"));
      yield put(ProductRequirmentAction.callResultFromApiReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* deleteDataSaga(data) {

  try {
    const result = yield call(deleteDataService, data.codeId);
    if (result.isSuccess) {
      toastJs.success(i18next.t("requirement.toastjs.deleteSuccess"));
      yield put(ProductRequirmentAction.closePopupDelete(true));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export default function* ProductRequirmentSaga(data) {
  yield takeEvery(
    GET_PAGING_PRODUCTION_REQUIRMENT_SAGA,
    getPagingProductionRequirmentSaga
  );
  yield takeEvery(
    CREATE_PRODUCTION_REQUIRMENT_SAGA,
    createProductionRequirmentSaga
  );
  yield takeEvery(GET_DETAIL_PRODUCTION_REQUIRMENT_SAGA, getDetailDataSaga);
  yield takeEvery(UPDATE_PRODUCTION_REQUIRMENT_SAGA, updateDataSaga);
  yield takeEvery(DELETE_PRODUCTION_REQUIRMENT_SAGA, deleteDataSaga);
}
