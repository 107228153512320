// import { data } from "src/components/page/QuanlityManager/Report/HeadlineReport/comp/FixedInfo";
import * as types from "../../utils/enum/ActionTypeEnums";
const NotificaionReducer = (state = {
    isFetching: false,
    isFetchingMore: false,
    isCallResult: false,
    listNotificaltion: []

}, action) => {
    state.isFetching = false;
    state.isFetchingMore = false;
    switch (action.type) {
        case types.GET_ALL_NOTIFiCATION_REDUCER:
            state.isCallResult = true;
            if (action.data) {
                let list = [];
                action.data.forEach(item => {
                    let obj = JSON.parse(item.info);
                    list.push(obj);
                })
                state.listNotificaltion = list;
            }
            return {
                ...state
            }
        case types.SET_DEFAULT:
            state.isCallResult = false;
            state.listNotificaltion = [];
            return {
                ...state
            }
        case types.ADD_RECORD:
            if (action.data) {
                const newArray = [action.data].concat(state.listNotificaltion);
                state.listNotificaltion = newArray;
            }
            return {
                ...state
            };
        case types.UPDATE_NOTIFICATION_REDUCER:
            if (action.approvalID) {
                let index = state.listNotificaltion.findIndex(m=>m.EntityId == action.approvalID);
                let obj =state.listNotificaltion.find(m=>m.EntityId=action.approvalID);
                obj.status="done";
                state.listNotificaltion[index]=obj;
                // const newArray = state.listNotificaltion?.filter(m => m.EntityId != action.approvalID);
                // state.listNotificaltion = newArray;
            }
            return {
                ...state
            }
        default:
            return {
                ...state,
            };
    }
}
export default NotificaionReducer;