import { call, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_USER_HOSPITAL_WAREHOUSE_SAGA,
  DELETE_USER_HOSPITAL_WAREHOUSE_SAGA,
  GET_HOSPITAL_WAREHOUSE_LIST_SAGA,
  GET_USER_HOSPITAL_WAREHOUSE_BY_USER_SAGA,
  GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_SAGA,
  GET_USER_HOSPITAL_WAREHOUSE_SAGA,
  GET_USER_LIST_BY_RULE_SAGA,
  GET_USER_LIST_SAGA,
  UPDATE_USER_HOSPITAL_WAREHOUSE_SAGA,
} from "./enum/TypeUserHospitalWarehouseAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import UserHospitalWarehouseAction from "./UserHospitalWarehouseAction";
import {
  createUserHospitalWarehouseService,
  deleteUserHospitalWarehouseService,
  getByUserIdService,
  getHospitalWarehouseListService,
  getUserListByRoleService,
  getUserListService,
  getWarehouseByUserService,
  updateUserHospitalWarehouseService,
} from "./UserHospitalWarehouseService";
import { statusEnum } from "src/components/page/MedicalSuppliesManagement/PermissionManagement/enum";

export function* createUserHospitalWarehouseSaga(requet) {
  try {
    if (requet) {
      const requestAPI = {
        Id: 0,
        UserId: requet?.data?.UserId?.trim(),
        HospitalId: requet?.data?.HospitalId,
        ListWarehouseId: requet?.data?.WarehouseId,
        Status: requet?.data?.Status,

      };
      const response = yield call(createUserHospitalWarehouseService, requestAPI);
      if (response.isSuccess) {
        yield put(UserHospitalWarehouseAction.createUserHospitalWarehouseReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* updateUserHospitalWarehouseSaga(requet) {
  try {
    if (requet) {
      const requestAPI = {
        Id: requet?.data?.Id,
        UserId: requet?.data?.UserId?.trim(),
        HospitalId: requet?.data?.HospitalId,
        WarehouseId: requet?.data?.WarehouseId,
        Status: requet?.data?.Status,
      };
      const response = yield call(updateUserHospitalWarehouseService, requestAPI);
      if (response.isSuccess) {
        yield put(UserHospitalWarehouseAction.updateUserHospitalWarehouseReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* deleteUserHospitalWarehouseSaga(requet) {
  try {
    if (requet) {
      const Id = requet?.data?.Id;
      const response = yield call(deleteUserHospitalWarehouseService, Id);
      if (response.isSuccess) {
        yield put(UserHospitalWarehouseAction.deleteUserHospitalWarehouseReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getByUserIdSaga(request) {
  try {
    const response = yield call(getByUserIdService, request.data);
    if (response.isSuccess) {
      yield put(
        UserHospitalWarehouseAction.getUserHospitalWarehouseByUserReducer(response)
      );
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* getWarehouseByUserSaga(request) {
  try {
    const response = yield call(getWarehouseByUserService, request.data);
    if (response.isSuccess) {
      yield put(
        UserHospitalWarehouseAction.getUserHospitalWarehouseByUserReducer(response)
      );
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* getUserListSaga(request) {
  try {
    if(request.searchTerm){
      request.pageIndex = 1
    }
    let param = {
      PageIndex: request.pageIndex,
      PageSize: request.pageSize,
      SearchTerm: request.searchTerm
    }
    const response = yield call(getUserListService, param);
    if (response.isSuccess) {
      yield put(
        UserHospitalWarehouseAction.getUserListReducer(response)
      );
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}


export function* getUserListByRoleSaga(request) {
  try {
    let param = {
      PageIndex: request.pageIndex,
      PageSize: request.pageSize,
      SearchTerm: request.searchTerm
    }
    const response = yield call(getUserListByRoleService, param);
    if (response.isSuccess) {
      yield put(
        UserHospitalWarehouseAction.getUserListByRuleReducer(response)
      );
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* getHospitalWarehouseListSaga(request) {
  try {
    let param = {
      UserId: request.UserId
    }
    const response = yield call(getHospitalWarehouseListService, param);
    if (response.isSuccess) {
      yield put(
        UserHospitalWarehouseAction.getHospitalWarehouseListReducer(response)
      );
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export default function* UserHospitalWarehouseSaga(data) {
  yield takeEvery(CREATE_USER_HOSPITAL_WAREHOUSE_SAGA, createUserHospitalWarehouseSaga);
  yield takeEvery(UPDATE_USER_HOSPITAL_WAREHOUSE_SAGA, updateUserHospitalWarehouseSaga);
  yield takeEvery(DELETE_USER_HOSPITAL_WAREHOUSE_SAGA, deleteUserHospitalWarehouseSaga);
  yield takeEvery(GET_USER_HOSPITAL_WAREHOUSE_BY_USER_SAGA, getByUserIdSaga);
  yield takeEvery(GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_SAGA, getWarehouseByUserSaga);
  yield takeEvery(GET_USER_LIST_SAGA, getUserListSaga);
  yield takeEvery(GET_USER_LIST_BY_RULE_SAGA, getUserListByRoleSaga);
  yield takeEvery(GET_HOSPITAL_WAREHOUSE_LIST_SAGA, getHospitalWarehouseListSaga);
}
