import {
  GET_PAGING_RETURN_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_RETURN_REDUCER,
  CREATE_RETURN_REDUCER,
  UPDATE_RETURN_REDUCER,
  SET_TRANSACTION_DETAIL_LIST_REDUCER,
  GET_INFO_RETURN_BY_ID_REDUCER,
  GET_PAGING_RETAIL_RETURN_REDUCER,
  RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER,
  RESET_GET_INFO_RETURN_BY_ID_REDUCER
} from "./enum/TypeReturnAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,
  isDeleteSuccess: false,
  isCreateUpdateSuccess: false,
  responseApi: null,
  data: null,
  isGetDetailSuccess: false,
  listDataRetailReturn: [],
  isGetLstDataRetailReturn: false,

  //biến dành cho tính toán dự trù khi chọn vật tư y tế
  listReturnDetailPredictabilityRetailListProduct: [],
  isGetLstReturnDetailPredictabilityRetailListProduct: false,

  //Danh sách vật tư y tế của từng phiếu xuất 
  listTransactionDetail: [],
};

const ReturnReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_RETURN_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case GET_PAGING_RETAIL_RETURN_REDUCER:
      if (action.data) {
        console.log(action)
        state.listDataRetailReturn = action.data;
        state.isGetLstDataRetailReturn = true;
      }
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      state.isGetLstDataRetailReturn = false;
      state.responseApi = null;
      return {
        ...state,
      };
    case CREATE_RETURN_REDUCER:
      state.isCreateUpdateSuccess = true;
      state.responseApi = action.data;
      return {
        ...state,
      };

    case SET_TRANSACTION_DETAIL_LIST_REDUCER:
      if (action.data)
        state.listTransactionDetail = action.data;
      return { ...state };

    case GET_INFO_RETURN_BY_ID_REDUCER:
      state.isGetDetailSuccess = true;
      state.data = action.data;
      return {
        ...state,
      };

      case RESET_GET_INFO_RETURN_BY_ID_REDUCER:
      state.isGetDetailSuccess = false;
      return {
        ...state,
      };

    case UPDATE_RETURN_REDUCER:
      if (action.data) {
        state.isCreateUpdateSuccess = true;
        state.responseApi = action.data;
      }
      return {
        ...state,
      };
    case RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER:
      if (action.data) {
        state.listReturnDetailPredictabilityRetailListProduct = action.data;
        state.isGetLstReturnDetailPredictabilityRetailListProduct = true;
      }
      return { ...state };
    case DELETE_RETURN_REDUCER:
      state.isDeleteSuccess = true;
      return {
        ...state,
      };
    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };
    default:
      return { ...state };
  }
};

export default ReturnReducer;
