import * as types from "../../utils/enum/ActionTypeEnums";
const NotificationAction = {
    getAllNotifactionSaga(userID) {
        return {
            type: types.GET_ALL_NOTIFiCATION_SAGA,
            userID: userID,
        }
    },
    setAllNotificationReducer(data) {
        return {
            type: types.GET_ALL_NOTIFiCATION_REDUCER,
            data: data
        }
    },
    updateNotification(approvalID,userID) {
        return {
            type: types.UPDATE_NOTIFICATION,
            approvalID: approvalID,
            userID:userID        }
    },
    updateNotificationReducer(approvalID,userID) {
        return {
            type: types.UPDATE_NOTIFICATION_REDUCER,
            approvalID: approvalID,
            userID:userID
        }
    },
    addRecord(data) {
        return {
            type: types.ADD_RECORD,
            data: data,
        }
    },
    callDefault() {
        return {
            type: types.SET_DEFAULT,
        }
    }
}
export default NotificationAction;