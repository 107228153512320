import { all } from "redux-saga/effects";
//-------------------------- sign up saga file ----------------------------------
import AccessoriesSaga from "../MaterialManager/AccessoriesManager/Accessoris/AccessoriesSaga";

import ReactHookDemoSaga from "src/redux-saga-v2/HookDemo/reactHookDemoSaga";
import SOPSaga from "src/redux-saga-v2/MMProduction/SOP/SOPSaga";
import BomSaga from "../MMProduction/BOM/BOMSaga";
import ApproveStepSaga from "src/redux-saga-v2/MMProduction/ApproveStep/ApproveStepSaga";
import ProductRequirmentSaga from "src/redux-saga-v2/MMProduction/ProductionRequirement/ProductionRequirementSaga";
import CommonDropDownSaga from "src/redux-saga-v2/commonDropdown/CommonDropdownSaga";
import WorkareaLinkProccessSaga from "../MMProduction/WorkareaLinkProcess/WorkareaLinkProccessSaga";
import ApprovalSaga from "src/redux-saga-v2/MMProduction/Approval/ApprovalSaga";
import RescheduleProductionAction from "src/redux-saga-v2/MMProduction/RescheduleProduction/RescheduleProductionSaga";
import ProcessSaga from "src/redux-saga-v2/MMProduction/ProcessManager/ProcessSaga";
import PackingLevelSaga from "../MMProduction/ProductManager/Pack/PackingLevel/PackingLevelSaga";
import PackingProcessSaga from "../MMProduction/ProductManager/Pack/PackingProcess/PackingProcessSaga";
import ProductPackagingSaga from "../MMProduction/ProductManager/Pack/ProductPackaging/ProductPackagingSaga";
import ProductIdentifierSaga from "../MMProduction/ProductIdentifier/ProductIdentifierSaga";
import RecordResultSaga from "../QuanlityManager/RecordResult/RecordResultSaga";
import LotQASaga from "../QuanlityManager/LotQA/LotQASaga";
import actionLinkProductRequirement from "src/redux-saga-v2/MMProduction/LinkWorkCenterWithProcess/saga";
import ItemAllocationSaga from "src/redux-saga-v2/MMProduction/ItemAllocation/ItemAllocationSaga";
import CheckpointSaga from "src/redux-saga-v2/QuanlityManager/Checkpoint/Saga";
import RepairSaga from "../QuanlityManager/Repair/Repairsaga";
import CheckListSaga from "../QuanlityManager/CheckList/CheckListSaga";
import UploadFileSaga from "../UploadFile/UploadFileSaga";
import MaintainceSaga from "../QuanlityManager/Maintaince/MaintainceSaga";
import POCoordinateSaga from "../QuanlityManager/ProductCoordination/POCoordinateSaga";
import RecordResultV2Saga from "../Machine/recordResults/Saga";
import ProdcutionLineSaga from "../MMProduction/ProductionLine/ProductionLineSaga";
import CommonGetCodeSaga from "../CommonGetCode/CommonGetCodeSaga";
import MachineryRepairSaga from "../QuanlityManager/MachineryRepair/MachineryRepairSaga";
import CommonCheckduplicatedSaga from "src/redux-saga-v2/CommonCheckduplicated/CommonCheckduplicatedSaga";
import permissionSystemSaga from "src/redux-saga-v2/Permission/permissionSaga";
import CommonDropdownVinmecSaga from "src/redux-saga-v2/commonDropdownVinmec/CommonDropdownVinmecSaga";
import AssetmentListSaga from "src/redux-saga-v2/AssetManagement/AssetmentPortfolio/AssetmentList/AssetmentListSaga";
import AssetmentTransferSaga from "src/redux-saga-v2/AssetManagement/AssetmentTransfer/AssetmentTransferSaga";
import CommonUploadFileSaga from "../CommonUpload/CommonUploadSaga";
import AssetmentStocktakingSaga from "src/redux-saga-v2/AssetManagement/AssetmentStocktaking/AssetmentStocktakingSaga";
import UserSyncSaga from "../Administration/UserManagement/UserManagementSaga";
import ResultInventorySaga from "src/redux-saga-v2/AssetManagement/AssetmentStocktaking/ResultInventory/ResultInventorySaga";
import InventoryUserConfigSaga from "src/redux-saga-v2/Administration/InventoryUserConfig/InventoryUserConfigSaga";

import AssetmentStocktakingReportSaga from "../AssetManagement/AssetmentStocktaking/Report/ReportSaga";
import RequestIncreaseAssetSaga from "src/redux-saga-v2/AssetManagement/AssetmentStocktaking/RequestIncreaseAsset/RequestIncreaseAssetSaga";
import PermissionDataSaga from "src/redux-saga-v2/Administration/PermissionData/PermissionDataSaga";

//-------------------------- register saga ------------------------------------
import CatHospitalSaga from "src/redux-saga-v2/MedicalSuppliesManagement/CatHospital/CatHospitalSaga";
import CatBarcodeSaga from "src/redux-saga-v2/MedicalSuppliesManagement/CatBarcode/CatBarcodeSaga";

import CatWarehouseSaga from "src/redux-saga-v2/MedicalSuppliesManagement/CatWarehouse/CatWarehouseSaga";
import UserHospitalWarehouseSaga from "src/redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseSaga";
import CatMedicalMaterialSaga from "src/redux-saga-v2/MedicalSuppliesManagement/CatMedicalMaterial/CatMedicalMaterialSaga";
import CatSupplierSaga from "src/redux-saga-v2/MedicalSuppliesManagement/CatSupplier/CatSupplierSaga";
import CatUnitMeasureSaga from "src/redux-saga-v2/MedicalSuppliesManagement/CatUnitMeasure/CatUnitMeasureSaga";

import DeliverySaga from "src/redux-saga-v2/DeliveryRefund/DeliveryManagement/DeliverySaga";
import ConsumablePackageSaga from "src/redux-saga-v2/ExportUsageManagement/ManageConsumablePackages/ConsumablePackageSaga";
import ExportUsageSaga from "src/redux-saga-v2/ExportUsageManagement/ExportUsage/ExportUsageSaga";
import PlanningSaga from "src/redux-saga-v2/BudgetShoppingManagement/Planning/PlanningSaga";
import PlanningDetailSaga from "src/redux-saga-v2/BudgetShoppingManagement/PlanningDetail/PlanningDetailSaga";
import ReturnSaga from "src/redux-saga-v2/DeliveryRefund/ReturnManagement/ReturnSaga";
import InventorySaga from "src/redux-saga-v2/InventoryManagement/Inventory/InventorySaga";
import CatProductSaga from "src/redux-saga-v2/MedicalSuppliesManagement/CatProduct/CatProductSaga";

import CatWarehouseProductSaga from "src/redux-saga-v2/MedicalSuppliesManagement/CatWarehouseProduct/CatWarehouseProductSaga";
import ConfigPlanningSaga from "src/redux-saga-v2/BudgetShoppingManagement/ConfigPlanning/ConfigPlanningSaga";
import InventoryTicketsSaga from "src/redux-saga-v2/InventoryManagement/InventoryTickets/InventoryTicketsSaga.js"
import LogManagementSaga from "../Administration/LogIntergrate/LogManagementSaga";
import LogJobSaga from "../Administration/LogJob/LogJobSaga";
import LogImpactSaga from "../Administration/LogImpact/LogImpactSaga";

// Báo cáo
import ReportSaga from "src/redux-saga-v2/Report/ReportSaga";

export default function* rootSagaV2() {
  yield all([
    ReactHookDemoSaga(),
    SOPSaga(),
    BomSaga(),
    ProductRequirmentSaga(),
    ApproveStepSaga(),
    CommonDropDownSaga(),
    ApprovalSaga(),
    WorkareaLinkProccessSaga(),
    RescheduleProductionAction(),
    ProcessSaga(),
    PackingLevelSaga(),
    PackingProcessSaga(),
    ProductPackagingSaga(),
    ProductIdentifierSaga(),
    RecordResultSaga(),
    LotQASaga(),
    actionLinkProductRequirement(),
    ItemAllocationSaga(),
    CheckpointSaga(),
    RepairSaga(),
    CheckListSaga(),
    UploadFileSaga(),
    ReportSaga(),
    MaintainceSaga(),
    POCoordinateSaga(),
    RecordResultV2Saga(),
    ProdcutionLineSaga(),
    CommonGetCodeSaga(),
    AccessoriesSaga(),
    MachineryRepairSaga(),
    CommonCheckduplicatedSaga(),
    permissionSystemSaga(),
    permissionSystemSaga(),
    CommonDropdownVinmecSaga(),
    AssetmentListSaga(),
    AssetmentTransferSaga(),
    CommonUploadFileSaga(),
    AssetmentStocktakingSaga(),
    UserSyncSaga(),
    ResultInventorySaga(),
    AssetmentStocktakingReportSaga(),
    InventoryUserConfigSaga(),
    RequestIncreaseAssetSaga(),
    PermissionDataSaga(),
    CatHospitalSaga(),
    CatWarehouseSaga(),
    UserHospitalWarehouseSaga(),
    CatMedicalMaterialSaga(),
    CatSupplierSaga(),
    CatUnitMeasureSaga(),
    ConsumablePackageSaga(),
    ExportUsageSaga(),
    PlanningSaga(),
    InventorySaga(),
    DeliverySaga(),
    PlanningDetailSaga(),
    CatBarcodeSaga(),
    CatProductSaga(),
    CatWarehouseProductSaga(),
    ConfigPlanningSaga(),
    ReturnSaga(),
    InventoryTicketsSaga(),
    LogManagementSaga(),
    LogJobSaga(),
    LogImpactSaga()
  ]);
}
