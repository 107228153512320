export const PackageInforLag = {
    language: "vn",
    package: {
        stackingnumber: "Số lượng xếp chồng",
        packagecode: "Mã package",
        packagetype: "Loại package",
        goodscode: "Mã sản phẩm",
        status: "Trạng thái",
        warehouselocation: "Vị trí kho",
        quantity: "Số lượng",
        unit: "Đơn vị",
        stacking: "Xếp chồng",
        printpackage: "In package",
        placeholder: "Mã, loại package, vị trí kho",
        confirmpackageremoval: "Xoá package",
        confirmdelete: "Bạn có chắc chắn muốn xóa package này?",
        form: {
            packagemalformed: "Mã package không đúng định dạng",
            packagecoderequired: "Mã package không được bỏ trống",
            packagecodelength: "Mã package không được quá 50 kí tự",
            productrequired: "Mã sản phẩm không được bỏ trống",
            packagetyperequired: "Loại package không được bỏ trống",
            packagetypelength: "Loại package không được quá 225 kí tự",
            unitrequired: "Đơn vị không được bỏ trống",
            unitlength: "Đơn vị không được quá 225 kí tự",
            qtywrong: "Chiều cao không đúng định dạng",
            heightrequired: "Chiều cao không được bỏ trống",
            heigthmaxlength: "Chiều cao không được quá 10 kí tự",
            lengthwrong: "Chiều dài không đúng định dạng",
            lengthmax: "Chiều dài không được quá 10 kí tự",
            widthwrong: "Chiều rộng không đúng định dạng",
            widtdrequired: "Chiều rộng không được bỏ trống",
            lengthrequired: "Chiều dài không được bỏ trống",
            widtdlength: "Chiều rộng không được quá 10 kí tự",
            stackingcoutwrong: "Số lượng xếp chồng không đúng định dạng",
            stackingcoutrequired: "Số lượng xếp chồng không được quá 10 kí tự",
            addnewpack: "Thêm mới thông tin package",
            updatepack: "Cập nhật thông tin package",
            detailpack: "Chi tiết thông tin package",
        },
        toastJs: {
            createOk: "Tạo mới package thành công",
            updateOk: "Cập nhật package thành công",
            deleteOk: "Xóa thành công  package"
        }
    }
};