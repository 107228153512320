import { call, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_CONSUMABLE_PACKAGE_SAGA,
  GET_DETAIL_CONSUMABLE_PACKAGE_SAGA,
  GET_PAGING_CONSUMABLE_PACKAGE_SAGA,
  REQUEST_PRINT_CONSUMABLE_SAGA,
  UPDATE_CONSUMABLE_PACKAGE_SAGA,
} from "./enum/TypeConsumablePackageAction";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import ConsumablePackageAction from "./ConsumablePackageAction";
import {
  getListConsumablePackagesService,
  createConsumablePackageService,
  updateConsumablePackageService,
  getDetailConsumablePackageService,
  getPrintConsumable
} from "./ConsumablePackageService";
import ConverDateToString from "src/shared/common/convertDateTime";
import { toast } from "react-toastify";

export function* getPagingSaga(data) {
  try {
    const SearchTerm = data.SearchTerm;
    const PageIndex = data.PageIndex;
    const PageSize = data.PageSize;
    const Status = data.Status;
    const CatHospitalIDList = data.CatHospitalIDList;
    const CatWarehouseIDList = data.CatWarehouseIDList;
    const StartDate = ConverDateToString(data.StartDate);
    const EndDate = ConverDateToString(data.EndDate);
    const Type = data.Type;
    const ListDataUser = data.ListDataUser;
    const request = {
      SearchTerm,
      PageIndex,
      PageSize,
      Status,
      CatHospitalIDList,
      CatWarehouseIDList,
      StartDate,
      EndDate,
      Type,
      ListDataUser,
    };

    const response = yield call(getListConsumablePackagesService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        openLoading();
        yield put(
            ConsumablePackageAction.getPagingSaga(
              request.SearchTerm,
              request.PageIndex - 1,
              request.PageSize,
              request.Status,
              request.CatHospitalIDList,
              request.CatWarehouseIDList,
              request.StartDate,
              request.EndDate,
              request.Type,
              request.ListDataUser,
              )
        );
      } else {
        yield put(ConsumablePackageAction.getPagingReducer(response));
        closeLoading();
      }
    }
  } catch (error) {
    closeLoading();
  }
}

export function* createDataSaga(input){
  try {
    const Id = 0;
    const Name = input.data.Name;
    const WarehouseId = input.data.WarehouseId;
    const HospitalId = input.data.HospitalId;
    const Status = input.data.Status;
    const ListConsumablePackageDetail = input.data.ListConsumableDetail?.map(item => ({
      ProductId: item.ProductId, //Id của vtyt
      QuantityActual: item.QuantityActual,
      //Id của chi tiết phiếu xuất
      Id: item?.ConsumablePackageId
    }));
    const Type = input.data.Type;
    console.log("createDataSaga", Type);
    const request = {
      Id,
      Name,
      WarehouseId,
      HospitalId,
      Status,
      ListConsumablePackageDetail,
      Type,
    }
    console.log(request);
    const response = yield call(createConsumablePackageService, request);
    if(response.isSuccess)
    {
      yield put(ConsumablePackageAction.createdSuccessedReducer(response));
    }
    else 
    {
      toast.error(response.userMessage);
    }
  } catch (error ) {
    closeLoading();
  }
  finally{
    // closeLoading();
  }
}

export function* updateDataSaga(input){
  try {
   
    const Id = input.data.Id;
    const Code = input.data.Code;
    const Name = input.data.Name;
    const WarehouseId = input.data.WarehouseId;
    const HospitalId = input.data.HospitalId;
    const Status = input.data.Status;
    const ListConsumablePackageDetail = input.data.ListConsumableDetail?.map(item => ({
      ProductId: item.ProductId, //Id của vtyt
      QuantityActual: item.QuantityActual,
      //Id của chi tiết phiếu xuất
      Id: item?.ConsumablePackageId
    }));
    const Type = input.data.Type;
      
    const request = {
      Id,
      Code,
      Name,
      WarehouseId,
      HospitalId,
      Status,
      ListConsumablePackageDetail,
      Type,
    }
    const response = yield call(updateConsumablePackageService, request);
    if(response.isSuccess)
    {
      yield put(ConsumablePackageAction.createdSuccessedReducer(response));
    }
    else 
    {
      toast.error(response.userMessage);
    }
  } catch (error ) {
    closeLoading();
  }
  finally{
    // closeLoading();
  }
}

export function* getDetailDataSaga(data){
  try {
    
    const consumableId = data.data;
    const response = yield call(getDetailConsumablePackageService, consumableId);
    if(response.isSuccess)
        yield put(ConsumablePackageAction.getDetailConsumableSuccessedReducer(response));
  } catch (error ) {
    closeLoading();
  }
  finally{
    closeLoading();
  }
}


export function* getPrintConsumableDataSaga(data) {
  try {
      const response = yield call(getPrintConsumable, data.data);
      if (response) {
          yield put(ConsumablePackageAction.getPrintConsumableReducer(response.data));
          closeLoading();
      }
  }
  catch (error) {
      console.error(error);
      closeLoading();
  }
  
}

export default function* ConsumablePackagesSaga(data) {
  yield takeEvery(GET_PAGING_CONSUMABLE_PACKAGE_SAGA, getPagingSaga);
  yield takeEvery(CREATE_CONSUMABLE_PACKAGE_SAGA, createDataSaga);
  yield takeEvery(UPDATE_CONSUMABLE_PACKAGE_SAGA, updateDataSaga);
  yield takeEvery(GET_DETAIL_CONSUMABLE_PACKAGE_SAGA, getDetailDataSaga);
  yield takeEvery(REQUEST_PRINT_CONSUMABLE_SAGA, getPrintConsumableDataSaga);
}
