export const PetitionerManager = {
    language: "vi",
    petitoner: {
        palaceholderSearch: "Mã nguồn yêu cầu, sloc chuyển đến, mô tả...",
        table: {
            code: "Mã nguồn yêu cầu",
            sloc: "Sloc chuyển đến",
            des: "Mô tả",
            status: "Trạng thái",
            active: "Đang hoạt động",
        },
        form: {
            code: "Mã nguồn yêu cầu",
            sloc: "Sloc chuyển đến",
            des: "Mô tả",
            active: "Đang hoạt động",
        },
        mes: {
            emptycode: "Mã nguồn yêu cầu không được bỏ trống ",
            emptysloc: "Sloc chuyển đến không được bỏ trống",
            codeLenght: "Mã nguồn yêu cầu không được quá 50 ký tự",
            sloclenght: "Sloc không được quá 50 ký tự",
            desLenght: "Mô tả không được quá 255 ký tự",
            codeErr: "Mã không đúng định dạng"
        },
        delete: {
            tittle: "Xác nhận xóa mã nguồn yêu cầu này",
            confirmLabel: "Bạn có chắc muốn xóa mã nguồn yêu cầu này",
        },
        toastJs: {
            createOk: "Tạo mới nguồn yêu cầu thành công",
            updateOk: "Cập nhật thành công nguồn yêu cầu",
            deteleOk: "Xóa thành công nguồn yêu cầu"
        }
    }
}