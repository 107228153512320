import { call, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_RETURN_SAGA,
  DELETE_RETURN_SAGA,
  GET_INFO_RETURN_BY_ID_SAGA,
  GET_PAGING_RETURN_SAGA,
  GET_PAGING_RETAIL_RETURN_SAGA,
  UPDATE_RETURN_SAGA,
  RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA
} from "./enum/TypeReturnAction";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import ReturnAction from "./ReturnAction";
import {
  getListReturnService, deleteReturnService, createReturnService, updateReturnService, getInfoReturnByIdService, ReturnPredictabilityRetailByListProductService
} from "./ReturnService";

export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    let hospitalId = data.hospitalId
    let warehouseReturnId = data.warehouseReturnId || []
    let status = data.status ? data.status : []
    let warehouseRecoveryId = data.warehouseRecoveryId ? data.warehouseRecoveryId : ""
    let startDateExport = data.startDateExport ? data.startDateExport : ""
    let endDateExport = data.endDateExport ? data.endDateExport : ""
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      HospitalId: hospitalId,
      ListWarehouseReturnId: warehouseReturnId,
      WarehouseRecoveryId: warehouseRecoveryId,
      Status: status,
      StartDateExport: startDateExport,
      EndDateExport: endDateExport,
      SearchTerm: searchTerm,
    };
    const response = yield call(getListReturnService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        openLoading();
        yield put(
          ReturnAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.HospitalId,
            request.ListWarehouseReturnId,
            request.WarehouseRecoveryId,
            request.Status,
            request.StartDateExport,
            request.EndDateExport,
            request.SearchTerm
          )
        );
      } else {
        yield put(ReturnAction.getPagingReducer(response));
        closeLoading();
      }
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getRetailReturnPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    let hospitalId = data.hospitalId
    let warehouseReturnId = data.warehouseReturnId
    let status = data.status ? data.status : []
    let warehouseRecoveryId = data.warehouseRecoveryId ? data.warehouseRecoveryId : ""
    let startDateExport = data.startDateExport ? data.startDateExport : ""
    let endDateExport = data.endDateExport ? data.endDateExport : ""
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      hospitalId: hospitalId,
      WarehouseReturnId: warehouseReturnId,
      WarehouseRecoveryId: warehouseRecoveryId,
      status: status,
      StartDateExport: startDateExport,
      EndDateExport: endDateExport,
      searchTerm: searchTerm,
    };
    const response = yield call(getListReturnService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          ReturnAction.getPagingRetailReturnReducer(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm
          )
        );
      } else {
        yield put(ReturnAction.getPagingRetailReturnReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* deleteReturnSaga(request) {
  try {
    if (request.id) {
      const response = yield call(deleteReturnService, request.id);
      if (response.isSuccess) {
        yield put(ReturnAction.deleteDataReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* ReturnPredictabilityRetailByListProductSaga(requet) {
  let data = {
    warehouseId: requet.warehouseId,
    approveWarehouseId: requet.approveWarehouseId,
    listProductId: requet.listProductId
  }
  try {
    const response = yield call(ReturnPredictabilityRetailByListProductService, data);
    if (response.isSuccess) {
      yield put(ReturnAction.ReturnPredictabilityRetailByListProductReducer(response.data));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}


export function* createReturnSaga(requet) {
  try {
    if (requet.data) {
      requet.data.Status = requet.saveType;
      const response = yield call(createReturnService, requet.data);
      if (response) {
        yield put(ReturnAction.createDataReducer(response));
      }
      // closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* updateReturnSaga(requet) {
  try {
    if (requet.data) {
      requet.data.Status = requet.saveType;
      const response = yield call(updateReturnService, requet.data);
      if (response) {
        yield put(ReturnAction.updateDataReducer(response));
      }
      // closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getInfoReturnByIdSaga(requet) {
  try {
    if (requet.data) {
      const response = yield call(getInfoReturnByIdService, requet.data);
      if (response.isSuccess) {
        yield put(ReturnAction.getInfoReturnReducer(response.data));
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export default function* ReturnSaga(data) {
  yield takeEvery(GET_PAGING_RETURN_SAGA, getPagingSaga);
  yield takeEvery(DELETE_RETURN_SAGA, deleteReturnSaga);
  yield takeEvery(CREATE_RETURN_SAGA, createReturnSaga);
  yield takeEvery(UPDATE_RETURN_SAGA, updateReturnSaga);
  yield takeEvery(GET_INFO_RETURN_BY_ID_SAGA, getInfoReturnByIdSaga);
  yield takeEvery(GET_PAGING_RETAIL_RETURN_SAGA, getRetailReturnPagingSaga);
  yield takeEvery(RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA, ReturnPredictabilityRetailByListProductSaga);
  //getInfoReturnSaga
}
