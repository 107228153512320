import { call, put, takeEvery } from "redux-saga/effects";
import {
    DELETE_INVENTORY_PLAN_SAGA,
    GET_COST_CENTER_EV5_BY_SEGMENT_SAGA,
    CREATE_INVENTORY_PLAN_SAGA,
    GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_SAGA,
    UPDATE_INVENTORY_PLAN_SAGA
} from "./enum/TypeAssetmentStocktakingAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import AssetmentStocktakingAction from "./AssetmentStocktakingAction";
import {
    DeleteDataService,
    GetCostCenterEV5BySegmentService,
    CreateInventoryPlanService,
    GetInventoryPlanByIdForEditService,
    UpdateInventoryPlanService
} from "./AssetmentStocktakingService";
import dayjs from "dayjs";

export function* deleteDataSaga(data) {
    try {
        const response = yield call(DeleteDataService, data.id);
        if (response.isSuccess) {
            yield put(AssetmentStocktakingAction.deleteDataReducer());
        }
        closeLoading();
    } catch (error) {
        closeLoading();
    }
}
export function* getCostCenterEV5BySegmentSaga(data) {
    try {
        let lstEv5 = data?.request?.lstEv5;
        let request = "?segmentId=" + data?.request?.segmentId + "&evaluationGroupIds=" + lstEv5;
        const response = yield call(GetCostCenterEV5BySegmentService, request);
        if (response.isSuccess) {
            yield put(AssetmentStocktakingAction.getCostCenterEV5BySegmentReducer(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();
    }
}
export function* CreateInventoryPlanSaga(data) {
    try {
        if (data?.request) {
            data.request.Name = data.request?.Name?.trim();
            data.request.EstimatedTimeFrom = data.request?.EstimatedTimeFrom ? dayjs(data.request.EstimatedTimeFrom).format("YYYY-MM-DD") : null;
            data.request.EstimatedTimeTo = data.request?.EstimatedTimeTo ? dayjs(data.request.EstimatedTimeTo).format("YYYY-MM-DD") : null;
            if (data?.request?.ListInventoryPlanSession && data?.request?.ListInventoryPlanSession?.length > 0) {
                data?.request?.ListInventoryPlanSession?.map((item, index) => {
                    item.StartTime = item.StartTime ? dayjs(item?.StartTime).format("YYYY-MM-DD") : null;
                    item.EndTime = item.EndTime ? dayjs(item?.EndTime).format("YYYY-MM-DD") : null;
                    let listItemDelete = [];
                    let listItemActive = [];
                    item.EvaluationGroup5Ids = item?.EvaluationGroup5Ids?.length > 0 ? item?.EvaluationGroup5Ids?.join(",") : "";
                    listItemDelete = item?.ListInventoryPlanSessionItem?.filter(x => x.IsDeleted == true);
                    listItemActive = item?.ListInventoryPlanSessionItem?.filter(x => x.IsDeleted == false);
                    if (listItemDelete?.length > 0) {
                        listItemDelete?.map((k) => {
                            if (k.Id > 0) {
                                listItemActive?.push(k);
                            }
                        });
                    }
                    listItemActive?.map((obj) => {
                        obj.UserInventory = obj?.UserInventory?.length > 0 ? obj?.UserInventory?.join(",") : "";
                    });
                    item.ListInventoryPlanSessionItem = listItemActive;
                });
            }
            const response = yield call(CreateInventoryPlanService, data.request);
            if (response.isSuccess) {
                yield put(AssetmentStocktakingAction.callAPICreateSuccessReducer(response.data));
            }
            closeLoading();
        }
    } catch (error) {
        closeLoading();
    }
}
export function* UpdateInventoryPlanSaga(data) {
    try {
        if (data?.request) {
            data.request.Name = data.request?.Name?.trim();
            data.request.EstimatedTimeFrom = data.request?.EstimatedTimeFrom ? dayjs(data.request.EstimatedTimeFrom).format("YYYY-MM-DD") : null;
            data.request.EstimatedTimeTo = data.request?.EstimatedTimeTo ? dayjs(data.request.EstimatedTimeTo).format("YYYY-MM-DD") : null;
            if (data?.request?.ListInventoryPlanSession && data?.request?.ListInventoryPlanSession?.length > 0) {
                data?.request?.ListInventoryPlanSession?.map((item, index) => {
                    item.StartTime = item.StartTime ? dayjs(item?.StartTime).format("YYYY-MM-DD") : null;
                    item.EndTime = item.EndTime ? dayjs(item?.EndTime).format("YYYY-MM-DD") : null;
                    let listItemUpdate = [];
                    item.EvaluationGroup5Ids = item?.EvaluationGroup5Ids?.length > 0 ? item?.EvaluationGroup5Ids?.join(",") : "";
                    listItemUpdate = item?.ListInventoryPlanSessionItem?.filter(x => (x.IsDeleted == true && x.Id > 0) || x.IsDeleted == false);
                    listItemUpdate?.map((obj) => {
                        obj.UserInventory = obj?.UserInventory?.length > 0 ? obj?.UserInventory?.join(",") : "";
                    });
                    item.ListInventoryPlanSessionItem = listItemUpdate;
                });
            }
            const response = yield call(UpdateInventoryPlanService, data.request);
            if (response.isSuccess) {
                yield put(AssetmentStocktakingAction.callAPIUpdateSuccessReducer());
            }
            closeLoading();
        }
    } catch (error) {
        closeLoading();
    }
}

export function* GetInventoryPlanByIdForEdit(request) {
    try {
        if (request.id > 0) {
            const response = yield call(GetInventoryPlanByIdForEditService, request.id);
            if (response.isSuccess) {
                yield put(AssetmentStocktakingAction.GetInventoryPlanByIdForEditReducer(response.data));
            }
            closeLoading();
        }
    } catch (error) {
        closeLoading();
    }
}

export default function* AssetmentStocktakingSaga(data) {
    yield takeEvery(GET_COST_CENTER_EV5_BY_SEGMENT_SAGA, getCostCenterEV5BySegmentSaga);
    yield takeEvery(DELETE_INVENTORY_PLAN_SAGA, deleteDataSaga);
    yield takeEvery(CREATE_INVENTORY_PLAN_SAGA, CreateInventoryPlanSaga);
    yield takeEvery(UPDATE_INVENTORY_PLAN_SAGA, UpdateInventoryPlanSaga);
    yield takeEvery(GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_SAGA, GetInventoryPlanByIdForEdit);
}
