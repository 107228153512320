export const GET_DATA_MAINTAINCE_CALENDAR_SAGA = "GET_DATA_MAINTAINCE_CALENDAR_SAGA";
export const SET_DATA_MAINTAINCE_CALENDAR_REDUCER = "SET_DATA_MAINTAINCE_CALENDAR_REDUCER";
export const DELETE_PERIOD_MAINTANCE_SAGA = "DELETE_PERIOD_MAINTANCE_SAGA";
export const CALL_DELETE_PERIOD_SUCCESS_REDUCER = "CALL_DELETE_PERIOD_SUCCESS_REDUCER";
export const GET_DETAIL_PERIOD_SAGA = "GET_DETAIL_PERIOD_SAGA";
export const SET_OBJ_DETAIL_PERIOD = "SET_OBJ_DETAIL_PERIOD";
export const CREATE_PERIOD_MAINTANCE_SAGA = "CREATE_PERIOD_MAINTANCE_SAGA";
export const CALL_CREATE_OR_UPDATE_PERIOD_SUCCESS_REDUCER = "CALL_CREATE_OR_UPDATE_PERIOD_SUCCESS_REDUCER";
export const UPDATE_PERIOD_MAINTANCE_SAGA = "UPDATE_PERIOD_MAINTANCE_SAGA";
export const GET_DATA_MAINTAINCE_SCHEDULE_SAGA = "GET_DATA_MAINTAINCE_SCHEDULE_SAGA";
export const SET_DATA_MAINTAINCE_SCHEDULE_REDUECR = "SET_DATA_MAINTAINCE_SCHEDULE_REDUECR";
export const CREATE_PERIOD_SCHEDULE_SAGA = "CREATE_PERIOD_SCHEDULE_SAGA";
export const SET_LIST_DATA_PERIOD_SCHEDULE_REDUCER = "SET_LIST_DATA_PERIOD_SCHEDULE_REDUCER";
export const CREATE_MAINTAINCE_SCHEDULE_SAGA = "CREATE_MAINTAINCE_SCHEDULE_SAGA";
export const CREATE_UPDATE_SCHEDULE_SUCCESS_REDUCER = "CREATE_UPDATE_SCHEDULE_SUCCESS_REDUCER";
export const GET_DETAIL_MAINTANCE_SCHEDULE_SAGA = "GET_DETAIL_MAINTANCE_SCHEDULE_SAGA";
export const SET_DETAIL_MAINTANCE_SCHEDULE_REDUCER = "SET_DETAIL_MAINTANCE_SCHEDULE_REDUCER";
export const CARRY_MAINTANCE_SCHEDULE_SAGA = "CARRY_MAINTANCE_SCHEDULE_SAGA";
export const SKIP_MAINTANCE_SCHEDULE_SAGA = "SKIP_MAINTANCE_SCHEDULE_SAGA";
export const CHANGE_DATA_MAINTAIN_REDUCER = "CHANGE_DATA_MAINTAIN_REDUCER";