export const LocationLag = {
    language: "en",
    loaction: {
        rank: "Rank",
        rulescreatecode: "Rules for creating sloc Code",
        subordinaterank: "Subordinate rank",
        notinanysubordinate: "Not in any subordinate ranks yet",
        action: "Action",
        seedetails: "See details",
        edit: "Edit",
        nodata: "No data to display",
        confirmdelete: "Delete location hierarchy",
        confirmdeletetrue: "Are you sure you want to delete this location hierarchy?",
        slocname: "Sloc name",
        slocbelong: "Sloc belong",
        lenght: "Length",
        width: "Width",
        height: "Height",
        manager: "Manager",
        asaliverepository: "As a live repository",
        codenamerank: "Code, name, rank",
        confirmdelete2: "Delete location",
        deleteconfirm: "Are you sure you want to delete this location?",
        form: {
            rankrequired: "Rank cannot be left blank",
            rankmaxlength: "Rank cannot exceed 255 characters",
            detaillocation: "Location level details",
            configgenrequired: "Rules for creating sloc codes cannot be left blank",
            addnewlocation: "Add location level",
            updatelocation: "Update location rank information",
            rulegencode: "Rules for generating code sloc",
            levelmother: "Parent level",
            ranknotepmty: "Rank cannot be left blank",
            sloccodeerrorformat: "The sloc code is not in the correct format",
            cloccodenotnull: "The sloc code cannot be left blank",
            cloccodemaxlength: "Sloc code cannot exceed 50 characters",
            length0: "Length must be number",
            height0: "Height must be number",
            width0: "Width must be number",
            nameslocnotnull: "Sloc name cannot be empty",
            nameslocmaxlength: "Sloc name cannot exceed 255 characters",
            slocenamespecial: "Sloc names do not contain special symbols",
            widthrequired: "Width cannot be empty",
            heightrequired: "Height cannot be empty",
            lengthrequired: "Length cannot be empty",
            detailinforlocation: "Detailed location information",
            addnewlocation2: "Add location",
            changelocationinfor: "Update location information",
            sloccode: "Sloc code",
        },
        toastJs: {
            createOK: "Successfully created new location",
            updateOk: "Location update successful",
            deleteOK: "Delete location successfully",
            createLevelOK: "Create new location rank successfully",
            updateLevelOK: "Location rank update successful",
            deleteLevelOK: "Successfully deleted the location rank"
        }
    }
}