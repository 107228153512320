export const NCCInformation = {
    language: "vi",
    NCCInformation: {
        searchNCC: "Mã, tên, mã số thuế đối tác",
        tableNCC: {
            number: "STT",
            partnerCode: "Mã đối tác",
            partnerName: "Tên đối tác",
            note: " Ghi chú",
            partnerType: "Loại đối tác",
            address: "Địa chỉ",
            phoneNumber: "Số điện thoại",
            bankAccount: "Tài khoản ngân hàng",
            taxCode: "Mã số thuế",
            active: "Đang hoạt động"
        },
        deleteNCC: {
            title: "Xác nhận xóa đối tác",
            description: "Bạn có chắc chắn muốn xóa đối tác này?"
        },
        btnNCC: {
            delete: "Xóa",
            close: "Đóng",
            confirmStorePackages: "Đồng ý"
        },
        typeDropdown: {
            ncc: "Nhà cung cấp",
            kh: "Khách hàng"
        },
        rulesForm: {
            partnerCodeErr: "Mã đối tác không đúng định dạng",
            partnerCodeNotNull: "Mã đối tác không được bỏ trống",
            partnerCodeLength: "Mã đối tác không được quá 50 kí tự",
            partnerNameNotNull: "Tên đối tác không được bỏ trống",
            partnerNameLength: "Tên đối tác không được quá 255 ký tự",
            partnerType: "Loại đối tác không được bỏ trống",
            emailErr: "Email không đúng định dạng",
            emailLength: "Email không được quá 255 ký tự",
            PhoneErr: "Số điện thoại không đúng định dạng",
            phoneLength: "Số điện thoại không được quá 11 ký tự",
            bankAccount: "Tài khoản ngân hàng không được quá 255 ký tự",
            taxCode: "Mã số thuế không được quá 20 ký tự",
            address: "Địa chỉ không được quá 255 ký tự",
            note: "Ghi chú không được quá 255 ký tự",
            taxCodeErr: "Mã số thuế không đúng định dạng"
        },
        titleForm: {
            create: "Thêm thông tin đối tác",
            update: "Cập nhật thông tin đối tác",
            view: "Chi tiết thông tin đối tác"
        },
        toastJs: {
            createOK: "Tạo mới dữ liệu đối tác thành công",
            updateOK: "Cập nhật dữ liệu đối tác thành công",
            deleteOK: "Xóa dữ liệu đối tác thành công"
        }
    }
}
