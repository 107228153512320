import * as type from "./enum/PackingProcessTypeAction";
const stateDefault = {
    listData: [],
    dropdownPackingLevel: [],
    packingProcessDetail: {},
    callResultFromApi: false,
    callApiGetPackingProcessById: false,
    callFalseFromApi: false,
    setErrorToReducer: false,
}
const PackingProcessReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case type.SET_ALL_PACKING_PROCESS_REDUCER:            
            if (action.data) {
                state.listData = action.data;
            }
            return {
                ...state,
            };
        case type.SET_DROP_DOWN_PACKING_LEVEL_REDUCER:
            if (action.data) {
                state.dropdownPackingLevel = action.data;
            }
            return {
                ...state,
            };
        case type.SET_PACKING_PROCESS_ID_REDUCER:
            if (action.data) {
                state.packingProcessDetail = action.data;
                state.callApiGetPackingProcessById = true;
            }
            return {
                ...state,
            };
        case type.CALL_RESULT_PACKING_PROCESS_REDUCER:
            state.callResultFromApi = true;
            return {
                ...state,
            };
        case type.CALL_DEFAULT_PACKING_PROCESS_REDUCER:
            state.callResultFromApi = false;
            state.callApiGetPackingProcessById = false;
            state.callFalseFromApi = false;
            return {
                ...state,
            };
        case type.CALL_FALSE_FROM_REDUCER:
            state.callFalseFromApi = true;
            return {
                ...state,
            }
        case type.SET_ERROR_TO_REDUCER:
            state.setErrorToReducer = true;
            return {
                ...state,
            }
        case type.SET_DEFAULT_ERROR_FROM_REDUCER:
            state.setErrorToReducer = false;
            return {
                ...state,
            }
        case type.CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };
        default:
            return {
                ...state,
            };
    }
};
export default PackingProcessReducer