export default function LoadingJs() {
  return (
    <div
      className="loading"
      id="loading-main"
    >
      <div className="vinm-spinner">
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
        <div>{}</div>
      </div>
    </div>
  );
}
