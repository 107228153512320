export const formTypeEnum = {
  Update: 1,
  Create: 2,
  Detail: 3,
};

export const statusEnum ={
  IsActive:1,
  NotIsActive:0,
}
export const typeConfirmEnum = {
  DeleteConfirm:1,
  UpdateConfirm:2,
}