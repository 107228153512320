import {
    GET_ASSETMENT_INVENTORY_RESULTS_SAGA,
    GET_ASSETMENT_INVENTORY_RESULTS_REDUCER,
    CLEAR_ASSETMENT_INVENTORY_RESULT_REDUCER,
    CALL_DEFAULT_INVENTORY_RESULT_REDUCER,
    GET_DETAIL_INVENTORY_RESULT_SAGA,
    GET_DETAIL_INVENTORY_RESULT_REDUCER,
    GET_ASSETMENT_INVENTORY_INFO_SAGA,
    GET_ASSETMENT_INVENTORY_INFO_REDUCER,
    UPDATE_ASSETMENT_INVENTORY_INFO_SAGA,
    UPDATE_ASSETMENT_INVENTORY_INFO_REDUCER,
    GET_SUMMARY_RESULT_INVENTORY_SAGA,
    GET_SUMMARY_RESULT_INVENTORY_REDUCER,
    GET_INVENTORY_ASSET_SCAN_SAGA,
    GET_INVENTORY_ASSET_SCAN_REDUCER,
    INVENTORY_ASSET_SUBMIT_REPORT_SAGA,
    INVENTORY_ASSET_SUBMIT_REPORT_REDUCER,
    INVENTORY_COMPLETION_CONFIRMATION_SAGA,
    INVENTORY_COMPLETION_CONFIRMATION_REDUCER,
    CREATE_MANUAL_INVENTORY_ASSET_SAGA,
    CREATE_MANUAL_INVENTORY_ASSET_REDUCER,
    EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_SAGA,
    EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_SAGA

} from "./enum/TypeResultInventoryAction.js";

const ResultInventoryAction = {
    getAssetmentInventoryResultSaga: (pageIndex, pageSize, searchText, objectFilter) => {
        return {
            type: GET_ASSETMENT_INVENTORY_RESULTS_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchText: searchText,
            objectFilter: objectFilter
        };
    },
    getAssetmentInventoryResultReducer: (data) => {
        return {
            type: GET_ASSETMENT_INVENTORY_RESULTS_REDUCER,
            data: data,
        };
    },
    getDetailInventoryResultSaga: (pageIndex, pageSize, objectFilter, InventoryPlanId) => {
        return {
            type: GET_DETAIL_INVENTORY_RESULT_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            objectFilter: objectFilter,
            InventoryPlanId: InventoryPlanId
        };
    },
    getDetailInventoryResultReducer: (data) => {
        return {
            type: GET_DETAIL_INVENTORY_RESULT_REDUCER,
            data: data,
        };
    },
    getAssetmentInventoryInfoSaga: (Id, pageSize, pageIndex, SearchTerm, TypeDifference) => {
        return {
            type: GET_ASSETMENT_INVENTORY_INFO_SAGA,
            Id: Id,
            PageSize: pageSize,
            PageIndex: pageIndex,
            SearchTerm: SearchTerm,
            TypeDifference: TypeDifference,
        };
    },
    getAssetmentInventoryInfoReducer: (data) => {
        return {
            type: GET_ASSETMENT_INVENTORY_INFO_REDUCER,
            data: data,
        };
    },
    updateAssetmentInventoryInfoSaga: (data) => {
        return {
            type: UPDATE_ASSETMENT_INVENTORY_INFO_SAGA,
            data: data
        };
    },
    updateAssetmentInventoryInfoReducer: () => {
        return {
            type: UPDATE_ASSETMENT_INVENTORY_INFO_REDUCER,
        };
    },
    getSummaryResultInventorySaga: (Id) => {
        return {
            type: GET_SUMMARY_RESULT_INVENTORY_SAGA,
            Id: Id
        };
    },
    getSummaryResultInventoryReducer: (data) => {
        return {
            type: GET_SUMMARY_RESULT_INVENTORY_REDUCER,
            data: data,
        };
    },
    getInventoryAssetScanSaga: (inventoryPlanSessionItemId, assetId, type) => {
        return {
            type: GET_INVENTORY_ASSET_SCAN_SAGA,
            inventoryPlanSessionItemId: inventoryPlanSessionItemId,
            assetId: assetId,
            types: type,
        };
    },
    getInventoryAssetScanReducer: (data) => {
        return {
            type: GET_INVENTORY_ASSET_SCAN_REDUCER,
            data: data,
        };
    },
    submitReportSaga: (InventoryPlanId) => {
        return {
            type: INVENTORY_ASSET_SUBMIT_REPORT_SAGA,
            InventoryPlanId: InventoryPlanId,
        };
    },
    submitReportReducer: () => {
        return {
            type: INVENTORY_ASSET_SUBMIT_REPORT_REDUCER,
        };
    },

    confirmationInventoryCompletionSaga: (InventoryPlanSessionItemId) => {
        return {
            type: INVENTORY_COMPLETION_CONFIRMATION_SAGA,
            InventoryPlanSessionItemId: InventoryPlanSessionItemId,
        };
    },
    confirmationInventoryCompletionReducer: () => {
        return {
            type: INVENTORY_COMPLETION_CONFIRMATION_REDUCER,
        };
    },
    createManualInventoryAssetSaga: (InventoryPlanSessionItemId, AssetId) => {
        return {
            type: CREATE_MANUAL_INVENTORY_ASSET_SAGA,
            InventoryPlanSessionItemId: InventoryPlanSessionItemId,
            AssetId: AssetId
        };
    },
    createManualInventoryAssetReducer: () => {
        return {
            type: CREATE_MANUAL_INVENTORY_ASSET_REDUCER,
        };
    },
    exportExcelInventoryResultDetailSaga: (data) => {
        return {
            type: EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_SAGA,
            request: data
        };
    },
    exportExcelInventoryAssetInformationSaga: (data) => {
        return {
            type: EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_SAGA,
            request: data
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_ASSETMENT_INVENTORY_RESULT_REDUCER,
        };
    },
    callDefault: () => {
        return {
            type: CALL_DEFAULT_INVENTORY_RESULT_REDUCER,
        };
    },
};

export default ResultInventoryAction;
