export const GET_PAGE_ASSETMENT_API = "/api/service/Asset/SearchAsset";
export const EXPORT_EXCEL_ASSETMENT_LIST_API = "/api/service/Asset/AssetExportExcel";
export const GET_ASSET_BY_ID_API = "/api/service/Asset/GetAssetById?assetId=";
export const UPDATE_ASSET_API = "/api/service/Asset/UpdateAsset";
export const UPDATE_MANY_ASSET_API = "/api/service/Asset/UpdateMultiAsset";
export const GET_LIST_ASSET_HISTORY_API = "/api/service/Asset/SearchAssetHistory";
export const GET_ALL_ASSET_STAMP_HISTORY_API = "/api/service/Asset/GetAllAssetStampHistory?assetId=";
// export const REQUEST_PRINT_STAMP_API = "/api/service/Asset/PrintStamp";
export const REQUEST_PRINT_STAMP_API = "/api/service/CatProduct/PrintStamp";

