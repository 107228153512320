import {
  GET_PAGING_DELIVERY_SAGA,

  GET_PAGING_DELIVERY_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_DELIVERY_SAGA,
  DELETE_DELIVERY_REDUCER,
  CREATE_DELIVERY_REDUCER,
  CREATE_DELIVERY_SAGA,
  UPDATE_DELIVERY_REDUCER,
  UPDATE_DELIVERY_SAGA,
  GET_INFO_DELIVERY_BY_ID_REDUCER,
  GET_INFO_DELIVERY_BY_ID_SAGA,
  GET_PAGING_RETAIL_DELIVERY_SAGA,
  GET_PAGING_RETAIL_DELIVERY_REDUCER,
  DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA,
  SET_TRANSACTION_DETAIL_LIST_REDUCER,
  DOWNLOAD_EXCEL_SAGA,
  RESET_GET_INFO_DELIVERY_BY_ID_REDUCER,

} from "./enum/TypeDeliveryAction";

// const stateDefault = {
//   listData: [],
//   isGetLstData: false,
//   isDeleteSuccess: false,
//   isCreateUpdateSuccess: false,
// };

const DeliveryAction = {
  getPagingSaga: (pageIndex, pageSize, hospitalId, warehouseId, approveWarehouseId, status, typeDelivery, startDateExport, endDateExport, searchTerm = "") => {
    return {
      type: GET_PAGING_DELIVERY_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseId: warehouseId,
      approveWarehouseId: approveWarehouseId,
      status: status,
      typeDelivery: typeDelivery,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      searchTerm: searchTerm,
    };
  },
  getPagingRetailDeliverySaga: (pageIndex, pageSize, hospitalId, warehouseId, approveWarehouseId, status, typeDelivery, startDateExport, endDateExport, searchTerm = "") => {
    return {
      type: GET_PAGING_RETAIL_DELIVERY_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseId: warehouseId,
      approveWarehouseId: approveWarehouseId,
      status: status,
      typeDelivery: typeDelivery,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      searchTerm: searchTerm,
    };
  },
  getPagingRetailDeliveryReducer: (data) => {
    return {
      type: GET_PAGING_RETAIL_DELIVERY_REDUCER,
      data: data,
    };
  },
  deleteDataSaga: (id) => {
    return {
      type: DELETE_DELIVERY_SAGA,
      id: id,
    };
  },
  deleteDataReducer: () => {
    return {
      type: DELETE_DELIVERY_REDUCER,
    };
  },
  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_DELIVERY_REDUCER,
      data: data,
    };
  },
  createDataReducer: (data) => {
    return {
      type: CREATE_DELIVERY_REDUCER,
      data: data,
    };
  },
  createDataSaga: (data, statusSave) => {
    return {
      type: CREATE_DELIVERY_SAGA,
      data: data,
      statusSave:statusSave
    };
  },
  updateDataReducer: (data) => {
    return {
      type: UPDATE_DELIVERY_REDUCER,
      data: data,
    };
  },
  updateDataSaga: (data, statusSave) => {
    return {
      type: UPDATE_DELIVERY_SAGA,
      data: data,
      statusSave:statusSave
    };
  },
  getInfoDeliveryReducer: (data) => {
    return {
      type: GET_INFO_DELIVERY_BY_ID_REDUCER,
      data: data,
    };
  },
  resetGetInfoDeliveryReducer: () => {
    return {
      type: RESET_GET_INFO_DELIVERY_BY_ID_REDUCER,
    };
  },
  getInfoDeliverySaga: (data) => {
    return {
      type: GET_INFO_DELIVERY_BY_ID_SAGA,
      data: data,
    };
  },
  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },

  setTransactionDetailList: (data) => {
    return {
      type: SET_TRANSACTION_DETAIL_LIST_REDUCER,
      data: data,
    };
  },
  downloadExcelSaga:(data) =>{
    return {
        type: DOWNLOAD_EXCEL_SAGA,
        data: data
    };
},
  // tính toán tồn kho lẻ phiếu lĩnh nếu chọn vậy tu y tế
  DeliveryPredictabilityRetailByListProductSaga: (warehouseId, approveWarehouseId, listProductId) => {
    return {
      type: DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA,
      warehouseId: warehouseId,
      approveWarehouseId: approveWarehouseId,
      listProductId: listProductId
    };
  },

}

export default DeliveryAction;
