export const GET_PAGING_CONSUMABLE_PACKAGE_SAGA = "GET_PAGING_CONSUMABLE_PACKAGE_SAGA";
export const GET_PAGING_CONSUMABLE_PACKAGE_REDUCER = "GET_PAGING_CONSUMABLE_PACKAGE_REDUCER";
export const CREATED_CONSUMABLE_PACKAGE_PACKAGE_REDUCER = "CREATED_CONSUMABLE_PACKAGE_PACKAGE_REDUCER";
export const GET_DETAIL_CONSUMABLE_PACKAGE_PACKAGE_REDUCER = "GET_DETAIL_CONSUMABLE_PACKAGE_PACKAGE_REDUCER";
export const SET_DETAIL_DATA_DEFAULT_REDUCER = "SET_DETAIL_DATA_DEFAULT_REDUCER";
export const CREATE_CONSUMABLE_PACKAGE_SAGA = "CREATE_CONSUMABLE_PACKAGE_SAGA";
export const UPDATE_CONSUMABLE_PACKAGE_SAGA = "UPDATE_CONSUMABLE_PACKAGE_SAGA";
export const GET_DETAIL_CONSUMABLE_PACKAGE_SAGA = "GET_DETAIL_CONSUMABLE_PACKAGE_SAGA";

export const REQUEST_PRINT_CONSUMABLE_SAGA = "REQUEST_PRINT_CONSUMABLE_SAGA";
export const GET_PRINT_CONSUMABLE_DATA_REDUCER = "GET_PRINT_CONSUMABLE_DATA_REDUCER";
export const RESET_GET_PRINT_CONSUMABLE_DATA_REDUCER = "RESET_GET_PRINT_CONSUMABLE_DATA_REDUCER";

export const SET_HOSPITALID_AND_WAREHOUSEID_REDUCER = "SET_HOSPITALID_AND_WAREHOUSEID_REDUCER";
export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
