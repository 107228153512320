import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import * as typesApi from "./enum/LogImpactTypeApi";

export async function getLogImpactDataPaginationService(params) {
    // return await CommonBase.postAsync(configApp.apiGateWay + typesApi.GET_LIST_INVENTORY_AUDIT_PLAN_API, body);
    // return new Promise((resolve) => {
    //     setTimeout(() => {
    //         resolve(FakeLogManagementData);
    //     }, 1000);
    // });
    return await CommonBase.postAsync(configApp.apiGateWay + typesApi.GET_LIST_LOGIMPACT_AUDIT_API, params);
}
