export const approval = {
  language: "vn",
  approval: {
    qtpdrequisred: "Quy trình phê duyệt không được để trống",
    approvalrequired: "Phải chọn tối thiểu 1 quy trình phê duyệt",
    createapp: "Tạo mới phê duyệt thành công",
    title: "Chọn quy trình phê duyệt",
    textlabel_1: "Tên quy trình",
    placeholder_1: "Chọn tên quy trình",
    btn_add: "Thêm bước mới",
    search: 'Tìm kiếm theo tên tài liệu, người gửi và loại phê duyệt',
    status_paging: {
      all: 'Tất cả',
      wait: 'Chờ phê duyệt',
      approved: 'Đã phê duyệt',
      reject: 'Từ chối'
    },
    status_Type: {
      wait: 'Chờ phê duyệt',
      approved: 'Đã phê duyệt',
      approving: 'Đang phê duyệt',
      reject: 'Đã huỷ'
    },
    table: {
      name: 'Tên tài liệu',
      sending_date: 'Ngày gửi',
      sending_by: 'Người gửi',
      type: 'Loại phê duyệt',
      status: 'Trạng thái',
      action: "Thao tác"
    },
    form: {
      back: 'Phê duyệt tài liệu',
      version: 'Phiên bản',
      effected_date: 'Ngày tạo',
      expired_date: 'Ngày phê duyệt',
      product: 'Tên tài liệu',
      note: 'Ghi chú',
      file: 'FIle đính kèm',
      btn_detail: 'Xem chi tiết',
      btn_approval: 'Phê duyệt',
      btn_reject: 'Từ chối',
      btn_close: 'Quay lại',
      approve_success: "Phê duyệt thành công",
      popup: {
        approve: {
          title: 'Xác nhận phê duyệt',
          placeHolder: 'Nhập lý do phê duyệt',
        },
        reject: {
          title: 'Xác nhận từ chối',
          placeHolder: 'Nhập lý do từ chối',
        },
      }
    },
    validate: {
      approveResult: "Lý do phê duyệt không được bỏ trống",
      approveLevel: "Tên cấp độ không được bỏ trống",
      department: "Tên phòng ban không được bỏ trống",
      approve: "Phê duyệt bởi không được bỏ trống",
    }
  },
};
