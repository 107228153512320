import {
    GET_PAGING_CONSUMABLE_PACKAGE_SAGA,
    GET_PAGING_CONSUMABLE_PACKAGE_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    CREATED_CONSUMABLE_PACKAGE_PACKAGE_REDUCER,
    CREATE_CONSUMABLE_PACKAGE_SAGA,
    GET_DETAIL_CONSUMABLE_PACKAGE_PACKAGE_REDUCER,
    SET_DETAIL_DATA_DEFAULT_REDUCER,
    GET_DETAIL_CONSUMABLE_PACKAGE_SAGA,
    UPDATE_CONSUMABLE_PACKAGE_SAGA,
    REQUEST_PRINT_CONSUMABLE_SAGA,
    GET_PRINT_CONSUMABLE_DATA_REDUCER,
    RESET_GET_PRINT_CONSUMABLE_DATA_REDUCER,
  } from "./enum/TypeConsumablePackageAction";
  
  const ConsumablePackageAction = {
    getPagingSaga: (searchTerm = "", pageIndex, pageSize, status =[], catHospitalIDList = [], catWarehouseIDList = [], startDate, endDate, type = [], ListDataUser = [], ) => {
      return {
        type: GET_PAGING_CONSUMABLE_PACKAGE_SAGA,
        SearchTerm: searchTerm,
        PageIndex: pageIndex,
        PageSize: pageSize,
        Status: status,
        CatHospitalIDList: catHospitalIDList,
        CatWarehouseIDList: catWarehouseIDList,
        StartDate: startDate,
        EndDate: endDate,
        Type: type,
        ListDataUser: ListDataUser,
      };
    },
    createDataSaga: (data) => {
      return {
        type: CREATE_CONSUMABLE_PACKAGE_SAGA,
        data: data,
      };
    },
    updateDataSaga : (data) => {
      return {
        type: UPDATE_CONSUMABLE_PACKAGE_SAGA,
        data: data,
      };
    },
    getDetailDataSaga:(data) =>{
      return{
        type: GET_DETAIL_CONSUMABLE_PACKAGE_SAGA,
        data:data
      }
    },
    getPagingReducer: (data) => {
      return {
        type: GET_PAGING_CONSUMABLE_PACKAGE_REDUCER,
        data: data,
      };
    },
    
    setDetailDataDefaultReducer:()=>{
      return {
        type: SET_DETAIL_DATA_DEFAULT_REDUCER,
      };
    },
    createdSuccessedReducer:() => {
      return {
        type: CREATED_CONSUMABLE_PACKAGE_PACKAGE_REDUCER,
      };
    },
    getDetailConsumableSuccessedReducer:(data) =>{
      return{
        type: GET_DETAIL_CONSUMABLE_PACKAGE_PACKAGE_REDUCER,
        data:data
      }
    },
    requestPrintConsumableSaga: (data) => {
      return {
        type: REQUEST_PRINT_CONSUMABLE_SAGA,
        data: data
      }
    },
    getPrintConsumableReducer: (data) => {
      return {
        type: GET_PRINT_CONSUMABLE_DATA_REDUCER,
        data
      }
    },
    resetPrintConsumableReducer: () => {
      return {
        type: RESET_GET_PRINT_CONSUMABLE_DATA_REDUCER
      }
    },
    callDefaultReducer: () => {
      return {
        type: CALL_DEFAULT_REDUCER,
      };
    },
    clearDataReducer: () => {
      return {
        type: CLEAR_DATA_REDUCER,
      };
    },
  
  }
  
  export default ConsumablePackageAction;
  