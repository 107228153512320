export const ProductPackagingLag = {
    language: "vi",
    ProductPackaging: {
        packTitle: "Thực thi đóng gói",
        finishedProducts: "Thành phẩm đóng gói",
        packagingLevel: "Cấp độ đóng gói",
        table: {
                tableName: "Danh sách đóng gói",
                code:"Mã định danh",
                unit: "Đơn vị",
                nodata:"Chưa có item nào!"
            },
        btn: {
            search: "Thêm",
            sprint:"In tem",
        },
        placeHolder: {
            searchProduct: "Chọn thành phẩm đóng gói",
            searchTable: "Nhập / scan mã định danh",            
            packagingLevel: "Chọn cấp độ đóng gói",
        },
        delete: {
            title:"Xoá mã định danh",
            confirm:"Bạn có chắc chắn muốn xoá mã định danh này?",
        },
        toast: {
            createSuccess: "In tem thành công",
            reprintSuccess: "In tem lại thành công",
            deleteSuccess: "Huỷ tem thành công",
            matchError: "Mã định danh đã tồn tại",
            overQtyError:"Không được nhập quá số lượng đóng gói",
        },
    },
}