export const Productmm = {
    language: "en",
    productmm: {
        table: {
            Productcode: "Product code",
            Productname: "Product name",
            Description: "Description",
            Description2: "Description 2",
            Modelname: "Model",
            Typeofproduct: "Type of product",
            Unitdescription: "Unit",
            Active: "Active",
        },
        placeholder: "Product code, product name, description, model, type of.... ",
        form: {
            title: "Product information",
            updatetitle: "Update product information",
            addtitle: "Add new product information",
            checkbox: "Active",
            productnameduplicated: "Product name already exists",
            productcodeduplicated: "Product code already exists",
        },
        deleteform: {
            title: "Confirm delete this product ",
            label: "Are you sure you want to delete this product ?"
        },
        navbar: {
            f1: "Product Management",
            f2: "Model Management",
        },
        placeholder2: {
            f1: "Enter product name",
            f2: "Select units",
            f3: "Enter product code",
            f4: "Select model",
            f5: "Select product model",
            f6: "Enter description 1",
            f7: "Enter description 2",
        },
        notification: {
            f1: "Create new product successfully",
            f2: "Product update successful",
            f3: "Product deletion successful",
        }
    }
}