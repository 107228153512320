
import {
    GET_ALL_MASTER_DATA_BY_GROUP_CODE_SAGA,
    GET_ALL_MASTER_DATA_BY_GROUP_CODE_REDUCER,
    GET_APPROVAL_FLOW_TEMPLATE_SAGA,
    GET_APPROVAL_FLOW_TEMPLATE_REDUCER,
    CALL_DEFAULT_COMMON_DROPDOWN_VINMEC_REDUCER,
    CLEAR_DATA_DROPDOWN_VINMEC_REDUCER,
    GET_LIST_USER_SAGA,
    GET_LIST_USER_REDUCER,
    // GET_LIST_ROLE_SAGA,
    // GET_LIST_ROLE_REDUCER,
    GET_APPROVAL_FLOW_TEMPLATE_BY_ID_SAGA,
    GET_APPROVAL_FLOW_TEMPLATE_BY_ID_REDUCER,
    GET_LIST_FOCAL_DEPARTMENT_G5_SAGA,
    GET_LIST_FOCAL_DEPARTMENT_G5_REDUCER,
    GET_LIST_BP_THE_LEDGER_SAGA,
    GET_LIST_BP_THE_LEDGER_REDUCER,
    GET_LIST_ASSET_CLASS_SAGA,
    GET_LIST_ASSET_CLASS_REDUCER,
    GET_LIST_SEGMENT_FUND_CENTER_SAGA,
    GET_LIST_SEGMENT_FUND_CENTER_REDUCER,
    GET_LIST_UNIT_SAGA,
    GET_LIST_UNIT_REDUCER,
    GET_LIST_STANDARD_ASSET_NAME_SAGA,
    GET_LIST_STANDARD_ASSET_NAME_REDUCER,
    GET_LIST_BUDGET_CI_SAGA,
    GET_LIST_BUDGET_CI_REDUCER,
    GET_LIST_EVALUATION_GROUP_1_SAGA,
    GET_LIST_EVALUATION_GROUP_1_REDUCER,
    GET_LIST_EVALUATION_GROUP_2_SAGA,
    GET_LIST_EVALUATION_GROUP_2_REDUCER,
    GET_LIST_EVALUATION_GROUP_3_SAGA,
    GET_LIST_EVALUATION_GROUP_3_REDUCER,
    GET_LIST_EVALUATION_GROUP_4_SAGA,
    GET_LIST_EVALUATION_GROUP_4_REDUCER,
    GET_LIST_EVALUATION_GROUP_5_SAGA,
    GET_LIST_EVALUATION_GROUP_5_REDUCER,
    GET_ALL_FUND_CENTER_SAGA,
    GET_ALL_FUND_CENTER_REDUCER,
    GET_GROUP_BPC_SAGA,
    GET_GROUP_BPC_REDUCER,
    GET_LIST_VENDOR_SAGA,
    GET_LIST_VENDOR_REDUCER,
    GET_LIST_ASSET_NUMBER_SUPPER_SAGA,
    GET_LIST_ASSET_NUMBER_SUPPER_REDUCER,
    GET_LIST_ALL_SEGMENT_SAGA,
    GET_LIST_ALL_SEGMENT_REDUCER,
    GET_LIST_COST_CENTER_BY_SEGMENT_SAGA,
    GET_LIST_COST_CENTER_BY_SEGMENT_REDUCER,
    GET_LIST_INVENTORY_CODE_SAGA,
    GET_LIST_INVENTORY_CODE_REDUCER,
    GET_USER_SEGMENT_SAGA,
    GET_USER_SEGMENT_REDUCER,
    GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_SAGA,
    GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_REDUCER,
    GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_SAGA,
    GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_REDUCER,
    GET_ALL_ASSET_SOURCE_SAGA,
    GET_ALL_ASSET_SOURCE_REDUCER,
    GET_ALL_SEGMENT_INVENTORY_PLAN_SAGA,
    GET_ALL_SEGMENT_INVENTORY_PLAN_REDUCER,
    GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_SAGA,
    GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_REDUCER

} from "./enum/TypeCommonDropdownVinmecAction";

const CommonDropdownVinmecAction = {

    getAllMasterDataByGroupCodeSaga: (groupCode = "") => {
        return {
            type: GET_ALL_MASTER_DATA_BY_GROUP_CODE_SAGA,
            groupCode: groupCode
        };
    },
    getAllMasterDataByGroupCodeReducer: (data) => {
        return {
            type: GET_ALL_MASTER_DATA_BY_GROUP_CODE_REDUCER,
            data: data
        };
    },

    getApprovalFlowTemplateSaga: (searchTerm = "", top = 0) => {
        return {
            type: GET_APPROVAL_FLOW_TEMPLATE_SAGA,
            searchTerm: searchTerm,
            top: top
        };
    },
    getApprovalFlowTemplateReducer: (data) => {
        return {
            type: GET_APPROVAL_FLOW_TEMPLATE_REDUCER,
            data: data
        };
    },
    getListUserSaga: (searchTerm = "", top = 0) => {
        return {
            type: GET_LIST_USER_SAGA,
            searchTerm: searchTerm,
            top: top
        };
    },
    getListUserReducer: (data) => {
        return {
            type: GET_LIST_USER_REDUCER,
            data: data
        };
    },
    // getListRoleSaga: (searchTerm = "", top = 0) => {
    //     return {
    //         type: GET_LIST_ROLE_SAGA,
    //         searchTerm: searchTerm,
    //         top: top
    //     };
    // },
    // getListRoleReducer: (data) => {
    //     return {
    //         type: GET_LIST_ROLE_REDUCER,
    //         data: data
    //     };
    // },

    getApprovalFlowTemplateByIdSaga: (id) => {
        return {
            type: GET_APPROVAL_FLOW_TEMPLATE_BY_ID_SAGA,
            id: id
        };
    },
    getApprovalFlowTemplateByIdReducer: (data) => {
        return {
            type: GET_APPROVAL_FLOW_TEMPLATE_BY_ID_REDUCER,
            data: data
        };
    },


    getListFocalDepartmentG5Saga: () => {
        return {
            type: GET_LIST_FOCAL_DEPARTMENT_G5_SAGA,
        };
    },
    getListFocalDepartmentG5Reducer: (data) => {
        return {
            type: GET_LIST_FOCAL_DEPARTMENT_G5_REDUCER,
            data: data
        };
    },
    getListBPOnTheLedgerSaga: (obj) => {
        return {
            type: GET_LIST_BP_THE_LEDGER_SAGA,
            obj: obj
        };
    },
    getListBPOnTheLedgerReducer: (data) => {
        return {
            type: GET_LIST_BP_THE_LEDGER_REDUCER,
            data: data
        };
    },
    getListAssetClassSaga: (obj) => {
        return {
            type: GET_LIST_ASSET_CLASS_SAGA,
            obj: obj
        };
    },
    getListAssetClassReducer: (data) => {
        return {
            type: GET_LIST_ASSET_CLASS_REDUCER,
            data: data
        };
    },
    getListSegmentFundCenterSaga: () => {
        return {
            type: GET_LIST_SEGMENT_FUND_CENTER_SAGA,
        };
    },
    getListSegmentFundCenterReducer: (data) => {
        return {
            type: GET_LIST_SEGMENT_FUND_CENTER_REDUCER,
            data: data
        };
    },
    getListUnitSaga: () => {
        return {
            type: GET_LIST_UNIT_SAGA,
        };
    },
    getListUnitReducer: (data) => {
        return {
            type: GET_LIST_UNIT_REDUCER,
            data: data
        };
    },
    getListStandardAssetNameSaga: (obj) => {
        return {
            type: GET_LIST_STANDARD_ASSET_NAME_SAGA,
            obj: obj
        };
    },
    getListStandardAssetNameReducer: (data) => {
        return {
            type: GET_LIST_STANDARD_ASSET_NAME_REDUCER,
            data: data
        };
    },
    getListBudgetCISaga: () => {
        return {
            type: GET_LIST_BUDGET_CI_SAGA,
        };
    },
    getListBudgetCIReducer: (data) => {
        return {
            type: GET_LIST_BUDGET_CI_REDUCER,
            data: data
        };
    },
    getListEvaluationGroup_1_Saga: () => {
        return {
            type: GET_LIST_EVALUATION_GROUP_1_SAGA,
        };
    },
    getListEvaluationGroup_1_Reducer: (data) => {
        return {
            type: GET_LIST_EVALUATION_GROUP_1_REDUCER,
            data: data
        };
    },
    getListEvaluationGroup_2_Saga: () => {
        return {
            type: GET_LIST_EVALUATION_GROUP_2_SAGA,
        };
    },
    getListEvaluationGroup_2_Reducer: (data) => {
        return {
            type: GET_LIST_EVALUATION_GROUP_2_REDUCER,
            data: data
        };
    },
    getListEvaluationGroup_3_Saga: () => {
        return {
            type: GET_LIST_EVALUATION_GROUP_3_SAGA,
        };
    },
    getListEvaluationGroup_3_Reducer: (data) => {
        return {
            type: GET_LIST_EVALUATION_GROUP_3_REDUCER,
            data: data
        };
    },
    getListEvaluationGroup_4_Saga: () => {
        return {
            type: GET_LIST_EVALUATION_GROUP_4_SAGA,
        };
    },
    getListEvaluationGroup_4_Reducer: (data) => {
        return {
            type: GET_LIST_EVALUATION_GROUP_4_REDUCER,
            data: data
        };
    },
    getListEvaluationGroup_5_Saga: () => {
        return {
            type: GET_LIST_EVALUATION_GROUP_5_SAGA,
        };
    },
    getListEvaluationGroup_5_Reducer: (data) => {
        return {
            type: GET_LIST_EVALUATION_GROUP_5_REDUCER,
            data: data
        };
    },
    getAllFundCenterSaga: () => {
        return {
            type: GET_ALL_FUND_CENTER_SAGA,
        };
    },
    getAllFundCenterReducer: (data) => {
        return {
            type: GET_ALL_FUND_CENTER_REDUCER,
            data: data
        };
    },
    getGroupBPCSaga: (data) => {
        return {
            type: GET_GROUP_BPC_SAGA,
            data: data
        };
    },
    getGroupBPCReducer: (data) => {
        return {
            type: GET_GROUP_BPC_REDUCER,
            data: data
        };
    },
    getListVendorSaga: () => {
        return {
            type: GET_LIST_VENDOR_SAGA,
        };
    },
    getListVendorReducer: (data) => {
        return {
            type: GET_LIST_VENDOR_REDUCER,
            data: data
        };
    },
    getListAssetSuperNumberSaga: () => {
        return {
            type: GET_LIST_ASSET_NUMBER_SUPPER_SAGA,
        };
    },
    getListAssetSuperNumberReducer: (data) => {
        return {
            type: GET_LIST_ASSET_NUMBER_SUPPER_REDUCER,
            data: data
        };
    },
    getListAllSegmentSaga: () => {
        return {
            type: GET_LIST_ALL_SEGMENT_SAGA,
        };
    },
    getListAllSegmentReducer: (data) => {
        return {
            type: GET_LIST_ALL_SEGMENT_REDUCER,
            data: data
        };
    },

    getListCostCenterbySegmentSaga: (SegmentId) => {
        return {
            type: GET_LIST_COST_CENTER_BY_SEGMENT_SAGA,
            SegmentId: SegmentId
        };
    },
    getListCostCenterbySegmentReducer: (data) => {
        return {
            type: GET_LIST_COST_CENTER_BY_SEGMENT_REDUCER,
            data: data
        };
    },

    getListCostCenterbySegmentFundCenterSaga: (SegmentId) => {
        return {
            type: GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_SAGA,
            SegmentId: SegmentId
        };
    },
    getListCostCenterbySegmentFundCenterReducer: (data) => {
        return {
            type: GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_REDUCER,
            data: data
        };
    },
    getListInventoryCodeSaga: (obj) => {
        return {
            type: GET_LIST_INVENTORY_CODE_SAGA,
            obj: obj
        };
    },
    getListInventoryCodeReducer: (data) => {
        return {
            type: GET_LIST_INVENTORY_CODE_REDUCER,
            data: data
        };
    },
    getUserSegmentSaga: () => {
        return {
            type: GET_USER_SEGMENT_SAGA,
        };
    },
    getUserSegmentReducer: (data) => {
        return {
            type: GET_USER_SEGMENT_REDUCER,
            data: data
        };
    },
    getAllEvaluationGroup5BySegmentSaga: (segmentId) => {
        return {
            type: GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_SAGA,
            segmentId: segmentId
        };
    },
    getAllEvaluationGroup5BySegmentReducer: (data) => {
        return {
            type: GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_REDUCER,
            data: data
        };
    },

    getListSegmentFundCenterCodeRequestSaga: () => {
        return {
            type: GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_SAGA,
        };
    },
    getListSegmentFundCenterCodeRequestReducer: (data) => {
        return {
            type: GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_REDUCER,
            data: data
        };
    },
    getAllAssetSourceSaga: () => {
        return {
            type: GET_ALL_ASSET_SOURCE_SAGA,
        };
    },
    getAllAssetSourceReducer: (data) => {
        return {
            type: GET_ALL_ASSET_SOURCE_REDUCER,
            data: data
        };
    },
    getAllSegmentInventoryPlanSaga: () => {
        return {
            type: GET_ALL_SEGMENT_INVENTORY_PLAN_SAGA,
        };
    },
    getAllSegmentInventoryPlanReducer: (data) => {
        return {
            type: GET_ALL_SEGMENT_INVENTORY_PLAN_REDUCER,
            data: data
        };
    },
    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_COMMON_DROPDOWN_VINMEC_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_DROPDOWN_VINMEC_REDUCER,
        };
    },
};

export default CommonDropdownVinmecAction;
