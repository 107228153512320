import { call, put, takeEvery } from 'redux-saga/effects';
import { closeLoading, openLoading } from 'src/shared/common/LoadingAction';
import actionLinkProductRequirement from './action';
import { GET_LIST_WORKCENTER_SAGA, GET_PRODUCTION_REQUIRE_DETAIL_SAGA, HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_SAGA, INSERT_DATA_SAGA, UPDATE_CODE_WO } from './enum/typeAction';
import { getListWorkcenterService, getProRequireDetailService, getRequirementByDropdownListService, insertDataService } from './services';
import Moment from "moment";
export function* getProRequiment(request) {
    try {
        const entityRequest = {
            productionrequirementcode: request.proRequireCode,
        };
        const response = yield call(getProRequireDetailService, entityRequest);
        if (response.isSuccess) {
            yield put(actionLinkProductRequirement.setProRequireReducer(response.data));
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};

export function* getListWorkcenter(request) {
    try {
        openLoading();
        const entityRequest = {
            Productionrequirementcode: request.Productionrequirementcode,
            ProductCode: request.ProductCode,
        }
        const response = yield call(getListWorkcenterService, entityRequest);
        if (response.isSuccess) {
            yield put(actionLinkProductRequirement.setListWorkcenterReducer(response.data, request.index, request.typeIndentifier));
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};
export function* insertData(request) {
    try {
        openLoading();
        let workorderDatas = []
        if (request.workorderData && request.workorderData.length > 0) {
            request.workorderData.map(item => {
                let workorderData = {
                    Esproductionquantity: item.Esproductionquantity,
                    Esproductiontimefrom: Moment(item.Esproductiontimefrom).format('DD/MM/YYYY'),
                    Esproductiontimeto: Moment(item.Esproductiontimeto).format('DD/MM/YYYY'),
                    Productcode: item.Productcode,
                    Productionrequirementcode: item.Productionrequirementcode,
                    Status: item.Status,
                    Workcenterid: item.Workcenterid,
                    Workordercode: item.Workordercode,
                }
                workorderDatas.push(workorderData);
            })
            const response = yield call(insertDataService, workorderDatas);
            if (response.isSuccess) {
                yield put(actionLinkProductRequirement.insertDataReducer());
            }
        }

    } catch (error) {

    } finally {
        closeLoading();
    }
};
export function* getAllRequirementByDropdownList(request) {
    try {
        openLoading();
        const response = yield call(getRequirementByDropdownListService, request.dropdownSelect);
        if (response.isSuccess) {
            yield put(actionLinkProductRequirement.setMuitipleOrderCodeReducer(response.data));
        } else if (request.dropdownSelect.length == 0) {
            yield put(actionLinkProductRequirement.setMuitipleOrderCodeReducer([]));
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};

export default function* saga(data) {
    yield takeEvery(GET_PRODUCTION_REQUIRE_DETAIL_SAGA, getProRequiment);
    yield takeEvery(GET_LIST_WORKCENTER_SAGA, getListWorkcenter);
    yield takeEvery(INSERT_DATA_SAGA, insertData);
    yield takeEvery(HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_SAGA, getAllRequirementByDropdownList);
}
