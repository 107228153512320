export const GET_PAGING_PRODUCT_SAGA = "GET_PAGING_PRODUCT_SAGA";
export const GET_PAGING_MATERIAL_BY_ROLE_USER_SAGA = "GET_PAGING_MATERIAL_BY_ROLE_USER_SAGA";
export const GET_PAGING_PRODUCT_REDUCER = "GET_PAGING_PRODUCT_REDUCER";
export const GET_PAGING_MATERIAL_BY_ROLE_USER_REDUCER = "GET_PAGING_MATERIAL_BY_ROLE_USER_REDUCER";

export const DELETE_PRODUCT_SAGA = "DELETE_PRODUCT_SAGA";
export const DELETE_PRODUCT_REDUCER = "DELETE_PRODUCT_REDUCER";

export const CREATE_PRODUCT_SAGA = "CREATE_PRODUCT_SAGA";
export const UPDATE_PRODUCT_SAGA = "UPDATE_PRODUCT_SAGA";
export const CALL_CREATE_UPDATE_SUCCESS_REDUCER =
  "CALL_CREATE_UPDATE_SUCCESS_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";

export const GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_SAGA =
  "GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_SAGA";
export const GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_REDUCER =
  "GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_REDUCER";
  export const GET_LIST_PRODUCT_SAGA =
  "GET_LIST_PRODUCT_SAGA";
  export const GET_LIST_PRODUCT_REDUCER =
  "GET_LIST_PRODUCT_REDUCER";

  export const GET_LIST_PRODUCT_NOT_IN_BARCODE_SAGA =
  "GET_LIST_PRODUCT_NOT_IN_BARCODE_SAGA";
  export const GET_LIST_PRODUCT_NOT_IN_BARCODE_REDUCER =
  "GET_LIST_PRODUCT_NOT_IN_BARCODE_REDUCER";