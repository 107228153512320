export const Modelmm = {
    language: "en",
    modelmm: {
        table: {
            Modelcode: "Model Code",
            Modelname: "Model name",
            Description: "Description",
            Description2: "Description 2",
            Note: "Note",
            Active: "Active",
        },
        placeholder: "Model code, model name.... ",
        form: {
            title: "Product model information",
            updatetitle: "Update product model information",
            addtitle: "Add new product model information",
            checkbox: "Active",
        },
        deleteform: {
            title: "Confirm delete this model",
            label: "Are you sure want to delete this model ?"
        },
        error: {
            modelcodevalidate: "The model code is not in the correct format",
            modelcoderequired: "Model code cannot be empty",
            modelcodelength: "Model code can't be more than 25 characters",
            modelnamevalidate: "Model name can't be empty",
            modelnamelength: "Model name can't be more than 255 characters",
            des1: "Description 1 can't be more than 255 characters",
            des2: "Description 2 should not be more than 255 characters",
            note: "Notes can't be more than 255 characters",
            productcodevali: "Malformed Code",
            productrequired: "Product code cannot be blank",
            productmaxlength: "Product code must not be more than 50 characters",
            productnamevali: "Product name cannot be left blank",
            productnamemaxlength: "Product name can't be more than 255 characters",
            unitrequired: "Unit cannot be empty",
            typerequired: "Product type cannot be empty",
            typemaxlength: "Product type must not exceed 100 characters",
            modelformrequired: "Product model cannot be blank",
            modelformlength: "Product model must not exceed 25 characters",
            des1max: "Description 1 can't be more than 255 characters",
            des2max: "Description 2 can't be more than 255 characters",
        }
    }
}