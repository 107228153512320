import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_PAGING_PERMISSION_DATA_API,
    DELETE_PERMISSION_DATA_API,
    CREATE_PERMISSION_DATA_API,
    UPDATE_PERMISSION_DATA_API,
    GET_DETAIL_PERMISSION_DATA_API
} from "./enum/TypePermissionDataAPI.js";

export async function getPagingService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_PAGING_PERMISSION_DATA_API,
        data
    )
}

export async function deletePermissionDataService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + DELETE_PERMISSION_DATA_API + data
    )
}
export async function updatePermissionDataService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + UPDATE_PERMISSION_DATA_API, data
    )
}
export async function createPermissionDataService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + CREATE_PERMISSION_DATA_API, data
    )
}
export async function getDetailPermissionDataService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_DETAIL_PERMISSION_DATA_API + data
    )
}