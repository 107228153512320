export const base = {
  language: "en",
  base: {
    default: "Default",
    apply: "Apply",
    filter: "Filter",
    btn: {
      create: 'Add New',
      update: 'Update',
      close: 'Close',
      save: "Save",
      add: "Add",
      agree: "Agree",
      cancel: "Cancel",
      delete: "Delete",
      back: "Back",
      shortData: "Collapse",
      changeVersion: "Change version",
      choose: "Choose",
      view: "See details",
      edit: "Edit",
      complete: "Complete",
      print: "Printer",
      enforcement: "Acceptance",
    },
    from: "From",
    to: "To",
    pagination: {
      display: 'Display',
      print: 'print'
    },
    table: {
      action: 'Action',
      nothingToDisplay: 'No data to display'
    },
    insertData: "Import data",
    select: "Select",
    sureDelete: "Are you sure you want to delete",
    cancel: "Cancel",
    ok: "Agree",
    close: "Close",
    upload: "Upload file",
    deleteFileSuccess: "Delete the file successfully",
    length: "length",
    width: "width",
    height: "height",
    uploadFile: {
      title: "Download files",
      overFileAllow: "Unable to upload due to too many files",
      fileTypeAllow: "Invalid for upload",
      overSizeAllow: "Invalid file size for upload"
    }
  },
  worker_skill: {
    placeholderskill: "Search by skill",
    title_table: "Skills training information",
    deletedegree: "Delete Degree",
    deletedegreequest: "Are you sure you want to delete this degree?",
    form: {
      create: "Add new major - skills",
      update: "Update major - skills",
      form: {
        skill: "Specialization / skill",
        unit: "Training unit",
        start: "Start time",
        end: "Time to end",
        rank: "Ranking",
        cost: "Cost",
        note: "Note",
        degree: "Degree",
        traningByGoverment: "Internal training (organised by factory)",
      }
    },
    table: {
      time: "Training time",
      traningBy: "Internal training",
      cost: "Training cost (VND)",
    },
  },
  requestTransfer: {
    search: "Search by request source code, sloc to , description  ,.... ",
    table: {
      code: "Requested source code",
      moveSloc: "Sloc moved to",
      des: "Description",
      active: "Active"
    },
    form: {
      codeexist: "Requested source code already exists",
      create: "Add new request source",
      update: "Update request source",
      view: "Request source details",
      placeHolderSlocTo: "Select sloc to move to"
    },
    delete: {
      title: "Delete request source",
      confirmText: "Are you sure you want to delete this request source?"
    }
  },
  do: {
    code_exist: "Code exist before",
    searchPH: "Delivery coupon code, order number, shipper, delivery status",
    table: {
      Datedelivery: "Delivery Date",
      Dateleavefactory: "Delivery Date",
      Deliveryordercode: "Delivery Coupon Code",
      Purchaseordercode: "Order code",
      Statusdeliveryname: "Delivery Status",
      Userdeliveryname: "Deliverer",
      icon: {
        hasBeenDelivery: "Đơn giao hàng DO đã giao không thể sửa",
        cannotDelete: "Đơn giao hàng DO đã giao không thể xóa"
      }
    },
    form: {
      title: {
        create: "Create a new delivery order DO",
        update: "Update delivery order DO",
        view: "DO delivery order details",
      },
      field: {
        code: "Create independent DO delivery order",
        poCode: "PO Order Code",
        partnerCode: "Partner Code",
        transferCode: "Delivery order Code",
        transferPerson: "Deliverer",
        dayExpo: " Date of inventory",
        dayTransfer: "Delivery Date",
        status: "Status",
      },
      table: {
        proCode: "Commodity Code",
        desPro: "Commodity Description",
        numberProTransfered: "Number of Delivered/ Quantity Ordered",
        transferedAmount: "Delivered Quantity",
        unit: "Unit",
        dayProduce: "Production date",
        dayExpire: "Expiration date",
      }
    },
    delete: {
      title: "Confirmation to delete shipping information",
      confirmText: "Are you sure you want to delete this shipping information?",
      form: {
        title: "Delete row code",
        confirmText: "Are you sure you want to delete this commodity code?",
      }
    },
    btn: {
      addPack: "Add new package",
    },
    placeHolder: {
      form: {
        po: "Select order number PO",
        partner: "Select partner code",
        shiping: "Select delivery coupon code",
        shiper: "Select a shipper",
        dateExpo: "Enter stock release date",
        shipDate: "Enter a delivery date",
        status: "Select status",
      },
      table: {
        good: {
          code: "Enter commodity code",
          name: "Commodity description"
        },
        amount: {
          hasExpo: "Enter number of deliveries",
          order: "Enter order quantity",
          ship: "Enter order quantity"
        },
        unit: "Unit",
        produceDate: "Enter production date",
        dateExpire: "Enter an expiration date",
      }
    },
    toastJs: {
      createOK: "Successfully created new delivery information",
      updateOK: "Update delivery information successfully",
      deleteOK: "Successfully deleted shipping information",
      noDelete: "Delivery note has been received, cannot be deleted"
    },
    rulesFormDO: {
      orderCodeErr: "Code is wrong format",
      orderCode: "Coupon code cannot be left blank",
      orderCodeLength: "Coupon code must not exceed 50 characters",
      partnerCode: "Partner code cannot be left blank",
      PO: "PO cannot be left blank",
      dateLeave: "The delivery date cannot be left blank",
      dateDelivery: "Delivery date cannot be left blank",
      userDelivery: "The delivery person cannot be left blank",
      statusDelivery: "Status cannot be empty",
    },
    rulesListData: {
      numberThan0: "Actual number must be greater than 0",
      qtyDelivery: "Delivery quantity cannot be left blank",
      dateofManufacture: "Production date cannot be left blank",
      qtyDeliveryed: "The delivered quantity cannot be left blank",
      numberEqual0: "Actual number must be greater than or equal to 0",
      expriedDate: "Expiration date cannot be left blank",
      goodsCode: "Commodity code cannot be left blank",
      qtyOrder: "Order quantity cannot be left blank",
      qtyOder: "Order quantity should not be less than or equal to 0",
      qtyDeliveryMin: "Delivery quantity should not be less than 0"
    },
    ruleForm: {
      Datedelivery: "The delivery date must not be before the date of shipment",
      Qtydelivery: "Quantity delivered cannot be greater than order quantity - delivered quantity",
      QtyDeliveried: "The delivered quantity cannot be greater than the ordered quantity",
      Exprieddate: "Expiry date must not occur before production date",
      ExprieddateAffter: "The expiration date must occur after the date of manufacture",
      Dateofmanufacture: "Production date cannot be left blank",
      ExprieddateErr: "Expiration date cannot be left blank"
    }
  },
  receiveGoodFromNcss: {
    searchField: {
      placeHolder: "Enter code",
    },
    btn: {
      receive: "Receive",
      reset: "Re-declare",
      print: "Print stamp",
      storePackage: "Import Warehouse",
      addPackage: "Add Package"
    },
    infoForm: {
      code: "Order code",
      supplier: "Supplier",
      orderBy: "Order person",
      orderDate: "Order Date",
      transferDate: "Estimated delivery date",
    },
    packingInfo: {
      title: "Packing Information",
      table: {
        packageCode: "Package code",
        packingType: "Packing style",
        amount: "Amount",
        unit: "Unit",
        dateExpire: "Expiration date",
        length: "Length",
        width: "Width",
        height: "Height",
        stacked: "Stacked",
        amountStacked: "Stacked amount",
        order: "put",
        receive: "actually received",
        column: {
          packingType: "Select package code",
          amount: "Enter amount",
          unit: "Enter units",
          dateExpire: "Enter an expiration date",
          length: "Enter length",
          width: "Enter width",
          height: "Enter height",
          amountStacked: "Enter number of stack stack",
          print: "Print",
          reprint: "Reprint"
        }
      }
    },
    detailInfoPackage: {
      title: "Order details",
      table: {
        goodCode: "Item code",
        goodName: "Item name",
        receive: "receive fact",
      }
    },
    popup: {
      printTem: {
        title: "Confirmation of stamp printing",
        content: "Are you sure you want to print stamps for this shipment?"
      },
      storeConfirm: {
        title: "Confirmation of stock import",
        content: "Are you sure you want to Warehouse this shipment?"
      },
      reset: {
        title: "Confirm Re-Declaration",
        content: "Are you sure you want to re-declare the data?"
      },
      deletePackage: {
        title: "Confirmation to delete package",
        content: "Are you sure you want to Remove this package?"
      },
      printItemTem: {
        title: "Confirmation of Stamp Printing",
        content: "Are you sure you want to Print stamps for this package?"
      },
      printItemTemBack: {
        title: "Confirm Reprint stamp",
        content: "Are you sure you want to reprint this package's stamp?"
      }
    },
    error: {
      stackNotAvaible: "Invalid stack count",
      stackNotOverZero: "The number of stacks cannot be less than 1",
      amountReciveIsOverRealityAmount: "The amount received is greater than the actual amount",
      amountReciveIsOverPermissionAmount: "The amount received is exceeding the specified amount",
      amountReciveMustBiggerThanZero: "The amount received must be greater than 0",
      amountReciveNotEmpty: "The amount received cannot be empty",
      heightNotEmpty: "Height cannot be empty",
      heightBiggerThanZero: "Height must be greater than or less than 0",
      widthNotEmpty: "The width cannot be empty",
      widthBiggerThanZero: "width must be greater than or less than 0",
      longNotEmpty: "Length cannot be empty",
      longBiggerThanZero: "Length must be greater than or less than 0",
      dateExpireNotEmpty: "The expiration date cannot be empty",
      packageTypeNotEmpty: "Package type cannot be empty",
      unitNotEmpty: "Unit cannot be empty",
      dateExpireNotSmallerThanCurrentDay: "The expiration date cannot be less than the current date",
      DataErr: "The data is not valid for printing, please check the data input fields at line number"
    },
    notification: {
      delete: "Delete successful",
      deletePackage: "Delete the package successfully"
    },
    validate: {
      qtyOrder: "Order quantity is not valid in line number",
      dateExpire: "The number of expiration dates has not been filled in",
      orderCode: "Order number cannot be left blank",
      QtyReal: "Actual number must be greater than or equal to 0",
      QtyRealErr: "Actual quantity cannot be left blank",
      actualReceivedAmount: "Actual received quantity should not be greater than order quantity",
      actualAmountEquals: "Actual quantity is not equal to received quantity",
    },
    toastJs: {
      printAllOk: "Print all stamps successfully",
      printOk: "Successful stamp printing",
      rePrintOK: "Successful stamp reprint",
      importedOk: "Successfully imported warehouse",
    }
  },
  producePlan: {
    requiredProduceCode: "Required Production",
    codeTable1: "Production Request Information",
    codeTable2: "Work Order",
    code: "Production Requirements",
    product: "Product",
    amountOrder: "Amount requested",
    amountHasInOrder: "Number of requests entered",
    date: "Date of handover",
    ttsx: "Production Center",
    start: "Expected start",
    end: "Expected End",
    amount: "Amount",
    expect: "Expected",
    real: "Reality",
    isRuning: "Running",
    hasPublish: "Running",
    isCreate: "Initializing",
    notification: {
      orderNotValid: "Error The quantity entered is not allowed to be greater than the requested quantity"
    },
    popup: {
      deleteWo: {
        title: "Work order deletion confirmation",
        content: "Are you sure you want to remove the work order"
      }
    }
  },
  pCoordination: {
    startReal: "Actual start",
    endReal: "Realistic End",
    changeStatus: "Change Status",
    searchField: "Search by work order , product , production center .....",
    status: "Select search status",
    date: "Select search time",
    run: "Run",
    pause: "Stop",
    complete: "Complete",
    stop: "Cancel",
    expectAmount: "The expected amount",
    realAmount: "Actual Amount",
    popup: {
      emptyHuman: {
        title: "Warning! Lack of workers or machinery",
        content: "Do you still want to confirm production scheduling?"
      },
      pause: {
        title: "Pause command",
        content: "Are you sure you want to pause these working commands?"
      },
      run: {
        title: "Run command",
        content: "Are you sure you want to run these working commands?"
      },
      stop: {
        title: "Cancel working order",
        content: "Are you sure you want to cancel this work order?"
      },
      form: {
        complete: "Complete the work order",
        update: "Update work order",
        view: "Work Order Details"
      }
    },
    placeHolder: {
      search: "Enter work order code"
    }
  },
  calendarComp: {
    monday: "Monday",
    tueday: "Tuesday",
    wenesday: "Wednesday",
    thurday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    hello: "hello"
  },
  inventoryCheck: {
    start: "Start",
    end: "End",
    table: {
      code: "Inventory code",
      expect: {
        start: "Estimated time to start",
        end: "Estimated time to end",
      },
      location: "Execution location",
      start: "Start time",
      end: "Time to end",
      type: "Category",
      user: "The person in charge",
      purpose: "Purpose",
      progress: "Progress",
    },
    form: {
      level: "Statistical level",
      create: "Create new inventory, tally",
      update: "Update inventory, tally",
      view: "Information on inventory and tally"
    },
    validate: {
      noChooseEnd: "Haven't chosen a plan to finish yet",
      noEnd: "Unable to end an inventory order that has been or is in progress",
      noChooseStart: "Haven't chosen a plan to start with",
      noStart: "Unable to start an inventory order already or in progress",
      noUpdate: "The inventory cannot be edited because it has been or is in progress",
      noDelete: "Inventory cannot be deleted because it has been or is in progress",
      timeErr: "Estimated time is not reasonable",
      inventoryCode: "Inventory code malformed",
      inventoryCodeNull: "Inventory code cannot be left blank",
      inventoryCodeLength: "Inventory code should not be more than 50 characters",
      Typechecking: "Category cannot be left blank",
      Pic: "The person in charge cannot be left blank",
      Areachecking: "Execution position cannot be left blank",
      Levelreport: "Stats level cannot be left blank",
      Dateplanfrom: "Choose a start time",
      Dateplanto: "Choose an end time",
      Objective: "Inventory purposes no more than 225 characters"
    },
    delete: {
      title: "Clear inventory",
      des: "Are you sure you want to delete this inventory ?"
    }
  },
  doingCheck: {
    location: "Inventory scope",
    locationDoingCheck: "Doing area",
    locationStoreCheckInformation: "Inventory store location",
    packageCode: "Package Code",
    amount: "Amount",
    notOpen: "Not Open",
    amountGet: "Amount to be checked",
    positionStore: "Inventory store location",
    packageName: "Commodity name",
    amountDeclareBeforeCheck: "Number of pre-inventory pins",
    placeHolder: {
      inventoryCode: "Select inventory code",
      scopeInventory: "Select inventory scope",
      ImplementationArea: "Select implementation area",
      positionStore: "Enter inventory store location",
    }
  },
  checkpoint: {
    table: {
      code: 'Checkpoint code',
      name: 'Checkpoint name',
      product: 'Product',
      process: 'Process',
      lower: 'The lower bound',
      higher: 'Top bound value',
      equal: 'Equal value',
      status: 'Status',
      iqc: "For IQC",
      oqc: "For OQC"
    },
    search: {
      placeHolder: 'Search by checkpoint code, checkpoint name'
    },
    delete_successful: 'Delete checkpoint successfully',
    popup: {
      delete: {
        title: "Confirmation of checkpoint deletion",
        content: "Are you sure you want to delete this checkpoint?",
      }
    },
    form: {
      create: "Create new checkpoint",
      update: "Update checkpoint",
      view: "Checkpoint details",
      code: "Code code",
      name: "Checkpoint name",
      status: "Status",
      isCheckInRange: "Control numeric values by range",
      isCheckEqual: "Control for equals",
      qtyUpper: "Top bound value",
      qtyLower: "Lower bound value",
      value: "Equal value"
    }
  },
  checklist: {
    btn: {
      add_btn_title: "Add New",
    },
    table: {
      code: 'Code checklist',
      name: 'Checklist name',
      product: 'Product',
      process: 'Process',
      stage: 'Stage',
      oqc: 'For OQC',
      iqc: 'For IQC',
      status: 'Status',
    },
    form: {
      title: {
        title_create: "Create new checklist",
        title_update: "Update checklist",
        title_see_detail: "Detailed checklist",
      },
      lable: {
        code: "Code code",
        name: "Name",
        product: "Product"
      },
      placeHolder: {
        checklist_name: "Enter checklist name",
        product: "Select product",
      },
      code: "Checkpoint code",
      name: "Checkpoint name",
      action: "Action",
      checklist_for: {
        checklist_for_title: "Checklist for",
        stage: 'For stage',
        stage_option: {
          stage_option_process_label: "Process",
          stage_option_step_process_label: "Stage",
        },
        placeHolder: {
          stage_option_process: "Select Process",
          stage_option_step_process: "Select stage",
        },
        oqc: 'For OQC',
        iqc: 'For IQC',
      },
      table: {
        placeHolder: "Select checkpoint",
      },
      btn: {
        add: "Add checkpoint",
        save: "Save",
        back: "Back",
      },
      status: "Status",
    },
    search: {
      placeHolder: 'Search by checklist code, checklist name, product code, product name, for OQC, status'
    },
    toast: {
      create_sucess: "Create new success checklist",
      update_sucess: "Update checklist successful",
    },
    rules: {
      checklist_code: {
        required: "Checklist code cannot be left blank",
      },
      product_code: {
        required: "Product cannot be blank",
      },
      checklist_name: {
        required: "Checklist name cannot be left blank",
        max_length: "Checklist name cannot exceed 150 characters",
      },
      processid: {
        required: "Process cannot be empty",
      },
      stepid: {
        required: "Stage cannot be left blank",
      },
      checkpoint_code: {
        required: "Checkpoint cannot be left blank",
      },
      approvefor: {
        required: "Please select checkbox",
      },
    }
  },
  traceReport: {
    placeHolderSearch: "Enter barcode",
    confirmTrackingBtn: "Tracing",
    table: {
      hisid: "HISID",
      itemcode: "Product Code",
      wocode: "WO",
      process: "Process Code",
      version: "Version",
      stepver: "Stage",
      workcenter: "TTSX",
      workunit: "DVX",
      starttime: "Start time",
      endtime: "End time",
      machineid: "Machine",
      workerid: "Employee",
      status: "Status",
      wuname: "Current location",
      packagecode: "Packaging code",
      lotQA: "Lot QA",
      startTimeItem: "Production start date"
    },
    popup: {
      delete: {
        title: "Confirmation of checkpoint deletion",
        confirmText: "Are you sure you want to delete this checkpoint?"
      }
    }
  },
  inventoryLg: {
    placeHolder: {
      Checkinventorycode: "Enter inventory code",
      Dateplanfrom: "Select a scheduled start time",
      Dateplanto: "Select expected end time",
      AreacheckingName: "Enter execution location",
      Datefrom: "Select start time",
      Dateto: "Choose an end time",
      Typechecking: "Select classification",
      PicName: "Enter person in charge",
      Objective: "Enter purpose",
      StatusName: "Select progress type",

    }
  },
  placeHolder: {
    date: "Enter date",
  },
  itemstatistics: {
    from: "From",
    to: "To",
    choosettsx: "Chọn trung tâm sản xuất",
    bywo: "Find by workorder",
    thongke: "Statistical",
    truyvet: "Trace by product code",
    itemstatic: "Item statistics",
  }
};
