export const UnitEN = {
    language: "en",
    unit: {
        searchUnit: "Unit, description",
        tableUnit: {
            unitCode: "Unit",
            description: "Description"
        },
        titleForm: {
            create: "Create unit information",
            update: "Update unit information",
            view: "Detail unit information"
        },
        rule: {
            unitErr: "Unit code is malformed",
            unitLength: "Unit code must not be more than 25 characters",
            unitNotNull: "Unit code is required",
            descriptionLength: "Description must not be more than 255 characters"
        },
        delete: {
            title: "Confirm delete unit",
            description: "Are you sure you want to delete this unit?"
        },
        toastJs: {
            createOK: "New successful unit creation",
            updateOK: "Unit update successful",
            deleteOK: "Successfully deleted unit"
        }
    }
}