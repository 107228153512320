import { checkListLg } from "src/shared/constants/locales/en/QuanlityManager/checklist"
import { 
    GET_LIST_USER, 
    SET_LIST_USER, 
    SAVE_USER, 
    LOOK_UP_USER_LDAP,
    SAVE_LIST_USER_LOOKUP_LDAP
} from "./enum/UserManagementAction";

const UserSyncAction = {
    getListAllUser: (data) => {
        return {
            type: GET_LIST_USER,
            data: data
        }
    },
    setListAllUser: (data) => {
        return {
            type: SET_LIST_USER,
            data: data
        }
    },
    saveUser: (data, action) => {
        return {
            type: SAVE_USER,
            data: data,
            action: action
        }
    },
    lookupUserLDAP: (userName) => {
        return {
            type: LOOK_UP_USER_LDAP,
            userName: userName
        }
    },
    saveUserLookupLDAP: (searchResult) => {
        return {
            type: SAVE_LIST_USER_LOOKUP_LDAP,
            searchResult: searchResult
        }
    }
}

export default UserSyncAction;