import { Select, Space } from "antd"
import { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { baseListData } from "src/shared/constants/enum/constants";
import { bindActionCreators } from "redux";
import CatWarehouseAction from "src/redux-saga-v2/MedicalSuppliesManagement/CatWarehouse/CatWarehouseAction";
import { openLoading } from "src/shared/common/LoadingAction";
import { withTranslation } from "react-i18next";
import { hasPermission, listActionEnum } from "src/shared/common/permistionUtils";
import StringUtils from "src/shared/common/StringUtils";
const WarehouseSelect = memo((props) => {
  const [catWarehouseIDList, setCatWarehouseIDList] = useState([]);
  const [listCBBDataAsset, setListCBBDataAsset] = useState([]);
  const [totalCCBRecord, setTotalCCBRecord] = useState();
  const [hasPermissionGetData, setHasPermissionGetData] = useState(false);
  //Phân trang cho combobox
  const [dataPageCBB, setDataPageCBB] = useState({
    PageIndex: baseListData.baseIndex,
    PageSize: baseListData.baseSize,
  });
  const  handleOnChange = (value, item = null) => {
    setCatWarehouseIDList(value);
    props.onChange(value, item);
  };

  useEffect(() => {
    // openLoading();
    //Nếu user hiện tại có quyền xem danh sách kho thì mới được gọi
    let checkPer = hasPermission(props?.listActionAccessible, listActionEnum.danhmuckho.listbyhospital);
    setHasPermissionGetData(checkPer);
    
    if (checkPer && props?.hospitalId > 0) {
      openLoading();
      // const request = {
      //   listHospitalId: [], 
      //   isGetAll: true
      // }
      // console.log("giá trịrequest ", request);
      // props.CatWarehouseAction.getListWarehouseByListHospitalIdSaga(request);
      let request = {
        hospitalId: props?.hospitalId,
        isGetAll: props?.isGetAll != null ? props?.isGetAll : false
      }
      props.CatWarehouseAction.getListDataWarehouseByHospitalIdSaga(request);

    }
    else if (props?.dataUserHospitalWarehouse) {
      //Nếu không có quyền thì chỉ lấy kho hiện tại thôi
      setListCBBDataAsset([
        {
          CodeOH:props?.dataUserHospitalWarehouse.WarehouseCode,
        value:  props?.dataUserHospitalWarehouse.WarehouseId,
        label: props?.dataUserHospitalWarehouse.WarehouseName,
        }
      ])
    }
  }, []);

  useEffect( () => {
    if (!hasPermissionGetData && props?.dataUserHospitalWarehouse) {
      
      //Nếu không có quyền thì chỉ lấy kho hiện tại thôi
      setListCBBDataAsset([
        {
          Id: props?.dataUserHospitalWarehouse.WarehouseId,
          Name: props?.dataUserHospitalWarehouse.WarehouseName,
          CodeOH:props?.dataUserHospitalWarehouse.WarehouseCode,
        value:  props?.dataUserHospitalWarehouse.WarehouseId,
        label: props?.dataUserHospitalWarehouse.WarehouseName,
        }
      ])
    }
  }, [ props?.dataUserHospitalWarehouse])

  //Nếu mã bệnh viện thay đổi, và có quyền lấy danh sách kho thì cần lấy lại danh sách kho
  useEffect(() => {
    if(props?.hospitalId != null && props?.hospitalId > 0 && hasPermissionGetData)
    {
      let request = {
        hospitalId: props?.hospitalId,
        isGetAll: props?.isGetAll != null ? props?.isGetAll : false
      }
      props.CatWarehouseAction.getListDataWarehouseByHospitalIdSaga(request);
    }
  },[props?.hospitalId])
  //Gọi api lấy danh sách kho theo bệnh viện xong, thì cập nhật list combobox
  useEffect(() => {
    if (props?.listDataWarehouseByHospital?.length > 0) {
      let lst = props?.listDataWarehouseByHospital.map((item) => {
        return { ...item, key: item.Id };
      });
      setTotalCCBRecord(props?.listDataWarehouseByHospital?.TotalCount);
      setListCBBDataAsset(lst);
    }

  },[props?.listDataWarehouseByHospital])
  return (
    <Select
      name="StockCode1"
      className="select-filter w-100"
      size={props?.size != null ? props?.size :  "large"}
      placeholder={props?.placeholder}
      listItemHeight={10}
      listHeight={150}
      allowClear={props?.allowClear}
      showSearch
      optionFilterProp="label"
      disabled = {props?.isDisabled}
      options={listCBBDataAsset?.map((item) => ({
        CodeOH:item.Code,
        value: item.Id,
        label: item.Name,
        CostCentreId:item?.CostCentreId ,
        CostCentreCode:item?.CostCentreCode,
        Costname_l:item?.Costname_l
      }))}
      value={props?.valueSelect}
      onChange={(value,item) => handleOnChange(value, item)}
      optionRender={(option) => (
        <Space>
          <span  aria-label={option.data.label}>
            {option.data.CodeOH}
          </span>
          <span  aria-label={option.data.label}>
            {option.data.label}
          </span>
        </Space>
      )}
      filterOption={(input, option) =>
        (StringUtils.removeAccents((option?.label).toLowerCase()) ?? '').includes(StringUtils.removeAccents(input.toLowerCase())) ||
        (StringUtils.removeAccents((option?.CodeOH).toLowerCase()) ?? '').includes(StringUtils.removeAccents(input.toLowerCase()))
      } //tìm kiếm theo label
    />
  )
})

const mapDispatchToProps = (dispatch) => ({
  CatWarehouseAction: bindActionCreators(CatWarehouseAction, dispatch),
});

const mapStateToProps = (state) => ({
  listWarehouse: state.CatWarehouseReducer.listData, //Danh sách tất cả kho có trong toàn hệ thống
  isGetLstCBBData: state.CatWarehouseReducer.isGetLstData,
  dataUserHospitalWarehouse:state.UserHospitalWarehouseReducer.dataUserHospitalWarehouse,
  listActionAccessible: state.PermissionReducer.listActionAccessible,
  listDataWarehouseByHospital: state.CatWarehouseReducer.listDataWarehouse, //Danh sách kho theo bệnh viện


})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WarehouseSelect));

