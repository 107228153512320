import {
  GET_PAGING_CAT_HOSPITAL_SAGA,
  GET_PAGING_CAT_HOSPITAL_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_CAT_HOSPITAL_SAGA,
  DELETE_CAT_HOSPITAL_REDUCER,
  UPDATE_CAT_HOSPITAL_SAGA,
  CALL_CREATE_UPDATE_SUCCESS_REDUCER,
  CREATE_CAT_HOSPITAL_SAGA,
  GET_LIST_CAT_HOSPITAL_REDUCER,
  GET_LIST_CAT_HOSPITAL_SAGA,
  RESET_GET_LIST_CAT_HOSPITAL_REDUCER,
} from "./enum/TypeCatHospitalAction";

const CatHospitalAction = {
  getPagingSaga: (pageIndex, pageSize, searchTerm = "") => {
    return {
      type: GET_PAGING_CAT_HOSPITAL_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
    };
  },

  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_CAT_HOSPITAL_REDUCER,
      data: data,
    };
  },
  deleteDataSaga: (id) => {
    return {
      type: DELETE_CAT_HOSPITAL_SAGA,
      id: id,
    };
  },

  deleteDataReducer: () => {
    return {
      type: DELETE_CAT_HOSPITAL_REDUCER,
    };
  },
  createDataSaga: (data) => {
    return {
      type: CREATE_CAT_HOSPITAL_SAGA,
      data: data,
    };
  },
  updateDataSaga: (data) => {
    return {
      type: UPDATE_CAT_HOSPITAL_SAGA,
      data: data,
    };
  },
  callCreateUpdateSuccessReducer: () => {
    return {
      type: CALL_CREATE_UPDATE_SUCCESS_REDUCER,
    };
  },
  getListUnitCategorySaga: () => {
    return {
      type: GET_LIST_CAT_HOSPITAL_SAGA,
    };
  },
  getListUnitCategoryReducer: (data) => {
    return {
      type: GET_LIST_CAT_HOSPITAL_REDUCER,
      data: data,
    };
  },
  resetListUnitCategoryReducer: () => {
    return {
      type: RESET_GET_LIST_CAT_HOSPITAL_REDUCER
    };
  },
  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },
};

export default CatHospitalAction;
