import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import { ConfigGenProduct, ConfigGen, ConfigGenMaintance } from "src/shared/constants/enum/constants";
import CommonGetCodeAction from "./CommonGetCodeAction";
import { getCodeMMProductionService, getCodeMaterialService, getCodeMaintanceService } from "./CommonGetCodeService";
import { GET_CODE_MMPRODUCTION_SAGA, GET_CODE_MATERIAL_SAGA, GET_CODE_MAINTANCE_SAGA } from "./enum/TypeCommonGetCodeAction";
export function* getCodeMMProductionSaga(data) {
    try {
        let RequestData = {
            forWhat: data.typeGetCode
        }
        if (
            RequestData.forWhat === ConfigGenProduct.Productionrequirement ||
            RequestData.forWhat === ConfigGenProduct.Model ||
            RequestData.forWhat === ConfigGenProduct.Product ||
            RequestData.forWhat === ConfigGenProduct.GenCheckpoint ||
            RequestData.forWhat === ConfigGenProduct.GenChecklist ||
            RequestData.forWhat === ConfigGenProduct.GenTemPackage ||
            RequestData.forWhat === ConfigGenProduct.GenTemPallet ||
            RequestData.forWhat === ConfigGenProduct.GenTemCatton ||
            RequestData.forWhat === ConfigGenProduct.GenTemBox ||
            RequestData.forWhat === ConfigGenProduct.GenWorkorder ||
            RequestData.forWhat === ConfigGenProduct.GenCodeItem ||
            RequestData.forWhat === ConfigGenProduct.GenLotQc
        ) {
            let response = yield call(getCodeMMProductionService, RequestData);
            if (response.isSuccess) {
                yield put(CommonGetCodeAction.setCodeMMProduction(RequestData.forWhat, response.data, data.key));
            }
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
}
export function* getCodeMaterialSaga(data) {
    try {
        let RequestData = {
            forWhat: data.typeGetCode,
            locationLevelId: data.parentCode,
        }
        if (
            RequestData.forWhat === ConfigGen.Package ||
            RequestData.forWhat === ConfigGen.Package ||
            RequestData.forWhat === ConfigGen.Storebill ||
            RequestData.forWhat === ConfigGen.PO ||
            RequestData.forWhat === ConfigGen.TransferRequest ||
            RequestData.forWhat === ConfigGen.PickingList ||
            RequestData.forWhat === ConfigGen.Whmovetransaction
        ) {
            let response = yield call(getCodeMaterialService, RequestData);
            if (response.isSuccess) {
                yield put(CommonGetCodeAction.setCodeMaterial(RequestData.forWhat, response.data, data.key));
            }
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
}
export function* getCodeMaintanceSga(data) {
    try {
        let RequestData = {
            forWhat: data.typeGetCode
        }
        if (
            RequestData.forWhat === ConfigGenMaintance.Machine ||
            RequestData.forWhat === ConfigGenMaintance.Period
        ) {
            let response = yield call(getCodeMaintanceService, RequestData);
            if (response.isSuccess) {
                yield put(CommonGetCodeAction.setCodeMaintance(RequestData.forWhat, response.data, data.key));
            }
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
}
export default function* CommonGetCodeSaga() {
    yield takeEvery(GET_CODE_MMPRODUCTION_SAGA, getCodeMMProductionSaga);
    yield takeEvery(GET_CODE_MATERIAL_SAGA, getCodeMaterialSaga);
    yield takeEvery(GET_CODE_MAINTANCE_SAGA, getCodeMaintanceSga);
}
