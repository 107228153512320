import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    DELETE_PERIOD_MAINTANCE_API, GET_DATA_MAINTAINCE_CALENDAR_API, GET_DETAIL_PERIOD_BY_CODE_API, CREATE_PERIOD_MAINTANCE_API, UPDATE_PERIOD_MAINTANCE_API,
    GET_DATA_PAGING_MAINTANCE_SCHEDULE_API, CREATE_PERIOD_SCHEDULE_API, CREATE_MAINTANCE_SCHEDULE_API, GET_DETAIL_MAINTANCE_SCHEDULE_API, CARRY_MAINTANCE_SCHEDULE_API,
    SKIP_MAINTANCE_SCHEDULE_API
} from "./enum/MaintainceTypeApi";
export async function getDataMaintainceCalendarService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DATA_MAINTAINCE_CALENDAR_API, data);
}
export async function deletePeriodMaintanceService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + DELETE_PERIOD_MAINTANCE_API + data);
}
export async function getDataPeriodByCodeService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DETAIL_PERIOD_BY_CODE_API + data);
}
export async function createPeriodService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + CREATE_PERIOD_MAINTANCE_API, data);
}
export async function updatePeriodService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + UPDATE_PERIOD_MAINTANCE_API, data);
}
export async function getDataPagingMaintanceScheduleService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DATA_PAGING_MAINTANCE_SCHEDULE_API, data);
}
export async function createPeriodScheduleService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + CREATE_PERIOD_SCHEDULE_API + data);
}
export async function createMaintainScheduleService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + CREATE_MAINTANCE_SCHEDULE_API, data);
}
export async function getDetailMaintanceScheduleService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DETAIL_MAINTANCE_SCHEDULE_API, data);
}
export async function carryMaintanceScheduleService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + CARRY_MAINTANCE_SCHEDULE_API, data);
}
export async function skipMaintanceScheduleService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + SKIP_MAINTANCE_SCHEDULE_API, data);
}