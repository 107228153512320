import {
  GET_PAGING_PLANNING_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_PLANNING_REDUCER,
  CREATE_PLANNING_REDUCER,
  UPDATE_PLANNING_REDUCER,
  GET_INFO_PLANNING_BY_ID_REDUCER,
  GET_PAGING_RETAIL_PLANNING_REDUCER,
  PREDICTABILITY_RETAIL_AUTO_REDUCER,
  PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER,
  PREDICTABILITY_BUDGET_AUTO_REDUCER,
  PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_REDUCER,
  RESET_INFO_PLANNING_BY_ID_REDUCER,
  GET_PAGING_DETAIL_BY_ID_REDUCER,
  UPDATE_RANGE_DETAIL_PLANNING_REDUCER,
  GET_INFO_PLANNING_PAGING_BY_ID_REDUCER,
  DELETE_RANGE_PLANNING_DETAIL_REDUCER,
  PROPOSAL_SUMMARY_REDUCER,
  RECORD_NOT_FOUND_REDUCER,
  RESET_RECORD_NOT_FOUND_REDUCER,
} from "./enum/TypePlanningAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,
  isDeleteSuccess: false,
  isCreateUpdateSuccess: false,
  data: null, //Chứa thông tin chi tiết của một phiếu dự trù

  listDataRetailPlanning: [],
  isGetLstDataRetailPlanning: false,

  //biến danh cho tính toán dự trù ko chọn vật tư y tế
  listPlanningDetailPredictabilityRetail: [],
  isGetPredictabilityRetailAuto: false,

  //biến dành cho tính toán dự trù khi chọn vật tư y tế
  planningIdAfterPredictability: [],
  isGetPredictabilityRetailListProduct: false,


  //biến dành cho tính toán dự trù toàn viện khi không chọn vật tư y tế
  listPlanningDetailPredictabilityBudget: [],
  isGetLstPlanningDetailPredictabilityBudget: false,

  //biên dành cho tính toán dự trù toàn viện khi chọn vật tư y tế
  listPlanningDetailPredictabilityBudgetListProduct: [],
  isGetLstPlanningDetailPredictabilityBudgetListProduct: false,

  //Kết quả sau khi tính toán dự trù kho lẻ
  pagingData: {}, //Thông tin chi tiết của một phiếu dự trù sau khi tính dự trù 
  isGetPagingData: false,

  //Danh sách chi tiết dự trù theo mã phiếu dự trù
  lstDetailPagingById: [],
  isGetLstDetailPagingById: false,
  totalCount: 0,

  //Thông tin chỉnh sửa một chi tiết dự trù
  isUpdateRangeDetail: false,
  //Xóa thành công danh sách chi tiết dự trù 
  isDeleteRangeDetailSuccess: false,
  //Tham khảo đề xuất kho lẻ thành công 
  isProposalSummerySucess: false,

  recordNotFound:false,
};

const PlanningReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_PLANNING_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case GET_PAGING_RETAIL_PLANNING_REDUCER:
      if (action.data) {
        state.listDataRetailPlanning = action.data;
        state.isGetLstDataRetailPlanning = true;
      }
      return { ...state };
    case PREDICTABILITY_RETAIL_AUTO_REDUCER:
      if (action.data) {
        // state.listPlanningDetailPredictabilityRetail = action.data;
        // state.isGetPredictabilityRetailAuto = true;
        state.pagingData = action.data;
        state.isGetPredictabilityRetailAuto = true;
      }
      return { ...state };
    case PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER:
      if (action.data) {
        state.planningIdAfterPredictability = action.data;
        state.isGetPredictabilityRetailListProduct = true;
      }
      return { ...state };
    case PREDICTABILITY_BUDGET_AUTO_REDUCER:
      if (action.data) {
        state.listPlanningDetailPredictabilityBudget = action.data;
        state.isGetLstPlanningDetailPredictabilityBudget = true;
      }
      return { ...state };
    case PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_REDUCER:
      if (action.data) {
        state.listPlanningDetailPredictabilityBudgetListProduct = action.data;
        state.isGetLstPlanningDetailPredictabilityBudgetListProduct = true;
      }
      return { ...state };
    case GET_PAGING_DETAIL_BY_ID_REDUCER:
      if (action.data) {

        state.lstDetailPagingById = action.data.Items;
        state.totalCount = action.data.TotalCount;
        state.isGetLstDetailPagingById = true;
      }
      return { ...state };
    case PROPOSAL_SUMMARY_REDUCER:
      if (action.data) {
        
        state.planningIdAfterPredictability = action.data;
        state.isProposalSummerySucess = true;
      }
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      state.isGetLstDataRetailPlanning = false;
      state.isGetPredictabilityRetailAuto = false;
      state.isGetPredictabilityRetailListProduct = false;
      state.isGetLstPlanningDetailPredictabilityBudget = false;
      state.isGetLstPlanningDetailPredictabilityBudgetListProduct = false;
      state.isGetLstDetailPagingById = false;
      state.isUpdateRangeDetail = false;
      state.isDeleteRangeDetailSuccess = false;
      state.isProposalSummerySucess = false;
      return {
        ...state,
      };
    case CREATE_PLANNING_REDUCER:
      state.isCreateUpdateSuccess = true;
      return {
        ...state,
      };
    case GET_INFO_PLANNING_BY_ID_REDUCER:
      state.data = action.data;
      state.isGetLstData = true
      return {
        ...state,
      };
    case RESET_INFO_PLANNING_BY_ID_REDUCER:
      state.data = null;
      state.isGetLstData = false
      return {
        ...state,
      };

    case UPDATE_PLANNING_REDUCER:
      state.isCreateUpdateSuccess = true;
      return {
        ...state,
      };
    case UPDATE_RANGE_DETAIL_PLANNING_REDUCER:
      state.isUpdateRangeDetail = true;
      return {
        ...state,
      };
    case DELETE_PLANNING_REDUCER:
      state.isDeleteSuccess = true;
      return {
        ...state,
      };
    case DELETE_RANGE_PLANNING_DETAIL_REDUCER:
      state.isDeleteRangeDetailSuccess = true;
      return {
        ...state,
      };
    case RECORD_NOT_FOUND_REDUCER:
      state.recordNotFound = true;
      return {
        ...state,
      };
    case RESET_RECORD_NOT_FOUND_REDUCER:
      state.recordNotFound = false;
      return {
        ...state,
      };
    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };
    default:
      return { ...state };
  }
};

export default PlanningReducer;
