export const timeScheduleManager = {
    language: "en",
    tableCalendar: {
        name: "Calendar Name",
        area: "Applicable Area",
        creator: "Creator",
        createDate: "Date Created",
        description: "Description",
        action: "Action",
        placeHolderSearchCalendarManager: "Calendar name, application area, creator",
        placeHolder_area: "Select the applicable area",
        mustHaveDateRange: "Required to choose date range",
    },
    search: "Search",
    create: "Create",
    titleDeleteCalendar: "Delete calendar",
    formInfo: {
        title_create: "Create new Calendar",
        title_infor: "Calendar information",
        title_edit: "Update calendar",
        describe: "Describe",
        placeholder: "Enter describe",
        placeholder_name: "Enter name calendar",
        placeHolderTimeShift: "Enter name time shift",
        placeHolderSymbol: "Enter the symbol",
        placeHolderShiftBreak: "Enter time break",
    },
    groupWorkingDay: {
        title_create: "Add a new group of working days",
        title_infor: "Group information working days",
        title_edit: "Update group of working days",
        title_delete: "Delete group of working days",
        text_confirm_delete: "Do you want to delete this business day group?",
        describe: "Description",
        placeholder: "Enter a description",
        placeHolderName: "Enter the group name",
        required_color: "Color cannot be left blank",
        maxLength_color: "Color up to 50 characters",
        required_name: "The group name cannot be left blank",
        maxLength_name: "Group name up to 50 characters",
    },
    mes: {
        empty_name: "Calendar name cannot be empty",
        maxLength_name: "Calendar name no longer than 100 characters",
        empty_creator: "Creator name cannot be empty",
        maxLength_creator: "Creator name longer than 6 characters",
        empty_des: "Describe cannot be empty",
        maxLength_des: "Describe no longer than 255 characters",
        required_date: "Create date is required",
        empty_workarea: "You must choose a work area",
        create_calendar_success: "Create new calendar successfully",
        update_calendar_success: "Update calendar successfully",
        delete_calendar_success: "Delete calendar successfully",
        update_calendar_fail: "Update fail!",
        delete_calendar_fail: "Delete fail!",
    }
}