import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_LIST_ASSETMENT_TRANSFER_API,
  GET_DETAIL_ASSETMENT_TRANSFER_API,
  CREATE_ASSETMENT_TRANSFER_API,
  UPDATE_ASSETMENT_TRANSFER_API,
  DELETE_ASSETMENT_TRANSFER_API
} from "./enum/TypeAssetmentTransferAPI";
import { apiPostHandler } from "src/shared/common/apiHandler";

export async function getListAssetmentTransferService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_LIST_ASSETMENT_TRANSFER_API,
    data
  );
}

export async function getDetailAssetmentTransferService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_DETAIL_ASSETMENT_TRANSFER_API,
    data
  );
}

export async function createAssetTransferReqService(data) {
  return await apiPostHandler(
    configApp.apiGateWay + CREATE_ASSETMENT_TRANSFER_API,
    data
  );
}

export async function updateAssetTransferReqService(data) {
  return await apiPostHandler(
    configApp.apiGateWay + UPDATE_ASSETMENT_TRANSFER_API,
    data
  );
}

export async function deleteAssetTransferReqService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_ASSETMENT_TRANSFER_API, data
  );
}
