export const GET_PAGING_ITEM_ALLOCATION_REDUCER = "GET_PAGING_ITEM_ALLOCATION_REDUCER";
export const CALL_RESULT_ITEM_ALLOCATION_REDUCER = "CALL_RESULT_ITEM_ALLOCATION_REDUCER";
export const UPDATE_ITEM_ALLOCATION = "UPDATE_ITEM_ALLOCATION";
export const GET_PAGING_ITEM_ALLOCATION_SAGA = "GET_PAGING_ITEM_ALLOCATION_SAGA"
export const CALL_DEFAULT_ITEM_ALLOCATION_REDUCER = "CALL_DEFAULT_ITEM_ALLOCATION_REDUCER";

export const GET_ITEMCODE_BY_SAGA = "GET_ITEMCODE_BY_SAGA";
export const GET_ITEMCODE_BY_REDUCER = "GET_ITEMCODE_BY_REDUCER";


export const START_ALLOCATION_ITEMCODE_SAGA = "START_ALLOCATION_ITEMCODE_SAGA";
export const START_ALLOCATION_ITEMCODE_REDUCER = "START_ALLOCATION_ITEMCODE_REDUCER";

