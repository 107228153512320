export const productionCoordinator = {
    language: "vi",
    productionCoordinator: {
        toast: {
            completed: "Lệnh sản xuất đã được hoàn thành, không thể hủy"
        },
    },
    table: {
        "PRODUCTION_ORDER": "LỆNH SẢN XUẤT",
        "PRODUCT": "SẢN PHẨM",
        "PRODUCTION_CENTER": "TRUNG TÂM SẢN XUẤT",
        "tentative_start": "bắt đầu dự kiến",
        "Expected_ending": "kết thúc dự kiến",
        "actual_start": "bắt đầu thực tế",
        "realistic ending": "kết thúc thực tế",
        "AMOUNT": "SỐ LƯỢNG",
        "STATUS": "TRẠNG THÁI",
        "Change_status": "Đổi trạng thái ",
    },
    popup: {
        "Pause_command": "Tạm dừng lệnh ",
        "Pause_command_comment": "Bạn chắc chắn muốn tạm dừng các lệnh làm việc này ? ",
        "Run_command": "Chạy lệnh ",
        "Run_command_comment": "Bạn chắc chắn muốn chạy các lệnh làm việc này ? ",
        "Cancel_the_work_order": "Huỷ lệnh làm việc ",
        "Cancel_the_work_order_cmt": "Bạn chắc chắn muốn huỷ lệnh làm việc này ? ",
    },
    form: {
        field: {
            "Production_order": "Lệnh sản xuất ",
            "Product": "Sản phẩm ",
            "Production_Center": "Trung tâm sản xuất ",
            "The_expected_number": "Số lượng dự kiến ",
            "Expected_start": "Bắt đầu dự kiến ",
            "Expected_ending": "Kết thúc dự kiến ",
            "STATUS": "Trạng thái ",
            "Realistic_start": "Bắt đầu thực tế ",
            "Realistic_ending": "Kết thúc thực tế ",
            "run": "Chạy ",
            "Pause": "Tạm dừng ",
            "Complete": "Hoàn thành ",
            "Cancel": "Tạm dừng lệnh ",
            "Pause_command": "Huỷ ",
        },
        complete: "Hoàn thành lệnh sản xuất ",
        update: "Cập nhật lệnh sản xuất ",
        view: "Chi tiết lệnh sản xuất ",
    },
    schedule: {
        Quantity: "Số lượng",
        Status: "Trạng thái",
        Expected: "Dự kiến",
        Reality: "Thực tế",
    }
};