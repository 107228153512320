export const LotQA = {
    language: 'en',
    lotQA: {
        slmauthanslinlot: "The number of samples does not exceed the number of products in the lot",
        itempackage: "Item or package ",
        existslot: " already exists in LOT",
        needscan: "You need to select the work order before scanning",
        enterqty: "You need to enter the number of samples to take",
        scaninlot: "You need to Scan the LOT or Package code",
        createLOT: "Create new LOT QA",
        updateLOT: "Update LOT QA",
        seeLOT: "View details of LOT QA",
        random: "Random Sampling",
        scanitempackage: "Scan item code/ or packing code to add to lot list",
        enteritemcode: "Enter item code",
        createcompleted: "Create New Successfully",
        updatecompleted: "Update successful",
    },
    LotSearch: {
        placeholder: 'Search by PASS lot number, product code or work order',
        status: 'Status',
        timeFrom: 'From date',
        timeTo: 'To date',
    },
    LotQATable: {
        numberLot: 'Number of lot',
        keyProduct: 'Key Product',
        orderProduction: 'Order production',
        quatityLot: 'Quantity lot',
        numberOfSamples: 'Number of samples',
        ramdumSampling: 'Ramdum sampling',
        quantityChecked: 'Quantity checked',
        createdateLot: 'Createdate lot',
        dateReview: 'Date review',
        action: 'Action',
        Record: 'Record',
        Evaluate: 'Evaluate'
    },
    LotForm: {
        enterLot: 'Enter number LOT',
        quantityChecked: 'Quantity checked',
        chooseWO: 'Choose WO',
        totalLot: 'Total LOT',
        quantityneed: 'Number of samples to take',
        packingCode: 'Packing Code',
        itemCode: 'Item Code',
        quantityneedPh: 'Enter number of samples to take',
    }
}