export const approvalList = {
    language: "en",
    approvalList: {
        table: {
            name: 'File name',
            type: 'Type of approval',
            sending_by: 'Sender',
            sending_date: 'Sent date',
            level: 'Level',
            status: 'Status',
            action: "Action"
        },
        search: "Search by document name, approval type",
        form: {
            fileName: "Request name:",
            description: "Description:",
            attachedFiles: "Attached files:",
            create_date: "Create date:",
            placeholder: "Import content"
        },
        approval: {
            title: "Confirm approval",
            reason: "Note",
            placeholder: "Enter approval notes"
        },
        reject: {
            title: "Refuse to approve",
            reason: "Reasons for refusal of approval",
            placeholder: "Enter the reason for the refusal"
        },
        btn: {
            confirm: "Confirm"
        },
        rule: {
            errorReason: "Reason cannot be left blank",
            lenghtReason: "The reason can't be more than 500 characters"
        }
    },
};