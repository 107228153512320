import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PAGING_MEDICAL_MATERIAL_SAGA,GET_LIST_MEDICAL_MATERIAL_SAGA
} from "./enum/TypeCatMedicalMaterialAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import CatMedicalMaterialAction from "./CatMedicalMaterialAction";
import {
  getPagingCatMedicalMaterialService,getListCatMedicalMaterialService
} from "./CatMedicalMaterialService";

export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    const unitCode = data.unitCode ? data.unitCode : "";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      searchTerm: searchTerm,
      unitCode: unitCode,
    };
    const response = yield call(getPagingCatMedicalMaterialService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          CatMedicalMaterialAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm
          )
        );
      } else {
        yield put(CatMedicalMaterialAction.getPagingReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}




export default function* CatMedicalMaterialManagementSaga(data) {
  yield takeEvery(GET_PAGING_MEDICAL_MATERIAL_SAGA, getPagingSaga);
  
}
