export const timeShiftManager = {
    language: "vn",
    placeholder: "Nhập tên ca làm việc",
    search: "Tìm kiếm",
    tableTime: {
        time_shift: "Ca làm việc",
        symbol: "Ký hiệu",
        des: "Mô tả",
        hour_start: "Giờ bắt đầu",
        hour_end: "Giờ kết thúc",
        action: "Thao tác",
    },
    formTimeShift: {
        Shift: "Ca làm việc",
        title_create: "Thêm mới ca làm việc",
        title_infor: "Thông tin ca làm việc",
        title_edit: "Cập nhật ca làm việc",
        title_delete: "Xóa ca làm việc",
        text_confirm_delete: "Bạn có muốn xóa ca làm việc này?",
        describe: "Mô tả",
        placeholder: "Nhập mô tả"
    },
    groupWorkingDay: {
        title_create: "Thêm mới nhóm ngày làm việc",
        title_infor: "Thông tin nhóm ngày làm việc",
        title_edit: "Cập nhật nhóm ngày làm việc",
        title_delete: "Xóa nhóm ngày làm việc",
        text_confirm_delete: "Bạn có muốn xóa nhóm ngày làm việc này?",
        describe: "Mô tả",
        placeholder: "Nhập mô tả",
        placeHolderName: "Nhập tên nhóm",
        required_color: "Màu sắc không được bỏ trống",
        maxLength_color: "Màu sắc tối đa 50 kí tự",
        required_name: "Tên nhóm không được bỏ trống",
        maxLength_name: "Tên nhóm tối đa 50 kí tự",
    },
    mesTimeShift: {
        empty_name: "Tên ca làm việc không được để trống",
        maxLength_name: "Tên ca làm việc không dài quá 100 kí tự",
        empty_creator: "Ký hiệu không được để trống",
        maxLength_creator: "Ký hiệu không dài quá 6 kí tự",
        required_hour_start: "Giờ bắt đầu không được để trống",
        required_hour_end: "Giờ kết thúc không được để trống",
        create_shift_success: "Tạo mới ca làm việc thành công",
        update_shift_success: "Cập nhật ca làm việc thành công",
        delete_shift_success: "Xóa ca làm việc thành công",
        update_shift_fail: "Cập nhật thất bại!",
        delete_shift_fail: "Xóa thất bại!",
    },
    recreationRequest: {
        listBreakTime: "Danh sách thời gian giải lao",
        name_break_time: "Tên giờ giải lao",
        title_create: "Thêm giờ giải lao",
        title_edit: "Cập nhật giờ giải lao",
        title_delete: "Xóa giờ giải lao",
        text_confirm_delete: "Bạn có muốn xóa giờ giải lao?",
        mes: {
            required_name: "Tên giờ giải lao không được để trống",
            maxLength_name: "Tên giờ giải lao không dài quá 100 ký tự",
            already_exist_name: "Tên giờ giải lao bị trùng",
            invalid_time: "Thời gian bắt đầu lớn hơn thời gian kết thúc",
            hour_start_invalid: "Giờ bắt đầu không hợp lệ",
            hour_end_invalid: "Thời gian kết thúc không họp lệ",
            hour_start_less_than_hour_end: "Giờ bắt đầu phải nhỏ hơn giờ kết thúc",
            end_time_bigger_end_time_of_time_schedule: "Giờ kết thúc phải nhỏ hơn giờ kết thúc của ca làm việc",
            create_success: "Tạo mới thành công",
            update_success: "Cập nhật thành công",
        }
    }
}