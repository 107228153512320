export const StoreLocationScreen = {
    language: "vi",
    StorageLang: {
        listgoodsempty: "List of goods is empty!",
        page: {
            StoreVoucher: "Store voucher",
            CreateVoucher: "Create Store voucher",
            StoreGoods: "Store goods",
            TimeStoreGoods: "Time store goods",
            btn_success: "Finish",
            hour: " hour",
            minute: " minute",
            second: " second",
            palaceHolderLocation: "Enter storage location",
            palaceHolderESL: "Enter expected store location",
            palaceHoderASL: "Enter actual store location",
            palaceHoderPackage: "Enter package code",
            palaceHoderGoods: "Enter goods code",
            palaceHoderGoodsName: "Enter goods name",
            backtitle: "Storage location",
            numberVoucher: "Number voucher",
            staffName: "Staff",
            Undefined: "Undefined",
            completed: "Completed",
        },
        form: {
            titleDelete: "Confirmation of stocking",
            textConfirm: "Are you sure you want to store the goods?",
        },
        table: {
            ExpectedStorageLocation: "Expected storage location",
            ActualStorageLocation: "Actual storage location",
            PackageCode: "Package code",
            GoodsCode: "Goods code",
            GoodsName: "Goods name",
            ChosenToTakeAway: "Chosen to take away",
            ScanPackage: "Number package chosened",
            EstimatedTimeOfStorage: "Estimated time of storaga",
            NumberOfLocationsToMoveToForStorage: "Number of location to move for storage",
            titleTableCurren: "The actual package is located at",
            titleTableLastest: "Package is expected to be stored at",
            packageandlocation: "Package stored in location",
            packageScanLastest: "Package code scan nearest",
            Action: "Action",
        }
    },
    notification: {
        f1: "Currently 1 stock order is being processed"
    },
    toastJs: {
        includedNo: "not included in the stock order",
        Scanned: "Scanned before",
        Package: "This package is already in stock",
        complete: "Completed stock order",
        storageLocation: "The storage location cannot be left empty",
        packageNo: "This package does not exist",
        successfulStorage: "Successfully put the goods in place"
    },
    storeLocationCheck: {
        packageNo: "Package code is not valid",
        noChoosePackage: "No package has been selected for storage yet",
        noList: "not in the list or previously selected",
    }
}