export const GET_ALL_SOP_API = "/api/production-management-service/sop/get-all";
export const CREATE_SOP_API = "/api/production-management-service/sop/create-sop";
export const UPDATE_SOP_API = "/api/production-management-service/sop/update-sop-version";
export const GET_DETAIL_SOP_API = "/api/production-management-service/sop/get-detail-sop-version/";
export const CHANGE_VERSION_SOP_API = "/api/production-management-service/sop/change-version-sop/";
export const GET_DROPDOWN_VERSION_API = "/api/production-management-service/sop/get-dropdown-sop-version/";
export const DELETE_VERSION_API = "/api/production-management-service/sop/delete-sop-version/";






