

export const GET_COST_CENTER_EV5_BY_SEGMENT_SAGA = "GET_COST_CENTER_EV5_BY_SEGMENT_SAGA";
export const GET_COST_CENTER_EV5_BY_SEGMENT_REDUCER = "GET_COST_CENTER_EV5_BY_SEGMENT_REDUCER";

export const DELETE_INVENTORY_PLAN_SAGA = "DELETE_INVENTORY_PLAN_SAGA";
export const DELETE_INVENTORY_PLAN_REDUCER = "DELETE_INVENTORY_PLAN_REDUCER";

export const CREATE_INVENTORY_PLAN_SAGA = "CREATE_INVENTORY_PLAN_SAGA";
export const CALL_CREATE_SUCCESS_REDUCER = "CALL_CREATE_SUCCESS_REDUCER";

export const UPDATE_INVENTORY_PLAN_SAGA = "UPDATE_INVENTORY_PLAN_SAGA";
export const CALL_UPDATE_SUCCESS_REDUCER = "CALL_UPDATE_SUCCESS_REDUCER";

export const GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_SAGA = "GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_SAGA";
export const GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_REDUCER = "GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";