export const workAreaLag = {
    language: "vn",
    workArea: {
        workTitle: "Khu vực sản xuất",
        table: {
            code: "Mã KVSX",
            name: "Tên KVSX",
            slocWorkAreaCode: "Mã kho KVSX",
            nameWorker: "Người quản lý",
            note: "Ghi chú",
            active: "Đang hoạt động",
            action: "Thao tác",
            dropdownStatus: {
                all: "Tất cả",
                active: "Đang hoạt động",
                nonActive: "Không hoạt động",
            },
            noneDataTable: "Không có dữ liệu hiển thị",
        },
        search: {
            placeHolder: {
                code: "Mã KVSX",
                name: "Tên KVSX",
                slocWorkAreaCode: "Mã Kho KVSX",
                nameWorker: "Người quản lý",
                note: "Ghi chú",
            },
        },
        form: {
            add_new: "Thêm khu vực sản xuất",
            update: "Cập nhật khu vực sản xuất",
            see_detail: "Thông tin khu vực sản xuất",
            code: "Mã khu vực sản xuất",
            name: "Tên khu vực sản xuất",
            nameWorker: "Người quản lý",
            note: "Ghi chú",
            status: "Đang hoạt động"
        },
        validate: {
            name: {
                required: "Tên khu vực sản xuất không được bỏ trống",
                maxLength: "Tên khu vực sản xuất không được quá 100 ký tự",
            },
            code: {
                type: "Mã khu vực sản xuất không chứa kí tự đặc biệt hoặc khoảng trống",
                text: "Mã KVSX độ dài tối đa 9 ký tự UPPER,chỉ gồm số và chữ, ko khoảng trắng",
                required: "Mã khu vực sản xuất không được bỏ trống",
                maxLength: "Mã khu vực sản xuất không được quá 9 ký tự",
            },
            note: {
                maxLength: "Ghi chú không được quá 255 ký tự",
            },
        },
        delete: {
            title: "Xoá khu vực sản xuất",
            description: "Bạn có chắc chắn muốn xóa khu vực sản xuất này?",
        },
        btn: {
            add_new: "Thêm mới",
            close: "Đóng",
            cancel: "Huỷ",
            add: "Thêm mới",
            update: "Cập nhật"
        },
        toastjs: {
            createSuccess: "Tạo mới KVSX thành công",
            editSuccess: "Cập nhật KVSX thành công",
            deleteSuccess: "Xoá KVSX thành công",
            codeRepeat: "Mã KVSX đã bị trùng, không thể lấy mã KVSX này",
            codeCharacters: "Mã KVSX độ dài tối đa 9 ký tự UPPER,chỉ gồm số và chữ, ko khoảng trắng"
        },
        confirm: {
            createSuccess: "Tạo mới KVSX thành công",
            updateSuccess: "Cập nhật KVSX thành công",
            deleteSuccess: "Xóa thành công KVSX",
            //comfirmText: "Version đang được sử dụng, không được xóa",
            comfirmTextDeleted: "Khu vực sản xuất đã có dữ liệu liên kết, không thể xóa",
        }
    },
};