import {
    GET_DATA_FROM_SAGA,
    SET_DATA_TO_REDUCER,
    RESET_DATA_REDUCER,
    DELETE_CHECKPOINT_SAGA,
    CALL_DELETE_CHECKPOINT_DONE,
    GET_DETAIL_CHECK_POINT_SAGA,
    SET_DETAIL_CHECK_POINT_REDUCER,
    CALL_CREATE_OR_UPDATE_CHECKPOINT_REDUCER,
    UPDATE_CHECKPOINT_SAGA,
    CREATE_CHECKPOINT_SAGA,
    SAVE_LIST_INDEX_SIZE,
    UPDATE_TABLE_BY_TYPE_CHECK_POINT_SCREEN,
} from "./enum/actionEnum";

const CheckpointAction = {
    getDataFromSaga: (searchKey = '', pageIndex = 1, pageSize = 10, userId = "") => {
        return {
            type: GET_DATA_FROM_SAGA,
            searchKey,
            pageIndex,
            pageSize,
            userId,
        }
    },
    setDataToReducer: (data) => {
        return {
            type: SET_DATA_TO_REDUCER,
            data,
        }
    },
    setDefault: () => {
        return {
            type: 'default',
        }
    },
    deleteCheckpointSaga: (checkpointCode) => {
        return {
            type: DELETE_CHECKPOINT_SAGA,
            checkpointCode,
        }
    },
    callDeleteDoneReducer: () => {
        return {
            type: CALL_DELETE_CHECKPOINT_DONE,
        }
    },
    getDetailCheckpointSaga: (checkpointCode = "") => {
        return {
            type: GET_DETAIL_CHECK_POINT_SAGA,
            checkpointCode,
        }
    },
    setDetailCheckpointReducer: (detailData = {}) => {
        return {
            type: SET_DETAIL_CHECK_POINT_REDUCER,
            detailData,
        }
    },
    createCheckpointSaga: (data = {}) => {
        return {
            type: CREATE_CHECKPOINT_SAGA,
            data,
        }
    },
    updateCheckpointSaga: (data = {}) => {
        return {
            type: UPDATE_CHECKPOINT_SAGA,
            data,
        }
    },
    callCreateOrUpdateCheckpointReducer: () => {
        return {
            type: CALL_CREATE_OR_UPDATE_CHECKPOINT_REDUCER,
        }
    },
    resetData: () => {
        return {
            type: RESET_DATA_REDUCER,
        }
    },

    updateTableByStatus: (status = false) => {
        return {
            type: UPDATE_TABLE_BY_TYPE_CHECK_POINT_SCREEN,
            status,
        }
    },
}

export default CheckpointAction;