export const NCCInformation = {
    language: "en",
    NCCInformation: {
        searchNCC: "Code, name, tax code partner",
        tableNCC: {
            number: "Number",
            partnerCode: "Partner code",
            partnerName: "Partner name",
            note: " Note",
            partnerType: "Partner type",
            address: "Address",
            phoneNumber: "Phone number",
            bankAccount: "Bank account",
            taxCode: "Tax code",
            active: "Active"
        },
        deleteNCC: {
            title: "Confirm delete partner",
            description: "Are you sure you want to delete this partner?"
        },
        btnNCC: {
            delete: "Delete",
            close: "Close",
            confirmStorePackages: "Agree"
        },
        typeDropdown: {
            ncc: "Supplier",
            kh: "Client"
        },
        rulesForm: {
            partnerCodeErr: "Partner code is malformed",
            partnerCodeNotNull: "Partner code is required",
            partnerCodeLength: "Partner code must not be more than 50 characters",
            partnerNameNotNull: "Partner name is required",
            partnerNameLength: "Partner name must not be more than 255 characters",
            partnerType: "Partner type is required",
            emailErr: "Email is malformed",
            emailLength: "Email must not be more than 255 characters",
            PhoneErr: "Phone number code is malformed",
            phoneLength: "Phone number must not be more than 11 characters",
            bankAccount: "Bank account must not be more than 255 characters",
            taxCode: "Tax code must not be more than 20 characters",
            address: "Address must not be more than 255 characters",
            note: "Note must not be more than 255 characters",
            taxCodeErr: "Tax code is not in the correct format"

        },
        titleForm: {
            create: "Create partner information",
            update: "Update partner information",
            view: "Detail partner information"
        },
        toastJs: {
            createOK: "Successfully created new partner data",
            updateOK: "Successfully updated partner data",
            deleteOK: "Successfully deleted partner data"
        }
    }
}
