export const approvalList = {
    language: "vn",
    approvalList: {
        table: {
            name: 'Tên yêu cầu',
            type: 'Loại trình duyệt',
            sending_by: 'Người gửi',
            sending_date: 'Ngày gửi',
            level: 'Cấp độ',
            status: 'Trạng thái',
            action: "Thao tác"
        },
        search: "Tìm kiếm theo tên yêu cầu",
        form: {
            fileName: "Tên yêu cầu:",
            description: "Mô tả:",
            attachedFiles: " File đính kèm:",
            create_date: "Ngày tạo:",
            placeholder: "Nhập nội dung"
        },
        approval: {
            title: "Xác nhận phê duyệt",
            reason: "Ghi chú",
            placeholder: "Nhập ghi chú phê duyệt"
        },
        reject: {
            title: "Từ chối phê duyệt",
            reason: "Lý do từ chối phê duyệt",
            placeholder: "Nhập nội dung lý do từ chối"
        },
        btn: {
            confirm: "Xác nhận"
        },
        rulesReason: {
            errorReason: "Lý do không được bỏ trống",
            lenghtReason: "Lý do không được quá 500 ký tự"
        }
    },
};
