import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_ALL_PACKING_PROCESS_API,
    CREATE_PACKING_PROCESS_API,
    UPDATE_PACKING_PROCESS_API,
    DELETE_PACKING_PROCESS_API,
    GET_DROP_DOWN_PACKING_LEVEL_API,
    GET_PACKING_PROCESS_ID_API
} from "./enum/PackingProcessTypeApi";
export async function getAllPackingProcessService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_ALL_PACKING_PROCESS_API,data);
}
export async function createPackingProcessService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + CREATE_PACKING_PROCESS_API, data);
}
export async function updatePackingProcessService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + UPDATE_PACKING_PROCESS_API, data);
}
export async function deletePackingProcessService(id) {
    return await CommonBase.postAsync(configApp.apiGateWay + DELETE_PACKING_PROCESS_API + "/" + id);
}
export async function getDropdownPackingLevelService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROP_DOWN_PACKING_LEVEL_API, data);
}
export async function getPackingProcessId(id) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_PACKING_PROCESS_ID_API + "/" + id);
}