export const TakenPackageTicketEN = {
    language: "en",
    takenPackageTicket: {
        search: "Picking list code, area, picker, status, ticket",
        searchDetail: "Pick up slot, commodity code",
        table: {
            area: "Delivery to the area",
            worker: "Pick-up person",
            status: "Status",
            requestForm: "Request form",
        },
        form: {
            petitioner: "Petitioner",
            reason: "Reason for moving",
        },
        formDetail: {
            ticket: "Pick-up slip",
            location: "Pick-up location",
            sloc: "Sloc pick up goods",
            productCode: "Goods code",
            quantity: "Quantity to take",
            quantityTake: "Amount taken",
            unit: "Unit",
            status: "Status"
        },
        titleForm: {
            create: "Add more coupons",
            view: "Details of receipts"
        },
        delete: {
            title: "Delete the pick up ticket",
            description: "Are you sure you want to delete this coupon?"
        },
        confirmCreate: {
            title: "Confirmation of receipt of receipt",
            description: "Are you sure you want to create this coupon ?",
            none: "No votes have been selected yet"
        },
        toastJs: {
            createOK: "Order creation successful",
            deleteOK: "Successfully deleted the voucher",
        }
    }
}