export const formTypeEnum = {
    Update: 1,
    Create: 2,
    View: 3,
};
//Trạng thái kế hoạch kiểm dùng cho bộ lọc
export const INVENTORY_PLAN__STATUS_FILTER = {
    TEMPORATY_SAVE: 1,
    PENDING_APPROVAL: 2,//Chờ kế toán trưởng phê duyệt
    REJECT:3,
    IN_PROGRESS:4,
    SUCCESSED: 5,
}
//Trạng thái kế hoạch kiểm kê 6 tháng dùng cho thêm/sửa kế hoạch
export const INVENTORY_PLAN_6_MONTH_STATUS = {
    TEMPORATY_SAVE: 1,
    PENDING_APPROVAL: 2,//Chờ kế toán trưởng phê duyệt
    REJECT:3,
    APPROVAL_LEVEL_1:4,//Chờ giám đốc phê duyệt
    IN_PROGRESS:5,
    COMPLETION_LEVEL_1: 6, 
    SUCCESSED: 7,
}
export const UPDATE_INVENTORY_OFFICER = {
    UPDATE_IN_PROGRESS : 10
}
export const INVENTORY_PLAN_1_MONTH_STATUS = {
    TEMPORATY_SAVE: 1,
    IN_PROGRESS: 2, 
    SUCCESSED: 3,
}
//Loại phiếu kiểm kê
export const INVENTORY_TYPE = {
    ANNUAL_INVENTORY: 1,
    REINVENTORY: 2
}


export const INVENTORY_PLAN_6_MONTH_STATUS_DETAILS = {
    TEMPORATY_SAVE: {
        VALUE: INVENTORY_PLAN_6_MONTH_STATUS.TEMPORATY_SAVE,
        TEXT: "Lưu tạm"
    },
    PENDING_APPROVAL:{
        VALUE: INVENTORY_PLAN_6_MONTH_STATUS.PENDING_APPROVAL,
        TEXT:"Chờ duyệt"
    },
    REJECT: {
        VALUE: INVENTORY_PLAN_6_MONTH_STATUS.REJECT,
        TEXT: "Từ chối"
    },
    APPROVAL_LEVEL_1:{
        VALUE: INVENTORY_PLAN_6_MONTH_STATUS.APPROVAL_LEVEL_1,
        TEXT:"Chờ duyệt"
    },
    IN_PROGRESS: {
        VALUE: INVENTORY_PLAN_6_MONTH_STATUS.IN_PROGRESS,
        TEXT: "Đang thực hiện"
    },
    COMPLETION_LEVEL_1: {
        VALUE: INVENTORY_PLAN_6_MONTH_STATUS.COMPLETION_LEVEL_1,
        TEXT: "Đang thực hiện"
    },
    SUCCESSED: {
        VALUE: INVENTORY_PLAN_6_MONTH_STATUS.SUCCESSED,
        TEXT: "Hoàn thành"
    },
}
export const INVENTORY_PLAN_1_MONTH_STATUS_DETAILS = {
    TEMPORATY_SAVE: {
        VALUE: INVENTORY_PLAN_1_MONTH_STATUS.TEMPORATY_SAVE,
        TEXT: "Lưu tạm"
    },
    IN_PROGRESS:{
        VALUE: INVENTORY_PLAN_1_MONTH_STATUS.IN_PROGRESS,
        TEXT:"Đang thực hiện"
    },
    SUCCESSED: {
        VALUE: INVENTORY_PLAN_1_MONTH_STATUS.SUCCESSED,
        TEXT: "Hoàn thành"
    },
}
export const OPTIONS_PLAN_STATUS_FILTER = [
    {value: INVENTORY_PLAN__STATUS_FILTER.TEMPORATY_SAVE, label: "Lưu tạm"},
    {value: INVENTORY_PLAN__STATUS_FILTER.PENDING_APPROVAL, label: "Chờ duyệt"},
    {value: INVENTORY_PLAN__STATUS_FILTER.REJECT, label: "Từ chối"},
    {value: INVENTORY_PLAN__STATUS_FILTER.IN_PROGRESS, label: "Đang thực hiện"},
    {value: INVENTORY_PLAN__STATUS_FILTER.SUCCESSED, label: "Hoàn thành"},
]

export const OPTIONS_PLAN_6_MONTH_STATUS = [
    {value: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.TEMPORATY_SAVE.VALUE, label: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.TEMPORATY_SAVE.TEXT},
    {value: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.PENDING_APPROVAL.VALUE, label: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.PENDING_APPROVAL.TEXT},
    {value: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.REJECT.VALUE, label: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.REJECT.TEXT},
    {value: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.APPROVAL_LEVEL_1.VALUE, label: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.APPROVAL_LEVEL_1.TEXT},
    {value: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.IN_PROGRESS.VALUE, label: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.IN_PROGRESS.TEXT},
    {value: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.COMPLETION_LEVEL_1.VALUE, label: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.COMPLETION_LEVEL_1.TEXT},
    {value: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.SUCCESSED.VALUE, label: INVENTORY_PLAN_6_MONTH_STATUS_DETAILS.SUCCESSED.TEXT},
]
export const OPTIONS_PLAN_1_MONTH_STATUS = [
    {value: INVENTORY_PLAN_1_MONTH_STATUS_DETAILS.TEMPORATY_SAVE.VALUE, label: INVENTORY_PLAN_1_MONTH_STATUS_DETAILS.TEMPORATY_SAVE.TEXT},
    {value: INVENTORY_PLAN_1_MONTH_STATUS_DETAILS.IN_PROGRESS.VALUE, label: INVENTORY_PLAN_1_MONTH_STATUS_DETAILS.IN_PROGRESS.TEXT},
    {value: INVENTORY_PLAN_1_MONTH_STATUS_DETAILS.SUCCESSED.VALUE, label: INVENTORY_PLAN_1_MONTH_STATUS_DETAILS.SUCCESSED.TEXT},
]

export const INVENTORY_PLAN_TYPE_VALUE = {
    ONE_MONTH:1,
    SIX_MONTH:6,
}

export const INVENTORY_PLAN_TYPE_LIST = [
    {
        label: "Kiểm kê 1 tháng",
        value: INVENTORY_PLAN_TYPE_VALUE.ONE_MONTH,
    },
    {
        label: "Kiểm kê 6 tháng",
        value: INVENTORY_PLAN_TYPE_VALUE.SIX_MONTH,
    }
]
export const TYPE_ACTION_ENUM = {
    DELETE_RECORD: 1
}

export const LIST_CONFIRM_ENUM = [
   
    // {
    //   key: typeActionEnum.CreateTicket,
    //   title: "Xác nhận phê duyệt",
    //   message: "Xác nhận phê duyệt tạo mới phiếu lĩnh và gửi sang kho chính bệnh viện?",
    //   urlIcon: "/assets/img/icon/icon-add-image.svg",
    // },
    // {
    //   key: typeActionEnum.Reject,
    //   title: "Xác nhận từ chối",
    //   message: "Bạn có chắc chắn muốn từ chối phê duyệt phiếu lĩnh này không?",
    //   urlIcon: "/assets/img/icon/approve.svg",
    // },
    {
      key: TYPE_ACTION_ENUM.DELETE_RECORD,
      title: "Xác nhận xóa",
      message: "Bạn có chắc chắn muốn óa kế hoạch kiểm kê không?",
      urlIcon: "/assets/img/icon/i-delete.svg"
    },
  ]

export const inventoryTicketFormTitle = {
    Update: "Chỉnh sửa phiếu kiểm kê",
    View: "Xem chi tiết phiếu kiểm kê",
}
//Trạng thái phiếu kiểm kê dùng để hiển thị
export const INVENTORY_TICKET_STATUS_UI = {
    AWAIT_INVENTORY: 1, //Chờ kiểm kê
    EXPIRED: 2, //Hết hạn
    INVENTORYING: 3, //Đang kiểm kê
    AWAIT_CONFRIM: 4, //Chờ phê duyệt
    SUCCESSED: 5 //Hoàn thành
}
//Trạng thái phiếu kiểm kê dùng để update 
export const INVENTORY_TICKET_STATUS = {
    AWAIT_INVENTORY: 1,
    EXPIRED: 2,
    INVENTORYING: 3,
    COMPLETE_INVENTORY: 4, //Kết thúc kiểm kê
    CONFRIM_LEVEL_1: 5,//Nhân viên kho chính xác nhận cấp 1 
    RE_INVENTORY: 6, // Kiểm kê lại
    CONFRIM_LEVEL_2: 7, //Kế toán viên xác nhận cấp 2
    REQUEST_RE_INVENTORY:8, //Kế toán yêu cầu kiểm kê lại
    SUCCESSED: 9 // Thủ kho xác nhận hoàn thành
}
export const INVENTORY_TICKET_STATUS_DETAILS = {
    NOT_INVENTORY: {
        VALUE: INVENTORY_TICKET_STATUS.AWAIT_INVENTORY,
        TEXT: "Chờ kiểm kê"
    },
    EXPIRED:{
        VALUE: INVENTORY_TICKET_STATUS.EXPIRED,
        TEXT: "Quá hạn"
    },
    INVENTORYING: {
        VALUE: INVENTORY_TICKET_STATUS.INVENTORYING,
        TEXT: "Đang kiểm kê"
    },
    COMPLETE_INVENTORY: {
        VALUE: INVENTORY_TICKET_STATUS.COMPLETE_INVENTORY,
        TEXT: "Chờ xác nhận"
    },
    CONFRIM_LEVEL_1: {
        VALUE: INVENTORY_TICKET_STATUS.CONFRIM_LEVEL_1,
        TEXT: "Chờ xác nhận"
    },
    CONFRIM_LEVEL_2: {
        VALUE: INVENTORY_TICKET_STATUS.CONFRIM_LEVEL_2,
        TEXT: "Chờ xác nhận"
    },
    SUCCESSED: {
        VALUE: INVENTORY_TICKET_STATUS.SUCCESSED,
        TEXT: "Hoàn thành"
    }
}

export const OPTIONS_INVENTORY_TICKET_6_MONTH_STATUS_DETAILS = [
    {
        value: INVENTORY_TICKET_STATUS_DETAILS.NOT_INVENTORY.VALUE,
        label: INVENTORY_TICKET_STATUS_DETAILS.NOT_INVENTORY.TEXT
    },
    {value: INVENTORY_TICKET_STATUS_DETAILS.INVENTORYING.VALUE, label: INVENTORY_TICKET_STATUS_DETAILS.INVENTORYING.TEXT},
    {value: INVENTORY_TICKET_STATUS_DETAILS.COMPLETE_INVENTORY.VALUE, label: INVENTORY_TICKET_STATUS_DETAILS.COMPLETE_INVENTORY.TEXT},
    {value: INVENTORY_TICKET_STATUS_DETAILS.CONFRIM_LEVEL_1.VALUE, label: INVENTORY_TICKET_STATUS_DETAILS.CONFRIM_LEVEL_1.TEXT},
    {value: INVENTORY_TICKET_STATUS_DETAILS.CONFRIM_LEVEL_2.VALUE, label: INVENTORY_TICKET_STATUS_DETAILS.CONFRIM_LEVEL_2.TEXT},
    {value: INVENTORY_TICKET_STATUS_DETAILS.SUCCESSED.VALUE, label: INVENTORY_TICKET_STATUS_DETAILS.SUCCESSED.TEXT},
]

export const INVENTORY_AUDIT_DETAIL_STATUS = 
{
    NOT_INVENTORY: 1, //Chưa kiểm kê
    SUFFICIENT: 2, //Đủ 
    INSUFFICIENT: 3, //Thiếu
    SURPLUS: 4 //Thừa
}

export const INVENTORY_AUDIT_DETAIL_STATUS_UI = 
[
    {
        value: INVENTORY_AUDIT_DETAIL_STATUS.NOT_INVENTORY,
        label: "Chưa kiểm kê",
        text: "Chưa kiểm kê",
    },
    {
        value: INVENTORY_AUDIT_DETAIL_STATUS.SUFFICIENT,
        label: "Đủ",
        text: "Đủ",
    },
    {
        value: INVENTORY_AUDIT_DETAIL_STATUS.INSUFFICIENT,
        label: "Thiếu",
        text: "Thiếu",
    },
    {
        value: INVENTORY_AUDIT_DETAIL_STATUS.SURPLUS,
        label: "Thừa",
        text: "Thừa",
    },
]

export const LIST_CAUSE_DISCREPANCY = [
    {
        value: 1,
        label: "Kiểm kê sai",
        text: "Kiểm kê sai",
    },
    {
        value: 2,
        label: "Đang hoàn trả",
        text: "Đang hoàn trả",
    },
    {
        value: 3,
        label: "Đang điều chuyển",
        text: "Đang điều chuyển",
    },
    {
        value: 4,
        label: "Chưa làm phiếu xuất kho",
        text: "Chưa làm phiếu xuất kho",
    },
    {
        value: 5,
        label: "Đổi nhân viên kiểm kê",
        text: "Đổi nhân viên kiểm kê",
    },
    
]