import {
    SET_DATA_MAINTAINCE_CALENDAR_REDUCER, CALL_DELETE_PERIOD_SUCCESS_REDUCER, SET_OBJ_DETAIL_PERIOD, CALL_CREATE_OR_UPDATE_PERIOD_SUCCESS_REDUCER, CHANGE_DATA_MAINTAIN_REDUCER,
    SET_DATA_MAINTAINCE_SCHEDULE_REDUECR, SET_LIST_DATA_PERIOD_SCHEDULE_REDUCER, CREATE_UPDATE_SCHEDULE_SUCCESS_REDUCER, SET_DETAIL_MAINTANCE_SCHEDULE_REDUCER,
} from "./enum/MaintainTypeAction";
const MaintainReducer = (
    state = {
        dataMaintainceCalendar: null,
        isCallDeleteSuccess: false,
        isCallGetDetailSuccess: false,
        isCallCreOrUpSuccess: false,
        createUpdateScheduleSuccess: false,
        objPeriod: {},
        listDataMaintanceSchedule: {},
        listDataPeriodSchedule: [],
        objMaintanSchedule: {},
        callDetail: false,
        changeData: false,
    }, action
) => {
    switch (action.type) {
        case SET_DATA_MAINTAINCE_CALENDAR_REDUCER:
            state.dataMaintainceCalendar = action.data;
            return {
                ...state,
            };
        case CALL_DELETE_PERIOD_SUCCESS_REDUCER:
            state.isCallDeleteSuccess = true;
            return {
                ...state,
            };
        case SET_OBJ_DETAIL_PERIOD:
            state.isCallGetDetailSuccess = true;
            state.objPeriod = action.obj;
            return {
                ...state,
            };
        case CALL_CREATE_OR_UPDATE_PERIOD_SUCCESS_REDUCER:
            state.isCallCreOrUpSuccess = true;
            return {
                ...state,
            };
        case SET_DATA_MAINTAINCE_SCHEDULE_REDUECR:
            state.listDataMaintanceSchedule = action.data;
            return {
                ...state,
            };
        case SET_LIST_DATA_PERIOD_SCHEDULE_REDUCER:
            state.listDataPeriodSchedule = action.data;
            return {
                ...state
            };
        case CREATE_UPDATE_SCHEDULE_SUCCESS_REDUCER:
            state.createUpdateScheduleSuccess = true;
            return {
                ...state
            };
        case SET_DETAIL_MAINTANCE_SCHEDULE_REDUCER:
            state.objMaintanSchedule = action.data;
            state.objMaintanSchedule.detail = action.detail;
            state.callDetail = true;
            return {
                ...state
            };
        case CHANGE_DATA_MAINTAIN_REDUCER:
            state.changeData = true;
            return {
                ...state
            };
        case 'default':
            state.callDetail = false;
            state.createUpdateScheduleSuccess = false;
            state.isCallDeleteSuccess = false;
            state.isCallGetDetailSuccess = false;
            state.isCallCreOrUpSuccess = false;
            state.changeData = false;
            state.listDataPeriodSchedule = [];
            return {
                ...state,
            };
        default:
            return {
                ...state,
            }
    }
}
export default MaintainReducer;