import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { GET_ALL_Unit_API, CREATE_Unit_TYPE_API, UPDATE_Unit_TYPE_API, DELETE_Unit_TYPE_API, GET_DETAIL_Unit_TYPE_API } from "./enum/hookDemoTypeApi";

export async function getPagingService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_ALL_Unit_API,
    data
  );
}

export async function createDataService(data) {

  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_Unit_TYPE_API,
    data
  );
}

export async function updateDataService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_Unit_TYPE_API,
    data
  );
}

export async function deleteDataService(unitMember) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_Unit_TYPE_API + unitMember,
    ""
  );
}
export async function getDetailService(unitMember) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_DETAIL_Unit_TYPE_API + unitMember,
    ""
  );
}
