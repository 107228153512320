
export const GET_LOT_DETAIL_SAGA ="GET_LOT_DETAIL_SAGA";// lay chi thong tin lot
export const GET_LOT_DETAIL_REDUCER ="GET_LOT_DETAIL_REDUCER";

export const GET_LIST_SAMPLE_DETAIL_SAGA ="GET_LIST_SAMPLE_DETAIL_SAGA"; // XEM DANH SACH BAI KIEM TRA KHI CHON NUT XEM CHI TIET
export const GET_LIST_SAMPLE_DETAIL_REDUCER = "GET_LIST_SAMPLE_DETAIL_REDUCER";

export const GET_LIST_SAMPLE_CHECK_SAGA ="GET_LIST_SAMPLE_CHECK_SAGA"; // lay ra danh sach kiem tra sau khi scan va chon dropdown
export const GET_LIST_SAMPLE_CHECK_REDUCER ="GET_LIST_SAMPLE_CHECK_REDUCER";

export const GET_LIST_SAMLE_SAGA ="GET_LIST_SAMLE_SAGA"; // danh sach trong bang danh gia item scan
export const GET_LIST_SAMPLE_REDUCER = "GET_LIST_SAMPLE_REDUCER";

export const SAVE_CHECK_LIST_SAMPLE ="SAVE_CHECK_LIST_SAMPLE"; // luu bai kiem tra

export const SAVE_VALUATION ="SAVE_VALUATION"; // luu danh gia

export const CALL_RESULT_RECORD_FROM_API ="CALL_RESULT_RECORD_FROM_API";
export const CALL_DEFAULT_RECORD_FROM_API = "CALL_DEFAULT_RECORD_FROM_API";

export const DROPDOWN_BY_CODE_SAGA ="DROPDOWN_BY_CODE_SAGA";
export const DROPDOWN_BY_CODE_REDUCER ="DROPDOWN_BY_CODE_REDUCER";
export const CALL_CHANGE_SUCCESS="CALL_CHANGE_SUCCESS";



