export const API_GET_REPORT_TRANSACTION = "/api/service/Report/GetReportTransaction";
export const API_EXPORT_REPORT_TRANSACTION = "/api/service/Report/ExportReportTransaction";

export const API_GET_REPORT_RECONCILE = "/api/service/Report/GetReportReconcile";
export const API_EXPORT_REPORT_RECONCILE = "/api/service/Report/ExportReportReconcile";

export const API_GET_REPORT_INVENTORY = "/api/service/Report/GetReportInventory";
export const API_EXPORT_REPORT_INVENTORY = "/api/service/Report/ExportReportInventory";

export const API_GET_SUM_REPORT = "/api/service/Report/GetSumReport";
