// import {
//     CALL_DEFAULT_REDUCER,
//     CLEAR_DATA_REDUCER
// } from "../InventoryManagement/Inventory/enum/TypeInventoryAction";
import {
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    GET_LOG_JOB_DATA_PAGINATION_CLEAR,
    GET_LOG_JOB_DATA_PAGINATION_REDUCER
} from "./enum/LogJobTypeAction";

const stateDefault = {
    dataJobLogAudit: [],
    isLoadingDataPagination: false,
    isGetData: false
};

const LogJobReducer = (state = stateDefault, action) => {
    switch (action.type) {
        case GET_LOG_JOB_DATA_PAGINATION_REDUCER:
            state.dataJobLogAudit = action.response;
            state.isGetData = true;
            return {...state};

        case GET_LOG_JOB_DATA_PAGINATION_CLEAR:
            state.isLoading = false
            return {...state};

        case CALL_DEFAULT_REDUCER:
            state.isLoading = false
            return {
                ...state,
            };

        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };

        default:
            return {...state};
    }
}

export default LogJobReducer