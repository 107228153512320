export const GET_PAGING_PACKING_LEVEL_SAGA = "GET_PAGING_PACKING_LEVEL_SAGA";
export const SET_PAGING_PACKING_LEVEL_REDUCER = "SET_PAGING_PACKING_LEVEL_REDUCER";
export const CREATE_PACKING_LEVEL_SAGA = "CREATE_PACKING_LEVEL_SAGA";
export const UPDATE_PACKING_LEVEL_SAGA = "UPDATE_PACKING_LEVEL_SAGA";
export const DELETE_PACKING_LEVEL_SAGA = "DELETE_PACKING_LEVEL_SAGA";
export const CHANGE_LEVEL_PACKING_LEVEL = "CHANGE_LEVEL_PACKING_LEVEL";
export const GET_PACKING_LEVEL_BY_ID = "GET_PACKING_LEVEL_BY_ID";
export const SET_PACKING_LEVEL_BY_ID_REDUCER = "SET_PACKING_LEVEL_BY_ID_REDUCER";
export const CALL_RESULT_PACKING_LEVEL_REDUCER = "CALL_RESULT_PACKING_LEVEL_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
export const CALL_DEFAULT_PACKING_LEVEL_REDUCER = "CALL_DEFAULT_PACKING_LEVEL_REDUCER";