export const CREATE_USER_HOSPITAL_WAREHOUSE_SAGA =
  "CREATE_USER_HOSPITAL_WAREHOUSE_SAGA";
export const CREATE_USER_HOSPITAL_WAREHOUSE_REDUCER =
  "CREATE_USER_HOSPITAL_WAREHOUSE_REDUCER";

export const UPDATE_USER_HOSPITAL_WAREHOUSE_SAGA =
  "UPDATE_USER_HOSPITAL_WAREHOUSE_SAGA";
export const UPDATE_USER_HOSPITAL_WAREHOUSE_REDUCER =
  "UPDATE_USER_HOSPITAL_WAREHOUSE_REDUCER";

export const DELETE_USER_HOSPITAL_WAREHOUSE_SAGA =
  "DELETE_USER_HOSPITAL_WAREHOUSE_SAGA";
export const DELETE_USER_HOSPITAL_WAREHOUSE_REDUCER =
  "DELETE_USER_HOSPITAL_WAREHOUSE_REDUCER";
  
export const GET_USER_HOSPITAL_WAREHOUSE_BY_USER_SAGA =
  "GET_USER_HOSPITAL_WAREHOUSE_BY_USER_SAGA";
export const GET_USER_HOSPITAL_WAREHOUSE_BY_USER_REDUCER =
  "GET_USER_HOSPITAL_WAREHOUSE_BY_USER_REDUCER";

export const GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_SAGA =
  "GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_SAGA";
export const GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_REDUCER =
  "GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_REDUCER";

export const GET_USER_LIST_SAGA = "GET_USER_LIST_SAGA";
export const GET_USER_LIST_BY_RULE_SAGA = "GET_USER_LIST_BY_RULE_SAGA";

export const GET_USER_LIST_REDUCER = "GET_USER_LIST_REDUCER";
export const GET_USER_LIST_BY_RULE_REDUCER = "GET_USER_LIST_BY_RULE_REDUCER";

export const GET_HOSPITAL_WAREHOUSE_LIST_SAGA = "GET_HOSPITAL_WAREHOUSE_LIST_SAGA";
export const GET_HOSPITAL_WAREHOUSE_LIST_REDUCER = "GET_HOSPITAL_WAREHOUSE_LIST_REDUCER";

export const SET_LIST_USER_REDUCER = "SET_LIST_USER_REDUCER";

export const CALL_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER =
  "CALL_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER";
export const CLEAR_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER = "CLEAR_DATA_REDUCER";
