export const movementType = {
    language: "vn",
    movementType: {
        table: {
            accountingEffect: "Tác động kế toán",
            description: "Mô tả",
            movementtypeMember: "Kiểu vận chuyển",
        },
        search: {
            placeholder: "Kiểu vận chuyển, mô tả",
        },
        form: {
            movementexist: "Mã kiểu vận chuyển đã tồn tại",
            title: "Thêm mới kiểu vận chuyển",
            movement: "Kiểu vận chuyển",
            accounting: "Tác động kế toán",
            description: "Mô tả",
            viewTittle: "Xem thông tin kiểu vận chuyển",
            button: "Đóng"
        },
        form_update: {
            title: "Cập nhật thông tin kiểu vận chuyển",
            movement: "Kiểu vận chuyển",
            accounting: "Tác động kế toán",
            description: "Mô tả",
            updateButton: "Lưu",
            saveButton: "Lưu",
            cancelButton: "Hủy"
        },
        form_delete: {
            title: "Xóa thông tin kiểu vận chuyển",
            label: "Bạn có chắc chắn muốn xóa kiểu vận chuyển này ?",
            confirmButton: "Xóa",
            cancelButton: "Hủy"
        },
        error: {
            mmtype: "Sai định dạng số tự nhiên",
            mmtypeRequired: "Kiểu vận chuyển không được để trống",
            des: "Mô tả không được vượt quá 255 ký tự",
            acc: "Tác động kế toán không được vượt quá 255 ký tự",
            mmtypeerror: "Kiểu vận chuyển phải lớn hơn 0",
            accerror: "Tác động kế toán không được bỏ trống",
            mmtypeerrorMaxValue: "giá trị không được vượt quá 2147483647",
        },
        toastJs: {
            createOK: "Thêm mới dữ liệu kiểu vận chuyển thành công",
            updateOK: "Cập nhật dữ liệu kiểu vận chuyển thành công",
            deleteOK: "Xóa dữ liệu kiểu vận chuyển thành công",
        }
    },
};