import {
  GET_PAGING_WAREHOUSE_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_WAREHOUSE_REDUCER,
  CALL_CREATE_UPDATE_SUCCESS_REDUCER,
  GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_REDUCER,
  GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_REDUCER,
  GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_REDUCER,
} from "./enum/TypeCatWarehouseAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,

  isDeleteSuccess: false,

  isCreateUpdateSuccess: false,

  listDataWarehouse: [], //Danh sách kho theo 1 bệnh viện
  listWarehouseByHospitalId: [],
  isGetListWarehouseByHospitalId: false,

  listDataWarehouseByListId:[], //Danh sách kho theo nhiều bệnh viện
  isGetLstDataByListId:false, 

  isGetMainWarehouseByHospitalId: false,
  mainWarehouse: {}
};

const CatWarehouseReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_WAREHOUSE_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_REDUCER:
      state.listDataWarehouse = action.data?.data;
      state.listWarehouseByHospitalId = action.data?.data;
      state.isGetListWarehouseByHospitalId = true;
      return { ...state };
    case GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_REDUCER:
      state.mainWarehouse = action.data?.data;
      state.isGetMainWarehouseByHospitalId = true;
      return { ...state };
    case GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_REDUCER:
      state.listDataWarehouseByListId = action.data?.data;
      state.isGetLstDataByListId = true;
      return { ...state };
    case DELETE_WAREHOUSE_REDUCER:
      state.isDeleteSuccess = true;
      return { ...state };

    case CALL_CREATE_UPDATE_SUCCESS_REDUCER:
      state.isCreateUpdateSuccess = true;
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.listDataWarehouse = [];
      state.listDataWarehouseByListId = [];
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      state.isGetLstDataByListId = false;
      state.isGetMainWarehouseByHospitalId= false;
      return {...state};
    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };

    default:
      return { ...state };
  }
};

export default CatWarehouseReducer;
