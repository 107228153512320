import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
GET_ALL_PACKING_LEVEL_API,CREATE_PACKING_LEVEL_API,UPDATE_PACKING_LEVEL_API,GET_PACKING_LEVEL_BY_ID_API,DELETE_PACKING_LEVEL_API,CHANGE_LEVEL_API
} from "./enum/PackingLevelTypeApi";

export async function getPagingService() {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_ALL_PACKING_LEVEL_API);
}

export async function createDataService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + CREATE_PACKING_LEVEL_API, data);
}

export async function updateDataService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + UPDATE_PACKING_LEVEL_API, data);
}

export async function deletePackingService(id) {
    return await CommonBase.postAsync(configApp.apiGateWay + DELETE_PACKING_LEVEL_API + "/" + id)
}

export async function changeLevelService(id, level) {
    return await CommonBase.postAsync(configApp.apiGateWay + CHANGE_LEVEL_API + "/" + id + "/" + level)
}
export async function getPackingLevelById(id) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_PACKING_LEVEL_BY_ID_API + "/" + id, '')
}