export const PeriodLag = {
    language: "vn",
    periodlag: {
        placeholdersearchperiod: "Mã chu kỳ, tên chu kỳ, mã máy, tên máy",
        cycledeclaration: "Khai báo chu kỳ",
        cycledetail: "Chi tiết chu kỳ",
        addnvl: "Thêm linh kiện",
        table: {
            cyclecode: "Mã chu kỳ",
            cyclename: "Tên chu kỳ",
            machinecode: "Mã máy",
            machinename: "Tên máy",
            maintenancetype: "Loại bảo dưỡng",
            cycletime: "Thời gian chu kỳ",
            cycleunit: "Đơn vị chu kỳ",
            nearestmaintenance: "Bảo dưỡng gần nhất",
            nextmaintenance: "Bảo dưỡng tiếp theo",
        },
        form: {
            confirmdelete: "Xác nhận xoá chu kỳ bảo dưỡng",
            confirmdeletetrue: "Bạn có chắc chắn muốn xóa chu kỳ bảo dưỡng này?",
            cycletime0: "Thời gian chu kỳ phải lớn hơn 0",
            timemaintance0: "Thời gian thực hiện bảo dưỡng phải lớn hơn 0",
            qtymaterial0: "Số lượng linh kiện phải lớn hơn 0",
            entercyclecode: "Nhập mã chu kỳ",
            entercyclename: "Nhập tên chu kỳ",
            selectmachine: "Chọn máy móc",
            selectmaintenancetype: "Chọn loại bảo dưỡng",
            entercycle: "Nhập chu kỳ",
            selectunit: "Chọn đơn vị",
            maintenancepurpose: "Mục đích bảo dưỡng",
            entermaintenancepurpose: "Nhập mục đích bảo dưỡng",
            timemaintance: "Thời gian thực hiện bảo dưỡng dự kiến (giờ)",
            enterhours: "Nhập số giờ",
            consumablesupplies: "Vật tư tiêu hao",
            accessory: "Linh kiện",
            amount: "Số lượng",
            selectaccessory: "Chọn linh kiện",
            enterquantity: "Nhập số lượng",
            nodata: "Không có dữ liệu",
        },
        rules: {
            quantityinterger: "Số lượng phải là số nguyên dương",
            quantityrequired: "Số lượng không được bỏ trống",
            accessoryrequired: "Linh kiện không được bỏ trống",
            coderequired: "Mã chu kỳ không được bỏ trống",
            namerequired: "Tên chu kỳ không được bỏ trống",
            machinerequired: "Máy móc không được bỏ trống",
            maintancetyperequired: "Loại bảo dưỡng không được bỏ trống",
            periodinterger: "Chu kỳ phải là số nguyên dương",
            cyclerequired: "Chu kỳ không được bỏ trống",
            unitrequired: "Đơn vị thời gian không được bỏ trống",
            mantaincetimeinterger: "Thời gian thực hiện bảo dưỡng là số nguyên dương",
            maitancetimerequired: "Thời gian thực hiện bảo dưỡng không được bỏ trống",
            maitancepurposerequired: "Mục đích bảo dưỡng không được bỏ trống",
        },
        message: {
            deletesuccess: "Chu kỳ bảo dưỡng đã được xóa",
            createSuccess: "Thêm mới chu kỳ bảo dưỡng thành công",
            updatemaintance: "Cập nhật chu kỳ bảo dưỡng thành công",
            blankreasonrequired: "Không được bỏ trống lý do bỏ qua",
            reasonmax: "Lý do bỏ qua không thể vượt quá 500 ký tự",
            receiverrequired: "Không được bỏ trống người thực hiện",
            dateactionrequired: "Không được bỏ trống ngày thực hiện",
            excuiterequired: "Không được bỏ trống thời gian thực hiện",
            pricerequired: "Không được bỏ trống chi phí bảo dưỡng",
            exqtyrequired: "Số lượng dự kiến không được bỏ trống",
            realqty: "Số lượng thực tế không được bỏ trống",
            namemaxlength: "Tên chu kì không thể vượt quá 150 kí tự",
            mucdich: "Mục đích không thể vượt quá 500 kí tự",
        }
    }
};