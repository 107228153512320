
import { type } from "@testing-library/user-event/dist/type";
import {
    GET_REQUEST_INCREASE_ASSET_REDUCER,
    GET_REQUEST_INCREASE_ASSET_SAGA,
    CLEAR_REQUEST_INCREASE_ASSET_REDUCER,
    CALL_DEFAULT_REQUEST_INCREASE_ASSET_REDUCER,
    // GET_DETAIL_INCREASE_ASSET_SAGA,
    // GET_DETAIL_INCREASE_ASSET_REDUCER
} from "./enum/TypeRequestIncreaseAssetAction.js";

const RequestIncreaseAssetAction = {
    getRequestAssetIncreaseSaga: (pageSize, pageIndex, objectFilter) => {
        return {
            type: GET_REQUEST_INCREASE_ASSET_SAGA,
            PageSize: pageSize,
            PageIndex: pageIndex,
            objectFilter: objectFilter
        }
    },
    getRequestAssetIncreaseReducer: (data) => {
        return {
            type: GET_REQUEST_INCREASE_ASSET_REDUCER,
            data
        }
    },
    // getDetailRequestAssetIncreaseSaga: (Id) => {
    //     return {
    //         type: GET_DETAIL_INCREASE_ASSET_SAGA,
    //         Id: Id
    //     };
    // },
    // getDetailRequestAssetIncreaseReducer: (obj) => {
    //     return {
    //         type: GET_DETAIL_INCREASE_ASSET_REDUCER,
    //         obj: obj
    //     };
    // },

    callDefault: () => {
        return {
            type: CALL_DEFAULT_REQUEST_INCREASE_ASSET_REDUCER,
        }
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_REQUEST_INCREASE_ASSET_REDUCER,
        };
    },



}

export default RequestIncreaseAssetAction;
