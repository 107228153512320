export const machineryRepairLag = {
    language: "vi",
    machineryRepair: {
        table: {
            machineCode: "Mã máy",
            declarationDate: "Ngày khai báo sửa chữa",
            acceptanceDay: "Ngày nghiệm thu",
            declarantWorkerid: "Người khai báo",
            acceptanceWorkerid: "Người nghiệm thu",
            costs: "Chi phí (VND)",
            action: "Thao tác",
            noData: "Không có dữ liệu để hiển thị",
        },
        placeholder: {
            searchTerm: "Tìm kiếm",
            status: "Trạng thái",
            typeError: "Phân loại",
            timeSearch: "Thời gian ghi nhận lỗi",
        },
        btn: {
            create: "Thêm mới",
            save: "Lưu",
        },
        deleteForm: {
            title: "Xoá sửa chữa máy móc",
            confirm: "Bạn có chắc chắn muốn xoá sửa chữa máy móc này?",
        },
        form: {
            createTitle: "Thêm mới thông tin sửa chữa",
            editTitle: "Thay đổi thông tin sửa chữa",
            seeDetailTitle: "Chi tiết thông tin sửa chữa",

            machineCode: "Mã máy",
            machineName: "Tên máy",
            workerId: "Người khai báo",
            declarationDate: "Ngày khai báo",
            repairReson: "Lý do sửa chữa",
            placeholder: {
                machineCode: "Chọn mã máy",
                machineName: "Tên máy",
                workerId: "Chọn người khai báo",
                declarationDate: "Thời gian",
                repairReson: "Nhập lý do sửa chữa",
            }
        },
        acceptanceForm: {
            title: "Nghiệm thu sửa chữa",
            machineCode: "Mã máy:",
            machineName: "Tên máy:",
            workerId: "Người khai báo:",
            declarationDate: "Ngày khai báo:",
            repairReson: "Lý do sửa chữa:",
            infoTitle: "Thông tin nghiệm thu",
            acceptanceWorkerid: "Người nghiệm thu",
            acceptanceDay: "Ngày nghiệm thu",
            acceptanceVerification: "Xác nhận",
            dropdownStatusRepair: "Trạng thái máy sau sửa chữa",
            cost: "Chi phí (VND)",
            placeholder: {
                acceptanceWorkerid: "Chọn người nghiệm thu",
                acceptanceDay: "Thời gian",
                acceptanceVerification: "Nhập xác nhận nghiệm thu",
                dropdownStatusRepair: "Chọn trạng thái máy sau sửa chữa",
            }
        },
        toast: {
            createSuccess: "Tạo mới sửa chữa máy móc thành công",
            warnningCreate: "Đã huỷ liên kết máy móc với đơn vị sản xuất",
            updateSuccess: "Cập nhật sửa chữa máy móc thành công",
            acceptanceSuccess: "Nghiệm thu sửa chữa máy móc thành công",
            deleteSuccess: "Xóa sửa chữa máy móc thành công",
        },
        rule: {
            MachineId: {
                required: "Mã máy không được bỏ trống",
            },
            WorkerId: {
                required: "Người khai báo không được bỏ trống",
            },
            DeclarationDate: {
                required: "Ngày khai báo không được bỏ trống",
            },
            RepairReason: {
                maxLength: "Lý do sửa chữa không được quá 255 kí tự"
            },
            acceptanceVerification: {
                maxLength: "Xác nhận không được quá 255 kí tự"
            },
            acceptanceWorkerid: {
                required: "Người nghiệm thu không được bỏ trống",
            },
            acceptanceDay: {
                required: "Ngày nghiệm thu không được bỏ trống",
            },
            machineStateAfterRepair: {
                required: "Trạng thái máy sau sửa chữa không được bỏ trống",
            },
            costs: {
                numberOnly: "Chi phí phải là số",
                numberBiggerZero: "Chi phí phải là số",
                required: "Chi phí không được bỏ trống",
                maxLength: "Chi phí không được quá 10 kí tự"
            },
        }
    },
}
