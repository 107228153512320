import {
    GET_LIST_REPORT,
    SET_BILL_INFO,
    GET_BILL_INFO,
    GET_ASSET_INFO,
    GET_REPORT_INFO,
    SET_ASSET_INFO,
    SET_REPORT_INFO,
    CLEAR_DATA_REDUCER,
    SET_LIST_REPORT,
    GET_RESULT_REPORT,
    SET_RESULT_REPORT,
    GET_SUMMARY_REPORT,
    SET_SUMMARY_REPORT,
    EXPORT_DATA_INVENTORY_REPORT_BILL,
    EXPORT_DATA_INVENTORY_REPORT_INFO,
    EXPORT_DATA_INVENTORY_REPORT_ASSETMENT

} from "./enum/ReportActionEnum";

const AssetmentStocktakingReportAction = {
    //lay danh sach bao cao
    getListReport: (pageIndex, pageSize, searchTerm) => {
        return {
            type: GET_LIST_REPORT,
            PageIndex: pageIndex,
            PageSize: pageSize,
            SearchTerm: searchTerm
        }
    },
    setReport: (data) => {
        return {
            data: data,
            type: SET_LIST_REPORT
        }
    },
    getBillInfo: (inventoryPlanId) => {
        // lay data tab thong tin phieu
        return {
            type: GET_BILL_INFO,
            inventoryPlanId
        }
    },
    setBillInfo: (data) => {
        return {
            type: SET_BILL_INFO,
            data
        }
    },
    getReportInfo: (inventoryPlanId) => {
        //lay data tab thong tin bao cao
        return {
            type: GET_REPORT_INFO,
            inventoryPlanId
        }
    },
    setReportInfo: (data) => {
        return {
            type: SET_REPORT_INFO,
            data
        }
    },
    getAssetInfo: (data) => {
        //lay data tab thong tin tai san
        return {
            type: GET_ASSET_INFO,
            data: data
        }
    },
    setAssetInfo: (data) => {
        return {
            type: SET_ASSET_INFO,
            data
        }
    },
    getResultReport: (data) => {

        return {
            type: GET_RESULT_REPORT,
            data: data
        }
    },
    setResultReport: (data) => {

        return {
            type: SET_RESULT_REPORT,
            data: data
        }
    },
    getSummaryReport: (data) => {
        return {
            type: GET_SUMMARY_REPORT,
            data: data
        }
    },
    setSummaryReport: (data) => {

        return {
            type: SET_SUMMARY_REPORT,
            data: data
        }
    },
    exportDataInventoryReportBill: (inventoryPlanId, inventoryCode) => {
        return {
            type: EXPORT_DATA_INVENTORY_REPORT_BILL,
            inventoryPlanId: inventoryPlanId,
            inventoryCode: inventoryCode
        }
    },
    exportDataInventoryReportInfo: (inventoryPlanId, inventoryCode) => {
        return {
            type: EXPORT_DATA_INVENTORY_REPORT_INFO,
            inventoryPlanId: inventoryPlanId,
            inventoryCode: inventoryCode
        }
    },
    exportDataInventoryReportAssetment: (data, inventoryCode) => {
        return {
            type: EXPORT_DATA_INVENTORY_REPORT_ASSETMENT,
            data: data,
            inventoryCode: inventoryCode
        }
    },

    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER,
        };
    },
};

export default AssetmentStocktakingReportAction;