import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import * as type from "./enum/apiType";
export async function uploadfileService(files) {
    let filesService = {
        files,
        isSuccess: true,
    };
    await Promise.all(filesService.files.map(async file => {
        let formData = new FormData();
        formData.append("files", file);
        formData.append("typeFile", 1);
        formData.append("folder", "files");
        let response = await CommonBase.postAsync(
            configApp.apiGateWay + type.UPLOAD_FILE_API,
            formData
        )
        if (response.check) {
            file.pathname = response.content;
            file.isUploaded = true;
        }
    }))
    return filesService
};