export const cancelStampLag = {
    language: "vi",
    cancelStamp: {
        packTitle: "Huỷ tem",
        table: {
            tableName:"Danh sách đóng gói",
            code:"Mã định danh",
            unit: "Đơn vị",          
        },
        stampsInfo: {
            name: "Thông tin đóng gói",
            code: "Mã thành phẩm:",
            element: "Thành phần:",
            packingLevel:"Cấp độ đóng gói:"
        },
        btn: {
            search: "Truy vấn",
            cancelStamp:"Huỷ tem",
        },
        placeHolder: {
            search:"Nhập / scan mã định danh",
        },
    },
}