export const GET_LIST_INVENTORY_TICKETS_API = "/api/service/INVENTORY_TICKET/Search";
export const DELETE_INVENTORY_TICKET_API = "/api/service/INVENTORY_TICKET/Delete";
export const CREATE_INVENTORY_TICKET_API = "/api/service/INVENTORY_TICKET/Create";
export const UPDATE_INVENTORY_TICKET_API = "/api/service/INVENTORY_TICKET/Update";
export const GET_DETAIL_INVENTORY_TICKET_API = "/api/service/INVENTORY_TICKET/GetById";
export const ISEXIST_INVENTORY_TICKET_API =
    "/api/service/INVENTORY_TICKET/IsExist";
    
export const UPDATE_INVENTORY_AUDIT_API = "/api/service/InventoryAudit/Update";
export const UPDATE_LIST_INVENTORY_AUDIT_DETAIL_API = "/api/service/InventoryAuditDetail/UpdateList";
export const GET_LIST_INVENTORY_AUDIT_DETAIL_BY_PLAN_API = "/api/service/InventoryAuditDetail/GetListByAuditPlan";
