import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import POCoordinateAction from "./POCoordinateAction";
import { actionService, checkActionService, getDetailWoService, updateWoService, getPagingService } from "./POCoordinateService";
import * as type from "./enum/enumAction";
import toastJs from 'src/shared/common/toastJs';

import { openLoading } from "src/shared/common/LoadingAction";
import moment from "moment";
const statusTable = {
    run: 1,
    pause: 2,
    complete: 3,
    cancel: 4,
};
export function* getWoDataPaging(request) {
    try {
        openLoading();
        const entityRequest = {
            SearchTerm: request.searchKey,
            PageSize: request.size,
            PageIndex: request.index,
            Statustype: request.status,
            FindTime: request.date
        };
        const response = yield call(getPagingService, entityRequest);
        if (response.isSuccess) {
            yield put(POCoordinateAction.setPagingData(response.data));
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};
export function* actionSaga(request) {
    try {
        const response = yield call(actionService, request)
        if (response.isSuccess) {
            const id = request.id;
            const action = request.Action;
            switch (action) {
                case statusTable.run:
                    toastJs.success("Chạy lệnh sản xuất " + id + " Thành công");
                    break;
                case statusTable.pause:
                    toastJs.success("Tạm dừng lệnh sản xuất " + id + " Thành công");
                    break;
                case statusTable.complete:
                    toastJs.success("Hoàn thành lệnh sản xuất " + id + " Thành công");
                    break;
                case statusTable.cancel:
                    toastJs.success("Hủy bỏ lệnh sản xuất " + id + " Thành công");
                    break;
                default:
                    break;
            }
            yield put(POCoordinateAction.callActionReducer())
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
}
export function* checkActionSaga(request) {
    try {
        const response = yield call(checkActionService, request)
        if (response.isSuccess) {
            yield put(POCoordinateAction.checkActionReducer(response.data, request.Action, request.Workordercode))
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
}
export function* updateWoSaga(request) {
    try {
        const entity = {
            "Workordercode": request.entity.Workordercode,
            "Workcenterid": request.entity.Workcenterid,
            "Esproductionquantity": parseInt(request.entity.Esproductionquantity),
            "Esproductiontimeto": request.entity.Esproductiontimeto,
            "Esproductiontimefrom": request.entity.Esproductiontimefrom,
            "realityproductionqty": parseInt(request.entity.Realityproductionqty),
            "Statustype": parseInt(request.entity.Statustype)
        }
        const response = yield call(updateWoService, entity)
        if (response.isSuccess) {
            yield put(POCoordinateAction.updateWoReducer())
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
}
export function* getDetailWo(request) {
    try {
        const response = yield call(getDetailWoService, request.entity)
        if (response.isSuccess) {
            if (request.typeRemained == 4) {
                response.data.Realityproductiontimeto = new Date();
                response.data.Statustype = 3;
            }
            yield put(POCoordinateAction.getDetailWoReducer(response.data, request.typeRemained))
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
}

export function* updateByDragItem(request) {
    try {
        const entityGetDetail = {
            WorkorderCode: request.woCode,
        }
        const response = yield call(getDetailWoService, entityGetDetail)

        if (response.isSuccess) {
            let woDetail = response.data;
            woDetail.Esproductiontimefrom = request.start;
            woDetail.Esproductiontimeto = request.end;
            woDetail.Workcenterid = request.workCenter != "" ? request.workCenter : woDetail.Workcenterid;
            if (woDetail.Statustype == 0 || woDetail.Statustype == 3) {
                const request = {
                    "Workordercode": woDetail.Workordercode,
                    "Workcenterid": woDetail.Workcenterid,
                    "Esproductionquantity": woDetail.Esproductionquantity,
                    "Esproductiontimeto": woDetail.Esproductiontimeto,
                    "Esproductiontimefrom": woDetail.Esproductiontimefrom,
                    "realityproductionqty": woDetail.Realityproductionqty,
                    "Statustype": woDetail.Statustype
                }
                const woUpdateStatus = yield call(updateWoService, request);
                if (woUpdateStatus.isSuccess) {
                    yield put(POCoordinateAction.updateByDragItem(2))
                }
            }
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
}
export default function* POCoordinateSaga() {
    yield takeEvery(type.CALL_ACTION_SAGA, actionSaga);
    yield takeEvery(type.CHECK_ACTION_SAGA, checkActionSaga);
    yield takeEvery(type.UPDATE_WORK_ORDER_SAGA, updateWoSaga);
    yield takeEvery(type.GET_WORK_ORDER_DETAIL_SAGA, getDetailWo);
    yield takeEvery(type.GET_DATA_PAGING_SAGA, getWoDataPaging);
    yield takeEvery(type.UPDATE_BY_DRAG_ITEM_SAGA, updateByDragItem);
}