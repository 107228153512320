import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import RepairAction from "./RepairAction";
import { getDataPaging, getChangeStatusRepairService, getDataRepairDetailService, getDataByItemCodeService, getDropdownStepRepairService, createErrorRecodingService, createRepairActionService } from "./RepairService";
import * as type from "./enum/RepairTypeAction";

export function* getPagingSaga(request) {
    try {
        const response = yield call(getDataPaging, request?.ListSearchTerm)
        if (response.isSuccess) {
            yield put(RepairAction.setPagingReducer(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading()

    }
}
export function* getChangeStatusRepairSaga(request) {
    try {
        const result = yield call(getChangeStatusRepairService, request.data.id)
        if (result.isSuccess) {
            yield put(RepairAction.getDataPagingSaga(request.data.dataPage))
            closeLoading();
        }
    } catch (error) {
        closeLoading()

    }
}
export function* getDataRepairDetailSaga(request) {
    try {
        const response = yield call(getDataRepairDetailService, request.value, request.action)
        if (response.isSuccess) {
            yield put(RepairAction.setDataRepairDetailReducer(response.data))
            yield put(RepairAction.getDataPagingSaga(request.dataPage))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDataByItemCodeSaga(request) {
    try {
        const response = yield call(getDataByItemCodeService, request.id)
        if (response.isSuccess) {
            yield put(RepairAction.setDataByItemCodeReducer(response.data))
        }
        closeLoading();
    } catch (error) {
        yield put(RepairAction.CallFalseDataByItemCodeReducer())
        closeLoading();

    }
}
export function* getDropdownStepRepairSaga(request) {
    try {
        const response = yield call(getDropdownStepRepairService, request.data)
        if (response.isSuccess) {
            yield put(RepairAction.setDropdownStepRepairReducer(response.data))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* createErrorRecodingSaga(request) {
    try {
        const result = yield call(createErrorRecodingService, request.data);
        if (result.isSuccess) {
            yield put(RepairAction.getDataPagingSaga(request.dataPage))
            yield put(RepairAction.callResultFromReducer())
        }
    } catch (error) {
        closeLoading();

    }
}
export function* createRepairActionSaga(request) {
    try {
        const result = yield call(createRepairActionService, request.data);
        if (result.isSuccess) {
            yield put(RepairAction.getDataPagingSaga(request.dataPage))
            yield put(RepairAction.callResultActionFromReducer())
        }
    } catch (error) {
        closeLoading();

    }
}
export default function* RepairSaga() {
    yield takeEvery(type.GET_DATA_REPAIR_PAGING_SAGA, getPagingSaga);
    yield takeEvery(type.GET_CHANGE_STATUS_REPAIR_SAGA, getChangeStatusRepairSaga);
    yield takeEvery(type.GET_DATA_REPAIR_DETAIL_SAGA, getDataRepairDetailSaga);
    yield takeEvery(type.GET_DATA_BY_ITEM_CODE_SAGA, getDataByItemCodeSaga);
    yield takeEvery(type.GET_DROPDOWN_STEP_REPAIR_SAGA, getDropdownStepRepairSaga);
    yield takeEvery(type.CREATE_ERROR_RECORDING_SAGA, createErrorRecodingSaga);
    yield takeEvery(type.CREATE_REPAIR_ACTION_SAGA, createRepairActionSaga);
}