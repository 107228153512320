export const movementType = {
    language: "en",
    movementType: {
        table: {
            accountingEffect: "Accounting Effect",
            description: "Description",
            movementtypeMember: "Movement Type",
        },
        search: {
            placeholder: "Search movement type",
        },
        form: {
            movementexist: "Movement type code is exists",
            title: "Add new movement type",
            movement: "Movement Type",
            accounting: "Accounting Effect",
            description: "Description",
            viewTittle: "Movement type information",
            button: "Close"
        },
        form_update: {
            title: "Update movement type",
            movement: "Movement Type",
            accounting: "Accounting Effect",
            description: "Description",
            updateButton: "Save",
            saveButton: "Save",
            cancelButton: "Cancel"
        },
        form_delete: {
            title: "Confirm to delete this movement type",
            label: "Are you sure want to delete this ?",
            confirmButton: "Confirm",
            cancelButton: "Cancel"
        },
        error: {
            mmtype: "Wrong format of natural numbers",
            mmtypeRequired: "Transport type cannot be empty",
            des: "Description cannot exceed 255 characters",
            acc: "Accounting effect should not exceed 255 characters",
            mmtypeerror: "Accounting effect should not exceed 255 characters",
            accerror: "Accounting effect cannot be left blank",
            mmtypeerrorMaxValue: "value should not exceed 2147483647",
        },
        toastJs: {
            createOK: "New shipping type data added successfully",
            updateOK: "Update data type converted successfully",
            deleteOK: "Delete shipping type data successfully",
        }
    },
};