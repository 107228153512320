import {
    SET_BILL_INFO,
    SET_ASSET_INFO,
    CLEAR_DATA_REDUCER,
    SET_REPORT_INFO,
    SET_LIST_REPORT,
    SET_RESULT_REPORT,
    SET_SUMMARY_REPORT
} from "./enum/ReportActionEnum";
import { isEmpty } from "lodash";

const stateDefault = {
    // danh sach bao cao 
    listReport: [],
    isGetListOK: false,
    //thong tin phieu
    billInfoData: {},
    isGetBillInfoData: false,
    // thong tin bao cao
    ReportInfoData: [],
    // thong tin tai san
    AssetInfoData: {},
    summaryAssetReportResult: {}
}
const AssetmentStocktakingReportReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case SET_BILL_INFO:
            if (!isEmpty(action.data?.data)) {
                state.billInfoData = action.data.data;
                state.isGetBillInfoData = true;
            }
            return {...state}
            break;
        case SET_REPORT_INFO:
            if (!isEmpty(action.data?.data)) {
                state.ReportInfoData = action.data.data;
            }
            return { ...state }
            break;
        case SET_LIST_REPORT:
            if (!isEmpty(action?.data)) {
                state.listReport = action?.data;
                state.isGetListOK = true;
            }
            return { ...state }
            break;
        case SET_ASSET_INFO:
            if (action.data) {
                state.AssetInfoData = action.data
            }
            return { ...state}
        break;
        case SET_RESULT_REPORT:
            if(action.data) {
                state.AssetInfoData = action.data
            }
            return {...state}
        case SET_SUMMARY_REPORT: 
            if(action.data) {
                state.summaryAssetReportResult = action.data?.data;
            }
            return { ...state }
            break;
        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };
            break;
        default:
            return { ...state };
    }
};

export default AssetmentStocktakingReportReducer;
