export const recordResult = {
  language: "vi",
  recordResult: {
    Identifiers: "Mã định danh",
    IdentifiersPlaceHolder: "Nhập mã định danh",
    Confirm: "Xác nhận đã đọc toàn bộ tài liệu hướng dẫn",
    information: "Thông tin chung",
    Workers: "Người lao động",
    Production_center: "Trung tâm sản xuất",
    Production_unit: "Đơn vị sản xuất",
    Production_order: "Người lao động",
    Finished: "Thành phẩm sản xuất:",
    stage: "Công đoạn thực hiện",
    Machines: "Máy móc sử dụng",
    document: "Tài liệu kỹ thuật",
    wo: "Lệnh sản xuất",
    table: {
      Criteria: "Tiêu chí",
      Unit: "Đơn vị",
      Standard: "Thông số tiêu chuẩn",
      Actual: "Thông số thực tế",
      Result: "Kết quả",
    },
    Stage_Result: "Kết quả Công đoạn",
    btn: {
      complete: "Hoàn thành",
      begin: "Bắt đầu",
    },
    validation: {
      notANumber: "Ký tự không hợp lệ",
      required: "Bắt buộc nhập",
      itemCodeRequired: "Vui lòng nhập mã định danh",
    }
  },
};
