import { httpCommon } from "./baseApi";

export const apiGetHandler = async (url, data) => {
  return new Promise((resolve, reject) => {
    try {
      return httpCommon({
        url: url,
        method: 'GET',
        params: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(false);
    }
  });
};

export const apiPostHandler = async (url, data) => {
  return new Promise((resolve, reject) => {
    try {
      return httpCommon({
        url: url,
        method: 'POST',
        data: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(false);
    }
  });
};

export const apiUploadHandler = async (url, data) => {
  return new Promise((resolve, reject) => {
    try {
      return httpCommon({
        url: url,
        method: 'POST',
        data: data,
        type: 'multipart/form-data'
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(false);
    }
  });
}
