import {
    GET_PAGING_INVENTORY_USER_CONFIG_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    CALL_CREATE_UPDATE_SUCCESS_REDUCER
} from "./enum/TypeInventoryUserConfigAction";

const stateDefault = {
    listData: [],
    isGetLstData: false,
    isCreateUpdateSuccess: false
}
const InventoryUserConfigReducer = (
    state = stateDefault,
    action
) => {

    switch (action.type) {
        case GET_PAGING_INVENTORY_USER_CONFIG_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.isGetLstData = true;
            }
            return { ...state };

        case CALL_CREATE_UPDATE_SUCCESS_REDUCER:
            state.isCreateUpdateSuccess = true;
            return { ...state };
        case CALL_DEFAULT_REDUCER:
            state.isGetLstData = false;
            state.isCreateUpdateSuccess = false;
            return {
                ...state,
            };
        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };

        default:
            return { ...state };
    }
};

export default InventoryUserConfigReducer;
