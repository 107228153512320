import { call, put, takeEvery } from "redux-saga/effects";
import {
    getDetailLocationService,
    getChecklistByItemCodeService,
    recordProducttionExecuteService,
    getDetailWorkorderByCode,
    getMachineList
} from "./Service";
import {
    CALL_API_RECORD_PRODUCT_EXCUTE_DATA_SAGA,
    CALL_API_TO_GET_INFORMATION_DATA_SAGA,
    GET_MACHINE_SAGA,
    GET_WORK_ORDER_BY_CODE_SAGA,
    SCAN_ITEM_CODE_SAGA
} from "./enum/actionEnum";
import action from "./Action";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
// import { fakeInformationData, rowFakeData } from "src/components/page/Machine/recordResultScreen/enum-entity";
import toastJs from "src/shared/common/toastJs";


//-----------------------------------------------------------
export function* callApiGetInfo(request) {
    openLoading();
    try {
        const response = yield call(getDetailLocationService, request);

        if (response.isSuccess) {
            yield put(action.setInfoDataReducer(response.data));
        }
    } catch (error) {

    } finally {
        closeLoading()
    }
}
//-----------------------------------------------------------
export function* scanItemCode(request) {
    openLoading();
    try {
        const entity = {
            "ItemCode": request.ItemCode,
            "workOderCode": request.workOderCode,
            "stepId": request.stepId,
            "isReadingDoc": request.isReadingDoc
        }

        if (!request.workOderCode) {
            toastJs.error("Thiếu lệnh sản xuất");
            return;
        }
        if (!request.stepId) {
            toastJs.error("Thiếu công đoạn thực hiện");
            return;
        }
        if (!request.isReadingDoc) {
            toastJs.error("Yêu cầu đọc tài liệu");
            return;
        }
        if (!request.ItemCode) {
            toastJs.error("Yêu cầu mã định dạng");
            return;
        }
        const response = yield call(getChecklistByItemCodeService, entity);
        if (response.isSuccess) {

            yield put(action.setScanItemCodeReducer(response.data));
        }
    } catch (error) {

    } finally {
        closeLoading()
    }
}
//-----------------------------------------------------------
export function* excuteRecordProduct(request) {
    openLoading();
    try {
        const response = yield call(recordProducttionExecuteService, request.entity);
        if (response.isSuccess) {
            yield put(action.excute_data_reducer());
        }
    } catch (error) {

    } finally {
        closeLoading()
    }
}
//-----------------------------------------------------------
export function* getwoSaga(request) {
    openLoading();
    try {
        const response = yield call(getDetailWorkorderByCode, request.woCode);
        if (response.isSuccess) {
            yield put(action.getworeducer(response.data));
        }
    } catch (error) {

    } finally {
        closeLoading()
    }
}
//-----------------------------------------------------------
export function* getmachineSaga(request) {
    openLoading();
    try {
        const response = yield call(getMachineList, request);
        if (response.isSuccess) {
            yield put(action.getMachinereducer(response.data));
        }
    } catch (error) {

    } finally {
        closeLoading()
    }
}
export default function* recordResultSaga() {
    yield takeEvery(CALL_API_TO_GET_INFORMATION_DATA_SAGA, callApiGetInfo);
    yield takeEvery(SCAN_ITEM_CODE_SAGA, scanItemCode);
    yield takeEvery(CALL_API_RECORD_PRODUCT_EXCUTE_DATA_SAGA, excuteRecordProduct);
    yield takeEvery(GET_WORK_ORDER_BY_CODE_SAGA, getwoSaga);
    yield takeEvery(GET_MACHINE_SAGA, getmachineSaga);
}
