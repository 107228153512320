import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import * as type from "./enum/MachineryRepairTypeApi";
export async function getDataPaingMachineryRepairService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DATA_PAING_MACHINERY_REPAIR_API, data);
}
export async function getDetailMachineryRepairService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DETAIL_MACHINERY_REPAIR_API +"?id="+ data);
}
export async function getDetailMachineryRepairAcceptanceService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_API +"?id="+ data);
}
export async function saveMachineryRepairService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.SAVE_MACHINERY_REPAIR_API,data);
}

export async function updateMachineryRepairService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.UPDATE_MACHINERY_REPAIR_API,data);
}
export async function saveAcceptanceAndRepairMachineryService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.SAVE_ACCEPTANCE_AND_REPAIR_MACHINERY_API,data);
}
export async function deleteMachineryRepairService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.DELETE_MACHINERY_REPAIR_API+"?id="+data);
}