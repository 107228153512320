export const GET_LIST_PLANNING_API = "/api/service/Planning/Search";
export const DELETE_PLANNING_API = "/api/service/Planning/Delete";
export const CREATE_PLANNING_API = "/api/service/Planning/Create";
export const UPDATE_PLANNING_API = "/api/service/Planning/Update";
export const GET_DETAIL_PLANNING_API = "/api/service/Planning/GetById";
export const ISEXIST_PLANNING_API =
    "/api/service/Planning/IsExist";

export const GET_INFO_PLANNING_BY_ID='/api/service/Planning/GetInfoPlanningById'

export const GET_PREDICTABILITY_RETAIL_AUTO_API='/api/service/Planning/PredictabilityRetailAuto'
export const PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_API='/api/service/Planning/PredictabilityRetailByListProduct'

export const GET_PREDICTABILITY_BUDGET_AUTO_API='/api/service/Planning/PredictabilityBudgetAuto'
export const PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_API='/api/service/Planning/PredictabilityBudgetByListProduct'
export const PLANNING_DOWNLOAD_EXCEL_API = '/api/service/Planning/ExportReport'
export const GET_PAGING_DETAIL_BY_ID_API='/api/service/PlanningDetail/PaginationByPlanningId'
export const UPDATE_RANGE_DETAIL_PLANNING_API = "/api/service/PlanningDetail/UpdateRange";
export const GET_INFO_PLANNING_PAGING_BY_ID_API = "/api/service/Planning/GetInfoPlanningPagingById";
export const DELETE_RANGE_PLANNING_DETAIL_API = "/api/service/PlanningDetail/DeleteRange";
export const PREDICTABILITY_BUGET_BY_IMPORT_API = "/api/service/Planning/PredictabilityBudgetByImport";
export const PROPOSAL_SUMMARY_API = "/api/service/Planning/ProposalSummary";

