import * as types from "./enum/AccessoriesTypeAction";
const AccessoriesReducer = (
  state = {
    isFetching: false,
    isFetchingMore: false,
    listData: [],
    objectData: {},
    callResultFromApi: false,
    callResultDetail: false,

    closeLoading: false,
    closePopupDelete: false,
  },
  action
) => {
  switch (action.type) {
    case types.RETURN_DATA_ACCESSORIES:
      if (action.data) {
        state.listData = action.data;
      }
      return {
        ...state,
      };

    case types.RETURN_OBJECT_ACCESSORIES:
      state.callResultDetail = true;
      if (action.obj) {
        state.objectData = action.obj.data;
      }
      return {
        ...state,
      };
    case types.CALL_RESULT_FROM_ACCESSORIES_API:
      state.callResultFromApi = true;
      return {
        ...state,
      };
    case types.CLOSE_LOADING_TABLE:
      state.closeLoading = action.status;
      return {
        ...state,
      };

    case types.CLOSE_DELETE_POPUP:
      state.closePopupDelete = action.status;
      return {
        ...state,
      };

    case types.CALL_DEFAULT_FROM_ACCESSORIES:
      state.callResultFromApi = false;
      state.callResultDetail = false;
      state.objectData = null;
      return {
        ...state,
      };
    case "default":
      state.callResultFromApi = false;
      state.callResultDetail = false;
      state.objectData = null;
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
};
export default AccessoriesReducer;
