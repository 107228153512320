export const Modelmm = {
    language: "vn",
    modelmm: {
        table: {
            Modelcode: "Mã Model",
            Modelname: "Tên Model",
            Description: "Mô tả",
            Description2: "Mô tả 2",
            Note: "Ghi chú",
            Active: "Trạng thái",
        },
        placeholder: "Mã model, tên model.... ",
        form: {
            title: "Xem thông tin model sản phẩm",
            updatetitle: "Cập nhật thông tin model sản phẩm",
            addtitle: "Thêm model sản phẩm",
            checkbox: "Đang hoạt động",
        },
        deleteform: {
            title: "Xác nhận xoá model",
            label: "Bạn có chắc chắn muốn xóa model này? "
        },
        error: {
            modelcodevalidate: "Mã model không đúng định dạng",
            modelcoderequired: "Mã model không được bỏ trống",
            modelcodelength: "Mã model không được quá 25 kí tự",
            modelnamevalidate: "Tên model không được bỏ trống",
            modelnamelength: "Tên model không được quá 255 ký tự",
            des1: "Mô tả 1 không được quá 255 ký tự",
            des2: "Mô tả 2 không được quá 255 ký tự",
            note: "Ghi chú không được quá 255 ký tự",
            productcodevali: "Mã không đúng định dạng",
            productrequired: "Mã sản phẩm không được bỏ trống",
            productmaxlength: "Mã sản phẩm không được quá 50 kí tự",
            productnamevali: "Tên sản phẩm không được bỏ trống",
            productnamemaxlength: "Tên sản phẩm không được quá 255 ký tự",
            unitrequired: "Đơn vị không được bỏ trống",
            typerequired: "Kiểu sản phẩm không được bỏ trống",
            typemaxlength: "Kiểu sản phẩm không được quá 100 kí tự",
            modelformrequired: "Model sản phẩm không được bỏ trống",
            modelformlength: "Model sản phẩm không được quá 25 kí tự",
            des1max: "Mô tả 1 không được quá 255 kí tự",
            des2max: "Mô tả 2 không được quá 255 kí tự",
        }
    }
}