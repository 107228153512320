import * as type from "./enum/MachineryRepairTypeAction";
const MachineryRepairAction = {
    getDataPagingMachineryRepairSaga: (data) => {
        return {
            type: type.GET_DATA_PAING_MACHINERY_REPAIR_SAGA,
            data: data,
        }
    },
    setDataPaingMachineryRepairReducer: (data) => {
        return {
            type: type.SET_DATA_PAING_MACHINERY_REPAIR_REDUCER,
            data:data,
        }
    },
    getDetailMachineryRepairSaga: (data) => {
        return {
            type: type.GET_DETAIL_MACHINERY_REPAIR_SAGA,
            data: data,
        }
    },
    getDetailMachineryRepairAcceptanceSaga: (data) => {
        return {
            type: type.GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_SAGA,
            data: data,
        }
    },
    getDetailMachineryRepairAcceptanceReducer: (data) => {
        return {
            type: type.GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_REDUCER,
            data: data,
        }
    },
    setDetailMachineryRepairReducer: (data) => {
        return {
            type: type.SET_DETAIL_MACHINERY_REPAIR_REDUCER,
            data:data,
        }
    },
    SaveMachineryRepairSaga: (data) => {
        return {
            type: type.SAVE_MACHINERY_REPAIR_SAGA,
            data:data,
        }
    },
    updateMachineryRepairSaga: (data) => {
        return {
            type: type.UPDATE_MACHINERY_REPAIR_SAGA,
            data: data,
        }
    },
    saveAcceptanceAndRepairMachinerySaga:(data) => {
        return {
            type: type.SAVE_ACCEPTANCE_AND_REPAIR_MACHINERY_SAGA,
            data: data,
        }
    },
    deleteMachineryRepairSaga:(data,dataPage) =>{        
        return {
            type: type.DELETE_MACHINERY_REPAIR_SAGA,
            data: data,
            dataPage: dataPage,
        }
    },
    callResultFromReducerMachineryRepair: () => {
        return {
            type:type.CALL_RESULT_FROM_REDUCER_MACHINER_REPAIR,
        }
    },
    callDefaultResultFromReducerMachineryRepair: () => {
        return {
            type: type.CALL_DEFAULT_RESULT_FROM_REDUCER_MACHINER_REPAIR,
        }
    }
}
export default MachineryRepairAction;