// product requirement
export const GET_ALL_PRODUCTION_REQUIRMENT_API =
  "/api/production-management-service/productionrequirement/get-all";
export const CREATE_PRODUCTION_REQUIRMENT_API =
  "/api/production-management-service/productionrequirement/create-productionrequirement";
export const UPDATE_PRODUCTION_REQUIRMENT_API =
  "/api/production-management-service/productionrequirement/update-productionrequirement";
export const DELETE_PRODUCTION_REQUIRMENT_API =
  "/api/production-management-service/productionrequirement/delete-productionrequirement/";
export const GET_DETAIL_PRODUCTION_REQUIRMENT_API =
  "/api/production-management-service/productionrequirement/get-detail/";
// get new code product requirment
export const GET_NEW_CODE_PRODUCT_API =
  "/api/production-management-service/common/get-code";
