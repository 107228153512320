export const GET_DROPDOWN_PRODUCT_PACKAGING_SAGA = "GET_DROPDOWN_PRODUCT_PACKAGING_SAGA";
export const SET_DROPDOWN_PRODUCT_PACKAGING_REDUCER = "SET_DROPDOWN_PRODUCT_PACKAGING_REDUCER";
export const GET_DROPDOWN_PACKING_LEVEL_SAGA = "GET_DROPDOWN_PACKING_LEVEL_SAGA";
export const SET_DROPDOWN_PACKING_LEVEL_REDUCER = "SET_DROPDOWN_PACKING_LEVEL_REDUCER";
export const GET_PACKAGE_INFO_SAGA = "GET_PACKAGE_INFO_SAGA";
export const GET_PACKAGE_DETAIL_SAGA = "GET_PACKAGE_DETAIL_SAGA";
export const SET_PACKAGE_DETAIL_REDUCER = "SET_PACKAGE_DETAIL_REDUCER";
export const GET_BELONG_INFO_SAGA = "GET_BELONG_INFO_SAGA";
export const SET_BELONG_INFO_REDUCER = "SET_BELONG_INFO_REDUCER";
export const DELETE_PACKAGE_SAGA = "DELETE_PACKAGE_SAGA";
export const CALL_SUCCESS_FROM_API_REDUCER = "CALL_SUCCESS_FROM_API_REDUCER";
export const CALL_RESULT_FROM_API_REDUCER = "CALL_RESULT_FROM_API_REDUCER";
export const CALL_FALSE_FROM_API_REDUCER = "CALL_FALSE_FROM_API_REDUCER";
export const CALL_DEFAULT_SUCCES_REDUCER = "CALL_DEFAULT_SUCCES_REDUCER";
export const CLER_DATA_REDUCER = "CLER_DATA_REDUCER";
export const CALL_DEFAULT_PRODUCT_PACKAGING_REDUCER = "CALL_DEFAULT_PRODUCT_PACKAGING_REDUCER";