import CommonBase from "src/shared/common/base"
import configApp from "src/shared/configApp/configApp"
import { GET_LIST_USER_API, SAVE_USER_API, LOOK_UP_USER_LDAP } from "./enum/UserManagementApi"

export const getListAllUserService = async (data) => {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_LIST_USER_API, data)
}

export const saveUserService = async (data) => {
    return await CommonBase.postAsync(configApp.apiGateWay + SAVE_USER_API, data)
}

export const lookupUserLDAP = async (userName) => {
    return CommonBase.getAsync(configApp.apiGateWay + LOOK_UP_USER_LDAP + `?userName=${userName}`)
}
