export const GET_DATA_FROM_SAGA = "GET_DATA_FROM_SAGA";
export const SET_DATA_TO_REDUCER = "SET_DATA_TO_REDUCER";
export const RESET_DATA_REDUCER = "RESET_DATA_REDUCER";
export const DELETE_CHECKPOINT_SAGA = "DELETE_CHECKPOINT_SAGA";
export const CALL_DELETE_CHECKPOINT_DONE = "CALL_DELETE_CHECKPOINT_DONE";
export const CREATE_CHECKPOINT_SAGA = "CREATE_CHECKPOINT_SAGA";
export const UPDATE_CHECKPOINT_SAGA = "UPDATE_CHECKPOINT_SAGA";
export const CALL_CREATE_OR_UPDATE_CHECKPOINT_REDUCER = "CALL_CREATE_OR_UPDATE_CHECKPOINT_REDUCER";
export const GET_DETAIL_CHECK_POINT_SAGA = "GET_DETAIL_CHECK_POINT_SAGA";
export const SET_DETAIL_CHECK_POINT_REDUCER = "SET_DETAIL_CHECK_POINT_REDUCER";
export const UPDATE_TABLE_BY_TYPE_CHECK_POINT_SCREEN = "UPDATE_TABLE_BY_TYPE_CHECK_POINT_SCREENABC";
