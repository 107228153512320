export const GET_PAGING_PRODUCT_API = "/api/service/CatProduct/Search";
export const GET_PAGING_PRODUCT_BY_ROLE_USER_API = "/api/service/CatProduct/SearchByRoleUser";
export const DELETE_PRODUCT_API = "/api/service/CatProduct/Delete?Id=";
export const CREATE_PRODUCT_API = "/api/service/CatProduct/Create";
export const UPDATE_PRODUCT_API = "/api/service/CatProduct/Update";
export const ISEXIST_PRODUCT_API = "/api/service/CatProduct/IsExist";

export const GET_LIST_DATA_BY_UNITCODE_API =
  "/api/service/CatProduct/GetListByHospital";
  export const GET_LIST_PRODUCT_API = "/api/service/CatProduct/SearchAllData";
  export const GET_LIST_PRODUCT_NOT_IN_BARCODE_API = "/api/service/CatProduct/SearchProductNotInBarcode";