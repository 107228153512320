export const repairLag = {
    language: "en",
    repair: {
        table: {
            itemCode: "Itemcode",
            stepProcessName: "Error recording stage",
            workUnitName: "Error recording chain",
            userRecord: "Error Recorder",
            workOderCode: "WO affiliated",
            decription: "Decription",
            timeRecord: "Recording time",
            errorTypeName: "Reason",
            status: "Status",
            action: "Action",
            noData: "No data to display"
        },
        placeholder: {
            searchTerm: "Maintenance code, machine code, machine name",
            status: "Status",
            typeError: "Classify",
            timeSearch: "Error recording time",
        },
        btn: {
            create: "Create new",
        },
        form: {
            formTitle: "Error recording",
            seeDetailForm: "Details of error recording",
            createTitleForm: "Error information",
            itemCode: "Enter barcode item",
            productcode: "Product code:",
            productname: "Product's name:",
            timeRecord: "Error recording time",
            receiver: "Error Recorder",
            workcenter: "Error log location",
            stepprocess: "Stage",
            errortype: "Error type",
            description: "Error description",
            infoTitleForm: "Error correction information",
            timerecordRepair: "Correct date and time",
            receiverNameRepair: "Bug fixer",
            hangdingAction: "Handling action",
            editContent: "Error correction content",
            reason: "Reason",
            errorRepairType: "Error type",
            InfoTableMaterial: "Material Information",
            table: {
                material: "Components Information",
                qtyToUse: "Number of components used",
                qtyRepair: "Number of parts returned to stock",
            },
            btn: {
                createTable: "Create new",
            },
            steoversion: "Production line return stage",
            placeholder: {
                itemCode: "Enter barcode item",
                timeRecord: "Error recording time",
                receiver: "Error Recorder",
                workcenter: "Work Center",
                workunit: "Work Unit",
                stepprocess: "Stage",
                errortype: "Error type",
                description: "Error description",
                hangdingAction: "Handling action",
                editContent: "Error correction content",
                reason: "Reason",
                errorRepairType: "Error type",
                material: "Components Information",
                qtyToUse: "Number of components used",
                qtyRepair: "Number of parts returned to stock",
                steoversion: "Production line",
            }
        },
        toast: {
            actionError: "Product error has been resolved, cannot proceed",
            createSuccess: "Create a new error record successfully",
            actionSuccess: "Correction execution successful",
        },
        rule: {
            itemcode: {
                required: "Barcode cannot be left blank",
            },
            receiverid: {
                required: "The error loger cannot be left blank",
            },
            workcenterid: {
                required: "The production center cannot be left empty",
            },
            workunitid: {
                required: "Production unit cannot be left blank",
            },
            stepprocessid: {
                required: "Stage cannot be left blank",
            },
            initialerrortype: {
                required: "Error type cannot be left blank",
            },
            decription: {
                required: "Description cannot be left blank",
                maxLength: "Description cannot exceed 255 characters"
            },
            handingaction: {
                required: "Handling action cannot be left blank",
            },
            editcontent: {
                required: "Corrections cannot be left blank",
                maxLength: "Error correction content cannot exceed 255 characters",
            },
            reason: {
                required: "The reason cannot be left blank",
            },
            errorrepaitype: {
                required: "Error type cannot be left blank",
            },
            stepversionid: {
                required: "The production line must not be empty",
            },
            qtyToUse: {
                numberOnly: "The number of links must be numeric",
                required: "The number of links cannot be left blank",
                maxLength: "The number of links cannot be left blank",
            },
            qtyRepair: {
                numberOnly: "The number of links must be positive",
                required: "The number of links cannot be left blank",
                maxLength: "The number of links cannot be left blank",
            },
            materialcode: {
                required: "Parts information cannot be left blank",
            },
        }
    }
}