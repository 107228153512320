import {
    GET_PAGING_EXPORT_USAGE_SAGA,
    GET_PAGING_EXPORT_USAGE_REDUCER,
    SET_TRANSACTION_DETAIL_LIST_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    CREATE_EXPORT_USAGE_SAGA,
    UPDATE_EXPORT_USAGE_SAGA,
    CREATE_OR_UPDATE_EXPORT_USAGE_SUCCESSED_REDUCER,
    SET_MEDICAL_SUPPLIES_LIST_REDUCER,
    DELETE_EXPORT_USAGE_SUCCESSED_REDUCER,
    DELETE_EXPORT_USAGE_SAGA,
    GET_DETAIL_EXPORT_USAGE_SAGA,
    GET_DETAIL_EXPORT_USAGE_SUCCESSED_REDUCER,
    SET_DETAIL_DATA_DEFAULT_REDUCER,
    SET_HOSPITALID_AND_WAREHOUSEID_REDUCER,
    SET_MEDICAL_SUPPLIES_LIST_DELETED_REDUCER,
    CANCEL_EXPORT_USAGE_SUCCESSED_REDUCER,
    CANCEL_EXPORT_USAGE_SAGA,
    GET_PATIENT_INFO_SAGA,
    GET_PATIENT_INFO_REDUCER,
    GET_PURCHASE_ORDER_BY_CODE_SAGA,
    GET_PURCHASE_ORDER_BY_CODE_REDUCER,
    GET_BY_ID_EXPORT_USAGE_SAGA,
    GET_BY_ID_EXPORT_USAGE_REDUCER,
    RESET_GET_BY_ID_EXPORT_USAGE_REDUCER,
    RESET_GET_PAGING_EXPORT_USAGE_REDUCER,
    RESET_DELETE_EXPORT_USAGE_REDUCER,
  } from "./enum/TypeExportUsageAction";
  const ExportUsageAction = {
    getPagingSaga: (
      searchTerm = "",
      type = [],
      hospitalIDList = [],
      warehouseIDList = [],
      startDateExport = "",
      endDateExport = "",  
      status = null,
      pageIndex,
      pageSize, 
      ListDataUser = [],
      CostCenter= [],
      IOP = null,
      PatientId = null,
      VisitCode = null,
      CodeOH = null,
             ) => {
      return {
        type: GET_PAGING_EXPORT_USAGE_SAGA,
        SearchTerm: searchTerm,
        IOP: IOP,
        Type: type, //Lọc theo loại phiếu xuất, 2 xuất cho bệnh nhân, 3,xuất sử dụng chung
        CatHospitalIDList: hospitalIDList, // Lọc theo danh sách bệnh viện
        CatWarehouseIDList: warehouseIDList, // Lọc theo danh sách kho 
        StartDateExport: startDateExport,
        EndDateExport: endDateExport, //Khoảng thời gian xuất phiếu
        Status: status, //Trạng thái phiếu xuất
        PageIndex: pageIndex,
        PageSize: pageSize,
        ListDataUser: ListDataUser,
        CostCenter: CostCenter,
        PatientId: PatientId,
        VisitCode: VisitCode,
        CodeOH: CodeOH,
      };
    },
    createDataSaga: (data, statusSave) => {
      return {
        type: CREATE_EXPORT_USAGE_SAGA,
        data: data,
        statusSave:statusSave
      };
    },
    updateDataSaga: (data, statusSave) => {
      return {
        type: UPDATE_EXPORT_USAGE_SAGA,
        data: data,
        statusSave:statusSave
      };
    },
    getLstPatientInfoSaga:(patientId) => {
      return{
        type: GET_PATIENT_INFO_SAGA,
        patientId: patientId,
      }
    },
    getLstPatientInfoReducer:(data) => {
      return{
        type: GET_PATIENT_INFO_REDUCER,
        data: data,
      }
    },
    getByIdSaga: (data) =>{
      return{
        type: GET_BY_ID_EXPORT_USAGE_SAGA,
        data: data,
      }
    },
    getByIdReducer: (data) =>{
      return{
        type: GET_BY_ID_EXPORT_USAGE_REDUCER,
        data: data,
      }
    },
    resetGetByIdReducer: () =>{
      return{
        type: RESET_GET_BY_ID_EXPORT_USAGE_REDUCER,
      }
    },
    getDetailDataSaga: (data)=>{
      return{
        type: GET_DETAIL_EXPORT_USAGE_SAGA,
        data: data,
      }
    },
    cancelDataSaga:(data) => {
      return{
        type: CANCEL_EXPORT_USAGE_SAGA,
        data:data,
      }
    },
    deleteDataSaga: (data) => {
      return {
        type: DELETE_EXPORT_USAGE_SAGA,
        data: data,
      };
    },
    resetDeleteDataReducer:() =>{
      return {
        type: RESET_DELETE_EXPORT_USAGE_REDUCER,
      };
    },
    createExportUsageSuccessedReducer:() =>{
      return {
        type: CREATE_OR_UPDATE_EXPORT_USAGE_SUCCESSED_REDUCER
      }
    },
    cancelSuccessedReducer:() =>{
      return {
        type: CANCEL_EXPORT_USAGE_SUCCESSED_REDUCER
      }
    },
    getDetailExportUsageSuccessedReducer:(data) =>{
      return {
        type: GET_DETAIL_EXPORT_USAGE_SUCCESSED_REDUCER,
        data: data
      }
    },
    deleteExportUsageSuccessedReducer:() =>{
      return {
        type: DELETE_EXPORT_USAGE_SUCCESSED_REDUCER
      }
    },
    getPagingReducer: (data) => {
      return {
        type: GET_PAGING_EXPORT_USAGE_REDUCER,
        data: data,
      };
    },
    resetGetPagingReducer: () => {
      return {
        type: RESET_GET_PAGING_EXPORT_USAGE_REDUCER
      };
    },
    setTransactionDetailList: (data) => {
      return {
        type: SET_TRANSACTION_DETAIL_LIST_REDUCER,
        data: data,
      };
    },
    setListMedicalSupplies: (data) => {
      return {
        type: SET_MEDICAL_SUPPLIES_LIST_REDUCER,
        data: data,
      };
    },
    setListMedicalSuppliesDeleted: (data) => {
      return {
        type: SET_MEDICAL_SUPPLIES_LIST_DELETED_REDUCER,
        data: data,
      };
    },
    setDetailDataDefaultReducer:()=>{
      return {
        type: SET_DETAIL_DATA_DEFAULT_REDUCER,
      };
    },
    setHospitalIdAndWarehouseIdByExportUsageReducer:(data) => {
      return{
        type: SET_HOSPITALID_AND_WAREHOUSEID_REDUCER,
        data:data
      }
    },
    callDefaultReducer: () => {
      return {
        type: CALL_DEFAULT_REDUCER,
      };
    },
    clearDataReducer: () => {
      return {
        type: CLEAR_DATA_REDUCER,
      };
    },
    getPurChaseOrderByCodeSaga:(data)=>{
      return{
        type: GET_PURCHASE_ORDER_BY_CODE_SAGA,
        data: data,
      };
    },
    
    getPurChaseOrderByCodeReducer:(data)=>{
      return{
        type: GET_PURCHASE_ORDER_BY_CODE_REDUCER,
        data: data,
      };
    }
  }
  
  export default ExportUsageAction;
  