import {
    GET_DROPDOWN_WORKORDER_SAGA, SET_DROPDOWN_WORKORDER_REDUCER, CHANGE_SHIPMENT_PRODUCT_SAGA, ENFORCEMENT_PRODUCT_IDENTIFIER_SAGA, CALL_API_EFORCEMENT_SUCCESS_REDUCER,
    GET_DATA_PAGING_PRODUCT_IDENTIFIER_SAGA, SET_DATA_PAGING_PRODUCT_IDENTIFIER_REDUCER, GET_DETAIL_PRODUCT_INDENTIER_SAGA, SET_DETAIL_PRODUCT_INDENTIER_REDUCER,
    GET_LIST_DETAIL_PAGING_SAGA, SET_LIST_DETAIL_PAGING_REDUCER, REPRINT_STAMPS_SAGA, REPRINT_STAMPS_SUCCESS
} from "./enum/ProductIdentifierTypeAction";
const ProductIdentifierAction = {
    getDropdownWorkorder: (search) => {
        return {
            type: GET_DROPDOWN_WORKORDER_SAGA,
            search: search,
        };
    },
    setDropdownWorkorderReducer: (list) => {
        return {
            type: SET_DROPDOWN_WORKORDER_REDUCER,
            list: list,
        }
    },
    callDefault: () => {
        return {
            type: "default",
        };
    },
    changeShipmentProduct: (productcode, qtyShipment) => {
        return {
            type: CHANGE_SHIPMENT_PRODUCT_SAGA,
            productcode: productcode,
            qtyShipment: qtyShipment,
        };
    },
    enforcementProductIdentifier: (request) => {
        return {
            type: ENFORCEMENT_PRODUCT_IDENTIFIER_SAGA,
            request: request,
        };
    },
    callApiEforcementSuccess: () => {
        return {
            type: CALL_API_EFORCEMENT_SUCCESS_REDUCER,
        };
    },
    getDataPaging: (dataPage) => {
        return {
            type: GET_DATA_PAGING_PRODUCT_IDENTIFIER_SAGA,
            dataPage: dataPage,
        };
    },
    setDataPagingReducer: (data) => {
        return {
            type: SET_DATA_PAGING_PRODUCT_IDENTIFIER_REDUCER,
            data: data,
        };
    },
    getDetailProductIdentifier: (workordercode) => {
        return {
            type: GET_DETAIL_PRODUCT_INDENTIER_SAGA,
            workordercode: workordercode,
        };
    },
    setDetailProductIdentifierReducer: (data) => {
        return {
            type: SET_DETAIL_PRODUCT_INDENTIER_REDUCER,
            data: data,
        };
    },
    getListDetailProductIdentifier: (dataPage) => {
        return {
            type: GET_LIST_DETAIL_PAGING_SAGA,
            dataPage: dataPage,
        };
    },
    setListDetailProductIdentifierReducer: (data) => {
        return {
            type: SET_LIST_DETAIL_PAGING_REDUCER,
            data: data,
        };
    },
    reprintProductIdentifier: (request) => {
        return {
            type: REPRINT_STAMPS_SAGA,
            request: request,
        };
    },
    ReprintStampsSuccess: () => {
        return {
            type: REPRINT_STAMPS_SUCCESS,
        };
    },
};

export default ProductIdentifierAction;