import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import {
    getPagingService,
    getPagingByRoleUserService,
    updateCatWarehouseProductService,
    exportListDataService,
    searchProductByCodeOHService,
    exportReportByListIdService,
    getPagingByPlanningService,
    getDetailCatWarehouseProductService
} from "./CatWarehouseProductService"
import CatWarehouseProductAction from "./CatWarehouseProductAction"

import {
    GET_PAGING_CAT_WAREHOUSE_PRODUCT_SAGA,
    GET_PAGING_PRODUCT_BY_ROLE_USER_SAGA,
    UPDATE_CAT_WAREHOUSE_PRODUCT_SAGA,
    EXPORT_LIST_DATA_SAGA,
    SEARCH_PRODUCT_BY_CODEOH_SAGA,
    EXPORT_REPORT_BY_LIST_ID_SAGA,
    GET_PAGING_BY_PLANNING_SAGA,
    GET_DETAIL_CAT_WAREHOUSE_PRODUCT_SAGA
} from "./enum/TypeCatWarehouseProductAction"
import { downloadFile, getFileNameWithDate } from "src/shared/utils/file";
import { typeExportEnum } from "src/shared/constants/enum/constants";
import { GET_DETAIL_CAT_WAREHOUSE_PRODUCT_API } from "./enum/TypeCatWarehouseProductAPI";
import toastJs from "src/shared/common/toastJs";


export function* getPagingSaga(data) {
  try {

    const pageSize = data.pageSize ? data.pageSize : 10;
    const pageIndex = data.pageIndex ? data.pageIndex : 1;
    const searchTerm = data.searchTerm ? data.searchTerm.trim() : "";
    const catWarehouseIDList = data?.catWarehouseIDList ? data?.catWarehouseIDList : [];
    const IsRetailDispensingList = data?.IsRetailDispensingList;

    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      SearchTerm: searchTerm,
      CatWarehouseIDList: catWarehouseIDList,
      IsRetailDispensingList: IsRetailDispensingList,
    };
    const response = yield call(getPagingService, request);
    if (response) {
      // debugger
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        openLoading();
        yield put(
          CatWarehouseProductAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.SearchTerm,
            request.CatWarehouseIDList,
            request.IsRetailDispensingList
          )
        );
      } else {
        yield put(CatWarehouseProductAction.getPagingReducer(response));
        closeLoading();
      }
    }
    } catch (error) {
        closeLoading();
    }
}
//Saga lấy danh sách VTYT có phân quyền dữ liệu
export function* getPagingByRoleUserSaga(data) {
    try {
      //Thông tin mã bệnh viện và mã kho muốn tìm kiếm, dùng trong trường hợp ng dùng là admin muốn tìm kiếm các vật tư của các kho khác nhau
      const condition = {
        PageIndex: data.pageIndex,
        PageSize:  data.pageSize,
        SearchTerm: data.searchTerm,
        ListIdNotSelect: data.listIdNotSelect,
        ListProductGroup:data.productGroupId,
        HospitalId: data.hospitalId,
        WarehouseId: data.warehouseId,
        ListCodeOHSelected: data.listCodeOHSelected,
        ConsumablePackageId:data?.ConsumablePackageId,
        
      };
      let request = {
        condition : condition,
        typeFunction: data?.typeFunction != null ? data?.typeFunction : '',
      }
      const response = yield call(getPagingByRoleUserService, request);
      if (response) {
        if (response.data.Items.length <= 0 && request.PageIndex > 1) {
          yield put(
            CatWarehouseProductAction.getPagingByRoleUserSaga(
              request.PageIndex - 1,
              request.PageSize,null,
              request.searchTerm
            )
          );
        } else {
          // yield put(CatProductAction.getPagingReducer(response));
          yield put(CatWarehouseProductAction.getPagingByRoleUserReducer(response));
        }
      }
      closeLoading();
    } catch (error) {
      closeLoading();
    }
    finally{
      closeLoading();
    }
  }

  export function* searchProductByCodeOHSaga(data) {
    try {
      if(data.warehouseId == null || data.hospitalId == null)
      {
        toastJs.error("Cần chọn bệnh viện và kho trước khi tìm kiếm.");
        return
      }
      const request = {
        codeOH: data.codeOH,
        warehouseId:data.warehouseId,
        hospitalId:data.hospitalId,
        typeCodeFunction: data?.typeCodeFunction
      };
      console.log(request);
      const response = yield call(searchProductByCodeOHService, request);
      if (response.isSuccess) {
        yield put(CatWarehouseProductAction.searchProductByCodeOHSuccessReducer(response));
      }
      closeLoading();
    } catch (error) {
      closeLoading();
    }
    finally{
      closeLoading();
    }
  }
export function* updateCatWarehouseProductSaga(request) {
    try {
        if (request?.data) {
            const requestAPI = {
                Id: request?.data.Id,
                CatProductId: request?.data.CatProductId,
                CatWarehouseId: request?.data.CatWarehouseId,
                Max: parseFloat(request?.data.Max),
                Min: parseFloat(request?.data.Min),
                Target: parseFloat(request?.data.Target),
                Reorderpoint: parseFloat(request?.data.Reorderpoint),
                RadixInventory: parseFloat(request?.data.RadixInventory),
                CodeOH: request?.data.CodeOH,
                CodeSAP: request?.data.CodeSAP,
                IsActive: true,
                PackingQuantity: parseFloat(request?.data.PackingQuantity),
                PackingConvertQuantity: parseFloat(request?.data.PackingConvertQuantity ?? 1),
                PackingUnitMeasureId: parseInt(request?.data.PackingUnitMeasureId),
                TapeQuantity: parseFloat(request?.data.TapeQuantity),
                TapeConvertQuantity: parseFloat(request?.data.TapeConvertQuantity ?? 1),
                TapeUnitMeasureId: parseInt(request?.data.TapeUnitMeasureId),
                IsRetailDispensing: request?.data.IsRetailDispensing,
                IsCharge: request?.data.IsCharge

            };
            const response = yield call(updateCatWarehouseProductService, requestAPI);
            if (response.isSuccess) {
                yield put(CatWarehouseProductAction.callUpdateSuccessReducer());
                closeLoading();
            }
            else
                yield put(CatWarehouseProductAction.callUpdateFailReducer(response));
        }
        closeLoading();

    } catch (error) {
        closeLoading();
    }
    finally {
        closeLoading();
    }
}

//Hàm export danh sách excel của tất cả các bản ghi trong trang hiện tại 
export function* exportListDataSaga(data) {
    try {
        const pageSize = data.pageSize;
        const pageIndex = data.pageIndex;
        const searchTerm = data.searchTerm ? data.searchTerm : "";
        const catWarehouseIDList = data.catWarehouseIDList;

        const request = {
            PageIndex: pageIndex,
            PageSize: pageSize,
            searchTerm: searchTerm,
            catWarehouseIDList: catWarehouseIDList
        };

        const response = yield call(exportListDataService, request);

        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileName = getFileNameWithDate({
            prefix: "",
            fileNameWithoutExtension: "Danh sách danh mục có trong kho",
            extension: ".xlsx",
        });
        downloadFile(fileUrl, fileName);
        closeLoading();
    } catch (error) {
        closeLoading();
    }
}

//Hàm xuất báo cáo excel theo danh sách Id truyền vào
export function* exportReportByListIdSaga(data) {
  try {
      const bodyParam = {
        SearchTerm: data.searchTerm,
        CatWarehouseIDList: data.catWarehouseIDList,
        IsRetailDispensingList: data.IsRetailDispensingList,
      }
      const request = {
        typeExport: typeExportEnum.Excel,
        bodyParam:bodyParam
      };

      const response = yield call(exportReportByListIdService, request);

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = getFileNameWithDate({
          prefix: "",
          fileNameWithoutExtension: "Danh sách danh mục có trong kho",
          extension: ".xlsx",
      });
      downloadFile(fileUrl, fileName);
      closeLoading();
  } catch (error) {
      closeLoading();
  }
}
export function* getPagingByPlanningSaga(data) {
  try {
      const bodyParam = {
        SearchTerm: data.searchTerm,
        PageIndex: data.pageIndex,
        PageSize: data.pageSize,
        WarehouseId: data.warehouseId,
        ListProductGroup: data.lstProductGroupId
      }
      const request = {
        planningId:data.planningId,
        bodyParam: bodyParam
      };
      const response = yield call(getPagingByPlanningService, request);
      if (response) {
          if (response.data?.Items?.length <= 0 && request.PageIndex > 1) {
              yield put(
                  CatWarehouseProductAction.getPagingByPlanningSaga(
                      request.PageIndex - 1,
                      request.PageSize,
                      request.searchTerm,
                      request.catWarehouseIDList
                  )
              );
          } else {
              yield put(CatWarehouseProductAction.getPagingByPlanningReducer(response));
          }
      }
      closeLoading();
  } catch (error) {
      closeLoading();
  }
}
export function* getDetailCatWarehouseProductSaga(Id){
  try {
      const response = yield call(getDetailCatWarehouseProductService, Id)
      if (!response.isSuccess) {
          yield put(CatWarehouseProductAction.getDetailCatWarehouseProductReducer);
          return
      }
      yield put(CatWarehouseProductAction.getDetailCatWarehouseProductReducer(response));
  } catch (e) {
      console.log("getDetailCatWarehouseProductReducer", e)
  } finally {
      yield put(CatWarehouseProductAction.callDefaultReducer());
  }
}
export default function* CatWarehouseProductSaga() {
    yield takeEvery(GET_PAGING_CAT_WAREHOUSE_PRODUCT_SAGA, getPagingSaga);
    yield takeEvery(GET_PAGING_PRODUCT_BY_ROLE_USER_SAGA, getPagingByRoleUserSaga);
    yield takeEvery(UPDATE_CAT_WAREHOUSE_PRODUCT_SAGA, updateCatWarehouseProductSaga);
    yield takeEvery(EXPORT_LIST_DATA_SAGA, exportListDataSaga);
    yield takeEvery(EXPORT_REPORT_BY_LIST_ID_SAGA, exportReportByListIdSaga);
    yield takeEvery(SEARCH_PRODUCT_BY_CODEOH_SAGA, searchProductByCodeOHSaga);
    yield takeEvery(GET_PAGING_BY_PLANNING_SAGA, getPagingByPlanningSaga);
    yield takeEvery(GET_DETAIL_CAT_WAREHOUSE_PRODUCT_SAGA, getDetailCatWarehouseProductSaga);

}
