import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import { GET_MENU_PERMISSION_SAGA } from "./enum/permissionEnumAction";
import PermissionAction from "./permissionAction";
import { getMenuPermissionService } from "./permissionService";

export function* getMenuPermissionSaga(data) {
    try {
        const response = yield call(getMenuPermissionService, data.request);
        if (response.Data) {
            yield put(PermissionAction.getMenuPermissionReducer(response.Data));
        }
    } finally {
        closeLoading();
    }
}

export default function* permissionSystemSaga() {
    yield takeEvery(GET_MENU_PERMISSION_SAGA, getMenuPermissionSaga);
}