export const cancelStampLag = {
    language: "en",
    cancelStamp: {
        packTitle: "Cancel stamps",
        table: {
            tableName:"Packing list",
            code:"Identifiers",
            unit: "Unit",
        },
        stampsInfo: {
            name: "Packing information",
            code: "Finished product code:",
            element: "Element:",
            packingLevel:"Packing level:"
        },
        btn: {
            search: "Query",
            cancelStamp:"Cancel stamps",
        },
        placeHolder: {
            search:"Enter / scan the identifier",
        },
    },
}