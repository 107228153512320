export const ApprovalProcess = {
    language: "en",
    approvalProcess: {
        search: {
            placeHolder:
                "Search by configuration name, description or created by",
        },
        table: {
            name: 'Process name',
            description: "Desciption",
            createdBy: "Creator",
            createdAt: "Created date"
        },
        form: {
            title: {
                create: 'Create an approval process',
                update: 'Update an approval process',
            },
            content: {
                name: {
                    title: "Process name",
                    placeHolder: "Enter process name..."
                },
                description: {
                    title: "Desciption",
                    placeHolder: "Enter content..."
                },
                lstStep: {
                    title: "Process step",
                    btnCreate: "Add new step",
                    step: "step",
                    noData: "You haven't created the process steps yet",
                    itemStep: {
                        name: {
                            placeHolder: "Enter the step name"
                        },
                        duration: {
                            placeHolder: "Hour"
                        },
                        user: {
                            title: "Approver",
                            placeHolder: "Enter your name or email"
                        },
                        role: {
                            title: "Role",
                            placeHolder: "Choose a role"
                        }
                    },
                }
            },

            rule: {
                name: {
                    required: "Process name cannot be empty",
                    maxLength: "Process name cannot exceed 250 characters"
                },
                description: {
                    maxLength: "Description cannot exceed 500 characters"
                },
                lstStep: {
                    name: {
                        required: "The step name cannot be empty",
                        maxLength: "The step name cannot exceed 250 characters"
                    },
                    duration: {
                        required: "Browsing time cannot be empty",
                        min: "Browsing time must be more than 0.5 hours"
                    },
                    userRole: {
                        required: "Each process step must select an approval person or role",
                    }
                }
            }
        },
        popup: {
            delete: {
                title: 'Delete the approval process',
                content: 'Are you sure you want to delete the approval process?',
            }
        },
        toastjs: {
            createSuccess: "New successful approval process",
            updateSuccess: "Update approval process successfully",
            deleteSuccess: "Clear the approval process successfully",
        },
    },
};
