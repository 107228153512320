import {
    GET_PAGING_CAT_BARCODE_SAGA,
    GET_PAGING_CAT_BARCODE_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    DELETE_CAT_BARCODE_SAGA,
    DELETE_CAT_BARCODE_REDUCER,
    UPDATE_CAT_BARCODE_SAGA,
    CALL_CREATE_UPDATE_SUCCESS_REDUCER,
    CREATE_CAT_BARCODE_SAGA,
    GET_LIST_CAT_BARCODE_REDUCER,
    GET_LIST_CAT_BARCODE_SAGA,
    CREATE_LIST_CAT_BARCODE_SAGA,
    DOWNLOAD_TEMPLATE_CAT_BARCODE_SAGA,
    GET_LIST_ALL_FIELD_CAT_BARCODE_REDUCER,
    GET_LIST_ALL_FIELD_CAT_BARCODE_SAGA
  } from "./enum/TypeCatBarcodeAction";
  
  const CatBarcodeAction = {
    getPagingSaga: (pageIndex, pageSize, searchTerm = "",type = '') => {
      return {
        type: GET_PAGING_CAT_BARCODE_SAGA,
        pageIndex: pageIndex,
        pageSize: pageSize,
        searchTerm: searchTerm,
        typeBarcode:type
      };
    },
  
    getPagingReducer: (data) => {
      return {
        type: GET_PAGING_CAT_BARCODE_REDUCER,
        data: data,
      };
    },
    deleteDataSaga: (id) => {
      return {
        type: DELETE_CAT_BARCODE_SAGA,
        id: id,
      };
    },
    downLoadTemplateDataSaga: () => {
      return {
        type: DOWNLOAD_TEMPLATE_CAT_BARCODE_SAGA,
      };
    },
    deleteDataReducer: () => {
      return {
        type: DELETE_CAT_BARCODE_REDUCER,
      };
    },
    
    createDataSaga: (data) => {
      return {
        type: CREATE_CAT_BARCODE_SAGA,
        data: data,
      };
    },
    createListDataSaga: (data) => {
        return {
          type: CREATE_LIST_CAT_BARCODE_SAGA,
          data: data,
        };
      },
    updateDataSaga: (data) => {
      return {
        type: UPDATE_CAT_BARCODE_SAGA,
        data: data,
      };
    },
    callCreateUpdateSuccessReducer: () => {
      return {
        type: CALL_CREATE_UPDATE_SUCCESS_REDUCER,
      };
    },
    getListUnitCategorySaga: () => {
      return {
        type: GET_LIST_CAT_BARCODE_SAGA,
      };
    },
    getListUnitCategoryReducer: (data) => {
      return {
        type: GET_LIST_CAT_BARCODE_REDUCER,
        data: data,
      };
    },
    getListFieldBarcodeReducer: (data) => {
      return {
        type: GET_LIST_ALL_FIELD_CAT_BARCODE_REDUCER,
        data: data,
      };
    },
    getListFieldBarcodeSaga: () => {
      return {
        type: GET_LIST_ALL_FIELD_CAT_BARCODE_SAGA,
      };
    },

    callDefaultReducer: () => {
      return {
        type: CALL_DEFAULT_REDUCER,
      };
    },
    clearDataReducer: () => {
      return {
        type: CLEAR_DATA_REDUCER,
      };
    },
  };
  
  export default CatBarcodeAction;
  