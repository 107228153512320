import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_PAGING_CAT_BARCODE_API,
  DELETE_CAT_BARCODE_API,
  CREATE_CAT_BARCODE_API,
  CREATE_LIST_CAT_BARCODE_API,
  UPDATE_CAT_BARCODE_API,
  GET_LIST_DATA_UNIT_API,DOWNLOAD_TEMPLATE_CAT_BARCODE_API,
  GET_LIST_FIELD_CAT_BARCODE_API
} from "./enum/TypeCatBarcodeAPI.js";

export async function getPagingService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_PAGING_CAT_BARCODE_API,
    data
  );
}

export async function deleteCatBarcodeService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_CAT_BARCODE_API + data
  );
}
export async function updateCatBarcodeService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_CAT_BARCODE_API,
    data
  );
}
export async function createCatBarcodeService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_CAT_BARCODE_API,
    data
  );
}

export async function createListCatBarcodeService(data) {
    return await CommonBase.postAsync(
      configApp.apiGateWay + CREATE_LIST_CAT_BARCODE_API,
      data
    );
  }


export async function getListUnitCategoryService() {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_LIST_DATA_UNIT_API
  );
}

export async function getListFieldBarcodeService() {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_LIST_FIELD_CAT_BARCODE_API
  );
}

export async function downloadTemplateBarcodeService() {
  return await CommonBase.getBlobAsync(
    configApp.apiGateWay + DOWNLOAD_TEMPLATE_CAT_BARCODE_API
  );
}
