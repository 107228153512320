import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
export async function getPagingService(userID) {
    // let api = configApp.apiGateWay + "/api/hubs/chat/get-all-approval/" + userID;
    // return await CommonBase.getAsync(api);
}
// export async function updateNotificationService(data) {
//     let api = configApp.apiGateWay + "/api/hubs/chat/update-status-approval/" + data.approvalID+"/"+data.userID;
//     return await CommonBase.postAsync(api);
// };
