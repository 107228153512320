import CommonBase from "../../../shared/common/base";
import configApp from "../../../shared/configApp/configApp";
import * as typesApi from "./enum/TypeInventoryAPI";

export async function fetchInventoryAuditPlanDataService(body) {
    return await CommonBase.postAsync(configApp.apiGateWay + typesApi.GET_LIST_INVENTORY_AUDIT_PLAN_API, body);
}

export async function fetchInventoryAuditPlanCreateService(body) {
    return await CommonBase.postAsync(configApp.apiGateWay + typesApi.CREATE_INVENTORY_AUDIT_PLAN_API, body);
}

export async function fetchInventoryAuditPlanUpdateService(body) {
    return await CommonBase.postAsync(configApp.apiGateWay + typesApi.UPDATE_INVENTORY_AUDIT_PLAN_API, body);
}

export async function fetchInventoryAuditPlanDeleteService(id) {
    return await CommonBase.getAsync(configApp.apiGateWay + typesApi.DELETE_INVENTORY_AUDIT_PLAN_API, {Id: id});
}

export async function fetchDetailAInventoryAuditPlanService(id) {
    return await CommonBase.getAsync(configApp.apiGateWay + typesApi.GET_DETAIL_INVENTORY_AUDIT_PLAN_API, {Id: id});
}
export async function fetchDetailAInventoryAuditPlanByCodeService(planCode) {
    return await CommonBase.getAsync(configApp.apiGateWay + typesApi.GET_DETAIL_INVENTORY_AUDIT_PLAN_BY_CODE_API, {code: planCode});
}

export async function fetchListInventoryAuditDetailService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + typesApi.GET_LIST_INVENTORY_AUDIT_DETAIL_API, data);
}

export async function downloadExcelLstDetailByAuditPlanService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + typesApi.EXPORT_LIST_DETAIL_BY_AUDIT_PLAN_API + "?auditPlanId=" + data.auditPlanId , data.filterData)
}