export const PackageInforLag = {
    language: "en",
    package: {
        stackingnumber: "Stacking quantity",
        packagecode: "Package code",
        packagetype: "Package type",
        goodscode: "Product code",
        status: "Status",
        warehouselocation: "Warehouse location",
        quantity: "Quantity",
        unit: "Unit",
        stacking: "Stacking",
        printpackage: "Print package",
        placeholder: "Code, package type, repository location",
        confirmpackageremoval: "Package removal",
        confirmdelete: "Are you sure you want to delete this package?",
        form: {
            packagemalformed: "Package code is malformed",
            packagecoderequired: "Package code cannot be left blank",
            packagecodelength: "Package code cannot exceed 50 characters",
            productrequired: "Product code cannot be left blank",
            packagetyperequired: "Package type cannot be left blank",
            packagetypelength: "Package type cannot exceed 225 characters",
            unitrequired: "Unit cannot be left blank",
            unitlength: "Units cannot exceed 225 characters",
            qtywrong: "Height is not formatted correctly",
            heightrequired: "Height cannot be empty",
            heigthmaxlength: "The height cannot exceed 10 characters",
            lengthwrong: "Length is not formatted",
            lengthmax: "The length cannot exceed 10 characters",
            widthwrong: "Wrong format width",
            widtdrequired: "Width cannot be empty",
            lengthrequired: "Length cannot be empty",
            widtdlength: "The width cannot exceed 10 characters",
            stackingcoutwrong: "Malformed stacking count",
            stackingcoutrequired: "The number of stacks cannot exceed 10 characters",
            addnewpack: "Add new package information",
            updatepack: "Update package information",
            detailpack: "Package information details",
        },
        toastJs: {
            createOk: "Successfully created new package",
            updateOk: "Package update successful",
            deleteOk: "Successfully removed the package"
        }
    }
};