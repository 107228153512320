import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { GET_LIST_INVENTORY_AUDIT_DETAIL_BY_PLAN_API, UPDATE_INVENTORY_AUDIT_API, UPDATE_LIST_INVENTORY_AUDIT_DETAIL_API } from "./enum/TypeInventoryTicketsAPI";

export async function fetchInventoryAuditUpdateService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + UPDATE_INVENTORY_AUDIT_API, data);
}

export async function updateListInventoryAuditDetailService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + UPDATE_LIST_INVENTORY_AUDIT_DETAIL_API + "?auditId=" + data?.auditId, 
        data.listAuditDetail);
}

export async function getListInventoryAuditDetailByPlanService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_LIST_INVENTORY_AUDIT_DETAIL_BY_PLAN_API + "?auditPlanId=" + data?.auditPlanId,  data.dataFilter);
}