export const GET_ASSETMENT_INVENTORY_RESULTS_SAGA = "GET_ASSETMENT_INVENTORY_RESULTS_SAGA";
export const GET_ASSETMENT_INVENTORY_RESULTS_REDUCER = "GET_ASSETMENT_INVENTORY_RESULTS_REDUCER";
export const CLEAR_ASSETMENT_INVENTORY_RESULT_REDUCER = "CLEAR_ASSETMENT_INVENTORY_RESULT_REDUCER";
export const CALL_DEFAULT_INVENTORY_RESULT_REDUCER = "CALL_DEFAULT_INVENTORY_RESULT_REDUCER";
export const GET_DETAIL_INVENTORY_RESULT_SAGA = "GET_DETAIL_INVENTORY_RESULT_SAGA";
export const GET_DETAIL_INVENTORY_RESULT_REDUCER = "GET_DETAIL_INVENTORY_RESULT_REDUCER";
export const LOADING = "LOADING";

export const GET_ASSETMENT_INVENTORY_INFO_SAGA = "GET_ASSETMENT_INVENTORY_INFO_SAGA";
export const GET_ASSETMENT_INVENTORY_INFO_REDUCER = "GET_ASSETMENT_INVENTORY_INFO_REDUCER";

export const UPDATE_ASSETMENT_INVENTORY_INFO_SAGA = "UPDATE_ASSETMENT_INVENTORY_INFO_SAGA";
export const UPDATE_ASSETMENT_INVENTORY_INFO_REDUCER = "UPDATE_ASSETMENT_INVENTORY_INFO_REDUCER";

export const GET_SUMMARY_RESULT_INVENTORY_SAGA = "GET_SUMMARY_RESULT_INVENTORY_SAGA";
export const GET_SUMMARY_RESULT_INVENTORY_REDUCER = "GET_SUMMARY_RESULT_INVENTORY_REDUCER";

export const GET_INVENTORY_ASSET_SCAN_SAGA = "GET_INVENTORY_ASSET_SCAN_SAGA";
export const GET_INVENTORY_ASSET_SCAN_REDUCER = "GET_INVENTORY_ASSET_SCAN_REDUCER";

export const INVENTORY_ASSET_SUBMIT_REPORT_SAGA = "INVENTORY_ASSET_SUBMIT_REPORT_SAGA";
export const INVENTORY_ASSET_SUBMIT_REPORT_REDUCER = "INVENTORY_ASSET_SUBMIT_REPORT_REDUCER";

export const INVENTORY_COMPLETION_CONFIRMATION_SAGA = "INVENTORY_COMPLETION_CONFIRMATION_SAGA";
export const INVENTORY_COMPLETION_CONFIRMATION_REDUCER = "INVENTORY_COMPLETION_CONFIRMATION_REDUCER";

export const CREATE_MANUAL_INVENTORY_ASSET_SAGA = "CREATE_MANUAL_INVENTORY_ASSET_SAGA";
export const CREATE_MANUAL_INVENTORY_ASSET_REDUCER = "CREATE_MANUAL_INVENTORY_ASSET_REDUCER";

export const EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_SAGA = "EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_SAGA";
export const EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_SAGA = "EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_SAGA";




