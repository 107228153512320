export const calendarManager = {
    language: "vn",
    calendar: {
        btn: {
            is_select_all: "Chọn tất cả",
            cancel: "Hủy lịch",
            choose_date_type: "Chọn loại ngày",
            save: "Lưu"
        },
        day: {
            sunday: "Chủ nhật",
            monday: "Thứ 2",
            tuesday: "Thứ 3",
            wednesday: "Thứ 4",
            thursday: "Thứ 5",
            friday: "Thứ 6",
            saturday: "Thứ 7",
        },
        month: {
            January: "Tháng 1",
            February: "Tháng 2",
            March: "Tháng 3",
            April: "Tháng 4",
            May: "Tháng 5",
            June: "Tháng 6",
            July: "Tháng 7",
            August: "Tháng 8",
            September: "Tháng 9",
            October: "Tháng 10",
            November: "Tháng 11",
            December: "Tháng 12"
        },
        errorCancel: {
            no_choose_to_cancel: "Chưa chọn ngày để hủy lịch",
            all_date_no_scheduled_to_cancel: "Tất cả ngày đã chọn chưa có lịch để hủy",
            the_selected_date_no_scheduled_to_cancel: "Ngày đã chọn chưa có lịch để hủy",
        },
        errorSave: {
            no_selected_work_schedule: "Chưa chọn lịch làm việc",
            no_set_calendar_for_the_day: "Chưa cài đặt lịch cho ngày"
        },
        successful: {
            successful_calendar_setting: "Cài đặt lịch làm việc thành công"
        },
        chooseDay: {
            choose_day: "Chọn loại ngày làm việc",
            name_type_working_day: "Tên loại ngày làm việc",
            symbol: "Ký hiệu",
            description: "Mô tả",
            salary_percentage: "Phần trăm lương(%)",
            representative_color: "màu đại diện",
            working_shift: "Ca làm việc",
            choose: "Chọn"
        }

    },
};
