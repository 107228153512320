export const requirementLag = {
    language: "en",
    requirement: {
        table: {
            productionRequirementcode: "Mã yêu cầu",
            productCode: "Mã sản phẩm",
            productName: "Tên sản phẩm",
            product: 'Sản phẩm',
            qtyrequirement: "Số lượng yêu cầu",
            unit: 'Đơn vị',
            timedeparture: 'Ngày xuất hàng',
            plannedQuanlity: 'Số lượng đã lên KHSX',
            producedQuanlity: 'Số lượng đã sản xuất',
            stockedQuanlity: 'Số lượng đã nhập kho',
            operation: 'Thao tác',
            dataEmpty: 'Không có dữ liệu để hiện thị',
        },
        search: {
            placeHolder:
                "Tìm theo mã yêu cầu, mã sản phẩm hoặc tên sản phẩm",
        },
        form: {
            add_new: "Tạo mới yêu cầu sản xuất",
            update: "Cập nhật yêu cầu sản xuất",
            see_detail: "Xem thông tin yêu cầu sản xuất",
            qtyrequirementPlaceholder: 'Nhập số lượng yêu cầu',
            productionRequirementcodePlaceholder: "Mã yêu cầu sản xuất",
            selectProduct: "Chọn sản phẩm",
            productionrequirementcodeRequired: "Mã yêu cầu sản xuất không được để trống",
            productionrequirementcodeMaxLength: "Mã yêu cầu sản xuất không được vượt quá 50 ký tự",
            QtyrequirementNumber: "Số lượng yêu cầu phải lớn hơn 0",
            QtyrequirementRequired: "Số lượng yêu cầu không được bỏ trống",
            TimedepartureRequired: "Ngày xuất hàng không được bỏ trống",
            QtyrequirementNumber2: "Số lượng yêu cầu phải là kiểu số nguyên dương",
            TimedepartureNotPast: "Ngày xuất hàng không thể là ngày trong quá khứ",
            productNotEmpty: "Sản phẩm không được bỏ trống",
            productCodeLength: "Mã sản phẩm chỉ cho phép dưới 50 kí tự",
        },
        delete: {
            title: "Xóa yêu cầu sản xuất",
            description: "Bạn chắc chắn muốn xóa yêu cầu sản xuất này?",
        },
        btn: {
            addNew: 'Thêm mới',
            create: 'Tạo',
            close: 'Đóng',
            save: 'Lưu',
            edit: 'Sửa'
        },
        toastjs: {
            createSuccess: "Tạo mới yêu cầu sản xuất thành công",
            updateSuccess: "Cập nhật yêu cầu sản xuất thành công",
            deleteSuccess: "Xóa yêu cầu sản xuất thành công",
        },
        rules: {
            required: 'Trường này không được để trống',
            number: 'Giá trị này phải là số',
            positive: 'Giá trị này phải là số nguyên dương'
        }

    },
};
