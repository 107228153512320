import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_PAGING_ASSETMENT_SAGA,
    EXPORT_EXCEL_ASSETMENT_LIST_SAGA,
    GET_ASSET_BY_ID_SAGA,
    UPDATE_ASSET_SAGA,
    UPDATE_MANY_ASSET_SAGA,
    GET_LIST_ASSET_HISTORY_SAGA,
    GET_ALL_ASSET_STAMP_HISTORY_SAGA,
    REQUEST_PRINT_STAMP
} from "./enum/TypeAssetmentListAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import AssetmentListAction from "./AssetmentListAction";
import {
    GetPagingService,
    exportExcelAssetmentListService,
    getAssetByIdService,
    UpdateAssetService,
    UpdateManyAssetService,
    GetListAssetHistoryService,
    GetAllAssetStampHistoryService,
    RequestPrintStamp
} from "./AssetmentListService";
import dayjs from "dayjs";
import moment from "moment";


export function* getPagingSaga(data) {
    try {
        const searchTerm = data.request?.searchTerm ? data.request?.searchTerm : null;
        const pageSize = data.request?.pageSize;
        const pageIndex = data.request?.pageIndex;
        const fromSAPCreateDate = data.request?.FromSAPCreateDate ? dayjs(data.request?.FromSAPCreateDate).format("YYYY-MM-DD") : null;
        const toSAPCreateDate = data.request?.ToSAPCreateDate ? dayjs(data.request?.ToSAPCreateDate).format("YYYY-MM-DD") : null;
        const assetNumber = data.request?.AssetNumber ? data.request?.AssetNumber : null;
        const subNumber = data.request?.SubNumber;
        const costCenterId = data.request?.CostCenterId ? data.request?.CostCenterId : null;
        const fundCenterId = data.request?.FundCenterId ? data.request?.FundCenterId : null;
        const status = data.request?.Status ? data.request?.Status : null;
        const segmentId = data.request?.SegmentId ? data.request?.SegmentId : null;
        const evaluationGroup3 = data.request?.EvaluationGroup3 ? data.request?.EvaluationGroup3 : null;
        const evaluationGroup5 = data.request?.EvaluationGroup5 ? data.request?.EvaluationGroup5 : null;
        const assetSource = data.request?.AssetSourceId ? data.request?.AssetSourceId : null;
        const propertyUser = data.request?.PropertyUser ? data.request?.PropertyUser : null;
        const serialNo = data.request?.SerialNo ? data.request?.SerialNo : null;
        const listAssetId = Array.isArray(data.request?.ListAssetId) ? data.request.ListAssetId : [];
        const request = {
            SearchTerm: searchTerm,
            PageIndex: pageIndex,
            PageSize: pageSize,
            FromSAPCreateDate: fromSAPCreateDate,
            ToSAPCreateDate: toSAPCreateDate,
            AssetNumber: assetNumber,
            SubNumber: subNumber,
            ListCostCenterId: costCenterId,
            ListFundCenterId: fundCenterId,
            ListStatus: status,
            ListSegmentId: segmentId,
            ListEvaluationGroup5Id: evaluationGroup5,
            ListEvaluationGroup3Id: evaluationGroup3,
            ListAssetSourceId: assetSource,
            PropertyUser: propertyUser,
            SerialNo: serialNo,
            ListAssetId: listAssetId
        };
        const response = yield call(GetPagingService, request);
        if (response) {
            if (response.Items.length <= 0 && data.request.PageIndex > 1) {
                request.PageIndex = data.request.pageIndex - 1;
                yield put(AssetmentListAction.getPagingSaga(request));
            } else {
                yield put(AssetmentListAction.getPagingReducer(response));
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* exportExcelAssetmentListSaga(data) {
    try {
        const searchTerm = data.request?.searchTerm ? data.request?.searchTerm : null;
        const fromSAPCreateDate = data.request?.FromSAPCreateDate ? dayjs(data.request?.FromSAPCreateDate).format("YYYY-MM-DD") : null;
        const toSAPCreateDate = data.request?.ToSAPCreateDate ? dayjs(data.request?.ToSAPCreateDate).format("YYYY-MM-DD") : null;
        const assetNumber = data.request?.AssetNumber ? data.request?.AssetNumber : null;
        const subNumber = data.request?.SubNumber;
        const costCenterId = data.request?.CostCenterId ? data.request?.CostCenterId : null;
        const fundCenterId = data.request?.FundCenterId ? data.request?.FundCenterId : null;
        const status = data.request?.Status;
        const segmentId = data.request.SegmentId ? data.request?.SegmentId : null;
        const evaluationGroup3 = data.request?.EvaluationGroup3 ? data.request?.EvaluationGroup3 : null;
        const evaluationGroup5 = data.request?.EvaluationGroup5 ? data.request?.EvaluationGroup5 : null;
        const assetSource = data.request?.AssetSourceId ? data.request?.AssetSourceId : null;
        const lstAssetId = data.request?.LstAssetId ? data.request?.LstAssetId : null;
        const propertyUser = data.request?.PropertyUser ? data.request?.PropertyUser : null;
        const serialNo = data.request?.SerialNo ? data.request?.SerialNo : null;
        const request = {
            SearchTerm: searchTerm,
            FromSAPCreateDate: fromSAPCreateDate,
            ToSAPCreateDate: toSAPCreateDate,
            AssetNumber: assetNumber,
            SubNumber: subNumber,
            ListCostCenterId: costCenterId,
            ListFundCenterId: fundCenterId,
            ListStatus: status,
            ListSegmentId: segmentId,
            ListEvaluationGroup5Id: evaluationGroup5,
            ListEvaluationGroup3Id: evaluationGroup3,
            ListAssetSourceId: assetSource,
            ListAssetId: lstAssetId,
            PropertyUser: propertyUser,
            SerialNo: serialNo
        };

        let result = yield call(exportExcelAssetmentListService, request);
        let url = window.URL
            .createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.download = `Danh_sach_tai_san_${moment().format("DD_MM_YYYY_HH_mm_ss")}.xlsx`;

        link.click();
        if (url) {
            url = URL.revokeObjectURL(url);
        }
        closeLoading()
    } catch (error) {
        closeLoading()
    }
}
export function* getAssetByIdSaga(data) {
    try {
        const response = yield call(getAssetByIdService, data.id);
        if (response.isSuccess) {
            yield put(AssetmentListAction.getAssetByIdReducer(response));
        }
        closeLoading();
    } catch (error) {
        closeLoading();
    }
}
export function* UpdateAssetSaga(data) {
    try {
        if (data.request) {
            const request = {
                Id: data.request?.Id,
                GroupBPCId: data.request?.GroupBPCId ? data.request?.GroupBPCId : "",
                //Note: "",
                EvaluationGroup1Id: data.request?.EvaluationGroup1Id ? data.request?.EvaluationGroup1Id : "",
                EvaluationGroup2Id: data.request?.EvaluationGroup2Id ? data.request?.EvaluationGroup2Id : "",
                EvaluationGroup3Id: data.request?.EvaluationGroup3Id ? data.request?.EvaluationGroup3Id : "",
                EvaluationGroup4Id: data.request?.EvaluationGroup4Id ? data.request?.EvaluationGroup4Id : "",
                EvaluationGroup5Id: data.request?.EvaluationGroup5Id ? data.request?.EvaluationGroup5Id : "",
                //CompanyCode: data.request?.CompanyCode ? data.request?.CompanyCode : "",
                //AssetNumber: data.request?.AssetNumber ? data.request?.AssetNumber : "",
                //SubNumber: data.request?.SubNumber ? data.request?.SubNumber : 0,
                //AssetClass: data.request?.AssetClass ? data.request?.AssetClass : "",
                Name1: data.request?.Name1 ? data.request?.Name1?.trim() : "",
                Name2: data.request?.Name2 ? data.request?.Name2?.trim() : "",
                //CapitalDate: data.request?.CapitalDate ? dayjs(data.request?.CapitalDate).format("YYYY-MM-DD") : "",
                //LastRetDate: data.request?.LastRetDate ? dayjs(data.request?.LastRetDate).format("YYYY-MM-DD") : "",
                //Po: data.request?.Po ? data.request?.Po.trim() : "",
                //VendorCode: data.request?.VendorCode ? data.request?.VendorCode : "",
                UnitsId: data.request?.UnitsId ? data.request?.UnitsId : "",
                Quantity: data.request?.Quantity ? data.request?.Quantity : 0,
                InventoryNumberModel: data.request?.InventoryNumberModel ? data.request?.InventoryNumberModel?.trim() : "",
                SerialNo: data.request?.SerialNo ? data.request?.SerialNo?.trim() : "",
                //CostCenterId: data.request?.CostCenterId ? data.request?.CostCenterId : "",
                //SegmentId: data.request?.SegmentId ? data.request?.SegmentId : "",
                FundCenterId: data.request?.FundCenterId ? data.request?.FundCenterId : "",
                PropertyUsers: data.request?.PropertyUsers ? data.request?.PropertyUsers : "",
                //ProfitCenterId: data.request?.ProfitCenterId ? data.request?.ProfitCenterId : "",
                RoomLocation: data.request?.RoomLocation ? data.request?.RoomLocation?.trim() : "",
                //DepreciationMethod: data.request?.DepreciationMethod ? data.request?.DepreciationMethod.trim() : "",
                //OriginalAsset: data.request?.OriginalAsset ? data.request?.OriginalAsset.trim() : "",
                //YearAllotmentTime: data.request?.YearAllotmentTime ? data.request?.YearAllotmentTime : 0,
                //MonthAllotmentTime: data.request?.MonthAllotmentTime ? data.request?.MonthAllotmentTime : 0,
                //OriginalPrice: data.request?.OriginalPrice ? data.request?.OriginalPrice : 0,
                //ResidualValue: data.request?.ResidualValue ? data.request?.ResidualValue : 0,
                //AssetAccount: data.request?.AssetAccount ? data.request?.AssetAccount.trim() : "",
                //DepreciationAccount: data.request?.DepreciationAccount ? data.request?.DepreciationAccount.trim() : "",
                //DepreciationExpenseAccount: data.request?.DepreciationExpenseAccount ? data.request?.DepreciationExpenseAccount.trim() : "",
                //DepreciationRunReport: data.request?.DepreciationRunReport ? data.request?.DepreciationRunReport : 0,
                DeactivedDate: data.request?.DeactivedDate ? dayjs(data.request?.DeactivedDate).format("YYYY-MM-DD") : "",
                Status: data.request?.Status ? data.request?.Status : 0,
                //SAPCreatedBy: data.request?.SAPCreatedBy ? data.request?.SAPCreatedBy : "",
                //SAPCreatedDate: data.request?.SAPCreatedDate ? dayjs(data.request?.SAPCreatedDate).format("YYYY-MM-DD") : "",
                AssetSourceId: data.request?.AssetSourceId ? data.request?.AssetSourceId : 0,
                ListDocument: data.request?.ListDocument
            };
            const response = yield call(UpdateAssetService, request);

            if (response.isSuccess) {
                yield put(AssetmentListAction.UpdateAssetReducer());
            } else if (response.response.status === 400) {
                yield put(AssetmentListAction.UpdateAssetOkSapFailedReducer());
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();
    }
}
export function* UpdateManyAssetSaga(data) {
    try {
        const request = {
            ListAssetId: data?.listAsset,
            //CompanyCode: data.objectUpdate?.CompanyCode ? data.objectUpdate?.CompanyCode : "",
            //AssetNumber: data.objectUpdate?.AssetNumber ? data.objectUpdate?.AssetNumber : "",
            //SubNumber: data.objectUpdate?.SubNumber ? data.objectUpdate?.SubNumber : 0,
            //AssetClass: data.objectUpdate?.AssetClass ? data.objectUpdate?.AssetClass : "",
            GroupBPCId: data.objectUpdate?.GroupBPCId ? data.objectUpdate?.GroupBPCId : "",
            //Note: "",
            EvaluationGroup1Id: data.objectUpdate?.EvaluationGroup1Id ? data.objectUpdate?.EvaluationGroup1Id : "",
            EvaluationGroup2Id: data.objectUpdate?.EvaluationGroup2Id ? data.objectUpdate?.EvaluationGroup2Id : "",
            EvaluationGroup3Id: data.objectUpdate?.EvaluationGroup3Id ? data.objectUpdate?.EvaluationGroup3Id : "",
            EvaluationGroup4Id: data.objectUpdate?.EvaluationGroup4Id ? data.objectUpdate?.EvaluationGroup4Id : "",
            EvaluationGroup5Id: data.objectUpdate?.EvaluationGroup5Id ? data.objectUpdate?.EvaluationGroup5Id : "",
            Name1: data.objectUpdate?.Name1 ? data.objectUpdate?.Name1?.trim() : "",
            Name2: data.objectUpdate?.Name2 ? data.objectUpdate?.Name2?.trim() : "",
            //CapitalDate: data.objectUpdate?.CapitalDate ? dayjs(data.objectUpdate?.CapitalDate).format("YYYY-MM-DD") : "",
            //LastRetDate: data.objectUpdate?.LastRetDate ? dayjs(data.objectUpdate?.LastRetDate).format("YYYY-MM-DD") : "",
            //Po: data.objectUpdate?.Po ? data.objectUpdate?.Po?.trim() : "",
            //VendorCode: data.objectUpdate?.VendorCode ? data.objectUpdate?.VendorCode : "",
            UnitsId: data.objectUpdate?.UnitsId ? data.objectUpdate?.UnitsId : "",
            Quantity: data.objectUpdate?.Quantity ? data.objectUpdate?.Quantity : 0,
            InventoryNumberModel: data.objectUpdate?.InventoryNumberModel ? data.objectUpdate?.InventoryNumberModel?.trim() : "",
            SerialNo: data.objectUpdate?.SerialNo ? data.objectUpdate?.SerialNo?.trim() : "",
            //CostCenterId: data.objectUpdate?.CostCenterId ? data.objectUpdate?.CostCenterId : "",
            //SegmentId: data.objectUpdate?.SegmentId ? data.objectUpdate?.SegmentId : "",
            FundCenterId: data.objectUpdate?.FundCenterId ? data.objectUpdate?.FundCenterId : "",
            PropertyUsers: data.objectUpdate?.PropertyUsers ? data.objectUpdate?.PropertyUsers : "",
            //ProfitCenterId: data.objectUpdate?.ProfitCenterId ? data.objectUpdate?.ProfitCenterId : "",
            RoomLocation: data.objectUpdate?.RoomLocation ? data.objectUpdate?.RoomLocation?.trim() : "",
            //DepreciationMethod: data.objectUpdate?.DepreciationMethod ? data.objectUpdate?.DepreciationMethod?.trim() : "",
            //OriginalAsset: data.objectUpdate?.OriginalAsset ? data.objectUpdate?.OriginalAsset?.trim() : "",
            //YearAllotmentTime: data.objectUpdate?.YearAllotmentTime ? data.objectUpdate?.YearAllotmentTime : 0,
            //MonthAllotmentTime: data.objectUpdate?.MonthAllotmentTime ? data.objectUpdate?.MonthAllotmentTime : 0,
            //OriginalPrice: data.objectUpdate?.OriginalPrice ? data.objectUpdate?.OriginalPrice : 0,
            //ResidualValue: data.objectUpdate?.ResidualValue ? data.objectUpdate?.ResidualValue : 0,
            //AssetAccount: data.objectUpdate?.AssetAccount ? data.objectUpdate?.AssetAccount?.trim() : "",
            //DepreciationAccount: data.objectUpdate?.DepreciationAccount ? data.objectUpdate?.DepreciationAccount?.trim() : "",
            //DepreciationExpenseAccount: data.objectUpdate?.DepreciationExpenseAccount ? data.objectUpdate?.DepreciationExpenseAccount?.trim() : "",
            //DepreciationRunReport: data.objectUpdate?.DepreciationRunReport ? data.objectUpdate?.DepreciationRunReport : 0,
            DeactivedDate: data.objectUpdate?.DeactivedDate ? dayjs(data.objectUpdate?.DeactivedDate).format("YYYY-MM-DD") : "",
            Status: data.objectUpdate?.Status ? data.objectUpdate?.Status : 0,
            //SAPCreatedBy: data.objectUpdate?.SAPCreatedBy ? data.objectUpdate?.SAPCreatedBy : "",
            //SAPCreatedDate: data.objectUpdate?.SAPCreatedDate ? dayjs(data.objectUpdate?.SAPCreatedDate).format("YYYY-MM-DD") : "",
            AssetSourceId: data.objectUpdate?.AssetSourceId ? data.objectUpdate?.AssetSourceId : 0
        };
        const response = yield call(UpdateManyAssetService, request);
        if (response.isSuccess) {
            yield put(AssetmentListAction.UpdateManyAssetReducer());
        }
        //closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getListAssetHistorySaga(data) {
    try {
        const request = {
            SearchTerm: data.request.searchTerm ? data.request.searchTerm : null,
            PageIndex: data.request.pageIndex,
            PageSize: data.request.pageSize,
            AssetId: data.request.assetId
        };
        const response = yield call(GetListAssetHistoryService, request);
        if (response) {
            yield put(AssetmentListAction.getListAssetHistoryReducer(response));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}

export function* getAllAssetStampHistorySaga(data) {
    try {
        const response = yield call(GetAllAssetStampHistoryService, data.id);
        if (response) {
            yield put(AssetmentListAction.GetAllAssetStampHistoryReducer(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}

export function* getPrintStampDataSaga(data) {
    try {
        const response = yield call(RequestPrintStamp, data.data);
        if (response) {
            // yield put(AssetmentListAction.setIsLoadedDataPrint(true))
            yield put(AssetmentListAction.getPrintStampData(response.data));
            closeLoading();
        }
    }
    catch (error) {
        console.error(error);
        closeLoading();
    }
    
}

export default function* AssetmentListSaga(data) {
    yield takeEvery(GET_PAGING_ASSETMENT_SAGA, getPagingSaga);
    yield takeEvery(EXPORT_EXCEL_ASSETMENT_LIST_SAGA, exportExcelAssetmentListSaga);
    yield takeEvery(GET_ASSET_BY_ID_SAGA, getAssetByIdSaga);
    yield takeEvery(UPDATE_ASSET_SAGA, UpdateAssetSaga);
    yield takeEvery(UPDATE_MANY_ASSET_SAGA, UpdateManyAssetSaga);
    yield takeEvery(GET_LIST_ASSET_HISTORY_SAGA, getListAssetHistorySaga);
    yield takeEvery(GET_ALL_ASSET_STAMP_HISTORY_SAGA, getAllAssetStampHistorySaga);
    yield takeEvery(REQUEST_PRINT_STAMP, getPrintStampDataSaga);
}
