import {
  GET_REPORT_TRANSACTION_REDUCER,
  GET_REPORT_RECONCILE_REDUCER,
  GET_REPORT_INVENTORY_REDUCER,
  CLEAR_DATA_REDUCER,
  CALL_DEFAULT_REDUCER,
  GET_SUM_REPORT_REDUCER,
} from "./enum/TypeReportAction";

const stateDefault = {
  listData: [],
  listDataReconcile: [],
  listDataInventory: [],
  isGetLstData: false,
  responseApi: null,
  data: null,
  sumReport: null, //Object chứa thông tin tổng 
  isGetSumReport: false,
};

const ReportReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_REPORT_TRANSACTION_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
        state.sumReport = action.data.TotalQuantity;
      }
      return { ...state };
    case GET_REPORT_RECONCILE_REDUCER:
      if (action.data) {
        state.listDataReconcile = action.data;
        state.isGetLstData = true;
        state.sumReport = action.data.TotalQuantity;
      }
      return { ...state };
    case GET_REPORT_INVENTORY_REDUCER:
      if (action.data) {
        state.listDataInventory = action.data;
        state.isGetLstData = true;
        state.sumReport = action.data.TotalQuantity;
      }
      return { ...state };
      case GET_SUM_REPORT_REDUCER:
      if (action.data) {
        state.sumReport = action.data;
        state.isGetSumReport = true;
      }
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.isGetLstData = false;
      state.responseApi = null;
      return {
        ...state,
      };
    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };
    default:
      return { ...state };
  }
};

export default ReportReducer;
