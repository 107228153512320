//import { combineReducers } from "redux";
import ReactHookDemoReducer from "src/redux-saga-v2/HookDemo/reactHookDemoReducer";
import SOPReducer from "src/redux-saga-v2/MMProduction/SOP/SOPReducer";
import BomReducer from "src/redux-saga-v2/MMProduction/BOM/BOMReducer";
import ApproveStepReducer from "src/redux-saga-v2/MMProduction/ApproveStep/ApproveStepReducer";
import ProductRequirmentReducer from "src/redux-saga-v2/MMProduction/ProductionRequirement/ProductionRequirementReducer";
import CommonDropDownReducerV2 from "src/redux-saga-v2/commonDropdown/CommonDropdownReducer";
import WorkareaLinkProccessReducer from "src/redux-saga-v2/MMProduction/WorkareaLinkProcess/WorkareaLinkProccessReducer";
//material manager
import AccessoriesReducer from "../MaterialManager/AccessoriesManager/Accessoris/AccessoriesReducer";
// thong tin phe duyet
import ApprovalReducer from "src/redux-saga-v2/MMProduction/Approval/ApprovalReducer";
import ProcessReducer from "src/redux-saga-v2/MMProduction/ProcessManager/ProcessReducer";
import PackingLevelReducer from "../MMProduction/ProductManager/Pack/PackingLevel/PackingLevelReducer";
import PackingProcessReducer from "../MMProduction/ProductManager/Pack/PackingProcess/PackingProcessReducer";
import ProductPackagingReducer from "../MMProduction/ProductManager/Pack/ProductPackaging/ProductPackagingReducer";
import ProductIdentifierReducer from "../MMProduction/ProductIdentifier/ProductIdentifierReducer";
import RecordResultReducer from "../QuanlityManager/RecordResult/RecordResultReducer";
import LOTQAReducer from "../QuanlityManager/LotQA/LotQAReducer";
import reducerLinkProductRequirement from "src/redux-saga-v2/MMProduction/LinkWorkCenterWithProcess/reducer";
import ItemAllocationReducer from "src/redux-saga-v2/MMProduction/ItemAllocation/ItemAllocationReducer";
import CheckpointReducer from "src/redux-saga-v2/QuanlityManager/Checkpoint/Reducer";
import RepairReducer from "../QuanlityManager/Repair/RepairReducer";
import CheckListReducer from "../QuanlityManager/CheckList/CheckListReducer";
import UploadReducer from "../UploadFile/UploadReducer";
import MaintainReducer from "../QuanlityManager/Maintaince/MaintainceReducer";
import POCoordinateReducer from "../QuanlityManager/ProductCoordination/POCoordinateReducer";
import RecordResultV2Reducer from "../Machine/recordResults/Reducer";
import ProductionLineReducer from "../MMProduction/ProductionLine/ProductionLineReducer";
import CommonGetCodeReducer from "../CommonGetCode/CommonGetCodeReducer";
import MachineryRepairReducer from "../QuanlityManager/MachineryRepair/MachineryRepairReducer";
import CommonCheckduplicatedReducer from "src/redux-saga-v2/CommonCheckduplicated/CommonCheckduplicatedReducer";
import PermissionReducer from "src/redux-saga-v2/Permission/permissionReducer";
import CommonDropdownVinmecReducer from "src/redux-saga-v2/commonDropdownVinmec/CommonDropdownVinmecReducer";
import BreadcrumbReducer from "../Breadcrumb/BreadcrumbReducer";
import AssetmentListReducer from "src/redux-saga-v2/AssetManagement/AssetmentPortfolio/AssetmentList/AssetmentListReducer";
import AssetmentTransferReducer from "src/redux-saga-v2/AssetManagement/AssetmentTransfer/AssetmentTransferReducer";
import UploadFileReducer from "../CommonUpload/CommonUploadReducer";
import AssetmentStocktakingReducer from "src/redux-saga-v2/AssetManagement/AssetmentStocktaking/AssetmentStocktakingReducer";
import UserSyncReducer from "../Administration/UserManagement/UserManagementReducer";
import ResultInventoryReducer from "src/redux-saga-v2/AssetManagement/AssetmentStocktaking/ResultInventory/ResultInventoryReducer";
import InventoryUserConfigReducer from "src/redux-saga-v2/Administration/InventoryUserConfig/InventoryUserConfigReducer";
import AssetmentStocktakingReportReducer from "../AssetManagement/AssetmentStocktaking/Report/ReportRducer";
import RequestIncreaseAssetReducer from "src/redux-saga-v2/AssetManagement/AssetmentStocktaking/RequestIncreaseAsset/RequestIncreaseAssetReducer";
import PermissionDataReducer from "src/redux-saga-v2/Administration/PermissionData/PermissionDataReducer";
import routerReducer from "src/redux-saga-v2/Router/reducer";
import CatHospitalReducer from "src/redux-saga-v2/MedicalSuppliesManagement/CatHospital/CatHospitalReducer";
import CatWarehouseReducer from "src/redux-saga-v2/MedicalSuppliesManagement/CatWarehouse/CatWarehouseReducer";
import UserHospitalWarehouseReducer from "src/redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseReducer";
import CatMedicalMaterialReducer from "src/redux-saga-v2/MedicalSuppliesManagement/CatMedicalMaterial/CatMedicalMaterialReducer";
import CatSupplierReducer from "src/redux-saga-v2/MedicalSuppliesManagement/CatSupplier/CatSupplierReducer";
import CatUnitMeasureReducer from "src/redux-saga-v2/MedicalSuppliesManagement/CatUnitMeasure/CatUnitMeasureReducer";
import CatBarcodeReducer from "src/redux-saga-v2/MedicalSuppliesManagement/CatBarcode/CatBarcodeReducer";

import DeliveryReducer from "src/redux-saga-v2/DeliveryRefund/DeliveryManagement/DeliveryReducer";
import ConsumablePackageReducer from "src/redux-saga-v2/ExportUsageManagement/ManageConsumablePackages/ConsumablePackageReducer";
import ExportUsageReducer from "src/redux-saga-v2/ExportUsageManagement/ExportUsage/ExportUsageReducer";
import ReturnReducer from "src/redux-saga-v2/DeliveryRefund/ReturnManagement/ReturnReducer";
import PlanningReducer from "src/redux-saga-v2/BudgetShoppingManagement/Planning/PlanningReducer";
import PlanningDetailReducer from "src/redux-saga-v2/BudgetShoppingManagement/PlanningDetail/PlanningDetailReducer";

import InventoryReducer from "src/redux-saga-v2/InventoryManagement/Inventory/InventoryReducer";

import CatProductReducer from "src/redux-saga-v2/MedicalSuppliesManagement/CatProduct/CatProductReducer";
import CatWarehouseProductReducer from "src/redux-saga-v2/MedicalSuppliesManagement/CatWarehouseProduct/CatWarehouseProductReducer"
import ConfigPlanningReducer from "src/redux-saga-v2/BudgetShoppingManagement/ConfigPlanning/ConfigPlanningReducer";
import InventoryTicketsReducer from "src/redux-saga-v2/InventoryManagement/InventoryTickets/InventoryTicketsReducer.js"
import LogManagementReducer from "../Administration/LogIntergrate/LogManagementReducer";
import LogJobReducer from "../Administration/LogJob/LogJobReducer";
import LogImpactReducer from "../Administration/LogImpact/LogImpactReducer";

// Báo cáo
import ReportReducer from "src/redux-saga-v2/Report/ReportReducer";

const rootReducerV2 = {
  ReactHookDemoReducer,
  SOPReducer,
  BomReducer,
  ProductRequirmentReducer,
  ApproveStepReducer,
  CommonDropDownReducerV2,
  ApprovalReducer,
  WorkareaLinkProccessReducer,
  ProcessReducer,
  PackingLevelReducer,
  PackingProcessReducer,
  ProductPackagingReducer,
  ProductIdentifierReducer,
  RecordResultReducer,
  LOTQAReducer,
  reducerLinkProductRequirement,
  ItemAllocationReducer,
  CheckpointReducer,
  RepairReducer,
  CheckListReducer,
  UploadReducer,
  ReportReducer,
  MaintainReducer,
  POCoordinateReducer,
  RecordResultV2Reducer,
  ProductionLineReducer,
  CommonGetCodeReducer,
  AccessoriesReducer,
  MachineryRepairReducer,
  CommonCheckduplicatedReducer,
  PermissionReducer,
  CommonDropdownVinmecReducer,
  BreadcrumbReducer,
  AssetmentListReducer,
  AssetmentTransferReducer,
  UploadFileReducer,
  AssetmentStocktakingReducer,
  UserSyncReducer,
  ResultInventoryReducer,
  AssetmentStocktakingReportReducer,
  InventoryUserConfigReducer,
  RequestIncreaseAssetReducer,
  PermissionDataReducer,
  routerReducer,
  CatHospitalReducer,
  CatWarehouseReducer,
  UserHospitalWarehouseReducer,
  CatMedicalMaterialReducer,
  CatSupplierReducer,
  CatUnitMeasureReducer,
  PlanningReducer,
  PlanningDetailReducer,
  InventoryReducer,
  ConsumablePackageReducer,
  ExportUsageReducer,
  DeliveryReducer,
  CatWarehouseProductReducer,
  CatBarcodeReducer,
  CatProductReducer,
  ConfigPlanningReducer,
  ReturnReducer,
  InventoryTicketsReducer,
  LogManagementReducer,
  LogJobReducer,
  LogImpactReducer
};

export default rootReducerV2;
