export const UPDATE_ITEM_CODE_REDUCER = "UPDATE_ITEM_CODE_REDUCER_record_produc";
export const UPDATE_ITEM_CODE_DONE = "UPDATE_ITEM_CODE_DONE_record_produc";
//-------------------------------------------------------------------

// set error
export const SET_UP_ERROR = "SET_UP_ERROR_record_produc";
export const SET_UP_ERROR_DONE = "SET_UP_ERROR_DONE_record_produc";
//-------------------------------------------------------------------

// lưu dữ liệu ghi nhận kết quả
export const SET_UP_DATA_RESULT = "SET_UP_DATA_RESULT_record_produc";
export const SET_UP_DATA_RESULT_DONE = "SET_UP_DATA_RESULT_DONE_record_produc";

//-------------------------------------------------------------------
// gọi api để kiểm tra mã định danh và trả về danh sách ghi nhận
export const SCAN_ITEM_CODE_SAGA = "SCAN_ITEM_CODE_SAGA_record_product";
export const SCAN_DATA_AFTER_SCAN_ITEM_CODE_REDUCER = "SCAN_DATA_AFTER_SCAN_ITEM_CODE_REDUCER_record_produc";
export const SCAN_ITEM_CODE_SAGA_DONE = "SCAN_ITEM_CODE_SAGA_DONE_record_produc";
export const SCAN_ITEM_CODE_UPDATE_TABLE = "SCAN_ITEM_CODE_UPDATE_TABLE_record_produc";

//-------------------------------------------------------------------
// gọi api để lấy thông tin ghi nhận sản xuất ở cột bên phải
export const CALL_API_TO_GET_INFORMATION_DATA_SAGA = "CALL_API_TO_GET_INFORMATION_DATA_SAGA_record_produc";
export const CALL_API_TO_GET_INFORMATION_DATA_REDUCER = "CALL_API_TO_GET_INFORMATION_DATA_REDUCER_record_produc";
export const CALL_API_TO_GET_INFORMATION_DATA_DONE = "CALL_API_TO_GET_INFORMATION_DATA_DONE_record_produc";
// gọi api để thực hiện ghi nhận kết quả
export const CALL_API_RECORD_PRODUCT_EXCUTE_DATA_SAGA = "CALL_API_RECORD_PRODUCT_EXCUTE_DATA_SAGA_record_produc";
export const CALL_API_RECORD_PRODUCT_EXCUTE_DATA_REDUCER = "CALL_API_RECORD_PRODUCT_EXCUTE_DATA_REDUCER_record_produc";
export const CALL_API_RECORD_PRODUCT_EXCUTE_DATA_DONE = "CALL_API_RECORD_PRODUCT_EXCUTE_DATA_DONE_record_produc";
// gọi api để lấy thông tin workorder
export const GET_WORK_ORDER_BY_CODE_SAGA = "GET_WORK_ORDER_BY_CODE_SAGA_record_produc";
export const GET_WORK_ORDER_BY_CODE_REDUCER = "GET_WORK_ORDER_BY_CODE_REDUCER_record_produc";
export const GET_WORK_ORDER_BY_CODE_DONE = "GET_WORK_ORDER_BY_CODE_DONE_record_produc";
// gọi api để lấy thông tin workorder
export const GET_MACHINE_SAGA = "GET_MACHINE_SAGA_record_produc";
export const GET_MACHINE_REDUCER = "GET_MACHINE_REDUCER_record_produc";
export const GET_MACHINE_DONE = "GET_MACHINE_DONE_record_produc";
//---------------------------------------------------------------------
export const SET_REDUCER_TO_DEFAULT = "SET_REDUCER_TO_DEFAULT_record_produc";
export const SET_UPDATE_INFORMATION = "SET_UPDATE_INFORMATION_record_produc";
