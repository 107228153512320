import { FALSE } from "sass";
import {
  GET_PAGING_EXPORT_USAGE_REDUCER,
  CALL_DEFAULT_REDUCER,
  SET_TRANSACTION_DETAIL_LIST_REDUCER,
  CLEAR_DATA_REDUCER,
  CREATE_OR_UPDATE_EXPORT_USAGE_SUCCESSED_REDUCER,
  SET_MEDICAL_SUPPLIES_LIST_REDUCER,
  DELETE_EXPORT_USAGE_SUCCESSED_REDUCER,
  GET_DETAIL_EXPORT_USAGE_SUCCESSED_REDUCER,
  SET_DETAIL_DATA_DEFAULT_REDUCER,
  SET_HOSPITAL_ID_BY_EXPORT_USAGE_REDUCER,
  SET_HOSPITALID_AND_WAREHOUSEID_REDUCER,
  SET_MEDICAL_SUPPLIES_LIST_DELETED_REDUCER,
  CANCEL_EXPORT_USAGE_SUCCESSED_REDUCER,
  GET_PATIENT_INFO_REDUCER,
  GET_PURCHASE_ORDER_BY_CODE_REDUCER,
  GET_BY_ID_EXPORT_USAGE_REDUCER,
  RESET_GET_BY_ID_EXPORT_USAGE_REDUCER,
  RESET_GET_PAGING_EXPORT_USAGE_REDUCER,
  RESET_DELETE_EXPORT_USAGE_REDUCER,
} from "./enum/TypeExportUsageAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,
  isCreateUpdateSuccess: false,
  isGetDetailDateSuccess: false,
  isCancelSuccess: false,
  isDeleteSuccess: false,
  isGetInforATransaction: null,
  inforATransaction: null, //Thông tin chi tiết của một transaction(phiếu xuất, adjustment, stockcheck)
  //Danh sách vật tư y tế của từng phiếu xuất, gói tiêu hao, dùng chung
  listDetail: [],
  listProductSyncFail: [], //Danh sách vật tư hủy giao dịch đồng bộ lên OH bị fail
  //Danh sách tất cả VTYT 
  listMedicalSupplies: [],
  //Danh sách vtyt bị xóa khỏi form thêm/cập nhật
  listMedicalSuppliesDeleted: [],
  //Thông tin bệnh nhân
  lstPatientInfo: [],
  getLstPatientInfoSuccessed: false
};

const ExportUsageReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_EXPORT_USAGE_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
      case RESET_GET_PAGING_EXPORT_USAGE_REDUCER:
        state.isGetLstData = false;
      return { ...state };
    case GET_BY_ID_EXPORT_USAGE_REDUCER:
      if (action.data) {
        state.isGetInforATransaction = true;
        state.inforATransaction = action.data.Data;
      }
      return { ...state };
    case GET_DETAIL_EXPORT_USAGE_SUCCESSED_REDUCER:
      if (action.data) {
        state.isGetDetailDateSuccess = true;
        state.listDetail = action.data.data;
      }
      return { ...state };
    case GET_PATIENT_INFO_REDUCER:
      if (action.data) {
        state.lstPatientInfo = action.data.data;
        state.getLstPatientInfoSuccessed = true;
      }
      return { ...state };

    //Set giá trị mã bệnh viện và mã kho, dùng để tìm kiếm vtyt trong theo kho của bệnh viện
    case SET_HOSPITALID_AND_WAREHOUSEID_REDUCER:
      if (action.data) {

        state.hospitalId = action.data.hospitalId;
        state.warehouseId = action.data.warehouseId;
      }
      return { ...state }
    case SET_DETAIL_DATA_DEFAULT_REDUCER:
      state.isGetDetailDateSuccess = false;
      return { ...state };
    case DELETE_EXPORT_USAGE_SUCCESSED_REDUCER:
      state.isDeleteSuccess = true;
      return { ...state };
    case RESET_DELETE_EXPORT_USAGE_REDUCER:
      state.isDeleteSuccess = false;
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.listDataWarehouse = [];
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      state.getLstPatientInfoSuccessed = false;

      return {
        ...state,
      };

    case SET_TRANSACTION_DETAIL_LIST_REDUCER:
      if (action.data)
        state.listDetail = action.data;
      return { ...state };

    case SET_MEDICAL_SUPPLIES_LIST_REDUCER:
      if (action.data)
        state.listMedicalSupplies = action.data;
      return { ...state };
    case SET_MEDICAL_SUPPLIES_LIST_DELETED_REDUCER:
      if (action.data)
        state.listMedicalSuppliesDeleted = action.data;
      return { ...state };
    case CREATE_OR_UPDATE_EXPORT_USAGE_SUCCESSED_REDUCER:
      state.isCreateUpdateSuccess = true;
      return { ...state };
    case CANCEL_EXPORT_USAGE_SUCCESSED_REDUCER:
      state.isCancelSuccess = true;
      if (action.data)
        state.listProductSyncFail = action.data;
      return { ...state };
    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };
    case GET_PURCHASE_ORDER_BY_CODE_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case RESET_GET_BY_ID_EXPORT_USAGE_REDUCER:
      state.isGetInforATransaction = false;
      return { ...state };
    default:
      return { ...state };
  }
};

export default ExportUsageReducer;
