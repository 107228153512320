import {
  GET_PAGING_MEDICAL_MATERIAL_SAGA,
  GET_LIST_MEDICAL_MATERIAL_SAGA,
  GET_LIST_MEDICAL_MATERIAL_REDUCER,
  GET_PAGING_MEDICAL_MATERIAL_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
} from "./enum/TypeCatMedicalMaterialAction";

const CatMedicalMaterialAction = {
  getPagingSaga: (pageIndex, pageSize, searchTerm = "", unitCode = "") => {
    return {
      type: GET_PAGING_MEDICAL_MATERIAL_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
      unitCode: unitCode,
    };
  },
  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_MEDICAL_MATERIAL_REDUCER,
      data: data,
    };
  },
  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },

}

export default CatMedicalMaterialAction;
