export const FakeLogManagementData = [
    {
        "Id": 1,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 16:53:22.612567",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 2,
        "Type": "InsertWarehouseProduct",
        "Url": "https://mocki.io/v1/2265f78c-4331-428a-bee6-a1735414bc3f",
        "Request": "",
        "Response": "{\"store_items\":{\"store_item\":[{\"store_item_id\":\"8A3F31E2-89AB-496C-4154-0000000A93D5\",\"store_id\":\"72418809-6D32-4A20-B549-0D565C9068FB\",\"item_id\":\"D19E3A27-96A8-4D51-8E92-F9AD03625B07\",\"qty_on_hand\":\"9.0000\",\"locked_flag\":\"0\",\"reorder_point_qty\":\"0\",\"max_qty\":\"5\",\"back_order_qty\":\"0\",\"bin_location\":\"\",\"pending_refill_request_qty\":\"0\",\"pending_issues_out\":\"0\",\"replenishment_type_rcd\":\"N\",\"pending_issues_in\":\"0\",\"company_code\":\"HHN\",\"tarrget_qty\":\"5\",\"in_transit_cost\":\"0\",\"stock_check_locked_flag\":\"0\",\"stock_uom_rcd\":\"Vial\",\"min_qty\":\"0\",\"in_transit_qty\":\"0\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:04:56.165419",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 3,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Start request",
        "Response": "",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:31:32.818503",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 4,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "",
        "Response": "{\"items\":{\"item\":[{\"item_id\":\"FA814405-AB08-4AF6-8080-001FCC640E53\",\"item_group_id\":\"3AE1774F-10C5-4B14-9488-0BFB2A245FB9\",\"item_code\":\"BA.01.01.01.1031\",\"item_name_e\":\"Đầu thắt tĩnh mạch thực quản\",\"item_name_l\":\"Đầu thắt tĩnh mạch thực quản\",\"uom\":\"UM81\",\"item_type_rcd\":\"INV\",\"sub_item_type_rcd\":\"STK\",\"user_chargeable_flag\":\"1\",\"variable_price_flag\":\"0\",\"mandatory_caregiver_flat\":\"0\",\"ipd_chargeable_flag\":\"1\",\"opd_chargeable_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:33:19.306154",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 5,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Start request",
        "Response": "",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:34:26.351513",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 6,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Start request",
        "Response": "",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:35:23.546886",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 7,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Start request",
        "Response": "",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:36:11.085015",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 8,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Start request",
        "Response": "",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:37:32.739852",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 9,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Start request",
        "Response": "",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:38:01.928575",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 10,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Start request",
        "Response": "",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:38:18.456657",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 11,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Start request",
        "Response": "",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:41:37.730862",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 12,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "",
        "Response": "{\"items\":{\"item\":[{\"item_id\":\"FA814405-AB08-4AF6-8080-001FCC640E53\",\"item_group_id\":\"3AE1774F-10C5-4B14-9488-0BFB2A245FB9\",\"item_code\":\"BA.01.01.01.1031\",\"item_name_e\":\"Đầu thắt tĩnh mạch thực quản\",\"item_name_l\":\"Đầu thắt tĩnh mạch thực quản\",\"uom\":\"UM81\",\"item_type_rcd\":\"INV\",\"sub_item_type_rcd\":\"STK\",\"user_chargeable_flag\":\"1\",\"variable_price_flag\":\"0\",\"mandatory_caregiver_flat\":\"0\",\"ipd_chargeable_flag\":\"1\",\"opd_chargeable_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-21 17:41:51.246320",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 13,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-22 12:00:30.613810",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 14,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "",
        "Response": "{\"items\":{\"item\":[{\"item_id\":\"FA814405-AB08-4AF6-8080-001FCC640E53\",\"item_group_id\":\"3AE1774F-10C5-4B14-9488-0BFB2A245FB9\",\"item_code\":\"BA.01.01.01.1031\",\"item_name_e\":\"Đầu thắt tĩnh mạch thực quản\",\"item_name_l\":\"Đầu thắt tĩnh mạch thực quản\",\"uom\":\"UM81\",\"item_type_rcd\":\"INV\",\"sub_item_type_rcd\":\"STK\",\"user_chargeable_flag\":\"1\",\"variable_price_flag\":\"0\",\"mandatory_caregiver_flat\":\"0\",\"ipd_chargeable_flag\":\"1\",\"opd_chargeable_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:40:17.795855",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 15,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:40:17.795852",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 16,
        "Type": "InsertWarehouseProduct",
        "Url": "https://mocki.io/v1/2265f78c-4331-428a-bee6-a1735414bc3f",
        "Request": "",
        "Response": "{\"store_items\":{\"store_item\":[{\"store_item_id\":\"8A3F31E2-89AB-496C-4154-0000000A93D5\",\"store_id\":\"72418809-6D32-4A20-B549-0D565C9068FB\",\"item_id\":\"D19E3A27-96A8-4D51-8E92-F9AD03625B07\",\"qty_on_hand\":\"9.0000\",\"locked_flag\":\"0\",\"reorder_point_qty\":\"0\",\"max_qty\":\"5\",\"back_order_qty\":\"0\",\"bin_location\":\"\",\"pending_refill_request_qty\":\"0\",\"pending_issues_out\":\"0\",\"replenishment_type_rcd\":\"N\",\"pending_issues_in\":\"0\",\"company_code\":\"HHN\",\"tarrget_qty\":\"5\",\"in_transit_cost\":\"0\",\"stock_check_locked_flag\":\"0\",\"stock_uom_rcd\":\"Vial\",\"min_qty\":\"0\",\"in_transit_qty\":\"0\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:40:18.650444",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 17,
        "Type": "InsertWarehouseProduct",
        "Url": "https://mocki.io/v1/2265f78c-4331-428a-bee6-a1735414bc3f",
        "Request": "Không thể đồng bộ các bản ghi này do không tìm thấy dữ liệu Kho và VTYT được đồng bộ trước đó. Cần đồng bộ các dữ liệu Kho và VTYT trước",
        "Response": "8A3F31E2-89AB-496C-4154-0000000A93D5",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:40:18.742447",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 18,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:40:21.583481",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 19,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:45:39.320581",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 20,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:46:20.537944",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 21,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:46:37.834412",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 22,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:47:18.362930",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 23,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:48:08.326066",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 24,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:50:23.484277",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 25,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:50:37.487629",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 26,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 09:52:10.656720",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 27,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 10:02:08.351487",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 28,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Có lỗi xảy ra trong quá trình ghi dữ liệu!",
        "Response": "Keyword not supported: 'port'.||   at Microsoft.Data.Common.DbConnectionOptions.ParseInternal(Dictionary`2 parsetable, String connectionString, Boolean buildChain, Dictionary`2 synonyms, Boolean firstKey)\r\n   at Microsoft.Data.Common.DbConnectionOptions..ctor(String connectionString, Dictionary`2 synonyms)\r\n   at Microsoft.Data.SqlClient.SqlConnectionString..ctor(String connectionString)\r\n   at Microsoft.Data.SqlClient.SqlConnectionFactory.CreateConnectionOptions(String connectionString, DbConnectionOptions previous)\r\n   at Microsoft.Data.ProviderBase.DbConnectionFactory.GetConnectionPoolGroup(DbConnectionPoolKey key, DbConnectionPoolGroupOptions poolOptions, DbConnectionOptions& userConnectionOptions)\r\n   at Microsoft.Data.SqlClient.SqlConnection.ConnectionString_Set(DbConnectionPoolKey key)\r\n   at Microsoft.Data.SqlClient.SqlConnection.set_ConnectionString(String value)\r\n   at Microsoft.Data.SqlClient.SqlConnection..ctor(String connectionString)\r\n   at VMS_Hangfire.Services.CatWarehouseServices.InsertOrUpdateBatch() in D:\\Projects\\Namek_VTYT\\VMS_Hangfire\\VMS_Hangfire\\VMS_Hangfire\\Services\\CatWarehouseServices.cs:line 85",
        "IsSuccess": false,
        "CreateAt": "2024-02-26 14:22:46.436306",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 29,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Có lỗi xảy ra trong quá trình ghi dữ liệu!",
        "Response": "Parameter '@Id_1' must have its value set||   at Npgsql.NpgsqlParameter.ResolveHandler(ConnectorTypeMapper typeMapper)\r\n   at Npgsql.NpgsqlCommand.ValidateParameters(ConnectorTypeMapper typeMapper)\r\n   at Npgsql.NpgsqlCommand.ExecuteReader(CommandBehavior behavior, Boolean async, CancellationToken cancellationToken)\r\n   at Npgsql.NpgsqlCommand.ExecuteReader(CommandBehavior behavior, Boolean async, CancellationToken cancellationToken)\r\n   at Npgsql.NpgsqlCommand.ExecuteNonQuery(Boolean async, CancellationToken cancellationToken)\r\n   at Npgsql.NpgsqlCommand.ExecuteNonQuery()\r\n   at VMS_Hangfire.Services.CatWarehouseServices.InsertOrUpdateBatch() in D:\\Projects\\Namek_VTYT\\VMS_Hangfire\\VMS_Hangfire\\VMS_Hangfire\\Services\\CatWarehouseServices.cs:line 123",
        "IsSuccess": false,
        "CreateAt": "2024-02-26 15:21:16.528408",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 30,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Thêm mới thành công!",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 16:19:26.706265",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 31,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Có lỗi xảy ra trong quá trình ghi dữ liệu!",
        "Response": "Connection already open||   at Npgsql.NpgsqlConnection.Open(Boolean async, CancellationToken cancellationToken)\r\n   at Npgsql.NpgsqlConnection.Open()\r\n   at VMS_Hangfire.Services.CatWarehouseServices.InsertOrUpdateBatch() in D:\\Projects\\Namek_VTYT\\VMS_Hangfire\\VMS_Hangfire\\VMS_Hangfire\\Services\\CatWarehouseServices.cs:line 130",
        "IsSuccess": false,
        "CreateAt": "2024-02-26 16:35:05.683068",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 32,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Có lỗi xảy ra trong quá trình ghi dữ liệu!",
        "Response": "42601: syntax error at end of input||   at Npgsql.NpgsqlConnector.<ReadMessage>g__ReadMessageLong|194_0(NpgsqlConnector connector, Boolean async, DataRowLoadingMode dataRowLoadingMode, Boolean readingNotifications, Boolean isReadingPrependedMessage)\r\n   at Npgsql.NpgsqlDataReader.NextResult(Boolean async, Boolean isConsuming, CancellationToken cancellationToken)\r\n   at Npgsql.NpgsqlDataReader.NextResult()\r\n   at Npgsql.NpgsqlCommand.ExecuteReader(CommandBehavior behavior, Boolean async, CancellationToken cancellationToken)\r\n   at Npgsql.NpgsqlCommand.ExecuteReader(CommandBehavior behavior, Boolean async, CancellationToken cancellationToken)\r\n   at Npgsql.NpgsqlCommand.ExecuteNonQuery(Boolean async, CancellationToken cancellationToken)\r\n   at Npgsql.NpgsqlCommand.ExecuteNonQuery()\r\n   at VMS_Hangfire.Services.CatWarehouseServices.InsertOrUpdateBatch() in D:\\Projects\\Namek_VTYT\\VMS_Hangfire\\VMS_Hangfire\\VMS_Hangfire\\Services\\CatWarehouseServices.cs:line 130",
        "IsSuccess": false,
        "CreateAt": "2024-02-26 16:40:30.256428",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 33,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Thêm mới thành công!",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-26 16:42:32.706917",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 34,
        "Type": "InsertWarehouseProduct",
        "Url": "https://mocki.io/v1/2265f78c-4331-428a-bee6-a1735414bc3f",
        "Request": "Thêm mới thành công!",
        "Response": "{\"store_items\":{\"store_item\":[{\"store_item_id\":\"8A3F31E2-89AB-496C-4154-0000000A93D5\",\"store_id\":\"72418809-6D32-4A20-B549-0D565C9068FB\",\"item_id\":\"D19E3A27-96A8-4D51-8E92-F9AD03625B07\",\"qty_on_hand\":\"9.0000\",\"locked_flag\":\"0\",\"reorder_point_qty\":\"0\",\"max_qty\":\"5\",\"back_order_qty\":\"0\",\"bin_location\":\"\",\"pending_refill_request_qty\":\"0\",\"pending_issues_out\":\"0\",\"replenishment_type_rcd\":\"N\",\"pending_issues_in\":\"0\",\"company_code\":\"HHN\",\"tarrget_qty\":\"5\",\"in_transit_cost\":\"0\",\"stock_check_locked_flag\":\"0\",\"stock_uom_rcd\":\"Vial\",\"min_qty\":\"0\",\"in_transit_qty\":\"0\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-29 11:52:10.327784",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 35,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Thêm mới thành công!",
        "Response": "{\"items\":{\"item\":[{\"item_id\":\"FA814405-AB08-4AF6-8080-001FCC640E53\",\"item_group_id\":\"3AE1774F-10C5-4B14-9488-0BFB2A245FB9\",\"item_code\":\"BA.01.01.01.1031\",\"item_name_e\":\"Đầu thắt tĩnh mạch thực quản\",\"item_name_l\":\"Đầu thắt tĩnh mạch thực quản\",\"uom\":\"UM81\",\"item_type_rcd\":\"INV\",\"sub_item_type_rcd\":\"STK\",\"user_chargeable_flag\":\"1\",\"variable_price_flag\":\"0\",\"mandatory_caregiver_flat\":\"0\",\"ipd_chargeable_flag\":\"1\",\"opd_chargeable_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-02-29 11:52:10.259195",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 36,
        "Type": "InsertWarehouseProduct",
        "Url": "https://mocki.io/v1/2265f78c-4331-428a-bee6-a1735414bc3f",
        "Request": "Không thể đồng bộ các bản ghi này do không tìm thấy dữ liệu Kho và VTYT được đồng bộ trước đó. Cần đồng bộ các dữ liệu Kho và VTYT trước!",
        "Response": "8A3F31E2-89AB-496C-4154-0000000A93D5",
        "IsSuccess": true,
        "CreateAt": "2024-02-29 11:52:10.496824",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 37,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Thêm mới thành công!",
        "Response": "{\"items\":{\"item\":[{\"item_id\":\"FA814405-AB08-4AF6-8080-001FCC640E53\",\"item_group_id\":\"3AE1774F-10C5-4B14-9488-0BFB2A245FB9\",\"item_code\":\"BA.01.01.01.1031\",\"item_name_e\":\"Đầu thắt tĩnh mạch thực quản\",\"item_name_l\":\"Đầu thắt tĩnh mạch thực quản\",\"uom\":\"UM81\",\"item_type_rcd\":\"INV\",\"sub_item_type_rcd\":\"STK\",\"user_chargeable_flag\":\"1\",\"variable_price_flag\":\"0\",\"mandatory_caregiver_flat\":\"0\",\"ipd_chargeable_flag\":\"1\",\"opd_chargeable_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:17:14.791575",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 38,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Thêm mới thành công!",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:17:14.774553",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 39,
        "Type": "InsertWarehouseProduct",
        "Url": "https://mocki.io/v1/2265f78c-4331-428a-bee6-a1735414bc3f",
        "Request": "Thêm mới thành công!",
        "Response": "{\"store_items\":{\"store_item\":[{\"store_item_id\":\"8A3F31E2-89AB-496C-4154-0000000A93D5\",\"store_id\":\"72418809-6D32-4A20-B549-0D565C9068FB\",\"item_id\":\"D19E3A27-96A8-4D51-8E92-F9AD03625B07\",\"qty_on_hand\":\"9.0000\",\"locked_flag\":\"0\",\"reorder_point_qty\":\"0\",\"max_qty\":\"5\",\"back_order_qty\":\"0\",\"bin_location\":\"\",\"pending_refill_request_qty\":\"0\",\"pending_issues_out\":\"0\",\"replenishment_type_rcd\":\"N\",\"pending_issues_in\":\"0\",\"company_code\":\"HHN\",\"tarrget_qty\":\"5\",\"in_transit_cost\":\"0\",\"stock_check_locked_flag\":\"0\",\"stock_uom_rcd\":\"Vial\",\"min_qty\":\"0\",\"in_transit_qty\":\"0\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:17:15.166680",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 40,
        "Type": "InsertWarehouseProduct",
        "Url": "https://mocki.io/v1/2265f78c-4331-428a-bee6-a1735414bc3f",
        "Request": "Không thể đồng bộ các bản ghi này do không tìm thấy dữ liệu Kho và VTYT được đồng bộ trước đó. Cần đồng bộ các dữ liệu Kho và VTYT trước!",
        "Response": "8A3F31E2-89AB-496C-4154-0000000A93D5",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:17:15.227841",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 41,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Thêm mới thành công!",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:17:15.313710",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 42,
        "Type": "InsertProduct",
        "Url": "https://mocki.io/v1/42bf2703-0f07-4bc8-b9a3-84f07454e648",
        "Request": "Thêm mới thành công!",
        "Response": "{\"items\":{\"item\":[{\"item_id\":\"FA814405-AB08-4AF6-8080-001FCC640E53\",\"item_group_id\":\"3AE1774F-10C5-4B14-9488-0BFB2A245FB9\",\"item_code\":\"BA.01.01.01.1031\",\"item_name_e\":\"Đầu thắt tĩnh mạch thực quản\",\"item_name_l\":\"Đầu thắt tĩnh mạch thực quản\",\"uom\":\"UM81\",\"item_type_rcd\":\"INV\",\"sub_item_type_rcd\":\"STK\",\"user_chargeable_flag\":\"1\",\"variable_price_flag\":\"0\",\"mandatory_caregiver_flat\":\"0\",\"ipd_chargeable_flag\":\"1\",\"opd_chargeable_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:17:33.554757",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 43,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Thêm mới thành công!",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:21:51.050778",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 44,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Thêm mới thành công!",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:24:35.176388",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    },
    {
        "Id": 45,
        "Type": "InsertWarehouse",
        "Url": "https://mocki.io/v1/6fe38e00-d853-4c85-9e73-9f0bc035b4fa",
        "Request": "Thêm mới thành công!",
        "Response": "{\"stores\":{\"store\":[{\"store_id\":\"A515DA0F-C133-4852-8E12-05A7FB487D06\",\"store_code\":\"HHN_S023\",\"store_name_l\":\"HHN_Kho xét nghiệm huyết học\",\"store_name_e\":\"HHN_Kho xét nghiệm huyết học\",\"costcentre_id\":\"09C3DF51-1D49-4C45-A412-B0FB9935F9A4\",\"costcentre_code\":\"1100.15555\",\"company_code\":\"HHN\",\"allow_sales_flag\":\"1\",\"lock_during_stock_check_flag\":\"1\",\"automatic_store_issue_receipt_flag\":\"0\",\"store_type_rcd\":\"S\",\"allow_negative_stock_flag\":\"1\"}]}}",
        "IsSuccess": true,
        "CreateAt": "2024-03-01 17:35:09.027934",
        "CreatedBy": "00000000-0000-0000-0000-000000000000",
        "UpdateAt": null,
        "UpdateBy": null,
        "IsDeleted": false
    }
]