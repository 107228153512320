export const UnitCoversionLag = {
    language: "en",
    UnitCoversion: {
        title: "Conversion table",
        table: {
            Materialcode: "Parts code",
            Materialname: "Part name",
            Unitfromname: "Units to be converted",
            Unittoname: "Units to convert to",
            Quantityfrom: "Amount of conversion",
            Quantityto: "Amount received",
            Status: "Active",
            Action: "Action",
        },
        search: {
            placeHolder: "Search by Component Code, component name, unit to convert, need to convert, unit to convert to",
        },
        form: {
            create: "Create a new unit conversion",
            update: "Update unit conversion",
            getDetail: "Unit conversion details",
            Materialcode: "Accessory",
            Unitfrom: "Unit",
            Unitto: "Units to convert to",
            Quantityfrom: "mount of conversion",
            Quantityto: "Amount received",
            Status: "Active",
            placeHolder: {
                Materialcode: "Select components",
                Unitfrom: "Select the unit to convert",
                Unitto: "Choose the unit to convert to",
                Quantityfrom: "Enter the amount to convert",
                Quantityto: "Enter the amount received",
            }
        },
        delete: {
            title: "Delete conversion",
            description: "Are you sure you want to delete this redemption?",
        },
        error: {
            Materialcode: {
                character: "Model code is not in the correct format",
                required: "Components cannot be left blank",
            },
            Unitfrom: {
                required: "Unit is left blank",
            },
            Unitto: {
                required: "Units to convert to cannot be left blank",
            },
            Quantityfrom: {
                required: "The number of redemptions cannot be left blank",
            },
            Quantityto: {
                required: "The amount received cannot be left blank",
            },
        },
        toastJS: {
            creatSuccess: "Create new conversion successfully",
            updateSuccess: "Successful conversion update",
            deleteSuccess: "uccessfully deleted the exchange",
        }
    }
}