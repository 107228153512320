export const GET_PAGING_CAT_WAREHOUSE_PRODUCT_SAGA = "GET_PAGING_CAT_WAREHOUSE_PRODUCT_SAGA";
export const GET_PAGING_PRODUCT_BY_ROLE_USER_SAGA = "GET_PAGING_PRODUCT_BY_ROLE_USER_SAGA";
export const UPDATE_CAT_WAREHOUSE_PRODUCT_SAGA = "UPDATE_CAT_WAREHOUSE_PRODUCT_SAGA";
export const SEARCH_PRODUCT_BY_CODEOH_SAGA = "SEARCH_PRODUCT_BY_CODEOH_SAGA";
export const GET_DETAIL_CAT_WAREHOUSE_PRODUCT_SAGA ="GET_DETAIL_CAT_WAREHOUSE_PRODUCT_SAGA";

export const GET_PAGING_CAT_WAREHOUSE_PRODUCT_REDUCER = "GET_PAGING_CAT_WAREHOUSE_PRODUCT_REDUCER";
export const GET_PAGING_PRODUCT_BY_ROLE_USER_REDUCER = "GET_PAGING_PRODUCT_BY_ROLE_USER_REDUCER";
export const CALL_UPDATE_SUCCESS_REDUCER = "CALL_UPDATE_SUCCESS_REDUCER";
export const CALL_UPDATE_FAIL_REDUCER = "CALL_UPDATE_FAIL_REDUCER";
export const SEARCH_PRODUCT_BY_CODEOH_SUSSESS_REDUCER = "SEARCH_PRODUCT_BY_CODEOH_SUSSESS_REDUCER";
export const RESET_SEARCH_PRODUCT_BY_CODEOH_REDUCER = "RESET_SEARCH_PRODUCT_BY_CODEOH_REDUCER";
export const EXPORT_LIST_DATA_SAGA = "EXPORT_LIST_DATA_SAGA";
export const EXPORT_REPORT_BY_LIST_ID_SAGA = "EXPORT_REPORT_BY_LIST_ID_SAGA";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
export const LOAD_MORE_DATA = "LOAD_MORE_DATA";
export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const GET_PAGING_BY_PLANNING_SAGA = "GET_PAGING_BY_PLANNING_SAGA";
export const GET_PAGING_BY_PANNING_ID_REDUCER = "GET_PAGING_BY_PANNING_ID_REDUCER";
export const CALL_DEFAULT_LST_DATA_BY_PLANING_REDUCER = "CALL_DEFAULT_LST_DATA_BY_PLANING_REDUCER";
export const CALL_CLEAR_DETAIL = "CALL_CLEAR_DETAIL";
export const GET_DETAIL_CAT_WAREHOUSE_PRODUCT_REDUCER = "GET_DETAIL_CAT_WAREHOUSE_PRODUCT_REDUCER";