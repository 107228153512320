import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    API_GET_REPORT_TRANSACTION,
    API_EXPORT_REPORT_TRANSACTION,
    API_GET_REPORT_RECONCILE,
    API_EXPORT_REPORT_RECONCILE,
    API_GET_REPORT_INVENTORY,
    API_EXPORT_REPORT_INVENTORY,
    API_GET_SUM_REPORT
} from "./enum/TypeReportAPI";

export async function getReportTransactionService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        API_GET_REPORT_TRANSACTION,
        data
    )
}
export async function exportReportTransactionService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + 
        API_EXPORT_REPORT_TRANSACTION, 
        data
    )
}
export async function getReportReconcileService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        API_GET_REPORT_RECONCILE,
        data
    )
}
export async function exportReportReconcileService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + 
        API_EXPORT_REPORT_RECONCILE, 
        data
    )
}
export async function getReportInventoryService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        API_GET_REPORT_INVENTORY,
        data
    )
}
export async function exportReportInventoryService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + 
        API_EXPORT_REPORT_INVENTORY, 
        data
    )
}

// export async function getSumReportService(data) {
//     return await CommonBase.postAsync(
//         configApp.apiGateWay +
//         API_GET_SUM_REPORT + "?typeReport=" + data?.typeReport,
//         data.requestParams
//     )
// }
