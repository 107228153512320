export const GET_ASSETMENT_INVENTORY_RESULTS_API = "/api/service/InventoryPlan/SearchResultInventoryPlan";
export const GET_DETAIL_INVENTORY_RESULTS_API = "/api/service/InventoryPlan/SearchResultInventoryPlanSessionItem";
export const GET_ASSETMENT_INVENTORY_INFO_API = "/api/service/InventoryPlan/SearchResultInventoryAsset";
export const UPDATE_ASSETMENT_INVENTORY_INFO_API = "/api/service/InventoryPlan/PopupUpdateInfoInventory";
export const GET_SUMMARY_RESULT_INVENTORY_API = "/api/service/InventoryPlan/GetResultInventoryAssetById";
export const GET_INVENTORY_ASSET_SCAN_API = "/api/service/InventoryPlan/GetInventoryAssetScan";
export const INVENTORY_ASSET_SUBMIT_REPORT_API = "/api/service/InventoryPlan/CreateReportInventory";
export const INVENTORY_COMPLETION_CONFIRMATION_API = "/api/service/InventoryPlan/ConfirmCompletedInventory";
export const CREATE_MANUAL_INVENTORY_ASSET_API = "/api/service/InventoryPlan/CreateManualInventoryAsset";
export const EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_API = "/api/service/InventoryPlan/InventoryResultDetailExportExcel";
export const EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_API = "/api/service/InventoryPlan/InventoryAssetInformationExportExcel";