export const ApproveStepLaguage = {
  language: "en",
  ApproveStepLaguage: {
    table: {
      name: 'Name of step',
      creator: 'Creator'
    },
    search: {
      placeHolder:
        "Search by name, creator",
    },
    delete: {
      title: 'Delete the approval process',
      comment: 'Are you sure you want to delete this approval process?'
    },
    title: 'Select new approval step',
    form: {
      title_delete: 'Delete approval',
      confirm_text: 'Are you sure delete this approval?',
      name: 'Name of step',
      name_placeHolder: 'Enter step name',
      approveType: 'Approve type',
      approveType_placeHolder: 'Choose approve type',
      btn_add: "Add new",
      btn_close: 'Close',
      table: {
        level: 'Level',
        name_level: 'Level name',
        placeHolderNameLevel: 'Enter level name',
        department: 'Department',
        approveBy: 'Approve by',
        area_placeHolder: "Select production area"
      }
    },
    approval_process: 'Create new approval process',
    approval_process_update: 'Update approval process',
    errors: {
      nameEmpty: 'Name of step not empty',
      nameMaxLength: 'Name of step no longer than 255 character',
      approveTypeEmpty: 'Approve type not empty',
      levelNameEmpty: 'Level name not empty',
      departmentEmpty: 'Department not empty',
      approveBy: 'Approver not empty',
      approve_type: "Approval type cannot be left blank",
      levelNameLength: 'Level name cannot be longer than 255 characters',
    },
    msg: {
      createSuccess: 'Create approve default success',
      updateSuccess: 'Update approve default success',
      deleteSuccess: 'Delete approve default success',
    }
  },
};
