import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_ALL_APPROVE_DEFAULT_API,
  CREATE_APPROVE_DEFAULT_API,
  UPDATE_APPROVE_DEFAULT_API,
  DELETE_APPROVE_DEFAULT,
  GET_DETAIL_APPROVE_DEFAULT,
  CREATE_APPROVAL
} from "./enum/ApproveStepTypeApi";

export async function getPagingService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_ALL_APPROVE_DEFAULT_API,
    data
  );
}
export async function createApproveDefaultApi(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_APPROVE_DEFAULT_API,
    data
  );
}
export async function updateApproveDefaultApi(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_APPROVE_DEFAULT_API,
    data
  );
}
export async function deleteApproveDefaultApi(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_APPROVE_DEFAULT + data.Id,
    data
  );
}

export async function getDetailApproveApi(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_DETAIL_APPROVE_DEFAULT + data.id,
    data
  );
}

export async function createApprovalService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_APPROVAL,
    data
  );
}