export const GET_LIST_EXPORT_USAGE_API = "/api/service/Transaction/Search";
export const CREATE_EXPORT_USAGE_API = "/api/service/Transaction/CreateExportUsage";
export const UPDATE_EXPORT_USAGE_API = "/api/service/Transaction/UpdateExportUsage";
export const CANCEL_EXPORT_USAGE_API = "/api/service/Transaction/CancelTransactionById";
export const DELETE_EXPORT_USAGE_API = "/api/service/Transaction/DeleteExportUsage";
export const GET_DETAIL_EXPORT_USAGE_API = "/api/service/Transaction/GetDetailExportUsage";
export const GET_PATIENT_INFO_API = "/api/service/Transaction/GetPatientInfoFromOH";
export const GET_BY_ID_API = "/api/service/Transaction/GetById";
export const ISEXIST_EXPORT_USAGE_API =
    "/api/service/ExportUsage/IsExist";
export const GET_PURCHASE_ORDER_BY_CODE_API = "/api/service/Transaction/GetPurchaseOrderByCode";