export const GET_DROPDOWN_WORKORDER_SAGA = "GET_DROPDOWN_WORKORDER_SAGA";
export const SET_DROPDOWN_WORKORDER_REDUCER = "SET_DROPDOWN_WORKORDER_REDUCER";
export const CHANGE_SHIPMENT_PRODUCT_SAGA = "CHANGE_SHIPMENT_PRODUCT_SAGA";
export const ENFORCEMENT_PRODUCT_IDENTIFIER_SAGA = "ENFORCEMENT_PRODUCT_IDENTIFIER_SAGA";
export const CALL_API_EFORCEMENT_SUCCESS_REDUCER = "CALL_API_EFORCEMENT_SUCCESS_REDUCER";
export const GET_DATA_PAGING_PRODUCT_IDENTIFIER_SAGA = "GET_DATA_PAGING_PRODUCT_IDENTIFIER_SAGA";
export const SET_DATA_PAGING_PRODUCT_IDENTIFIER_REDUCER = 'SET_DATA_PAGING_PRODUCT_IDENTIFIER_REDUCER';
export const GET_DETAIL_PRODUCT_INDENTIER_SAGA = "GET_DETAIL_PRODUCT_INDENTIER_SAGA";
export const SET_DETAIL_PRODUCT_INDENTIER_REDUCER = "SET_DETAIL_PRODUCT_INDENTIER_REDUCER";
export const GET_LIST_DETAIL_PAGING_SAGA = "GET_LIST_DETAIL_PAGING_SAGA";
export const SET_LIST_DETAIL_PAGING_REDUCER = "SET_LIST_DETAIL_PAGING_REDUCER";
export const REPRINT_STAMPS_SAGA = "REPRINT_STAMPS_SAGA";
export const REPRINT_STAMPS_SUCCESS = "REPRINT_STAMPS_SUCCESS";
