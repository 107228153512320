import {

    GET_PAGING_CONFIG_PLANNING_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    CREATE_CONFIG_PLANNING_REDUCER,
    UPDATE_CONFIG_PLANNING_REDUCER,
    GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_REDUCER
} from "./enum/TypeConfigPlanningAction";

const stateDefault = {
    listData: [],
    isGetLstData: false,
    isDeleteSuccess: false,
    isCreateUpdateSuccess: false
};

const ConfigPlanningReducer = (state = stateDefault, action) => {
    switch (action.type) {
        case GET_PAGING_CONFIG_PLANNING_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.isGetLstData = true;
            }
            
            return { ...state };
        case CREATE_CONFIG_PLANNING_REDUCER:
            state.isCreateUpdateSuccess =  true
            return { ...state };
        case GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_REDUCER:
            state.listData =  action.data;
            state.isGetLstData = true;
            return { ...state };
            
        case UPDATE_CONFIG_PLANNING_REDUCER:
            state.isCreateUpdateSuccess =  true
            return { ...state };
        case CALL_DEFAULT_REDUCER:
            state.isGetLstData = false;
            state.isDeleteSuccess = false;
            state.isCreateUpdateSuccess = false;
            return {
                ...state,
            };

        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };
        default:
            return { ...state };
    }
};

export default ConfigPlanningReducer;
