import {
  CALL_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER,
  CLEAR_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER,
  CREATE_USER_HOSPITAL_WAREHOUSE_REDUCER,
  UPDATE_USER_HOSPITAL_WAREHOUSE_REDUCER,
  GET_USER_HOSPITAL_WAREHOUSE_BY_USER_REDUCER,
  GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_REDUCER,
  GET_USER_LIST_REDUCER,
  GET_HOSPITAL_WAREHOUSE_LIST_REDUCER,
  DELETE_USER_HOSPITAL_WAREHOUSE_REDUCER,
  GET_USER_LIST_BY_RULE_REDUCER,
} from "./enum/TypeUserHospitalWarehouseAction";

const stateDefault = {
  isCreateUpdateSuccess: false,
  isDeleteSuccess: false,
  dataUserHospitalWarehouse: {},
  dataUserHospitalWarehouseName: {},
  listUser: [],
  isGetListUser: false,
  listHospitalWarehouseByUserId: [], //Danh sách kho và bệnh viện của 1 user
  isGetListHospitalWarehouseByUserId: false,
  listUserByRule: [],
  isGetListUserByRule: false,
};

const UserHospitalWarehouseReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case CREATE_USER_HOSPITAL_WAREHOUSE_REDUCER:
      state.isCreateUpdateSuccess = true;
      return { ...state };
    case UPDATE_USER_HOSPITAL_WAREHOUSE_REDUCER:
      state.isCreateUpdateSuccess = true;
      return { ...state };
    case DELETE_USER_HOSPITAL_WAREHOUSE_REDUCER:
      state.isDeleteSuccess = true;
      return { ...state };
    case GET_USER_HOSPITAL_WAREHOUSE_BY_USER_REDUCER:
      state.dataUserHospitalWarehouse = action.data?.data;
      return { ...state };
    case GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_REDUCER:
      state.dataUserHospitalWarehouseName = action.data?.data;
      return { ...state };
    case GET_HOSPITAL_WAREHOUSE_LIST_REDUCER:
      state.listHospitalWarehouseByUserId = action.data?.data;
      state.isGetListHospitalWarehouseByUserId = true;
      return { ...state };
    case GET_USER_LIST_REDUCER:
      state.isGetListUser = true;
      state.listUser = action.data;
      return {
        ...state
      };
    case GET_USER_LIST_BY_RULE_REDUCER:
      state.isGetListUserByRule = true;
      state.listUserByRule = action.data;
      return {
        ...state
      };
    case CALL_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER:
      state.isCreateUpdateSuccess = false;
      state.isDeleteSuccess = false;
      state.isGetListUser = false;
      state.listUser = [];
      state.isGetListHospitalWarehouseByUserId = false;
      state.isGetListUserByRule = false;
      state.listHospitalWarehouseByUserId = [];
      return {
        ...state,
      };
    case CLEAR_DEFAULT_USER_HOSPITAL_WAREHOUSE_REDUCER:
      return {
        state: stateDefault,
      };

    default:
      return { ...state };
  }
};

export default UserHospitalWarehouseReducer;
