export const GET_DATA_REPAIR_PAGING_SAGA = "GET_DATA_REPAIR_PAGING_SAGA";
export const SET_PAGING_REDUCER = "SET_PAGING_REDUCER";
export const GET_CHANGE_STATUS_REPAIR_SAGA = "GET_CHANGE_STATUS_REPAIR_SAGA";
export const GET_DATA_REPAIR_DETAIL_SAGA = "GET_DATA_REPAIR_DETAIL_SAGA";
export const SET_DETAIL_REPAIR_REDUCER = "SET_DETAIL_REPAIR_REDUCER";
export const GET_DATA_BY_ITEM_CODE_SAGA = "GET_DATA_BY_ITEM_CODE_SAGA";
export const CALL_FALSE_DATA_BY_ITEM_CODE_REDUCER = "CALL_FALSE_DATA_BY_ITEM_CODE_REDUCER";
export const SET_DATA_BY_ITEM_CODE_REDUCER = "SET_DATA_BY_ITEM_CODE_REDUCER";
export const SET_DATA_BY_ITEM_CODE_ACTION_REDUCER = "SET_DATA_BY_ITEM_CODE_ACTION_REDUCER";
export const GET_DROPDOWN_STEP_REPAIR_SAGA = "GET_DROPDOWN_STEP_REPAIR_SAGA";
export const SET_DROPDOWN_STEP_REPAIR_REDUCER = "SET_DROPDOWN_STEP_REPAIR_REDUCER";
export const CREATE_ERROR_RECORDING_SAGA = "CREATE_ERROR_RECORDING_SAGA";
export const CALL_RESULT_FROM_REDUCER = "CALL_RESULT_FROM_REDUCER";
export const CALL_DEFAULT_RESULT_FROM_REDUCER = "CALL_DEFAULT_RESULT_FROM_REDUCER";
export const CREATE_REPAIR_ACTION_SAGA = "CREATE_REPAIR_ACTION_SAGA";
export const CALL_RESULT_ACTION_FROM_REDUCER = "CALL_RESULT_ACTION_FROM_REDUCER";
export const CALL_DEFAULT_RESULT_ACTION_FROM_REDUCER = "CALL_DEFAULT_RESULT_ACTION_FROM_REDUCER";
export const CLEAR_DATA_BY_ITEM_CODE_REPAIR_REDUCER = "CLEAR_DATA_BY_ITEM_CODE_REPAIR_REDUCER";
export const CALL_DEFAULT_RESULT_REDUCER = "CALL_DEFAULT_RESULT_REDUCER";