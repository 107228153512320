export const LotQA = {
    language: 'vn',
    lotQA: {
        slmauthanslinlot: "Số lượng mẫu không vượt quá số lượng sản phẩm trong lot",
        itempackage: "Item hoặc package ",
        existslot: " đã tồn tại trong LOT",
        needscan: "Bạn cần phải chọn lệnh sản xuất trước khi scan",
        enterqty: "Bạn cần nhập số lượng mẫu cần lấy",
        scaninlot: "Bạn cần phải Scan mã LOT hoặc Package",
        createLOT: "Tạo mới LOT QA",
        updateLOT: "Cập nhật LOT QA",
        seeLOT: "Xem chi tiết LOT QA",
        random: "Lấy mẫu ngẫu nhiên",
        scanitempackage: "Scan item code/ hoặc mã đóng gói để thêm vào danh sách lot",
        enteritemcode: "Nhập item code",
        createcompleted: "Tạo mới thành công",
        updatecompleted: "Cập nhật thành công",
    },
    LotSearch: {
        placeholder: 'Tìm kiếm theo Số lot QA , mã sản phẩm hoặc lệnh sản xuất',
        status: 'Trạng thái',
        timeFrom: 'Ngày đánh giá',
        timeTo: 'Đến ngày'
    },
    LotQATable: {
        numberLot: 'Số LOT QA',
        keyProduct: 'Mã sản phẩm',
        orderProduction: 'Lệnh sản xuất',
        quatityLot: 'Số lượng trong Lot',
        numberOfSamples: 'Số lượng mẫu',
        ramdumSampling: 'Lấy mẫu ngẫu nhiên',
        quantityChecked: 'Số lượng đã kiểm',
        createdateLot: 'Ngày tạo Lot',
        dateReview: 'Ngày đánh giá',
        action: 'Thao tác',
        Record:'Ghi nhận',
        Evaluate: 'Đánh giá'
    },
    LotForm: {
        enterLot: 'Nhập số lot',
        quantityChecked: 'Số lượng mẫu đã kiểm tra',
        chooseWO: 'Chọn WO',
        totalLot: 'Tổng số lượng trong LOT',
        quantityneed: 'Số lượng mẫu cần lấy',
        packingCode: 'Mã đóng gói',
        itemCode: 'Item Code',
        quantityneedPh: 'Nhập số lượng mẫu cần lấy',
    }
}