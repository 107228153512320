import {
  CALL_DEFAULT_PRODUCTION_REQUIRMENT_REDUCER,
  DELETE_PRODUCTION_REQUIRMENT_REDUCER,
  GET_PAGING_PRODUCTION_REQUIRMENT_REDUCER,
  GET_DETAIL_PRODUCTION_REQUIRMENT_REDUCER,
  CALL_RESULT_PRODUCTION_REQUIRMENT_REDUCER,
  FORCE_POPUP_UPDATE_DATA,
  CLOSE_POPUP_DELETE,
} from "./enum/typeProductRequirementAction";

const ProductionRequirementReducer = (
  state = {
    isFetching: false,
    isFetchingMore: false,
    listData: [],
    objectData: {},
    callResultFromApi: false,
    callResultDetail: false,
    isDeleteSuccess: false,
    isForcePopupDataUpdate: false,

    closeDeletePopupScreen: false,
  },
  action
) => {
  switch (action.type) {
    case GET_PAGING_PRODUCTION_REQUIRMENT_REDUCER:
      if (action.data) {
        state.listData = action.data;
      }
      return { ...state };

    case GET_DETAIL_PRODUCTION_REQUIRMENT_REDUCER:
      state.callResultDetail = true;
      if (action.obj) {
        state.objectData = action.obj.data;
      }
      return {
        ...state,
      };

    case CALL_RESULT_PRODUCTION_REQUIRMENT_REDUCER:
      state.callResultFromApi = true;
      return {
        ...state,
      };
    case FORCE_POPUP_UPDATE_DATA:
      state.isForcePopupDataUpdate = action.status;
      return {
        ...state,
      };

    case CALL_DEFAULT_PRODUCTION_REQUIRMENT_REDUCER:
      state.callResultFromApi = false;
      state.callResultDetail = false;
      state.isDeleteSuccess = false;
      state.objectData = null;
      return {
        ...state,
      };
    case DELETE_PRODUCTION_REQUIRMENT_REDUCER:
      state.isDeleteSuccess = true;
      return {
        ...state,
      };
    case CLOSE_POPUP_DELETE:

      state.closeDeletePopupScreen = action.status;
      return {
        ...state,
      };

    default:
      return { ...state };
  }
};

export default ProductionRequirementReducer;
