export const ItemAllocation = {
    language: "en",
    itemallocation: {
        messtatus: "MES status",
        granted: "Granted",
        printerstatus: "Printer status",
        waitingin: "Waiting to print",
        esoutput: "Estimated output: ",
        selectallo: 'Select allocation order',
        tatolqty: "Total BarCode allocated: ",
        printed: "Number of BarCodes printed: ",
        nodata: "Currently no data!",
        startallow: "Start allocation",
        stopallow: "Stop allocation",
    }
};