import { call, put, takeEvery } from "redux-saga/effects";
import {
  CANCEL_EXPORT_USAGE_SAGA,
  CREATE_EXPORT_USAGE_SAGA,
  DELETE_EXPORT_USAGE_SAGA,
  GET_DETAIL_EXPORT_USAGE_SAGA,
  GET_PAGING_EXPORT_USAGE_SAGA,
  GET_PATIENT_INFO_SAGA,
  UPDATE_EXPORT_USAGE_SAGA,
  GET_PURCHASE_ORDER_BY_CODE_SAGA,
  GET_BY_ID_EXPORT_USAGE_SAGA,
} from "./enum/TypeExportUsageAction";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import ExportUsageAction from "./ExportUsageAction";
import {
  getListExportUsageService,
  createExportUsageService,
  updateExportUsageService,
  deleteExportUsageService,
  getDetailExportUsageService,
  cancelExportUsageService,
  getPatientInfoService,
  getPurchaseOrderByCodeService,
  getByIdService,
} from "./ExportUsageService";
import { toast } from "react-toastify";
import ConverDateToString from "src/shared/common/convertDateTime";
import { ADJUSTMENT_TYPE } from "src/components/page/InventoryManagement/Adjustment/enum";
import { EXPORT_USAGE_TYPE } from "src/components/page/ExportUsageManagement/ExportUsage/enum";

export function* getPagingSaga(data) {
  try {
    const SearchTerm = data.SearchTerm;
    const IOP = data.IOP ;
    const Type = data.Type; //Lọc theo loại phiếu xuất, 2 xuất cho bệnh nhân, 3,xuất sử dụng chung
    const CatHospitalIDList = data.CatHospitalIDList; // Lọc theo danh sách bệnh viện
    const CatWarehouseIDList = data.CatWarehouseIDList; // Lọc theo danh sách kho 
    const StartDateExport = ConverDateToString(data.StartDateExport);
    const EndDateExport = ConverDateToString(data.EndDateExport); //Khoảng thời gian xuất phiếu
    const Status = data.Status;
    const PageIndex = data.PageIndex;
    const PageSize = data.PageSize;
    const ListDataUser = data.ListDataUser;
    const ListCostCenter = data.ListCodeCenter;
    const PatientId = data.PatientId;
    const VisitCode = data.VisitCode;
    const CodeOH = data.CodeOH;
    const request = {
      SearchTerm:SearchTerm,
      IOP:IOP,
      Type:Type,
      CatHospitalIDList:CatHospitalIDList,
      CatWarehouseIDList:CatWarehouseIDList,
      StartDateExport:StartDateExport,
      EndDateExport:EndDateExport,
      Status:Status,
      PageIndex:PageIndex,
      PageSize:PageSize,
      ListDataUser: ListDataUser,
      ListCostCenter: ListCostCenter,
      PatientId: PatientId,
      VisitCode: VisitCode,
      CodeOH : CodeOH,
    };
    const response = yield call(getListExportUsageService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        openLoading();
        yield put(
            ExportUsageAction.getPagingSaga(
              request.SearchTerm,
              request.Type,
              request.CatHospitalIDList,
              request.CatWarehouseIDList,
              request.StartDateExport,
              request.EndDateExport,
              request.Status,
              request.PageIndex - 1,
              request.PageSize,
              request.IOP,
              request.ListDataUser,
              request.ListCostCenter,
              request.PatientId,
              request.VisitCode,
              request.CodeOH,
          )
        );
      } else {
        yield put(ExportUsageAction.getPagingReducer(response));
        closeLoading();
      }
    }
  } catch (error) {
    closeLoading();
  }
}

export function* createDataSaga(input){
  try {
    const Id = 0;
    const Name = input.data.Name;
    const PatientId = input.data?.PatientId ?? null;
    const VisitCode = input.data?.VisitCode ?? 0;
    const PatientInfo = input.data?.PatientInfo;
    const PatientType = input.data?.PatientType ?? null;
    const WarehouseId = input.data.WarehouseId;
    const HospitalId = input.data.HospitalId;
    const Status = input.statusSave;
    const Type = input.data.Type;
    const AdjustmentType = input.data.AdjustmentType;
    let IOP = 0;
    if (Type === ADJUSTMENT_TYPE.IncreaseInventory_AndSyncSAP ||
      Type === ADJUSTMENT_TYPE.IncreaseInventory
    )
       IOP = 1;
    const ListTransactionDetail = input.data.ListTransactionDetail?.map(item => ({
      ProductId: item.ProductId, //Id của vtyt
      QuantityActual: item.QuantityActual,
      // //Id của chi tiết phiếu xuất
      // Id: item?.TransactionId,
      // 
      ChargeChangeReason: item?.ChargeChangeReason,
      IsCharge: Type === EXPORT_USAGE_TYPE.PATIENTS_EXPORT ? item?.IsCharge : null,
      ConsumablePackageCode: item?.ConsumablePackageCode,
      InventoryAdjustmentReason: (Type === ADJUSTMENT_TYPE.DecreaseInventory_AndSyncSAP ||
        Type === ADJUSTMENT_TYPE.IncreaseInventory_AndSyncSAP ||
        Type === ADJUSTMENT_TYPE.DecreaseInventory ||
        Type === ADJUSTMENT_TYPE.IncreaseInventory) ? item?.InventoryAdjustmentReason : null

    }));
    const request = {
      Id,
      Name,
      IOP,
      PatientId,
      VisitCode,
      PatientInfo,
      PatientType,
      WarehouseId,
      HospitalId,
      Status,
      Type,
      AdjustmentType,
      ListTransactionDetail
    }
    const response = yield call(createExportUsageService, request);
    if(response.isSuccess)
    {
      yield put(ExportUsageAction.createExportUsageSuccessedReducer(response));
      toast.success(response.userMessage);
    }
    else 
    {
      toast.error(response.userMessage);
    }
  } catch (error ) {
    closeLoading();
  }
  finally{
    // closeLoading();
  }
}

export function* updateDataSaga(input){
  try {
   
    const Id = input.data.Id;
    const Code = input.data.Code;
    const Name = input.data.Name;
    const PatientId = input.data?.PatientId ?? null;
    const VisitCode = input.data?.VisitCode ?? 0;
    const PatientInfo = input.data?.PatientInfo;

    const PatientType = input.data?.PatientType ?? null;
    const WarehouseId = input.data.WarehouseId;
    const HospitalId = input.data.HospitalId;
    const Status = input.statusSave;
    const Type = input.data.Type;
    const AdjustmentType = input.data.AdjustmentType;
    let IOP = 0;
    if (Type === ADJUSTMENT_TYPE.IncreaseInventory_AndSyncSAP ||
      Type === ADJUSTMENT_TYPE.IncreaseInventory
    )
       IOP = 1;
    const ListTransactionDetail = input.data.ListTransactionDetail?.map(item => ({
      ProductId: item.ProductId, //Id của vtyt
      QuantityActual: item.QuantityActual,
      //Id của chi tiết phiếu xuất
      Id: item?.TransactionDetailId,
      ConsumablePackageCode: item?.ConsumablePackageCode,
      ChargeChangeReason: item?.ChargeChangeReason,
      IsCharge: Type === EXPORT_USAGE_TYPE.PATIENTS_EXPORT ? item?.IsCharge : null,
      InventoryAdjustmentReason: (Type === ADJUSTMENT_TYPE.DecreaseInventory_AndSyncSAP ||
        Type === ADJUSTMENT_TYPE.IncreaseInventory_AndSyncSAP ||
        Type === ADJUSTMENT_TYPE.DecreaseInventory ||
        Type === ADJUSTMENT_TYPE.IncreaseInventory) ? item?.InventoryAdjustmentReason : null
    }));
      
    const request = {
      Id,
      Code,
      Name,
      IOP,
      PatientId,
      VisitCode,
      PatientInfo,
      PatientType,
      WarehouseId,
      HospitalId,
      Status,
      Type,
      AdjustmentType,
      ListTransactionDetail
    }

    const response = yield call(updateExportUsageService, request);
    if(response.isSuccess)
    {
      yield put(ExportUsageAction.createExportUsageSuccessedReducer(response));
      toast.success(response.userMessage);
    }
    else 
    {
      toast.error(response.userMessage);
    }
  } catch (error ) {
    closeLoading();
  }
  finally{
    // closeLoading();
  }
}

export function* getLstPatientInfoSaga(input) {
  try {
    const response = yield call(getPatientInfoService, input.patientId);
    if(response.isSuccess)
        yield put(ExportUsageAction.getLstPatientInfoReducer(response));
  } catch (error ) {
    closeLoading();
  }
  finally{
    closeLoading();
  }
}

export function* getByIdSaga(data){
  try {
    const request = {
      transactionId : data.data
    };
    const response = yield call(getByIdService, request);
    if(response.IsSuccess)
        yield put(ExportUsageAction.getByIdReducer(response));
  } catch (error ) {
    closeLoading();
  }
  finally{
    closeLoading();
  }
}


export function* getDetailDataSaga(data){
  try {
    const request = {
      transactionId : data.data.transactionId,
      visitCode:data.data.visitCode
    };
    const response = yield call(getDetailExportUsageService, request);
    if(response.isSuccess)
        yield put(ExportUsageAction.getDetailExportUsageSuccessedReducer(response));
  } catch (error ) {
    closeLoading();
  }
  finally{
    closeLoading();
  }
}

export function* cancelDataSaga(input){
  try {
    var request = {
      transactionId:input.data.transactionId,
      lstDetailCancel : input.data.lstDetailCancel
    }
    const response = yield call(cancelExportUsageService, request);
    if(response.isSuccess)
    {
      yield put(ExportUsageAction.cancelSuccessedReducer(response));
      toast.success(response.userMessage);
    }
    else 
    {
      toast.error(response.userMessage);
    }
  } catch (error ) {
    closeLoading();
  }
  finally{
    closeLoading();
  }
}

export function* deleteDataSaga(data){
  try {
    const transactionId = data.data;

    const response = yield call(deleteExportUsageService, transactionId);
    if(response.isSuccess)
        yield put(ExportUsageAction.deleteExportUsageSuccessedReducer(response));
  } catch (error) {
    closeLoading();
  }
  finally{
    closeLoading();
  }
}
export function * getPurchaseOrderByCodeSaga(request){
  try{
    const response = yield call(getPurchaseOrderByCodeService, request.data);
    if(response.isSuccess)
        yield put(ExportUsageAction.getPurChaseOrderByCodeReducer(response));
  } catch (error ) {
    closeLoading();
  }
  finally{
    closeLoading();
  }
  
}


export default function* ExportUsageSaga(data) {
  yield takeEvery(GET_PAGING_EXPORT_USAGE_SAGA, getPagingSaga);
  yield takeEvery(CREATE_EXPORT_USAGE_SAGA, createDataSaga);
  yield takeEvery(UPDATE_EXPORT_USAGE_SAGA, updateDataSaga);
  yield takeEvery(GET_PATIENT_INFO_SAGA, getLstPatientInfoSaga);
  yield takeEvery(GET_BY_ID_EXPORT_USAGE_SAGA, getByIdSaga);
  yield takeEvery(GET_DETAIL_EXPORT_USAGE_SAGA, getDetailDataSaga);
  yield takeEvery(CANCEL_EXPORT_USAGE_SAGA, cancelDataSaga);
  yield takeEvery(DELETE_EXPORT_USAGE_SAGA, deleteDataSaga);
  yield takeEvery(GET_PURCHASE_ORDER_BY_CODE_SAGA, getPurchaseOrderByCodeSaga);
  //yield takeEvery(GET_PURCHASE_ORDER_BY_CODE_REDUCER, getPurchaseOrderByCodeReducer);

}
