export const GET_PAGING_APPROVE_STEP_SAGA = "GET_PAGING_APPROVE_STEP_SAGA";
export const GET_PAGING_APPROVE_STEP_REDUCER = "GET_PAGING_APPROVE_STEP_REDUCER";
export const CREATE_APPROVE_SAGA = "CREATE_APPROVE_SAGA";
export const UPDATE_APPROVE_SAGA = "UPDATE_APPROVE_SAGA";
export const CALL_CREATE_OR_UPDATE = "CALL_CREATE_OR_UPDATE";
export const DELETE_APPROVE_SAGA = "DELETE_APPROVE_SAGA";
export const CALL_DELETE_APPROVE_SAGA = "CALL_DELETE_APPROVE_SAGA";
export const GET_DETAIL_APPROVE_DEFAULT = "GET_DETAIL_APPROVE_DEFAULT";
export const SET_DETAIL_APPROVE_DEFAULT = "SET_DETAIL_APPROVE_DEFAULT";

export const CREATE_APPROVAL_SAGA = "CREATE_APPROVAL_SAGA";
export const CREATE_APPROVAL_REDUCER = "CREATE_APPROVAL_REDUCER";
export const UPDATE_ID_ROW = "UPDATE_ID_ROW";