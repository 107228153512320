export const CREATE_USER_HOSPITAL_WAREHOUSE_API =
  "/api/service/UserHospitalWarehouse/CreateList";

export const UPDATE_USER_HOSPITAL_WAREHOUSE_API =
  "/api/service/UserHospitalWarehouse/Update";

export const DELETE_USER_HOSPITAL_WAREHOUSE_API =
  "/api/service/UserHospitalWarehouse/Delete";

export const GET_USER_HOSPITAL_WAREHOUSE_BY_USER_API =
  "/api/service/UserHospitalWarehouse/GetByUserId";


export const GET_USER_HOSPITAL_WAREHOUSE_NAME_BY_USER_API =
  "/api/service/UserHospitalWarehouse/GetWarehouseByUser";

export const GET_USER_LIST_API = "/api/service/Users/SearchPagination"
export const GET_USER_LIST_BY_RULE_API = "/api/service/Users/SearchPaginationByRoleUser"

export const GET_HOSPITAL_WAREHOUSE_LIST_API = "/api/service/UserHospitalWarehouse/SearchByUser"
