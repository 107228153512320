import React, { useEffect, useState } from 'react';
import { Dropdown, message, Tooltip, Select, Form } from 'antd';
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserHospitalWarehouseAction from "src/redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseAction.js"
import ImageComponent from 'src/components/base/ControllerVer2/image/ImageComponent';
import { loadUserFromStorage, signoutRedirect } from 'src/shared/oidc-client-base/userService';
import { openLoading } from 'src/shared/common/LoadingAction';
import { getHospitalWarehouseListService, updateUserHospitalWarehouseService } from 'src/redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseService';
import { roleUser } from 'src/shared/constants/enum/constants';
import { hasPermission, listActionEnum } from 'src/shared/common/permistionUtils';


const AvantarCom = (props) => {
    const [listData, setListData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState();
    const [userName, setUserName] = useState(null);
    const [roleCurrentUser, setRoleCurrentUser] = useState();
    const getUserProfile = async () => {
        const user = await loadUserFromStorage();
        setUserName(user?.profile?.user_name)
        setRoleCurrentUser(user?.profile?.role);
        // props.UserHospitalWarehouseAction.getHospitalWarehouseListSaga(user.profile.sub);
        const response = await getHospitalWarehouseListService({ UserId: user?.profile?.sub });

        if (response?.data?.length > 0) {
            const listHospitalWarehouseByUserId = response?.data;
            setListData(listHospitalWarehouseByUserId);
            const rowToSelect = listHospitalWarehouseByUserId.find(item => item.Status === 1);
            // console.log("rowToSelect", rowToSelect);
            if (rowToSelect) {
                setSelectedRowKeys({ value: rowToSelect?.WarehouseId, label: rowToSelect?.WarehouseName });
            }
            else {
                setSelectedRowKeys({});
            }
        }
        else {
            setListData([])
        }
    }

    const handleLogout = () => {
        signoutRedirect()
    }

    const formatName = (str) => {
        if (str && str.includes("@")) {
            return str.slice(0, str.indexOf("@"))
        } else return str
    }

    const handleOnChangeWareHouse = async (value, option) => {
        console.log(value);
        console.log(option);
        openLoading();
        await updateUserHospitalWarehouseService({
            Id: option.Id,
            UserId: option.UserId,
            HospitalId: option.HospitalId,
            WarehouseId: option.WarehouseId,
            Status: 1,
        })
        setSelectedRowKeys(option);
        window.location.reload();
    }
    useEffect(() => {
        getUserProfile();

    }, [])

    return (
        <>
            {hasPermission(props?.listActionAccessible, listActionEnum.cauhinhdonvikho.change_active_warehouse)&& (
                <Select
                    showSearch
                    name="MeterialType"
                    className="select-ams-multi-search col-3"
                    size="medium"

                    value={selectedRowKeys}
                    options={listData.map(item => {
                        return {
                            ...item,
                            value: item.WarehouseId,
                            label: item.WarehouseCode + " " + item.WarehouseName,
                        }
                    })}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    placeholder="Chọn Kho"
                    // style={{ width: '100%' }} // Đặt chiều rộng cố định cho thẻ Select
                    optionFilterProp="label"
                    //value={dataPage?.ProductGroupId ? dataPage?.ProductGroupId : null}
                    onChange={
                        handleOnChangeWareHouse
                    }
                    maxTagCount={'responsive'}
                />
            )}
            <p>&nbsp;</p><p>&nbsp;</p>
            {/* <Tooltip title={(formatName(userName))}> */}
            <Tooltip title={userName}>
                <span className="text-white text-truncate d-inline-block" style={{ maxWidth: '200px' }} >Hi, {userName}</span>
            </Tooltip>
            <span className="pipe mx-3 text-white" >|</span>
            <Dropdown dropdownRender={(menu) => (
                <div className="dropdown-content avatar-option">
                    {menu}
                </div>
            )}>
                <Tooltip title="Đăng xuất" placement="left" >
                    <span>
                        <ImageComponent onClick={handleLogout} className="mr-3" url="/assets/img/icon/logout-icon.svg" />
                    </span>
                </Tooltip>
            </Dropdown>
        </>
    )
};
const mapDispatchToProps = (dispatch) => ({
    UserHospitalWarehouseAction: bindActionCreators(UserHospitalWarehouseAction, dispatch)
});

const mapStateToProps = (state) => ({
    isGetListHospitalWarehouseByUserId: state.UserHospitalWarehouseReducer.isGetListHospitalWarehouseByUserId,
    listHospitalWarehouseByUserId: state.UserHospitalWarehouseReducer.listHospitalWarehouseByUserId,
    listActionAccessible: state.PermissionReducer.listActionAccessible,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AvantarCom);


