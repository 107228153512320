import {

    GET_PAGING_PLANNING_DETAIL_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,SET_LIST_RETAIL_PLANNING_DETAIL_REDUCER
} from "./enum/TypePlanningDetailAction";

const stateDefault = {
    listData: [],
    isGetLstData: false,
    isDeleteSuccess: false,
    isCreateUpdateSuccess: false,
    listRetailPlanningProduct:[]
};

const PlanningDetailReducer = (state = stateDefault, action) => {
    switch (action.type) {
        case GET_PAGING_PLANNING_DETAIL_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.isGetLstData = true;
            }
            
            return { ...state };
        case CALL_DEFAULT_REDUCER:
            state.isGetLstData = false;
            state.isDeleteSuccess = false;
            state.isCreateUpdateSuccess = false;
            return {
                ...state,
            };

        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };
        default:
            return { ...state };
    }
};

export default PlanningDetailReducer;
