import {
  GET_PAGING_RETURN_SAGA,

  GET_PAGING_RETURN_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_RETURN_SAGA,
  DELETE_RETURN_REDUCER,
  CREATE_RETURN_REDUCER,
  CREATE_RETURN_SAGA,
  UPDATE_RETURN_REDUCER,
  UPDATE_RETURN_SAGA,
  GET_INFO_RETURN_BY_ID_REDUCER,
  GET_INFO_RETURN_BY_ID_SAGA,
  GET_PAGING_RETAIL_RETURN_SAGA,
  GET_PAGING_RETAIL_RETURN_REDUCER,
  SET_TRANSACTION_DETAIL_LIST_REDUCER,
  RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA,
  RESET_GET_INFO_RETURN_BY_ID_REDUCER,

} from "./enum/TypeReturnAction";

// const stateDefault = {
//   listData: [],
//   isGetLstData: false,
//   isDeleteSuccess: false,
//   isCreateUpdateSuccess: false,
// };

const ReturnReducer = {
  getPagingSaga: (pageIndex, pageSize, hospitalId, warehouseReturnId, warehouseRecoveryId, status, startDateExport, endDateExport, searchTerm = "") => {
    return {
      type: GET_PAGING_RETURN_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseReturnId: warehouseReturnId,
      warehouseRecoveryId: warehouseRecoveryId,
      status: status,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      searchTerm: searchTerm,
    };
  },
  getPagingRetailReturnSaga: (pageIndex, pageSize, hospitalId, warehouseReturnId, warehouseRecoveryId, status, startDateExport, endDateExport, searchTerm = "") => {
    return {
      type: GET_PAGING_RETAIL_RETURN_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      hospitalId: hospitalId,
      warehouseReturnId: warehouseReturnId,
      warehouseRecoveryId: warehouseRecoveryId,
      status: status,
      startDateExport: startDateExport,
      endDateExport: endDateExport,
      searchTerm: searchTerm,
    };
  },
  getPagingRetailReturnReducer: (data) => {
    return {
      type: GET_PAGING_RETAIL_RETURN_REDUCER,
      data: data,
    };
  },
  deleteDataSaga: (id) => {
    return {
      type: DELETE_RETURN_SAGA,
      id: id,
    };
  },
  deleteDataReducer: () => {
    return {
      type: DELETE_RETURN_REDUCER,
    };
  },
  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_RETURN_REDUCER,
      data: data,
    };
  },
  createDataReducer: (data) => {
    return {
      type: CREATE_RETURN_REDUCER,
      data: data,
    };
  },
  createDataSaga: (data, saveType) => {
    return {
      type: CREATE_RETURN_SAGA,
      data: data,
      saveType:saveType,
    };
  },
  updateDataReducer: (data) => {
    return {
      type: UPDATE_RETURN_REDUCER,
      data: data,
    };
  },
  updateDataSaga: (data, saveType) => {
    return {
      type: UPDATE_RETURN_SAGA,
      data: data,
      saveType:saveType,

    };
  },
  getInfoReturnReducer: (data) => {
    return {
      type: GET_INFO_RETURN_BY_ID_REDUCER,
      data: data,
    };
  },
  resetIsGetInfoReturnReducer: () => {
    return {
      type: RESET_GET_INFO_RETURN_BY_ID_REDUCER,
    };
  },
  getInfoReturnSaga: (data) => {
    return {
      type: GET_INFO_RETURN_BY_ID_SAGA,
      data: data,
    };
  },
  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },

  setTransactionDetailList: (data) => {
    return {
      type: SET_TRANSACTION_DETAIL_LIST_REDUCER,
      data: data,
    };
  },

  // tính toán tồn kho lẻ phiếu lĩnh nếu chọn vậy tu y tế
  ReturnPredictabilityRetailByListProductSaga: (warehouseId, approveWarehouseId, listProductId) => {
    return {
      type: RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA,
      warehouseId: warehouseId,
      approveWarehouseId: approveWarehouseId,
      listProductId: listProductId
    };
  },

}

export default ReturnReducer;
