export const sopLag = {
  language: "vn",
  sop: {
    table: {
      code: "Tên SOP",
      product: "SẢN PHẨM",
      versionUse: "Version đang sử dụng",
      createdDate: "Ngày tạo",
      effectiveDate: "Ngày hiệu lực",
      versionNewest: "Version mới nhất",
      statusVersionNewest: "Trạng thái version mới nhất",
      actionEdit: "Chỉnh sửa",
      actionBrowser: "Trình duyệt"
    },
    search: {
      placeHolder:
        "Tên SOP, sản phẩm, version hoặc trạng thái version",
    },
    form: {
      add_new: "Tạo mới tài liệu SOP",
      update: "Cập nhật thông tin tài liệu SOP",
      see_detail: "Xem thông tin tài liệu SOP",
      add_version: "Thêm mới version",
      code: "Tên tài liệu",
      product: "Sản phẩm",
      version: "Phiên bản",
      note: "Ghi chú",
      attachedFiles: "File tài liệu đính kèm",
    },
    delete: {
      title: "Xóa SOP",
      description: "Bạn có chắc chắn muốn xóa SOP này?",
    },
    btn: {
      deletedVersion: "Xóa phiên bản",
      changeVersion: "Đổi version",
      addVersion: "Thêm mới"

    },
    toastjs: {
      noVersionEdit: "Không có version để sửa",
      cant_not_edit: "Version đã có hành động khác, không thể sửa",
      version_not_approved: "Chỉ được đổi version đã được phê duyệt",
      noVersionChange: "Không có version mới để đổi",
      no_choose_version: "Chưa chọn SOP để đổi version",
      no_browser_one: "Chỉ những version có trạng thái tạo mới mới được trình duyệt",
      no_browser_two: "Không có version mới tạo để trình duyệt"

    },
    error: {
      fileMax: "Không thể upload file có dung lượng lớn hơn 20MB",
      versionIsUsing: " Version đang được sử dụng",
      sopNameRequired: "Tên SOP không được bỏ trống",
      sopName: "Tên SOP không được quá 255 kí tự",
      product: "Sản phẩm không được bỏ trống",
      versionRequired: "Phiên bản không được bỏ trống",
      version: "Phiên bản không được quá 255 ký tự",
      attachedFiles: "File đính kèm không được quá 20Mb",
      attachedFilesRequired: "File đính kèm k được bỏ trống",
      note: "Ghi chú không được quá 255 kí tự",
      add_more_version: "Đã có version mới nhất, không thể thêm mới version mới khác",
      add_more_version_one: "Đã có version mới nhất, không thể thêm version mới khác",
      add_more_version_two: "Đã có version mới nhất, chỉ được thêm khi version mới nhất bị từ chối",
      errorFile: "File đính kèm không được bỏ trống"
    },
    confirm: {
      createSuccess: "Tạo mới SOP thành công",
      updateSuccess: "Cập nhật SOP thành công",
      changeVersionSuccess: "Đổi version thành công",
      deleteSuccess: "Xóa thành công version",
      addVersionSuccess: "Thêm mới version thành công",
      comfirmText: "Version đang được sử dụng, không được xóa",
      comfirmTextDeleted: "Version hiện là duy nhất, không được xóa"

    }
  },
};
