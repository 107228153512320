import {
    CLEAR_LINK_PRODUCT_REQUIRED_INFORMATION, CLEAR_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem, CONFIRM_SAVE_DATA_SUCCESS, CREATE_LINK_PRODUCT_TO_PROCESS_SCHEDULE, DELETE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION, DELETE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem, GET_LIST_WORKCENTER_SAGA, GET_PRODUCTION_REQUIRE_DETAIL_SAGA, HANDLE_DELETE_ROW_TABLE_ORDER_PRODUCE, HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_REDUCER, HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_SAGA, INSERT_DATA_REDUCER, INSERT_DATA_SAGA, IS_CHECK_VALIDATE, IS_DATA_CLEAR_PRO_REQUIREMENT_DONE,
    IS_DATA_CLEAR_WORK_ORDER_DONE, IS_DROPDOWN_PRO_REQUIREMENT_UPDATE_DONE, IS_SAVE_API,
    IS_SAVE_API_DONE, IS_SCHEDULE_UPDATE, LET_SCHEDULE_UPDATE_WORK_ORDER,
    LET_SCHEDULE_UPDATE_WORK_ORDER_DONE, REFRESH_PRODUCT_REQUIRED_INFORMAITON_SELECT, SCHEDULE_UPDATE_DONE, SELECTED_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION, SET_LIST_WORKCENTER_REDUCER, SET_PRODUCTION_REQUIRE_DETAIL_REDUCER, UPDATE_CODE_WO, UPDATE_DROPDOWN_DEFAULT_PRODUCTION_REQUIREMENT, UPDATE_DROPDOWN_PRODUCT, UPDATE_DROPDOWN_PRODUCTION_REQUIREMENT, UPDATE_DROPDOWN_WORKCENTER, UPDATE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION, UPDATE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem, UPDATE_SCHEDULE_REFIX_V2, UPDATE_WORKORDER_TABLE_REDUCER, UPDATE_WORK_ORDER_DATA, UPDATE_WORK_ORDER_REQUIREMENT
} from './enum/typeAction'

const actionLinkProductRequirement = {
    setDefaultState: () => {
        return {
            type: 'setDefaultState',
        }
    },
    setWorkOrderRequirement: (detail) => {
        return {
            type: UPDATE_WORK_ORDER_REQUIREMENT,
            detail: detail,
        }
    },
    setProductRequirementDropdown: (option) => {
        return {
            type: UPDATE_DROPDOWN_PRODUCTION_REQUIREMENT,
            option: option,
        }
    },
    setDefaultProductRequirementDropdown: (dropdowns) => {
        return {
            type: UPDATE_DROPDOWN_DEFAULT_PRODUCTION_REQUIREMENT,
            dropdowns: dropdowns,
        }
    },
    updateWorkOrderData: (data) => {
        return {
            type: UPDATE_WORK_ORDER_DATA,
            data: data,
        }
    },
    updateDropdownProduct: (data) => {
        return {
            type: UPDATE_DROPDOWN_PRODUCT,
            data: data,
        }
    },
    updateDropdownWorkcenter: (data) => {
        return {
            type: UPDATE_DROPDOWN_WORKCENTER,
            data: data,
        }
    },
    isDataSaveSuccess: () => {
        return {
            type: CONFIRM_SAVE_DATA_SUCCESS,
        }
    },
    isDataProductRequirementClearDone: () => {
        return {
            type: IS_DATA_CLEAR_PRO_REQUIREMENT_DONE,
        }
    },
    isDataWorkorderClearDone: () => {
        return {
            type: IS_DATA_CLEAR_WORK_ORDER_DONE,
        }
    },
    isCheckValidate: () => {
        return {
            type: IS_CHECK_VALIDATE,
        }
    },
    doneUpdateProRequirement: () => {
        return {
            type: IS_DROPDOWN_PRO_REQUIREMENT_UPDATE_DONE,
        }
    },
    isScheduleUpdate: (data) => {
        return {
            type: IS_SCHEDULE_UPDATE,
            data: data
        }
    },
    scheduleUpdateDone: () => {
        return {
            type: SCHEDULE_UPDATE_DONE,
        }
    },
    letScheduleUpdateWO: () => {
        return {
            type: LET_SCHEDULE_UPDATE_WORK_ORDER,
        }
    },
    letScheduleUpdateWODone: () => {
        return {
            type: LET_SCHEDULE_UPDATE_WORK_ORDER_DONE,
        }
    },
    saveApi: () => {
        return {
            type: IS_SAVE_API,
        }
    },
    saveApiDone: () => {
        return {
            type: IS_SAVE_API_DONE,
        }
    },

    // cac action xu ly api
    getProRequireSaga: (proRequireCode) => {
        return {
            type: GET_PRODUCTION_REQUIRE_DETAIL_SAGA,
            proRequireCode,
        }
    },
    setProRequireReducer: (data) => {
        return {
            type: SET_PRODUCTION_REQUIRE_DETAIL_REDUCER,
            data,
        }
    },
    getListWorkcenterSaga: (Productionrequirementcode = "", ProductCode = "", index = "", typeIndentifier = "") => {
        return {
            type: GET_LIST_WORKCENTER_SAGA,
            Productionrequirementcode,
            ProductCode,
            index,
            typeIndentifier
        }
    },
    setListWorkcenterReducer: (data, index = "", typeIndentifier = "") => {
        return {
            type: SET_LIST_WORKCENTER_REDUCER,
            data,
            index,
            typeIndentifier
        }
    },
    insertDataSaga: (workorderData) => {
        return {
            type: INSERT_DATA_SAGA,
            workorderData,
        }
    },
    insertDataReducer: () => {
        return {
            type: INSERT_DATA_REDUCER,
        }
    },
    updateWorkorderTable: (status, data = []) => {
        return {
            type: UPDATE_WORKORDER_TABLE_REDUCER,
            status,
            data
        }
    },
    updateRowTableProductionRequirementInfomation: (status, qty = 0, woCode = "") => {
        return {
            type: UPDATE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION,
            status,
            qty,
            woCode
        }
    },
    handleSelectedRowTableProductionRequirementInfomation: (poCode = "", qty) => {
        return {
            type: SELECTED_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION,
            poCode,
            qty
        }
    },
    deleteRowTableProductionRequirementInfomation: (woCode = "", qty) => {
        return {
            type: DELETE_ROW_TABLE_PRODUCT_REQUIRED_INFORMATION,
            woCode,
            qty
        }
    },
    refreshProductRequirementInformationSelected: (status) => {
        return {
            type: REFRESH_PRODUCT_REQUIRED_INFORMAITON_SELECT,
            status
        }
    },
    clearReducer: () => {
        return {
            type: CLEAR_LINK_PRODUCT_REQUIRED_INFORMATION
        }

    },
    showScheduleData: (tab = 1, date = new Date(), data, isCreate = false) => {
        return {
            type: CREATE_LINK_PRODUCT_TO_PROCESS_SCHEDULE,
            tab,
            date,
            data,
            isCreate
        }

    },
    updateDragItemScheduleProcess: (dragItem) => {
        return {
            type: UPDATE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem,
            dragItem,
        }
    },
    deleteDragItemScheduleProcess: (wo) => {
        return {
            type: DELETE_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem,
            wo,
        }
    },
    clearDragItemScheduleProcess: (status) => {
        return {
            type: CLEAR_SCHEDULE_LINK_PRODUCT_TO_PROCESS_dragItem,
            status
        }
    },

    updateDataPrefixV2: (statusUpdate = false, data = {}) => {
        return {
            type: UPDATE_SCHEDULE_REFIX_V2,
            statusUpdate,
            data
        }
    },
    deleteRowProduceOrder: (code) => {
        return {
            type: HANDLE_DELETE_ROW_TABLE_ORDER_PRODUCE,
            code
        }
    },
    getMuitipleOrderCodeSaga: (dropdownSelect = []) => {
        return {
            type: HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_SAGA,
            dropdownSelect
        }
    },
    setMuitipleOrderCodeReducer: (data) => {
        return {
            type: HANDLE_SELECT_MUITIPLE_DROPDOWN_WORKODER_REDUCER,
            data
        }
    },
    XoaYeuCauSanXuat: (status) => {
        return {
            type: "XoaYeuCauSanXuat",
            status
        }
    },
}
export default actionLinkProductRequirement;