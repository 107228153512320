export const timeGroupWorkDay = {
    language: 'en',
    table_group_day: {
        name: "Name group",
        color: "Color",
        status: "Required to choose date range",
    },
    placeholder_group: "Enter name group day",
    form_group_day: {
        title_create: "Create new group day",
        title_edit: "Update group day",
        title_delete: "Delete group day",
        text_confirm_delete: "Do you want to remove group day?",
    },
    mes_group_day: {
        required_name: "Name cannot be empty",
        maxLenght_name: "Name no longer than 100 characters",
        maxLenght_color: "Color no longer than 20 characters",
        required_color: "Color cannot be empty",
        create_success: "Create new group day successfully",
        update_success: "Update new group day successfully",
        delete_success: "Delete new group day successfully",
    }

}