import * as type from "./enum/actionType";
const stateDefault = {
    files: [],
    isFileUploadDone: false,
    status: true,
    controlNum: '1'
}
const UploadReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        // reducer được gọi mỗi khi chu trình upload file hoàn thành
        case type.UPLOAD_FILE_REDUCER:
            state.files = action.files;
            state.isFileUploadDone = true;
            state.controlNum = action.controlNum;
            return {
                ...state,
            }
        default:
            state = stateDefault;
            return {
                ...state
            }
    }
}
export default UploadReducer;