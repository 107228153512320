import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_REPORT_TRANSACTION_SAGA,
  EXPORT_REPORT_TRANSACTION_SAGA,
  GET_REPORT_RECONCILE_SAGA,
  EXPORT_REPORT_RECONCILE_SAGA,
  GET_REPORT_INVENTORY_SAGA,
  EXPORT_REPORT_INVENTORY_SAGA,
} from "./enum/TypeReportAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import ReportAction from "./ReportAction";
import {
  getReportTransactionService, exportReportTransactionService,
  getReportReconcileService, exportReportReconcileService,
  getReportInventoryService, exportReportInventoryService,
} from "./ReportService";
import { downloadFile, getFileNameWithDate } from "src/shared/utils/file";
import ConverDateToString from "src/shared/common/convertDateTime";

export function* getReportTransactionSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const pID = data.pID ? data.pID : "";
    const listProductId = data.listProductId ? data.listProductId : [];
    const billCode = data.billCode ? data.billCode : "";
    let hospitalId = data.hospitalId || []
    let warehouseId = data.warehouseId ? data.warehouseId : []
    let productGroupId = data.productGroupId? data.productGroupId : []
    let startDateExport = ConverDateToString(data.startDateExport)
    let endDateExport = ConverDateToString(data.endDateExport)
    const searchTerm = data.searchTerm;
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      HospitalId: hospitalId,
      WarehouseId: warehouseId,
      ProductGroupId: productGroupId,
      PID: pID,
      ListProductId: listProductId,
      MovementTypeOH: data.movementTypeOH,
      BillCode: billCode,
      StartDateExport: startDateExport,
      EndDateExport: endDateExport,
      SearchTerm: searchTerm,
    };
    const response = yield call(getReportTransactionService, request);
    if (response) {
        yield put(ReportAction.getReportTransactionReducer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* exportReportTransactionSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const pID = data.pID ? data.pID : "";
    const billCode = data.billCode ? data.billCode : "";
    let hospitalId = data.hospitalId || []
    let warehouseId = data.warehouseId ? data.warehouseId : []
    let productGroupId = data.productGroupId? data.productGroupId : []
    let startDateExport = ConverDateToString(data.startDateExport)
    let endDateExport = ConverDateToString(data.endDateExport)
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      HospitalId: hospitalId,
      WarehouseId: warehouseId,
      ProductGroupId: productGroupId,
      PID: pID,
      ListProductId: data.listProductId,
      BillCode: billCode,
      StartDateExport: startDateExport,
      EndDateExport: endDateExport,
      MovementTypeOH: data.movementTypeOH,
      SearchTerm: data.searchTerm,
    };
    const response = yield call(exportReportTransactionService, request);
    if (response) {
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = getFileNameWithDate({
        prefix: "",
        fileNameWithoutExtension: "Bao_cao_giao_dich_kho",
        extension: ".xlsx",
      });
      downloadFile(fileUrl, fileName);
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* getReportReconcileSaga(data) {
  try {
    const request = {
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
      HospitalId:  data.hospitalId,
      WarehouseId: data.warehouseId,
      ProductGroupId: data.productGroupId,
      ListProductId: data.listProductId,
      MovementTypeOH: data.movementTypeOH ,
      StartDateExport:  ConverDateToString(data.startDateExport),
      EndDateExport: ConverDateToString(data.endDateExport),
      SearchTerm: data.searchTerm
    };
    const response = yield call(getReportReconcileService, request);
    if (response) {
        yield put(ReportAction.getReportReconcileReducer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* exportReportReconcileSaga(data) {
  try {
    const request = {
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
      HospitalId:  data.hospitalId,
      WarehouseId: data.warehouseId,
      ProductGroupId: data.productGroupId,
      ListProductId: data.listProductId,
      MovementTypeOH: data.movementTypeOH ,
      StartDateExport:  ConverDateToString(data.startDateExport),
      EndDateExport: ConverDateToString(data.endDateExport),
      SearchTerm: data.searchTerm

    };
    const response = yield call(exportReportReconcileService, request);
    if (response) {
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = getFileNameWithDate({
        prefix: "",
        fileNameWithoutExtension: "Bao_cao_reconcile_MM",
        extension: ".xlsx",
      });
      downloadFile(fileUrl, fileName);
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* getReportInventorySaga(data) {
  try {
    let startDateExport = ConverDateToString(data.startDateExport);
    let endDateExport = ConverDateToString(data.endDateExport)
    const request = {
      PageIndex: data.pageIndex,
      PageSize: data.pageSize,
      HospitalId:  data.hospitalId,
      WarehouseId:  data.warehouseId,
      ProductGroupId: data.productGroupId,
      ListProductId: data.listProductId,
      StartDateExport: startDateExport,
      EndDateExport: endDateExport,
      SearchTerm: data.searchTerm,
    };
    const response = yield call(getReportInventoryService, request);
    if (response) {
        yield put(ReportAction.getReportInventoryReducer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* exportReportInventorySaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const listProductId = data.listProductId ? data.listProductId : [];
    let hospitalId = data.hospitalId || []
    let warehouseId = data.warehouseId ? data.warehouseId : []
    let productGroupId = data.productGroupId? data.productGroupId : []
    let startDateExport = ConverDateToString(data.startDateExport)
    let endDateExport = ConverDateToString(data.endDateExport)
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      HospitalId: hospitalId,
      WarehouseId: warehouseId,
      ProductGroupId: productGroupId,
      ListProductId: listProductId,
      StartDateExport: startDateExport,
      EndDateExport: endDateExport,
    };
    const response = yield call(exportReportInventoryService, request);
    if (response) {
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = getFileNameWithDate({
        prefix: "",
        fileNameWithoutExtension: "Bao_cao_ton_kho",
        extension: ".xlsx",
      });
      downloadFile(fileUrl, fileName);
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}



export default function* ReportSaga(data) {
  yield takeEvery(GET_REPORT_TRANSACTION_SAGA, getReportTransactionSaga);
  yield takeEvery(EXPORT_REPORT_TRANSACTION_SAGA, exportReportTransactionSaga);
  yield takeEvery(GET_REPORT_RECONCILE_SAGA, getReportReconcileSaga);
  yield takeEvery(EXPORT_REPORT_RECONCILE_SAGA, exportReportReconcileSaga);
  yield takeEvery(GET_REPORT_INVENTORY_SAGA, getReportInventorySaga);
  yield takeEvery(EXPORT_REPORT_INVENTORY_SAGA, exportReportInventorySaga);
}
