// packages
import { Layout, Menu } from "antd";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import queryString from "query-string";
import userManager, {
  loadUserFromStorage,
  signinRedirect,
} from "../../../../shared/oidc-client-base/userService";
// redux
import PermissionAction from "src/redux-saga-v2/Permission/permissionAction";
import { remountRouteComponent } from "src/redux-saga-v2/Router/actionCreators";
import FormUserHospitalWarehouse from "../../../page/MedicalSuppliesManagement/UserHospitalWarehouse/form";
import UserHospitalWarehouseAction from "../../../../redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseAction";

// components
import ImageComponent from "src/components/base/ControllerVer2/image/ImageComponent";

// utils
import {
  getMenuItems,
  getTreeData,
  getAliasPath,
  getAncestorsFromHiddenMenus,
  getAncestors,
} from "./utils";
import { uniqueArray } from "src/shared/utils/arrray";

// constants
import { HIDDEN_MENUS } from "./constants";

// styles
import "src/shared/scss/layout/sidebar/sidebar.scss";

const { Sider } = Layout;

const SidebarComp = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(
    props.location?.pathname === "/trang-chu" ? ["trangChu1"] : []
  );

  let history = useHistory();

  // di chuyển sang page khác
  const onChangePage = (key = "") => {
    const url = permissionBusiness(key);
    if (!!url) {
      history.push(url);
      props.remountRouteComponent();
    }
  };

  const permissionBusiness = useCallback(
    (key) => {
      let dataSelect = {
        menuKey: "",
        menuChildens: [],
        menuDefaultShow: {},
      };
      let urlNew = "";
      let keyNew = "";
      if (key.length > 0 && key.length > 36) keyNew = key.substring(0, 36);
      else keyNew = key;
      let menuChildens = props.permissionReducer?.listMenu?.filter(
        (x) => x.MenuId == keyNew
      );
      if (menuChildens && menuChildens.length > 0) {
        dataSelect.menuKey = keyNew;
        dataSelect.menuChildens = menuChildens;
        let MenuDefault = menuChildens.find((x) => x.IsDefault == true);
        if (MenuDefault) {
          urlNew = MenuDefault.path;
          dataSelect.menuDefaultShow = MenuDefault;
        } else {
          urlNew = menuChildens[0].path;
          dataSelect.menuDefaultShow = menuChildens[0];
        }
        if (urlNew) {
          props.PermissionAction.setMenuSelect(dataSelect);
        }
      }
      return urlNew;
    },
    [props.PermissionAction, props.permissionReducer?.listMenu]
  );

  const menuItems = useMemo(() => {
    const currentPage = props.permissionReducer.listMenuShow.find(
      (element) => element.to == props.history.location.pathname
    );
    const ancestorPage = props.permissionReducer.listMenuShow.find(
      (page) => page.id == currentPage?.ancestor
    );
    const treeData = getTreeData(
      props.permissionReducer.listMenuShow,
      null,
      ancestorPage?.id,
      collapsed
    );

    return getMenuItems({
      treeData,
      selectedKeys,
      collapsed,
    });
  }, [
    props.permissionReducer.listMenuShow,
    props.history.location,
    collapsed,
    selectedKeys,
  ]);

  // cập nhập thay đổi dữ liệu phân quyền của page không nằm trên menu
  useEffect(() => {
    const { pathname } = props.history.location;
    const listMenu = props.permissionReducer?.listMenu || [];
    const currentPage = listMenu?.filter((menu) => menu.path === pathname);
    if (currentPage && currentPage?.length)
      permissionBusiness(currentPage[0]?.MenuId, currentPage[0]?.path);
  }, [
    props.history.location,
    permissionBusiness,
    props.permissionReducer?.listMenu,
  ]);

  // xử lý skhi người dùng đóng mở sidebar và khi chuyển trang
  useEffect(() => {
    if (props.sitePage && props.permissionReducer.listMenuShow.length) {
      let ancesstorKeys = getAncestors(props.sitePage, menuItems) ?? [];

      // kiểm tra dữ liệu và hiển thị menu và submenu người dùng đang chọn
      if (ancesstorKeys.length === 0) {
        const aliasPath = getAliasPath({
          path: props.sitePage,
          query: queryString.parse(props.location.search),
        });
        ancesstorKeys = getAncestorsFromHiddenMenus({
          currentPath: aliasPath,
          menus: menuItems,
          hiddenMenus: HIDDEN_MENUS,
        });
      }

      if (ancesstorKeys.length > 0) {
        setOpenKeys(uniqueArray([...openKeys, ...ancesstorKeys]));
        setSelectedKeys([...ancesstorKeys]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sitePage, props.permissionReducer.listMenuShow]);
  const closePopupCreateUpdate = (isCancel) => {
    setIsOpenForm(false);
  };
  return (
    <Sider
      width={240}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className="mes-sidebar"
    >
      {/* {
        <>
          {isOpenForm ? (
            <FormUserHospitalWarehouse
              isOpen
              closePopupCreateUpdate={closePopupCreateUpdate}
            />
          ) : null}
        </>
      }  */}
      <div
        className={
          collapsed
            ? "logo-mes-collapsed align-center"
            : "logo-mes mx-3 align-center"
        }
      >
        <ImageComponent
          url={
            collapsed
              ? "/assets/img/home/logo-sidebar-toggled2.svg"
              : "/assets/img/home/logo-sidebar-toggled1.svg"
          }
        />
      </div>
      <div
        className="btn-collapse-wrap"
        onClick={() => setCollapsed(!collapsed)}
      >
        <span className="btn-collapse btn-trapezoid-outline">
          <span>{collapsed ? <UpOutlined /> : <DownOutlined />}</span>
        </span>
      </div>
      <Menu
        style={menuStyle}
        mode="inline"
        onOpenChange={(newOpenKeys) => {
          setOpenKeys(newOpenKeys);
        }}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onClick={async ({ item, key, keyPath }) => {
          setSelectedKeys(keyPath);
          onChangePage(key);
          if (key == "68fe1188-675b-4fc4-9620-cc4a0f9a43221") {
            //mở modal
            setIsOpenForm(true);
          }
          //TrungLQ: Mỗi lần mở menu thì sẽ gọi để lấy thông tin bệnh viện, kho xuất được phân quyền
          let user = await loadUserFromStorage();
          let userID = user.profile.sub;
          props.UserHospitalWarehouseAction.getUserHospitalWarehouseByUserSaga(
            userID
          );
        }}
        theme={
          collapsed ? "custom-sidebar-collapsed" : "custom-sidebar-expanded"
        }
        items={menuItems}
      />
    </Sider>
  );
};
// neu su dung sidebar ngang hoac doc theo custom rieng
const MenuComp = (props) => {
  let data = props.data;
  let parentKey = props?.parentKey;
  let history = useHistory();
  const openScreen = (data) => {
    history.push(data.to);
  };
  if (data.children)
    return (
      <Menu mode={"inline"} className="ps-0 ant-menu-level-1">
        <Menu.SubMenu
          popupclassname={"menu-config-" + (data.level + 1)}
          key={(parentKey ?? "") + data.key}
          icon={data.icon && <ImageComponent url={data.icon} />}
          title={data.label}
        >
          {data.children &&
            data.children.length > 0 &&
            data.children.map((item, key) =>
              item.children ? (
                <MenuComp key={key} data={item} parentKey={data.key} />
              ) : (
                <Menu.Item key={Math.random()} onClick={() => openScreen(item)}>
                  {" "}
                  {item.label}
                </Menu.Item>
              )
            )}
        </Menu.SubMenu>
      </Menu>
    );
  else {
    return (
      <Menu>
        <Menu.Item
          className="single-page-only"
          onClick={() => openScreen(data)}
          key={data.key}
          icon={data.icon && <ImageComponent url={data.icon} />}
        >
          {" "}
          {data.label}
        </Menu.Item>
      </Menu>
    );
  }
};
// kiểm tra liệu url có tồn tại trong dữ liệu sidebar không
const checkIfUrlExist = (key) => {
  let keyArr = key.split("_url:");
  if (keyArr.length > 0) {
    return keyArr[1];
  }
  return false;
};

// style menu
const menuStyle = {
  maxWidth: 400,
  overflowY: "auto",
  height: "inherit",
  paddingBottom: "80px",
};
const mapDispatchToProps = (dispatch) => ({
  PermissionAction: bindActionCreators(PermissionAction, dispatch),
  UserHospitalWarehouseAction: bindActionCreators(
    UserHospitalWarehouseAction,
    dispatch
  ),
  remountRouteComponent: () => {
    dispatch(remountRouteComponent());
  },
});

const mapStateToProps = (state) => {
  return {
    sitePage: state.loginReducer.sitePage,
    permissionReducer: {
      listMenuShow: state.PermissionReducer.listMenuShow,
      listMenu: state.PermissionReducer.listMenu,
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SidebarComp)));
