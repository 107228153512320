import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import WorkareaLinkProccessAction from "./WorkareaLinkProccessAction";
import {
  GET_PAGING_WLP_SAGA, GET_DROPDOWN_PROCESS_BY_PRODUCT_CODE_SAGA, GET_DROPDOWN_PRODUCT_PROCESS, GET_DATA_TABLE_SAGA, CREATE_NEW_SAGA, GET_DETAIL_WLP_SAGA,
  DELETE_WLP_SAGA, UPDATE_LINK_SAGA
} from "./enum/WorkareaLinkProccessTypeAction";
import {
  getPagingService, getDropdownProcessService, getDropdownProductService, getDataTableService, createNewService, getDetailService, deleteLinkService, updateLinkService
} from "./WorkareaLinkProccessServices";
import toastJs from "src/shared/common/toastJs";
import i18next from "i18next";
export function* getPagingSaga(data) {
  try {
    const response = yield call(getPagingService, data.request);
    if (response.isSuccess) {
      if (response.data.Items.length <= 0 && data.request.PageIndex > 1) {
        data.request.PageIndex--;
        yield put(WorkareaLinkProccessAction.getPagingSaga(data.request));
      } else {
        yield put(WorkareaLinkProccessAction.getPagingReducer(response.data));
      }
    }
  } catch (error) {

  }
  closeLoading();
}
export function* getDropdownProcessSaga(data) {
  try {
    const response = yield call(getDropdownProcessService, data.code);
    if (response.isSuccess) {
      yield put(WorkareaLinkProccessAction.setDropdownProcessReducer(response.data));
    }
  } catch (error) {

  }
  closeLoading();
}
export function* getDropdownProductSaga() {
  try {
    const response = yield call(getDropdownProductService);
    if (response.isSuccess) {
      yield put(WorkareaLinkProccessAction.setDropdownProductReducer(response.data));
    }
  } catch (error) {

  }
  closeLoading();
}
export function* getDataTableSaga(data) {
  try {
    const request = {
      ProcessId: data.ProcessId, WorkcenterId: data.WorkcenterId
    }
    const response = yield call(getDataTableService, request);
    if (response.isSuccess) {
      yield put(WorkareaLinkProccessAction.setDataTableReducer(response.data));
    }
  } catch (error) {

  }
  closeLoading();
}
export function* createNewSaga(data) {
  try {
    const request = data.request;
    request.PageIndex = 1;
    const response = yield call(createNewService, data.data);
    if (response.isSuccess) {
      yield put(WorkareaLinkProccessAction.setCallAPIsuccess());
      toastJs.success(i18next.t("wokarealinkproccess.addsuccesss"));
      yield put(WorkareaLinkProccessAction.getPagingSaga(request));
    }
  } catch (error) {

  }
  closeLoading();
}
export function* getDetailSaga(data) {
  try {
    const response = yield call(getDetailService, data.id);
    if (response.isSuccess) {
      yield put(WorkareaLinkProccessAction.setObjDetail(response.data));
    }
  } catch (error) {

  }
  closeLoading();
}
export function* deleteLinkSaga(data) {
  try {
    const response = yield call(deleteLinkService, data.id);
    if (response.isSuccess) {
      toastJs.success(i18next.t("wokarealinkproccess.deletesuccess"));
      yield put(WorkareaLinkProccessAction.getPagingSaga(data.request));
    }
  } catch (error) {

  }
  closeLoading();
}
export function* updateLinkSaga(data) {
  try {
    const request = data.request;
    request.PageIndex = 1;
    const response = yield call(updateLinkService, data.obj);
    if (response.isSuccess) {
      yield put(WorkareaLinkProccessAction.setCallAPIsuccess());
      toastJs.success(i18next.t("wokarealinkproccess.fixsuccess"));
      yield put(WorkareaLinkProccessAction.getPagingSaga(request));
    }
  } catch (error) {

  }
  closeLoading();
}
export default function* WorkareaLinkProccessSaga() {
  yield takeEvery(GET_PAGING_WLP_SAGA, getPagingSaga);
  yield takeEvery(GET_DROPDOWN_PROCESS_BY_PRODUCT_CODE_SAGA, getDropdownProcessSaga);
  yield takeEvery(GET_DROPDOWN_PRODUCT_PROCESS, getDropdownProductSaga);
  yield takeEvery(GET_DATA_TABLE_SAGA, getDataTableSaga);
  yield takeEvery(CREATE_NEW_SAGA, createNewSaga);
  yield takeEvery(GET_DETAIL_WLP_SAGA, getDetailSaga);
  yield takeEvery(DELETE_WLP_SAGA, deleteLinkSaga);
  yield takeEvery(UPDATE_LINK_SAGA, updateLinkSaga);
}
