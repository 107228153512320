import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import ReactHookDemoAction from "./ApproveStepAction";
import {
  getPagingService,
  createApproveDefaultApi,
  updateApproveDefaultApi,
  deleteApproveDefaultApi,
  getDetailApproveApi,
  createApprovalService
} from "./ApproveStepService";
import {
  GET_PAGING_APPROVE_STEP_SAGA,
  CREATE_APPROVE_SAGA,
  UPDATE_APPROVE_SAGA,
  DELETE_APPROVE_SAGA,
  GET_DETAIL_APPROVE_DEFAULT,
  CREATE_APPROVAL_SAGA
} from "./enum/ApproveStepTypeAction";

export function* getPagingDemoHookSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ?? " ";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      SearchTerm: searchTerm,
    };
    const response = yield call(getPagingService, request);
    if (response?.isSuccess) {
      yield put(ReactHookDemoAction.getPagingReducer(response.data));
    }
    closeLoading()
  } catch (error) {
    closeLoading()

  }
}
export function* createApproveDefault(request) {
  try {
    const response = yield call(createApproveDefaultApi, request.data);

    if (response.isSuccess) {
      yield put(ReactHookDemoAction.callCreateOrUpdateReducer());
    }
    closeLoading()
  } catch (error) {
    closeLoading()

  }
}
export function* updateApproveDefault(request) {
  try {
    const response = yield call(updateApproveDefaultApi, request.data);
    if (response.isSuccess) {
      yield put(ReactHookDemoAction.callCreateOrUpdateReducer());
    }
    closeLoading()
  } catch (error) {
    closeLoading()

  }
}
export function* deleteApproveDefault(data) {
  try {
    const response = (yield call(deleteApproveDefaultApi, data));
    if (response.isSuccess) {
      yield put(ReactHookDemoAction.callDeleteDefaultReducer());
    }
    closeLoading()
  } catch (error) {
    closeLoading()

  }
}

export function* getDetailApproveDefault(data) {
  try {
    const response = (yield call(getDetailApproveApi, data));
    if (response.isSuccess) {

      yield put(ReactHookDemoAction.setDetailApproveDefault(response.data));
    }
    closeLoading()
  } catch (error) {
    closeLoading()

  }
}
export function* createApproval(data) {
  try {
    data.req.ListApprovalStepRequest.forEach((item, i) => {
      item.level = i + 1;
    })
    const response = (yield call(createApprovalService, data.req));
    if (response.isSuccess) {
      yield put(ReactHookDemoAction.createApprovalReducer());
    }
    closeLoading()
  } catch (error) {
    closeLoading()

  }
}

export default function* ApproveStepSaga() {
  yield takeEvery(GET_PAGING_APPROVE_STEP_SAGA, getPagingDemoHookSaga);
  yield takeEvery(CREATE_APPROVE_SAGA, createApproveDefault);
  yield takeEvery(UPDATE_APPROVE_SAGA, updateApproveDefault);
  yield takeEvery(DELETE_APPROVE_SAGA, deleteApproveDefault);
  yield takeEvery(GET_DETAIL_APPROVE_DEFAULT, getDetailApproveDefault);
  yield takeEvery(CREATE_APPROVAL_SAGA, createApproval);
}
