export const takenPackagePageLag = {
    language: "vn",
    takenPackagePage: {
        nodataTable: "Không có dữ liệu để hiển thị",
        scanPackageCode: {
            placeHolder: "Mã phiếu lấy hàng",
            Or: "Hoặc",
            FormPickingTicket: {
                title: "Chọn phiếu lấy hàng",
                btn: "Chọn phiếu từ danh sách",
                Pickinglistcode: "Mã phiếu lấy hàng",
                Sloctransferto: "Khu vực chuyển hàng tới",
                Action: "Thao tác",
                placeHolder: {
                    Pickinglistcode: "Nhập mã phiếu lấy hàng",
                    Sloctransferto: "Nhập khu vực chuyển hàng tới",
                    btn: "Tìm kiếm",
                }
            },
        },
        ListPicking: {
            titleInputPositionTakenPackage: "Scan vị trí lấy hàng",
            placeHolder: {
                positionTakenPackage: "Vị trí lấy hàng",
                packageCode: "Nhập package code",
                qtyTaken: "Nhập số lượng lấy hàng",
            },
            btn: {
                endLocation: "Kết thúc lấy hàng tại vị trí",
                moveToNextLocation: "Sang vị trí mới",
                checkBoxGetAll: "Lấy hết",
                confirmDoneLocation: "Hoàn thành lấy hàng",
            },
            table: {
                Sloctakegood: "Sloc lấy hàng",
                Goodscode: "Mã hàng hóa",
                Qtypicking: "Số lượng cần lấy",
                Qtytaken: "Số lượng đã lấy",
                Unit: "Đơn vị",
                Action: "Thao tác",
            },
            error: {
                errorQty: "Số lượng không hợp lệ!",
            },
            toastJs: {
                isDisablePositionsTakenInput: "Vị trí lấy hàng đang bị bỏ trống",
                isDisablePackageCodeInput: "Package code đang bị bỏ trống",
                positionTakenPackage: "Vị trí lấy hàng đang bị bỏ trống",
                isAvaibleToTakePostion: "Vị trí lấy hàng không được bỏ trống",
                postionEmpty: "Vị trí này đã được lấy trước đó",
                isLocationExist: "Vị trí vừa nhập không tồn tại trong danh sách phiếu",
                positionTakenPackageEmpty: "Vị trí lấy hàng không được bỏ trống",
                packageCode: "Package code không được bỏ trống",
                qtyTaken: "Số lượng không được bỏ trống !",
                qtyValue: "Số lượng không hợp lệ !",
                packageCodeAfterScan: "Số lượng nhập đang lớn hơn số lượng trong package",
                qtySum: "Số lượng hàng đã lấy sẽ vượt quá số lượng hàng yêu cầu",
                isAvailbleQtyToUpdate: "Số lượng đã lấy vượt quá với Số lượng cần lấy",
                Sloctransferto: "Vị trí cất hàng không hợp lệ",
                Slocstored: "package này không thuộc vị trí lấy hàng",
                isGoodCodeAvaible: "Mã hàng hóa của package không phù hợp với phiếu lấy hàng tại vị trí hiện tại",
                isPackageInPickingList: "Số lượng không đủ điều kiện để lấy",
                isPackageInPickingListError: "Package không thuộc sloc lấy hàng",
                Package: "Package không hợp lệ",
                callPickingNextLocation: "Di chuyển vị trí thành công",
                callPickingNextLocationTrue: "Lấy hàng theo phiếu thành công",
            }
        },
        PopUpComfirmIfExistPackageNotTaken: {
            title: "Danh sách những mặt hàng cần lấy tại vị trí chưa hoàn thành",
            table: {
                Goodscode: "Mã",
                Qtypicking: "Số lượng yêu cầu",
                Qtytaken: "Số lượng đã lấy",
                Des: "Giải trình",
                placeHolder: {
                    Des: "Nhập nguyên nhân",
                },
            },
            error: {
                Des: "Giải trình không được bỏ trống",
            },
        },
        PopUpComfirmAllPackage: {
            titleNotgetAll: "Xác nhận không lấy tất cả package",
            titleGetAll: "Xác nhận lấy tất cả package",
            comfirmNotgetAll: "Bạn xác nhận không lấy tất cả só lượng package ?",
            comfirmGetAll: "Bạn xác nhận lấy tất cả só lượng package ?",
        },
        LocationInput: {
            slocTranferTo: "Nhập vị trí cất hàng",
        },
        DeletePackageForm: {
            title: "Xoá thông tin vừa nhập",
            table: {
                Sloctakegood: "Sloc lấy hàng",
                Goodscode: "Mã hàng hóa",
                Takeallpackage: "Lấy cả package",
                Qtytaken: "Số lượng đã lấy",
                Action: "Thao tác",
            },
            toast: {
                deleteSucces: "Xóa thông tin thành công",
            }
        },
        BtnConfirmTicket: {
            btn: "Chọn",
            title: "Xác nhận chọn phiếu lấy hàng",
            confirm: "Bạn có chắc chắn muốn chọn phiếu lấy hàng này?"
        }
    },
}