import { TRUE } from "sass";
import {
  GET_PAGING_DELIVERY_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_DELIVERY_REDUCER,
  CREATE_DELIVERY_REDUCER,
  UPDATE_DELIVERY_REDUCER,
  GET_INFO_DELIVERY_BY_ID_REDUCER,
  GET_PAGING_RETAIL_DELIVERY_REDUCER,
  DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER,
  SET_TRANSACTION_DETAIL_LIST_REDUCER,
  RESET_GET_INFO_DELIVERY_BY_ID_REDUCER,
} from "./enum/TypeDeliveryAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,
  isDeleteSuccess: false,
  isCreateUpdateSuccess: false,
  responseApi: null,
  data: null,
  isGetDetailSuccess:false,
  listDataRetailDelivery: [],
  isGetLstDataRetailDelivery: false,

  //biến dành cho tính toán dự trù khi chọn vật tư y tế
  listDeliveryDetailPredictabilityRetailListProduct: [],
  isGetLstDeliveryDetailPredictabilityRetailListProduct: false,

  //Danh sách vật tư y tế của từng phiếu xuất 
  listTransactionDetail: [],
};

const DeliveryReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_DELIVERY_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case GET_PAGING_RETAIL_DELIVERY_REDUCER:
      if (action.data) {
        state.listDataRetailDelivery = action.data;
        state.isGetLstDataRetailDelivery = true;
      }
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      state.isGetLstDataRetailDelivery = false;
      state.responseApi = null;
      return {
        ...state,
      };
    case CREATE_DELIVERY_REDUCER:
      state.isCreateUpdateSuccess = true;
      state.responseApi = action.data;
      return {
        ...state,
      };
    case GET_INFO_DELIVERY_BY_ID_REDUCER:
      state.isGetDetailSuccess = true;
      state.data = action.data;
      return {
        ...state,
      };
      case RESET_GET_INFO_DELIVERY_BY_ID_REDUCER:
      state.isGetDetailSuccess = false;
      return {
        ...state,
      };
    case UPDATE_DELIVERY_REDUCER:
      state.isCreateUpdateSuccess = true;
      state.responseApi = action.data;
      return {
        ...state,
      };


    case SET_TRANSACTION_DETAIL_LIST_REDUCER:
      if (action.data)
        state.listTransactionDetail = action.data;
      return { ...state };


    case DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER:
      if (action.data) {
        state.listDeliveryDetailPredictabilityRetailListProduct = action.data;
        state.isGetLstDeliveryDetailPredictabilityRetailListProduct = true;
      }
      return { ...state };
    case DELETE_DELIVERY_REDUCER:
      state.isDeleteSuccess = true;
      return {
        ...state,
      };
    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };
    default:
      return { ...state };
  }
};

export default DeliveryReducer;
