import { put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import UploadFileAction from "./CommonUploadAction";

const { SET_UPLOAD_FILE, CLEAR_UPLOAD_FILE_REDUCER } = require("./enum/TypeAction");

export function* setUploadFileDataSaga(data) {
    try {
        if (data && data.length) {
            yield put(UploadFileAction.setFileUpload(data));
        }
    } catch (error) {
    } 
}
export default function* CommonUploadFileSaga() {
    yield takeEvery(SET_UPLOAD_FILE, setUploadFileDataSaga);
}
