import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_PAGING_PLANNING_DETAIL_SAGA,
} from "./enum/TypePlanningDetailAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import PlanningDetailAction from "./PlanningDetailAction";
import { getListPlanningDetailService } from "./PlanningDetailService";

export function* getPagingSaga(data) {
    try {
        const request = {
            PageIndex: data.pageIndex,
            PageSize: data.pageSize,
            SearchTerm: data.searchTerm,
            PlanningId:data.planningId,
            TypeOverLimit: data.typeOverLimit,
            WarehouseId : data.warehouseId,
            HospitalId:data.hospitalId,
        };
        const response = yield call(getListPlanningDetailService, request);
        if (response) {
            if (response.Items.length <= 0 && request.PageIndex > 1) {
                yield put(
                    PlanningDetailAction.getPagingSaga(
                        request.PageIndex - 1,
                        request.PageSize,
                        request.SearchTerm
                    )
                );
            } else {
                yield put(PlanningDetailAction.getPagingReducer(response));
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();
    }
}

export default function* PlanningSaga(data) {
    yield takeEvery(GET_PAGING_PLANNING_DETAIL_SAGA, getPagingSaga);
}
