export const productionCoordinator = {
    language: "en",
    productionCoordinator: {
        toast: {
            completed: "Work order has been completed, cannot be canceled"
        },
    },
    table: {
        "PRODUCTION_ORDER": "PRODUCTION ORDER",
        "PRODUCT": "PRODUCT",
        "PRODUCTION_CENTER": "PRODUCTION CENTER",
        "tentative_start": "Expected start",
        "Expected_ending": "Expected ending",
        "actual_start": "Actual start",
        "realistic ending": "realistic ending",
        "AMOUNT": "AMOUNT",
        "STATUS": "Status",
        "Change_status": "Change Status ",
    },
    popup: {
        "Pause_command": "Pause command ",
        "Pause_command_comment": "Are you sure you want to pause these working commands? ",
        "Run_command": "Run command",
        "Run_command_comment": "Are you sure you want to pause these working commands? ",
        "Cancel_the_work_order": "Cancel the work order ",
        "Cancel_the_work_order_cmt": "Are you sure you want to cancel this work order?",
    },
    form: {
        field: {
            "Production_order": "Production order ",
            "Product": "Product ",
            "Production_Center": "PRODUCTION CENTER ",
            "The_expected_number": "The expected number ",
            "Expected_start": "Expected start ",
            "Expected_ending": "Expected ending ",
            "STATUS": "Status ",
            "Realistic_start": "Actual start ",
            "Realistic_ending": "realistic ending ",
            "run": "Chạy ",
            "Pause": "Pause ",
            "Complete": "Complete ",
            "Cancel": "Pause command ",
            "Pause_command": "Cancel ",
        },
        complete: "Complete work order ",
        update: "Update work order ",
        view: "Work order details ",
    },
    schedule: {
        Quantity: "Amount",
        Status: "Status",
        Expected: "Expected",
        Reality: "Reality",
    }
};