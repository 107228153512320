import {
    GET_PAGING_CAT_BARCODE_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    DELETE_CAT_BARCODE_REDUCER,
    CALL_CREATE_UPDATE_SUCCESS_REDUCER,
    GET_LIST_CAT_BARCODE_REDUCER,GET_LIST_ALL_FIELD_CAT_BARCODE_REDUCER
  } from "./enum/TypeCatBarcodeAction";
  
  const stateDefault = {
    listData: [],
    isGetLstData: false,
    isDeleteSuccess: false,
    isCreateUpdateSuccess: false,
    listUnits: [],
    listFieldBarcode:[],
  };
  const CatBarcodeReducer = (state = stateDefault, action) => {
    switch (action.type) {
      case GET_PAGING_CAT_BARCODE_REDUCER:
        if (action.data) {
          state.listData = action.data;
          state.isGetLstData = true;
        }
        return { ...state };
      case DELETE_CAT_BARCODE_REDUCER:
        state.isDeleteSuccess = true;
        return { ...state };
      case GET_LIST_ALL_FIELD_CAT_BARCODE_REDUCER:
        state.listFieldBarcode = action.data;
      return { ...state };
      case CALL_CREATE_UPDATE_SUCCESS_REDUCER:
        state.isCreateUpdateSuccess = true;
        return { ...state };
      case GET_LIST_CAT_BARCODE_REDUCER:
        state.listUnits = action.data;
        return { ...state };
      case CALL_DEFAULT_REDUCER:
        state.isGetLstData = false;
        state.isDeleteSuccess = false;
        state.isCreateUpdateSuccess = false;
        state.isListFieldBarcode = false;
        return {
          ...state,
        };
      case CLEAR_DATA_REDUCER:
        return {
          state: stateDefault,
        };
  
      default:
        return { ...state };
    }
  };
  
  export default CatBarcodeReducer;
  