import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";

import {
  GET_ALL_PRODUCTION_REQUIRMENT_API,
  CREATE_PRODUCTION_REQUIRMENT_API,
  UPDATE_PRODUCTION_REQUIRMENT_API,
  DELETE_PRODUCTION_REQUIRMENT_API,
  GET_DETAIL_PRODUCTION_REQUIRMENT_API,
} from "./enum/typeProductRequirementApi";

export async function getPagingService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_ALL_PRODUCTION_REQUIRMENT_API,
    data
  );
}

export async function createDataService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_PRODUCTION_REQUIRMENT_API,
    data
  );
}

export async function getDetailService(requirmentMember) {
  return await CommonBase.getAsync(
    configApp.apiGateWay +
    GET_DETAIL_PRODUCTION_REQUIRMENT_API +
    requirmentMember,
    ""
  );
}

export async function updateDataService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_PRODUCTION_REQUIRMENT_API,
    data
  );
}

export async function deleteDataService(codeId) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + DELETE_PRODUCTION_REQUIRMENT_API + codeId,
    ""
  );
}
