import { closeLoading } from "src/shared/common/LoadingAction";
import {GET_LOG_IMPACT_DATA_PAGINATION_SAGA} from "./enum/LogImpactTypeAction";
import {call, put, takeEvery} from "redux-saga/effects";
import LogImpactAction from "./LogImpactAction";
import {getLogImpactDataPaginationService} from "./LogImpactService";

export function* getLogImpactDataPagination(body) {

    try {
    // debugger;
    const params = 
            {
                SearchTerm: body.params.SearchTerm ? body.params.SearchTerm : "",
                PageIndex: body.params.PageIndex ? body.params.PageIndex : 1,
                PageSize: body.params.PageSize ? body.params.PageSize : 20,
                StartDate: body.params.StartDate ? body.params.StartDate : "",
                EndDate: body.params.EndDate ? body.params.EndDate : "",
                Type:  body.params.Type ? body.params.Type : "",
            }

        const response = yield call(getLogImpactDataPaginationService, params);
        // if (!response.isSuccess && !response.Items) {
        //     yield put(LogManagementAction.getPaginationClear());
        //     yield call(closeLoading)
        //     closeLoading()
        //     return
        // }
        yield put(LogImpactAction.getPaginationReducer(response));
    } catch (e) {
        console.log("getLogManagementDataPagination", e)
    }

    yield put(LogImpactAction.getPaginationClear());
    yield call(closeLoading)
}

export default function* LogImpactSaga() {
    yield takeEvery(GET_LOG_IMPACT_DATA_PAGINATION_SAGA, getLogImpactDataPagination);
}
