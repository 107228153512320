export const UnitVI = {
    language: "vi",
    unit: {
        searchUnit: "Đơn vị, mô tả",
        tableUnit: {
            unitCode: "Đơn vị",
            description: "Mô tả"
        },
        titleForm: {
            create: "Thêm đơn vị",
            update: "Cập nhật đơn vị",
            view: "Xem thông tin đơn vị"
        },
        rule: {
            unitErr: "Mã đơn vị không đúng định dạng",
            unitLength: "Mã đơn vị không được quá 25 kí tự",
            unitNotNull: "Mã đơn vị không được bỏ trống",
            descriptionLength: "Mô tả không được quá 255 ký tự"
        },
        delete: {
            title: "Xóa đơn vị",
            description: "Bạn có chắc chắn muốn xóa đơn vị này?"
        },
        toastJs: {
            createOK: "Tạo mới đơn vị thành công",
            updateOK: "Cập nhật đơn vị thành công",
            deleteOK: "Xóa thành công đơn vị"
        }
    }
}