import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import { toast } from 'react-toastify';
import RecordResultAction from "./RecordResultAction";
import * as type from "./enum/RecordResultTypeAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import {
    getListSampleDetailService,
    getListSampleService,
    saveListSampleServie,
    saveEvluationService,
    getDropDownCheckListByCode
} from "./RecordResultService";

export function* getListSampleDetailSaga(searchtemp) {
    try {
        let obj = {
            code: searchtemp.data.code,
            type: searchtemp.data.type
        }
        let response = yield call(getListSampleDetailService, obj);
        if (response.isSuccess) {
            yield put(RecordResultAction.getListSampleDetailReducer(response.data));
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* getListSampleSaga(data) {
    try {
        let response = yield call(getListSampleService, data.itemcode, data.lotcode);
        if (response.isSuccess) {
            yield put(RecordResultAction.getListSamleReducer(response.data));
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}

export function* saveListSampleSaga(request) {
    try {
        const result = yield call(saveListSampleServie, request.data);
        if (result.isSuccess) {
            toast.success(i18next.t("recordResultLOT.toast.createSuccess"), {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(RecordResultAction.callResultFromAPI());
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* saveEvaluationSaga(request) {
    try {
        const result = yield call(saveEvluationService, request.data);
        if (result.isSuccess) {
            yield put(RecordResultAction.callChangeData());
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* getDropDownByCodeSaga(data) {
    try {
        const response = yield call(getDropDownCheckListByCode, data.request);
        if (response?.data) {
            yield put(RecordResultAction.getDropDownReducer(response.data));
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export default function* RecordResultSaga() {
    yield takeEvery(type.GET_LIST_SAMPLE_DETAIL_SAGA, getListSampleDetailSaga);
    yield takeEvery(type.GET_LIST_SAMLE_SAGA, getListSampleSaga);
    yield takeEvery(type.SAVE_CHECK_LIST_SAMPLE, saveListSampleSaga);
    yield takeEvery(type.SAVE_VALUATION, saveEvaluationSaga);
    yield takeEvery(type.DROPDOWN_BY_CODE_SAGA, getDropDownByCodeSaga);
}