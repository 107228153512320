import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import CommonDropdownAction from "./CommonDropdownAction";
import {
  getDropDownMMProduction,
  getDropDownMaterial,
  getDropDownMatainer,
  getCommonDropdownByCode
} from "./CommonDropdownService";
import {
  GET_COMMON_DROPDOWN_MMPRODUCTION,
  GET_COMMON_DROPDOWN_MATERIAL,
  GET_COMMON_DROPDOWN_MATAINER,
  GET_WORK_COMMON_DROPDOWN_BY_CODE_SAGA,
} from "./enum/CommonDropdownAction";


export function* getDropDownMMProductionSaga(request) {
  try {
    const type = request.typeDropdown;
    const search = request.searchTerm ?? " ";
    const RequestData = {
      type: type,
      search: search,
    };
    const response = yield call(getDropDownMMProduction, RequestData);
    if (response.isSuccess) {
      let responseData = response.data;
      let data = [];
      data = responseData;
      yield put(CommonDropdownAction.setCommonDropDownMMProduction(type, data, request.typeIndentifier));
    }
  } catch (error) {

  } finally {
    closeLoading();
  }
}
export function* getDropDownMaterialSaga(request) {
  try {
    const type = request.typeDropdown;
    const search = request.searchTerm ?? " ";
    const RequestData = {
      type: type,
      search: search,
    };
    const response = yield call(getDropDownMaterial, RequestData);
    if (response.isSuccess) {
      let responseData = response.data;
      let data = [];
      data = responseData;
      yield put(CommonDropdownAction.setCommonDropDownMaterial(type, data));
    }
  } catch (error) {

  } finally {
    closeLoading();
  }
}
export function* getDropDownMatainerSaga(request) {
  try {
    const type = request.typeDropdown;
    const search = request.searchTerm ?? " ";
    const RequestData = {
      type: type,
      search: search,
    };
    const response = yield call(getDropDownMatainer, RequestData);
    if (response.isSuccess) {
      let responseData = response.data;
      let data = [];
      data = responseData;
      yield put(CommonDropdownAction.setCommonDropDownMatainer(type, data));
    }
  } catch (error) {

  } finally {
    closeLoading();
  }
}
export function* getWorkDropdownByCode(request) {
  try {
    const type = request.typeDropdown;
    const RequestData = {
      type: request.typeDropdown,
      Search: request.searchTerm,
      Code: request.Code,
      Status: request.Status
    };
    const response = yield call(getCommonDropdownByCode, RequestData);
    if (response.isSuccess) {
      let responseData = response.data;
      let data = [];
      data = responseData;
      yield put(CommonDropdownAction.getCommonDropdownByCodeReducer(type, data, request.key));
    }
  } catch (error) {

  } finally {
    closeLoading();
  }
}
export default function* ReactHookDemoSaga() {
  yield takeEvery(GET_COMMON_DROPDOWN_MMPRODUCTION, getDropDownMMProductionSaga);
  yield takeEvery(GET_COMMON_DROPDOWN_MATERIAL, getDropDownMaterialSaga);
  yield takeEvery(GET_COMMON_DROPDOWN_MATAINER, getDropDownMatainerSaga);
  yield takeEvery(GET_WORK_COMMON_DROPDOWN_BY_CODE_SAGA, getWorkDropdownByCode);
}
