import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_DROPDOWN_WORKORDER_SERVICE, CHANGE_SHIPMENT_PRODUCT_SERVICE, ENFORCEMENT_PRODUCT_IDENTIFIER_SERVICE, GET_DATA_PAGING_SERVICE,
    GET_DETAIL_PRODUCT_IDENTIFIER_SERVICE, GET_DETAIL_LIST_PRODUCT_IDENTIFIER_SERVICE, REPRINT_STAMPS_SERVICE
} from "./enum/ProductIdentifierTypeService";
export async function getDropdownWorkorderService(search) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROPDOWN_WORKORDER_SERVICE + (search == "" ? "" : "?search=" + search));
}
export async function changeShipmentProductService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + CHANGE_SHIPMENT_PRODUCT_SERVICE + request.productcode + "/" + request.qtyShipment);
}
export async function enforcementProductIdentifierService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + ENFORCEMENT_PRODUCT_IDENTIFIER_SERVICE, request);
}
export async function getDataPagingService(request) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DATA_PAGING_SERVICE, request);
}
export async function getDetailProductIdentifierService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DETAIL_PRODUCT_IDENTIFIER_SERVICE + request);
}
export async function getListDetailProductIdentifierService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DETAIL_LIST_PRODUCT_IDENTIFIER_SERVICE, request);
}
export async function reprintProductIdentifierService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + REPRINT_STAMPS_SERVICE, request);
}