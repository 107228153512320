// "MenuId": "63ca6efa-e8e4-4a9b-b60e-0570c76706ce",
// "MenuName": "Báo cáo đầu chuyền",
// "MenuTitle": "Báo cáo đầu chuyền",
// "MenuType": 1,
// "Parent": "c268a4ae-ea81-40e6-a253-6f424ba9d9ad",
// "ActionApi": 0,
// "ActionCode": "",
// "Status": 0,
// "IsMenuAction": true,
// "IsHorizontal": false,
// "Islevel": 2,
// "IsDefault": false,
// "comPath": "src/components/page/QuanlityManager/Report/HeadlineReport/Index",
// "path": "/bao-cao-day-chuyen",
// "imagePath": "",
// "sortOrder": 1,
// "permissionMenus": null,
// "actionMenus": null

export const pageData = [
    {
        to: '/trang-chu',
        icon: '/assets/img/icon/home-icon.svg',
        activeIcon: '/assets/img/icon/home-icon-active.svg',
        activeIconCollapse: '/assets/img/icon/home-icon-collapse.svg',
        title: 'Trang chủ',
        id: 'trangchu',
        parentId: null,
        level: 0,
        ancestor: 'trangchu'
    },

    {
        to: null,
        icon: '/assets/img/icon/asset-management-icon.svg',
        activeIcon: '/assets/img/icon/asset-management-active.svg',
        activeIconCollapse: '/assets/img/icon/asset-management-active-collapse.svg',
        title: 'Quản lý tài sản',
        id: 'quanlytaisan',
        level: 0,
        parentId: null,
        ancestor: 'quanlytaisan'
    },
    {
        to: '/danh-sach-phe-duyet',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Danh sách phê duyệt',
        id: 'danhsachpheduyet',
        level: 1,
        parentId: 'quanlytaisan',
        ancestor: 'quanlytaisan'
    },
    {
        to: '/danh-sach-phe-duyet/xu-ly-phe-duyet/:id',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Phê duyệt yêu cầu',
        id: 'pheduyettailieu',
        level: 2,
        parentId: 'danhsachpheduyet',
        ancestor: 'quanlytaisan',
        invisible: true
    },
    {
        to: "",
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Quản lý thông tin tài sản',
        id: 'quanlythongtintaisan',
        level: 1,
        parentId: 'quanlytaisan',
        ancestor: 'quanlytaisan'
    },
    {
        to: '/ma-tai-san/tao-moi',
        icon: null,
        activeIcon: null,
        title: 'Taọ mới mã tài sản',
        id: 'yeucautaomataisan',
        level: 2,
        parentId: 'quanlythongtintaisan',
        ancestor: 'quanlytaisan',
        invisible: true
    },
    {
        to: '/trinh-duyet',
        icon: null,
        activeIcon: null,
        title: 'Trình duyệt',
        id: 'trinhduyet',
        level: 2,
        parentId: 'quanlythongtintaisan',
        ancestor: 'quanlytaisan',
        invisible: true
    },
    {
        to: '/danh-sach-ma-tai-san',
        icon: null,
        activeIcon: null,
        title: 'Danh sách yêu cầu',
        id: 'danhsachmataisan',
        level: 2,
        parentId: 'quanlythongtintaisan',
        ancestor: 'quanlytaisan',
    },
    {
        to: '/danh-sach-tai-san',
        icon: null,
        activeIcon: null,
        title: 'Danh sách tài sản',
        id: 'assetManager',
        level: 2,
        parentId: 'quanlythongtintaisan',
        ancestor: 'quanlytaisan'
    },
    {
        to: '/dieu-chuyen-tai-san',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Điều chuyển tài sản',
        id: 'dieuchuyentaisan',
        level: 1,
        parentId: 'quanlytaisan',
        ancestor: 'quanlytaisan'
    },
    {
        to: '/tao-moi-yeu-cau-dieu-chuyen',
        icon: null,
        activeIcon: null,
        title: 'Tạo yêu cầu',
        id: 'yeucaudieuchuyen',
        level: 2,
        parentId: 'dieuchuyentaisan',
        ancestor: 'quanlytaisan',
        invisible: true
    },
    {
        to: '/kiem-ke-tai-san',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Kiểm kê tài sản',
        id: 'kiemketaisan',
        level: 1,
        parentId: 'quanlytaisan',
        ancestor: 'quanlytaisan'
    },
    {
        to: '/bao-cao',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Báo cáo',
        id: 'baocao',
        level: 1,
        parentId: 'quanlytaisan',
        ancestor: 'quanlytaisan'
    },

    {
        to: null,
        icon: '/assets/img/icon/window-icon.svg',
        activeIcon: '/assets/img/icon/window-icon-active.svg',
        activeIconCollapse: '/assets/img/icon/window-icon-active-collapse.svg',
        title: 'Danh mục',
        id: 'danhmuc',
        level: 0,
        parentId: null,
        ancestor: null
    },
    {
        to: null,
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Thiết lập phê duyệt',
        id: 'thietlappheduyet',
        level: 1,
        parentId: 'danhmuc',
        ancestor: 'danhmuc'
    },
    {
        to: '/quy-trinh-phe-duyet',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Quy trình phê duyệt',
        id: 'quytrinhpheduyet',
        parentId: 'thietlappheduyet',
        level: 2,
        ancestor: 'danhmuc'
    },
    {
        to: '/cau-hinh-quy-trinh-phe-duyet',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Cấu hình quy trình phê duyệt',
        id: 'cauhinhquytrinhpheduyet',
        parentId: 'thietlappheduyet',
        level: 2,
        ancestor: 'danhmuc'
    },
    {
        to: '/ke-hoach-kiem-ke',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Kế hoạch kiểm kê',
        id: 'kehoachkiemke',
        parentId: 'quytrinhpheduyet',
        level: 2,
        ancestor: 'quanlytaisan'
    },
    {
        to: '/ke-hoach-kiem-ke/tao-moi',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Tạo mới yêu cầu',
        id: 'taomoiphieukiemke',
        parentId: 'kehoachkiemke',
        level: 2,
        ancestor: 'quanlytaisan'
    },
    {
        to: '/ke-hoach-kiem-ke/xem-chi-tiet/:id',
        icon: null,
        activeIcon: null,
        activeIconCollapse: null,
        title: 'Xem phiếu kiểm kê',
        id: 'xemphieukiemke',
        parentId: 'kehoachkiemke',
        level: 2,
        ancestor: 'quanlytaisan'
    }

]
