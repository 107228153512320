export const poLag = {
    language: "vn",
    po: {
        ordercode: "Mã đơn hàng",
        supplier: "Nhà cung cấp",
        dateorder: "Ngày đặt hàng",
        esdate: "Ngày giao hàng dự kiến",
        orderer: "Người đặt hàng",
        deleverystatus: "Tình trạng giao hàng",
        createdoerror: "PO đã hoàn thành giao hàng, không thể tạo DO",
        canteditpo: "Đơn đặt hàng PO đã có giao dịch không thể sửa",
        cantdeletepo: "Đơn đặt hàng PO đã có giao dịch không thể xóa",
        ordermanager: "Quản lý đặt hàng - PO",
        placerhoder: "Mã đơn hàng, tên nhà cung cấp, tình trạng giao hàng",
        confirmdelete: "Xoá đơn đặt hàng PO",
        deletequestions: "Bạn có chắc chắn muốn xóa đơn PO này?",
        form: {
            validatepocode: "Mã tạo ra không chứa kí tự đặc biệt hoặc khoảng trống",
            pocoderequired: "Mã đơn đặt hàng không được để trống",
            pocodelength: "Mã đơn hàng không được vượt quá 50 kí tự",
            partnercoderequired: "Nhà cung cấp không được để trống",
            dateorderrequired: "Ngày đặt hàng không được để trống",
            datadeleveryrequired: "Ngày giao hàng không được để trống",
            qtyorderwrong: "Phải nhập vào số nguyên dương",
            qtyorderrequired: "Số lượng đặt không được để trống",
            goodscoderequied: "Mã hàng hóa không được để trống",
            number0: "Số lượng đặt phải lớn hơn 0",
            numberthannumber: "Số lượng đã nhận phải nhỏ hơn hoặc bằng số lượng đặt",
            comparedate: "Ngày giao hàng dự kiến không được diễn ra trước ngày đặt hàng",
            listnull: "Đơn đặt hàng yêu cầu phải có các mặt hàng",
            commoncode: "Mã hàng hóa",
            description: "Mô tả hàng hóa",
            qtyorder: "Số lượng đặt",
            qtyreveiced: "Số lượng đã nhận",
            createpo: "Tạo đơn đặt hàng - PO",
            updatepo: "Cập nhật đơn đặt hàng - PO",
            detailpo: "Xem thông tin chi tiết - PO",
            addrow: "Thêm hàng",
        },
        toastJs: {
            createOK: "Thêm mới dữ liệu PO thành công",
            updateOK: "Cập nhật dữ liệu PO thành công",
            deleteOK: "Đã xóa dữ liệu PO thành công"
        }
    }
}