import * as type from "./enum/RecordResultTypeAction";
const RecordResultAction = {
    getListSampleDetailSaga: (data) => {
        return {
            type: type.GET_LIST_SAMPLE_DETAIL_SAGA,
            data: data,
        }
    },
    getListSampleDetailReducer: (data) => {
        return {
            type: type.GET_LIST_SAMPLE_DETAIL_REDUCER,
            data: data
        }
    },
    getListSampleSaga: (itemcode,lotcode) => {
        return {
            type: type.GET_LIST_SAMLE_SAGA,
            itemcode: itemcode,
            lotcode:lotcode,
        }
    },
    getListSamleReducer: (data) => {
        return {
            type: type.GET_LIST_SAMPLE_REDUCER,
            data: data
        }
    },
    saveCheckListSample: (data) => {
        return {
            type: type.SAVE_CHECK_LIST_SAMPLE,
            data: data
        }
    },
    saveEvaluation: (data) => {
        return {
            type: type.SAVE_VALUATION,
            data: data
        }
    },
    callChangeData:()=>{
        return {
            type:type.CALL_CHANGE_SUCCESS
        }
    },
    getDropDownByCodeSaga: (request) => {
        return {
            type: type.DROPDOWN_BY_CODE_SAGA,
            request: request
        }
    },
    getDropDownReducer: (data) => {
        return {
            type: type.DROPDOWN_BY_CODE_REDUCER,
            data: data
        }
    },
    callResultFromAPI: () => {
        return {
            type: type.CALL_RESULT_RECORD_FROM_API
        }
    },
    callDefaultFromAPI: () => {
        return {
            type: type.CALL_DEFAULT_RECORD_FROM_API
        }
    },
}
export default RecordResultAction;