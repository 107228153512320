export const ProductionLineLaguage = {
    language: "vi",
    ProductionLineLaguage: {
        workCenter: "Trung tâm sản xuất",
        palaceHolerWorkCenter: 'Chọn trung tâm sản xuất',
        workUnit: 'Đơn vị sản xuất',
        workOrder: 'Lệnh sản xuất',
        palaceHolerWorkOrder: 'Chọn lệnh sản xuất',
        resetWorkCenter: 'Cài đặt lại TTSX',
        resetWorkUnit: 'Cài đặt lại ĐVSX',
        stage: 'Công đoạn',
        labelWorkUnit: 'Nhập mã NLĐ hoặc máy móc để cài đặt',
        palaceHolderWorkUnit: 'Nhập mã đơn vị sản xuất',
        quantityWorker: 'Số người',
        quantityMachine: 'Số máy',
        nameListSkill: 'Danh sách kỹ năng cần cho công đoạn',
        nameListMachine: 'Danh sách máy móc cần cho công đoạn',
        mesEmptyCode: 'Mã code đã tồn tại, vui lòng nhập mã khác',
        mesEmptyObject: 'Không tìm thấy con người hay máy móc',
        mesNotFoundUnit: 'Không tìm thấy công đoạn trong danh sách',
        machine: "Máy ",
        inunit: "  đang thuộc đơn vị ",
        ofcenter: " của trung tâm ",
        wantchange: " bạn có muốn thay đổi?",
        worker: "Công nhân ",
        table: {
            quantityNeed: 'Số lượng cần',
            quantityRealy: 'Đã đăng ký',
            typeMachine: 'Loại máy',
        },
        tableWorker: {
            code: 'Mã',
            nameWorker: 'Tên người',
            skill: 'Kỹ năng',
            action: 'Thao tác'
        },
        tableMachine: {
            code: 'Mã',
            nameMachine: 'Tên máy',
            nameTypeMachine: 'Loại máy',
        }
    }

}