import {
    GET_PAGING_ASSETMENT_SAGA,
    GET_PAGING_ASSETMENT_REDUCER,

    CLEAR_DATA_ASSETMENT_LIST_REDUCER,
    CALL_DEFAULT_ASSETMENT_LIST_REDUCER,

    EXPORT_EXCEL_ASSETMENT_LIST_SAGA,

    GET_ASSET_BY_ID_SAGA,
    GET_ASSET_BY_ID_REDUCER,
    SET_IS_OPEN_PRINT_STAMP_MODAL,

    UPDATE_ASSET_SAGA,
    UPDATE_ASSET_REDUCER,

    UPDATE_MANY_ASSET_SAGA,
    UPDATE_MANY_ASSET_REDUCER,

    GET_LIST_ASSET_HISTORY_SAGA,
    GET_LIST_ASSET_HISTORY_REDUCER,

    GET_ALL_ASSET_STAMP_HISTORY_SAGA,
    GET_ALL_ASSET_STAMP_HISTORY_REDUCER,

    CLOSE_PAGE_UPDATE_MANY_ASSET_REDUCER,
    REQUEST_PRINT_STAMP,
    GET_PRINT_STAMP_DATA,
    SET_LOADED_STAMP_PRINT,
    UPDATE_ASSET_OK_SAP_FAILED_REDUCER
} from "./enum/TypeAssetmentListAction";

const AssetmentListAction = {
    getPagingSaga: (request) => {
        return {
            type: GET_PAGING_ASSETMENT_SAGA,
            request: request
        };
    },

    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_ASSETMENT_REDUCER,
            data: data,
        };
    },

    exportExcelAssetmentListSaga: (request) => {
        return {
            type: EXPORT_EXCEL_ASSETMENT_LIST_SAGA,
            request: request
        }
    },
    getAssetByIdSaga: (id) => {
        return {
            type: GET_ASSET_BY_ID_SAGA,
            id: id
        }
    },
    getAssetByIdReducer: (data) => {
        return {
            type: GET_ASSET_BY_ID_REDUCER,
            data: data
        }
    },
    UpdateAssetSaga: (request) => {
        return {
            type: UPDATE_ASSET_SAGA,
            request: request
        }
    },
    UpdateAssetReducer: () => {
        return {
            type: UPDATE_ASSET_REDUCER,
        }
    },
    UpdateAssetOkSapFailedReducer: () => {
        return {
            type: UPDATE_ASSET_OK_SAP_FAILED_REDUCER,
        }
    },
    UpdateManyAssetSaga: (listAsset, objectUpdate) => {
        return {
            type: UPDATE_MANY_ASSET_SAGA,
            listAsset: listAsset,
            objectUpdate: objectUpdate
        }
    },
    UpdateManyAssetReducer: () => {
        return {
            type: UPDATE_MANY_ASSET_REDUCER,
        }
    },

    getListAssetHistorySaga: (request) => {
        return {
            type: GET_LIST_ASSET_HISTORY_SAGA,
            request: request
        };
    },

    getListAssetHistoryReducer: (data) => {
        return {
            type: GET_LIST_ASSET_HISTORY_REDUCER,
            data: data,
        };
    },
    setIsOpenPrintStampModal: (isOpenStampModal) => {
        return {
            type: SET_IS_OPEN_PRINT_STAMP_MODAL,
            isOpenStampModal: isOpenStampModal
        }
    },
    GetAllAssetStampHistorySaga: (id) => {
        return {
            type: GET_ALL_ASSET_STAMP_HISTORY_SAGA,
            id: id
        }
    },
    GetAllAssetStampHistoryReducer: (data) => {
        return {
            type: GET_ALL_ASSET_STAMP_HISTORY_REDUCER,
            data: data
        }
    },
    closePageUpdateManyAssetReducer: () => {
        return {
            type: CLOSE_PAGE_UPDATE_MANY_ASSET_REDUCER,
        };
    },
    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_ASSETMENT_LIST_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_ASSETMENT_LIST_REDUCER,
        };
    },
    requestPrintStamp: (data) => {
        return {
            type: REQUEST_PRINT_STAMP,
            data: data
        }
    },
    getPrintStampData: (data) => {
        return {
            type: GET_PRINT_STAMP_DATA,
            data
        }
    },
    setIsLoadedDataPrint : (isLoadedDataSprint) => {
        return {
            type: SET_LOADED_STAMP_PRINT,
            isLoadedDataSprint: isLoadedDataSprint
        }
    }
};

export default AssetmentListAction;
