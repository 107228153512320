export const GET_PAGING_DEMO_HOOK_SAGA = "GET_PAGING_DEMO_HOOK_SAGA";
export const GET_PAGING_DEMO_HOOK_REDUCER = "GET_PAGING_DEMO_HOOK_REDUCER";
export const CREATE_DATA_SAGA = "CREATE_DATA_SAGA";
export const CALL_RESULT_DEMO_HOOK_REDUCER = "CALL_RESULT_DEMO_HOOK_REDUCER";
export const UPDATE_DATA_SAGA = "UPDATE_DATA_SAGA";
export const DELETE_DATA_SAGA = "DELETE_DATA_SAGA";
export const DELETE_DATA_REDUCER = "DELETE_DATA_REDUCER";
export const GET_DETAIL_DATA_SAGA = "GET_DETAIL_DATA_SAGA";
export const GET_DETAIL_DATA_REDUCER = "GET_DETAIL_DATA_REDUCER";
export const CALL_DEFAULT_DEMO_HOOK_REDUCER = "CALL_DEFAULT_DEMO_HOOK_REDUCER";

//-------------- Unit API------------
export const GET_ALL_Unit_API = "/api/material-management-service/unit/get-all"
export const CREATE_Unit_TYPE_API = "/api/material-management-service/unit/create"
export const UPDATE_Unit_TYPE_API = "/api/material-management-service/unit/update"
export const DELETE_Unit_TYPE_API = "/api/material-management-service/unit/delete/"
export const GET_DETAIL_Unit_TYPE_API = "/api/material-management-service/unit/get-detail/"

