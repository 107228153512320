// PRODUCT REQUIREMENTS
export const GET_PAGING_RESCHEDULE_PRODUCT_SAGA = "GET_PAGING_RESCHEDULE_PRODUCT_SAGA";
export const GET_PAGING_RESCHEDULE_PRODUCT_REDUCER = "GET_PAGING_RESCHEDULE_PRODUCT_REDUCER";
export const CREATE_RESCHEDULE_PRODUCT_SAGA = "CREATE_RESCHEDULE_PRODUCT_SAGA";
export const CALL_RESULT_RESCHEDULE_PRODUCT_REDUCER = "CALL_RESULT_RESCHEDULE_PRODUCT_REDUCER";
export const CALL_DEFAULT_RESCHEDULE_PRODUCT_REDUCER = "CALL_DEFAULT_RESCHEDULE_PRODUCT_REDUCER";
export const GET_ALL_DROP_DOWN_SAGA = "GET_ALL_DROP_DOWN_SAGA";
export const SET_ALL_DROP_DOWN_REDUCER = "SET_ALL_DROP_DOWN_REDUCER";
export const RE_RENDER_RESCHEDULE = "RE_RENDER_RESCHEDULE";
export const GET_DROPDOWN_PROCESS_REWORK = "GET_DROPDOWN_PROCESS_REWORK";
export const SET_DROPDOWN_PROCESS_REWORK = "SET_DROPDOWN_PROCESS_REWORK";
export const CHANGE_DATA_RENDER = "CHANGE_DATA_RENDER";