// PRODUCT REQUIREMENTS
export const GET_PAGING_PRODUCTION_REQUIRMENT_SAGA =
  "GET_PAGING_PRODUCTION_REQUIRMENT_SAGA";
export const GET_PAGING_PRODUCTION_REQUIRMENT_REDUCER =
  "GET_PAGING_PRODUCTION_REQUIRMENT_REDUCER";
export const CREATE_PRODUCTION_REQUIRMENT_SAGA =
  "CREATE_PRODUCTION_REQUIRMENT_SAGA";
export const CALL_RESULT_PRODUCTION_REQUIRMENT_REDUCER =
  "CALL_RESULT_PRODUCTION_REQUIRMENT_REDUCER";
export const UPDATE_PRODUCTION_REQUIRMENT_SAGA =
  "UPDATE_PRODUCTION_REQUIRMENT_SAGA";
export const DELETE_PRODUCTION_REQUIRMENT_SAGA =
  "DELETE_PRODUCTION_REQUIRMENT_SAGA";
export const DELETE_PRODUCTION_REQUIRMENT_REDUCER =
  "DELETE_PRODUCTION_REQUIRMENT_REDUCER";
export const GET_DETAIL_PRODUCTION_REQUIRMENT_SAGA =
  "GET_DETAIL_PRODUCTION_REQUIRMENT_SAGA";
export const GET_DETAIL_PRODUCTION_REQUIRMENT_REDUCER =
  "GET_DETAIL_PRODUCTION_REQUIRMENT_REDUCER";
export const CALL_DEFAULT_PRODUCTION_REQUIRMENT_REDUCER =
  "CALL_DEFAULT_PRODUCTION_REQUIRMENT_REDUCER";

export const GET_NEW_CODE_PRODUCT =
  "GET_NEW_CODE_PRODUCT";
export const FORCE_POPUP_UPDATE_DATA =
  "FORCE_POPUP_UPDATE_DATA";

export const CLOSE_POPUP_DELETE =
  "CLOSE_POPUP_DELETE_REQUIRED_SCREEN";