export const MaintenanceScheduleLag = {
    language: "en",
    MaintenanceScheduleLag: {
        Search: "Maintenance code",
        btn: {
            create: "Register for maintenance schedule",
            CreateCalendar: "Create a calendar",
            Skip: "Skip",
            Auto: "Auto"
        },
        table: {
            MaintenanceCode: "Maintenance code",
            CycleCode: "Cycle code",
            MaintenanceType: "Maintenance type",
            ExpectedStartDate: "Start date (expected)",
            ExpectedEndDate: "End date (expected)",
            ActualStartTime: "Start time (actual)",
            ActualEndTime: "End time (actual)",
            Status: "Status",
            Action: "Action"
        },
        form: {
            title: "Execution of maintenance orders",
            titleDetail: "Service order details",
            MachineName: "Machine name",
            MachineCode: "Machine code",
            ExpectedStartTime: "Estimated start time",
            ExpectedEndTime: "Estimated end time",
            Executor: "Executor",
            ImplementationDate: "Implementation date",
            ExecutionTime: "Time taken (hours)",
            MaterialTable: {
                title: "Consumable supplies",
                Accessory: "Accessory",
                Unit: "Unit",
                ExpectedNumber: "The expected number",
                ActualNumber: "The actual number",
                MaintenanceCost: "Maintenance cost(VND)"
            }
        },
        delete: {
            Title: "Skip maintenance",
            des: "Are you sure to skip maintenance on this machine/component?",
            reason: "Enter the reason for skipping",
            validate: "The reason for skipping cannot be left blank",
            validateLength: "Reason must not exceed 225 characters",
            onlyMaintenanceOrder: "There is only one service command for the machine code, this cycle code, which must not be ignored",
            onlyUnfulfilled: "Only pending maintenance orders can be ignored",
            noSkip: "This service order must not be ignored"
        },
        rules: {
            material: "Material cannot be duplicated"
        },
        createSchedule: {
            toastjs: "No maintenance schedule to create",
            ExpectedMaintenanceDate: "Expected maintenance date",
            ExpectedCompletionDate: "Expected completion date",
            MaintenanceType: "Maintenance type",
        }
    }
};