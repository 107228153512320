import { call, put, takeEvery } from "redux-saga/effects";
import {
    createCheckpointService,
    deleteCheckpointService,
    getDetailCheckpointService,
    getPagingService,
    updateCheckpointService
} from "./Service";
import {
    CREATE_CHECKPOINT_SAGA,
    DELETE_CHECKPOINT_SAGA,
    GET_DATA_FROM_SAGA,
    GET_DETAIL_CHECK_POINT_SAGA,
    UPDATE_CHECKPOINT_SAGA
} from "./enum/actionEnum";
import CheckpointAction from "./Action";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";

// lấy danh sách checkpoint để hiển thị
export function* getDataCheckpoint(request) {
    try {
        openLoading();
        const entityRequest = {
            SearchTerm: request.searchKey,
            PageSize: request.pageSize,
            PageIndex: request.pageIndex,
            UserId: request.userId
        };
        const response = yield call(getPagingService, entityRequest);
        if (response.isSuccess) {
            yield put(CheckpointAction.setDataToReducer(response.data));
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};
export function* deleteCheckpoint(request) {
    try {
        openLoading();
        const response = yield call(deleteCheckpointService, request.checkpointCode);
        if (response.isSuccess) {
            yield put(CheckpointAction.callDeleteDoneReducer())
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};
export function* getDetailCheckpoint(request) {
    try {
        openLoading();
        const response = yield call(getDetailCheckpointService, request.checkpointCode);
        if (response.isSuccess) {
            yield put(CheckpointAction.setDetailCheckpointReducer(response.data))
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};
export function* createCheckpoint(request) {
    try {
        openLoading();
        const response = yield call(createCheckpointService, request.data);
        if (response.isSuccess) {
            yield put(CheckpointAction.callCreateOrUpdateCheckpointReducer())
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};
export function* updateCheckpoint(request) {
    try {
        openLoading();
        const response = yield call(updateCheckpointService, request.data);
        if (response.isSuccess) {
            yield put(CheckpointAction.callCreateOrUpdateCheckpointReducer())
        }
    } catch (error) {

    } finally {
        closeLoading();
    }
};
export default function* CheckpointSaga() {
    yield takeEvery(GET_DATA_FROM_SAGA, getDataCheckpoint);
    yield takeEvery(DELETE_CHECKPOINT_SAGA, deleteCheckpoint);
    yield takeEvery(GET_DETAIL_CHECK_POINT_SAGA, getDetailCheckpoint);
    yield takeEvery(CREATE_CHECKPOINT_SAGA, createCheckpoint);
    yield takeEvery(UPDATE_CHECKPOINT_SAGA, updateCheckpoint);
}
