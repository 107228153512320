export const PackagetypeManager = {
    language: "en",
    PackagetypeLang: {
        palaceholderSearch: "Enter Package type, short description",
        table: {
            packagetype: "Package Type",
            shortdes: "Short description",
            des: "Description",
            active: "Active",
            action: "Action",
        },
        form: {
            titleInfo: "Infor package type",
            titleEdit: "Update package type",
            titleCreate: "Create package type",
            titleDelete: "Delete package type",
            textConfirmDelete: "Do you want delete this package type?",
            palaceHolderTye: "Enter package type",
            palaceHolderShorDes: "Enter short description",
            palaceHolderDes: "Enter description",
        },
        mes: {
            emptyTypePackage: "Package type not be empty",
            emptyShortDes: "Short description not be empty",
            emptyDes: "Description not be empty",
            formatTypePage: "Package type malformed",
            lengthTypePackage: "Package type no longer than 150 characters",
            lengthShortDes: "Short description no longer than 25 characters",
            lengthDes: "Description no longer than 255 characters",
        },
        toastJs: {
            createOK: "Successfully created new packaging style",
            updateOK: "Packing style update successful",
            deleteOK: "Successful deletion of packing style"
        }
    }
}