import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import { toast } from 'react-toastify';
import * as type from "./enum/PackingProcessTypeAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import PackingProcessAction from "./PackingProcessAction";
import { getAllPackingProcessService, createPackingProcessService, updatePackingProcessService, deletePackingProcessService, getDropdownPackingLevelService, getPackingProcessId } from "./PackingProcessService"
export function* getAllPackingProcessSaga(data) {
    try {
        const pageSize = data.pageSize;
        const pageIndex = data.pageIndex;
        const searchTerm = data.searchTerm ?? " ";
        const request = {
            SearchTerm: searchTerm,
            PageSize: pageSize,
            PageIndex: pageIndex,
        };
        const response = yield call(getAllPackingProcessService, request)
        if (response?.isSuccess) {
            yield put(PackingProcessAction.setAllReducer(response.data))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* createPackingProcessSaga(data) {
    try {
        const result = yield call(createPackingProcessService, data.request)
        if (result.isSuccess) {
            toast.success(i18next.t("packingProcess.toast.createSuccess") + " mã thành phẩm " + data.request.Productcode, {
                position: "top-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(PackingProcessAction.getAllPagingPackingProcessSaga(data.pageIndex, data.pageSize));
            yield put(PackingProcessAction.callResultFromApiReducer());
            closeLoading();
        } else {
            yield put(PackingProcessAction.callFalseFromReducer());
        }
    } catch (error) {
        closeLoading();

    }
}
export function* updatePackingProcessSaga(data) {
    try {
        const result = yield call(updatePackingProcessService, data.request)
        if (result.isSuccess) {
            toast.success(i18next.t("packingProcess.toast.updateSuccess"), {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(PackingProcessAction.getAllPagingPackingProcessSaga(data.pageIndex, data.pageSize));
            yield put(PackingProcessAction.callResultFromApiReducer());
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* deletePackingProcessSaga(data) {
    try {
        const result = yield call(deletePackingProcessService, data.request);
        if (result.isSuccess) {
            toast.success(i18next.t("packingProcess.toast.deleteSuccess"), {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(PackingProcessAction.getAllPagingPackingProcessSaga(data.pageIndex, data.pageSize));
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export function* getDropdownPackingLevelSaga(data) {
    try {
        const response = yield call(getDropdownPackingLevelService, data.request);
        if (response?.isSuccess) {
            yield put(PackingProcessAction.setDropdownPackingLevelReducer(response.data))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getPackingProcessById(data) {
    try {
        const response = yield call(getPackingProcessId, data.request);
        if (response?.isSuccess) {
            yield put(PackingProcessAction.setPackingProcessById(response.data))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export default function* PackingProcessSaga() {
    yield takeEvery(type.GET_ALL_PACKING_PROCESS, getAllPackingProcessSaga);
    yield takeEvery(type.CREATE_PACKING_PROCESS_SAGA, createPackingProcessSaga);
    yield takeEvery(type.UPDATE_PACKING_PROCESS_SAGA, updatePackingProcessSaga);
    yield takeEvery(type.DELETE_PACKING_PROCESS_SAGA, deletePackingProcessSaga);
    yield takeEvery(type.GET_DROP_DOWN_PACKING_LEVEL_SAGA, getDropdownPackingLevelSaga);
    yield takeEvery(type.GET_PACKING_PROCESS_ID_SAGA, getPackingProcessById);
}