export const GET_PAGING_EXPORT_USAGE_SAGA = "GET_PAGING_EXPORT_USAGE_SAGA";
export const CREATE_EXPORT_USAGE_SAGA = "CREATE_EXPORT_USAGE_SAGA";
export const UPDATE_EXPORT_USAGE_SAGA = "UPDATE_EXPORT_USAGE_SAGA";
export const GET_PATIENT_INFO_SAGA = "GET_PATIENT_INFO_SAGA";
export const GET_PATIENT_INFO_REDUCER = "GET_PATIENT_INFO_REDUCER";
export const GET_BY_ID_EXPORT_USAGE_SAGA = "GET_BY_ID_EXPORT_USAGE_SAGA";
export const GET_DETAIL_EXPORT_USAGE_SAGA = "GET_DETAIL_EXPORT_USAGE_SAGA";
export const CANCEL_EXPORT_USAGE_SAGA = "CANCEL_EXPORT_USAGE_SAGA";
export const DELETE_EXPORT_USAGE_SAGA = "DELETE_EXPORT_USAGE_SAGA";
export const GET_PURCHASE_ORDER_BY_CODE_SAGA = "GET_PURCHASE_ORDER_BY_CODE_SAGA";

export const GET_PAGING_EXPORT_USAGE_REDUCER = "GET_PAGING_EXPORT_USAGE_REDUCER";
export const RESET_GET_PAGING_EXPORT_USAGE_REDUCER = "RESET_GET_PAGING_EXPORT_USAGE_REDUCER";
export const CREATE_OR_UPDATE_EXPORT_USAGE_SUCCESSED_REDUCER = "CREATE_OR_UPDATE_EXPORT_USAGE_SUCCESSED_REDUCER";
export const GET_DETAIL_EXPORT_USAGE_SUCCESSED_REDUCER = "GET_DETAIL_EXPORT_USAGE_SUCCESSED_REDUCER";
export const CANCEL_EXPORT_USAGE_SUCCESSED_REDUCER = "CANCEL_EXPORT_USAGE_SUCCESSED_REDUCER";
export const DELETE_EXPORT_USAGE_SUCCESSED_REDUCER = "DELETE_EXPORT_USAGE_SUCCESSED_REDUCER";
export const RESET_DELETE_EXPORT_USAGE_REDUCER = "RESET_DELETE_EXPORT_USAGE_REDUCER";
export const SET_DETAIL_DATA_DEFAULT_REDUCER = "SET_DETAIL_DATA_DEFAULT_REDUCER";
export const SET_HOSPITALID_AND_WAREHOUSEID_REDUCER = "SET_HOSPITALID_AND_WAREHOUSEID_REDUCER";
export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const SET_TRANSACTION_DETAIL_LIST_REDUCER = "SET_TRANSACTION_DETAIL_LIST_REDUCER";
export const SET_MEDICAL_SUPPLIES_LIST_REDUCER = "SET_MEDICAL_SUPPLIES_LIST_REDUCER";
export const SET_MEDICAL_SUPPLIES_LIST_DELETED_REDUCER = "SET_MEDICAL_SUPPLIES_LIST_DELETED_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
export const GET_PURCHASE_ORDER_BY_CODE_REDUCER = "GET_PURCHASE_ORDER_BY_CODE_REDUCER";
export const RESET_GET_BY_ID_EXPORT_USAGE_REDUCER = "RESET_GET_BY_ID_EXPORT_USAGE_REDUCER";
export const GET_BY_ID_EXPORT_USAGE_REDUCER = "GET_BY_ID_EXPORT_USAGE_REDUCER";