import i18next from "i18next";
import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import toastJs from "src/shared/common/toastJs";
import {
    GET_DROPDOWN_WORKORDER_SAGA, CHANGE_SHIPMENT_PRODUCT_SAGA, ENFORCEMENT_PRODUCT_IDENTIFIER_SAGA, GET_DATA_PAGING_PRODUCT_IDENTIFIER_SAGA,
    GET_DETAIL_PRODUCT_INDENTIER_SAGA, GET_LIST_DETAIL_PAGING_SAGA, REPRINT_STAMPS_SAGA
} from "./enum/ProductIdentifierTypeAction";
import ProductIdentifierAction from "./ProductIdentifierAction";
import {
    getDropdownWorkorderService, changeShipmentProductService, enforcementProductIdentifierService, getDataPagingService,
    getDetailProductIdentifierService, getListDetailProductIdentifierService, reprintProductIdentifierService
} from "./ProductIdentifierService";
export function* getDropdownWorkorderSaga(data) {
    try {
        const result = yield call(getDropdownWorkorderService, data.search);
        if (result.isSuccess) {
            yield put(ProductIdentifierAction.setDropdownWorkorderReducer(result.data))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* changeShipmentProduct(data) {
    try {
        let request = {
            productcode: data.productcode,
            qtyShipment: data.qtyShipment,
        }
        const result = yield call(changeShipmentProductService, request);
        if (result.isSuccess) {
            toastJs.success(i18next.t("productidentifier.changeshipmentsuccess"));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* enforcementProductIdentifier(data) {
    try {
        let request = {
            isMulti: data.request.isMulti,
            isShipment: data.request.isShipment,
            productcode: data.request.productcode,
            qtyShipment: data.request.qtyShipment && data.request.qtyShipment != '' ? data.request.qtyShipment : 0,
            qtyTem: data.request.qtyTem != '' ? data.request.qtyTem : 0,
            workorderCode: data.request.workorderCode,
        }
        const result = yield call(enforcementProductIdentifierService, request);
        if (result.isSuccess) {
            yield put(ProductIdentifierAction.callApiEforcementSuccess());
            toastJs.success(i18next.t("productidentifier.identifiersuccess"));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDataPaging(data) {
    try {
        const result = yield call(getDataPagingService, data.dataPage);
        if (result.isSuccess) {
            if (result.data.Items.length <= 0 && data.dataPage.PageIndex > 1) {
                data.dataPage.PageIndex--;
                yield put(ProductIdentifierAction.getDataPaging(data.dataPage));
            } else {
                yield put(ProductIdentifierAction.setDataPagingReducer(result.data));
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDetailProductIdentifier(data) {
    try {
        const result = yield call(getDetailProductIdentifierService, data.workordercode);
        if (result.isSuccess) {
            yield put(ProductIdentifierAction.setDetailProductIdentifierReducer(result.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getListDetailProductIdentifier(data) {
    try {
        const result = yield call(getListDetailProductIdentifierService, data.dataPage);
        if (result.isSuccess) {
            yield put(ProductIdentifierAction.setListDetailProductIdentifierReducer(result.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* reprintProductIdentifier(data) {
    try {
        const result = yield call(reprintProductIdentifierService, data.request);
        if (result.isSuccess) {
            toastJs.success(i18next.t("productidentifier.reprintsuccess"));
            yield put(ProductIdentifierAction.ReprintStampsSuccess());
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export default function* ProductIdentifierSaga(data) {
    yield takeEvery(GET_DROPDOWN_WORKORDER_SAGA, getDropdownWorkorderSaga);
    yield takeEvery(CHANGE_SHIPMENT_PRODUCT_SAGA, changeShipmentProduct);
    yield takeEvery(ENFORCEMENT_PRODUCT_IDENTIFIER_SAGA, enforcementProductIdentifier);
    yield takeEvery(GET_DATA_PAGING_PRODUCT_IDENTIFIER_SAGA, getDataPaging);
    yield takeEvery(GET_DETAIL_PRODUCT_INDENTIER_SAGA, getDetailProductIdentifier);
    yield takeEvery(GET_LIST_DETAIL_PAGING_SAGA, getListDetailProductIdentifier);
    yield takeEvery(REPRINT_STAMPS_SAGA, reprintProductIdentifier);
}