import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { GET_CODE_MMPRODUCTION_API, GET_CODE_MAINTANCE_API, GET_CODE_MATERIAL_API } from "./enum/TypeCommonGetCodeService";

export async function getCodeMMProductionService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_CODE_MMPRODUCTION_API, data);
}
export async function getCodeMaterialService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_CODE_MATERIAL_API, data);
}
export async function getCodeMaintanceService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_CODE_MAINTANCE_API, data);
}