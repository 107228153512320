export const ApproveStepLaguage = {
  language: "vi",
  ApproveStepLaguage: {
    table: {
      name: 'Tên quy trình',
      creator: 'Người tạo'
    },
    search: {
      placeHolder:
        "Tìm kiếm theo tên quy trình hoặc người tạo",
    },
    delete: {
      title: 'Xoá bước phê duyệt',
      comment: 'Bạn có chắc chắn muốn xóa bước phê duyệt này?'
    },
    title: 'Tạo mới quy trình phê duyệt',
    form: {
      title_delete: 'Xoá quy trình phê duyệt',
      confirm_text: 'Bạn có chắc muốn xoá quy trình này không?',
      name: 'Tên quy trình',
      name_placeHolder: 'Nhập tên quy trình',
      approveType: 'Loại phê duyệt',
      approveType_placeHolder: 'Chọn kiểu phê duyệt',
      btn_add: "Thêm bước mới",
      btn_close: 'Hủy',
      table: {
        level: 'Cấp độ',
        name_level: 'Tên cấp độ',
        placeHolderNameLevel: 'Nhập tên cấp độ',
        department: 'Tên phòng ban',
        approveBy: 'Phê duyệt bởi',
        area_placeHolder: "Chọn khu vực sản xuất"
      }
    },
    approval_process: 'Thêm quy trình phê duyệt',
    approval_process_update: 'Cập nhật quy trình phê duyệt',
    errors: {
      nameEmpty: 'Tên quy trình không được bỏ trống',
      nameMaxLength: 'Tên quy trình không được dài quá 255 kí tự',
      approveTypeEmpty: 'Kiểu phê duyệt không được bỏ trống',
      levelNameEmpty: 'Tên cấp độ không được bỏ trống',
      departmentEmpty: 'Phòng ban không được bỏ trống',
      approveBy: 'Người phê duyệt không được bỏ trống',
      approve_type: "Loại phê duyệt không được bỏ trống",
      levelNameLength: 'Tên cấp độ không được dài quá 255 kí tự',
    },
    msg: {
      createSuccess: 'Tạo mới quy trình thành công',
      updateSuccess: 'Cập nhật quy trình thành công',
      deleteSuccess: 'Xóa quy trình thành công'
    },
  },
};
