import {
    GET_REQUEST_INCREASE_ASSET_REDUCER,
    CALL_DEFAULT_REQUEST_INCREASE_ASSET_REDUCER,
    CLEAR_REQUEST_INCREASE_ASSET_REDUCER,
    // GET_DETAIL_INCREASE_ASSET_REDUCER
} from "./enum/TypeRequestIncreaseAssetAction.js";

const stateDefault = {
    isGetDataFromApi: false,
    listAssetCreaseRequest: [],
    isCallDetail : false,
    objectDetail : {}
}
const RequestIncreaseAssetReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case GET_REQUEST_INCREASE_ASSET_REDUCER:
            if (action.data) {
                state.listAssetCreaseRequest = action.data;
                state.isGetDataFromApi = true
            }
            return { ...state };

        case CALL_DEFAULT_REQUEST_INCREASE_ASSET_REDUCER:
            state.isGetDataFromApi = false

            return {
                ...state,
            }

            // case GET_DETAIL_INCREASE_ASSET_REDUCER:
            //     state.isCallDetail = true;
            //     state.objectDetail = action.obj;
            //     return {
            //         ...state,
            //     }

        case CLEAR_REQUEST_INCREASE_ASSET_REDUCER:
            return {
                state: stateDefault,
            };

        default:
            return { ...state };
    }
};

export default RequestIncreaseAssetReducer;
