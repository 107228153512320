import { GET_WORK_UNIT_REDUCER, SET_WORKER_MAICHINE_REDUCER, SET_WORKUNIT_WITH_WC_WO, CALL_SAVE_WORKUNIT_SUCCESS, CALL_CHANGE_WORK_MACHINE_SUCCESS, DEFAULT } from "./enum/typeProductionLineAction";
const ProductionLineReducer = (
    state = {
        listWorkUnit: [],
        listStepProcess: [],
        objectData: {},
        isCallWorkerMachine: false,
        callResultFromApi: false,
        callResultFromDetail: false,
        isWorkUnitWCWO: false,
        dropdownWO: [],
        isSaveData: false,
        isChangeWorkerMachine: false,
    },
    action
) => {
    switch (action.type) {
        case GET_WORK_UNIT_REDUCER:
            if (action.data) {
                state.listWorkUnit = action.data;
                state.callResultFromApi = true
            }
            return {
                ...state
            }
        case SET_WORKER_MAICHINE_REDUCER:
            if (action.data) {
                state.objectData = action.data;
                state.isCallWorkerMachine = true;
            }
            return {
                ...state
            }
        case SET_WORKUNIT_WITH_WC_WO:
            if (action.data) {
                state.listStepProcess = action.data;
                state.isWorkUnitWCWO = true;
            }
            return {
                ...state
            }
        case CALL_SAVE_WORKUNIT_SUCCESS:
            state.isSaveData = true;
            return {
                ...state
            }
        case CALL_CHANGE_WORK_MACHINE_SUCCESS:
            state.isChangeWorkerMachine = true;
            return {
                ...state
            }
        case DEFAULT:
            state.objectData = {};
            state.isCallWorkerMachine = false;
            state.listWorkUnit = [];
            state.listStepProcess = [];
            state.callResultFromApi = false;
            state.isWorkUnitWCWO = false;
            state.isSaveData = false;
            state.isChangeWorkerMachine = false;
            return {
                ...state
            }
        default:
            return {
                ...state
            }
    }
}
export default ProductionLineReducer;
