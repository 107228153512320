const { SET_UPLOAD_FILE, CLEAR_UPLOAD_FILE_REDUCER } = require("./enum/TypeAction")

const UploadFileAction = {
    setFileUpload: (dataUpload) => {
        return {
            type: SET_UPLOAD_FILE,
            dataUpload: dataUpload
        }
    },
    clearFileUploadReducer: () => {
        return {
            type: CLEAR_UPLOAD_FILE_REDUCER,
        }
    },
}

export default UploadFileAction;