import {
    CALL_DEFAULT_DEMO_HOOK_REDUCER, CALL_RESULT_DEMO_HOOK_REDUCER, CREATE_DATA_SAGA, DELETE_DATA_SAGA, GET_DETAIL_DATA_REDUCER,
    GET_DETAIL_DATA_SAGA, GET_DROP_DOWN_UNIT, GET_PAGING_DEMO_HOOK_REDUCER, GET_PAGING_DEMO_HOOK_SAGA, UPDATE_DATA_SAGA, DELETE_DATA_REDUCER,
    CLEAR_ALL_REDUCER_STATE
} from "./enum/hookDemoTypeAction";

const ReactHookDemoAction = {
    getDropDownUnit: () => {
        return {
            type: GET_DROP_DOWN_UNIT,
        }
    },
    getPagingSaga: (pageIndex, pageSize, searchTerm = '') => {
        return {
            type: GET_PAGING_DEMO_HOOK_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
        }
    },
    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_DEMO_HOOK_REDUCER,
            data: data,
        }
    },
    getDetailSaga: (modelCode) => {
        return {
            type: GET_DETAIL_DATA_SAGA,
            modelCode: modelCode
        }
    },
    returnDetailReducer: (obj) => {
        return {
            type: GET_DETAIL_DATA_REDUCER,
            obj: obj,
        }
    },
    createDataSaga: (request) => {
        return {
            type: CREATE_DATA_SAGA,
            request: request,
        }
    },
    updateDataSaga: (request, pageIndex, pageSize) => {
        return {
            type: UPDATE_DATA_SAGA,
            request: request,
            pageIndex: pageIndex,
            pageSize: pageSize,
        }
    },
    deleteDataSaga: (modelCode, pageIndex, pageSize) => {
        return {
            type: DELETE_DATA_SAGA,
            modelCode: modelCode,
            pageIndex: pageIndex, pageSize: pageSize,
        }
    },
    deleteDataReducer: () => {
        return {
            type: DELETE_DATA_REDUCER,
        }
    },
    callResultFromApiReducer: () => {
        return {
            type: CALL_RESULT_DEMO_HOOK_REDUCER,
        }
    },
    callDefault: () => {
        return {
            type: CALL_DEFAULT_DEMO_HOOK_REDUCER,
        }
    },
    clearDataReducer : () => {
        return {
            type: CLEAR_ALL_REDUCER_STATE,
        }
    }
}

export default ReactHookDemoAction;