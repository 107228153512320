import { Value } from "sass";

export const formTypeEnum = {
    Update: 1,
    Create: 2,
    Detail: 3,
};

export const formTitle = {
    Update: "Chỉnh sửa kế hoạch kiểm kê",
    Create: "Tạo mới kế hoạch kiểm kê",
    View: "Xem chi tiết kế hoạch kiểm kê",
}

//Enum loại phiếu xuất sử dụng
export const EXPORT_USAGE_TYPE = {
    PATIENTS_EXPORT: 2,
    GENERAL_EXPORT: 3
}

//Enum nhóm vtyt
export const PRODUCT_GROUP_TYPE = {
    NORMAL_GROUP: 1,
    CONSIGNMENT_GROUP: 2,
}

//Enum trạng thái của phiếu xuất
// export const EXPORT_USAGE_STATUS = {
//   TEMPORATY_SAVE: 1, ==> GIỮ NGUYÊN
//   PROCESSING: 2, //Chuyển thành HAS_BEEN_SHIPPED
//   SUCCESSED: 3, xóa bỏ
//   CANCELED:4 chuyển thành 3
// }
export const EXPORT_USAGE_STATUS = {
    TEMPORATY_SAVE: 1,
    HAS_BEEN_SHIPPED: 2, //Chuyển thành 
    CANCELED: 3
}

export const POPUP_TYPE = {

    SCAN_BARCODE: 1,
    CHOOSE_PRODUCT: 2,
}
export const LIST_CHARGE_CHANGE_REASON_ENUM = [
    {
        value: 1,
        label: "Sai giao dịch nhập kho"
    },
    {
        value: 2,
        label: "Sai giao dịch xuất sử dụng chung"
    },
    {
        value: 3,
        label: "Lỗi cá nhân"
    },

]
export const LIST_PAYMENT_REASON_ENUM = [
    {
        value: 1,
        label: "Hủy phí hoàn trả"
    },
    {
        value: 2,
        label: "Xóa khỏi phiên"
    },
    {
        value: 3,
        label: "Hủy mục lệnh điều tra"
    },
    {
        value: 4,
        label: "Không xác định"
    },
    {
        value: 5,
        label: "Lý do khác"
    },
    {
        value: 6,
        label: "Chuyển gói"
    },
    {
        value: 7,
        label: "Bệnh nhân hoàn trả"
    },
    {
        value: 8,
        label: "Đã cập nhật"
    },

]
export const STOCK_OUT_STATUS_ENUM = [
    {
        value: EXPORT_USAGE_STATUS.TEMPORATY_SAVE,
        label: "Lưu tạm"
    },
    {
        value: EXPORT_USAGE_STATUS.HAS_BEEN_SHIPPED,
        label: "Đã xuất kho"
    },
    {
        value: EXPORT_USAGE_STATUS.CANCELED,
        label: "Đã hủy xuất kho"
    },
]

export const CHARGE_STATUS_ENUM = [
    {
        value: "UNP",
        label: "Chưa thanh toán"
    },
    {
        value: "PART",
        label: "Đã thanh toán 1 phần"
    },
    {
        value: "COM",
        label: "Đã thanh toán xong"
    },
]

export const CONSUMABLE_PACKAGE_TYPE_ENUM = {
    NORMAL: 1,
    SURCHARGE: 2
}
export const Package_Type_Enum = {
    NORMAL: 1,
    SURCHARGE: 2
}
export const PACKAGE_TYPE = [
    {
        value: Package_Type_Enum.NORMAL,
        label: "Gói tiêu hao thông thường",
    },
    {
        value: Package_Type_Enum.SURCHARGE,
        label: "Gói Surcharge",
    }
]