import { Button, Form, Input, Modal, Tooltip, Select, InputNumber, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";
import Moment from "moment";
import { useEffect, useState } from "react";
import i18next, { t } from "i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openLoading } from "src/shared/common/LoadingAction";
import { modalStatus } from "src/shared/constants/enum/constants";
import StringUtils from "src/shared/common/StringUtils";
import toastJs from "src/shared/common/toastJs";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import UserHospitalWarehouseAction from "src/redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseAction.js";
import CatHospitalAction from "src/redux-saga-v2/MedicalSuppliesManagement/CatHospital/CatHospitalAction.js";
import CatWarehouseAction from "src/redux-saga-v2/MedicalSuppliesManagement/CatWarehouse/CatWarehouseAction.js";
import { formTypeEnum } from "./enum";
import userManager, {
  loadUserFromStorage,
  signinRedirect,
} from "../../../../shared/oidc-client-base/userService";
import WarehouseSelect from "src/components/base/CommonSelect/WarehouseSelect/WarehouseSelect"
import CustomSelect from "src/components/base/CommonSelect/WarehouseSelect/MultipleWarehouseSelect.jsx"

const { TextArea } = Input;

function FormUserHospitalWarehouse(props) {
  //const translation = props.t;
  const objDataModel = {
    Id: "",
    UserId: "",
    FullName: "",
    HospitalId: null,
    WarehouseId: [],
    WarehouseUIId: null,
  };
  const [objData, setObjData] = useState({ ...objDataModel });
  const [lstHospitalId, setLstHospitalId] = useState([]); //Tạo ra một lst để truyền vào Warehouse
  const { isOpen, closePopupCreateUpdate, userIsChoose, formType, userNameIsChoose } = props;
  const [lstError, setLstError] = useState([]);
  const rules = [
    {
      field: "HospitalId",
      required: true,
      messages: {
        required: "Vui lòng chọn bệnh viện",
      },
    },
    {
      field: "WarehouseUIId",
      required: true,
      messages: {
        required: "Vui lòng chọn kho",
      },
    },
    // {
    //   field: "FullName",
    //   required: true,
    //   messages: {
    //     required: "Vui lòng chọn người dùng",
    //   },
    // },

  ];
  const closePopup = (isCancel) => {
    closePopupCreateUpdate(isCancel);
  };

  const validateInputListfieldForm = () => {
    let lstErrors = window.$.mkd_validateForm(objData, rules, []);
    setLstError(lstErrors);
    return lstErrors;
  };

  const saveData = () => {
    let isSave = true;
    let listErrors = validateInputListfieldForm();
    if (listErrors.length > 0) {
      isSave = false;
    }
    setObjData({ ...objData, UserId: userIsChoose?.Id, Id: 0 })
    if (isSave) {
      openLoading();
      props.UserHospitalWarehouseAction.createUserHospitalWarehouseSaga(objData);
    setObjData({ ...objData, UserId: userIsChoose?.Id, HospitalId : null, WarehouseId: [], WarehouseUIId: null})
      closePopup(true);
    }
  };
  useEffect(() => {
    (async () => {
      //Put your logic here
      let user = await loadUserFromStorage();
      objData.UserId = userIsChoose?.Id;
      setObjData({ ...objData });
      props.CatHospitalAction.getListUnitCategorySaga();
    })();
  }, [userIsChoose]);
  // useEffect(() => {
  //   //lấy data bệnh viện
  //   if (props?.dataUserHospitalWarehouse) {
  //     objData.Id = props?.dataUserHospitalWarehouse.Id;
  //     objData.HospitalId = props?.dataUserHospitalWarehouse.HospitalId;
  //     objData.WarehouseId = props?.dataUserHospitalWarehouse.WarehouseId != null ? [props?.dataUserHospitalWarehouse.WarehouseId] : [];
  //     objData.WarehouseUIId = props?.dataUserHospitalWarehouse.WarehouseId != null ? props?.dataUserHospitalWarehouse.WarehouseId : null;
  //     // objData.UserId = props?.dataUserHospitalWarehouse.UserId;
  //     objData.UserId = userIsChoose?.Id;
  //     setObjData({ ...objData });
  //     let tempLstHospitalId = props?.dataUserHospitalWarehouse.HospitalId != null ? [props?.dataUserHospitalWarehouse.HospitalId] : [];
  //     setLstHospitalId(tempLstHospitalId);
  //   }
  // }, [props?.dataUserHospitalWarehouse]);

  const handleOnChangeSelect = (value, keyName) => {
    objData[keyName] = value;
    objData.WarehouseUIId = value?.length == 0 ? null : value[0];
    setObjData(objData);
    validateInputListfieldForm();

  };
  const onChangeCheckbox = (e) => {
    if (e.target.checked)
      setObjData(prev => ({
        ...prev,
        Status: 1
      }))
    else
      setObjData(prev => ({
        ...prev,
        Status: 0
      }))
  }
  return (
    <Modal
      width={"95vw"}
      className="popup-main modal-confirm"
      onCancel={() => closePopup(true)}
      open={isOpen}
      footer={null}
    >
      <div className="header-confirm row">
        <span className="col-8 header-confirm-title">
          {formType === formTypeEnum.Update ? "Cập nhật" :
            (formType === formTypeEnum.Create ? "Thêm mới" : "")
          } thông tin kho, bệnh viện người dùng
        </span>
        <div className="col-4 d-flex flex-row-reverse header-confirm-btn">
          <Button
            className="blue-button btn-custom save-button w-10 me-2"
            onClick={saveData}
          >
            {i18next.t("base.btn.save")}
          </Button>
        </div>
      </div>
      <hr width="100%" align="center" className="line-modal col-12" />
      <Form layout="vertical" className="row input-reason approvalProcess-step">
        <div className="row">
          <Form.Item
            className="col-4"
            validateStatus={
              lstError?.find((x) => x.key == "FullName") ? "error" : "success"
            }
            help={lstError?.map((item) => {
              if (item.key == "FullName") {
                return <span className="error">{item.errorText}</span>;
              }
              return null;
            })}
            label={<span className="label-input required">Người dùng</span>}
          >
            <Input
              // className="select-mutil-user"
              size="medium"
              value={userIsChoose?.FullName}
              disabled
            />
          </Form.Item>
          <Form.Item
            className="col-4"
            validateStatus={
              lstError?.find((x) => x.key == "HospitalId") ? "error" : "success"
            }
            help={lstError?.map((item) => {
              if (item.key == "HospitalId") {
                return <span className="error">{item.errorText}</span>;
              }
              return null;
            })}
            label={<span className="label-input required">Bệnh viện</span>}
          >
            <Select
              name="HospitalId"
              allowClear
              size="medium"
              placeholder="Chọn bệnh viện"
              showSearch
              onChange={(value) => {
                objData.HospitalId = value;
                objData.WarehouseId = [];
                objData.WarehouseUIId = null;
                setObjData(objData)
                // setObjData({ ...objData, HospitalId: value, WarehouseId: [] })
                let tempLstHospitalId = value != null ? [value] : [];
                setLstHospitalId(tempLstHospitalId);
                validateInputListfieldForm();
              }}
               
              value={objData?.HospitalId ? objData?.HospitalId : null}
              options={props?.listUnits?.map((item) => ({
                value: item.Id,
                label: item.Name,
              }))}
              disabled={formType != formTypeEnum.Create}
              //tìm kiếm theo label
              filterOption={(input, option) =>
                (StringUtils.removeAccents((option?.label).toLowerCase()) ?? '').includes(StringUtils.removeAccents(input.toLowerCase()))
              }
            />
          </Form.Item>
          <Form.Item
          
              className="col-4"
              validateStatus={
                lstError?.find((x) => x.key == "WarehouseUIId")
                  ? "error"
                  : "success"
              }
              help={lstError?.map((item) => {
                if (item.key == "WarehouseUIId") {
                  return <span className="error">{item.errorText}</span>;
                }
                return null;
              })}
            label={<span className="label-input required">Kho</span>}
            >
              <CustomSelect
              placeholder={"Chọn kho"}
              value={objData?.WarehouseId}
              onChange={(value) => {
                handleOnChangeSelect(value, "WarehouseId")
              }}
              size="medium"
              isGetAll="true"
              listHospitalId={lstHospitalId} //Truyền vào danh sách bệnh viện để lấy ds kho xuất theo bệnh viện
            />
            </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  UserHospitalWarehouseAction: bindActionCreators(
    UserHospitalWarehouseAction,
    dispatch
  ),
  CatHospitalAction: bindActionCreators(CatHospitalAction, dispatch),
  CatWarehouseAction: bindActionCreators(CatWarehouseAction, dispatch),
});

const mapStateToProps = (state) => {
  return {
    isCreateUpdateSuccess: state.UserHospitalWarehouseReducer.isCreateUpdateSuccess,
    listUnits: state.CatHospitalReducer.listUnits,
    dataUserHospitalWarehouse: state.UserHospitalWarehouseReducer.dataUserHospitalWarehouse,
    listDataWarehouse: state.CatWarehouseReducer.listDataWarehouse,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormUserHospitalWarehouse));
