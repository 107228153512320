import { keyBy } from "lodash";

export const formTypeEnum = {
  Update: 1,
  Create: 0,
  Detail: 3,
  PendingApproval: 2, //Chờ duyệt
  Confirm: 4, //Xác nhận cấp phát
  Receive: 5, //Xác nhận lĩnh hàng
};

export const TypeCodeFunctionEnum =
{
    MainWarehouse: 1, //Phiếu dự trù toàn viện
    RetailWarehouse: 2, //Phiếu dự trù kho lẻ
    Delivery: 3, //Phiếu lĩnh
    Return: 4, //Phiếu hoàn trả
    Consumable: 5, //Phiếu gói tiêu hao
    ExportUsage: 6,//Phiếu xuất sử dụng
    InventoryAdjustment: 7 // Tạm thời chỉ có điều chỉnh kiểm kê adjustment và stockcheck, để chung 1 loại
};
export const Status = {
  TemporatySave: 1, // Lưu tạm
  PendingApproval: 2, //Chờ duyệt
  Reject: 3, //Từ chối
  WaitingForProgressing: 4, // chờ cấp phát
  Processing: 5, //Chờ lĩnh hàng (Thủ kho xác nhận cấp phát cho kho lẻ)
  Successed: 6 //Hoàn thành (Điều dưỡng trưởng xác nhận đã lĩnh hàng)
}

export const StatusName = {
  TemporatySave: "Lưu tạm", // Lưu tạm
  PendingApproval: "Chờ duyệt", //Chờ duyệt
  Reject: "Từ chối", //Từ chối
  WaitingForProgressing: "Chờ cấp phát", // chờ cấp phát
  Processing: "Chờ lĩnh hàng", //Đang xử lý (Thủ kho xác nhận cấp phát cho kho lẻ)
  Successed: "Hoàn thành" //Hoàn thành (Điều dưỡng trưởng xác nhận đã lĩnh hàng)
}
export const ListStatusEnum = [
  {
    value: Status.TemporatySave,
    label: "Lưu tạm"
  },
  {
    value: Status.PendingApproval,
    label: "Chờ duyệt"
  },
  {
    value: Status.Reject,
    label: "Từ chối"
  },
  {
    value: Status.WaitingForProgressing,
    label: "Chờ cấp phát"
  },
  {
    value: Status.Processing,
    label: "Chờ lĩnh hàng",
  },
  {
    value: Status.Successed,
    label: "Hoàn thành" 
  },
]

export const typeActionEnum = {
  ApprovalWorkflow: 1, //Trình phê duyệt, 
  Reject:2, //Từ chối phiếu lĩnh
  CreateTicket: 3, //Tạo phiếu lĩnh ( Gửi sang cho thủ kho)
  Confirm: 4, //Xác nhận cấp phát
  Receive: 5, //Xác nhận lĩnh hàng
  PrintBarcode: 6, //Xác nhận in barcode
  DeleteItem: 7 //Xác nhận xóa vật tư
}

export const listConfirmEnum = [
  {
    key: typeActionEnum.ApprovalWorkflow,
    title: "Trình phê duyệt",
    message: "Xác nhận trình phê duyệt phiếu lĩnh này?",
    urlIcon: "/assets/img/icon/icon-add-image.svg",
  },
  {
    key: typeActionEnum.CreateTicket,
    title: "Xác nhận phê duyệt",
    message: "Xác nhận phê duyệt tạo mới phiếu lĩnh và gửi sang kho chính bệnh viện?",
    urlIcon: "/assets/img/icon/icon-add-image.svg",
  },
  {
    key: typeActionEnum.Reject,
    title: "Xác nhận từ chối",
    message: "Bạn có chắc chắn muốn từ chối phê duyệt phiếu lĩnh này không?",
    urlIcon: "/assets/img/icon/approve.svg",
  },
  {
    key: typeActionEnum.Confirm,
    title: "Xác nhận cấp phát",
    message: "Bạn có đồng ý cấp phát hàng cho phiếu này không?",
    urlIcon: "/assets/img/icon/approve.svg",
  },
  {
    key: typeActionEnum.Receive,

    title: "Xác nhận lĩnh hàng",
    message: "Bạn có đồng ý lĩnh hàng cho phiếu này không?",
    urlIcon: "/assets/img/icon/approve.svg"
  },
  {
    key: typeActionEnum.PrintBarcode,

    title: "Xác nhận in barcode",
    message: "Có vật tư không cần in thêm barcode, có tiếp tục in không?",
    urlIcon: "/assets/img/icon/approve.svg"
  },
  {
    key: typeActionEnum.DeleteItem,
    title: "Xác nhận xóa",
    message: "Bạn có chắc chắn muốn xóa các vật tư được chọn không?",
    urlIcon: "/assets/img/icon/i-delete.svg"
  },
]

export const TypeDeliveryEnum = {
  Manually: 1, // Tạo thủ công
  Auto: 2, // Tạo tự động
}

//Enum nhóm vtyt
export const PRODUCT_GROUP_TYPE = {
  NORMAL_GROUP: 1,
  CONSIGNMENT_GROUP: 2,
}

//Enum loại xuất báo cáo
export const TYPE_EXPORT = {
  EXCEL:1,
  PDF:2,
  VIEW_PDF:3
}