import { call, put, takeEvery } from "redux-saga/effects";
// import { TypeApprovev2 } from "src/components/page/MMProduction/Approval/constant/statusType";
import { closeLoading } from "src/shared/common/LoadingAction";
// import ApprovalAction from "./ApprovalAction";
import {
  approveRejectService,
  getApprovalPagingService,
  getDetailApproval,
  getWorkerFromWorkArea
} from "./ApprovalService";
import {
  GET_PAGING_APPROVAL_SAGA,
  GET_APPROVAL_DETAIL_SAGA,
  APPROVE_REJECT_SAGA,
  GET_WORKER_FROM_OF_WORKAREA_SAGA
} from "./enum/ApprovalTypeAction";

// approval 
export function* getPagingApprovalSaga(data) {
  try {
    const request = {
      PageIndex: data.pageIndex,
      PageSize: data.pageSize, // tổng số
      SearchTerm: data.searchTerm ?? '', // nếu search không có gì sẽ đẩy rỗng lên 
      Status: data.status
    };
    const response = yield call(getApprovalPagingService, request);
    if (response?.isSuccess) {
      // yield put(ApprovalAction.getApprovalPagingReducer(response.data));
    }
    closeLoading()
  } catch (error) {
    closeLoading()

  }
}
export function* getDetailApprovalSaga(request) {
  try {
    const response = yield call(getDetailApproval, request.id);
    if (response?.isSuccess) {
      // yield put(ApprovalAction.getApprovalDetailReducer(response.data));
    }
  } catch (error) {
    closeLoading()

  }
}
export function* approveRejectSaga(request) {
  try {
    let requestEnt = {
      // TypeApproval: request.TypeApproval == TypeApprovev2.Approved ? 1 : 2,
      Approvalid: request.Approvalid,
      Comment: request.Comment,
    }
    const response = yield call(approveRejectService, requestEnt);
    if (response?.isSuccess) {
      // yield put(ApprovalAction.approveRejectActionReducer(request.Approvalid, request.TypeApproval));
    }
  } catch (error) {

  } finally {
    closeLoading()
  }
}
export function* getWorkerFromWorkAreaId(request) {
  try {
    const response = yield call(getWorkerFromWorkArea, request);
    if (response?.isSuccess) {
      // yield put(ApprovalAction.getWorkerFromWorkareaReducer(response.data, request.id));
    }
  } catch (error) {

  } finally {
    closeLoading()
  }
}
export default function* ApprovalSaga() {
  // approval
  yield takeEvery(GET_PAGING_APPROVAL_SAGA, getPagingApprovalSaga);
  yield takeEvery(GET_APPROVAL_DETAIL_SAGA, getDetailApprovalSaga);
  yield takeEvery(APPROVE_REJECT_SAGA, approveRejectSaga);
  yield takeEvery(GET_WORKER_FROM_OF_WORKAREA_SAGA, getWorkerFromWorkAreaId);
}
