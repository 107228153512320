import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import NotificationAction from "src/redux-saga/action/Notification/NotificationAction";
import { getPagingService, updateNotificationService } from "../../services/Notification/NotificationService";
import { GET_ALL_NOTIFiCATION_SAGA, UPDATE_NOTIFICATION } from "../../utils/enum/ActionTypeEnums";
import toastJs from "src/shared/common/toastJs";
export function* getAllNotification(request) {
    try {
        let userID = request.userID;
        const response = yield call(getPagingService, userID)
        if (response?.isSuccess) {
            yield put(NotificationAction.setAllNotificationReducer(response.data));
        }
        closeLoading()
    } catch (error) {
        console.log(error)
    }
    closeLoading()
}
export function* updateNotification(request) {
    // try {
    //     let data = {
    //         approvalID: request.approvalID,
    //         userID: request.userID,
    //     }
    //     // const response = yield call(updateNotificationService, data);
    //     if (response == true) {
    //         yield put(NotificationAction.updateNotificationReducer(data.approvalID))
    //         // sử lý sau khi lưu thành công
    //     }
    //     closeLoading();
    // } catch (error) {
    //     console.log(error);
    //     closeLoading();
    // }
}
export default function* NotificationSaga() {
    yield takeEvery(GET_ALL_NOTIFiCATION_SAGA, getAllNotification);
    yield takeEvery(UPDATE_NOTIFICATION, updateNotification);
}
