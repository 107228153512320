export const packingLevelLag = {
    language: "en",
    packingLevel: {
        packTitle: "Packing level",
        notify:"High rated pack levels can contain lower ranked levels",
        table: {
            rank: "Rank",
            nameLevel:"Level name",
            description:"Description",
            action: "Action",
            nodata: "No data to show",
            title:"Change the package rating"
        },
        form: {
            add_new:"Add new packing level",
            update:"Edit packing level",
            nameLevel:"Level name",
            description: "Description",
            placeholder: {
                nameLevel: "Enter the level name",
                description:"Enter a description",
            }
        },
        btn: {
            add_new: "Create new",
            add: "Add",
            update:"Update",
            close: "Cancel",
            deleteBack: "Back",
            deleteSave:"Delete",
        },
        validate: {
            name: {
                required: "Level name cannot be left blank",
                maxLength:"Level name should not exceed 50 characters"
            },
            description: {
                required: "Description cannot be left blank",
                maxLength:"Description cannot exceed 255 characters"
            }
        },
        toast: {
            createSuccess: "Successfully created new packaging level!",
            updateSuccess: "Packing level update successful",
            deleteSuccess: "Clear packing level successfully",
            changeLevelSuccess: "Rating change successful",
        },
        delete: {
            title:"Clear packing level",
            description:"Are you sure you want to remove ",
        },
    }
}