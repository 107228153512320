import {
    ACTION_DETAIL_A_INVENTORY_AUDIT_REDUCER,
    ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_REDUCER,
    ACTION_INVENTORY_AUDIT_PLAN_DELETE_REDUCER, ACTION_INVENTORY_AUDIT_DETAILS_CLEAR, ACTION_INVENTORY_AUDIT_PLAN_DETAILS_REDUCER,
    ACTION_INVENTORY_AUDIT_PLAN_DETAILS_SAGA,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    FETCH_INVENTORY_AUDIT_PLAN_DATA_REDUCER,
    UPDATE_FILTER_DATA_REDUCER,
    CLEAR_FILTER_DATA_REDUCER,
} from "./enum/TypeInventoryAction";

const stateDefault = {
    ivenAuditData: [],
    ivenAuditDetails: {},
    actionMessage: {},
    inventoryAuditData: [],
    filterData: null,
    isLoading: false,
    isLoadingAction: false
};

const InventoryReducer = (state = stateDefault, action) => {
    switch (action.type) {
        case FETCH_INVENTORY_AUDIT_PLAN_DATA_REDUCER:
            // console.log("FETCH_INVENTORY_AUDIT_PLAN_DATA_REDUCER", action)
            state.inventoryAuditData = action.data;
            state.isLoading = true;
            return { ...state };

        case ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_REDUCER:
            // console.log("ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_REDUCER", action)
            state.actionMessage = action.data;
            state.isLoadingAction = true;
            return { ...state };

        case ACTION_INVENTORY_AUDIT_PLAN_DETAILS_REDUCER:
            // console.log("ACTION_INVENTORY_AUDIT_PLAN_DETAILS_REDUCER", action)
            state.ivenAuditDetails = { ...action.data };
            state.isLoading = true;
            return { ...state };

        case ACTION_INVENTORY_AUDIT_PLAN_DELETE_REDUCER:
            // console.log("ACTION_INVENTORY_AUDIT_PLAN_DELETE_REDUCER", action)
            state.actionMessage = action.data;
            state.isLoadingAction = true;
            return { ...state };
        case ACTION_DETAIL_A_INVENTORY_AUDIT_REDUCER:
            state.detailAInventoryAudit = action.data;
            return { ...state };

        case UPDATE_FILTER_DATA_REDUCER:
            state.filterData = action.data;
            return { ...state };
        case CLEAR_FILTER_DATA_REDUCER:
            state.filterData = null;
            return { ...state };

        case CALL_DEFAULT_REDUCER:
            state.isLoading = false
            state.isLoadingAction = false
            return {
                ...state,
            };

        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };

        default:
            return { ...state };
    }
};

export default InventoryReducer;
