import {
  GET_PAGING_SUPPLIER_CATEGORY_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_SUPPLIER_CATEGORY_REDUCER,
  CALL_CREATE_UPDATE_SUCCESS_REDUCER,
} from "./enum/TypeCatSupplierAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,
  isDeleteSuccess: false,
  isCreateUpdateSuccess: false,
  listUnits: [],
};
const CatSupplierReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_SUPPLIER_CATEGORY_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };
    case DELETE_SUPPLIER_CATEGORY_REDUCER:
      state.isDeleteSuccess = true;
      return { ...state };

    case CALL_CREATE_UPDATE_SUCCESS_REDUCER:
      state.isCreateUpdateSuccess = true;
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      return {
        ...state,
      };
    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };

    default:
      return { ...state };
  }
};

export default CatSupplierReducer;
