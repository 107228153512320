export const GET_PAGING_CAT_HOSPITAL_SAGA = "GET_PAGING_CAT_HOSPITAL_SAGA";
export const GET_PAGING_CAT_HOSPITAL_REDUCER =
  "GET_PAGING_CAT_HOSPITAL_REDUCER";

export const DELETE_CAT_HOSPITAL_SAGA = "DELETE_CAT_HOSPITAL_SAGA";
export const DELETE_CAT_HOSPITAL_REDUCER = "DELETE_CAT_HOSPITAL_REDUCER";

export const CREATE_CAT_HOSPITAL_SAGA = "CREATE_CAT_HOSPITAL_SAGA";
export const UPDATE_CAT_HOSPITAL_SAGA = "UPDATE_CAT_HOSPITAL_SAGA";
export const CALL_CREATE_UPDATE_SUCCESS_REDUCER =
  "CALL_CREATE_UPDATE_SUCCESS_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";

export const GET_LIST_CAT_HOSPITAL_REDUCER = "GET_LIST_CAT_HOSPITAL_REDUCER";
export const RESET_GET_LIST_CAT_HOSPITAL_REDUCER = "RESET_GET_LIST_CAT_HOSPITAL_REDUCER";
export const GET_LIST_CAT_HOSPITAL_SAGA = "GET_LIST_CAT_HOSPITAL_SAGA";
