let ENV_APP = process.env;
var configApp = {
    apiGateWay: "https://pis-gw.namek.com.vn",
    apiUpload: "http://192.168.2.103:8062",
    apiMaintainance: "",
    apiMaterial: "",
    apiAms: "", 
    urlCdn: "",
    apiSocket: "",

    authority: "https://vtyt-auth.namek.com.vn",
    client_id: "VinMReactApp",
    client_secret: "secret",
    response_type: "code",
    scope:
        "openid profile offline_access evo_mes_admin_api evo_mes_sop_api evo_mes_upload_api evo_mes_identity_api",
    redirect_uri: "https://pis.namek.com.vn/signin-oidc",
    post_logout_redirect_uri: "https://pis.namek.com.vn/trang-chu",
};
// if (ENV_APP.REACT_APP_ENV === "localmac") {
//     configApp.apiGateWay = "https://pis-gw.namek.com.vn";
//     configApp.authority = "https://vtyt-auth.namek.com.vn";
//     configApp.redirect_uri = "http://localhost:3000/signin-oidc";
//     configApp.post_logout_redirect_uri = "http://localhost:3000/trang-chu";
// }

// if (ENV_APP.REACT_APP_ENV == "local") {
//     // local, localdev, dev, qa, uat, production
//     configApp.apiGateWay = "https://pis-gw.namek.com.vn";
//     configApp.apiAms = "https://localhost:44357";
//     configApp.authority = "https://vtyt-auth.namek.com.vn";
//     configApp.client_id = "VinMReactApp";
//     configApp.client_secret = "secret";
//     configApp.response_type = "code";
//     configApp.scope =
//         "openid profile offline_access evo_mes_admin_api evo_mes_sop_api evo_mes_upload_api evo_mes_identity_api";
//     configApp.redirect_uri = "http://localhost:3000/signin-oidc";
//     configApp.post_logout_redirect_uri = "http://localhost:3000/trang-chu";
// }

// if (ENV_APP.REACT_APP_ENV == "localdev") {
//     configApp.apiGateWay = "http://192.168.2.103:6018";
//     configApp.apiAms = "https://localhost:44357";
//     configApp.authority = "https://vtyt-auth.namek.com.vn";
//     configApp.client_id = "VinMReactApp";
//     configApp.client_secret = "secret";
//     configApp.response_type = "code";
//     configApp.scope =
//         "openid profile offline_access evo_mes_admin_api evo_mes_sop_api evo_mes_upload_api evo_mes_identity_api";
//     configApp.redirect_uri = "http://localhost:3000/signin-oidc";
//     configApp.post_logout_redirect_uri = "http://localhost:3000/trang-chu";
//     //configApp.apiUpload = "http://192.168.2.100:51080"
//     configApp.apiUpload = "http://192.168.2.103:6012";
// }

// AMS_dashboard: http://192.168.2.103:6010 => done
// AMS-API: https://192.168.2.103:6011 => done
// AMS-Upload: http://192.168.2.103:6012 => done
// AMS-Admin: https://192.168.2.103:6013 => done
// AMS_BackgroundJob:http://192.168.2.103:6014 => done
// AMS-Mobile:http://192.168.2.103:6015 => done => sai source

// API GATEWWAY: http://192.168.2.103:6018 => done
// API SSO: https://192.168.2.103:6019 => done

// if (ENV_APP.REACT_APP_ENV == "dev") {
//     configApp.apiGateWay = "http://192.168.2.103:6018";
//     configApp.apiAms = "http://192.168.2.103:6011";
//     configApp.authority = "https://vtyt-auth.namek.com.vn";
//     configApp.client_id = "VinMReactApp";
//     configApp.client_secret = "secret";
//     configApp.response_type = "code";
//     configApp.scope =
//         "openid profile offline_access evo_mes_admin_api evo_mes_sop_api evo_mes_upload_api evo_mes_identity_api";
//     configApp.redirect_uri = "http://192.168.2.103:6010/signin-oidc";
//     configApp.post_logout_redirect_uri = "http://192.168.2.103:6010/trang-chu";
//     configApp.apiUpload = "http://192.168.2.103:6012";
// }

// if (ENV_APP.REACT_APP_ENV == "qa") {
//     configApp.apiGateWay = "http://192.168.2.103:7018";
//     configApp.apiAms = "http://192.168.2.103:7011";
//     configApp.authority = "https://192.168.2.103:7019";
//     configApp.client_id = "VinMReactApp";
//     configApp.client_secret = "secret";
//     configApp.response_type = "code";
//     configApp.scope =
//         "openid profile offline_access evo_mes_admin_api evo_mes_sop_api evo_mes_upload_api evo_mes_identity_api";
//     configApp.redirect_uri = "http://192.168.2.103:7010/signin-oidc";
//     configApp.post_logout_redirect_uri = "http://192.168.2.103:7010/trang-chu";
//     configApp.apiUpload = "http://192.168.2.103:7012";
// }

// if (ENV_APP.REACT_APP_ENV == "preproduction") {
//     configApp.apiGateWay = "https://pis-gw.namek.com.vn";
//     configApp.apiAms = "https://uat-ams.vinmec.com:82";
//     configApp.authority = "https://vtyt-auth.namek.com.vn";
//     configApp.client_id = "VinMReactApp";
//     configApp.client_secret = "secret";
//     configApp.response_type = "code";
//     configApp.scope =
//         "openid profile offline_access evo_mes_admin_api evo_mes_sop_api evo_mes_upload_api evo_mes_identity_api";
//     configApp.redirect_uri = "https://uat-ams.vinmec.com/signin-oidc";
//     configApp.post_logout_redirect_uri = "https://uat-ams.vinmec.com/trang-chu";
//     configApp.apiUpload = "https://uat-ams.vinmec.com:83";
// }

// if (ENV_APP.REACT_APP_ENV == "production") {
    configApp.apiGateWay = "https://pis-gw.namek.com.vn";
    configApp.apiAms = "https://ams.vinmec.com:82";
    configApp.authority = "https://vtyt-auth.namek.com.vn";
    configApp.client_id = "VinMReactApp";
    configApp.client_secret = "secret";
    configApp.response_type = "code";
    configApp.scope =
        "openid profile offline_access evo_mes_admin_api evo_mes_sop_api evo_mes_upload_api evo_mes_identity_api";
    configApp.redirect_uri = "https://pis.namek.com.vn/signin-oidc";
    configApp.post_logout_redirect_uri = "https://pis.namek.com.vn/trang-chu";
    configApp.apiUpload = "https://pis.namek.com.vn:83";
//}

export default configApp;
