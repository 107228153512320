export const GET_INVENTORY_PLAN_REPORT_LIST = "/api/service/InventoryPlanReport/SearchInventoryPlanReport";
export const GET_INVENTORY_PLAN_REPORT_BY_ID = "/api/service/InventoryPlanReport/GetInventoryPlanReportById";
export const GET_ASSET_INVENTORY_GROUP_BY_ASSET_CLASS = "/api/service/InventoryPlanReport/GetAssetInventoryGroupByAssetClass";
export const GET_REPORT_INVENTORY_ASSET = "/api/service/InventoryPlanReport/SearchReportInventoryAsset";
export const GET_RESULT_REPORT_INVENTORY_ASSET = '/api/service/InventoryPlanReport/GetResultReportInventoryAsset';
export const SEARCH_REPORT_INVENTORY_ASSET = "/api/service/InventoryPlanReport/SearchReportInventoryAsset";
export const EXPORT_DATA_INVENTORY_REPORT_BILL_API = "/api/service/InventoryPlanReport/InventoryPlanReportByIdExportExcel";
export const EXPORT_DATA_INVENTORY_REPORT_INFO_API = "/api/service/InventoryPlanReport/InventoryGroupByAssetClassExportExcel";
export const EXPORT_DATA_INVENTORY_REPORT_ASSETMENT_API = "/api/service/InventoryPlanReport/ReportInventoryAssetExportExcel";
export const GET_SUMMARY_RESULT_ASSET = "/api/service/InventoryPlanReport/GetResultReportInventoryAsset";
