export const GET_PAGING_PERMISSION_DATA_SAGA = "GET_PAGING_PERMISSION_DATA_SAGA";
export const GET_PAGING_PERMISSION_DATA_REDUCER = "GET_PAGING_PERMISSION_DATA_REDUCER";

export const GET_DETAIL_PERMISSION_DATA_SAGA = "GET_DETAIL_PERMISSION_DATA_SAGA";
export const GET_DETAIL_PERMISSION_DATA_REDUCER = "GET_DETAIL_PERMISSION_DATA_REDUCER";

export const DELETE_PERMISSION_DATA_SAGA = "DELETE_PERMISSION_DATA_SAGA";
export const DELETE_PERMISSION_DATA_REDUCER = "DELETE_PERMISSION_DATA_REDUCER";

export const CREATE_PERMISSION_DATA_SAGA = "CREATE_PERMISSION_DATA_SAGA";
export const UPDATE_PERMISSION_DATA_SAGA = "UPDATE_PERMISSION_DATA_SAGA";
export const CALL_CREATE_UPDATE_SUCCESS_REDUCER = "CALL_CREATE_UPDATE_SUCCESS_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";