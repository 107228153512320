import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PAGING_CatUnitMeasure_SAGA,
} from "./enum/TypeCatUnitMeasureAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import CatUnitMeasureAction from "./CatUnitMeasureAction";
import {
  getListCatUnitMeasureService,
} from "./CatUnitMeasureService"

export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      searchTerm: searchTerm,
    };
    const response = yield call(getListCatUnitMeasureService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          CatUnitMeasureAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm
          )
        );
      } else {
        yield put(CatUnitMeasureAction.getPagingReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export default function* CatUnitMeasureManagementSaga(data) {
  yield takeEvery(GET_PAGING_CatUnitMeasure_SAGA, getPagingSaga);
}
