import {
    GET_CODE_MMPRODUCTION_SAGA, SET_CODE_MMPRODUCTION_REDUCER, GET_CODE_MATERIAL_SAGA, SET_CODE_MATERIAL_REDUCER, GET_CODE_MAINTANCE_SAGA, SET_CODE_MAINTANCE_REDUCER
} from "./enum/TypeCommonGetCodeAction";

const CommonGetCodeAction = {
    // phân hệ quản lý sản xuất 
    getCodeMMProduction: (typeGetCode, parentCode = "", key = "") => {
        return {
            type: GET_CODE_MMPRODUCTION_SAGA,
            typeGetCode: typeGetCode,
            parentCode: parentCode,
            key: key,
        };
    },

    setCodeMMProduction: (typeGetCode, code, key) => {
        return {
            type: SET_CODE_MMPRODUCTION_REDUCER,
            typeGetCode: typeGetCode,
            code: code,
            key: key
        };
    },

    // Nguyên vật liệu
    getCodeMaterial: (typeGetCode, parentCode = "", key = "") => {
        return {
            type: GET_CODE_MATERIAL_SAGA,
            typeGetCode: typeGetCode,
            parentCode: parentCode,
            key: key,
        };
    },

    setCodeMaterial: (typeGetCode, code, key) => {
        return {
            type: SET_CODE_MATERIAL_REDUCER,
            typeGetCode: typeGetCode,
            code: code,
            key: key
        };
    },

    // Bảo trì bảo dưỡng
    getCodeMaintance: (typeGetCode, parentCode = "", key = "") => {
        return {
            type: GET_CODE_MAINTANCE_SAGA,
            typeGetCode: typeGetCode,
            parentCode: parentCode,
            key: key,
        };
    },

    setCodeMaintance: (typeGetCode, code, key) => {
        return {
            type: SET_CODE_MAINTANCE_REDUCER,
            typeGetCode: typeGetCode,
            code: code,
            key: key
        };
    },
    default: () => {
        return {
            type: 'default',
        }
    }
}

export default CommonGetCodeAction;