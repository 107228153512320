import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_LIST_PLANNING_API,
    DELETE_PLANNING_API,
    CREATE_PLANNING_API,
    UPDATE_PLANNING_API,
    GET_DETAIL_PLANNING_API,
    GET_INFO_PLANNING_BY_ID,
    GET_PREDICTABILITY_RETAIL_AUTO_API,
    PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_API,
    GET_PREDICTABILITY_BUDGET_AUTO_API,
    PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_API,
    PLANNING_DOWNLOAD_EXCEL_API,
    GET_PAGING_DETAIL_BY_ID_API,
    UPDATE_RANGE_DETAIL_PLANNING_API,
    GET_INFO_PLANNING_PAGING_BY_ID_API,
    DELETE_RANGE_PLANNING_DETAIL_API,
    PROPOSAL_SUMMARY_API
} from "./enum/TypePlanningAPI";
export async function getListPlanningService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_LIST_PLANNING_API,
        data
    )
}
export async function createPlanningService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_PLANNING_API,
        data
    )
}
export async function updatePlanningService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_PLANNING_API,
        data
    )
}
export async function deletePlanningService(deleteId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        DELETE_PLANNING_API +
        "?Id=" + deleteId
    )
}
export async function getDetailPlanningService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_DETAIL_PLANNING_API +
        "?Id=" + Id
    )
}

export async function getInfoPlanningByIdService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_INFO_PLANNING_BY_ID +
        "?Id=" + Id
    )
}

export async function PredictabilityRetailAutoService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_PREDICTABILITY_RETAIL_AUTO_API,
        data
    )
}

export async function PredictabilityRetailByListProductService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_API,
        data
    )
}

export async function PredictabilityBudgetAutoService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_PREDICTABILITY_BUDGET_AUTO_API,
        data
    )
}

export async function PredictabilityBudgetByListProductService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_API,
        data
    )
}

export async function downloadExcelService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + PLANNING_DOWNLOAD_EXCEL_API + "?Id=" + data.Id + "&&TypeExport=" + data.typeExport 
    )
}
export async function getPagingDetailByIdService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_PAGING_DETAIL_BY_ID_API,
        data
    )
}

export async function updateRangeDetailService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_RANGE_DETAIL_PLANNING_API,
        data
    )
}
export async function getInfoPlanningPagingService(Id) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_INFO_PLANNING_PAGING_BY_ID_API +
        "?Id=" + Id
    )
}
export async function deleteRangeDetailService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        DELETE_RANGE_PLANNING_DETAIL_API +
        "?planningId=" + data.planningId,  data.listDetailId
    )
}

export async function proposalSummaryService(data) {
    
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        PROPOSAL_SUMMARY_API +
        "?mainPlanningId=" + data.planningId,  data.listDetail
    )
}