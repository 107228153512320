import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PAGING_CAT_BARCODE_SAGA,
  DELETE_CAT_BARCODE_SAGA,
  CREATE_CAT_BARCODE_SAGA,
  UPDATE_CAT_BARCODE_SAGA,
  GET_LIST_CAT_BARCODE_SAGA,
  CREATE_LIST_CAT_BARCODE_SAGA,DOWNLOAD_TEMPLATE_CAT_BARCODE_SAGA,
  GET_LIST_ALL_FIELD_CAT_BARCODE_SAGA
} from "./enum/TypeCatBarcodeAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import CatBarcodeAction from "./CatBarcodeAction";
import {
  getPagingService,
  deleteCatBarcodeService,
  createCatBarcodeService,
  updateCatBarcodeService,
  getListUnitCategoryService,
  createListCatBarcodeService,downloadTemplateBarcodeService,
  getListFieldBarcodeService
} from "./CatBarcodeService";
import toastJs from "src/shared/common/toastJs";

export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    const typeBarcode= data.typeBarcode ? data.typeBarcode : "";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      searchTerm: searchTerm,
      listType:typeBarcode
    };
    const response = yield call(getPagingService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          CatBarcodeAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm,
            request.unitCode
          )
        );
      } else {
        yield put(CatBarcodeAction.getPagingReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* deleteCatBarcodeSaga(request) {
  try {
    if (request.id) {
      const response = yield call(deleteCatBarcodeService, request.id);
      if (response.isSuccess) {
        yield put(CatBarcodeAction.deleteDataReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* downloadTemplateCatBarcodeSaga() {
  try {
    const response = yield call(downloadTemplateBarcodeService);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Template_Barcode.xlsx');
    document.body.appendChild(link);
    link.click();
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}


export function* createCatBarcodeSaga(requet) {
  try {
    if (requet) {
      const requestAPI = {
        Id: requet?.data?.Id,
        Barcode: requet?.data?.Barcode?.trim(),
        ProductId: requet?.data?.ProductId?.trim(),
      };
      const response = yield call(createCatBarcodeService, requestAPI);
      if (response.isSuccess) {
        yield put(CatBarcodeAction.callCreateUpdateSuccessReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}


export function* createListCatBarcodeSaga(request) {
    try {
      if (request) {
        const response = yield call(createListCatBarcodeService, request.data);
        if (response.isSuccess) {
          yield put(CatBarcodeAction.callCreateUpdateSuccessReducer());
          // toastJs.success(response.userMessage);
        }
        // else 
        //   toastJs.error(response.userMessage);
        closeLoading();
      }
    } catch (error) {
      closeLoading();
    }
  }
export function* updateCatBarcodeSaga(requet) {
  try {
    if (requet?.data) {
      const requestAPI = {
        Id: requet?.data?.Id,
        Barcode: requet?.data?.Barcode?.trim(),
        ProductId: requet?.data?.ProductId?.trim(),
      };
      const response = yield call(updateCatBarcodeService, requestAPI);
      if (response.isSuccess) {
        yield put(CatBarcodeAction.callCreateUpdateSuccessReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getListUnitCategory() {
  try {
    const response = yield call(getListUnitCategoryService);
    if (response.isSuccess) {
      yield put(CatBarcodeAction.getListUnitCategoryReducer(response.data));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* getListFieldBarcode() {
  try {
    const response = yield call(getListFieldBarcodeService);
    if (response.isSuccess) {
      yield put(CatBarcodeAction.getListFieldBarcodeReducer(response.data));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export default function* CatBarcodeManagementSaga(data) {
  yield takeEvery(GET_PAGING_CAT_BARCODE_SAGA, getPagingSaga);
  yield takeEvery(DELETE_CAT_BARCODE_SAGA, deleteCatBarcodeSaga);
  yield takeEvery(CREATE_CAT_BARCODE_SAGA, createCatBarcodeSaga);
  yield takeEvery(UPDATE_CAT_BARCODE_SAGA, updateCatBarcodeSaga);
  yield takeEvery(GET_LIST_CAT_BARCODE_SAGA, getListUnitCategory);
  yield takeEvery(CREATE_LIST_CAT_BARCODE_SAGA, createListCatBarcodeSaga);
  yield takeEvery(DOWNLOAD_TEMPLATE_CAT_BARCODE_SAGA, downloadTemplateCatBarcodeSaga);
  yield takeEvery(GET_LIST_ALL_FIELD_CAT_BARCODE_SAGA, getListFieldBarcode);
}
