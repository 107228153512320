import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_PAGING_CAT_WAREHOUSE_PRODUCT_API,
    GET_PAGING_PRODUCT_BY_ROLE_USER_API,
    EXPORT_LIST_DATA_API,
    UPDATE_CAT_WAREHOUSE_PRODUCT_API,
    SEARCH_PRODUCT_BY_CODEOH_API,
    EXPORT_REPORT_BY_LIST_ID_API,
    GET_PAGING_BY_PLANNING_API,
    GET_DETAIL_CAT_WAREHOUSE_PRODUCT_API,
    // GET_LIST_DATA_UNIT_API,
} from "./enum/TypeCatWarehouseProductAPI.js";

export async function getPagingService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_PAGING_CAT_WAREHOUSE_PRODUCT_API,
        data
    );
}

//Hàm lấy api danh sách VTYT có trong kho có phân quyền dữ liệu (Dùng trong màn danh sách VTYT phiếu dự trù kho lẻ)
//TrungLQ(20/2/2024)
export async function getPagingByRoleUserService(data) {
    return await CommonBase.postAsync(
      configApp.apiGateWay + GET_PAGING_PRODUCT_BY_ROLE_USER_API + "?typeFunction=" + data?.typeFunction,
      data.condition
    );
  }

//Hàm tìm kiếm tuyệt đối vật tư theo mã OH truyền vào
  export async function searchProductByCodeOHService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + SEARCH_PRODUCT_BY_CODEOH_API + "?codeOH=" +
        data.codeOH + "&warehouseId=" + data.warehouseId + "&hospitalId=" + data.hospitalId  + "&type=" + data?.typeCodeFunction 
    );
}
export async function updateCatWarehouseProductService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + UPDATE_CAT_WAREHOUSE_PRODUCT_API,
        data
    );
}

export async function exportListDataService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + EXPORT_LIST_DATA_API,
        data
    );
}

export async function exportReportByListIdService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + EXPORT_REPORT_BY_LIST_ID_API + "?typeExport=" + data.typeExport, 
        data.bodyParam
        
    );
}

export async function getPagingByPlanningService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_PAGING_BY_PLANNING_API + "?planningId=" + data.planningId,
        data.bodyParam
    );
}
export async function getDetailCatWarehouseProductService(data){
    return await CommonBase.getAsync(
        configApp.apiGateWay+ GET_DETAIL_CAT_WAREHOUSE_PRODUCT_API+ "?id="+ data.Id
    );
}
