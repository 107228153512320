import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PAGING_WAREHOUSE_SAGA,
  DELETE_WAREHOUSE_SAGA,
  CREATE_WAREHOUSE_SAGA,
  UPDATE_WAREHOUSE_SAGA,
  GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_SAGA,
  GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_SAGA,
  GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_SAGA,
} from "./enum/TypeCatWarehouseAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import CatWarehouseAction from "./CatWarehouseAction";
import {
  getPagingService,
  deleteCatWarehouseService,
  createCatWarehouseService,
  updateCatWarehouseService,
  getListDataWarehouseByHospitalIdService,
  getListWarehouseByListHospitalIdService,
  getMainWarehouseByHospitalIdService,
} from "./CatWarehouseService";

export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    const catHospitalIDList = data?.catHospitalIDList ? data?.catHospitalIDList : [];
    const listType = data?.listType ? data?.listType : [];
    const listStatus = data?.listStatus ? data?.listStatus : [];
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      searchTerm: searchTerm,
      catHospitalIDList: catHospitalIDList,
      listType: listType,
      listStatus: listStatus
    };
    const response = yield call(getPagingService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          CatWarehouseAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm,
            request.catHospitalIDList,
            request.listType,
            request.listStatus
          )
        );
      } else {
        yield put(CatWarehouseAction.getPagingReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* deleteCatWarehouseSaga(request) {
  try {
    if (request.id) {
      const response = yield call(deleteCatWarehouseService, request.id);
      if (response.isSuccess) {
        yield put(CatWarehouseAction.deleteDataReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* createCatWarehouseSaga(requet) {
  try {
    if (requet) {
      const requestAPI = {
        Id: requet?.data?.Id,
        Code: requet?.data?.Code?.trim(),
        Name: requet?.data?.Name?.trim(),
        UnitCode: requet?.data?.UnitCode?.trim(),
        Type: requet?.data?.Type,
      };
      const response = yield call(createCatWarehouseService, requestAPI);
      if (response.isSuccess) {
        yield put(CatWarehouseAction.callCreateUpdateSuccessReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export function* updateCatWarehouseSaga(requet) {
  try {
    if (requet?.data) {
      const requestAPI = {
        Id: requet?.data?.Id,
        Code: requet?.data?.Code?.trim(),
        Name: requet?.data?.Name?.trim(),
        UnitCode: requet?.data?.UnitCode?.trim(),
        Type: requet?.data?.Type,
      };
      const response = yield call(updateCatWarehouseService, requestAPI);
      if (response.isSuccess) {
        yield put(CatWarehouseAction.callCreateUpdateSuccessReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
//Lấy danh sách kho theo một bệnh viện truyền vào
export function* getListDataWarehouseByHospitalIdSaga(request) {
  if (!request?.data.hospitalId) return
  try {
    const response = yield call(getListDataWarehouseByHospitalIdService, request?.data);
    if (response.isSuccess) {
      yield put(CatWarehouseAction.getListDataWarehouseByHospitalIdReducer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

//Lấy kho chính theo một bệnh viện truyền vào
export function* getMainWarehouseByHospitalIdSaga(request) {
  if (!request.hospitalId) return
  try {
    const response = yield call(getMainWarehouseByHospitalIdService, request.hospitalId);
    if (response.isSuccess) {
      yield put(CatWarehouseAction.getMainWarehouseByHospitalIdReducer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

//Lấy danh sách kho theo danh sách bệnh viện truyền vào
export function* getListDataWarehouseByListHospitalIdSaga(request) {
  if (!request.data) {
    return
  }
  try {
    const param = {
      listHospitalId: request.data?.listHospitalId,
      isGetAll:request.data?.isGetAll ?? ""
    }
    const response = yield call(
      getListWarehouseByListHospitalIdService,
      param
    );
    if (response.isSuccess) {
      yield put(
        CatWarehouseAction.getListWarehouseByListHospitalIdReducer(response)
      );
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export default function* CatWarehouseManagementSaga(data) {
  yield takeEvery(GET_PAGING_WAREHOUSE_SAGA, getPagingSaga);
  yield takeEvery(DELETE_WAREHOUSE_SAGA, deleteCatWarehouseSaga);
  yield takeEvery(CREATE_WAREHOUSE_SAGA, createCatWarehouseSaga);
  yield takeEvery(UPDATE_WAREHOUSE_SAGA, updateCatWarehouseSaga);
  yield takeEvery(GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_SAGA,getListDataWarehouseByHospitalIdSaga);
  yield takeEvery(GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_SAGA,getListDataWarehouseByListHospitalIdSaga);
  yield takeEvery(GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_SAGA,getMainWarehouseByHospitalIdSaga);
}
