export const recordResult = {
  language: "en",
  recordResult: {
    Identifiers: "Identifiers",
    IdentifiersPlaceHolder: "Enter the identifier",
    Confirm: "Confirm that you have read the entire manual",
    information: "General Information",
    Workers: "Workers",
    Production_center: "Production Center",
    Production_unit: "Production unit",
    Production_order: "Employee",
    Finished: "Finished products",
    stage: "Execution stage",
    Machines: "Used Machines",
    document: "Technical Documentation",
    wo: "Production order",
    table: {
      Criteria: "Criteria",
      Unit: "Unit",
      Standard: "Standard Specification",
      Actual: "Actual parameters",
      Result: "Result",
    },
    Stage_Result: "Stage Result",
    btn: {
      complete: "Complete",
      begin: "Begin",
    },
    validation: {
      notANumber: "Text not available",
      required: "Must enter",
      itemCodeRequired: "You must enter the item code",
    }
  },
};
