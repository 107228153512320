import * as type from "./enum/CheckListTypeAction";
const stateDefault = {
    listDataCheckList: {},
    detailDataCheckList: {},
    callResultFromApi: false,
    isGetData: false,
    backToChecklistPage: false,
}
const CheckListReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case type.SET_DATA_PAGING_CHECKLIST_REDUCER:
            state.listDataCheckList = action.data;
            state.isGetData = true;
            return {
                ...state,
            }
        case type.CLEAR_ALL_DATA_CHECKLIST_REDUCER:
            state.callResultFromApi = false;
            state.isGetData = false;


            return {
                state: stateDefault,
            }
        case type.SET_DETAIL_DATA_CHECKLIST_REDUCER:
            state.detailDataCheckList = action.data;
            return {
                ...state
            }
        case type.CALL_RESULT_FROM_API:
            state.callResultFromApi = true;

            return {
                ...state
            }
        case type.CALL_DEFAULT_RESULT_FROM_API:
            state.callResultFromApi = false;
            state.isGetData = false;


            return {
                ...state
            }
        case type.BACK_TO_CHECKLIST_PAGE:
            state.backToChecklistPage = action.status;
            return {
                ...state,
            }
        default:
            return {
                ...state
            }
    }
}
export default CheckListReducer;