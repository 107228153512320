export const sopLag = {
  language: "en",
  sop: {
    table: {
      code: "Name SOP",
      product: "PRODUCT",
      versionUse: "Version in use",
      createdDate: " Created date",
      effectiveDate: "Effective date",
      versionNewest: "Version newest",
      statusVersionNewest: "Status Version newest",
      actionEdit: "Edit",
      actionBrowser: "Browser"
    },
    search: {
      placeHolder:
        "Name SOP, product, version in use or version status",
    },
    form: {
      add_new: "Create new SOP document",
      update: "Update information SOP document",
      see_detail: " See information SOP document",
      add_version: "Add new version",
      code: "Name SOP",
      product: "Product",
      version: "Version",
      note: "Note",
      attachedFiles: "Attached files",
    },
    delete: {
      title: "Delete SOP",
      description: "Are you sure you want to delete this SOP?",
    },
    btn: {
      deletedVersion: " Delete version",
      changeVersion: "Change version",
      addVersion: "Add new"
    },
    toastjs: {
      noVersionEdit: "There is no version to fix",
      cant_not_edit: "Version with different actions, cannot be modified",
      version_not_approved: "Only approved versions can be changed",
      noVersionChange: "There is no new version to change",
      no_choose_version: "SOP not select to change version",
      no_browser_one: "Only versions with created status can be browsed",
      no_browser_two: "There is no new version created for browsing",

    },
    error: {
      fileMax: "Unable to upload files larger than 20MB",
      versionIsUsing: "Version in use",
      sopNameRequired: "SOP name is required",
      sopName: "SOP name should not exceed 255 characters",
      product: "Product is required",
      version: "Version should not exceed 255 characters",
      versionRequired: "Version is required",
      attachedFiles: "Attachments cannot exceed 20Mb",
      attachedFilesRequired: "Attached files is required",
      note: "Note should not exceed 255 characters",
      add_more_version_one: "The latest version is available, cannot add another new version",
      add_more_version_two: "The latest version is available, only added when the latest version is rejected",
      errorFile: "Attachments is required",


    },
    confirm: {
      createSuccess: "Successfully created new SOP",
      updateSuccess: "SOP update successful",
      changeVersionSuccess: "Change version successfully",
      deleteSuccess: "Successfully deleted version",
      addVersionSuccess: "New version added successfully",
      comfirmText: "Version in use, cannot be deleted",
      comfirmTextDeleted: "Version is currently unique, not removed"

    }
  },
};
