import React from "react";
import configApp from "src/shared/configApp/configApp";

const ImageComponent = (props) => {
  // url = tên ảnh và đường link ,
  // className nếu muốn css riêng cho ảnh

  return (
    <img
      className={props?.className ?? "class-imgage-base"}
      style={props?.style ?? null}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      id={props.id}
      src={configApp.urlCdn + props.url}
      alt={configApp.urlCdn + props.alt}
    />
  );
};

export default ImageComponent;
