export const packingProcessLag = {
    language: "vi",
    packingProcess: {
        packTitle: "Quy cách đóng gói",
        table: {
            code: "Mã thành phẩm",
            name: "Tên thành phẩm",
            packingProcess: "Quy cách đóng gói",
            status: "Trạng thái",
            action: "Thao tác",
            nodata: "Không có dữ liệu để hiển thị"
        },
        form: {
            add_new: "Thêm mới quy cách đóng gói",
            update: "Chỉnh sửa quy cách đóng gói",
            edit: "Chi tiết quy cách đóng gói",
            finishedProductPack: "Thành phẩm đóng gói",
            table: {
                packingLevel: "Cấp độ đóng gói",
                quota: "Định mức",
                unit: "Đơn vị",
            },
            btn_add: "Thêm cấp độ mới",
            status: "Đang hoạt động",
            delete: {
                title: "Xoá cấp độ đóng gói",
                confirm: "Bạn có chắc chắn muốn xoá cấp độ đóng gói này?",
            },
            error_add: "Cần thêm cấp độ đóng gói trước khi tạo mới",
        },
        placeHolder: {
            search: "Mã thành phẩm, tên thành phẩm",
            finishedProductPack: "Thành phẩm đóng gói",
            packingLevel: "Cấp độ đóng gói",
            quota: "Định mức",
            unit: "Đơn vị",
        },
        validate: {
            productCode: {
                required: "Thành phẩm đóng gói không được bỏ trống",
            },
            packingid: {
                required: "Cấp độ đóng gói không được bỏ trống",
            },
            qty: {
                required: "Định mức không được bỏ trống",
                numberOnly: "Định mức yêu cầu phải là kiểu số nguyên dương",
                numberBiggerZero: "Định mức yêu cầu phải lớn hơn 0",
                maxLength: "Định mức không quá 10 kí tự",
            },
            status: {
                change: "Thành phẩm đã tồn tại quy cách đóng gói ở trạng thái hoạt động, cần tắt trạng thái hoạt động trước khi tạo mới",
            }
        },
        toast: {
            createSuccess: "Tạo mới quy cách đóng gói thành công",
            updateSuccess: "Cập nhật quy cách đóng gói thành công",
            deleteSuccess: "Xoá quy cách đóng gói thành công",
        },
        btn: {
            add_new: "Thêm mới",
            add: "Thêm",
            update: "Cập nhật",
            close: "Huỷ",
            cancel: "Đóng",
        },
        delete: {
            title: "Xoá quy cách đóng gói",
            description: "Bạn có chắc chắn muốn xoá quy cách đóng gói này?",
        },
    }
}