import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
    GET_LIST_CatUnitMeasure_API,
    DELETE_CatUnitMeasure_API,
    CREATE_CatUnitMeasure_API,
    UPDATE_CatUnitMeasure_API,
    GET_DETAIL_CatUnitMeasure_API
} from "./enum/TypeCatUnitMeasureAPI.js";
import { toast } from "react-toastify";

export async function getListCatUnitMeasureService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_LIST_CatUnitMeasure_API,
        data
    )
}
export async function createCatUnitMeasureService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_CatUnitMeasure_API,
        data
    )
}
export async function updateCatUnitMeasureService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_CatUnitMeasure_API,
        data
    )
}
export async function deleteCatUnitMeasureService(deleteId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        DELETE_CatUnitMeasure_API +
        "?Id=" + deleteId
    )
}
export async function getDetailCatUnitMeasureService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_DETAIL_CatUnitMeasure_API +
        "?Id=" + Id
    )
}