import * as type from "./enum/MachineryRepairTypeAction";
const stateDefault = {
    listDataMachineryRepair: null,
    detailDataMachineryRepair: null,
    detailDatamachineryRepairAcceptance: null,
    callResultFromReducerMachinerRepair: false,
}
const MachineryRepairReducer = (
    state = stateDefault,
    action)=> {
    switch (action.type) {
        case type.SET_DATA_PAING_MACHINERY_REPAIR_REDUCER:
            state.listDataMachineryRepair = action.data;
            return {
                ...state
            }
        case type.SET_DETAIL_MACHINERY_REPAIR_REDUCER:
            state.detailDataMachineryRepair = action.data;
            return {
                ...state
            }
        case type.GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_REDUCER:
            state.detailDatamachineryRepairAcceptance=action.data;
            return {
                ...state
            }
        case type.CALL_RESULT_FROM_REDUCER_MACHINER_REPAIR:
            state.callResultFromReducerMachinerRepair = true;
            return {
                ...state
            }
        case type.CALL_DEFAULT_RESULT_FROM_REDUCER_MACHINER_REPAIR:
            state.callResultFromReducerMachinerRepair= false;
            return {
                ...state
            }
        default:
            return {
                ...state
            }
    }
}
export default MachineryRepairReducer;