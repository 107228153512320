import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_PAGING_CONFIG_PLANNING_API,
    DELETE_CONFIG_PLANNING_API,
    CREATE_CONFIG_PLANNING_API,
    UPDATE_CONFIG_PLANNING_API,
    GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_API
} from "./enum/TypeConfigPlanningAPI";
export async function getPagingService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_PAGING_CONFIG_PLANNING_API,
        data
    )
}
export async function createConfigPlanningService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_CONFIG_PLANNING_API,
        data
    )
}
export async function updateConfigPlanningService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_CONFIG_PLANNING_API,
        data
    )
}
export async function deleteConfigPlanningService(deleteId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        DELETE_CONFIG_PLANNING_API +
        "?Id=" + deleteId
    )
}
export async function getDetailConfigPlanningByHospitalIdService(HospitalId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_API +
        "?HospitalId=" + HospitalId
    )
}