export const accessoriesLag = {
    language: "vn",
    accessories: {
        title: "Quản lý linh kiện",
        table: {
            Materialcode: "Mã linh kiện",
            Materialname: "Tên linh kiện",
            Description: "Mô tả",
            Description2: "Mô tả 2",
            Unit: "Đơn vị",
            Color: "Màu sắc",
            TypeName: "Kiểu linh kiện",
            Active: "Đang hoạt động",
            Action: "Thao tác"
        },
        btnTitle: {
            seeDetail: "Xem chi tiết",
            edit: "Chỉnh sửa",
            delete: "Xoá",
        },
        search: {
            placeHolder: "Nhập mã linh kiện, tên linh kiện, mô tả, kiểu linh kiện, đơn vị,..."
        },
        form: {
            createNew: "Thêm mới linh kiện",
            update: "Cập nhật thông tin linh kiện",
            getDetail: "Chi tiết thông tin linh kiện",
            Materialcode: "Mã linh kiện",
            Materialname: "Tên linh kiện",
            Unit: "Đơn vị",
            Typeid: "Kiểu linh kiện",
            Color: "Màu sắc",
            Description: "Mô tả 1",
            Description2: "Mô tả 2",
            status: "Đang hoạt động",
            placeHolder: {
                Materialcode: "Nhập mã linh kiện",
                Materialname: "Nhập tên linh kiện",
                Unit: "Chọn đơn vị",
                Typeid: "Chọn kiểu linh kiện",
                Color: "Nhập mã màu",
                Description: "Nhập mô tả",
            }
        },
        delete: {
            title: "Xoá linh kiện",
            description: "Bạn có chắc chắn muốn xóa linh kiện này?",
        },
        error: {
            Materialcode: {
                validateType: "Mã không đúng định dạng",
                required: "Mã linh kiện không được bỏ trống",
                maxLength: "Mã linh kiện không được quá 50 kí tự",
            },
            Materialname: {
                required: "Tên linh kiện không được bỏ trống",
                maxLength: "Tên linh kiện không được quá 255 ký tự",
            },
            Unit: {
                required: "Đơn vị không được bỏ trống",
                maxLength: "Đơn vị không được quá 36 kí tự",
            },
            Description: {
                maxLength: "Mô tả 1 không được quá 255 kí tự",
            },
            Description2: {
                maxLength: "Mô tả 2 không được quá 255 kí tự",
            },
            Typeid: {
                required: "Kiểu linh kiện không được bỏ trống"
            }
        },
        toastJS: {
            creatSuccess: "Tạo mới linh kiện thành công",
            updateSuccess: "Cập nhật linh kiện thành công",
            deleteSuccess: "Xóa thành công linh kiện",
        }
    }
}