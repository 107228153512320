import {
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
} from "./enum/TypeInventoryTicketsAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,
  isDeleteSuccess: false,
  isCreateUpdateSuccess: false,
};

const InventoryTicketsReducer = (state = stateDefault, action) => {
  switch (action.type) {
    
    case CALL_DEFAULT_REDUCER:
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      return {
        ...state,
      };

    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };
    default:
      return { ...state };
  }
};

export default InventoryTicketsReducer;
