import * as type from "./enum/actionType";
const UploadFileAction = {
    uploadFileSaga: (files = [], controlNum = '1') => {
        return {
            type: type.UPLOAD_FILE_SAGA,
            files,
            controlNum
        }
    },
    uploadFileReducer: (files = [], controlNum = '1') => {
        return {
            type: type.UPLOAD_FILE_REDUCER,
            files,
            controlNum
        }
    },
    resetUploadFileReducer: () => {
        return {
            type: 'default',
        }
    }
}
export default UploadFileAction;