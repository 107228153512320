import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { GET_LIST_WORKCENTER_API, GET_PRO_REQUIRE_DETAIL_API, INSERT_DATA_API } from "./enum/typeApi";

export async function getProRequireDetailService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_PRO_REQUIRE_DETAIL_API,
        request
    )
};
export async function getRequirementByDropdownListService(dropdowns = []) {
    let responseDropdown = {
        isSuccess: false,
        data: []
    };
    if (dropdowns.length > 0) {
        await Promise.all(dropdowns.map(async option => {
            const entityRequest = {
                productionrequirementcode: option,
            };
            let response = await CommonBase.getAsync(
                configApp.apiGateWay + GET_PRO_REQUIRE_DETAIL_API,
                entityRequest
            )
            responseDropdown.data.push(response.data)
        }))
        responseDropdown.isSuccess = true;
    }

    return responseDropdown;
};
export async function getListWorkcenterService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_LIST_WORKCENTER_API,
        request
    )
};

export async function insertDataService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + INSERT_DATA_API,
        request
    )
};

