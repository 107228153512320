export const API_REQUEST = "API_REQUEST";
export const API_REQUEST_LOAD_MORE = "API_REQUEST_LOAD_MORE";
export const API_REQUEST_FAIL = "API_REQUEST_FAIL";
export const API_REQUEST_SUCCESS = "API_REQUEST_SUCCESS";

export const TEST_ACTION_SAGA = "TEST_ACTION_SAGA";
export const TEST_ACTION_REDUCER = "TEST_ACTION_REDUCER";

export const GET_USER_ACTION_SAGA = "GET_USER_ACTION_SAGA";
export const GET_USER_ACTION_REDUCER = "GET_USER_ACTION_REDUCER";

export const ADD_USER_ACTION_SAGA = "ADD_USER_ACTION_SAGA";
export const DELETE_USER_ACTION_SAGA = "DELETE_USER_ACTION_SAGA";

export const DELETE_USER_REDUCER = "DELETE_USER_REDUCER";
export const UPDATE_USER_ACTION_SAGA = "UPDATE_USER_ACTION_SAGA";

export const GET_USER_INFO = "GET_USER_ID";
export const SET_USER_INFO = "SET_USER_ID";

export const LOGIN_SAGA = "LOGIN_SAGA";
export const LOGIN_REDUCER = "LOGIN_REDUCER";

export const AUTH_USER = "AUTH_USER";
export const UN_AUTH_USER = "UN_AUTH_USER";

export const UPDATE_DEFAULT_API = "UPDATE_DEFAULT_API";
export const UPDATE_MENU_PAGES = "UPDATE_MENU_PAGES";

//adGroup

export const GET_ADGROUP_SAGA = "GET_ADGROUP_SAGA";
export const GET_ADGROUP_REDUCER = "GET_ADGROUP_REDUCER";

//inventory unit
//get list
export const GET_INVENTORY_UNIT_SAGA = "GET_INVENTORY_UNIT_SAGA";
export const GET_INVENTORY_UNIT_REDUCER = "GET_INVENTORY_UNIT_REDUCER";
//create
export const CREATE_INVENTORY_UNIT_ACTION = "CREATE_INVENTORY_UNIT_ACTION";
//edit
export const EDIT_INVENTORY_UNIT_ACTION = "EDIT_INVENTORY_UNIT_ACTION";
//delete
export const DELETE_INVENTORY_UNIT_ACTION = "DELETE_INVENTORY_UNIT_ACTION";

// inventory
export const GET_INVENTORY_SAGA = "GET_INVENTORY_SAGA";
export const GET_INVENTORY_REDUCER = "GET_INVENTORY_REDUCER";
export const CREATE_INVENTORY_ACTION = "CREATE_INVENTORY_ACTION";
export const EDIT_INVENTORY_ACTION = "EDIT_INVENTORY_ACTION";
export const DELETE_INVENTORY_ACTION = "DELETE_INVENTORY_ACTION";
export const GET_CHILD_INVENTORY_SAGA = "GET_CHILD_INVENTORY_SAGA";
export const GET_INVENTORY_TREE_VIEW_REDUCER =
  "GET_INVENTORY_TREE_VIEW_REDUCER";
export const GET_PARENT_TREE_VIEW_INVENTORY = "GET_PARENT_TREE_VIEW_INVENTORY";
// category
export const GET_INVENTORY_TYPE_SAGA = "GET_INVENTORY_TYPE_SAGA";
export const GET_INVENTORY_TYPE_REDUCER = "GET_INVENTORY_TYPE_REDUCER";
//ad SITE
//get list
export const GET_AD_SITE_SAGA = "GET_AD_SITE_SAGA";
export const GET_AD_SITE_REDUCER = "GET_AD_SITE_REDUCER";
//create
export const CREATE_AD_SITE_ACTION = "CREATE_AD_SITE_ACTION";
//edit
export const EDIT_AD_SITE_ACTION = "EDIT_AD_SITE_ACTION";
//delete
export const DELETE_AD_SITE_ACTION = "DELETE_AD_SITE_ACTION";

//cm position persion
//get list
export const GET_CM_POSITION_PERSON_SAGA = "GET_CM_POSITION_PERSON_SAGA";

export const GET_CM_POSITION_PERSON_REDUCER = "GET_CM_POSITION_PERSON_REDUCER";
//create
export const CREATE_CM_POSITION_PERSON_ACTION =
  "CREATE_CM_POSITION_PERSON_ACTION";
//edit
export const EDIT_CM_POSITION_PERSON_ACTION = "EDIT_CM_POSITION_PERSON_ACTION";
//delete
export const DELETE_CM_POSITION_PERSON_ACTION =
  "DELETE_CM_POSITION_PERSON_ACTION";

//ad color
//get list
export const GET_AD_COLOR_SAGA = "GET_AD_COLOR_SAGA";
//reducer
export const GET_AD_COLOR_REDUCER = "GET_AD_COLOR_REDUCER";
//create
export const CREATE_AD_COLOR_ACTION = "CREATE_AD_COLOR_ACTION";
//edit
export const EDIT_AD_COLOR_ACTION = "EDIT_AD_COLOR_ACTION";
//delete
export const DELETE_AD_COLOR_ACTION = "DELETE_AD_COLOR_ACTION";

//====================== Bank list ===============
export const GET_CM_BANK_SAGA = "GET_CM_BANK_SAGA";

export const GET_CM_BANK_REDUCER = "GET_CM_BANK_REDUCER";
//create
export const CREATE_CM_BANK_ACTION = "CREATE_CM_BANK_ACTION";
//edit
export const EDIT_CM_BANK_ACTION = "EDIT_CM_BANK_ACTION";
//delete
export const DELETE_CM_BANK_ACTION = "DELETE_CM_BANK_ACTION";

//====================== Test api ===============
export const TEST_API_SAGA = "TEST_API_SAGA";
export const TEST_API_REDUCER = "TEST_API_REDUCER";

export const TEST_UPLOAD_FILE = "TEST_UPLOAD_FILE";
export const RETURN_STRING_FILE = "RETURN_STRING_FILE";

//Work (danh sách công việc)
export const GET_WORK_BY_USER_LOGIN = "GET_WORK_BY_USER_LOGIN";
export const GET_WORK_BY_USER_LOGIN_REDUCER = "GET_WORK_BY_USER_LOGIN_REDUCER";
export const UPDATE_STATUS_SOP_STEP = "UPDATE_STATUS_SOP_STEP";
export const GET_DETAIL_WORK = "GET_DETAIL_WORK";
export const RETURN_DETAIL_WORK = "RETURN_DETAIL_WORK   ";
//reducer
export const GET_APPROVE_PROCESS_SAGA = "GET_APPROVE_PROCESS_SAGA";
export const GET_APPROVE_PROCESS_REDUCER = "GET_APPROVE_PROCESS_REDUCER";
//create
export const CREATE_APPROVE_PROCESS_ACTION = "CREATE_APPROVE_PROCESS_ACTION";
//edit
export const EDIT_APPROVE_PROCESS_ACTION = "EDIT_APPROVE_PROCESS_ACTION";
//delete
export const DELETE_APPROVE_PROCESS_ACTION = "DELETE_APPROVE_PROCESS_ACTION";
// get by id
export const GET_APPROVE_PROCESS_BY_ID = "GET_APPROVE_PROCESS_BY_ID";
export const SET_APPROVE_PROCESS_BY_ID = "SET_APPROVE_PROCESS_BY_ID";
// get list phòng ban
export const GET_LIST_DEPARTMENT = "GET_LIST_DEPARTMENT";
export const SET_LIST_DEPARTMENT = "SET_LIST_DEPARTMENT";

// get list các list user theo phòng ban [list1 : [], list2 : []]
export const GET_LIST_USER_BY_DEPARTMENT = "GET_LIST_USER_BY_DEPARTMENT";
export const SET_LIST_USER_BY_DEPARTMENT = "SET_LIST_USER_BY_DEPARTMENT";
export const CALL_RESPONE_RESULT_SOP = "CALL_RESPONE_RESULT_SOP";
// thay đổi listUser
export const GET_CHANGE_LIST_USER_BY_LEVEL = "GET_CHANGE_LIST_USER_BY_LEVEL";
export const SET_CHANGE_LIST_USER_BY_LEVEL = "SET_CHANGE_LIST_USER_BY_LEVEL";

// thay đổi listWorkFlowDefaultStep getChangeListWorkFlowDefaultStep
export const GET_CHANGE_LIST_WORKFLOWDEFAULTSTEP =
  "GET_CHANGE_LIST_WORKFLOWDEFAULTSTEP";
export const SET_CHANGE_LIST_WORKFLOWDEFAULTSTEP =
  "SET_CHANGE_LIST_WORKFLOWDEFAULTSTEP";
//END AproveProcess - Luồng phê duyệt default
//END AproveProcess - Luồng phê duyệt default

//====================== Tài liệu SOP ===============
//lấy danh sách tài liệu sop
export const GET_ALL_DOCUMENT_SOP = "GET_ALL_DOCUMENT_SOP";
export const RETURN_DATA_DOCUMENT_SOP = "RETURN_DATA_DOCUMENT_SOP";
//tạo mới tài liệu sop
export const CREATE_DOCUMENT_SOP = "CREATE_DOCUMENT_SOP";
//cập nhật tài liệu sop
export const UPDATE_DOCUMENT_SOP = "UPDATE_DOCUMENT_SOP";
//xóa tài liệu SOP
export const DELETE_DOCUMENT_SOP = "DELETE_DOCUMENT_SOP";
//lấy chi tiết tài liệu sop !
export const GET_DETAIL_SOP = "GET_DETAIL_SOP";
//trả dữ liệu chi tiết sop !
export const RETURN_DETAIL_SOP = "RETURN_DETAIL_SOP";
//WorkUnit
//reducer
export const GET_WORK_UNIT_SAGA = "GET_WORK_UNIT_SAGA";
export const GET_WORK_UNIT_REDUCER = "GET_WORK_UNIT_REDUCER";
export const GET_WORK_UNIT_BY_ID = "GET_WORK_UNIT_BY_ID";
export const SET_WORK_UNIT_BY_ID = "SET_WORK_UNIT_BY_ID";
export const CREATE_WORK_UNIT_ACTION = "CREATE_WORK_UNIT_ACTION";
export const EDIT_WORK_UNIT_ACTION = "EDIT_WORK_UNIT_ACTION";
export const DELETE_WORK_UNIT_ACTION = "DELETE_WORK_UNIT_ACTION";
export const GET_DROPDOWN_WORKCENTER = "GET_DROPDOWN_WORKCENTER";
export const SET_DROPDOWN_WORKCENTER = "SET_DROPDOWN_WORKCENTER";
export const CALL_RESULT_WORK_UNIT_REDUCER = "CALL_RESULT_WORK_UNIT_REDUCER";
//get all list workcenter
export const GET_LIST_WORKCENTER_SAGA = "GET_LIST_WORKCENTER";
export const GET_LIST_WORKCENTER_REDUCER = "GET_LIST_WORKCENTER_REDUCER";

//get workcenter by Id
export const GET_WORKCENTER_BY_ID = "GET_WORKCENTER_BY_ID";
export const GET_WORKCENTER_BY_ID_REDUCER = "GET_WORKCENTER_BY_ID_REDUCER";
export const SET_WORKCENTER_PRODUCT_BY_ID = "SET_WORKCENTER_PRODUCT_BY_ID";
export const SET_WORKCENTER_BY_ID = "SET_WORKCENTER_BY_ID";
//add edit delete workcenter
export const CREATE_WORKCENTER_ACTION = "CREATE_WORKCENTER_ACTION";
export const EDIT_WORKCENTER_ACTION = "EDIT_WORKCENTER_ACTION";
export const SET_LIST_WORKCENTER_PRODUCT = "SET_LIST_WORKCENTER_PRODUCT";
export const DELETE_WORKCENTER_ACTION = "DELETE_WORKCENTER_ACTION";
export const GET_LIST_TREE_WORKAREA = "GET_LIST_TREE_WORKAREA";
export const SET_LIST_TREE_WORKAREA = "SET_LIST_TREE_WORKAREA";
export const CALL_RESULT_WORK_CENTER_REDUCER = "CALL_RESULT_WORK_CENTER_REDUCER";
///====================== DROPDOWN ===============
export const GET_DROPDOWN = "GET_DROPDOWN";
export const SET_DROPDOWN = "SET_DROPDOWN";
export const GET_DROPDOWN_PRODUCT = "GET_DROPDOWN_PRODUCT";
export const SET_DROPDOWN_PRODUCT = "SET_DROPDOWN_PRODUCT";
export const GET_DROPDOWN_MACHINE = "GET_DROPDOWN_MACHINE";
export const SET_DROPDOWN_MACHINE = "SET_DROPDOWN_MACHINE";
export const GET_DROPDOWN_MACHINE_TYPE = "GET_DROPDOWN_MACHINE_TYPE";
export const GET_DROPDOWN_MACHINE_TYPE_V2 = "GET_DROPDOWN_MACHINE_TYPE_V2";
export const SET_DROPDOWN_MACHINE_TYPE = "SET_DROPDOWN_MACHINE_TYPE";
export const SET_DROPDOWN_MACHINE_TYPE_V2 = "SET_DROPDOWN_MACHINE_TYPE_V2";
export const GET_DROP_DOWN_BY_CODE_SAGA = "GET_DROP_DOWN_BY_CODE_SAGA";
export const GET_DROP_DOWN_BY_CODE_REDUCER = "GET_DROP_DOWN_BY_CODE_REDUCER";
export const GET_DROPDOWN_MODEL = "GET_DROPDOWN_MODEL";
export const SET_DROPDOWN_MODEL = "SET_DROPDOWN_MODEL";
export const GET_DROPDOWN_COMMON_MATERIAL = "GET_DROPDOWN_COMMON_MATERIAL";
export const SET_DROPDOWN_COMMON_MATERIAL = "SET_DROPDOWN_COMMON_MATERIAL";
export const GET_DROPDOWN_LOCATION_BY_LEVEL = "GET_DROPDOWN_LOCATION_BY_LEVEL";
export const SET_DROPDOWN_LOCATION_BY_LEVEL = "SET_DROPDOWN_LOCATION_BY_LEVEL";
export const GET_DROPDOWN_LOCATION_BY_TYPE_CHECKING =
  "GET_DROPDOWN_LOCATION_BY_TYPE_CHECKING";
export const SET_DROPDOWN_LOCATION_BY_TYPE_CHECKING =
  "SET_DROPDOWN_LOCATION_BY_TYPE_CHECKING";
export const GET_DROPDOWN_LEVEL_CHECKING = "GET_DROPDOWN_LEVEL_CHECKING";

export const SET_DROPDOWN_LOCATION_LEVEL_CHECKING =
  "SET_DROPDOWN_LOCATION_LEVEL_CHECKING";

// dropdown for production requirment
export const SET_DROPDOWN_PRODUCTION = "SET_DROPDOWN_PRODUCTION";
export const GET_DROPDOWN_PRODUCTION = "GET_DROPDOWN_PRODUCTION";

export const SET_DROPDOWN_DOCUMENT = "SET_DROPDOWN_DOCUMENT";
export const GET_DROPDOWN_DOCUMENT = "GET_DROPDOWN_DOCUMENT";

export const SET_DROPDOWN_SLOT = "SET_DROPDOWN_SLOT";
export const GET_DROPDOWN_SLOT = "GET_DROPDOWN_SLOT";

//Dropdown CalendarManager
export const GET_DROPDOWN_CALENDAR_MANAGER = "GET_DROPDOWN_CALENDAR_MANAGER";
export const SET_DROPDOWN_CALENDAR_MANAGER = "SET_DROPDOWN_CALENDAR_MANAGER";

///====================== WORKAREA ===============
export const GET_LIST_WORKAREA_ACTION = "GET_LIST_WORKAREA_ACTION";
export const SET_LIST_WORKAREA_ACTION = "SET_LIST_WORKAREA_ACTION";
export const CREATE_WORKAREA_ACTION = "CREATE_WORKAREA_ACTION";
export const EDIT_WORKAREA_ACTION = "EDIT_WORKAREA_ACTION";
export const DELETE_WORKAREA_ACTION = "DELETE_WORKAREA_ACTION";
export const GET_WORKAREA_BY_ID = "GET_WORKAREA_BY_ID";
export const SET_WORKAREA_BY_ID = "SET_WORKAREA_BY_ID";
export const SET_LIST_WORKAREA_SHOW = "SET_LIST_WORKAREA_SHOW";
export const CALL_RESULT_WORK_AREA_REDUCER = "CALL_RESULT_WORK_AREA_REDUCER";
///====================== END ------WORKAREA ===============

///====================== TIME MANAGER ===============
//TIME SCHEDULE MANAGER
export const GET_LIST_TIMESCHEDULE_MANAGER_SAGA =
  "GET_LIST_TIMESCHEDULE_MANAGER_SAGA";
export const GET_LIST_TIMESCHEDULE_MANAGER_REDUCER =
  "GET_LIST_TIMESCHEDULE_MANAGER_REDUCER";
export const GET_TIMESCHEDULE_MANAGER_BY_ID = "GET_TIMESCHEDULE_MANAGER_BY_ID";
export const GET_TIMESCHEDULE_MANAGER_BY_ID_REDUCER =
  "GET_TIMESCHEDULE_MANAGER_BY_ID_REDUCER";
export const SET_RECREATIONREQUEST_TIMESCHEDULE_BY_ID = "SET_RECREATIONREQUEST_TIMESCHEDULE_BY_ID";
export const SET_LIST_TIMESCHEDULE_RECREATIONREQUEST = "SET_LIST_TIMESCHEDULE_RECREATIONREQUEST";

export const CREATE_TIMESCHEDULE_MANAGER_ACTION =
  "CREATE_TIMESCHEDULE_MANAGER_ACTION";
export const EDIT_TIMESCHEDULE_MANAGER_ACTION = "EDIT_TIMESCHEDULE_MANAGER_ACTION";
export const DELETE_TIMESCHEDULE_MANAGER_ACTION =
  "DELETE_TIMESCHEDULE_MANAGER_ACTION";
export const CALL_RESPONSE_TIMESCHEDULE_RESULT = "CALL_RESPONSE_TIMESCHEDULE_RESULT";
export const GET_DROPDOWN_WORKAREA_SAGA = "GET_DROPDOWN_WORKAREA_SAGA";
export const GET_DROPDOWN_WORKAREA_REDUCER = "GET_DROPDOWN_WORKAREA_REDUCER";
export const CLOSE_POPUP_FORM_CALENDAR_WORK_MANAGER = "CLOSE_POPUP_FORM_CALENDAR_WORK_MANAGER";
export const CLOSE_POPUP_DELETE_CALENDAR_WORK_MANAGER = "CLOSE_POPUP_DELETE_CALENDAR_WORK_MANAGER";


//TIME SHIFT MANAGER
export const GET_LIST_TIMESHIFT_MANAGER_SAGA =
  "GET_LIST_TIMESHIFT_MANAGER_SAGA";
export const GET_LIST_TIMESHIFT_MANAGER_REDUCER =
  "GET_LIST_TIMESHIFT_MANAGER_REDUCER";
export const GET_TIMESHIFT_MANAGER_BY_ID = "GET_TIMESHIFT_MANAGER_BY_ID";
export const GET_TIMESHIFT_MANAGER_BY_ID_REDUCER =
  "GET_TIMESHIFT_MANAGER_BY_ID_REDUCER";
export const SET_RECREATIONREQUEST_BY_ID = "SET_RECREATIONREQUEST_BY_ID";
export const SET_LIST_RECREATIONREQUEST = "SET_LIST_RECREATIONREQUEST";

export const CREATE_TIMESHIFT_MANAGER_ACTION =
  "CREATE_TIMESHIFT_MANAGER_ACTION";
export const EDIT_TIMESHIFT_MANAGER_ACTION = "EDIT_TIMESHIFT_MANAGER_ACTION";
export const DELETE_TIMESHIFT_MANAGER_ACTION =
  "DELETE_TIMESHIFT_MANAGER_ACTION";
export const CALL_RESPONSE_TIMESHIFT_RESULT = "CALL_RESPONSE_TIMESHIFT_RESULT";
// GROUP WORK DAY
export const GET_LIST_GROUP_WORKDAY_SAGA = "GET_LIST_GROUP_WORKDAY_SAGA";
export const GET_LIST_GROUP_WORKDAY_REDUCER = "GET_LIST_GROUP_WORKDAY_REDUCER";
export const GET_GROUP_WORKDAY_BY_ID = "GET_GROUP_WORKDAY_BY_ID";
export const GET_GROUP_WORKDAY_BY_ID_REDUCER =
  "GET_GROUP_WORKDAY_BY_ID_REDUCER";

export const CREATE_GROUP_WORKDAY_ACTION = "CREATE_GROUP_WORKDAY_ACTION";
export const EDIT_GROUP_WORKDAY_ACTION = "EDIT_GROUP_WORKDAY_ACTION";
export const DELETE_GROUP_WORKDAY_ACTION = "DELETE_GROUP_WORKDAY_ACTION";
export const CLOSE_POPUP_DELETE_GROUP_WORK_DAY_SCREEN = "CLOSE_POPUP_DELETE_GROUP_WORK_DAY_SCREEN";
export const CLOSE_POPUP_fORM_GROUP_WORK_DAY_SCREEN = "CLOSE_POPUP_fORM_GROUP_WORK_DAY_SCREEN";


// TIME MANAGER TYPE
export const GET_LIST_TIME_MANAGER_TYPE = "GET_LIST_TIME_MANAGER_TYPE";
export const SET_LIST_TIME_MANAGER_TYPE = "SET_LIST_TIME_MANAGER_TYPE";
export const GET_TIME_MANAGER_TYPE_BY_ID = "GET_TIME_MANAGER_TYPE_BY_ID";
export const SET_TIME_MANAGER_TYPE_BY_ID = "SET_TIME_MANAGER_TYPE_BY_ID";

export const CREATE_TIME_MANAGER_TYPE = "CREATE_TIME_MANAGER_TYPE";
export const EDIT_TIME_MANAGER_TYPE = "EDIT_TIME_MANAGER_TYPE";
export const DELETE_TIME_MANAGER_TYPE = "DELETE_TIME_MANAGER_TYPE";
export const CLOSE_POPUP_DELETE_TIME_TYPE_MANAGER = "CLOSE_POPUP_DELETE_TIME_TYPE_MANAGER";
export const CLOSE_POPUP_FORM_TIME_TYPE_MANAGER = "CLOSE_POPUP_FORM_TIME_TYPE_MANAGER";

export const SAVE_MODAL_STATUS = "SAVE_MODAL_STATUS";

// CalenderManager
export const GET_LIST_DAY_BY_YEAR_MONTH_SAGA =
  "GET_LIST_DAY_BY_YEAR_MONTH_SAGA";
export const GET_LIST_DAY_BY_YEAR_MONTH_REDUCER =
  "GET_LIST_DAY_BY_YEAR_MONTH_REDUCER";

export const SAVE_LIST_DAY_BY_YEAR_MONTH_SAGA =
  "SAVE_LIST_DAY_BY_YEAR_MONTH_SAGA";
export const SAVE_LIST_DAY_BY_YEAR_MONTH_REDUCER =
  "SAVE_LIST_DAY_BY_YEAR_MONTH_REDUCER";
export const CALL_DEFAULT_CREATE_UPDATE =
  "CALL_DEFAULT_CREATE_UPDATE";



// Worker

export const GET_ALL_WORKER = "GET_ALL_WORKER";
export const RETURN_DATA_WORKER = "RETURN_DATA_WORKER";
export const CREATE_WORKER = "CREATE_WORKER";
export const UPDATE_WORKER = "UPDATE_WORKER";
export const DELETE_WORKER = "DELETE_WORKER";
export const GET_DETAIL_WORKER = "GET_DETAIL_WORKER";
export const RETURN_OBJECT_WORKER = "RETURN_OBJECT_WORKER";
export const CALL_RESULT_FROM_WORKER_API = "CALL_RESULT_FROM_WORKER_API";
export const CALL_DEFAULT_FROM_WORKER = "CALL_DEFAULT_FROM_WORKER";
export const WORKER_UPLOAD_FILE_SAGA = "WORKER_UPLOAD_FILE_SAGA";
export const WORKER_UPLOAD_FILE_REDUCER = "WORKER_UPLOAD_FILE_REDUCER";
export const WORKER_UPLOAD_FILE_DONE = "WORKER_UPLOAD_FILE_DONE";

export const CREATE_ACCOUNT_SAGA = "CREATE_ACCOUNT_SAGA";
export const CREATE_ACCOUNT_REDUCER = "CREATE_ACCOUNT_REDUCER";

export const RESET_ACCOUNT_SAGA = "RESET_ACCOUNT_SAGA";
export const RESET_ACCOUNT_REDUCER = "RESET_ACCOUNT_REDUCER";

// WorkerSkill

export const GET_ALL_WORKER_SKILL = "GET_ALL_WORKER_SKILL";
export const RETURN_DATA_WORKER_SKILL = "RETURN_DATA_WORKER_SKILL";
export const CREATE_WORKER_SKILL = "CREATE_WORKER_SKILL";
export const UPDATE_WORKER_SKILL = "UPDATE_WORKER_SKILL";
export const DELETE_WORKER_SKILL = "DELETE_WORKER_SKILL";
export const GET_DETAIL_WORKER_SKILL = "GET_DETAIL_WORKER_SKILL";
export const RETURN_OBJECT_WORKER_SKILL = "RETURN_OBJECT_WORKER_SKILL";
export const CALL_RESULT_FROM_WORKER_SKILL_API =
  "CALL_RESULT_FROM_WORKER_SKILL_API";
export const CALL_DEFAULT_FROM_WORKER_SKILL = "CALL_DEFAULT_FROM_WORKER_SKILL";
// Skill

export const GET_ALL_SKILL = "GET_ALL_SKILL";
export const RETURN_DATA_SKILL = "RETURN_DATA_SKILL";
export const CREATE_SKILL = "CREATE_SKILL";
export const UPDATE_SKILL = "UPDATE_SKILL";
export const DELETE_SKILL = "DELETE_SKILL";
export const GET_DETAIL_SKILL = "GET_DETAIL_SKILL";
export const RETURN_OBJECT_SKILL = "RETURN_OBJECT_SKILL";
export const CALL_RESULT_FROM_SKILL_API = "CALL_RESULT_FROM_SKILL_API";
export const CALL_DEFAULT_FROM_SKILL = "CALL_DEFAULT_FROM_SKILL";
export const SKILL_UPLOAD_FILE_SAGA = "SKILL_UPLOAD_FILE_SAGA";
export const SKILL_UPLOAD_FILE_REDUCER = "SKILL_UPLOAD_FILE_REDUCER";
export const SKILL_UPLOAD_FILE_DONE = "SKILL_UPLOAD_FILE_DONE";
export const CLEAR_DATA_SKILL_REDUCER = "CLEAR_DATA_SKILL_REDUCER"
export const CLOSE_DELETE_POPUP_SKILL_SREEN = "CLOSE_DELETE_POPUP_SKILL_SREEN_popup"
export const CLOSE_LOADING_TABLE_SKILL_SCREEN = "CLOSE_LOADING_TABLE_SKILL_SCREEN"


// Machine Management
export const GET_ALL_MACHINES = "GET_ALL_MACHINES";
export const RETURN_DATA_MACHINES = "RETURN_DATA_MACHINE";
export const CREATE_MACHINE = "CREATE_MACHINE";
export const UPDATE_MACHINE = "UPDATE_MACHINE";
export const DELETE_MACHINE = "DELETE_MACHINE";
export const GET_DETAIL_MACHINE = "GET_DETAIL_MACHINE";
export const RETURN_DETAIL_MACHINE = "RETURN_DETAIL_MACHINE";
export const MACHINE_GET_DETAIL_DONE = "MACHINE_GET_DETAIL_DONE";

export const CALL_RESULT_FROM_MACHINE_API = "CALL_RESULT_FROM_MACHINE_API";
export const CALL_DEFAULT_FROM_MACHINE = "CALL_DEFAULT_FROM_MACHINE";
export const EXPORT_EXCEL_MACHINE = "EXPORT_EXCEL_MACHINE";
export const MACHINE_UPLOAD_FILE_SAGA = "MACHINE_UPLOAD_FILE_SAGA";
export const MACHINE_UPLOAD_FILE_REDUCER = "MACHINE_UPLOAD_FILE_REDUCER";
export const MACHINE_UPLOAD_FILE_DONE = "MACHINE_UPLOAD_FILE_DONE";
export const CLOSE_POP_UP_DELETE_WARNING_MACHINE_SCREEN = "CLOSE_POP_UP_DELETE_WARNING_MACHINE_SCREEN";
export const CLOSE_FORM_MACHINE_SCREEN = "CLOSE_FORM_MACHINE_SCREEN";
export const CLOSE_LOADING_MACHINE_SCREEN = "CLOSE_LOADING_MACHINE_SCREEN";


// TimeSchedule management
export const GET_ALL_TIMESCHEDULE = "GET_ALL_TIMESCHEDULE";
export const RETURN_DATA_TIMESCHEDULE = "RETURN_DATA_TIMESCHEDULE";
export const CREATE_TIMESCHEDULE = "CREATE_TIMESCHEDULE";
export const UPDATE_TIMESCHEDULE = "UPDATE_TIMESCHEDULE";
export const DELETE_TIMESCHEDULE = "DELETE_TIMESCHEDULE";
export const GET_DETAIL_TIMESCHEDULE = "GET_DETAIL_TIMESCHEDULE";
export const RETURN_DETAIL_TIMESCHEDULE = "RETURN_DETAIL_TIMESCHEDULE";
export const CALL_RESULT_FROM_TIMESCHEDULE_API = "CALL_RESULT_FROM_TIMESCHEDULE_API";
export const CALL_DEFAUT_FROM_TIMESCHEDULE = "CALL_DEFAUT_FROM_TIMESCHEDULE";

// Machine Type Management
export const GET_ALL_MACHINE_TYPES = "GET_ALL_MACHINE_TYPES";
export const RETURN_DATA_MACHINE_TYPES = "RETURN_DATA_MACHINE_TYPES";
export const CREATE_MACHINE_TYPE = "CREATE_MACHINE_TYPE";
export const UPDATE_MACHINE_TYPE = "UPDATE_MACHINE_TYPE";
export const DELETE_MACHINE_TYPE = "DELETE_MACHINE_TYPE";
export const GET_DETAIL_MACHINE_TYPE = "GET_DETAIL_MACHINE_TYPE";
export const RETURN_DETAIL_MACHINE_TYPE = "RETURN_DETAIL_MACHINE_TYPE";
export const CALL_RESULT_FROM_MACHINE_TYPE_API =
  "CALL_RESULT_FROM_MACHINE_TYPE_API";
export const CALL_DEFAULT_FROM_MACHINE_TYPE = "CALL_DEFAULT_FROM_MACHINE_TYPE";
export const CLOSE_POP_UP_DELETE_WARNING_MACHINE_TYPE_SCREEN = "CLOSE_POP_UP_DELETE_WARNING_MACHINE_TYPE_SCREEN";
export const CLOSE_FORM_MACHINE_TYPE_SCREEN = "CLOSE_FORM_MACHINE_TYPE_SCREEN";
export const CLOSE_LOADING_MACHINE_TYPE_SCREEN = "CLOSE_LOADING_MACHINE_TYPE_SCREEN";

///====================== END ------TIME MANAGER ===============

// Product

export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const RETURN_DATA_PRODUCT = "RETURN_DATA_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const GET_DETAIL_PRODUCT = "GET_DETAIL_PRODUCT";
export const RETURN_OBJECT_PRODUCT = "RETURN_OBJECT_PRODUCT";
export const CALL_RESULT_FROM_PRODUCT_API = "CALL_RESULT_FROM_PRODUCT_API";
export const CALL_DEFAULT_FROM_PRODUCT = "CALL_DEFAULT_FROM_PRODUCT";
export const GET_CODE = "GET_CODE";
export const RETURN_CODE = "RETURN_CODE";
export const CLOSE_POPUP_PRODUCTION_DELETE_WARNING = "CLOSE_POPUP_PRODUCTION_DELETE_WARNING--PRODUCT_SCREEN";
export const DISPLAY_ERROR_FROM_API = "DISPLAY_ERROR_FROM_API--PRODUCT_SCREEN";
// model

export const GET_ALL_MODEL = "GET_ALL_MODEL";
export const RETURN_DATA_MODEL = "RETURN_DATA_MODEL";
export const CREATE_MODEL = "CREATE_MODEL";
export const UPDATE_MODEL = "UPDATE_MODEL";
export const DELETE_MODEL = "DELETE_MODEL";
export const DELETE_MODEL_REDUCER = "DELETE_MODEL_REDUCER";
export const GET_DETAIL_MODEL = "GET_DETAIL_MODEL";
export const RETURN_OBJECT_MODEL = "RETURN_OBJECT_MODEL";
export const CALL_RESULT_FROM_MODEL_API = "CALL_RESULT_FROM_MODEL_API";
export const CALL_DEFAULT_FROM_MODEL = "CALL_DEFAULT_FROM_MODEL";

// accessories type

export const GET_ALL_ACCESSORIES_TYPE = "GET_ALL_ACCESSORIES_TYPE";
export const RETURN_DATA_ACCESSORIES_TYPE = "RETURN_DATA_ACCESSORIES_TYPE";
export const CREATE_ACCESSORIES_TYPE = "CREATE_ACCESSORIES_TYPE";
export const UPDATE_ACCESSORIES_TYPE = "UPDATE_ACCESSORIES_TYPE";
export const DELETE_ACCESSORIES_TYPE = "DELETE_ACCESSORIES_TYPE";
export const GET_DETAIL_ACCESSORIES_TYPE = "GET_DETAIL_ACCESSORIES_TYPE";
export const RETURN_OBJECT_ACCESSORIES_TYPE = "RETURN_OBJECT_ACCESSORIES_TYPE";
export const CALL_RESULT_FROM_ACCESSORIES_TYPE_API =
  "CALL_RESULT_FROM_ACCESSORIES_TYPE_API";
export const CALL_DEFAULT_FROM_ACCESSORIES_TYPE =
  "CALL_DEFAULT_FROM_ACCESSORIES_TYPE";
export const IS_DELETE_ACCESSORIES_TYPE = "IS_DELETE_ACCESSORIES_TYPE";
export const CLOSE_LOADING_TABLE_ACCESSORIES_TYPE_SCREEN = "CLOSE_LOADING_TABLE_ACCESSORIES_TYPE_SCREEN";
export const CLOSE_POPUP_FORM_ACTION_CREATE_OR_UPDATE_ACCESSORIES_TYPE_SCREEN = "CLOSE_POPUP_FORM_ACTION_CREATE_OR_UPDATE_ACCESSORIES_TYPE_SCREEN";


// Code
export const GET_CODE_SAGA = "GET_CODE_SAGA";
export const SET_CODE_REDUCER = "SET_CODE_REDUCER";
export const GET_CODE_MACHINE_SAGA = "GET_CODE_MACHINE_SAGA";
export const GET_CODE_MATAINCENER_SAGA = "GET_CODE_MATAINCENER_SAGA";

// Accessories

// Unit

export const GET_ALL_UNIT = "GET_ALL_UNIT";
export const RETURN_DATA_UNIT = "RETURN_DATA_UNIT";
export const CREATE_UNIT = "CREATE_UNIT";
export const UPDATE_UNIT = "UPDATE_UNIT";
export const DELETE_UNIT = "DELETE_UNIT";
export const GET_DETAIL_UNIT = "GET_DETAIL_UNIT";
export const RETURN_OBJECT_UNIT = "RETURN_OBJECT_UNIT";
export const CALL_RESULT_FROM_UNIT_API = "CALL_RESULT_FROM_UNIT_API";
export const CALL_DEFAULT_FROM_UNIT = "CALL_DEFAULT_FROM_UNIT";
export const GET_DROP_DOWN_UNIT = "GET_DROP_DOWN_UNIT";
export const CLEAR_ALL_UNIT_REDUCER_STATE = "CLEAR_ALL_UNIT_REDUCER_STATE";

// convert unit

export const GET_ALL_CONVERT_UNIT = "GET_ALL_CONVERT_UNIT";
export const RETURN_DATA_CONVERT_UNIT = "RETURN_DATA_CONVERT_UNIT";
export const CREATE_CONVERT_UNIT = "CREATE_CONVERT_UNIT";
export const UPDATE_CONVERT_UNIT = "UPDATE_CONVERT_UNIT";
export const DELETE_CONVERT_UNIT = "DELETE_CONVERT_UNIT";
export const GET_DETAIL_CONVERT_UNIT = "GET_DETAIL_CONVERT_UNIT";
export const RETURN_OBJECT_CONVERT_UNIT = "RETURN_OBJECT_CONVERT_UNIT";
export const CALL_RESULT_FROM_CONVERT_UNIT_API =
  "CALL_RESULT_FROM_CONVERT_UNIT_API";
export const CALL_DEFAULT_FROM_CONVERT_UNIT = "CALL_DEFAULT_FROM_CONVERT_UNIT";
export const CLOSE_POPUP_DELETE_IN_CONVERT_TABLE_UNIT = "CLOSE_POPUP_DELETE_IN_CONVERT_TABLE_UNIT--UNIT_SCREEN";
export const CLOSE_LOADING_TABLE_IN_CONVERT_TABLE_UNIT = "CLOSE_LOADING_TABLE_IN_CONVERT_TABLE_UNIT--UNIT_SCREEN";

// NCCInformation

export const GET_ALL_NCCINFORMATION = "GET_ALL_NCCINFORMATION";
export const RETURN_DATA_NCCINFORMATION = "RETURN_DATA_NCCINFORMATION";
export const CREATE_NCCINFORMATION = "CREATE_NCCINFORMATION";
export const UPDATE_NCCINFORMATION = "UPDATE_NCCINFORMATION";
export const DELETE_NCCINFORMATION = "DELETE_NCCINFORMATION";
export const GET_DETAIL_NCCINFORMATION = "GET_DETAIL_NCCINFORMATION";
export const RETURN_OBJECT_NCCINFORMATION = "RETURN_OBJECT_NCCINFORMATION";
export const CALL_RESULT_FROM_NCCINFORMATION_API =
  "CALL_RESULT_FROM_NCCINFORMATION_API";
export const CALL_DEFAULT_FROM_NCCINFORMATION =
  "CALL_DEFAULT_FROM_NCCINFORMATION";
export const DELETE_NCCINFORMATION_REDUCER = "DELETE_NCCINFORMATION_REDUCER";
export const CALL_INFO_FROM_NCCINFORMATION_API =
  "CALL_INFO_FROM_NCCINFORMATION_API";
export const CLEAR_ALL_NCC_REDUCER_STATE = "CLEAR_ALL_NCC_REDUCER_STATE";
export const CLOSE_POPUP_NCC_SCREEN = "CLOSE_POPUP_NCC_SCREEN";

// location level

export const GET_ALL_LOCATION_LEVEL = "GET_ALL_LOCATION_LEVEL";
export const RETURN_DATA_LOCATION_LEVEL = "RETURN_DATA_LOCATION_LEVEL";
export const CREATE_LOCATION_LEVEL = "CREATE_LOCATION_LEVEL";
export const UPDATE_LOCATION_LEVEL = "UPDATE_LOCATION_LEVEL";
export const DELETE_LOCATION_LEVEL = "DELETE_LOCATION_LEVEL";
export const GET_DETAIL_LOCATION_LEVEL = "GET_DETAIL_LOCATION_LEVEL";
export const RETURN_OBJECT_LOCATION_LEVEL = "RETURN_OBJECT_LOCATION_LEVEL";
export const CALL_RESULT_FROM_LOCATION_LEVEL_API =
  "CALL_RESULT_FROM_LOCATION_LEVEL_API";
export const CALL_DEFAULT_FROM_LOCATION_LEVEL =
  "CALL_DEFAULT_FROM_LOCATION_LEVEL";
export const IS_DELETE_LOCATION_LEVEL = "IS_DELETE_LOCATION_LEVEL";

// location

export const GET_ALL_LOCATION = "GET_ALL_LOCATION";
export const RETURN_DATA_LOCATION = "RETURN_DATA_LOCATION";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_REDUCER = "DELETE_LOCATION_REDUCER";
export const GET_DETAIL_LOCATION = "GET_DETAIL_LOCATION";
export const RETURN_OBJECT_LOCATION = "RETURN_OBJECT_LOCATION";
export const CALL_RESULT_FROM_LOCATION_API = "CALL_RESULT_FROM_LOCATION_API";
export const CALL_DEFAULT_FROM_LOCATION = "CALL_DEFAULT_FROM_LOCATION";

// Sloc Code
export const GET_SLOC_CODE_SAGA = "GET_SLOC_CODE_SAGA";
export const SET_SLOC_CODE_REDUCER = "SET_SLOC_CODE_REDUCER";

// lấy thông tin lô hàng
export const GET_SHIFTMENT_INFORMATION = "GET_SHIFTMENT_INFORMATION";
export const SET_SHIFTMENT_INFORMATION = "SET_SHIFTMENT_INFORMATION";
export const UPDATE_TEMPORY = "UPDATE_TEMPORY";
export const PRINT_ALL_TEM = "PRINT_ALL_TEM";
export const PRINT_TEM = "PRINT_TEM";
export const RE_PRINT_TEM = "RE_PRINT_TEM";
export const IMPORT_PACKAGE = "IMPORT_PACKAGE";
export const IS_IMPORT_PACKAGE_SUCCESS = "IS_IMPORT_PACKAGE_SUCCESS";

export const GET_NEW_PACKAGE_INFORMATION = "GET_NEW_PACKAGE_INFORMATION";
export const SET_NEW_PACKAGE_INFORMATION = "SET_NEW_PACKAGE_INFORMATION";

export const SET_NEW_PACKAGE_CODE_ARRAY = "SET_NEW_PACKAGE_CODE_ARRAY";
export const SET_PACKAGE_CODE_DEFAULT = "SET_PACKAGE_CODE_DEFAULT";

// storebill action
// các action lấy phiếu cất hàng
export const GET_STORE_BILL_TICKET_SAGA = "GET_STORE_BILL_TICKET_SAGA";
export const SET_STORE_BILL_TICKET_REDUCER = "SET_STORE_BILL_TICKET_REDUCER";
export const SET_DETAIL_STOREBILL = "SET_DETAIL_STOREBILL";
// lưu ticket storebill
export const SAVE_STORE_BILL_TICKET = "SAVE_STORE_BILL_TICKET";

export const SET_STATUS_SAVE_STORE_BILL_SUCCESS =
  "SET_STATUS_SAVE_STORE_BILL_SUCCESS";
export const GET_STORE_BILL_SAGA = "GET_STORE_BILL_SAGA";
export const SET_STORE_BILL_REDUCER = "SET_STORE_BILL_REDUCER";
export const COMPLETE_STORE_BILL_TICKET = "COMPLETE_STORE_BILL_TICKET";
export const CHECK_IF_LOCATION_EXIST = "CHECK_IF_LOCATION_EXIST";
export const SET_CHECK_IF_LOCATION_EXIST = "SET_CHECK_IF_LOCATION_EXIST";
export const SCAN_PACKAGE_CODE = "SCAN_PACKAGE_CODE";
export const SET_SCAN_PACKAGE_CODE = "SET_SCAN_PACKAGE_CODE";
export const SAVE_STORAGE_INFO = "SAVE_STORAGE_INFO";
export const SET_SAVE_STORAGE_INFO = "SET_SAVE_STORAGE_INFO";
export const OPEN_SCREEN_SCAN_PACKAGE = "OPEN_SCREEN_SCAN_PACKAGE--SCAN_PACKAGE_TO_STORE_BY_LOCATION";
export const BLOCK_LOCATION_SEARCH_INPUT = "BLOCK_LOCATION_SEARCH_INPUT--STORE_PACKAGE_INTO_WAREHOURSE"
// lấy thông tin package
export const GET_ALL_PACKAGEINFO_SAGA = "GET_ALL_PACKAGEINFO_SAGA";
export const GET_DETAIL_PACKAGEINFO_SAGA = "GET_DETAIL_PACKAGEINFO_SAGA";
export const UPDATE_PACKAGEINFO_SAGA = "UPDATE_PACKAGEINFO_SAGA";
export const DELETE_PACKAGEINFO_SAGA = "DELETE_PACKAGEINFO_SAGA";
export const CREATE_PACKAGEINFO_SAGA = "CREATE_PACKAGEINFO_SAGA";

export const RETURN_DATA_PACKAGEINFO = "RETURN_DATA_PACKAGEINFO";
export const RETURN_PACKAGEINFO_REDUCER = "RETURN_PACKAGEINFO_REDUCER";
export const RETURN_OBJECT_PACKAGEINFO_REDUCER = "RETURN_OBJECT_PACKAGEINFO_REDUCER";
export const CALL_RESULT_PACKAGEINFO_REDUCER = "CALL_RESULT_PACKAGEINFO_REDUCER";
export const CALL_DEFAULT_PACKAGEINFO_REDUCER = "CALL_DEFAULT_PACKAGEINFO_REDUCER";
export const DELETE_PACKAGEINFO_REDUCER = "DELETE_PACKAGEINFO_REDUCER";

//PurchaseOrder
export const GET_ALL_PURCHASE_ORDER = "GET_ALL_PURCHASE_ORDER";
export const RETURN_DATA_PURCHASE_ORDER = "RETURN_DATA_PURCHASE_ORDER";
export const GET_DETAIL_PURCHASE_ORDER = "GET_DETAIL_PURCHASE_ORDER";
export const RETURN_OBJECT_PURCHASE_ORDER = "RETURN_OBJECT_PURCHASE_ORDER";

//DeliveryOrder
export const GET_ALL_DELIVERY_ORDER = "GET_ALL_DELIVERY_ORDER";
export const RETURN_DATA_DELIVERY_ORDER = "RETURN_DATA_DELIVERY_ORDER";
export const GET_DETAIL_DELIVERY_ORDER = "GET_DETAIL_DELIVERY_ORDER";
export const GET_INFO_DELIVERY_ORDER_SAGA = "GET_INFO_DELIVERY_ORDER_SAGA";
export const RETURN_OBJECT_DELIVERY_ORDER = "RETURN_OBJECT_DELIVERY_ORDER";
export const RETURN_INFO_DELIVERY_ORDER = "RETURN_INFO_DELIVERY_ORDER";
export const DELETE_DELIVERY_ORDER_REDUCER = "DELETE_DELIVERY_ORDER_REDUCER";
export const CALL_DEFAULT_FROM_DELIVERY_ORDER =
  "CALL_DEFAULT_FROM_DELIVERY_ORDER";
export const CREATE_DELIVERY_ORDER = "CREATE_DELIVERY_ORDER";
export const UPDATE_DELIVERY_ORDER = "UPDATE_DELIVERY_ORDER";
export const DELETE_DELIVERY_ORDER = "DELETE_DELIVERY_ORDER";
export const CALL_RESULT_FROM_DELIVERY_ORDER_API =
  "CALL_RESULT_FROM_DELIVERY_ORDER_API";

// storage tranfer
export const GET_PACKAGE_INFO_BY_PACK_CODE_SAGA =
  "GET_PACKAGE_INFO_BY_PACK_CODE_SAGA";
export const GET_WAREHOUSE_TRANFER_NOTE = "GET_WAREHOUSE_TRANFER_NOTE";
export const RETURN_DATA_TRANFER_NOTE = "RETURN_DATA_TRANFER_NOTE";
export const CALL_RESULT_FROM_TRANFER_API = "CALL_RESULT_FROM_TRANFER_API";
export const CALL_DEFAULT_FROM_TRANFER = "CALL_DEFAULT_FROM_TRANFER";
export const RETURN_OBJECT_TRANFER = "RETURN_OBJECT_TRANFER";
export const GET_PACKAGE_INFO_BY_PACK_CODE_REDUCER =
  "GET_PACKAGE_INFO_BY_PACK_CODE_REDUCER";
export const CREATE_PACKAGE_TO_WAREHOUSETRANS_SAGA =
  "CREATE_PACKAGE_TO_WAREHOUSETRANS_SAGA";
export const CLEAR_DATA_PACKAGE_INFOR = "CLEAR_DATA_PACKAGE_INFOR";
export const DELETE_PACKAGE_TO_WAREHOUSETRANS_SAGA =
  "DELETE_PACKAGE_TO_WAREHOUSETRANS_SAGA";
export const IS_DELETE_PACKAGE_TO_WAREHOUSETRANS_REDUCER =
  "IS_DELETE_PACKAGE_TO_WAREHOUSETRANS_REDUCER";
export const GET_PICKUP_GOODS_PACKAGE_TO_TRANS_SAGA =
  "GET_PICKUP_GOODS_PACKAGE_TO_TRANS_SAGA";
export const RETURN_DATA_PICKUP_GOODS_REDUCER =
  "RETURN_DATA_PICKUP_GOODS_REDUCER";
export const GET_STORE_GOODS_PACKAGE_TO_TRANS_SAGA =
  "GET_STORE_GOODS_PACKAGE_TO_TRANS_SAGA";
export const RETURN_DATA_STORE_GOODS_REDUCER =
  "RETURN_DATA_STORE_GOODS_REDUCER";
export const GET_DONE_NOTE_PACKAGE_TO_TRANS_SAGA =
  "GET_DONE_NOTE_PACKAGE_TO_TRANS_SAGA";
export const RETURN_DATA_ONE_NOTE_REDUCER = "RETURN_DATA_ONE_NOTE_REDUCER";
export const STORED_ERROR_REDUCER = "STORED_ERROR_REDUCER";
//PurchaseOrder
export const GET_ALL_PO_SAGA = "GET_ALL_PO_SAGA";
export const RETURN_DATA_PO_REDUCER = "RETURN_DATA_PO_REDUCER";
export const DELETE_PO_SAGA = "DELETE_PO_SAGA";
export const GET_DETAIL_PO_SAGA = "GET_DETAIL_PO_SAGA";
export const GET_DETAIL_PO_REDUCER = "GET_DETAIL_PO_REDUCER";
export const ADD_PO_SAGA = "ADD_PO_SAGA";
export const CALL_SAVE_DATA = "CALL_SAVE_DATA";
export const UPDATE_PO_SAGA = "UPDATE_PO_SAGA";

//PetitionerManager
export const GET_ALL_PETITIONER_SAGA = "GET_ALL_PETITIONER_SAGA";
export const GET_DETAIL_PETITIONER_SAGA = "GET_DETAIL_PETITIONER_SAGA";
export const UPDATE_PETITIONER_SAGA = "UPDATE_PETITIONER_SAGA";
export const DELETE_PETITIONER_SAGA = "DELETE_PETITIONER_SAGA";
export const CREATE_PETITIONER_SAGA = "CREATE_PETITIONER_SAGA";
export const RETURN_DATA_PETITIONER = " RETURN_DATA_PETITIONER";
export const RETURN_PETITIONER_REDUCER = " RETURN_PETITIONER_REDUCER";
export const RETURN_OBJECT_PETITIONER_REDUCER =
  " RETURN_OBJECT_PETITIONER_REDUCER";
export const CALL_RESULT_PETITIONER_REDUCER = " CALL_RESULT_PETITIONER_REDUCER";
export const CALL_DEFAULT_PETITIONER_REDUCER =
  "CALL_DEFAULT_PETITIONER_REDUCER";
export const DELETE_PETITIONER_REDUCER = "DELETE_PETITIONER_REDUCER";

//PickingList
export const GET_ALL_PICKING_LIST_SAGA = "GET_ALL_PICKING_LIST_SAGA";
export const GET_DETAIL_PICKING_LIST_SAGA = "GET_DETAIL_PICKING_LIST_SAGA";
export const DELETE_PICKING_LIST_SAGA = "DELETE_PICKING_LIST_SAGA";
export const CREATE_PICKING_LIST_SAGA = "CREATE_PICKING_LIST_SAGA";
export const RETURN_DATA_PICKING_LIST = " RETURN_DATA_PICKING_LIST";
export const RETURN_PICKING_LIST_REDUCER = " RETURN_PICKING_LIST_REDUCER";
export const RETURN_OBJECT_PICKING_LIST_REDUCER =
  " RETURN_OBJECT_PICKING_LIST_REDUCER";
export const CALL_RESULT_PICKING_LIST_REDUCER =
  " CALL_RESULT_PICKING_LIST_REDUCER";
export const CALL_DEFAULT_PICKING_LIST_REDUCER =
  "CALL_DEFAULT_PICKING_LIST_REDUCER";
export const DELETE_PICKING_LIST_REDUCER = "DELETE_PICKING_LIST_REDUCER";

export const PICKING_LIST_NEXT_LOCATION_SAGA =
  "PICKING_LIST_NEXT_LOCATION_SAGA";
export const CALL_PICKING_LIST_NEXT_LOCATION_REDUCER =
  "CALL_PICKING_LIST_NEXT_LOCATION_REDUCER";
export const PICKING_LIST_DONE_LOCATION_SAGA =
  "PICKING_LIST_DONE_LOCATION_SAGA";
export const CALL_PICKING_LIST_DONE_LOCATION_REDUCER =
  "CALL_PICKING_LIST_DONE_LOCATION_REDUCER";

// tranferPickingList
export const GET_PICKING_LIST_TRANFER_SAGA = "GET_PICKING_LIST_TRANFER";
export const SET_PICKING_LIST_REDUCER = "SET_PICKING_LIST_REDUCER";
export const GET_PICKING_LIST_BY_ID_TRANFER_SAGA =
  "GET_PICKING_LIST_BY_ID_TRANFER";
export const SET_PICKING_LIST_BY_ID_REDUCER = "SET_PICKING_LIST_BY_ID_REDUCER";
export const GET_PICKING_HISTORY_BY_DETAIL_ID_SAGA =
  "GET_PICKING_HISTORY_BY_DETAIL_ID";
export const SET_PICKING_HISTORY_BY_DETAIL_ID_REDUCER =
  "SET_PICKING_HISTORY_BY_DETAIL_ID_REDUCER";
export const ADD_PACKAGE_IN_PICK_HIS_SAGA = "ADD_PACKAGE_IN_PICK_HIS_abc";
export const ADD_PACKAGE_IN_PICK_HIS_SAGA_V2 =
  "ADD_PACKAGE_IN_PICK_HIS_SAGA_V2";
export const CALL_ADD_PACKAGE_IN_PICK_HIS_SUCCESS =
  "CALL_ADD_PACKAGE_IN_PICK_HIS_SUCCESS";
export const REMOVE_PACKAGE_IN_HIS_SAGA = "REMOVE_PACKAGE_IN_HIS";
export const CALL_REMOVE_PACKAGE_IN_HIS = "CALL_REMOVE_PACKAGE_IN_HIS";
export const CHECK_PACKAGE_INFOR = "CHECK_PACKAGE_INFOR";
export const CALL_PICKING_LIST_BY_USER = "CALL_PICKING_LIST_BY_USER";
export const GET_PICKING_LIST_BY_USER = "GET_PICKING_LIST_BY_USER";
//---------------------------- taken package ------------------------------
export const TAKEN_PACKAGE_GET_DETAIL_BY_PACKAGE_CODE_SAGA = "TAKEN_PACKAGE_GET_DETAIL_BY_PACKAGE_CODE_SAGA";
export const TAKEN_PACKAGE_GET_DETAIL_BY_PACKAGE_CODE_REDUCER = "TAKEN_PACKAGE_GET_DETAIL_BY_PACKAGE_CODE_REDUCER";
export const TAKEN_PACKAGE_GET_DETAIL_BY_PACKAGE_CODE_DONE = "TAKEN_PACKAGE_GET_DETAIL_BY_PACKAGE_CODE_DONE";
export const TAKEN_PACKAGE_SAVED_DATA_HIS = "TAKEN_PACKAGE_SAVED_DATA_HIS";
export const TAKEN_PACKAGE_SCAN_PACKAGE_CODE_SAGA = "TAKEN_PACKAGE_SCAN_PACKAGE_CODE_SAGA";
export const TAKEN_PACKAGE_SCAN_PACKAGE_CODE_REDUCER = "TAKEN_PACKAGE_SCAN_PACKAGE_CODE_REDUCER";
export const TAKEN_PACKAGE_SCAN_PACKAGE_CODE_DONE = "TAKEN_PACKAGE_SCAN_PACKAGE_CODE_DONE";

//----------------------------------------------------------------------
export const GET_ALL_PICKING_LIST_CREATE_SAGA =
  "GET_ALL_PICKING_LIST_CREATE_SAGA";
export const RETURN_PICKING_LIST_CREATE_SAGA =
  "RETURN_PICKING_LIST_CREATE_SAGA";

// Lấy thông tin phiếu yêu cầu

export const GET_ALL_REQUESTFORM_SAGA = "GET_ALL_REQUESTFORM_SAGA";
export const DELETE_REQUESTFORM_SAGA = "DELETE_REQUESTFORM_SAGA";
export const CREATE_REQUESTFORM_SAGA = " CREATE_REQUESTFORM_SAGA";
export const GET_DETAIL_REQUESTFORM_SAGA = " GET_DETAIL_REQUESTFORM_SAGA";
export const UPDATE_REQUESTFORM_SAGA = " UPDATE_REQUESTFORM_SAGA";

export const RETURN_DATA_REQUESTFORM_REDUCER =
  "RETURN_DATA_REQUESTFORM_REDUCER";
export const CALL_RESULT_REQUESTFORM_REDUCER =
  "CALL_RESULT_REQUESTFORM_REDUCER";
export const DELETE_REQUESTFORM_REDUCER = " DELETE_REQUESTFORM_REDUCER";
export const CALL_DEFAULT_REQUESTFORM_REDUCER =
  " CALL_DEFAULT_REQUESTFORM_REDUCER";
export const RETURN_OBJECT_REQUESTFORM_REDUCER =
  " RETURN_OBJECT_REQUESTFORM_REDUCER";

// Báo cáo tồn kho
export const CALL_DEFAULT_INVENTORY_REPORT_REDUCER =
  " CALL_DEFAULT_INVENTORY_REPORT_REDUCER";
export const GET_INVENTORY_REPORT_SAGA = " GET_INVENTORY_REPORT_SAGA";
export const RETURN_DATA_INVENTORY_REPORT_REDUCER =
  " RETURN_DATA_INVENTORY_REPORT_REDUCER";
export const EXPORT_INVENTORY_REPORT_PERIOD = " EXPORT_INVENTORY_REPORT_PERIOD";

//InventoryByTime
export const EXPORT_INVENTORY_BY_TIME_TO_EXCEL =
  "EXPORT_INVENTORY_BY_TIME_TO_EXCEL";
export const GET_ALL_INVENTORY_BY_TIME_SAGA = "GET_ALL_INVENTORY_BY_TIME_SAGA";
export const GET_ALL_INVENTORY_BY_TIME_REDUCER =
  "GET_ALL_INVENTORY_BY_TIME_REDUCER";

// Kiểm kê

export const GET_ALL_INVENTORY_CHECK_SAGA = "GET_ALL_INVENTORY_CHECK_SAGA";
export const DELETED_INVENTORY_CHECK_SAGA = "DELETED_INVENTORY_CHECK_SAGA";
export const CREATE_INVENTORY_CHECK_SAGA = "CREATE_INVENTORY_CHECK_SAGA";
export const START_INVENTORY_CHECK_SAGA = "START_INVENTORY_CHECK_SAGA";
export const GET_DETAIL_INVENTORY_CHECK_SAGA =
  "GET_DETAIL_INVENTORY_CHECK_SAGA";
export const UPDATE_INVENTORY_CHECK_SAGA = "UPDATE_INVENTORY_CHECK_SAGA";
export const END_INVENTORY_CHECK_SAGA = "END_INVENTORY_CHECK_SAGA";

export const RETURN_DATA_INVENTORY_CHECK_REDUCER =
  "RETURN_DATA_INVENTORY_CHECK_REDUCER";
export const CALL_RESULT_INVENTORY_CHECK_REDUCER =
  "CALL_RESULT_INVENTORY_CHECK_REDUCER";
export const CALL_DEFAULT_INVENTORY_CHECK_REDUCER =
  "CALL_DEFAULT_INVENTORY_CHECK_REDUCER";
export const DELETE_INVENTORY_CHECK_REDUCER = "DELETE_INVENTORY_CHECK_REDUCER";
export const RETURN_OBJECT_INVENTORY_CHECK_REDUCER =
  "RETURN_OBJECT_INVENTORY_CHECK_REDUCER";

//Standardpacking
export const GET_ALL_STANDARDPAKING_SAGA = "GET_ALL_STANDARDPAKING_SAGA";
export const RETURN_DATA_STANDARDPAKING_REDUCER =
  "RETURN_DATA_STANDARDPAKING_REDUCER";
export const ADD_STANDARDPAKING_SAGA = "ADD_STANDARDPAKING_SAGA";
export const ADD_STANDARDPAKING_REDUCER = "ADD_STANDARDPAKING_REDUCER";
export const EDIT_STANDARDPAKING_SAGA = "EDIT_STANDARDPAKING_SAGA";
export const UPDATE_STANDARDPAKING_REDUCER = "UPDATE_STANDARDPAKING_REDUCER";
export const DELETE_STANDARDPAKING_SAGA = "DELETE_STANDARDPAKING_SAGA";
export const CLEAR_ALL_SP_REDUCER_STATE = "CLEAR_ALL_SP_REDUCER_STATE";
export const CALL_DEFAULT_SP_REDUCER = "CALL_DEFAULT_SP_REDUCER";
export const CLOSE_DELETE_POPUP_STANDARD_PACKING_SCREEN = "CLOSE_DELETE_POPUP_STANDARD_PACKING_SCREEN";

//Packagetype
export const GET_ALL_PACKAGETYPE = "GET_ALL_PACKAGETYPE";
export const RETURN_DATA_PACKAGETYPE = "RETURN_DATA_PACKAGETYPE";
export const CREATE_PACKAGETYPE = "CREATE_PACKAGETYPE";
export const UPDATE_PACKAGETYPE = "UPDATE_PACKAGETYPE";
export const DELETE_PACKAGETYPE = "DELETE_PACKAGETYPE";
export const GET_DETAIL_PACKAGETYPE = "GET_DETAIL_PACKAGETYPE";
export const RETURN_OBJECT_PACKAGETYPE = "RETURN_OBJECT_PACKAGETYPE";
export const CALL_RESULT_FROM_PACKAGETYPE_API =
  "CALL_RESULT_FROM_PACKAGETYPE_API";
export const CALL_DEFAULT_FROM_PACKAGETYPE = "CALL_DEFAULT_FROM_PACKAGETYPE";
//----------------------------- thực hiện kiểm kê------------------------------------
export const GET_DROPDOWN_BY_CHECK_INVENTORY_CODE =
  "GET_DROPDOWN_BY_CHECK_INVENTORY_CODE";
export const SET_DROPDOWN_BY_CHECK_INVENTORY_CODE =
  "SET_DROPDOWN_BY_CHECK_INVENTORY_CODE";
export const GET_LOCATION_BY_LOCATION_CHECKING =
  "GET_LOCATION_BY_LOCATION_CHECKING";
export const SET_LOCATION_BY_LOCATION_CHECKING =
  "SET_LOCATION_BY_LOCATION_CHECKING";
export const COMPLETE_INVENTORY_CHECK = "COMPLETE_INVENTORY_CHECK";
export const CALL_COMPLETE_INVENTORY_CHECK = "CALL_COMPLETE_INVENTORY_CHECK";
export const GET_INVENTORY_CHECK_BY_PACKAGE_CODE =
  "GET_INVENTORY_CHECK_BY_PACKAGE_CODE";
export const SET_INVENTORY_CHECK_BY_PACKAGE_CODE =
  "SET_INVENTORY_CHECK_BY_PACKAGE_CODE";
export const GET_INVENTORY_CHECK_BY_GOOD_CODE =
  "GET_INVENTORY_CHECK_BY_GOOD_CODE";
export const SET_INVENTORY_CHECK_BY_GOOD_CODE =
  "SET_INVENTORY_CHECK_BY_GOOD_CODE";
//----------------------------------------------------------------------------------------------
//----------------------------------- lấy thông tin dựa trên sloc code thành công --------------------------------------
export const INVENTORY_CHECK_GET_INFO_BY_SLOC_CODE_SAGA = "INVENTORY_CHECK_GET_INFO_BY_SLOC_CODE_SAGA";
export const INVENTORY_CHECK_GET_INFO_BY_SLOC_CODE_REDUCER = "INVENTORY_CHECK_GET_INFO_BY_SLOC_CODE_REDUCER";
export const INVENTORY_CHECK_GET_INFO_BY_SLOC_DONE = "INVENTORY_CHECK_GET_INFO_BY_SLOC_DONE"
//------------------------------------------------------------------------------------------------------------------------
//movementtype
export const GET_ALL_MOVEMENTTYPE_SAGA = "GET_ALL_MOVEMENTTYPE_SAGA";
export const RETURN_DATA_MOVEMENTTYPE_REDUCER =
  "RETURN_DATA_MOVEMENTTYPE_REDUCER";
export const ADD_MOVEMENTTYPE_SAGA = "ADD_MOVEMENTTYPE_SAGA";
export const EDIT_MOVEMENTTYPE_SAGA = "EDIT_MOVEMENTTYPE_SAGA";
export const DELETE_MOVEMENTTYPE_SAGA = "DELETE_MOVEMENTTYPE_SAGA";

// demo Hook
export const GET_PAGING_DEMO_HOOK_SAGA = "GET_PAGING_DEMO_HOOK_SAGA";
export const GET_PAGING_DEMO_HOOK_REDUCER = "GET_PAGING_DEMO_HOOK_REDUCER";
export const CREATE_DATA_SAGA = "CREATE_DATA_SAGA";
export const CALL_RESULT_DEMO_HOOK_REDUCER = "CALL_RESULT_DEMO_HOOK_REDUCER";
export const UPDATE_DATA_SAGA = "UPDATE_DATA_SAGA";
export const DELETE_DATA_SAGA = "DELETE_DATA_SAGA";
export const DELETE_DATA_REDUCER = "DELETE_DATA_REDUCER";
export const GET_DETAIL_DATA_SAGA = "GET_DETAIL_DATA_SAGA";
export const GET_DETAIL_DATA_REDUCER = "GET_DETAIL_DATA_REDUCER";
export const CALL_DEFAULT_DEMO_HOOK_REDUCER = "CALL_DEFAULT_DEMO_HOOK_REDUCER";

// PRODUCT REQUIREMENTS
export const GET_PAGING_PRODUCTION_REQUIRMENT_SAGA =
  "GET_PAGING_PRODUCTION_REQUIRMENT_SAGA";
export const GET_PAGING_PRODUCTION_REQUIRMENT_REDUCER =
  "GET_PAGING_PRODUCTION_REQUIRMENT_REDUCER";
export const CREATE_PRODUCTION_REQUIRMENT_SAGA =
  "CREATE_PRODUCTION_REQUIRMENT_SAGA";
export const CALL_RESULT_PRODUCTION_REQUIRMENT_REDUCER =
  "CALL_RESULT_PRODUCTION_REQUIRMENT_REDUCER";
export const UPDATE_PRODUCTION_REQUIRMENT_SAGA =
  "UPDATE_PRODUCTION_REQUIRMENT_SAGA";
export const DELETE_PRODUCTION_REQUIRMENT_SAGA =
  "DELETE_PRODUCTION_REQUIRMENT_SAGA";
export const DELETE_PRODUCTION_REQUIRMENT_REDUCER =
  "DELETE_PRODUCTION_REQUIRMENT_REDUCER";
export const GET_DETAIL_PRODUCTION_REQUIRMENT_SAGA =
  "GET_DETAIL_PRODUCTION_REQUIRMENT_SAGA";
export const GET_DETAIL_PRODUCTION_REQUIRMENT_REDUCER =
  "GET_DETAIL_PRODUCTION_REQUIRMENT_REDUCER";
export const CALL_DEFAULT_PRODUCTION_REQUIRMENT_REDUCER =
  "CALL_DEFAULT_PRODUCTION_REQUIRMENT_REDUCER";

export const GET_NEW_CODE_PRODUCT =
  "GET_NEW_CODE_PRODUCT";


export const SET_DEFAULT = "SET_DEFAULT";

// NOTIFICATION 
export const GET_ALL_NOTIFiCATION_SAGA =
  "GET_ALL_NOTIFiCATION_SAGA"
export const GET_ALL_NOTIFiCATION_REDUCER =
  "GET_ALL_NOTIFiCATION_REDUCER"
export const ADD_RECORD =
  "ADD_RECORD"
export const UPDATE_NOTIFICATION =
  "UPDATE_NOTIFICATION"
export const UPDATE_NOTIFICATION_REDUCER =
  "UPDATE_NOTIFICATION_REDUCER"

