import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_PAGE_ASSETMENT_API,
    EXPORT_EXCEL_ASSETMENT_LIST_API,
    GET_ASSET_BY_ID_API,
    UPDATE_ASSET_API,
    UPDATE_MANY_ASSET_API,
    GET_LIST_ASSET_HISTORY_API,
    GET_ALL_ASSET_STAMP_HISTORY_API,
    REQUEST_PRINT_STAMP_API

} from "./enum/TypeAssetmentListAPI";

export async function GetPagingService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_PAGE_ASSETMENT_API,
        data
    );
}
export async function exportExcelAssetmentListService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + EXPORT_EXCEL_ASSETMENT_LIST_API,
        data
    );
}

export async function getAssetByIdService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_ASSET_BY_ID_API + data
    );
}
export async function UpdateAssetService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + UPDATE_ASSET_API, data
    );
}
export async function UpdateManyAssetService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + UPDATE_MANY_ASSET_API, data
    );
}

export async function GetListAssetHistoryService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_LIST_ASSET_HISTORY_API, data
    );
}

export async function GetAllAssetStampHistoryService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_ALL_ASSET_STAMP_HISTORY_API + data
    );
}

export async function RequestPrintStamp(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + REQUEST_PRINT_STAMP_API, data
    )
}
