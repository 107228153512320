export const GET_PAGING_DELIVERY_SAGA = "GET_PAGING_DELIVERY_SAGA";

export const GET_PAGING_DELIVERY_REDUCER =
  "GET_PAGING_DELIVERY_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";


export const DELETE_DELIVERY_SAGA = "DELETE_DELIVERY_SAGA";
export const DELETE_DELIVERY_REDUCER = "DELETE_DELIVERY_REDUCER";


export const CREATE_DELIVERY_REDUCER = "CREATE_DELIVERY_REDUCER"
export const CREATE_DELIVERY_SAGA = "CREATE_DELIVERY_SAGA"
export const UPDATE_DELIVERY_REDUCER = "UPDATE_DELIVERY_REDUCER"
export const UPDATE_DELIVERY_SAGA = "UPDATE_DELIVERY_SAGA"

export const GET_INFO_DELIVERY_BY_ID_REDUCER = 'GET_INFO_DELIVERY_BY_ID_REDUCER'
export const RESET_GET_INFO_DELIVERY_BY_ID_REDUCER = 'RESET_GET_INFO_DELIVERY_BY_ID_REDUCER'
export const GET_INFO_DELIVERY_BY_ID_SAGA = 'GET_INFO_DELIVERY_BY_ID_SAGA'

export const GET_PAGING_RETAIL_DELIVERY_SAGA = "GET_PAGING_RETAIL_DELIVERY_SAGA";

export const GET_PAGING_RETAIL_DELIVERY_REDUCER =
  "GET_PAGING_RETAIL_DELIVERY_REDUCER";

export const SET_TRANSACTION_DETAIL_LIST_REDUCER = "SET_TRANSACTION_DETAIL_LIST_REDUCER";
export const DOWNLOAD_EXCEL_SAGA = "DOWNLOAD_EXCEL_SAGA";

export const DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA = "DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA";

export const DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER =
  "DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER";