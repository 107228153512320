import { isEmpty } from "lodash";
import { call, put, takeEvery } from "redux-saga/effects"
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import { GET_LIST_USER, SAVE_USER } from "./enum/UserManagementAction";
import UserSyncAction from "./UserManagementAction";
import { getListAllUserService, saveUserService } from "./UserManagementService"
import toastJs from "src/shared/common/toastJs";

const getListUserParams = {
    KEY: "",
    PAGE_NUMBER: 1,
    PAGE_SIZE: 2000,
    TOTAL_RECORD: 0,
    STATUS: 0,
    listData: []
  }
function* getListAllUserSaga(data) {
    const result = yield call(getListAllUserService, getListUserParams);
    if(!isEmpty(result)) {
        yield put(UserSyncAction.setListAllUser(result))
    }
}

function* saveUserSaga(data) {
    try {
        const result = yield call(saveUserService,data?.data);
        if(result?.Success) {
            openLoading();
            const userData = yield call(getListAllUserService, getListUserParams);
            if(!isEmpty(result)) {
                closeLoading();
                if(data.action == 1) {
                    toastJs.success("Tạo mới người dùng thành công");
                } 
                else if(data.action == 2) {
                    toastJs.success("Cập nhập thông tin người dùng thành công");
                }
                yield put(UserSyncAction.setListAllUser(userData))
            }
        }
    }
    catch(err) {
        console.warn(err);
        toastJs.error("Cập nhập thông tin người dùng không thành công")
    }
}

export default function* UserSyncSaga(data) {
    yield takeEvery(GET_LIST_USER, getListAllUserSaga);
    yield takeEvery(SAVE_USER, saveUserSaga)
}
