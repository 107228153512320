// code approval action tyoe 
export const GET_PAGING_APPROVAL_SAGA = "GET_PAGING_APPROVAL_SAGA";
export const GET_PAGING_APPROVAL_REDUCER = "GET_PAGING_APPROVAL_REDUCER";

export const GET_APPROVAL_DETAIL_SAGA = "GET_APPROVAL_DETAIL_SAGA";
export const GET_APPROVAL_DETAIL_REDUCER = "GET_APPROVAL_DETAIL_REDUCER";

export const APPROVE_REJECT_SAGA = "APPROVE_REJECT_SAGA";
export const APPROVE_REJECT_REDUCER = "APPROVE_REJECT_REDUCER";
export const APPROVE_REJECT_DONE = "APPROVE_REJECT_DONE";
export const GET_WORKER_FROM_OF_WORKAREA_SAGA = "GET_WORKER_FROM_OF_WORKAREA_SAGA";
export const GET_WORKER_FROM_OF_WORKAREA_REDUCER = "GET_WORKER_FROM_OF_WORKAREA_REDUCER";
export const GET_WORKER_FROM_OF_WORKAREA_DONE = "GET_WORKER_FROM_OF_WORKAREA_DONE";
export const SET_STEP_BY_ACTION_APPROVE_REDUCER = "SET_STEP_BY_ACTION_APPROVE_REDUCER";
export const SET_STEP_BY_ACTION_APPROVE_DONE = "SET_STEP_BY_ACTION_APPROVE_DONE";


