export const formTypeEnum = {
    Update: 1,
    Create: 2,
    Detail: 3,
};

export const formTitle = {
  Update: "Chỉnh sửa kế hoạch kiểm kê",
  Create: "Tạo mới kế hoạch kiểm kê",
  View: "Xem chi tiết kế hoạch kiểm kê",
}

//Enum loại phiếu xuất sử dụng
export const EXPORT_USAGE_TYPE = {
    PATIENTS_EXPORT: 2,
    GENERAL_EXPORT: 3
}
export const ADJUSTMENT_TYPE = {
    IncreaseInventory_AndSyncSAP: 9,
    DecreaseInventory_AndSyncSAP: 10,
    IncreaseInventory: 11,
    DecreaseInventory: 12,
}

//Enum nhóm vtyt
export const PRODUCT_GROUP_TYPE = {
    NORMAL_GROUP: 1,
    CONSIGNMENT_GROUP: 2,
}

//Enum trạng thái của phiếu xuất
// export const EXPORT_USAGE_STATUS = {
//   TEMPORATY_SAVE: 1, ==> GIỮ NGUYÊN
//   PROCESSING: 2, //Chuyển thành HAS_BEEN_SHIPPED
//   SUCCESSED: 3, xóa bỏ
//   CANCELED:4 chuyển thành 3
// }
export const EXPORT_USAGE_STATUS = {
    TEMPORATY_SAVE: 1,
    HAS_BEEN_SHIPPED: 2, //Chuyển thành 
    CANCELED: 3
}

export const POPUP_TYPE = {

    SCAN_BARCODE: 1,
    CHOOSE_PRODUCT: 2,
}

export const LIST_ADJUSTMENT_REASON_ENUM = [
    {
        value:1,
        label:"Kiểm kê sai"

    },
    {
        value:2,
        label:"Kiểm kê thiếu"

    },{
        value:3,
        label:"Bổ sung"

    }
]