import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_ALL_RESCHEDULE_PRODUCT_API, CREATE_RESCHEDULE_PRODUCT_API, GET_DROPDOWN_ALL_API, GET_DROPDOWN_PROCESS_REWORK_API
} from "./enum/typeRescheduleProductionApi";

export async function getPagingService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_ALL_RESCHEDULE_PRODUCT_API, data);
}

export async function createDataService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + CREATE_RESCHEDULE_PRODUCT_API, data);
}
export async function getDropdownService() {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROPDOWN_ALL_API);
}
export async function getDropdownProcessService(productcode) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DROPDOWN_PROCESS_REWORK_API + productcode);
}
