import {
  GET_PAGING_APPROVE_STEP_REDUCER,
  CALL_CREATE_OR_UPDATE,
  CALL_DELETE_APPROVE_SAGA,
  SET_DETAIL_APPROVE_DEFAULT,
  CREATE_APPROVAL_REDUCER,
  UPDATE_ID_ROW
} from "./enum/ApproveStepTypeAction";
const ApproveStepReducer = (
  state = {
    isFetching: false,
    isFetchingMore: false,
    listData: [],
    callListData: false,
    callWorkerDropdown: false,
    callCreateOrUpdate: false,
    callDelete: false,
    detailApprove: {},
    callDetailApprove: false,
    callCreateApprovalSuccess: false,
    idRow: "",
  },
  action
) => {
  switch (action.type) {
    case GET_PAGING_APPROVE_STEP_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.callListData = true;
      }
      return {
        ...state,
      };
    case CALL_CREATE_OR_UPDATE:
      state.callCreateOrUpdate = true;
      return {
        ...state,
      };
    case CALL_DELETE_APPROVE_SAGA:
      state.callDelete = true;
      return {
        ...state,
      };
    case SET_DETAIL_APPROVE_DEFAULT:
      state.callDetailApprove = true;
      state.detailApprove = action.data;
      return { ...state }
    case CREATE_APPROVAL_REDUCER:
      state.callCreateApprovalSuccess = true;

      return { ...state }
    case 'CLEAR_DATA_REDUCER':
      state.isFetching = false;
      state.isFetchingMore = false;
      state.listData = [];
      state.callListData = false;
      state.callWorkerDropdown = false;
      state.callCreateOrUpdate = false;
      state.callDelete = false;
      state.callDetailApprove = false;
      state.detailApprove = {};
      state.callCreateApprovalSuccess = false;

      return {
        ...state,
      };
    case UPDATE_ID_ROW:
      state.idRow = action.id;

      return {
        ...state,
      }
    case 'default':
      state.callListData = false;
      state.callWorkerDropdown = false;
      state.callDetailApprove = false;
      state.callCreateOrUpdate = false;
      state.callDelete = false;
      state.callCreateApprovalSuccess = false;

      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default ApproveStepReducer;
