export const GET_PAGING_ASSETMENT_SAGA = "GET_PAGING_ASSETMENT_SAGA";
export const GET_PAGING_ASSETMENT_REDUCER = "GET_PAGING_ASSETMENT_REDUCER";

export const CLEAR_DATA_ASSETMENT_LIST_REDUCER = "CLEAR_DATA_ASSETMENT_LIST_REDUCER";
export const CALL_DEFAULT_ASSETMENT_LIST_REDUCER = "CALL_DEFAULT_ASSETMENT_LIST_REDUCER";

export const EXPORT_EXCEL_ASSETMENT_LIST_SAGA = "EXPORT_EXCEL_ASSETMENT_LIST_SAGA";

export const GET_ASSET_BY_ID_SAGA = "GET_ASSET_BY_ID_SAGA";
export const GET_ASSET_BY_ID_REDUCER = "GET_ASSET_BY_ID_REDUCER";
export const SET_IS_OPEN_PRINT_STAMP_MODAL = "SET_IS_OPEN_PRINT_STAMP_MODAL";

export const UPDATE_ASSET_SAGA = "UPDATE_ASSET_SAGA";
export const UPDATE_ASSET_REDUCER = "UPDATE_ASSET_REDUCER";
export const UPDATE_ASSET_OK_SAP_FAILED_REDUCER = "UPDATE_ASSET_OK_SAP_FAILED_REDUCER";

export const UPDATE_MANY_ASSET_SAGA = "UPDATE_MANY_ASSET_SAGA";
export const UPDATE_MANY_ASSET_REDUCER = "UPDATE_MANY_ASSET_REDUCER";

export const GET_LIST_ASSET_HISTORY_SAGA = "GET_LIST_ASSET_HISTORY_SAGA";
export const GET_LIST_ASSET_HISTORY_REDUCER = "GET_LIST_ASSET_HISTORY_REDUCER";

export const GET_ALL_ASSET_STAMP_HISTORY_SAGA = "GET_ALL_ASSET_STAMP_HISTORY_SAGA";
export const GET_ALL_ASSET_STAMP_HISTORY_REDUCER = "GET_ALL_ASSET_STAMP_HISTORY_REDUCER";

export const CLOSE_PAGE_UPDATE_MANY_ASSET_REDUCER = "CLOSE_PAGE_UPDATE_MANY_ASSET_REDUCER";
export const REQUEST_PRINT_STAMP = "REQUEST_PRINT_STAMP";
export const GET_PRINT_STAMP_DATA = "GET_PRINT_STAMP_DATA";
export const SET_LOADED_STAMP_PRINT = "SET_LOADED_STAMP_PRINT";

