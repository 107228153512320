import {
    getByUserIdService
} from "../../redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseService";

/**
 * Class tĩnh để lưu thông tin bệnh viện và kho được phân quyền
 * Nếu lưu trong store hay bị lỗi null khi unmount và mount component
 * Lưu thông tin toàn cục thì cách này sẽ đảm bảo nhất và dễ lấy tại các component
 * =>> tránh xử lý phức tạp
 * */
export class InformationUser {
    static infoUser = {};

    static getInfoUserByKey(key) {
        return this.infoUser[key]
    }

    static setInfoUser(value) {
        return this.infoUser = value
    }

    static async fetch(userId) {
        try {
            const response = await getByUserIdService(userId)
            if (response.isSuccess && response.data) {
                this.infoUser = response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
}