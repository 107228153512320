import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_PAGING_PERMISSION_DATA_SAGA,
    DELETE_PERMISSION_DATA_SAGA,
    CREATE_PERMISSION_DATA_SAGA,
    UPDATE_PERMISSION_DATA_SAGA,
    GET_DETAIL_PERMISSION_DATA_SAGA
} from "./enum/TypePermissionDataAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import PermissionDataAction from "./PermissionDataAction";
import {
    getPagingService,
    deletePermissionDataService,
    createPermissionDataService,
    updatePermissionDataService,
    getDetailPermissionDataService
} from "./PermissionDataService";


export function* getPagingSaga(data) {
    try {
        const request = {
            PageIndex: data?.request?.pageIndex,
            PageSize: data?.request?.pageSize,
            SearchTerm: data?.request?.searchTerm ? data?.request?.searchTerm?.trim() : "",
            ListType: data?.request?.listTypeId?.length > 0 ? data?.request?.listTypeId : [],
            ListSegment: data?.request?.listSegmentId?.length > 0 ? data?.request?.listSegmentId : [],
            ListFullName: data?.request?.listUserId?.length > 0 ? data?.request?.listUserId : [],
        };
        const response = yield call(getPagingService, request);
        if (response) {
            if (response.Items.length <= 0 && request.PageIndex > 1) {
                yield put(PermissionDataAction.getPagingSaga(request.PageIndex - 1, request.PageSize, request.SearchTerm));
            } else {
                yield put(PermissionDataAction.getPagingReducer(response));
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* deletePermissionDataSaga(request) {
    try {
        if (request.id > 0) {
            const response = yield call(deletePermissionDataService, request.id);
            if (response.isSuccess) {
                yield put(PermissionDataAction.deleteDataReducer());
            }
            closeLoading();
        }

    } catch (error) {
        closeLoading();
    }
}

export function* createPermissionDataSaga(requet) {
    try {
        if (requet) {
            const requestAPI = {
                Id: requet?.data?.Id,
                ListType: requet?.data?.Type,
                Segment: requet?.data?.Segment,
                IsAdmin: requet?.data?.IsAdmin,
                UserId: requet?.data?.UserId,
                CostCenterId: requet?.data?.CostCenterId,
                EvaluationGroup5Id: requet?.data?.EvaluationGroup5Id,
            }
            const response = yield call(createPermissionDataService, requestAPI);
            if (response.isSuccess) {
                yield put(PermissionDataAction.CallCreateUpdateSuccessReducer(response.data));
            }
            closeLoading();
        }
    } catch (error) {
        closeLoading();
    }
}
export function* updatePermissionDataSaga(requet) {
    try {
        if (requet?.data) {
            const requestAPI = {
                Id: requet?.data?.Id,
                Type: requet?.data?.Type,
                Segment: requet?.data?.Segment,
                IsAdmin: requet?.data?.IsAdmin,
                UserId: [requet?.data?.UserId],
                CostCenterId: requet?.data?.CostCenterId,
                EvaluationGroup5Id: requet?.data?.EvaluationGroup5Id,
            }
            const response = yield call(updatePermissionDataService, requestAPI);
            if (response.isSuccess) {
                yield put(PermissionDataAction.CallCreateUpdateSuccessReducer(response.data));
            }
            closeLoading();
        }
    } catch (error) {
        closeLoading();
    }
}
export function* getDetailPermissionDataSaga(requet) {
    try {
        if (requet?.id > 0) {
            const response = yield call(getDetailPermissionDataService, requet?.id);
            if (response.isSuccess) {
                yield put(PermissionDataAction.getDetailDataReducer(response.data));
            }
            closeLoading();
        }
    } catch (error) {
        closeLoading();
    }
}
export default function* PermissionDataSaga(data) {
    yield takeEvery(GET_PAGING_PERMISSION_DATA_SAGA, getPagingSaga);
    yield takeEvery(DELETE_PERMISSION_DATA_SAGA, deletePermissionDataSaga);
    yield takeEvery(CREATE_PERMISSION_DATA_SAGA, createPermissionDataSaga);
    yield takeEvery(UPDATE_PERMISSION_DATA_SAGA, updatePermissionDataSaga);
    yield takeEvery(GET_DETAIL_PERMISSION_DATA_SAGA, getDetailPermissionDataSaga);
}
