export const worker = {
  language: "en",
  worker: {
    create_title: "Create Worker",
    update_title: "Update worker infomation",
    create_button: "Save",
    update_button: "Update",
    Code: " Code",
    Name: "Name",
    Surname: "Surname",
    PositionName: "Position",
    Phone: "Phone number",
    Email: "Email",
    Isquitwork: "Has retire",
    Dateofbirth: "Date of birth",
    Gender: "Gender",
    Address: "Address",
    HomeTown: "HomeTown",
    Maritalstatus: "Marital status",
    provincescode: "Provinces",
    districtscode: "Districts",
    Addressdetails: "Address detail",
    Usercontactrelationship: "Relationship",
    Quitworkdate: "retire date",
    EmergencyContactPerson: "Emergency contact person",
    UserContactName: "Name",
    workAreaSelectTitle: "Work area",
    workAreaSelectPlaceHolder: "Chọn work area",
    table: {
      code: "Staff code",
      name: "First and last name",
      Tel: "Tel",
      Email: "Email",
      location: "Current position",
      isQuit: "Stopped working",
      dropdown: {
        edit: "Edit information",
        Degrees: "Degrees, skills",
        password: "Change Password",
        account: "Create login"
      }
    },
    form: {
      view: "back to the list of workers",
      edit: "Create new employee information",
      add: "Update employee information",
      information: "Basic information",
      address: "Address",
      homeTown: "hometown",
      contact: "Emergency contact person",
      edit_image: "Edit",
      male: "Male",
      female: "Female",
      informationForm: "Employee information"
    },
    placeHolder: {
      search: "Search by employee code, name, phone number, or email",
      Surname: "Enter last name",
      Name: "Enter Name",
      Code: "Enter code",
      Dateofbirth: "Select a date of birth",
      Maritalstatus: "Select marital status",
      Phone: "Enter phone number",
      Email: "Enter email",
      Positionid: "Select location",
      Workareaid: "Select area",
      Addressprovincescode: "Select province or city",
      Addressdistrictscode: "Select county or district",
      Addressdetails: "Enter detailed address",
      Usercontactname: "Enter contact name",
      Usercontactrelationship: "Select relationship",
      Usercontactphone: "Enter contact phone number",
      Usercontactaddress: "Enter contact address",
      Quitworkdate: "Select a day off work",
    },
    dropdown: {
      relation: {
        f1: "Mr",
        f2: "Grandma",
        f3: "Dad",
        f4: "Mother",
        f5: "Uncle",
        f6: "auntie",
        f7: "You, Me",
        f8: "Sister, Em",
        f9: "Child",
        f10: "nephew",
        f11: "Wife",
        f12: "Husband",
      },
      maried: {
        f1: "Not married",
        f2: "Married",
      }
    },
    validation: {
      f1: "They cannot be left blank",
      f2: "The last name can't be more than 20 characters",
      f3: "Name cannot be left blank",
      f4: "Name cannot exceed 50 characters",
      f5: "Code cannot be blank",
      f6: "Code cannot exceed 20 characters",
      f7: "Date of birth cannot be left blank",
      f8: "Retirement day cannot be left blank",
      f9: "Gender cannot be left blank",
      f10: "Invalid phone number",
      f11: "Phone number cannot be blank",
      f12: "Phone number cannot exceed 50 characters",
      f13: "Email cannot be blank",
      f14: "Email no more than 50 characters",
      f15: "Address of Province/City cannot be left blank",
      f16: "District address cannot be left blank",
      f17: "WorkArea cannot be left blank",
      f18: "The detailed address cannot be more than 200 characters",
      f21: "Name cannot be left blank",
      f22: "Name cannot exceed 100 characters",
      f23: "Relation cannot be left blank",
      f24: "Address cannot be empty",
      f25: "Address cannot exceed 100 characters",
      f26: "Phone number cannot be blank",
      f27: "Phone number cannot exceed 50 characters",
      f28: "Invalid employee ID",
      f29: "Invalid email format",
      f30: "Invalid date of birth",
      f31: "Invalid leave date",
      f32: "error downloading malformed file",
      f33: "Employee phone number must not match emergency contact phone number"
    },
    delete: {
      title: "Deletion of employees",
      confirmText: "Are you sure you want to delete this worker?",
    },
    err: {
      codeexist: "Employee code already exists",
      err: "Error",
      employeeCode: "Employee ID does not exist",
      accessAccount: "Access account already exists",
      accessAccountNo: "Access account does not exist",
      creatAccount: "Create account access error",
      changePassword: "Password change failed",
      birthDayMax: "The date of birth cannot be greater than the current date",
      birthDay: "Invalid severance date",
      phone: "The employee's phone number must not be the same as the emergency contact's phone number",
      characters: "does not contain special characters",
      nameCharacters: "Contact name does not contain special symbols",
      uploadFile: "Error downloading file in the wrong format"
    },
    success: {
      creatAccount: "Successful account creation",
      changePassword: "Change password successfully",
      createWorker: "Create new successful workers",
      updateWorker: "Update employee information successfully",
      deleteWorker: "Delete the worker successfully",
    }
  },
};
