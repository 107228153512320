export const GET_ALL_MASTER_DATA_BY_GROUP_CODE_SAGA = "GET_ALL_MASTER_DATA_BY_GROUP_CODE_SAGA";
export const GET_ALL_MASTER_DATA_BY_GROUP_CODE_REDUCER = "GET_ALL_MASTER_DATA_BY_GROUP_CODE_REDUCER";

export const GET_APPROVAL_FLOW_TEMPLATE_SAGA = "GET_APPROVAL_FLOW_TEMPLATE_SAGA";
export const GET_APPROVAL_FLOW_TEMPLATE_REDUCER = "GET_APPROVAL_FLOW_TEMPLATE_REDUCER";

export const CALL_DEFAULT_COMMON_DROPDOWN_VINMEC_REDUCER = "CALL_DEFAULT_COMMON_DROPDOWN_VINMEC_REDUCER";
export const CLEAR_DATA_DROPDOWN_VINMEC_REDUCER = "CLEAR_DATA_DROPDOWN_VINMEC_REDUCER";


export const GET_LIST_USER_SAGA = "GET_LIST_USER_SAGA";
export const GET_LIST_USER_REDUCER = "GET_LIST_USER_REDUCER";

// export const GET_LIST_ROLE_SAGA = "GET_LIST_ROLE_SAGA";
// export const GET_LIST_ROLE_REDUCER = "GET_LIST_ROLE_REDUCER";

export const GET_APPROVAL_FLOW_TEMPLATE_BY_ID_SAGA = "GET_APPROVAL_FLOW_TEMPLATE_BY_ID_SAGA";
export const GET_APPROVAL_FLOW_TEMPLATE_BY_ID_REDUCER = "GET_APPROVAL_FLOW_TEMPLATE_BY_ID_REDUCER";

//list dropdown asset code information
export const GET_LIST_FOCAL_DEPARTMENT_G5_SAGA = "GET_LIST_FOCAL_DEPARTMENT_G5_SAGA";
export const GET_LIST_FOCAL_DEPARTMENT_G5_REDUCER = "GET_LIST_FOCAL_DEPARTMENT_G5_REDUCER";

export const GET_LIST_BP_THE_LEDGER_SAGA = "GET_LIST_BP_THE_LEDGER_SAGA";
export const GET_LIST_BP_THE_LEDGER_REDUCER = "GET_LIST_BP_THE_LEDGER_REDUCE";

export const GET_LIST_ASSET_CLASS_SAGA = "GET_LIST_ASSET_CLASS_SAGA";
export const GET_LIST_ASSET_CLASS_REDUCER = "GET_LIST_ASSET_CLASS_REDUCER";

export const GET_LIST_SEGMENT_FUND_CENTER_SAGA = "GET_LIST_SEGMENT_FUND_CENTER_SAGA";
export const GET_LIST_SEGMENT_FUND_CENTER_REDUCER = "GET_LIST_SEGMENT_FUND_CENTER__REDUCER";

export const GET_LIST_UNIT_SAGA = "GET_LIST_UNIT_SAGA";
export const GET_LIST_UNIT_REDUCER = "GET_LIST_UNIT_REDUCER";

export const GET_LIST_STANDARD_ASSET_NAME_SAGA = "GET_LIST_STANDARD_ASSET_NAME_SAGA";
export const GET_LIST_STANDARD_ASSET_NAME_REDUCER = "GET_LIST_STANDARD_ASSET_NAME_REDUCER";

export const GET_LIST_BUDGET_CI_SAGA = "GET_LIST_BUDGET_CI_SAGA";
export const GET_LIST_BUDGET_CI_REDUCER = "GET_LIST_BUDGET_CI_REDUCER";

export const GET_LIST_EVALUATION_GROUP_1_SAGA = "GET_LIST_EVALUATION_GROUP_1_SAGA";
export const GET_LIST_EVALUATION_GROUP_1_REDUCER = "GET_LIST_EVALUATION_GROUP_1_REDUCER";

export const GET_LIST_EVALUATION_GROUP_2_SAGA = "GET_LIST_EVALUATION_GROUP_2_SAGA";
export const GET_LIST_EVALUATION_GROUP_2_REDUCER = "GET_LIST_EVALUATION_GROUP_2_REDUCER";

export const GET_LIST_EVALUATION_GROUP_3_SAGA = "GET_LIST_EVALUATION_GROUP_3_SAGA";
export const GET_LIST_EVALUATION_GROUP_3_REDUCER = "GET_LIST_EVALUATION_GROUP_3_REDUCER";

export const GET_LIST_EVALUATION_GROUP_4_SAGA = "GET_LIST_EVALUATION_GROUP_4_SAGA";
export const GET_LIST_EVALUATION_GROUP_4_REDUCER = "GET_LIST_EVALUATION_GROUP_4_REDUCER";

export const GET_LIST_EVALUATION_GROUP_5_SAGA = "GET_LIST_EVALUATION_GROUP_5_SAGA";
export const GET_LIST_EVALUATION_GROUP_5_REDUCER = "GET_LIST_EVALUATION_GROUP_5_REDUCER";

export const GET_ALL_FUND_CENTER_SAGA = "GET_ALL_FUND_CENTER_SAGA";
export const GET_ALL_FUND_CENTER_REDUCER = "GET_ALL_FUND_CENTER_REDUCER";

export const GET_GROUP_BPC_SAGA = "GET_GROUP_BPC_SAGA";
export const GET_GROUP_BPC_REDUCER = "GET_GROUP_BPC_REDUCER";

export const GET_LIST_VENDOR_SAGA = "GET_LIST_VENDOR_SAGA";
export const GET_LIST_VENDOR_REDUCER = "GET_LIST_VENDOR_REDUCER";

export const GET_LIST_ASSET_NUMBER_SUPPER_SAGA = "GET_LIST_ASSET_NUMBER_SUPPER_SAGA";
export const GET_LIST_ASSET_NUMBER_SUPPER_REDUCER = "GET_LIST_ASSET_NUMBER_SUPPER_REDUCER";

export const GET_LIST_ALL_SEGMENT_SAGA = "GET_LIST_ALL_SEGMENT_SAGA";
export const GET_LIST_ALL_SEGMENT_REDUCER = "GET_LIST_ALL_SEGMENT_REDUCER";

export const GET_LIST_COST_CENTER_BY_SEGMENT_SAGA = "GET_LIST_COST_CENTER_BY_SEGMENT_SAGA";
export const GET_LIST_COST_CENTER_BY_SEGMENT_REDUCER = "GET_LIST_COST_CENTER_BY_SEGMENT_REDUCER";

export const GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_SAGA = "GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_SAGA";
export const GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_REDUCER = "GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_REDUCER";

export const GET_LIST_INVENTORY_CODE_SAGA = "GET_LIST_INVENTORY_CODE_SAGA";
export const GET_LIST_INVENTORY_CODE_REDUCER = "GET_LIST_INVENTORY_CODE_SAGA_REDUCER";

export const GET_USER_SEGMENT_SAGA = "GET_USER_SEGMENT_SAGA";
export const GET_USER_SEGMENT_REDUCER = "GET_USER_SEGMENT_REDUCER";

export const GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_SAGA = "GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_SAGA";
export const GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_REDUCER = "GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_REDUCER";


export const GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_SAGA = "GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_SAGA";
export const GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_REDUCER = "GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_REDUCER";

export const GET_ALL_ASSET_SOURCE_SAGA = "GET_ALL_ASSET_SOURCE_SAGA";
export const GET_ALL_ASSET_SOURCE_REDUCER = "GET_ALL_ASSET_SOURCE_REDUCER";


export const GET_ALL_SEGMENT_INVENTORY_PLAN_SAGA = "GET_ALL_SEGMENT_INVENTORY_PLAN_SAGA";
export const GET_ALL_SEGMENT_INVENTORY_PLAN_REDUCER = "GET_ALL_SEGMENT_INVENTORY_PLAN_REDUCER";