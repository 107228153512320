import { call, put, take, takeEvery } from "redux-saga/effects";
import {
    CREATE_PLANNING_SAGA,
    DELETE_PLANNING_SAGA,
    GET_INFO_PLANNING_BY_ID_SAGA,
    GET_INFO_PLANNING_PAGING_BY_ID_SAGA,
    GET_PAGING_DETAIL_BY_ID_SAGA,
    GET_PAGING_PLANNING_SAGA,
    GET_PAGING_RETAIL_PLANNING_SAGA,
    PREDICTABILITY_BUDGET_AUTO_SAGA,
    PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_SAGA,
    PREDICTABILITY_RETAIL_AUTO_SAGA,
    PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA,
    UPDATE_A_DETAIL_PLANNING_SAGA,
    UPDATE_PLANNING_SAGA,
    TYPE_EXPORT,
    DELETE_RANGE_PLANNING_DETAIL_SAGA,
    PROPOSAL_SUMMARY_SAGA,
    PLANNING_DOWNLOAD_EXCEL_SAGA
} from "./enum/TypePlanningAction";
import { listDataFake } from "./enum/fakedata"
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import PlanningAction from "./PlanningAction";
import {
    getListPlanningService,deletePlanningService, createPlanningService, updatePlanningService, getInfoPlanningByIdService, PredictabilityRetailAutoService, PredictabilityRetailByListProductService, PredictabilityBudgetAutoService, PredictabilityBudgetByListProductService,
    getPagingDetailByIdService,
    downloadExcelService,
    updateRangeDetailService,
    getInfoPlanningPagingService,
    deleteRangeDetailService,
    proposalSummaryService
} from "./PlanningService";
import ConverDateToString from "src/shared/common/convertDateTime";
import toastJs from "src/shared/common/toastJs";
import { downloadFile, getFileNameWithDate } from "src/shared/utils/file";
export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const listhospitalId = data.listhospitalId;
    const warehouseId = data.warehouseId ? data.warehouseId : "";
    const monthPlanning = ConverDateToString(data.monthPlanning);
    const listStatus = data.listStatus;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    const isWhole = data.isWhole ? data.isWhole : false;
    const listDataUser = data.listDataUser? data.listDataUser: "";

    const StartDateExport = ConverDateToString(data.startDateExport);
    const EndDateExport = ConverDateToString(data.endDateExport); //Khoảng thời gian xuất phiếu
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      SearchTerm: searchTerm,
      IsWhole: isWhole,
      ListHospitalId: listhospitalId,
      ListWarehouseId: warehouseId,
      MonthPlanning: monthPlanning,
      ListStatus: listStatus,
      ListDataUser: listDataUser,
      StartDateExport:StartDateExport,
      EndDateExport:EndDateExport
    };
    const response = yield call(getListPlanningService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        openLoading();
        yield put(
          PlanningAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.ListHospitalId,
            request.ListWarehouseId,
            request.MonthPlanning,
            request.ListStatus,
            request.SearchTerm,
            request.IsWhole,
            request.ListDataUser,
            request.StartDateExport,
            request.EndDateExport
          )
        );
      } else {
        yield put(PlanningAction.getPagingReducer(response));
        closeLoading();
      }
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getRetailPlanningPagingSaga(data) {
  try {
      const pageSize = data.pageSize;
      const pageIndex = data.pageIndex;
      const listhospitalId = data.listhospitalId;
      const warehouseId = data.warehouseId? data.warehouseId : "";
      const monthPlanning = ConverDateToString(data.monthPlanning);
      const listStatus = data.listStatus;
      const searchTerm = data.searchTerm ? data.searchTerm : "";
      const isWhole =data.isWhole ? data.isWhole : false;
     
      const request = {
          PageIndex: pageIndex,
          PageSize: pageSize,
          SearchTerm: searchTerm,
          IsWhole:isWhole,
          ListHospitalId:listhospitalId,
          ListWarehouseId:warehouseId,
          MonthPlanning:monthPlanning,
          ListStatus:listStatus
      };
      const response = yield call(getListPlanningService, request);
      if (response) {
          if (response.Items.length <= 0 && request.PageIndex > 1) {
              yield put(
                  PlanningAction.getPagingRetailPlanningReducer(
                      request.PageIndex - 1,
                      request.PageSize,
                      request.searchTerm
                  )
              );
          } else {
              yield put(PlanningAction.getPagingRetailPlanningReducer(response));
          }
      }
      closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* deletePlanningSaga(request) {
  try {
    if (request.id) {
      const response = yield call(deletePlanningService, request.id);
      if (response.isSuccess) {
        yield put(PlanningAction.deleteDataReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* downloadExcelSaga(data) {
  let request = {
    Id: data.data.Id,
    typeExport: data.data.typeExport
  };
  const response = yield call(downloadExcelService, request);
  //Xử lý nếu typeExport là tải Excel
  if (request.typeExport === TYPE_EXPORT.EXCEL) {
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const fileName = getFileNameWithDate({
      prefix: "",
      fileNameWithoutExtension: "Danh_sach_vat_tu",
      extension: ".xlsx",
    });
    downloadFile(fileUrl, fileName);
  }
  // else if (request.typeExport === TYPE_EXPORT.VIEW_PDF) {
  //   const fileBlob = new Blob([response.data], { type: 'application/pdf' });
  //   const fileUrl = URL.createObjectURL(fileBlob);
  //   window.open(fileUrl, '_blank');
  //   URL.revokeObjectURL(fileUrl); //Giải phóng object URL sau khi dùng xong.
  // }
  closeLoading();
}
  export function* createPlanningSaga(requet) {
    try {
      if (requet.data) {
        const response = yield call(createPlanningService, requet.data);
        if (response.isSuccess) {
          yield put(PlanningAction.createDataReducer());
        }
        //closeLoading();
      }
    } catch (error) {
      closeLoading();
    }
  }

  export function* updatePlanningSaga(requet) {
    try {
      if (requet.data) {
        const response = yield call(updatePlanningService, requet.data);
        if (response.isSuccess) {
          yield put(PlanningAction.updateDataReducer());
        }
        //closeLoading();
      }
    } catch (error) {
      closeLoading();
    }
  }
  export function* getInfoPlanningByIdSaga(requet) {
    try {
      if (requet.data) {
        const response = yield call(getInfoPlanningByIdService, requet.data);
        if (response.isSuccess) {
          yield put(PlanningAction.getInfoPlanningReducer(response.data));
        }
        closeLoading();
      }
    } catch (error) {
      closeLoading();
    }
  }

  export function* PredictabilityRetailAutoSaga(requet) {
    try {
      const response = yield call(PredictabilityRetailAutoService,requet.data);
        if (response.PagingDetail.Items?.length > 0) {
          yield put(PlanningAction.PredictabilityRetailAutoReducer(response));
        }
        else 
        toastJs.error("Không có vật tư nào phát sinh suất sử dụng, không thể tính dự trù.");
        closeLoading();
    } catch (error) {
      closeLoading();
    }
  }
  export function* PredictabilityRetailByListProductSaga(request) {
    
    try {
      const response = yield call(PredictabilityRetailByListProductService, request.data);
        if (response.isSuccess) {
          yield put(PlanningAction.PredictabilityRetailByListProductReducer(response.data));
        }
        closeLoading();
    } catch (error) {
      closeLoading();
    }
  }
  export function* PredictabilityBudgetAutoSaga(requet) {
    
    try {
      const response = yield call(PredictabilityBudgetAutoService,requet.data);
      if (response.PagingDetail.Items?.length > 0) {
        yield put(PlanningAction.PredictabilityRetailAutoReducer(response));
      }
        closeLoading();
    } catch (error) {
      closeLoading();
    }
  }

  export function* PredictabilityBudgetByListProductSaga(requet) {
    
    try {
      const response = yield call(PredictabilityBudgetByListProductService,requet.data);
        if (response.isSuccess) {
          yield put(PlanningAction.PredictabilityRetailByListProductReducer(response.data));
        }
        closeLoading();
    } catch (error) {
      closeLoading();
    }
  }

  export function* updateAndChangePaging(data){
    //Gọi hàm update
    const listDetailUpdate = data.listDetailUpdate;
    if (listDetailUpdate?.length > 0){
      yield call(updateRangeDetailService,listDetailUpdate);
      //Đợi update xong thì thực hiện gọi hàm phân tragn
      yield call(getPagingDetailByIdSaga, data);
    }else {
      yield call(getPagingDetailByIdSaga, data);
    }
  }

export function* getPagingDetailByIdSaga(data) {
  try {
    const request = {
      PageSize: data.pageSize,
      PageIndex: data.pageIndex,
      PlanningId: data.planningId
    };
    const response = yield call(getPagingDetailByIdService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          PlanningAction.getPagingDetailByIdSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm
          )
        );
      } else {
        yield put(PlanningAction.getPagingDetailByIdReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* updateRangeDetailSaga(requet) {
  try {
    if (requet.data) {
      const response = yield call(updateRangeDetailService, requet.data);
      if (response.isSuccess) {
        yield put(PlanningAction.updateRangeDetailReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getInfoPlanningPagingSaga(requet) {
  try {
    
    if (requet.data) {
      const response = yield call(getInfoPlanningPagingService, requet.data);
      if (response.isSuccess) 
        yield put(PlanningAction.PredictabilityRetailAutoReducer(response.data));
      else
        yield put(PlanningAction.RecordNotFound());
    }
  } catch (error) {
    // toastJs.error(error);
    // debugger
  }
}
export function* deleteRangeDetailSaga(request) {
  try {
    
    let param = {
      planningId: request.data.planningId,
      listDetailId: request.data.listDetailId
    }
    if (param.planningId > 0 && param.listDetailId?.length > 0) {
      const response = yield call(deleteRangeDetailService, param);
      if (response.isSuccess) {
        yield put(PlanningAction.deleteRangeDetailReducer());
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* proposalSummarySaga(request){
  try {
    
    const response = yield call(proposalSummaryService, request.data);
    if (response.isSuccess)
      yield put(PlanningAction.ProposalSummaryReducer(response.data));
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export default function* PlanningSaga(data) {
    yield takeEvery(GET_PAGING_PLANNING_SAGA, getPagingSaga);
    yield takeEvery(DELETE_PLANNING_SAGA, deletePlanningSaga);
    yield takeEvery(CREATE_PLANNING_SAGA, createPlanningSaga);
    yield takeEvery(UPDATE_PLANNING_SAGA, updatePlanningSaga);
    yield takeEvery(GET_INFO_PLANNING_BY_ID_SAGA, getInfoPlanningByIdSaga);
    yield takeEvery(GET_PAGING_RETAIL_PLANNING_SAGA, getRetailPlanningPagingSaga);
    yield takeEvery(PREDICTABILITY_RETAIL_AUTO_SAGA, PredictabilityRetailAutoSaga);
    yield takeEvery(PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA, PredictabilityRetailByListProductSaga);
    yield takeEvery(PREDICTABILITY_BUDGET_AUTO_SAGA, PredictabilityBudgetAutoSaga);
    yield takeEvery(PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_SAGA, PredictabilityBudgetByListProductSaga);
    yield takeEvery(GET_PAGING_DETAIL_BY_ID_SAGA, updateAndChangePaging);
    yield takeEvery(UPDATE_A_DETAIL_PLANNING_SAGA, updateRangeDetailSaga);
    yield takeEvery(GET_INFO_PLANNING_PAGING_BY_ID_SAGA, getInfoPlanningPagingSaga);
    yield takeEvery(DELETE_RANGE_PLANNING_DETAIL_SAGA, deleteRangeDetailSaga);
    yield takeEvery(PROPOSAL_SUMMARY_SAGA, proposalSummarySaga);
    yield takeEvery(PLANNING_DOWNLOAD_EXCEL_SAGA, downloadExcelSaga);
}
