export const Productmm = {
    language: "vn",
    productmm: {
        table: {
            Productcode: "Mã sản phẩm",
            Productname: "Tên sản phẩm",
            Description: "Mô tả",
            Description2: "Mô tả 2",
            Modelname: "Model",
            Typeofproduct: "Kiểu sản phẩm",
            Unitdescription: "Đơn vị",
            Active: "Trạng thái",
        },
        placeholder: "Mã sản phẩm, tên sản phẩm, mô tả, model, kiểu.... ",
        form: {
            title: "Xem thông tin sản phẩm",
            updatetitle: "Cập nhật thông tin sản phẩm",
            addtitle: "Thêm mới sản phẩm",
            checkbox: "Đang hoạt động",
            productnameduplicated: "Tên sản phẩm đã tồn tại",
            productcodeduplicated: "Mã sản phẩm đã tồn tại",
        },
        deleteform: {
            title: "Xác nhận xóa sản phẩm",
            label: "Bạn có chắc chắn muốn xóa sản phẩm này?"
        },
        navbar: {
            f1: "Quản lý sản phẩm",
            f2: "Quản lý model",
        },
        placeholder2: {
            f1: "Nhập tên sản phẩm",
            f2: "Chọn đơn vị",
            f3: "Nhập mã sản phẩm",
            f4: "Chọn model",
            f5: "Chọn kiểu sản phẩm",
            f6: "Nhập mô tả 1",
            f7: "Nhập mô tả 2",
        },
        notification: {
            f1: "Tạo mới sản phẩm thành công",
            f2: "Cập nhật sản phẩm thành công",
            f3: "Xóa sản phẩm thành công",
        }

    }
}