export const timeShiftManager = {
    language: "en",
    placeholder: "Enter name time shift",
    search: "Search",
    tableTime: {
        time_shift: "Time Shift",
        symbol: "Symbol",
        des: "Describe",
        hour_start: "Hour start",
        hour_end: "Hour end",
        action: "Action",
    },
    formTimeShift: {
        Shift: "Shift",
        title_create: "Create new time shift",
        title_infor: "Time shift information",
        title_edit: "Update time shift",
        title_delete: "Delete time shift",
        text_confirm_delete: "Do you want to remove time shift?",
        describe: "Describe",
        placeholder: "Enter describe"
    },
    mesTimeShift: {
        empty_name: " Time shift name cannot be empty",
        maxLength_name: "Time shift name no longer than 100 characters",
        empty_creator: "Creator name cannot be empty",
        maxLength_creator: "Creator name longer than 6 characters",
        required_hour_start: "Hour start is required",
        required_hour_end: "Hour end is required",
        create_shift_success: "Create new time shift successfully",
        update_shift_success: "Update time shift successfully",
        delete_shift_success: "Delete time shift successfully",
        update_shift_fail: "Update fail!",
        delete_shift_fail: "Delete fail!",
    },
    recreationRequest: {
        listBreakTime: "List break time",
        name_break_time: "Break name",
        title_create: "Create new break time",
        title_edit: "Update break time",
        title_delete: "Delete break time",
        text_confirm_delete: "Do you want delete this break time?",
        mes: {
            required_name: "Break name cannot be empty",
            maxLength_name: "Break name no longer than 100 characters",
            already_exist_name: "Break name already exist",
            invalid_time: "Start time is greater than end time",
            hour_start_invalid: "Start time invalid",
            hour_end_invalid: "End time invalid",
            hour_start_less_than_hour_end: "Time start must less than time end",
            end_time_bigger_end_time_of_time_schedule: "The end time must be less than the end time of the time schedule",
            create_success: "Create new break time successfully",
            update_success: "Update break time successfully",
        }
    }
}