export const timeScheduleManager = {
    language: "vn",
    tableCalendar: {
        name: "Tên lịch làm việc",
        area: "Khu vực áp dụng",
        creator: "Người tạo",
        createDate: "Ngày tạo",
        description: "Mô tả lịch làm việc",
        action: "Hành động",
        placeHolderSearchCalendarManager: "Tên lịch làm việc , khu vực áp dụng , người tạo",
        placeHolder_area: "Chọn khu vực áp dụng",
        mustHaveDateRange: "Bắt buộc chọn khoảng ngày",
    },
    search: "Tìm kiếm",
    create: "Tạo mới",
    titleDeleteCalendar: "Xóa lịch làm việc",
    formInfo: {
        title_create: "Thêm mới lịch làm việc",
        title_infor: "Thông tin lịch làm việc",
        title_edit: "Cập nhật lịch làm việc",
        describe: "Mô tả",
        placeholder: "Nhập mô tả",
        placeholder_name: "Nhập tên lịch làm việc",
        placeHolderTimeShift: "Nhập tên ca làm việc",
        placeHolderSymbol: "Nhập ký hiệu",
        placeHolderShiftBreak: "Nhập tên giờ nghỉ giải lao",
    },
    groupWorkingDay: {
        title_create: "Thêm mới nhóm ngày làm việc",
        title_infor: "Thông tin nhóm ngày làm việc",
        title_edit: "Cập nhật nhóm ngày làm việc",
        title_delete: "Xóa nhóm ngày làm việc",
        text_confirm_delete: "Bạn có muốn xóa nhóm ngày làm việc này?",
        describe: "Mô tả",
        placeholder: "Nhập mô tả",
        placeHolderName: "Nhập tên nhóm",
        required_color: "Màu sắc không được bỏ trống",
        maxLength_color: "Màu sắc tối đa 50 kí tự",
        required_name: "Tên nhóm không được bỏ trống",
        maxLength_name: "Tên nhóm tối đa 50 kí tự",
    },
    mes: {
        empty_name: "Tên lịch làm việc không được để trống",
        maxLength_name: "Tên lịch làm việc không được quá 100 ký tự",
        empty_creator: "Ký hiệu không được để trống",
        maxLength_creator: "Ký hiệu không dài quá 6 kí tự",
        empty_des: "Mô tả không được để trống",
        empty_workarea: "Bạn phải chọn ít nhất một khu vực làm việc",
        maxLength_des: "Mô tả không được phép dài quá 255 ký tự",
        required_date: "Ngày bắt đầu lịch làm việc không được để trống",
        create_calendar_success: "Tạo lịch mới thành công",
        update_calendar_success: "Cập nhật lịch làm việc thành công",
        delete_calendar_success: "Xóa lịch làm việc thành công",
        update_calendar_fail: "Cập nhật thất bại",
        delete_calendar_fail: "Xóa lịch làm việc thất bại",
    }
}