// lấy danh sách người lao động dựa vào công đoạm
// lấy danh sách máy móc dựa vào công đoạn
export const GET_WORKER_BY_CODE_SAGA = "GET_WORKER_BY_CODE_SAGA";
export const GET_WORKER_BY_CODE_REDUCER = "GET_WORKER_BY_CODE_REDUCER";
export const GET_WORK_UNIT_PRODUCTION_SAGA = "GET_WORK_UNIT_PRODUCTION_SAGA";
export const GET_WORK_UNIT_REDUCER = "GET_WORK_UNIT_REDUCER";
export const DEFAULT = "DEFAULT";
export const GET_WORKER_MACHINE_SAGA = "GET_WORKER_MACHINE_SAGA";
export const SET_WORKER_MAICHINE_REDUCER = "SET_WORKER_MAICHINE_REDUCER";
export const CREATE_WORKER_MACHINE = "CREATE_WORKER_MACHINE";
export const GET_WORKUNIT_WITH_WORKCENTER_AND_WO = "GET_WORKUNIT_WITH_WORKCENTER_AND_WO";
export const SET_WORKUNIT_WITH_WC_WO = "SET_WORKUNIT_WITH_WC_WO";
export const CALL_SAVE_WORKUNIT_SUCCESS = "CALL_SAVE_WORKUNIT_SUCCESS";
export const SAVE_TRANFER_WORKUNIT_WCMC = "SAVE_TRANFER_WORKUNIT_WCMC";
export const CALL_CHANGE_WORK_MACHINE_SUCCESS = "CALL_CHANGE_WORK_MACHINE_SUCCESS";