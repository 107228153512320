export const GET_PAGING_PLANNING_SAGA = "GET_PAGING_PLANNING_SAGA";
export const GET_PAGING_PLANNING_REDUCER = "GET_PAGING_PLANNING_REDUCER";
export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
export const DELETE_PLANNING_SAGA = "DELETE_PLANNING_SAGA";
export const DELETE_PLANNING_REDUCER = "DELETE_PLANNING_REDUCER";
export const CREATE_PLANNING_REDUCER = "CREATE_PLANNING_REDUCER"
export const CREATE_PLANNING_SAGA = "CREATE_PLANNING_SAGA"
export const UPDATE_PLANNING_REDUCER = "UPDATE_PLANNING_REDUCER"
export const UPDATE_PLANNING_SAGA = "UPDATE_PLANNING_SAGA"
export const UPDATE_A_DETAIL_PLANNING_SAGA = "UPDATE_A_DETAIL_PLANNING_SAGA"
export const UPDATE_RANGE_DETAIL_PLANNING_REDUCER = "UPDATE_RANGE_DETAIL_PLANNING_REDUCER"
export const GET_INFO_PLANNING_BY_ID_REDUCER = 'GET_INFO_PLANNING_BY_ID_REDUCER'
export const RESET_INFO_PLANNING_BY_ID_REDUCER = 'RESET_INFO_PLANNING_BY_ID_REDUCER'
export const GET_INFO_PLANNING_BY_ID_SAGA = 'GET_INFO_PLANNING_BY_ID_SAGA'
export const GET_INFO_PLANNING_PAGING_BY_ID_REDUCER = 'GET_INFO_PLANNING_PAGING_BY_ID_REDUCER'
export const GET_INFO_PLANNING_PAGING_BY_ID_SAGA = 'GET_INFO_PLANNING_PAGING_BY_ID_SAGA'
export const GET_PAGING_RETAIL_PLANNING_SAGA = "GET_PAGING_RETAIL_PLANNING_SAGA";
export const GET_PAGING_RETAIL_PLANNING_REDUCER = "GET_PAGING_RETAIL_PLANNING_REDUCER";
export const PREDICTABILITY_RETAIL_AUTO_SAGA = "PREDICTABILITY_RETAIL_AUTO_SAGA";
export const PREDICTABILITY_RETAIL_AUTO_REDUCER = "PREDICTABILITY_RETAIL_AUTO_REDUCER";
export const PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA = "PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA";
export const PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER = "PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER";
export const PREDICTABILITY_BUDGET_AUTO_SAGA = "PREDICTABILITY_BUDGET_AUTO_SAGA";
export const PREDICTABILITY_BUDGET_AUTO_REDUCER = "PREDICTABILITY_BUDGET_AUTO_REDUCER";
export const PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_SAGA = "PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_SAGA";
export const PLANNING_DOWNLOAD_EXCEL_SAGA = "PLANNING_DOWNLOAD_EXCEL_SAGA";
export const PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_REDUCER = "PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_REDUCER";
export const GET_PAGING_DETAIL_BY_ID_SAGA = "GET_PAGING_DETAIL_BY_ID_SAGA";
export const GET_PAGING_DETAIL_BY_ID_REDUCER = "GET_PAGING_DETAIL_BY_ID_REDUCER";
export const DELETE_RANGE_PLANNING_DETAIL_SAGA = "DELETE_RANGE_PLANNING_DETAIL_SAGA";
export const DELETE_RANGE_PLANNING_DETAIL_REDUCER = "DELETE_RANGE_PLANNING_DETAIL_REDUCER";
export const PROPOSAL_SUMMARY_SAGA = "PROPOSAL_SUMMARY_SAGA";
export const PROPOSAL_SUMMARY_REDUCER = "PROPOSAL_SUMMARY_REDUCER";
export const RECORD_NOT_FOUND_REDUCER = "RECORD_NOT_FOUND_REDUCER";
export const RESET_RECORD_NOT_FOUND_REDUCER = "RESET_RECORD_NOT_FOUND_REDUCER";

export const TYPE_EXPORT = {
  EXCEL: 1,
  PDF: 2,
  VIEW_PDF: 3
}
