export const GET_PAGING_CAT_WAREHOUSE_PRODUCT_API = "/api/service/CatWarehouseProduct/Search";
export const GET_PAGING_PRODUCT_BY_ROLE_USER_API = "/api/service/CatWarehouseProduct/SearchByRoleUser";
export const UPDATE_CAT_WAREHOUSE_PRODUCT_API = "/api/service/CatWarehouseProduct/Update";
export const EXPORT_LIST_DATA_API = "/api/service/CatWarehouseProduct/ExportTemplate";
export const EXPORT_REPORT_BY_LIST_ID_API = "/api/service/CatWarehouseProduct/ExportReport";

export const IMPORT_TEMPLATE_CAT_WAREHOUSE_PRODUCT_API = "/api/service/CatWarehouseProduct/ImportTemplate";

export const SEARCH_PRODUCT_BY_CODEOH_API = "/api/service/CatWarehouseProduct/SearchByCodeOH";
export const GET_PAGING_BY_PLANNING_API = "/api/service/CatWarehouseProduct/SearchPagingnationByPlanning";
export const GET_DETAIL_CAT_WAREHOUSE_PRODUCT_API = "/api/service/CatWarehouseProduct/GetById";