export const RescheduleProductionEN = {
    language: "en",
    rescheduleproduction: {
        selectitem: "No product item have been selected yet",
        errorcreate: "Can't select 2 products to create work order",
        searchplaceholder: "Product item, product, workedorder, workcenter, status, production end time",
        create: "Create work order",
        itempro: "Product Item",
        producedorder: "Worked order",
        productioncenter: "Work center",
        endtime: "Work end time",
        status: "Status",
        placeholder: {
            itempro: "Product Item",
            product: "Product",
            producedorder: "Worked order",
            productionorder: "Work order",
            productioncenterold: "Work center old",
            status: "Status",
            process: "Process",
            amount: "Amount",
            expectedstart: "Expected start",
            expectedend: "Expected end",
        },
        table: {
            dataEmpty: "There are no data to display",
        },
        form: {
            WorkorderCode: "Work order cannot be left blank",
            Amount: "Amount cannot be left blank",
            AmountFormat: "Number is not in the correct format",
            NumberBiggerZero: "The number must be greater than 0",
        },
        rules: {
            workordercodeempty: "Work order code cannot be empty",
            workordercodemax: "Work order code cannot exceed 50 characters",
            processempty: "Process cannot be empty",
            workcenterempty: "Work center cannot be empty",
            esproductiontimefromempty: "Esproductiontimefrom cannot be left blank",
            esproductiontimetoempty: "Esproductiontimeto cannot be left blank",
            checktime: "Esproductiontimeto must be greater than esproductiontimefrom",
            noitemproduct: "There are no product item",
        }
    },
}