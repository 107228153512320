/* eslint-disable */
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
// import { ConnectedRouter } from 'react-router-redux';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import createSagaMiddleware from "redux-saga";
import App from "./App";
import "./i18n";
import rootReducerV2 from "./redux-saga-v2/root/rootReducers";
import rootReducer from "./redux-saga/reducers/rootReducers/HomeRootReducers";

import AppContextProvider from "./context/AppContext";
import { MenuContextProvider } from "./context/MenuContext";
import rootSagaV2 from "./redux-saga-v2/root/rootSaga";
import rootSaga from "./redux-saga/sagas/rootSagas/HomeRootSaga";

import { ConfigProvider } from "antd";
import locale from "antd/locale/vi_VN";
import "dayjs/locale/vi";

// import css base chung
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
// const history = createBrowserHistory({ basename: baseUrl });
const rootElement = document.getElementById("root");
const sagaMiddleware = createSagaMiddleware();
const rootReducerHome = combineReducers({
  ...rootReducer,
  ...rootReducerV2,
});
const store = createStore(rootReducerHome, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
sagaMiddleware.run(rootSagaV2);
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(
  <I18nextProvider>
    <AppContextProvider>
      <MenuContextProvider>
        <ConfigProvider locale={locale}>
          <Provider store={store}>
            {/* <ConnectedRouter children={null} store={store} history={history}>  */}
            <App />
            {/* </ConnectedRouter> */}
          </Provider>
        </ConfigProvider>
      </MenuContextProvider>
      <ToastContainer />
    </AppContextProvider>
  </I18nextProvider>
);
// root.render(
// <I18nextProvider>
//   <Provider store={store}>
//     <ConnectedRouter history={history}>
//       <App />
//     </ConnectedRouter>
//   </Provider>
//   <ToastContainer  />
// </I18nextProvider>,
// rootElement);
