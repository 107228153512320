import {
    GET_ASSETMENT_INVENTORY_RESULTS_REDUCER,
    CLEAR_ASSETMENT_INVENTORY_RESULT_REDUCER,
    CALL_DEFAULT_INVENTORY_RESULT_REDUCER,
    GET_DETAIL_INVENTORY_RESULT_REDUCER,
    GET_ASSETMENT_INVENTORY_INFO_REDUCER,
    LOADING,
    UPDATE_ASSETMENT_INVENTORY_INFO_REDUCER,
    GET_SUMMARY_RESULT_INVENTORY_REDUCER,
    GET_INVENTORY_ASSET_SCAN_REDUCER,
    INVENTORY_COMPLETION_CONFIRMATION_REDUCER,
    INVENTORY_ASSET_SUBMIT_REPORT_REDUCER,
    CREATE_MANUAL_INVENTORY_ASSET_REDUCER

} from "./enum/TypeResultInventoryAction.js";

const stateDefault = {
    listData: [],
    callResultFromApi: false,
    detailList: [],
    callDetailFromApi: false,
    assetmentInventoryInfoList: [],
    isGetAssetmentInfo: false,
    isUpdateAssetmentInfoOk: false,
    isCallInfoInventoryOk: false,
    objectInfoInventory: {},
    isGetInventoryAssetScan: false,
    listInventoryAssetScan: [],
    isSubmitReportOK: false,
    isInventoryConfirmOk: false,
    isCreateManualInventoryAssetOk: false
}
const ResultInventoryReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {

        case GET_ASSETMENT_INVENTORY_RESULTS_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.callResultFromApi = true;
            }
            return { ...state };

        case GET_DETAIL_INVENTORY_RESULT_REDUCER:
            if (action.data) {
                state.detailList = action.data;
                state.callDetailFromApi = true;
            }
            return { ...state };

        case GET_ASSETMENT_INVENTORY_INFO_REDUCER:
            if (action.data) {
                state.assetmentInventoryInfoList = action.data;
                state.isGetAssetmentInfo = true;
            }
            return { ...state };

        case UPDATE_ASSETMENT_INVENTORY_INFO_REDUCER:
            state.isUpdateAssetmentInfoOk = true;

            return { ...state }

        case GET_SUMMARY_RESULT_INVENTORY_REDUCER:
            if (action.data) {
                state.isCallInfoInventoryOk = true;
                state.objectInfoInventory = action.data.data;
            }
            return { ...state }

        case GET_INVENTORY_ASSET_SCAN_REDUCER:
            if (action.data) {
                state.listInventoryAssetScan = action.data.data;
                state.isGetInventoryAssetScan = true;
            }
            return { ...state };

        case INVENTORY_ASSET_SUBMIT_REPORT_REDUCER:
            state.isSubmitReportOK = true;

            return { ...state }
        case INVENTORY_COMPLETION_CONFIRMATION_REDUCER:
            state.isInventoryConfirmOk = true;

            return { ...state }
        case CREATE_MANUAL_INVENTORY_ASSET_REDUCER:
            state.isCreateManualInventoryAssetOk = true;
            return { ...state }
        case CALL_DEFAULT_INVENTORY_RESULT_REDUCER:
            state.callResultFromApi = false;
            state.callDetailFromApi = false;
            state.isGetAssetmentInfo = false;
            state.isUpdateAssetmentInfoOk = false;
            state.isGetInventoryAssetScan = false;
            state.isSubmitReportOK = false;
            state.isInventoryConfirmOk = false;
            state.isCallInfoInventoryOk = false;
            state.isCreateManualInventoryAssetOk = false;

            return {
                ...state,
            }

        case LOADING:
            state.loading = action.isLoading
            return { ...state }

        case CLEAR_ASSETMENT_INVENTORY_RESULT_REDUCER:
            return {
                state: stateDefault,
            };
        default:
            return { ...state };
    }
};

export default ResultInventoryReducer;
