import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import SOPAction from "src/redux-saga-v2/MMProduction/SOP/SOPAction";
import { getPagingService, createDataService, updateDataService, getDetailService, changeVersionService, getDropdownVersionService, deleteVersionService } from "./SOPServices";
import { GET_PAGING_SOP_SAGA, CREATE_SOP_SAGA, UPDATE_SOP_SAGA, GET_DETAIL_SOP_SAGA, CHANGE_VERSION_SOP_SAGA, GET_DROPDOWN_VERSION_SAGA, DELETED_VERSION_SAGA, ADD_NEW_VESION_SAGA } from "./enum/SOPTypeAction";

export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ?? " ";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      SearchTerm: searchTerm,
    };
    const response = yield call(getPagingService, request);
    if (response?.isSuccess) {
      yield put(SOPAction.getPagingReducer(response.data));
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* createDataSaga(data) {
  try {
    const request = {
      isCreateVersion: false,
      Name: data.request.nameSOP,
      Productcode: data.request.product,
      NewVersionName: data.request.version,
      Note: data.request.note,
      Filepath: data.request.attachedFiles,
    }
    const result = yield call(createDataService, request);
    if (result.isSuccess) {
      yield put(SOPAction.callResultFromApiCreateReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* updateDataSaga(data) {
  try {
    const request = {
      isCreateVersion: false,
      Id: data.request.idSOP,
      Name: data.request.nameSOP,
      Productcode: data.request.productCode,
      NewVersionName: data.request.version,
      Note: data.request.note,
      Filepath: data.request.attachedFiles,
      VersionId: data.request.versionId
    }
    const result = yield call(updateDataService, request);
    if (result.isSuccess) {
      yield put(SOPAction.callResultFromApiUpdateReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* getDetailDataSaga(data) {
  try {
    const objectData = yield call(getDetailService, data.sopCode);
    if (objectData) {
      yield put(SOPAction.returnDetailReducer(objectData));
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* changeVersionSaga(data) {
  try {
    const result = yield call(changeVersionService, data.sopId);
    if (result.isSuccess) {
      yield put(SOPAction.callChangeVersionReducer());

    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* getDropdownVersionSaga(data) {
  try {
    const response = yield call(getDropdownVersionService, data.sopId);
    if (response?.isSuccess) {
      yield put(SOPAction.getDropdownVersionReducer(response.data));
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* deletedVersionSaga(data) {
  try {
    const result = yield call(deleteVersionService, data.versionId);
    if (result.isSuccess) {
      yield put(SOPAction.deleteVersionReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export function* addNewVersionSaga(data) {
  try {
    const request = {
      isCreateVersion: true,
      Id: data.data.idSOP,
      Name: data.data.nameSOP,
      Productcode: data.data.productCode,
      NewVersionName: data.data.version,
      Note: data.data.note,
      Filepath: data.data.attachedFiles,
    }
    const result = yield call(createDataService, request);
    if (result.isSuccess) {
      yield put(SOPAction.callResultAddVersionReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export default function* SOPSaga() {
  yield takeEvery(GET_PAGING_SOP_SAGA, getPagingSaga);
  yield takeEvery(CREATE_SOP_SAGA, createDataSaga);
  yield takeEvery(UPDATE_SOP_SAGA, updateDataSaga);
  yield takeEvery(GET_DETAIL_SOP_SAGA, getDetailDataSaga);
  yield takeEvery(CHANGE_VERSION_SOP_SAGA, changeVersionSaga);
  yield takeEvery(GET_DROPDOWN_VERSION_SAGA, getDropdownVersionSaga);
  yield takeEvery(DELETED_VERSION_SAGA, deletedVersionSaga);
  yield takeEvery(ADD_NEW_VESION_SAGA, addNewVersionSaga);




















}
