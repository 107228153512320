export const Machine = {
    language: "en",
    machine: {
        //index
        classcodeexist: "Class code already exists",
        classnameexist: "The taxonomy name already exists",
        machinetitle: "Machine Management",
        machinetypetitle: "Machine classification management",
        searchplaceholder: "Machine code, machine name, classification, model, manufacturer, origin, machine location",
        addnew: "Create new",
        excel: "Export excel file",
        searchplaceholder2: "Machine type code, machine type name",
        deletetitletype: "Delete type of machine",
        deleteques: "Are you sure you want to delete this type of machine?",
        //title table
        description: "Description",
        classificationcode: "Classification code",
        machinecode: "Machine code",
        machinename: "Machine name",
        unit: "Unit",
        classification: "Classification",
        manufacturer: "Manufacturer",
        origin: "Origin",
        status: "Status",
        action: "Action",
        machinelocation: "Machine location",
        nodata: "There are no data to display",
        deletetitle: "Confirm machine deletion",
        deletequestion: "Are you sure you want to delete this machine?",
        //form
        imagemax: "Upload more than 3 photos",
        fileformat: "The file format is not correct",
        filesizemax5: "File size larger than 5MB",
        edittype: "Edit Machine Type",
        viewtype: "Machine type Information",
        newtype: "Create a new type of machine",
        createnewmachine: "Create new machine",
        machineupdate: "Machine Update",
        machineinformation: "Machine information",
        technicaldocument: "Technical document",
        size: "Size ",
        wattage: "Capacity ",
        supplier: "Supplier",
        machineimages: "Machine images (up to 3 images, up to 5 MB)",
        maintancetime: "Periodic maintenance time",
        month: "month",
        depreciationperiod: "Depreciation period",
        statusmachine: "Machine status",
        entryprice: "Entry price",
        seri: "Seri number",
        specifications: "Specifications",
        generalinfor: "General information",
        validate: {
            descriptionrequired: "Description cannot exceed 255 characters",
            typecodelength: "Classification code cannot exceed 50 characters",
            typecoderequired: "Classification code cannot be left blank",
            typenamelength: "Classification name cannot exceed 255 characters",
            typenamerequired: "Classification name cannot be left blank",
            coderequired: "Machine code cannot be left blank",
            namerequired: "Machine name cannot be left blank",
            namelength: "Machine name cannot exceed 255 characters",
            statusrequired: "Machine status cannot be empty",
            unitrequired: "Unit name cannot be empty",
            unitlength: "Unit name cannot exceed 200 characters",
            serirequired: "Serial number cannot be left blank",
            serilength: "Serial number cannot exceed 50 characters",
            machineclassrequired: "Machine classification cannot be left blank",
            workarearequired: "Machine position cannot be empty",
            numbervalid: "Incorrect format as number type",
            depreciationtimerequired: "Amortization period cannot be left blank",
            maintancetimerequired: "Regular maintenance period cannot be left blank",
            sizerequired: "Device size cannot be empty",
            deviceproviderrequired: "Device provider cannot be left blank",
            originrequired: "Origin cannot be left blank",
            originlength: "Origin cannot exceed 200 characters",
            modelrequired: "Model cannot be empty",
            modellength: "Model cannot exceed 200 characters",
            manufacturerrequired: "Manufacturer cannot be left blank",
            manufacturerlength: "Manufacturer must not exceed 200 characters",
            capacity: "Capacity cannot be empty",
            machineimg: "Machine images cannot be left blank",
        },
        machineType_createTitle: "Thêm phân loại máy móc",
        machineType_editTitle: "Cập nhật phân loại máy móc",
        machineType_seeDetailTitle: "Thông tin phân loại máy móc",
    },

}