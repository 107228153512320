export const accessoriesLag = {
    language: "en",
    accessories: {
        title: "Components management",
        table: {
            Materialcode: "Component code",
            Materialname: "Component name",
            Description: "Description",
            Description2: "Description 2",
            Unit: "Unit",
            Color: "Color",
            TypeName: "Component type",
            Active: "Active",
            Action: "Action"
        },
        btnTitle: {
            seeDetail: "See detail",
            edit: "Edit",
            delete: "Delete",
        },
        search: {
            placeHolder: "Enter part code, part name, description, component type, unit,..."
        },
        form: {
            createNew: "Create new components",
            update: "Update component information",
            getDetail: "See detail component information",
            Materialcode: "Component code",
            Materialname: "Component name",
            Unit: "Unit",
            Typeid: "Component type",
            Color: "Color",
            Description: "Description",
            Description2: "Description 2",
            status: "Active",
            placeHolder: {
                Materialcode: "Enter component code",
                Materialname: "Enter component name",
                Unit: "Select unit",
                Typeid: "Select component type",
                Color: "Enter color",
                Description: "Enter description",
            }
        },
        delete: {
            title: "Delete components",
            description: "Are you sure you want to delete this component?",
        },
        error: {
            Materialcode: {
                validateType: "Incorrect code",
                required: "Component code cannot be left blank",
                maxLength: "Component code must not exceed 50 characters",
            },
            Materialname: {
                required: "Component name cannot be left blank",
                maxLength: "Component name must not exceed 255 characters",
            },
            Unit: {
                required: "Unit cannot be left blank",
                maxLength: "Unit must not exceed 36 characters",
            },
            Description: {
                maxLength: "Description 1 can't be more than 255 characters",
            },
            Description2: {
                maxLength: "Description 2 can't be more than 255 characters",
            },
            Typeid: {
                required: "Component type cannot be left blank"
            }
        },
        toastJS: {
            creatSuccess: "Create new components successfully",
            updateSuccess: "Components update successful",
            deleteSuccess: "Successfully deleted components",
        }
    }
}