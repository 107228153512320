import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_ALL_LOTQA_API,
    CREATE_LOTQA_API,
    GET_DETAIL_LOTQA_API,
    GET_DROPDOWN_WO_API,
    UPDATE_LOTQA_API,
    GET_CODE_FROM_API,
    GET_LIST_ITEM_CODE_API,


} from "./enum/LotQATypeApi";
export async function getPagingService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_ALL_LOTQA_API,
        data
    )
};
export async function createDataService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + CREATE_LOTQA_API,
        data
    )
};
export async function updateDataService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + UPDATE_LOTQA_API,
        request
    )
};
export async function getDetailService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_DETAIL_LOTQA_API + "/" + request.lotcode + "/" + request.forConductEvaluation
    );
};
export async function getDropdownWOService(searchtemp) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_DROPDOWN_WO_API, searchtemp
    );
};
export async function getCodeFromAPI(searchtemp){
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_CODE_FROM_API,searchtemp
    );
};
export async function getListItemCodeService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_LIST_ITEM_CODE_API + "/" + request.code + "/" + request.workordercode
    );
};