export const RequestFormManager = {
    language: "vn",
    manager: {
        table: {
            code: "Mã",
            petiton: "Yêu cầu bởi",
            transfer: "Chuyển hàng tới khu vực",
            reason: "Lý do yêu cầu",
            delivery: "Số D/O",
            status: "Trạng thái",
            pickingList: "Danh sách lấy hàng",
            action: "Thao tác",
        },
        searchlabel: "Nhập thông tin phiếu yêu cầu cần tìm...",
        addForm: {
            tittleLabel: "Thêm mới phiếu yêu cầu",
            orderby: "Yêu cầu bởi",
            exportCode: "Mã phiếu yêu cầu",
            docode: "Mã phiếu xuất hàng",
            reason: "Lý do yêu cầu",
            orderCode: "Mã hàng yêu cầu",
            description: "Mô tả hàng hóa",
            number: "Số lượng chuyển",
            unit: "Đơn vị",
            action: "Thao tác",
            addbutton: "Thêm hàng",
            addlabel: "Nhập mã hàng yêu cầu"
        },
        updateForm: {
            tittleLabel: "Cập nhật phiếu yêu cầu",
        },
        deleteForm: {
            title: "Xóa phiếu yêu cầu",
            label: "Bạn chắc muốn xóa phiếu yêu cầu này?",
            titletable: "Xác nhận xoá mã hàng yêu cầu",
            labletable: "Bạn có chắc chắn muốn xóa mã hàng yêu cầu này ?"

        },
        detailForm: "Phiếu yêu cầu",
        errorToast: "Phiếu giao hàng không thể sửa do đã có dữ liệu đi lấy hàng",
        errorReason: "Lý do yêu cầu không vượt quá 255 kí tự",
        domaxlength: "Mã phiếu xuất hàng không được vượt quá 255 ký tự",
        errorForm: {
            errorValidateTranfer: "Mã không đúng định dạng",
            errorRequiredTranfer: "Mã phiếu yêu cầu không được bỏ trống",
            errorMaxLengthTranfer: "Mã phiếu yêu cầu không được quá 50 kí tự",
            errorRequiredPetition: "Yêu cầu bởi không được bỏ trống",
            errorRequiredQty: "Số lượng chuyển hàng không được bỏ trống",
            errorRequiredGoodCode: "Mã hàng yêu cầu không được bỏ trống",
            errorTextQty: "Số lượng đặt phải là một số",
            errorTextQty2: "Số lượng đặt phải lớn hơn hoặc bằng 0",
            errorToast: "Phiếu yêu cầu phải có các mã hàng",
        },
        toastJs: {
            createOK: "Tạo mới phiếu yêu cầu thành công",
            updateOK: "Cập nhật phiếu yêu cầu thành công",
            deleteOK: "Xóa phiếu yêu cầu thành công"
        }
    }
}