import {
    CALL_DEFAULT_LOTQA_REDUCER,
    UPDATE_LOTQA_SAGA,
    CREATE_LOTQA_SAGA,
    GET_DETAIL_LOTQA_REDUCER,
    GET_PAGING_LOTQA_REDUCER,
    GET_PAGING_LOTQA_SAGA,
    CALL_RESULT_LOTQA_REDUCER,
    GET_DETAIL_LOTQA_SAGA,
    GET_DROPDOWN_WO_SAGA,
    SET_DROPDOWN_WO_REDUCER,
    CALL_CHANGE_DATA,
    GET_CODE_FROM_API_REDUCER,
    GET_CODE_FROM_API_SAGA,
    GET_LIST_ITEM_CODE_SAGA,
    GET_LIST_ITEM_CODE_REDUCER,
}
    from "./enum/LotQATypeAction";

const LOTQAAction = {
    // ham truyen bien de goi data
    getPagingSaga: (pageIndex, pageSize, searchTerm, status, fromTime,toTime) => {
        return {
            type: GET_PAGING_LOTQA_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
            status: status,
            fromTime: fromTime,
            toTime:toTime,
        };
    },
    // ham de xet data vao reducer
    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_LOTQA_REDUCER,
            data: data
        };
    },
    // ham de goi phuong thuc tao moi
    createDataSaga: (data) => {
        return {
            type: CREATE_LOTQA_SAGA,
            data: data
        };
    },
    updateDataSaga: (data) => {
        return {
            type: UPDATE_LOTQA_SAGA,
            data: data
        };
    },
    // ham de goi du lieu xem chi tiet
    getDetailSaga: (lotcode, forConductEvaluation) => {
        return {
            type: GET_DETAIL_LOTQA_SAGA,
            lotcode: lotcode,
            forConductEvaluation: forConductEvaluation,
        };
    },
    // ham luu gia tri chi tiet vao reducer
    returnDetailReducer: (obj) => {
        return {
            type: GET_DETAIL_LOTQA_REDUCER,
            obj: obj
        };
    },
    // ham kiem tra ket qua sau khi goi api
    callResultFromApiReducer: () => {
        return {
            type: CALL_RESULT_LOTQA_REDUCER,
        };
    },
    callChangeData: () => {
        return {
            type: CALL_CHANGE_DATA,
        };
    },
    // ham goi api lay danh sach trang thai
    getDropdownWO: (searchtemp) => {
        return {
            type: GET_DROPDOWN_WO_SAGA,
            searchtemp: searchtemp
        };
    },
    // ham set gia tri ds vao reuder
    setDropdownWO: (data) => {
        return {
            type: SET_DROPDOWN_WO_REDUCER,
            data: data
        };
    },
    getCodeSaga: (searchtemp) => {
        return {
            type: GET_CODE_FROM_API_SAGA,
            searchtemp: searchtemp
        }
    },
    getCodeReducer: (data) => {
        return {
            type: GET_CODE_FROM_API_REDUCER,
            data: data
        }
    },
    getListItemCodeSaga: (code,workordercode) => {
        return {
            type: GET_LIST_ITEM_CODE_SAGA,
            code: code,
            workordercode:workordercode,
        }
    },
    getListItemCodeReducer: (data) => {
        return {
            type: GET_LIST_ITEM_CODE_REDUCER,
            data:data
        }
    },
    // ham reset du lieu ve ban dau
    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_LOTQA_REDUCER
        }
    }
}
export default LOTQAAction;