import {
    GET_DATA_PAGING_SAGA, SET_DATA_PAGING_REDUCER, GET_DROPDOWN_PRODUCT_PROCESS_SAGA, GET_DROPDOWN_PRODUCT_PROCESS_REDUCER, GET_DROPDOWN_BOMSLOT_SAGA,
    GET_DROPDOWN_BOMSLOT_PROCESS_REDUCER, GET_DROPDOWN_SOP_SAGA, GET_DROPDOWN_SOP_PROCESS_REDUCER, CREATE_PROCESS_SAGA, CALL_CREATE_UPDATE_SUCCESS,
    GET_DETAIL_VERSION_PROCESS, SET_DETAIL_VERSION_PROCESS_REDUCER, GET_DROPDOWN_VERSION_PROCESS, SET_DROPDOWN_VERSION_PROCESS, IS_CALL_RENDER,
    DELETE_VERSION_PROCESS, DELETE_SUCCESS, CHANGE_VERSION_PROCESS_SAGA
} from "./enum/ProcessTypeAction.js";
const ProcessAction = {
    getDataPagingSaga: (pageIndex, pageSize, searchTerm = "") => {
        return {
            type: GET_DATA_PAGING_SAGA,
            PageIndex: pageIndex,
            PageSize: pageSize,
            SearchTerm: searchTerm,
        };
    },
    getDataPagingReducer: (data) => {
        return {
            type: SET_DATA_PAGING_REDUCER,
            data: data,
        };
    },
    getDropdownProduct: (request) => {
        return {
            type: GET_DROPDOWN_PRODUCT_PROCESS_SAGA,
            request: request,
        };
    },
    setDropdownProduct: (data) => {
        return {
            type: GET_DROPDOWN_PRODUCT_PROCESS_REDUCER,
            data: data,
        };
    },
    getDropdownBomslot: (productcode) => {
        return {
            type: GET_DROPDOWN_BOMSLOT_SAGA,
            productcode: productcode,
        };
    },
    setDropdownBomslot: (data) => {
        return {
            type: GET_DROPDOWN_BOMSLOT_PROCESS_REDUCER,
            data: data,
        };
    },
    getDropdownSop: (productcode) => {
        return {
            type: GET_DROPDOWN_SOP_SAGA,
            productcode: productcode,
        };
    },
    setDropdownSop: (data) => {
        return {
            type: GET_DROPDOWN_SOP_PROCESS_REDUCER,
            data: data,
        };
    },
    createProcess: (request) => {
        return {
            type: CREATE_PROCESS_SAGA,
            request: request,
        };
    },
    callAPICreateOrUpdateSuccess: () => {
        return {
            type: CALL_CREATE_UPDATE_SUCCESS,
        };
    },
    callDefault: () => {
        return {
            type: 'default',
        };
    },
    getDetailVersionProcess: (versionid) => {
        return {
            type: GET_DETAIL_VERSION_PROCESS,
            versionid: versionid,
        };
    },
    setDetailVersion: (obj) => {
        return {
            type: SET_DETAIL_VERSION_PROCESS_REDUCER,
            obj: obj,
        };
    },
    getDropdownVersionProcess: (id) => {
        return {
            type: GET_DROPDOWN_VERSION_PROCESS,
            id: id,
        };
    },
    setDropdownVersionProcess: (data) => {
        return {
            type: SET_DROPDOWN_VERSION_PROCESS,
            data: data,
        };
    },
    callRender: () => {
        return {
            type: IS_CALL_RENDER,
        }
    },
    deleteVersion: (id) => {
        return {
            type: DELETE_VERSION_PROCESS,
            id: id,
        };
    },
    callAPIDeleteSuccess: () => {
        return {
            type: DELETE_SUCCESS,
        };
    },
    changeProcessVersionSaga: (processid) => {
        return {
            type: CHANGE_VERSION_PROCESS_SAGA,
            processid: processid,
        };
    },
};
export default ProcessAction;
