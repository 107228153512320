import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_MASTER_DATA_BY_GROUP_CODE_SAGA,
  GET_APPROVAL_FLOW_TEMPLATE_SAGA,
  GET_LIST_USER_SAGA,
  //GET_LIST_ROLE_SAGA,
  GET_APPROVAL_FLOW_TEMPLATE_BY_ID_SAGA,
  GET_LIST_FOCAL_DEPARTMENT_G5_SAGA,
  GET_LIST_ASSET_CLASS_SAGA,
  GET_LIST_BP_THE_LEDGER_SAGA,
  GET_LIST_SEGMENT_FUND_CENTER_SAGA,
  GET_LIST_UNIT_SAGA,
  GET_LIST_STANDARD_ASSET_NAME_SAGA,
  GET_LIST_BUDGET_CI_SAGA,
  GET_LIST_EVALUATION_GROUP_1_SAGA,
  GET_LIST_EVALUATION_GROUP_2_SAGA,
  GET_LIST_EVALUATION_GROUP_3_SAGA,
  GET_LIST_EVALUATION_GROUP_4_SAGA,
  GET_LIST_EVALUATION_GROUP_5_SAGA,
  GET_ALL_FUND_CENTER_SAGA,
  GET_GROUP_BPC_SAGA,
  GET_LIST_VENDOR_SAGA,
  GET_LIST_ASSET_NUMBER_SUPPER_SAGA,
  GET_LIST_ALL_SEGMENT_SAGA,
  GET_LIST_COST_CENTER_BY_SEGMENT_SAGA,
  GET_LIST_INVENTORY_CODE_SAGA,
  GET_USER_SEGMENT_SAGA,
  GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_SAGA,
  GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_SAGA,
  GET_ALL_ASSET_SOURCE_SAGA,
  GET_ALL_SEGMENT_INVENTORY_PLAN_SAGA,
  GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_SAGA,
} from "./enum/TypeCommonDropdownVinmecAction";
import { closeLoading } from "src/shared/common/LoadingAction";
// import toastJs from "../../../shared/common/toastJs";
import CommonDropdownVinmecAction from "./CommonDropdownVinmecAction";
import {
  getAllMasterDataByGroupCodeService,
  getApprovalFlowTemplateService,
  getListUserService,
  //getListRoleService,
  getApprovalFlowTemplateByIdService,
  getListFocalDepartmentG5Service,
  getListBPOnTheLedgerService,
  getListAssetClassService,
  getListSegmentFundCenterService,
  getListStandardAssetNameService,
  getListBudgetCIService,
  getListEvaluationGroup_1_Service,
  getListEvaluationGroup_2_Service,
  getListEvaluationGroup_3_Service,
  getListEvaluationGroup_4_Service,
  getListEvaluationGroup_5_Service,
  getAllFundCenterService,
  getGroupBPCService,
  getListVendorService,
  getListAssetSuperNumberService,
  getListAllSegment,
  getListCostCenterbySegmentService,
  getListInventoryCodeService,
  getUserSegmentService,
  getAllEvaluationGroup5BySegmentService,
  getListSegmentFundCenterCodeRequestService,
  getAllAssetSourceService,
  getAllSegmentInventoryPlanService,
  getListCostCenterbySegmentFundCenterService,
} from "./CommonDropdownVinmecService";

export function* getAllMasterDataByGroupCodeSaga(data) {
  try {
    const response = yield call(
      getAllMasterDataByGroupCodeService,
      data.groupCode
    );
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getAllMasterDataByGroupCodeReducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getApprovalFlowTemplateSaga(data) {
  try {
    const strSearch = "?SearchTerm=" + data.searchTerm + "&Top=" + data.top;
    const response = yield call(getApprovalFlowTemplateService, strSearch);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getApprovalFlowTemplateReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListUserSaga(data) {
  try {
    const strSearch = "?SearchTerm=" + data.searchTerm + "&Top=" + data.top;

    const response = yield call(getListUserService, strSearch);
    if (response.isSuccess) {
      yield put(CommonDropdownVinmecAction.getListUserReducer(response));
    }
  } catch (error) {
    closeLoading();
  }
}
// export function* getListRoleSaga(data) {
//     try {
//         const strSearch = "?SearchTerm=" + data.searchTerm + "&Top=" + data.top;
//         const response = yield call(getListRoleService, strSearch);
//         if (response.isSuccess) {
//             yield put(CommonDropdownVinmecAction.getListRoleReducer(response));
//         }
//     } catch (error) {
//         closeLoading();
//     }
// }
export function* getApprovalFlowTemplateByIdSaga(data) {
  try {
    const response = yield call(getApprovalFlowTemplateByIdService, data.id);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getApprovalFlowTemplateByIdReducer(response)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListFocalDepartmentG5Saga() {
  try {
    const response = yield call(getListFocalDepartmentG5Service);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListFocalDepartmentG5Reducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListBPOnTheLedgerSaga(data) {
  try {
    let request = "";
    if (data.obj.SearchTerm == "") {
      request = "?Top=" + data.obj.Top;
    } else {
      request = "?SearchTerm=" + data.obj.SearchTerm + "&Top=" + data.obj.Top;
    }
    const response = yield call(getListBPOnTheLedgerService, request);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListBPOnTheLedgerReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListAssetClassSaga(data) {
  try {
    let request = "";
    if (data.obj.SearchTerm == "") {
      request = "?Top=" + data.obj.Top;
    } else {
      request = "?SearchTerm=" + data.obj.SearchTerm + "&Top=" + data.obj.Top;
    }
    const response = yield call(getListAssetClassService, request);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListAssetClassReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListSegmentFundCenterSaga() {
  try {
    const response = yield call(getListSegmentFundCenterService);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListSegmentFundCenterReducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getListStandardAssetNameSaga(data) {
  try {
    let request = "";
    if (data.obj.SearchTerm == "") {
      request = "?Top=" + data.obj.Top;
    } else {
      request = "?SearchTerm=" + data.obj.SearchTerm + "&Top=" + data.obj.Top;
    }
    const response = yield call(getListStandardAssetNameService, request);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListStandardAssetNameReducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListBudgetCISaga() {
  try {
    const response = yield call(getListBudgetCIService);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListBudgetCIReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListEvaluationGroup_1_Saga() {
  try {
    const response = yield call(getListEvaluationGroup_1_Service);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListEvaluationGroup_1_Reducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListEvaluationGroup_2_Saga() {
  try {
    const response = yield call(getListEvaluationGroup_2_Service);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListEvaluationGroup_2_Reducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListEvaluationGroup_3_Saga() {
  try {
    const response = yield call(getListEvaluationGroup_3_Service);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListEvaluationGroup_3_Reducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListEvaluationGroup_4_Saga() {
  try {
    const response = yield call(getListEvaluationGroup_4_Service);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListEvaluationGroup_4_Reducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListEvaluationGroup_5_Saga() {
  try {
    const response = yield call(getListEvaluationGroup_5_Service);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListEvaluationGroup_5_Reducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getAllFundCenterSaga() {
  try {
    const response = yield call(getAllFundCenterService);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getAllFundCenterReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getGroupBPCSaga(request) {
  try {
    let requestAPI = "";
    if (request?.data?.SearchTerm == "") {
      requestAPI = "?Top=" + request?.data?.Top;
    } else {
      requestAPI =
        "?SearchTerm=" +
        request?.data?.SearchTerm +
        "&Top=" +
        request?.data?.Top;
    }
    const response = yield call(getGroupBPCService, requestAPI);
    if (response.isSuccess) {
      yield put(CommonDropdownVinmecAction.getGroupBPCReducer(response.data));
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListVendorSaga() {
  try {
    const response = yield call(getListVendorService);
    if (response.isSuccess) {
      yield put(CommonDropdownVinmecAction.getListVendorReducer(response.data));
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListAssetSuperNumberSaga() {
  try {
    const response = yield call(getListAssetSuperNumberService);
    if (response) {
      yield put(
        CommonDropdownVinmecAction.getListAssetSuperNumberReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListAllSegmentSaga() {
  try {
    const response = yield call(getListAllSegment);
    if (response) {
      yield put(
        CommonDropdownVinmecAction.getListAllSegmentReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getListCostCenterbySegmentSaga(SegmentId) {
  try {
    const response = yield call(getListCostCenterbySegmentService, SegmentId);
    if (response) {
      yield put(
        CommonDropdownVinmecAction.getListCostCenterbySegmentReducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListCostCenterbySegmentFundCenterSaga(SegmentId) {
  try {
    const response = yield call(
      getListCostCenterbySegmentFundCenterService,
      SegmentId
    );
    if (response) {
      yield put(
        CommonDropdownVinmecAction.getListCostCenterbySegmentFundCenterReducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getListInventoryCodeSaga(data) {
  try {
    const response = yield call(getListInventoryCodeService, data.obj);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListInventoryCodeReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getUserSegmentSaga() {
  try {
    const response = yield call(getUserSegmentService);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getUserSegmentReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getAllEvaluationGroup5BySegmentSaga(request) {
  try {
    // let segmentId = Number(request.segmentId);
    const response = yield call(
      getAllEvaluationGroup5BySegmentService,
      request?.segmentId
    );
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getAllEvaluationGroup5BySegmentReducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getListSegmentFundCenterCodeRequestSaga() {
  try {
    const response = yield call(getListSegmentFundCenterCodeRequestService);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getListSegmentFundCenterCodeRequestReducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getAllAssetSourceSaga() {
  try {
    const response = yield call(getAllAssetSourceService);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getAllAssetSourceReducer(response.data)
      );
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getAllSegmentInventoryPlanSaga() {
  try {
    const response = yield call(getAllSegmentInventoryPlanService);
    if (response.isSuccess) {
      yield put(
        CommonDropdownVinmecAction.getAllSegmentInventoryPlanReducer(
          response.data
        )
      );
    }
  } catch (error) {
    closeLoading();
  }
}

export default function* CommonDropdownVinmecSaga(data) {
  yield takeEvery(
    GET_ALL_MASTER_DATA_BY_GROUP_CODE_SAGA,
    getAllMasterDataByGroupCodeSaga
  );
  yield takeEvery(GET_APPROVAL_FLOW_TEMPLATE_SAGA, getApprovalFlowTemplateSaga);
  yield takeEvery(GET_LIST_USER_SAGA, getListUserSaga);
  //yield takeEvery(GET_LIST_ROLE_SAGA, getListRoleSaga);
  yield takeEvery(
    GET_APPROVAL_FLOW_TEMPLATE_BY_ID_SAGA,
    getApprovalFlowTemplateByIdSaga
  );
  yield takeEvery(
    GET_LIST_FOCAL_DEPARTMENT_G5_SAGA,
    getListFocalDepartmentG5Saga
  );
  yield takeEvery(GET_LIST_BP_THE_LEDGER_SAGA, getListBPOnTheLedgerSaga);
  yield takeEvery(GET_LIST_ASSET_CLASS_SAGA, getListAssetClassSaga);
  yield takeEvery(
    GET_LIST_SEGMENT_FUND_CENTER_SAGA,
    getListSegmentFundCenterSaga
  );
  yield takeEvery(
    GET_LIST_STANDARD_ASSET_NAME_SAGA,
    getListStandardAssetNameSaga
  );
  yield takeEvery(GET_LIST_BUDGET_CI_SAGA, getListBudgetCISaga);
  yield takeEvery(
    GET_LIST_EVALUATION_GROUP_1_SAGA,
    getListEvaluationGroup_1_Saga
  );
  yield takeEvery(
    GET_LIST_EVALUATION_GROUP_2_SAGA,
    getListEvaluationGroup_2_Saga
  );
  yield takeEvery(
    GET_LIST_EVALUATION_GROUP_3_SAGA,
    getListEvaluationGroup_3_Saga
  );
  yield takeEvery(
    GET_LIST_EVALUATION_GROUP_4_SAGA,
    getListEvaluationGroup_4_Saga
  );
  yield takeEvery(
    GET_LIST_EVALUATION_GROUP_5_SAGA,
    getListEvaluationGroup_5_Saga
  );
  yield takeEvery(GET_ALL_FUND_CENTER_SAGA, getAllFundCenterSaga);
  yield takeEvery(GET_GROUP_BPC_SAGA, getGroupBPCSaga);
  yield takeEvery(GET_LIST_VENDOR_SAGA, getListVendorSaga);
  yield takeEvery(
    GET_LIST_ASSET_NUMBER_SUPPER_SAGA,
    getListAssetSuperNumberSaga
  );
  yield takeEvery(GET_LIST_ALL_SEGMENT_SAGA, getListAllSegmentSaga);
  yield takeEvery(
    GET_LIST_COST_CENTER_BY_SEGMENT_SAGA,
    getListCostCenterbySegmentSaga
  );
  yield takeEvery(
    GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_SAGA,
    getListCostCenterbySegmentFundCenterSaga
  );
  yield takeEvery(GET_LIST_INVENTORY_CODE_SAGA, getListInventoryCodeSaga);
  yield takeEvery(GET_USER_SEGMENT_SAGA, getUserSegmentSaga);
  yield takeEvery(
    GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_SAGA,
    getAllEvaluationGroup5BySegmentSaga
  );
  yield takeEvery(
    GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_SAGA,
    getListSegmentFundCenterCodeRequestSaga
  );
  yield takeEvery(GET_ALL_ASSET_SOURCE_SAGA, getAllAssetSourceSaga);
  yield takeEvery(
    GET_ALL_SEGMENT_INVENTORY_PLAN_SAGA,
    getAllSegmentInventoryPlanSaga
  );
}
