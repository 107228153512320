import axios from "axios";

/**
 *
 * parse error response
 */
function parseError(messages) {
  // error
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject({ messages: messages });
    } else {
      return Promise.reject({ messages: [messages] });
    }
  } else {
    return Promise.reject({ messages: ["error"] });
  }
}

/**
 * axios instance
 */
let instance = axios.create({});

// request header
instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access_token");;
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
        "Content-Type": config.type ? config.type : "application/json",
      };
    } else {
      config.headers = {
        Accept: "application/json",
        "Content-Type": config.type ? config.type : "application/json",
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response parse
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error)
  }
);
export const httpCommon = instance;

