import * as type from "./enum/ProductPackagingTypeAction";
const stateDefault = {
    listData: [],
    dropdownProductPackaging: [],
    dropdownPackingLevel: [],
    packageDetail: null,
    listBelong: {},
    isCallSuccessFromApiReducer: false,
    callResultFromApiReducer: false,
    callFalseFromApiReducer: false,
}
const ProductPackagingReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case type.SET_DROPDOWN_PRODUCT_PACKAGING_REDUCER:
            if (action.data) {
                state.dropdownProductPackaging = action.data;
            }
            return {
                ...state,
            };
        case type.SET_DROPDOWN_PACKING_LEVEL_REDUCER:
            if (action.data) {
                state.dropdownPackingLevel = action.data;
            }
            return {
                ...state,
            }
        case type.SET_PACKAGE_DETAIL_REDUCER:
            if (action.data) {
                state.packageDetail = action.data;
            }
            return {
                ...state,
            }
        case type.SET_BELONG_INFO_REDUCER: 
            if (action.data) {
                state.listBelong = action.data;
            }
            return {
                ...state,
            }
        case type.CALL_SUCCESS_FROM_API_REDUCER:
            state.isCallSuccessFromApiReducer = true;
            return {
                ...state,
            }
        case type.CALL_DEFAULT_SUCCES_REDUCER:
            state.isCallSuccessFromApiReducer = false;
            return {
                ...state,
            }
        case type.CALL_RESULT_FROM_API_REDUCER:
            state.callResultFromApiReducer = true;
            return {
                ...state,
            }
        case type.CALL_FALSE_FROM_API_REDUCER:
            state.callFalseFromApiReducer = true;
            return {
                ...state,
            }
        case type.CALL_DEFAULT_PRODUCT_PACKAGING_REDUCER:
            state.callResultFromApiReducer = false;
            state.callFalseFromApiReducer = false;
            return {
                ...state,
            }
        case type.CLER_DATA_REDUCER:
            return {
                ...stateDefault,
            }
        default:
            return {
                ...state,
            };
    }
}
export default ProductPackagingReducer