import _ from "lodash";
import queryString from "query-string";
import * as DOMPurify from "dompurify";
import StringUtils from "../common/StringUtils";
import { isTwoArrayEqual } from "./arrray";

export const getValidOptions = (optionsMap, key) => {
  if (!Array.isArray(optionsMap?.[key])) {
    return [];
  }

  return optionsMap[key];
};

export const getNotNilData = (data, excludesEmpty) => {
  const newData = {};
  const keys = Object.keys(data);
  for (let key of keys) {
    if (excludesEmpty && data[key] === "") {
      continue;
    }

    if (!_.isNil(data[key])) {
      newData[key] = data[key];
    }
  }

  return newData;
};

export const getRequestParams = (data) => {
  const result = queryString.stringify(getNotNilData(data, true));
  if (result.trim() === "") {
    return "";
  }
  return `?${result}`;
};

export const getDataFromKeyParts = (obj, keyParts) => {
  let result = null;
  let container = obj;
  for (let i = 0; i < keyParts.length; i++) {
    const keyPart = keyParts[i];
    result = container?.[keyPart];
    if (_.isNil(result)) {
      return undefined;
    }

    container = result;
  }

  return result;
};

export const filterByLabel = (
  inputKeyword,
  option,
  sensitive = false,
  exact = false
) => {
  let keyword = inputKeyword?.toString() ?? "";
  let label = option?.children ?? option?.label ?? option?.value ?? "";

  if (!exact) {
    keyword = StringUtils.removeAccents(keyword);
    label = StringUtils.removeAccents(label);
  }

  if (!sensitive) {
    keyword = keyword.toLowerCase();
    label = label.toLowerCase();
  }

  return label.includes(keyword);
};

export const formatNumber = (value) => {
  if (_.isNil(value)) {
    return "";
  }

  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const searchString = (inputArg, searchArg, sensitive = false) => {
  if (_.isNil(searchArg)) {
    return false;
  }

  const inputString = getValidString(inputArg);
  const searchString = getValidString(searchArg);

  if (sensitive) {
    return inputString.includes(searchString);
  }
  return inputString.toLowerCase().includes(searchString.toLowerCase());
};

export const getTableNth = ({ pageIndex, pageSize, index }) => {
  return (pageIndex - 1) * pageSize === 0
    ? index + 1
    : (pageIndex - 1) * pageSize + 1 + index;
};

export const getRealIndex = ({ pageIndex, pageSize, index }) => {
  return (pageIndex - 1) * pageSize === 0
    ? index
    : (pageIndex - 1) * pageSize + index;
};

export const decodeHTMLEntities = (input) => {
  if (typeof input !== "string") {
    return "";
  }
  const txt = new DOMParser().parseFromString(input, "text/html");
  return txt.documentElement.textContent;
};

export const getSafeHTML = (dirty) => {
  return DOMPurify.sanitize(dirty, { ADD_ATTR: ["target"] });
};

export const getApiErrorMessage = (
  err,
  defaultErrorMessage = "Đã có lỗi xảy ra."
) => {
  return err?.response?.data?.message ?? err?.message ?? defaultErrorMessage;
};

export const convertBinaryToBase64 = (rawData, mimeType = "") => {
  const base64 = `data:${mimeType};base64,${btoa(
    unescape(encodeURIComponent(rawData))
  )}`;
  return base64;
};

export const encodeStringParam = (inputValue) => {
  if (typeof inputValue !== "string") {
    return "";
  }

  return inputValue;
};

export const decodeStringParam = (inputValue) => {
  if (typeof inputValue !== "string") {
    return "";
  }

  return inputValue;
};

export const encodeArrayParam = (inputValue) => {
  if (!Array.isArray(inputValue)) {
    return null;
  }

  return inputValue.join(",");
};

export const decodeArrayParam = (inputValue, isNumberArray) => {
  if (typeof inputValue !== "string") {
    return [];
  }

  const outputArray = inputValue.split(",");
  if (isNumberArray) {
    return outputArray.map((item) => {
      const parsedValue = parseInt(item);
      return isNaN(parsedValue) ? item : parsedValue;
    });
  }
  return outputArray;
};

export const encodeDateParam = (inputValue) => {
  const outputValue = Date.parse(inputValue);

  if (isNaN(outputValue)) {
    return null;
  }
  return outputValue;
};

export const decodeIntegerParam = (inputValue) => {
  const outputValue = parseInt(inputValue);

  if (isNaN(outputValue)) {
    return null;
  }
  return outputValue;
};

export const decodeDateParam = (inputValue) => {
  if (typeof inputValue !== "string") {
    return null;
  }

  const parsedValue = parseInt(inputValue);
  if (isNaN(parsedValue)) {
    return null;
  }

  return new Date(parsedValue);
};

export const calculateTotalPages = ({ perPage, totalItems }) => {
  return Math.ceil(totalItems / perPage);
};

// pageIndex start from 1
export const getPaginationData = ({ pageIndex, pageSize, data }) => {
  const startIndex = (pageIndex - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const slicedData = data.slice(startIndex, endIndex);
  return slicedData;
};

export function debouncePromise(fn, delay) {
  let timeoutId;

  return function (...args) {
    return new Promise((resolve, reject) => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(async () => {
        try {
          const result = await fn(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };
}

export const getCompositeLabel = (value, label) => {
  return `${value} - ${label}`;
};

export const getValidString = (input) => {
  return input?.toString() ?? "";
};

export const getFormDataSource = ({ pageIndex, pageSize, data }) => {
  const formDataSource = [];
  const startIndex = (pageIndex - 1) * pageSize;
  let scanIndex = 0;
  let matchedCount = 0;

  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (!item.isShow) {
      formDataSource.push({ ...item, isShow: false, CustomNth: "" });
      continue;
    }

    scanIndex++;

    if (scanIndex < startIndex + 1) {
      formDataSource.push({ ...item, isShow: false, CustomNth: "" });
      continue;
    }

    matchedCount++;

    if (matchedCount > pageSize) {
      formDataSource.push({ ...item, isShow: false, CustomNth: "" });
      continue;
    }

    formDataSource.push({
      ...item,
      isShow: true,
      CustomNth: scanIndex,
    });
  }

  return formDataSource;
};

export const arrayHasElements = (input) => {
  return !!input?.length;
};

export const trimEachLineTextArea = ({ value, separator = "\n" }) => {
  const lines = value.split(separator);
  const trimmedLines = [];
  lines.forEach((line) => {
    const trimmedLine = line.trim();
    if (trimmedLine !== "") {
      trimmedLines.push(trimmedLine);
    }
  });
  return trimmedLines.join(separator);
};

export const formatAssetLine = ({
  line,
  regex = /(\s*-+\s*)|(\s+)/,
  newValue = "-",
}) => {
  const trimmedLine = line.trim();
  return trimmedLine.replace(regex, newValue);
};

export const formatInputAssets = ({
  value,
  separator = "\n",
  formatLine = formatAssetLine,
}) => {
  const lines = value
    .split(separator)
    .map((line) => line.trim())
    .filter((line) => line !== "");
  const uniqueLines = _.uniq(lines);
  const formattedLines = uniqueLines.map((line) => {
    return formatLine({ line });
  });

  return formattedLines.join(separator);
};

export const findItemByLabel = ({ options, label }) => {
  return options.find((option) => option.label.includes(label));
};

export const formatBatchTextAreaLine = ({ line }) => {
  return line.trim();
};

export const formatBatchTextArea = ({
  value,
  separator = "\n",
  formatLine = formatBatchTextAreaLine,
}) => {
  const lines = value
    .split(separator)
    .map((line) => line.trim())
    .filter((line) => line !== "");
  const uniqueLines = _.uniq(lines);
  const formattedLines = uniqueLines.map((line) => {
    return formatLine({ line });
  });

  return formattedLines.join(separator);
};

export const valueInOptions = ({value, options}) => {
  if (_.isNil(value) || !Array.isArray(options)) {
    return false;
  }

  const matchedOptionIndex = options.findIndex(option => option.value.toString() === value.toString());

  return matchedOptionIndex !== -1;
}

export const areAllItemsSelected = ({ allOptions, selectedValues }) => {
  const allValues = allOptions.map((segmentOption) => segmentOption.value);

  if (allValues.length === 0 || allOptions.length === 0) {
    return false;
  }

  return isTwoArrayEqual(allValues, selectedValues);
};

export const chainInfoArray = (infoArray, separator = " - ") => {
  if (!Array.isArray(infoArray)) {
    return "";
  }

  const filteredArray = infoArray.filter(item => {
    return !_.isNil(item);
  });

  return filteredArray.join(separator);
}