import {
    SET_CODE_MMPRODUCTION_REDUCER, SET_CODE_MATERIAL_REDUCER, SET_CODE_MAINTANCE_REDUCER
} from "./enum/TypeCommonGetCodeAction";
const CommonGetCodeReducer = (
    state = {
        listCodeMMProduction: [],
        listCodeMaterial: [],
        listCodeMaintance: [],
    },
    action
) => {
    let listCodeNew = [];
    switch (action.type) {
        case SET_CODE_MMPRODUCTION_REDUCER:
            listCodeNew = state.listCodeMMProduction;
            listCodeNew = listCodeNew.filter(m => m.typeGetCode != action.typeGetCode);
            listCodeNew.push({ typeGetCode: action.typeGetCode, code: action.code, key: action.key });
            state.listCodeMMProduction = listCodeNew;
            return {
                ...state,
            };
        case SET_CODE_MATERIAL_REDUCER:
            listCodeNew = state.listCodeMaterial;
            listCodeNew = listCodeNew.filter(m => m.typeGetCode != action.typeGetCode);
            listCodeNew.push({ typeGetCode: action.typeGetCode, code: action.code, key: action.key });
            state.listCodeMaterial = listCodeNew;
            return {
                ...state,
            };
        case SET_CODE_MAINTANCE_REDUCER:
            listCodeNew = state.listCodeMaintance;
            listCodeNew = listCodeNew.filter(m => m.typeGetCode != action.typeGetCode);
            listCodeNew.push({ typeGetCode: action.typeGetCode, code: action.code, key: action.key });
            state.listCodeMaintance = listCodeNew;
            return {
                ...state,
            };
        case 'default':
            state.listCodeMMProduction = [];
            return {
                ...state,
            };
        default:
            return {
                ...state,
            };
    }
};

export default CommonGetCodeReducer;
