import {
    SET_DATA_PAGING_REDUCER, GET_DROPDOWN_PRODUCT_PROCESS_REDUCER, GET_DROPDOWN_BOMSLOT_PROCESS_REDUCER, GET_DROPDOWN_SOP_PROCESS_REDUCER, CALL_CREATE_UPDATE_SUCCESS,
    SET_DETAIL_VERSION_PROCESS_REDUCER, SET_DROPDOWN_VERSION_PROCESS, IS_CALL_RENDER, DELETE_SUCCESS
} from "./enum/ProcessTypeAction";
const stateDefault = {
    listData: {},
    dropdownProduct: [],
    dropdownBomslot: [],
    dropdownSop: [],
    isCallUpdateOrUpdate: false,
    objData: {},
    dropdownVersionProcess: [],
    changeData: false,
    deleteSuccess: false,
    isLoading: true,
}
const ProcessReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case SET_DATA_PAGING_REDUCER:
            if (action.data) {
                state.isLoading = false;
                state.listData = action.data;
            }
            return {
                ...state,
            };
        case GET_DROPDOWN_PRODUCT_PROCESS_REDUCER:
            if (action.data) {
                state.dropdownProduct = action.data;
            }
            return {
                ...state,
            };
        case GET_DROPDOWN_BOMSLOT_PROCESS_REDUCER:
            if (action.data) {
                state.dropdownBomslot = action.data;
            }
            return {
                ...state,
            };
        case GET_DROPDOWN_SOP_PROCESS_REDUCER:
            if (action.data) {
                state.dropdownSop = action.data;
            }
            return {
                ...state,
            };
        case CALL_CREATE_UPDATE_SUCCESS:
            state.isCallUpdateOrUpdate = true;
            return {
                ...state,
            };
        case SET_DETAIL_VERSION_PROCESS_REDUCER:
            state.objData = action.obj;
            return {
                ...state,
            };
        case SET_DROPDOWN_VERSION_PROCESS:
            state.dropdownVersionProcess = action.data;
            return {
                ...state,
            };
        case IS_CALL_RENDER:
            state.changeData = true;
            return {
                ...state,
            };
        case DELETE_SUCCESS:
            state.deleteSuccess = true;
            return {
                ...state,
            };
        case 'default':
            state.isLoading = true;
            state.deleteSuccess = false;
            state.isCallUpdateOrUpdate = false;
            state.changeData = false;
            return {
                ...state,
            };
        default:
            return {
                ...state,
            };
    }
};

export default ProcessReducer;
