export const GET_ALL_BOM_API = "/api/production-management-service/bom-manage/bom-get-paging";
export const CREATE_BOM_API = "/api/production-management-service/bom-manage/create-bom";
export const GET_DROPDOWN_PRODUCT_API = "/api/production-management-service/bom-manage/get-dropdown-product-non-bom/";
export const GET_DETAIL_BOM_API = "/api/production-management-service/bom-manage/bom-get-detail/";
export const GET_DROPDOWN_VERSION_BOM_API = "/api/production-management-service/bom-manage/bom-get-list-version/";
export const DELETE_VERSION_BOM_API = "/api/production-management-service/bom-manage/delete-bomb-version/";
export const ADD_VERSION_BOM_API = "/api/production-management-service/bom-manage/create-version-bom";
export const CHANGE_VERSION_BOM_API = "/api/production-management-service/bom-manage/change-bomb-version/";
export const GET_DESCRIPTION_MATERIAL_API = "/api/material-management-service/material/get-list-detail-marterial";












