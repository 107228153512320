import {
    SET_DROPDOWN_WORKORDER_REDUCER, CALL_API_EFORCEMENT_SUCCESS_REDUCER, SET_DATA_PAGING_PRODUCT_IDENTIFIER_REDUCER,
    SET_DETAIL_PRODUCT_INDENTIER_REDUCER, SET_LIST_DETAIL_PAGING_REDUCER, REPRINT_STAMPS_SUCCESS
} from "./enum/ProductIdentifierTypeAction";

const ProductIdentifierReducer = (
    state = {
        dropdownWorkorder: [],
        isCallDropdownWorkorder: false,
        isCallEnforcementSuccess: false,
        listData: {},
        detailObj: {},
        listDetailObj: {},
        reprintStampsSuccess: false,
        isLoading: false,
    },
    action
) => {
    switch (action.type) {
        case SET_DROPDOWN_WORKORDER_REDUCER:
            if (action.list) {
                state.dropdownWorkorder = action.list;
                state.isCallDropdownWorkorder = true;
            }
            return {
                ...state,
            };
        case CALL_API_EFORCEMENT_SUCCESS_REDUCER:
            state.isCallEnforcementSuccess = true;
            return {
                ...state,
            };
        case SET_DATA_PAGING_PRODUCT_IDENTIFIER_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.isLoading = false;
            }
            return {
                ...state,
            };
        case SET_DETAIL_PRODUCT_INDENTIER_REDUCER:
            if (action.data) {
                state.detailObj = action.data;
            }
            return {
                ...state,
            };
        case SET_LIST_DETAIL_PAGING_REDUCER:
            if (action.data) {
                state.listDetailObj = action.data;
                state.isLoading = false;
            }
            return {
                ...state,
            };
        case REPRINT_STAMPS_SUCCESS:
            state.reprintStampsSuccess = true;
            return {
                ...state,
            };
        case "default":
            state.isCallDropdownWorkorder = false;
            state.isCallEnforcementSuccess = false;
            state.reprintStampsSuccess = false;
            state.isLoading = true;
            return {
                ...state,
            };
        default:
            return {
                ...state
            };
    }
};

export default ProductIdentifierReducer;
