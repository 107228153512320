import {
  SAVE_LIST_ASSETMENT_TRANSFER,
  SAVE_DETAIL_ASSETMENT_TRANSFER,
  CLEAR_DETAIL_ASSETMENT_TRANSFER,
  DELETE_ASSETMENT_TRANSFER_REDUCER
} from "./enum/TypeAssetmentTransferAction";

const stateDefault = {
  listData: [],
  isGetLstData: false,
  detailData: null,
  isGetDetailData: false,
  isOpenStampModal: false,
  isDeleteData: false
};
const AssetmentTransferReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case SAVE_LIST_ASSETMENT_TRANSFER:
      if (action.data) {
        state.listData = action.data;
      }
      return { ...state };
    case SAVE_DETAIL_ASSETMENT_TRANSFER:
      if (action.data) {
        state.detailData = action.data;
      }
      return { ...state };

    case DELETE_ASSETMENT_TRANSFER_REDUCER:
      state.isDeleteData = true;
      return { ...state };

    case CLEAR_DETAIL_ASSETMENT_TRANSFER:
      state.detailData = null;
      state.isDeleteData = false;
      return { ...state };
    default:
      return { ...state };
  }
};

export default AssetmentTransferReducer;
