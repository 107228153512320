import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
    GET_LIST_EXPORT_USAGE_API,
    DELETE_EXPORT_USAGE_API,
    CREATE_EXPORT_USAGE_API,
    UPDATE_EXPORT_USAGE_API,
    GET_DETAIL_EXPORT_USAGE_API,
    CANCEL_EXPORT_USAGE_API,
    GET_PATIENT_INFO_API,
    GET_PURCHASE_ORDER_BY_CODE_API,
    GET_BY_ID_API,
} from "./enum/TypeExportUsageAPI";
import { toast } from "react-toastify";

export async function getListExportUsageService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_LIST_EXPORT_USAGE_API,
        data
    )
}
export async function createExportUsageService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_EXPORT_USAGE_API,
        data
    )
}
export async function updateExportUsageService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_EXPORT_USAGE_API,
        data
    )
}
export async function cancelExportUsageService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CANCEL_EXPORT_USAGE_API +
        "?Id=" + request?.transactionId, request?.lstDetailCancel
    )
}
export async function deleteExportUsageService(deleteId) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        DELETE_EXPORT_USAGE_API +
        "?Id=" + deleteId
    )
}
export async function getByIdService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_BY_ID_API +
        "?Id=" +  request.transactionId 
    )
}
export async function getDetailExportUsageService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_DETAIL_EXPORT_USAGE_API +
        "?Id=" + request.transactionId + "&&visitCode=" + request.visitCode
    )
}
export async function getPatientInfoService(patientId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_PATIENT_INFO_API +
        "?patientId=" + patientId
    )
}
export async function getPurchaseOrderByCodeService(request){
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_PURCHASE_ORDER_BY_CODE_API + "?poid=" + request 
    )
}