import {
    GET_PAGING_CONFIG_PLANNING_SAGA,
    GET_PAGING_CONFIG_PLANNING_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    CREATE_CONFIG_PLANNING_SAGA,
    UPDATE_CONFIG_PLANNING_SAGA,
    CREATE_CONFIG_PLANNING_REDUCER,
    UPDATE_CONFIG_PLANNING_REDUCER,
    GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_SAGA,
    GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_REDUCER
} from "./enum/TypeConfigPlanningAction";

const ConfigPlanningAction = {
    getPagingSaga: (pageIndex, pageSize, searchTerm = "") => {
        return {
            type: GET_PAGING_CONFIG_PLANNING_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,

        };
    },
    
    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_CONFIG_PLANNING_REDUCER,
            data: data,
        };
    },
    createDataSaga: (data) => {
        return {
          type: CREATE_CONFIG_PLANNING_SAGA,
          data: data,
        };
      },
      createDataReducer: (data) => {
        return {
          type: CREATE_CONFIG_PLANNING_REDUCER,
          data: data,
        };
      },
      updateDataSaga: (data) => {
        return {
          type: UPDATE_CONFIG_PLANNING_SAGA,
          data: data,
        };
      },
      updateDataReducer: (data) => {
        return {
          type: UPDATE_CONFIG_PLANNING_REDUCER,
          data: data,
        };
      },
      getDetailConfigPlanningByHospitalIdSaga: (data) => {
        return {
          type: GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_SAGA,
          data: data,
        };
      },
      getDetailConfigPlanningByHospitalIdReducer: (data) => {
        return {
          type: GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_REDUCER,
          data: data,
        };
      },
    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER,
        };
    },

}

export default ConfigPlanningAction;
