import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import * as type from "./enum/enumApi";

export async function actionService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.ACTION_API, data);
}
export async function checkActionService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.CHECK_ACTION_API, data);
}
export async function getDetailWoService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DETAIL_WO_API, data);
}
export async function updateWoService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.UPDATE_WO_API, data);
}
export async function getPagingService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + type.GET_PAGING_DATA_API,
        data
    )
};