export const GET_COMMON_DROPDOWN_MMPRODUCTION = "GET_COMMON_DROPDOWN_MMPRODUCTION";
export const SET_COMMON_DROPDOWN_MMPRODUCTION = "SET_COMMON_DROPDOWN_MMPRODUCTION";

export const GET_COMMON_DROPDOWN_MATERIAL = "GET_COMMON_DROPDOWN_MATERIAL";
export const SET_COMMON_DROPDOWN_MATERIAL = "SET_COMMON_DROPDOWN_MATERIA";

export const GET_COMMON_DROPDOWN_MATAINER = "GET_COMMON_DROPDOWN_MATAINER";
export const SET_COMMON_DROPDOWN_MATAINER = "SET_COMMON_DROPDOWN_MATAINER";

export const GET_WORK_COMMON_DROPDOWN_BY_CODE_SAGA = "GET_WORK_COMMON_DROPDOWN_BY_CODE_SAGA";
export const GET_WORK_COMMON_DROPDOWN_BY_CODE_REDUCER = "GET_WORK_COMMON_DROPDOWN_BY_CODE_REDUCER";