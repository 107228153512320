import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { GET_ALL_SOP_API,CREATE_SOP_API ,UPDATE_SOP_API, GET_DETAIL_SOP_API, CHANGE_VERSION_SOP_API,GET_DROPDOWN_VERSION_API,DELETE_VERSION_API} from "./enum/SOPTypeApi";

export async function getPagingService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_ALL_SOP_API,
    data
  );
}
export async function createDataService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_SOP_API,
    data
  );
}
export async function updateDataService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_SOP_API,
    data
  );
}
export async function getDetailService(sopId) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_DETAIL_SOP_API + sopId,
    ""
  );
}
export async function changeVersionService(sopId) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + CHANGE_VERSION_SOP_API + sopId,
    ""
  );
}
export async function getDropdownVersionService(sopId) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_DROPDOWN_VERSION_API + sopId,
    ""
  );
}
export async function deleteVersionService(versionId) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + DELETE_VERSION_API + versionId,
    ""
  );
}



