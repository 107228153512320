import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_PAGING_PRODUCT_API,
  GET_PAGING_PRODUCT_BY_ROLE_USER_API,
  DELETE_PRODUCT_API,
  CREATE_PRODUCT_API,
  UPDATE_PRODUCT_API,
  GET_LIST_DATA_BY_UNITCODE_API,GET_LIST_PRODUCT_API,GET_LIST_PRODUCT_NOT_IN_BARCODE_API
} from "./enum/TypeCatProductApi.js";

export async function getListDataProductByUnitCodeService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_DATA_BY_UNITCODE_API + "?unitCode=" + data
  );
}
export async function getPagingService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_PAGING_PRODUCT_API,
    data
  );
}

//Hàm lấy api danh sách VTYT có phân quyền dữ liệu
//TrungLQ(20/2/2024)
export async function getPagingByRoleUserService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_PAGING_PRODUCT_BY_ROLE_USER_API,
    data
  );
}

export async function deleteCatProductService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_PRODUCT_API + data
  );
}
export async function updateCatProductService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_PRODUCT_API,
    data
  );
}
export async function createCatProductService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_PRODUCT_API,
    data
  );
}

//thieulx lấy all danh sách vtyt ko phân trang
export async function getListCatProductService(data) {
  return await CommonBase.postAsync(
      configApp.apiGateWay +
      GET_LIST_PRODUCT_API,
      data
  )
}

export async function getListCatProductNotInBarcodeService(data) {
  return await CommonBase.postAsync(
      configApp.apiGateWay +
      GET_LIST_PRODUCT_NOT_IN_BARCODE_API,
      data
  )
}