import {
    CALL_DEFAULT_BOM_REDUCER,
    CREATE_BOM_SAGA,
    GET_DETAIL_BOM_REDUCER,
    GET_PAGING_BOM_REDUCER,
    GET_PAGING_BOM_SAGA,
    CALL_RESULT_BOM_REDUCER,
    GET_DETAIL_BOM_SAGA,
    GET_DROPDOWN_PRODUCT_SAGA,
    GET_DROPDOWN_VERSION_BOM_SAGA,
    ADD_NEW_VESION_BOM_SAGA,
    CHANGE_VERSION_BOM_SAGA,
    SET_DROPDOWN_VERSION_BOM_REDUCER,
    SET_DROPDOWN_PRODUCT_REDUCER,
    DELETED_VERSION_BOM_SAGA,
    DELETE_VERSION_BOM_REDUCER,
    CHANGE_DATA_BOM_REDUCER,
    CALL_CHANGE_VERSION_BOM_REDUCER,
    GET_DESCRIPTION_MATERIAL_SAGA,
    SET_DESCRIPTION_MATERIAL_REDUCER,
    ADD_MORE_VERSION_BOM_REDUCER
} from './enum/BOMTypeAction'

const BomAction = {
    getPagingSaga: (pageIndex, pageSize, searchTerm) => {
        return {
            type: GET_PAGING_BOM_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
        }
    },
    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_BOM_REDUCER,
            data: data
        };
    },

    createDataSaga: (data) => {
        return {
            type: CREATE_BOM_SAGA,
            data: data
        };
    },

    getDetailSaga: (bomCode, versionId, searchItem) => {
        return {
            type: GET_DETAIL_BOM_SAGA,
            bomCode: bomCode,
            versionId: versionId,
            searchItem: searchItem
        };
    },
    returnDetailReducer: (obj) => {
        return {
            type: GET_DETAIL_BOM_REDUCER,
            obj: obj,
        }
    },
    callResultFromApiReducer: () => {
        return {
            type: CALL_RESULT_BOM_REDUCER
        }
    },
    getDropdownProduct: (searchtemp) => {
        return {
            type: GET_DROPDOWN_PRODUCT_SAGA,
            searchtemp: searchtemp
        }
    },
    setDropdownProduct: (data) => {
        return {
            type: SET_DROPDOWN_PRODUCT_REDUCER,
            data: data,

        }
    },
    getDropdownVersion: (bomCode) => {
        return {
            type: GET_DROPDOWN_VERSION_BOM_SAGA,
            bomCode: bomCode,
        }
    },
    getDropdownVersionReducer: (data) => {
        return {
            type: SET_DROPDOWN_VERSION_BOM_REDUCER,
            data: data,
        };
    },
    deletedVersionSaga: (versionId) => {
        return {
            type: DELETED_VERSION_BOM_SAGA,
            versionId: versionId,
        }
    },
    deleteVersionReducer: () => {
        return {
            type: DELETE_VERSION_BOM_REDUCER,
        }
    },
    setChangedata: () => {
        return {
            type: CHANGE_DATA_BOM_REDUCER,
        }
    },
    addNewVersionSaga: (data, bomCode, bomName, dataCondition) => {
        return {
            type: ADD_NEW_VESION_BOM_SAGA,
            data: data,
            bomCode: bomCode,
            bomName: bomName,
            dataCondition: dataCondition
        }
    },
    changeVersion: (bomCode) => {
        return {
            type: CHANGE_VERSION_BOM_SAGA,
            bomCode: bomCode,
        }
    },
    callChangeVersionReducer: () => {
        return {
            type: CALL_CHANGE_VERSION_BOM_REDUCER,
        }
    },
    getDescriptionMarterial: (listMachineCode) => {
        return {
            type: GET_DESCRIPTION_MATERIAL_SAGA,
            listMachineCode: listMachineCode
        }
    },
    setDescriptionMarterialReducer: (data) => {
        return {
            type: SET_DESCRIPTION_MATERIAL_REDUCER,
            data: data,
        }
    },
    callResultAddVersionReducer: () => {
        return {
            type: ADD_MORE_VERSION_BOM_REDUCER,
        }
    },
    callDefault: () => {
        return {
            type: CALL_DEFAULT_BOM_REDUCER,
        }
    }
}
export default BomAction
