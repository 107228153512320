import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { GET_ALL_ITEM_ALLOCATION, GET_DATA_WO, START_ALLOCATION_ITEMCODE } from "./enum/ItemAllocationTypeApi";

export async function getPagingService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_ALL_ITEM_ALLOCATION,
        data
    )
};

export async function getItemByWO(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_DATA_WO,
        data
    )
};

export async function startAllocationItemCode(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + START_ALLOCATION_ITEMCODE,
        data
    )
};