export const workUnitLag = {
    language: "en",
    workUnit: {
        workTitle: "Work Unit",
        table: {
            code: "Work unit code",
            name: "Work unit name",
            nameWorker: "Manager",
            status: "Active",
            note: "Note",
            affiliatedCenter: "Work center directly",
            action: "Action",
            noneDataTable: "No data to show",
        },
        search: {
            placeHolder: {
                code: "Work unit code",
                name: "Work unit name",
                nameWorker: "Manager",
                note: "Note",
                affiliatedCenter: "Work center directly",
            },
        },
        form: {
            add_new: "Create new work unit",
            update: "Update work unit",
            see_detail: "Work unit information",
            code: "Work unit code",
            name: "Work unit name",
            nameWorker: "Manager",
            affiliatedCenter: "Work center directly",
            type: "Type",
            note: "Note",
            status: "Active",
            listWorker: {
                listWorkerName: "List worker",
                code: "WORKER CODE",
                name: "NAME",
                position: "POSITION",
                work: "WORK",
                active: "ACTIVE",
                note: "NOTE",
                noneDataTable: "No data to show"
            },
            listMachine: {
                listMachineName: "List machine",
                code: "MACHINE CODE",
                name: "MACHINE NAME",
                wattage: "WATTAGE",
                active: "ACTIVE",
                note: "NOTE",
                noneDataTable: "No data to show"
            }
        },
        validate: {
            name: {
                required: "Work unit name cannot be empty",
                maxLength: "Work unit name should not exceed 100 characters",
            },
            code: {
                type: "Work unit code cannot contains special characters or spaces",
                text: "Work unit code is up to 9 characters long UPPER, only numbers and letters, no spaces",
                required: "Work unit code cannot be empty",
                maxLength: "Work unit code should not exceed 9 characters",
            },
            workercenter: {
                required: "Belonging to the work center cannot be empty",
            },
            note: {
                maxLength: "Work unit node should not exceed 255 characters",
            },
        },
        delete: {
            title: "Delete work unit",
            description: "Are you sure you want to delete this work unit?",
        },
        btn: {
            add_new: "Create new",
            close: "Close",
            cancel: "Cancel",
            add: "Add",
            update: "Update"
        },
        toastjs: {
            createSuccess: "Create Work Unit successfully",
            editSuccess: "Edit Work Unit successfully",
            deleteSuccess: "Delete Work Unit successfully",
            codeRepeat: "Work unit code has been duplicated, can't get this Work unit code",
            codeCharacters: "Work unit code maximum length 9 characters UPPER, only numbers and letters, no spaces"
        },
        confirm: {
            createSuccess: "Successfully created new work unit",
            updateSuccess: "Work unit update successful",
            deleteSuccess: "Successfully deleted work unit",
            //comfirmText: "Version đang được sử dụng, không được xóa",
            comfirmTextDeleted: "The work unit already has linked data, which cannot be deleted",
        }
    },
};