export const requirementLag = {
    language: "en",
    requirement: {
        table: {
            productionRequirementcode: "Requirment code",
            productCode: "Product code",
            productName: "Product name",
            product: 'Product',
            qtyrequirement: "Requirement quantity",
            unit: 'Unit',
            timedeparture: 'Time departure',
            plannedQuanlity: 'Planned quantity',
            producedQuanlity: 'Produced quantity',
            stockedQuanlity: 'Stocked quantity',
            operation: 'Action',
            dataEmpty: 'There are no data to display'
        },
        search: {
            placeHolder:
                "Search by productionrequirement code, product code or product name",
        },
        form: {
            add_new: "Create new production requirment",
            update: "Edit production requirment",
            see_detail: " See information of production requirment",
            add_requirement: "Create new version",
            qtyrequirementPlaceholder: 'Input requirement quantity',
            productionRequirementcodePlaceholder: "Production Requirement Code",
            selectProduct: "Select Product",
            productionrequirementcodeRequired: "Production Requirement Code cannot be empty",
            productionrequirementcodeMaxLength: "Production Requirement Code  must not exceed 50 characters",
            QtyrequirementNumber: "Qtyrequirement must be greater than 0",
            QtyrequirementRequired: "Qtyrequirement cannot be left blank",
            TimedepartureRequired: "Timedeparture cannot be empty",
            TimedepartureNotPast: "Timedeparture cannot be in the past",
            QtyrequirementNumber2: "Qtyrequirement must be a positive integer",
            productNotEmpty: "Product code can not be empty",
            productCodeLength: "Product code must be lest than 50 character",
        },
        delete: {
            title: "Delete production requirment",
            description: "Are you sure you want to delete this production requirment?",
        },
        btn: {
            addNew: 'Add new',
            create: 'Create new',
            close: 'Close',
            save: 'Save',
            edit: 'Edit'
        },
        toastjs: {
            createSuccess: "Create Production Requirement success",
            updateSuccess: "Update Production Requirement success",
            deleteSuccess: "Delete Production Requirement success",
        },
        rules: {
            required: 'This field must be not null',
            number: 'This value must be a number',
            positive: 'This value must be a positive number',

        }

    },
};
