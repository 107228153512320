export const tranferSloc = {
    language: "vn",
    tranfersloc: {
        qtylargerthanqty: "Số lượng lấy ra đang lớn hơn số lượng đã có trong kho",
        packagecodepick: "Package code lấy hàng",
        goodcode: "Mã hàng hóa",
        goodname: "Tên hàng hóa",
        locationcurrent: "Vị trí hiện tại của package",
        amounttransfer: "Số lượng lấy để chuyển",
        note: "Ghi chú",
        slocpickup: "Sloc lấy hàng",
        pickup: "Lấy hàng",
        storage: "Cất hàng",
        scantopick: "Scan package code để lấy hàng",
        goodinpackage: "Mã hàng hóa trong package:",
        locationpackagecurrent: "Vị trí package hiện tại:",
        number0: 'Số lượng phải lớn hơn 0',
        takeall: "Lấy hết",
        amounttakaout: "Số lượng lấy ra",
        finish: "Hoàn thành lấy hàng",
        deletepackagetransfer: "Xóa thông tin package chuyển kho",
        confirmdeletepack: "Bạn chắc chắn muốn xóa thông tin package này chứ?",
        removerow: "Xóa hàng lẻ ra khỏi phiếu",
        deletetiltle: "Xóa thông tin package chuyển kho",
        youaresuredelete: "Bạn chắc chắn muốn xóa hàng đã lấy?",
        notisstorage: ' không thuộc vị trí cất',
        sameitemcode: "Package không cùng mã hàng!",
        takecomplete: "Hàng trong package đã được lấy hết",
        storagelocationrequired: "Vị trí cất hàng không được bỏ trống",
        pleaseputall: "Không thể hoàn thành cất hàng! Vui lòng cất hết hàng đã lấy",
        qtystored: "Số lượng đã cất đi",
        locationstorage: "Vị trí cất- Package cất",
        scanstorage: "Scan vị trí cất hàng",
        storagelocation: "Vị trí cất hàng",
        packputinplace: "*Package cất vào vị trí hoặc package để chứa hàng lẻ",
        quantitystoredinpack: 'Số lượng cất vào package',
        finishstocking: "Hoàn thành cất hàng",
    },
};