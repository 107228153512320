import { call, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import {
  GET_LIST_ASSETMENT_TRANSFER,
  GET_DETAIL_ASSETMENT_TRANSFER,
  CREATE_ASSETMENT_TRANSFER,
  UPDATE_ASSETMENT_TRANSFER,
  DELETE_ASSETMENT_TRANSFER,
} from "./enum/TypeAssetmentTransferAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import AssetmentTransferAction from "./AssetmentTransferAction";
import {
  getListAssetmentTransferService,
  getDetailAssetmentTransferService,
  createAssetTransferReqService,
  updateAssetTransferReqService,
  deleteAssetTransferReqService,
} from "./AssetmentTransferService";

export function* getListAssetmentTransfer(data) {
  try {
    const response = yield call(getListAssetmentTransferService, data.request);
    if (response) {
      yield put(AssetmentTransferAction.saveListAssetmentTransfer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* getDetailAssetmentTransfer(data) {
  try {
    const response = yield call(getDetailAssetmentTransferService, {
      requestTransferAssetId: data.request,
    });
    if (response && response.isSuccess) {
      yield put(AssetmentTransferAction.saveDetailAssetmentTransfer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* createAssetmentTransferReq(data) {
  try {
    const response = yield call(createAssetTransferReqService, data.data);
    if (response && response.isSuccess) {
      if (!_.isNil(data.onCreated)) {
        data.onCreated();
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* updateAssetmentTransferReq(data) {
  try {
    const response = yield call(updateAssetTransferReqService, data.data);
    if (response && response.isSuccess) {
      if (!_.isNil(data?.onUpdated)) {
        data?.onUpdated();
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export function* deleteAssetmentTransferReq(data) {
  try {
    const response = yield call(deleteAssetTransferReqService, {
      requestTransferAssetId: data.data
    });
    if (response.isSuccess) {
      yield put(AssetmentTransferAction.deleteAssetmentTransferReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export default function* AssetmentTransferSaga(data) {
  yield takeEvery(GET_LIST_ASSETMENT_TRANSFER, getListAssetmentTransfer);
  yield takeEvery(GET_DETAIL_ASSETMENT_TRANSFER, getDetailAssetmentTransfer);
  yield takeEvery(CREATE_ASSETMENT_TRANSFER, createAssetmentTransferReq);
  yield takeEvery(UPDATE_ASSETMENT_TRANSFER, updateAssetmentTransferReq);
  yield takeEvery(DELETE_ASSETMENT_TRANSFER, deleteAssetmentTransferReq);
}
