import {
  GET_PAGING_CONSUMABLE_PACKAGE_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  CREATED_CONSUMABLE_PACKAGE_PACKAGE_REDUCER,
  GET_DETAIL_CONSUMABLE_PACKAGE_PACKAGE_REDUCER,
  SET_DETAIL_DATA_DEFAULT_REDUCER,
  GET_PRINT_CONSUMABLE_DATA_REDUCER,
  RESET_GET_PRINT_CONSUMABLE_DATA_REDUCER,
} from "./enum/TypeConsumablePackageAction";

const stateDefault = {
  listData: [],
  listConsumableDetail: [],
  printStampConsumableData:[], //Danh sách gói tiêu hao muốn in qrcode
  isGetLstData: false,
  isDeleteSuccess: false,
  isCreateUpdateSuccess: false,
  isGetDetailDateSuccess: false,
  isPrintStampConsumableData:false,
};

const ConsumablePackageReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_PAGING_CONSUMABLE_PACKAGE_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.isGetLstData = true;
      }
      return { ...state };

    case CREATED_CONSUMABLE_PACKAGE_PACKAGE_REDUCER:
      state.isCreateUpdateSuccess = true;
      return { ...state };

    case GET_DETAIL_CONSUMABLE_PACKAGE_PACKAGE_REDUCER:
      if (action.data) {
        state.isGetDetailDateSuccess = true;
        state.listConsumableDetail = action.data.data;
      }
      return { ...state };
    case CALL_DEFAULT_REDUCER:
      state.listDataWarehouse = [];
      state.isGetLstData = false;
      state.isDeleteSuccess = false;
      state.isCreateUpdateSuccess = false;
      return {
        ...state,
      };
    case GET_PRINT_CONSUMABLE_DATA_REDUCER:
      if (action.data) {
        state.printStampConsumableData = action.data;
        state.isPrintStampConsumableData = true;
        return { ...state }
      }
      break;
    case RESET_GET_PRINT_CONSUMABLE_DATA_REDUCER:
      state.isPrintStampConsumableData = false;
      return { ...state }
    case SET_DETAIL_DATA_DEFAULT_REDUCER:
      state.isGetDetailDateSuccess = false;
      return { ...state };

    case CLEAR_DATA_REDUCER:
      return {
        state: stateDefault,
      };
    default:
      return { ...state };
  }
};

export default ConsumablePackageReducer;
