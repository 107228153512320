export const baseExampleLag = {
    language: "en",
    baseExample : {
        table : {
            code : 'Unit member',
            description : 'Description',
            active : 'active'
        },
        search : {
            placeHolder : 'Search by unit code or unit name'
        },
        form : {
            add_new: 'Create new unit',
            update: 'Update unit',
            see_detail: 'See detail information of unit',
            code : 'Unit code',
            description : 'Unit description',
            active : 'active'
        },
        errorText : {
            memberEmpty : 'Unit member not allow empty',
            descriptionNotOver : 'Description not over 255 character',
            memberNotOver : 'Unit member not over 25 character',
            codeNotAvaible : 'Unit member not avaible'

        }
    }
};
