import {
    GET_PAGING_ASSETMENT_REDUCER,
    CLEAR_DATA_ASSETMENT_LIST_REDUCER,
    CALL_DEFAULT_ASSETMENT_LIST_REDUCER,
    GET_ASSET_BY_ID_REDUCER,
    SET_IS_OPEN_PRINT_STAMP_MODAL,
    UPDATE_MANY_ASSET_REDUCER,
    UPDATE_ASSET_REDUCER,
    GET_LIST_ASSET_HISTORY_REDUCER,
    GET_ALL_ASSET_STAMP_HISTORY_REDUCER,
    CLOSE_PAGE_UPDATE_MANY_ASSET_REDUCER,
    GET_PRINT_STAMP_DATA,
    SET_LOADED_STAMP_PRINT,
    UPDATE_ASSET_OK_SAP_FAILED_REDUCER
} from "./enum/TypeAssetmentListAction";

const stateDefault = {
    listData: [],
    isGetLstData: false,
    detailData: [],
    isGetDetailData: false,
    isOpenStampModal: false,
    // isCallCreateOrUpdateSuccess: false,
    // detailData: {},
    // isGetDetailData: false,
    // isDeleteSuccess: false,
    isUpdateAssetOK: false,
    isUpdateSapOK: false,
    isUpdateManyAssetOK: false,

    listAssetHistory: [],
    isGetListAssetHistory: false,
    listAllAssetStampHistory: [],
    isGetListAllAssetStampHistory: false,
    isClosePageUpdateManyAsset: false,
    printStampData: [],
    isPrintStampData: false,
    isLoadedDataSprint: false
}
const AssetmentListReducer = (
    state = stateDefault,
    action
) => {

    switch (action.type) {
        case GET_PAGING_ASSETMENT_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.isGetLstData = true;
            }
            return { ...state };
        case GET_ASSET_BY_ID_REDUCER:
            if (action.data) {
                state.detailData = action.data.data;
                state.isGetDetailData = true;
            }
            return { ...state };

        case UPDATE_ASSET_REDUCER:
            state.isUpdateAssetOK = true;
            state.isUpdateSapOK = true;
            return { ...state }
        case UPDATE_ASSET_OK_SAP_FAILED_REDUCER:
            state.isUpdateAssetOK = true;
            state.isUpdateSapOK = false;
            return { ...state }
        case UPDATE_MANY_ASSET_REDUCER:
            state.isUpdateManyAssetOK = true;
            return { ...state }
        case GET_LIST_ASSET_HISTORY_REDUCER:
            if (action.data) {
                state.listAssetHistory = action.data;
                state.isGetListAssetHistory = true;
            }
            return { ...state };
        case GET_ALL_ASSET_STAMP_HISTORY_REDUCER:
            if (action.data) {
                state.listAllAssetStampHistory = action.data;
                state.isGetListAllAssetStampHistory = true;
            }
            return { ...state };
        case SET_IS_OPEN_PRINT_STAMP_MODAL:
            state.isOpenStampModal = action.isOpenStampModal;
            return { ...state };
        case CLOSE_PAGE_UPDATE_MANY_ASSET_REDUCER:
            state.isClosePageUpdateManyAsset = true;
            return { ...state };

        case CALL_DEFAULT_ASSETMENT_LIST_REDUCER:
            state.isGetLstData = false;
            state.isGetDetailData = false;
            state.isUpdateAssetOK = false;
            state.isUpdateSapOK = false;
            state.isUpdateManyAssetOK = false;
            state.isGetListAssetHistory = false;
            state.isGetListAllAssetStampHistory = false;
            state.isClosePageUpdateManyAsset = false;
            state.isPrintStampData = false;
            return { ...state };

        case GET_PRINT_STAMP_DATA:
            if (action.data) {
                state.printStampData = action.data;
                state.isPrintStampData = true;
                return { ...state }
            }
            break;
        case SET_LOADED_STAMP_PRINT:
            if (action.isLoadedDataSprint) {
                state.isLoadedDataSprint = action.isLoadedDataSprint;
                return { ...state }
            }
            break;
        case CLEAR_DATA_ASSETMENT_LIST_REDUCER:
            return {
                state: stateDefault,
            };
        default:
            return { ...state };
    }
};

export default AssetmentListReducer;
