export const GET_ALL_ACCESSORIES = "GET_ALL_ACCESSORIES";
export const RETURN_DATA_ACCESSORIES = "RETURN_DATA_ACCESSORIES";
export const CREATE_ACCESSORIES = "CREATE_ACCESSORIES";
export const UPDATE_ACCESSORIES = "UPDATE_ACCESSORIES";
export const DELETE_ACCESSORIES = "DELETE_ACCESSORIES";
export const GET_DETAIL_ACCESSORIES = "GET_DETAIL_ACCESSORIES";
export const RETURN_OBJECT_ACCESSORIES = "RETURN_OBJECT_ACCESSORIES";
export const CALL_RESULT_FROM_ACCESSORIES_API = "CALL_RESULT_FROM_ACCESSORIES_API";
export const CALL_DEFAULT_FROM_ACCESSORIES = "CALL_DEFAULT_FROM_ACCESSORIES";
export const CLOSE_LOADING_TABLE = "CLOSE_LOADING_TABLE--ACCESSORIES_SCREEN";
export const CLOSE_DELETE_POPUP = "CLOSE_DELETE_POPUP--ACCESSORIES_SCREEN";
