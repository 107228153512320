import {
    CALL_DEFAULT_BOM_REDUCER,
    GET_PAGING_BOM_REDUCER,
    GET_DETAIL_BOM_REDUCER,
    CALL_RESULT_BOM_REDUCER,
    SET_DROPDOWN_PRODUCT_REDUCER,
    SET_DROPDOWN_VERSION_BOM_REDUCER,
    CHANGE_DATA_BOM_REDUCER,
    CALL_CHANGE_VERSION_BOM_REDUCER,
    DELETE_VERSION_BOM_REDUCER,
    SET_DESCRIPTION_MATERIAL_REDUCER,
    ADD_MORE_VERSION_BOM_REDUCER
} from './enum/BOMTypeAction'

const BomReducer = (
    state = {
        isFetching: false,
        isFetchingMore: false,
        listData: [],
        objectData: {},
        callResultFromApi: false,
        callResultFromDetail: false,
        isDeleteSuccess: false,
        dropdownProduct: [],
        isCallDropdownProduct: false,
        isCallDropdownVersion: false,
        DropdownVersion: [],
        changeData: false,
        isChangeVersionBom: false,
        DescriptionMaterial: [],
        isCallDescriptionMaterial: false,
        isAddMoreVersionSuccess: false,
    },
    action
) => {
    switch (action.type) {
        case GET_PAGING_BOM_REDUCER:
            if (action.data) {
                state.listData = action.data;
            }
            return { ...state }
        case GET_DETAIL_BOM_REDUCER:
            state.callResultFromDetail = true;
            if (action.obj) {
                state.objectData = action.obj.data;
            }
            return {
                ...state
            };
        case CALL_RESULT_BOM_REDUCER:
            state.callResultFromApi = true;
            return {
                ...state
            };
        case SET_DROPDOWN_PRODUCT_REDUCER:
            if (action.data) {
                state.dropdownProduct = action.data;
                state.isCallDropdownProduct = true;
            }
            return { ...state }
        case SET_DROPDOWN_VERSION_BOM_REDUCER:
            state.isCallDropdownVersion = true;
            state.DropdownVersion = action.data;
            return {
                ...state,
            };
        case SET_DESCRIPTION_MATERIAL_REDUCER:
            state.isCallDescriptionMaterial = true;
            state.DescriptionMaterial = action.data;
            return {
                ...state,
            };
        case CHANGE_DATA_BOM_REDUCER:
            state.changeData = true;
            return {
                ...state
            };
        case CALL_CHANGE_VERSION_BOM_REDUCER:
            state.isChangeVersionBom = true;
            return {
                ...state,
            };
        case DELETE_VERSION_BOM_REDUCER:
            state.isDeleteSuccess = true;
            return {
                ...state,
            };
        case ADD_MORE_VERSION_BOM_REDUCER:
            state.isAddMoreVersionSuccess = true;
            return {
                ...state,
            };

        case CALL_DEFAULT_BOM_REDUCER:
            state.callResultFromApi = false;
            state.callResultFromDetail = false;
            state.isDeleteSuccess = false;
            state.objectData = null;
            state.isCallDropdownProduct = false;
            state.isCallDropdownVersion = false;
            state.changeData = false;
            state.isChangeVersionBom = false;
            state.isCallDescriptionMaterial = false;
            state.DescriptionMaterial = [];
            state.isAddMoreVersionSuccess = false;
            return {
                ...state,
            }
        default:
            return { ...state }
    }
}

export default BomReducer
