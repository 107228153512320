export const UnitCoversionLag = {
    language: "vn",
    UnitCoversion: {
        title: "Bảng quy đổi",
        table: {
            Materialcode: "Mã linh kiện",
            Materialname: "Tên linh kiện",
            Unitfromname: "Đơn vị cần quy đổi",
            Unittoname: "Đơn vị quy đổi sang",
            Quantityfrom: "Số lượng quy đổi",
            Quantityto: "Số lượng nhận được",
            Status: "Đang hoạt động",
            Action: "Thao tác",
        },
        search: {
            placeHolder: "Tìm kiếm theo Mã linh kiện , tên linh kiện , đơn vị cần quy đổi , cần quy đổi , đơn vị quy đổi sang",
        },
        form: {
            create: "Thêm mới quy đổi đơn vị",
            update: "Cập nhật quy đổi đơn vị",
            getDetail: "Chi tiết quy đổi đơn vị",
            Materialcode: "Linh kiện",
            Unitfrom: "Đơn vị",
            Unitto: "Đơn vị quy đổi sang",
            Quantityfrom: "Số lượng quy đổi",
            Quantityto: "Số lượng nhận được",
            Status: "Đang hoạt động",
            placeHolder: {
                Materialcode: "Chọn linh kiện",
                Unitfrom: "Chọn đơn vị cần quy đổi",
                Unitto: "Chọn đơn vị quy đổi sang",
                Quantityfrom: "Nhập số lượng quy đổi",
                Quantityto: "Nhập số lượng nhận được",
            }
        },
        delete: {
            title: "Xoá quy đổi",
            description: "Bạn có chắc chắn muốn xóa quy đổi này?",
        },
        error: {
            Materialcode: {
                character: "Mã model không đúng định dạng",
                required: "Linh kiện không được bỏ trống",
            },
            Unitfrom: {
                required: "Đơn vị được bỏ trống",
            },
            Unitto: {
                required: "Đơn vị quy đổi sang không được bỏ trống",
            },
            Quantityfrom: {
                required: "Số lượng quy đổi không được bỏ trống",
            },
            Quantityto: {
                required: "Số lượng nhận được không được bỏ trống",
            },
        },
        toastJS: {
            creatSuccess: "Tạo mới quy đổi thành công",
            updateSuccess: "Cập nhật quy đổi thành công",
            deleteSuccess: "Xóa thành công quy đổi",
        }
    }
}