export const GET_PAGING_CONFIG_PLANNING_SAGA = "GET_PAGING_CONFIG_PLANNING_SAGA";

export const GET_PAGING_CONFIG_PLANNING_REDUCER =
    "GET_PAGING_CONFIG_PLANNING_REDUCER";


export const CREATE_CONFIG_PLANNING_SAGA='CREATE_CONFIG_PLANNING_SAGA1'
export const UPDATE_CONFIG_PLANNING_SAGA='UPDATE_CONFIG_PLANNING_SAGA'
export const CREATE_CONFIG_PLANNING_REDUCER='CREATE_CONFIG_PLANNING_REDUCER'
export const UPDATE_CONFIG_PLANNING_REDUCER='UPDATE_CONFIG_PLANNING_REDUCER'
export const GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_SAGA='GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_SAGA'
export const GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_REDUCER='GET_DETAIL_CONFIG_PLANNING_BY_HOSPITALID_REDUCER'
export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
