import * as type from "./enum/PackingLevelTypeAction";
const PackingLevelAction = {
    getPagingSaga: () => {        
        return {
            type: type.GET_PAGING_PACKING_LEVEL_SAGA,
        };
    },
    setPagingReducer: (data) => {
        return {
            type: type.SET_PAGING_PACKING_LEVEL_REDUCER,
            data: data,
        }
    },
    createDataSaga: (request) => {
        return {
            type: type.CREATE_PACKING_LEVEL_SAGA,
            request: request,
        }
    },
    updateDataSaga: (request) => {
        return {
            type: type.UPDATE_PACKING_LEVEL_SAGA,
            request: request,
        }
    },
    deletePackingLevel: (request) => {
        return {
            type: type.DELETE_PACKING_LEVEL_SAGA,
            request: request,
        }
    },
    changeLevelPackingLevel: (id, level) => {
        return {
            type: type.CHANGE_LEVEL_PACKING_LEVEL,
            id: id,
            level:level,
        }
    },
    getPackingLevelById: (id) => {
        return {
            type: type.GET_PACKING_LEVEL_BY_ID,
            id: id,
        }
    },
    setPackingLevelByIdReducer: (data) => {
        return {
            type: type.SET_PACKING_LEVEL_BY_ID_REDUCER,
            data: data,
        }
    },
    callResultFromApiReducer: () => {
        return {
            type: type.CALL_RESULT_PACKING_LEVEL_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type:type.CLEAR_DATA_REDUCER,
        };
    },
    callDefault: () => {
        return {
            type: type.CALL_DEFAULT_PACKING_LEVEL_REDUCER,
        }
    },
    
};
export default PackingLevelAction;