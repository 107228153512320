import {call, put, takeEvery} from "redux-saga/effects";
import {
    ACTION_DOWNLOAD_DETAILS_BY_AUDIT_PLAN_SAGA,
    ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_SAGA,
    ACTION_INVENTORY_AUDIT_PLAN_DELETE_SAGA,
    ACTION_INVENTORY_AUDIT_PLAN_DETAILS_SAGA,
    FETCH_INVENTORY_AUDIT_PLAN_DATA_SAGA
} from "./enum/TypeInventoryAction";
import {
    fetchInventoryAuditPlanCreateService,
    fetchInventoryAuditPlanDataService,
    fetchInventoryAuditPlanDeleteService,
    fetchDetailAInventoryAuditPlanService,
    fetchInventoryAuditPlanUpdateService,
    downloadExcelLstDetailByAuditPlanService,
} from "./InventoryService";
import {closeLoading, openLoading} from "../../../shared/common/LoadingAction";
import InventoryAction from "./InventoryAction";
import {formTypeEnum} from "../../../components/page/InventoryManagement/Inventory/enum";
import { downloadFile, getFileNameWithDate } from "src/shared/utils/file";

export function* fetchInventoryAuditPlanData(body) {
    const bodySend = {
        "SearchTerm": body.body?.search ?? "",
        "PageIndex": body.body?.pageIndex ?? 1,
        "PageSize": body.body?.pageSize ?? 20,
        "Status": body.body.status,
        "InventoryPeriod": body.body.inventoryPeriod,
        "ExecutionTimeStart": body.body.executionTimeStart,
        "ExecutionTimeEnd": body.body.executionTimeEnd,
        "HospitalId": body.body.hospitalId,
        "CreateAtStart": body.body.createAtStart,
        "CreateAtEnd": body.body.createAtEnd,
        "PlanType": body.body.planType,
    }

    openLoading().then(() => "Load")
    try {
        yield put(InventoryAction.clearDataReducer());
        const response = yield call(fetchInventoryAuditPlanDataService, bodySend);
        if (!response.isSuccess && !response.Items) {
            yield put(InventoryAction.callDefaultReducer());
            closeLoading().then(() => "Close")
            return
        }
        yield put(InventoryAction.getInventoryAuditPlanPaginationReducer(response));
    } catch (e) {
        console.log("fetchInventoryAuditPlanData", e)
    } finally {
        yield put(InventoryAction.callDefaultReducer());
        closeLoading().then(() => "Close")
    }
}

export function* actionInventoryAuditPlanCreateOrUpdate(body) {
    openLoading().then(() => "Load")
    try {
        const response = body.mode === formTypeEnum.Create
            ? yield call(fetchInventoryAuditPlanCreateService, body.body)
            : yield call(fetchInventoryAuditPlanUpdateService, body.body);

        if (!response.isSuccess) {
            yield put(InventoryAction.callDefaultReducer());
            closeLoading().then(() => "Close")
            return
        }
        yield put(InventoryAction.createOrUpdateInventoryAuditPlanReducer(response));
    } catch (e) {
        console.log("actionInventoryAuditPlanCreateOrUpdate", e)
    } finally {
        yield put(InventoryAction.callDefaultReducer());
        closeLoading().then(() => "Close")
    }
}

export function* actionInventoryAuditPlanDelete(params) {
    openLoading().then(() => "Load")
    try {
        yield call(fetchInventoryAuditPlanDeleteService, params.id)
        yield put(InventoryAction.deleteInventoryAuditPlanReducer);
    } catch (e) {
        console.log("actionInventoryAuditPlanDelete", e)
    } finally {
        yield put(InventoryAction.callDefaultReducer());
        closeLoading().then(() => "Close")
    }
}

export function* fetchInventoryAuditPlanDetails(params) {
    openLoading().then(() => "Load")
    try {
        const response = yield call(fetchDetailAInventoryAuditPlanService, params.id)
        if (!response.isSuccess) {
            yield put(InventoryAction.callDefaultReducer());
            return
        }
        yield put(InventoryAction.detailsInventoryAuditPlanReducer(response));
    } catch (e) {
        console.log("fetchInventoryAuditPlanDetails", e)
    } finally {
        yield put(InventoryAction.callDefaultReducer());
        closeLoading().then(() => "Close")
    }
}
export function* downloadLstDetailByAuditPlanExcelSaga(data){
    let request = {
        auditPlanId: data.data.auditPlanId,
        filterData: data.data.filterData
    };
    
    const response = yield call(downloadExcelLstDetailByAuditPlanService, request);
    //Xử lý nếu typeExport là tải Excel
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = getFileNameWithDate({
        prefix: "",
        fileNameWithoutExtension: "bao_cao_06_thang",
        extension: ".xlsx",
      });
      downloadFile(fileUrl, fileName);
    
    closeLoading();
  
  }

//watch all saga config
export default function* InventoryAuditManagementSaga() {
    yield takeEvery(FETCH_INVENTORY_AUDIT_PLAN_DATA_SAGA, fetchInventoryAuditPlanData);
    yield takeEvery(ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_SAGA, actionInventoryAuditPlanCreateOrUpdate);
    yield takeEvery(ACTION_INVENTORY_AUDIT_PLAN_DELETE_SAGA, actionInventoryAuditPlanDelete);
    yield takeEvery(ACTION_INVENTORY_AUDIT_PLAN_DETAILS_SAGA, fetchInventoryAuditPlanDetails);
    yield takeEvery(ACTION_DOWNLOAD_DETAILS_BY_AUDIT_PLAN_SAGA, downloadLstDetailByAuditPlanExcelSaga);
}
