
import { FALSE } from "sass";
import {
    GET_PAGING_CAT_WAREHOUSE_PRODUCT_REDUCER,
    GET_PAGING_PRODUCT_BY_ROLE_USER_REDUCER,
    CALL_UPDATE_SUCCESS_REDUCER,
    CALL_UPDATE_FAIL_REDUCER,
    CALL_DEFAULT_REDUCER,
    LOAD_MORE_DATA,
    CLEAR_DATA_REDUCER,
    SEARCH_PRODUCT_BY_CODEOH_SUSSESS_REDUCER,
    RESET_SEARCH_PRODUCT_BY_CODEOH_REDUCER,
    GET_PAGING_BY_PANNING_ID_REDUCER,
    GET_DETAIL_CAT_WAREHOUSE_PRODUCT_REDUCER,
    CALL_CLEAR_DETAIL,
    CALL_DEFAULT_LST_DATA_BY_PLANING_REDUCER,
} from "./enum/TypeCatWarehouseProductAction"
const stateDefault = {
    listData: [],
    listDataByRoleUser:[],
    productByCodeOH:{},
    isGetLstData: false,
    isGetLstDataByRoleUser:false,
    isSearchProductByCodeOH: false,
    isUpdateSuccess: false,
    errorUserMess: "",
    listDataByPlanning: [],
    isGetLstDataByPlanning: false,
    detailCatWarehouseProduct: {},
    isGetDetailCatWarehouseProduct: false,
}

const CatWarehouseProductReducer = (state = stateDefault, action) => {
    switch (action.type) {
        case GET_PAGING_CAT_WAREHOUSE_PRODUCT_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.isGetLstData = true;
            }
            return { ...state };
        case GET_PAGING_PRODUCT_BY_ROLE_USER_REDUCER:
            if (action.data) {
                state.listDataByRoleUser = action.data;
                state.isGetLstDataByRoleUser = true;
            }
            return { ...state };
        case CALL_UPDATE_SUCCESS_REDUCER:
            state.isUpdateSuccess = true;
            return { ...state };
        
        case CALL_UPDATE_FAIL_REDUCER:
            state.isUpdateSuccess = false;
            if (action.data) {
                console.log("gias trij ", action);
                console.log("gias trij action.data.userMessage ", action.data.userMessage);
                state.errorUserMess = action.data.userMessage;
            }
            return { ...state };
        case LOAD_MORE_DATA:
            state.isGetLstDataByRoleUser = true;
            return {
                ...state
            }
        case SEARCH_PRODUCT_BY_CODEOH_SUSSESS_REDUCER:
            if (action.data) {
                state.isSearchProductByCodeOH = true;
                state.productByCodeOH = action.data.data;
            }
            return {
                ...state
            }
        case RESET_SEARCH_PRODUCT_BY_CODEOH_REDUCER:
            state.isSearchProductByCodeOH = false;
            state.productByCodeOH = {};
            return {
                ...state
            }
        case CALL_DEFAULT_REDUCER:
            // state.listData = [];
            state.listDataByRoleUser = [];
            state.listDataByPlanning = [];
            state.isGetLstData = false;
            state.isGetLstDataByRoleUser = false;
            state.isUpdateSuccess = false;
            state.errorUserMess = "";

            return {
                ...state
            }
    
        case CALL_CLEAR_DETAIL:
                state.isGetDetailCatWarehouseProduct = false;
            return { ...state };

        case GET_PAGING_BY_PANNING_ID_REDUCER:
            if (action.data) {
                state.listDataByPlanning = action.data;
                state.isGetLstDataByPlanning = true;
            }
            return { ...state };
        case CALL_DEFAULT_LST_DATA_BY_PLANING_REDUCER:
            state.isGetLstDataByPlanning = false;
            return { ...state };
        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault
            }
        case GET_DETAIL_CAT_WAREHOUSE_PRODUCT_REDUCER:
            if(action.data){
                state.detailCatWarehouseProduct = action.data.data;
                state.isGetDetailCatWarehouseProduct = true; 
            }
            return{...state};
        
        default:
            return { ...state };
    }
}

export default CatWarehouseProductReducer;