import { type } from "@testing-library/user-event/dist/type";
import {
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    FETCH_INVENTORY_AUDIT_PLAN_DATA_SAGA,
    FETCH_INVENTORY_AUDIT_PLAN_DATA_REDUCER,
    ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_SAGA,
    ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_REDUCER,
    ACTION_INVENTORY_AUDIT_PLAN_DELETE_SAGA,
    ACTION_INVENTORY_AUDIT_PLAN_DELETE_REDUCER,
    ACTION_INVENTORY_AUDIT_PLAN_DETAILS_SAGA,
    ACTION_INVENTORY_AUDIT_PLAN_DETAILS_REDUCER,
    ACTION_INVENTORY_AUDIT_DETAILS_CLEAR,
    ACTION_DETAIL_A_INVENTORY_AUDIT_REDUCER,
    ACTION_DOWNLOAD_DETAILS_BY_AUDIT_PLAN_SAGA,
    UPDATE_FILTER_DATA_REDUCER,
    CLEAR_FILTER_DATA_REDUCER,
} from "./enum/TypeInventoryAction";

const InventoryAction = {

    //Search + Filter
    getInventoryAuditPlanPaginationSaga: (body) => {
        return {
            body: {...body},
            type: FETCH_INVENTORY_AUDIT_PLAN_DATA_SAGA
        };
    },
    getInventoryAuditPlanPaginationReducer: (data) => {
        return {
            type: FETCH_INVENTORY_AUDIT_PLAN_DATA_REDUCER,
            data
        };
    },

    //Create Or Update
    createOrUpdateInventoryAuditPlanSaga: (body, mode) => {
        return {
            body: {...body},
            mode: mode,
            type: ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_SAGA
        };
    },
    createOrUpdateInventoryAuditPlanReducer: (data) => {
        return {
            type: ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_REDUCER,
            data
        };
    },

    //View
    detailsInventoryAuditPlanSaga: (id) => {
        return {
            id: id,
            type: ACTION_INVENTORY_AUDIT_PLAN_DETAILS_SAGA
        };
    },
    detailsInventoryAuditPlanReducer: (data) => {
        return {
            type: ACTION_INVENTORY_AUDIT_PLAN_DETAILS_REDUCER,
            data
        };
    },

    //Delete
    deleteInventoryAuditPlanSaga: (id) => {
        return {
            id: id,
            type: ACTION_INVENTORY_AUDIT_PLAN_DELETE_SAGA
        };
    },
    deleteInventoryAuditPlanReducer: (data) => {
        return {
            type: ACTION_INVENTORY_AUDIT_PLAN_DELETE_REDUCER,
            data
        };
    },

    detailAInventoryAuditReducer: (data) => {
        return {
            type: ACTION_DETAIL_A_INVENTORY_AUDIT_REDUCER,
            data
        };
    },

    downloadLstDetailByAuditPlanExcelSaga:(data) =>{
        return{
            type: ACTION_DOWNLOAD_DETAILS_BY_AUDIT_PLAN_SAGA,
            data:data
        }
    },

    updateFilterDataReducer:(data) =>{ 
        return{
            type: UPDATE_FILTER_DATA_REDUCER,
            data:data
        }
    },
    
    clearFilterDataReducer:() =>{ 
        return{
            type: CLEAR_FILTER_DATA_REDUCER
        }
    },

    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_REDUCER,
        };
    },

    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER,
        };
    }
}

export default InventoryAction;
