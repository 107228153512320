export const PackagetypeManager = {
    language: "vi",
    PackagetypeLang: {
        palaceholderSearch: "Loại package, mô tả ngắn, mô tả",
        table: {
            packagetype: "Loại package",
            shortdes: "Mô tả ngắn",
            des: "Mô tả",
            active: "Active",
            action: "Thao tác",
        },
        form: {
            titleInfo: "Thông tin kiểu đóng gói",
            titleEdit: "Cập nhật kiểu đóng gói",
            titleCreate: "Thêm mới kiểu đóng gói",
            titleDelete: "Xoá kiểu đóng gói",
            textConfirmDelete: "Bạn có chắc muốn xoá kiểu đóng gói này",
            palaceHolderTye: "Nhập loại package",
            palaceHolderShorDes: "Nhập mô tả ngắn",
            palaceHolderDes: "Nhập mô tả",
        },
        mes: {
            emptyTypePackage: "Package type không được bỏ trống",
            emptyShortDes: "Mô tả ngắn không được để trống",
            emptyDes: "Mô tả không được để trống",
            formatTypePage: "Loại package không đúng định dạng",
            lengthTypePackage: "Package type không được quá 150 kí tự",
            lengthShortDes: "Mô tả ngắn không được quá 25 ký tự",
            lengthDes: "Mô tả không được quá 255 ký tự",
        },
        toastJs: {
            createOK: "Tạo mới kiểu đóng gói thành công",
            updateOK: "Cập nhật kiểu đóng gói thành công",
            deleteOK: "Xóa thành công kiểu đóng gói"
        }
    }
}