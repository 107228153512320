
export const FETCH_INVENTORY_AUDIT_PLAN_DATA_SAGA = "FETCH_INVENTORY_AUDIT_PLAN_DATA_SAGA"
export const FETCH_INVENTORY_AUDIT_PLAN_DATA_REDUCER = "FETCH_INVENTORY_AUDIT_PLAN_DATA_REDUCER"


export const ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_SAGA = "ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_SAGA"
export const ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_REDUCER = "ACTION_INVENTORY_AUDIT_PLAN_CREATE_OR_UPDATE_REDUCER"


export const ACTION_INVENTORY_AUDIT_PLAN_DELETE_SAGA = "ACTION_INVENTORY_AUDIT_PLAN_DELETE_SAGA"
export const ACTION_INVENTORY_AUDIT_PLAN_DELETE_REDUCER = "ACTION_INVENTORY_AUDIT_PLAN_DELETE_REDUCER"


export const ACTION_INVENTORY_AUDIT_PLAN_DETAILS_SAGA = "ACTION_INVENTORY_AUDIT_PLAN_DETAILS_SAGA"
export const ACTION_INVENTORY_AUDIT_PLAN_DETAILS_REDUCER = "ACTION_INVENTORY_AUDIT_PLAN_DETAILS_REDUCER"

export const ACTION_DETAIL_A_INVENTORY_AUDIT_REDUCER = "ACTION_DETAIL_A_INVENTORY_AUDIT_REDUCER"

export const ACTION_DOWNLOAD_DETAILS_BY_AUDIT_PLAN_SAGA = "ACTION_DOWNLOAD_DETAILS_BY_AUDIT_PLAN_SAGA"

export const UPDATE_FILTER_DATA_REDUCER = "UPDATE_FILTER_DATA_REDUCER"
export const CLEAR_FILTER_DATA_REDUCER = "CLEAR_FILTER_DATA_REDUCER"

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";