import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";

import {
    DELETE_INVENTORY_PLAN_API,
    GET_COST_CENTER_EV5_BY_SEGMENT_API,
    CREATE_INVENTORY_PLAN_API,
    GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_API,
    UPDATE_INVENTORY_PLAN_API
} from "./enum/TypeAssetmentStocktakingAPI";


export async function DeleteDataService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + DELETE_INVENTORY_PLAN_API + data
    );
}
export async function GetCostCenterEV5BySegmentService(data) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_COST_CENTER_EV5_BY_SEGMENT_API + data
    );
}
export async function CreateInventoryPlanService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + CREATE_INVENTORY_PLAN_API, data
    );
}
export async function UpdateInventoryPlanService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + UPDATE_INVENTORY_PLAN_API, data
    );
}
export async function GetInventoryPlanByIdForEditService(id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_INVENTORY_PLAN_BY_ID_FOR_EDIT_API + id
    );
}
