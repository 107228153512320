import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_PAGING_INVENTORY_USER_CONFIG_API,
    CREATE__UPDATE_INVENTORY_USER_CONFIG_API,
} from "./enum/TypeInventoryUserConfigAPI.js";

export async function getPagingService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_PAGING_INVENTORY_USER_CONFIG_API,
        data
    )
}

export async function createUpdateDataService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + CREATE__UPDATE_INVENTORY_USER_CONFIG_API,
        data
    )
}

