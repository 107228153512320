export const RescheduleProductionVI = {
    language: "vn",
    rescheduleproduction: {
        selectitem: "Chưa có item sản phẩm nào được chọn",
        errorcreate: "Không thể lựa chọn 2 sản phẩm để tạo lệnh sản xuất",
        searchplaceholder: "Item sản phẩm, sản phẩm, lệnh đã sản xuất, trung tâm sản xuất, trạng thái, thời gian kết thúc sản xuất",
        create: "Tạo lệnh sản xuất",
        itempro: "Item sản phẩm",
        producedorder: "Lệnh đã sản xuất",
        productioncenter: "Trung tâm sản xuất",
        endtime: "Thời gian kết thúc sản xuất",
        status: "Trạng thái",
        placeholder: {
            itempro: "Item sản phẩm",
            product: "Sản phẩm",
            producedorder: "Lệnh đã sản xuất",
            productionorder: "Lệnh sản xuất",
            productioncenterold: "Trung tâm đã sản xuất",
            status: "Trạng thái",
            process: "Quy trình",
            amount: "Số lượng",
            expectedstart: "Bắt đầu dự kiến",
            expectedend: "Kết thúc dự kiến",
        },
        table: {
            dataEmpty: "Không có dữ liệu",
        },
        form: {
            WorkorderCode: "Lệnh sản xuất không được bỏ trống",
            Amount: "Số lượng không được bỏ trống",
            AmountFormat: "Số lượng không đúng định dạng",
            NumberBiggerZero: "Số lượng phải lớn hơn 0",
        },
        rules: {
            workordercodeempty: "Mã lệnh sản xuất không được để trống",
            workordercodemax: "Mã lệnh sản xuất không thể vượt quá 50 kí tự",
            processempty: "Quy trình không được để trống",
            workcenterempty: "TTSX không được để trống",
            esproductiontimefromempty: "Ngày bắt đầu dự kiến không được để trống",
            esproductiontimetoempty: "Ngày kết thúc dự kiến không được để trống",
            checktime: "Thời gian kết thúc dự kiến phải lớn hơn thời gian bắt đầu dự kiến",
            noitemproduct: "Không có item sản phẩm nào",
        }
    },
}