import * as type from "./enum/enumAction";
const POCoordinateAction = {
    getPagingDataSaga: (searchKey = "", index = 1, size = 500, status = 0, date = "") => {
        return {
            type: type.GET_DATA_PAGING_SAGA,
            searchKey,
            index,
            size,
            status,
            date
        }
    },
    setPagingData: (data) => {
        return {
            type: type.SET_DATA_PAGING_REDUCER,
            data
        }
    },
    callActionSaga: (Action, id, Workordercode) => {
        return {
            type: type.CALL_ACTION_SAGA,
            Action,
            id,
            Workordercode,
        }
    },
    callActionReducer: () => {
        return {
            type: type.CALL_ACTION_REDUCER,
        }
    },
    checkActionSaga: (Action, Workordercode) => {
        return {
            type: type.CHECK_ACTION_SAGA,
            Action,
            Workordercode,
        }
    },
    checkActionReducer: (result, Action, Workordercode) => {
        return {
            type: type.CHECK_ACTION_REDUCER,
            result,
            Action,
            Workordercode
        }
    },
    updateWoSaga: (entity) => {

        return {
            type: type.UPDATE_WORK_ORDER_SAGA,
            entity
        }
    },
    updateWoReducer: () => {
        return {
            type: type.UPDATE_WORK_ORDER_REDUCER,
        }
    },
    getDetailWoSaga: (entity, typeRemained) => {
        return {
            type: type.GET_WORK_ORDER_DETAIL_SAGA,
            entity,
            typeRemained
        }
    },
    getDetailWoReducer: (entity, typeRemained) => {
        return {
            type: type.GET_WORK_ORDER_DETAIL_REDUCER,
            entity,
            typeRemained
        }
    },
    savedData: (data, isSaved) => {
        return {
            type: type.SAVED_DATA,
            data,
            isSaved
        }
    },
    default: () => {
        return {
            type: type.DEFAULT,
        }
    },
    updateByDragItem: (statusType = 1, woCode = "", start = new Date(), end = new Date(), workCenter = "") => {
        let statusSaga = "";
        if (statusType == 1) {
            statusSaga = type.UPDATE_BY_DRAG_ITEM_SAGA;
        } else if (statusType == 2) {
            statusSaga = type.UPDATE_BY_DRAG_ITEM_REDUCER;
        } else if (statusType == 3) {
            statusSaga = type.UPDATE_BY_DRAG_ITEM_DONE;
        }
        return {
            type: statusSaga,
            woCode,
            start,
            end,
            workCenter
        }
    },
    updateScheduleV2: (statusType = 1, data = []) => {
        let statusSaga = "";
        if (statusType == 1) {
            statusSaga = type.UPDATE_SCHEDULE_REDUCER;
        } else {
            statusSaga = type.UPDATE_SCHEDULE_DONE;
        }
        return {
            type: statusSaga,
            data
        }
    },
}
export default POCoordinateAction;