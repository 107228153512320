export const WorkareaLinkProccessLag = {
    language: "vn",
    wokarealinkproccess: {
        //index
        addworkunit: "Thêm mới ĐVSX",
        titleenter: "Nhấn 'Enter' để tìm kiếm",
        code: "Mã TTSX",
        name: "Tên TTSX",
        productcode: "Mã sản phẩm",
        productname: "Tên sản phẩm",
        process: "Quy trình",
        version: "Phiên bản",
        productivity: "Năng suất 1 giờ",
        unit: "Đơn vị",
        time: "Thời gian cài đặt (giây)",
        action: "Thao tác",
        //form
        ttsxselect: "TTSX chưa được chọn",
        addsuccesss: "Thêm mới liên kết thành công",
        deletesuccess: "Xóa liên kết thành công",
        fixsuccess: "Sửa  liên kết thành công",
        installation: "Thời gian cài đặt để sản xuất",
        seconds: "giây",
        ttsxuat: "Trung tâm sản xuất",
        productionProccess: "Quy trình sản xuất",
        product: "Sản phẩm",
        day: " ngày",
        hour: " giờ",
        week: " tuần",
        create: "Thêm mới Liên kết TTSX & Quy trình",
        update: "Chỉnh sửa Liên kết TTSX & Quy trình",
        details: "Xem thông tin chi tiết Liên kết TTSX & Quy trình",
        linkedlist: "Danh sách liên kết",
        productionunit: "Đơn vị sản xuất",
        productionunitname: "Tên đơn vị sản xuất",
        stage: "Công đoạn",
        execution: "Thời gian thực hiện (giây)",
        save: "Lưu",
        linked: "Đã liên kết (",
        unlinked: "Chưa liên kết",
        placeholder: {
            code: "Mã TTSX",
            name: "Tên TTSX",
            productcode: "Mã sản phẩm",
            productname: "Tên sản phẩm",
            process: "Quy trình",
            version: "Phiên bản",
            productivity: "Năng suất",
            unit: "Đơn vị",
            time: "Thời gian cài đặt",
            step: "Bước",
        },
        delete: {
            title: "Xóa liên kết",
            description: "Bạn chắc chắn muốn xóa liên kết này?"
        },
        validate: {
            noComplete: "Chưa hoàn thành việc tạo liên kết TTSX - Quy trình",
            productrequired: "Sản phẩm không được để trống",
            processrequired: "Quy trình không được để trống",
            ttsxrequired: "TTSX không được để trống",
            yield: "Năng suất không được để trống",
            yield0: "Năng suất phải lớn hơn 0",
            yieldrequired: "Năng suất không được để trống",
            timeint: "Thời gian cài đặt phải là số nguyên dương",
            time0: "Thời gian cài đặt phải lớn hơn 0",
            timerequired: "Thời gian cài đặt không được để trống",
        }
    }
}