export const CALL_ACTION_SAGA = "CALL_ACTION_SAGA";
export const CALL_ACTION_REDUCER = "CALL_ACTION_REDUCER";
export const CHECK_ACTION_SAGA = "CHECK_ACTION_SAGA";
export const CHECK_ACTION_REDUCER = "CHECK_ACTION_REDUCER";
export const UPDATE_WORK_ORDER_SAGA = "UPDATE_WORK_ORDER_SAGA";
export const UPDATE_WORK_ORDER_REDUCER = "UPDATE_WORK_ORDER_REDUCER";
export const GET_WORK_ORDER_DETAIL_SAGA = "GET_WORK_ORDER_DETAIL_SAGA";
export const GET_WORK_ORDER_DETAIL_REDUCER = "GET_WORK_ORDER_DETAIL_REDUCER";
export const GET_DATA_PAGING_SAGA = "GET_DATA_PRODUCTION_COORDINATION_PAGING_SAGA";
export const SET_DATA_PAGING_REDUCER = "SET_DATA_PAGING_REDUCER";
//-------------------------DEFALT ACTION ------------------------------------
export const DEFAULT = "DEFAULT";
export const SAVED_DATA = "SAVED_DATA";
//---------------------------------------------------------------------------
export const UPDATE_BY_DRAG_ITEM_SAGA = "UPDATE_BY_DRAG_ITEM_SAGA_PO_COORDINATION_TIMELINE";
export const UPDATE_BY_DRAG_ITEM_REDUCER = "UPDATE_BY_DRAG_ITEM_REDUCER_PO_COORDINATION_TIMELINE";
export const UPDATE_BY_DRAG_ITEM_DONE = "UPDATE_BY_DRAG_ITEM_DONE_PO_COORDINATION_TIMELINE";
export const UPDATE_SCHEDULE_REDUCER = "UPDATE_SCHEDULE_REDUCER_PO_COORDINATION_TIMELINE";
export const UPDATE_SCHEDULE_DONE = "UPDATE_SCHEDULE_PO_COORDINATION_TIMELINE";


