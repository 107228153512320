import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_LIST_RETURN_API,
    DELETE_RETURN_API,
    CREATE_RETURN_API,
    UPDATE_RETURN_API,
    GET_DETAIL_RETURN_API,
    GET_INFO_RETURN_BY_ID,
    RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_API
} from "./enum/TypeReturnAPI";

export async function getListReturnService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_LIST_RETURN_API,
        data
    )
}
export async function createReturnService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_RETURN_API,
        data
    )
}
export async function updateReturnService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_RETURN_API,
        data
    )
}
export async function deleteReturnService(deleteId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        DELETE_RETURN_API +
        "?Id=" + deleteId
    )
}
export async function getDetailReturnService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_DETAIL_RETURN_API +
        "?Id=" + Id
    )
}

export async function getInfoReturnByIdService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_INFO_RETURN_BY_ID +
        "?Id=" + Id
    )
}

export async function ReturnPredictabilityRetailByListProductService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        RETURN_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_API,
        data
    )
}