import {
  CREATE_ASSETMENT_TRANSFER,
  GET_LIST_ASSETMENT_TRANSFER,
  GET_DETAIL_ASSETMENT_TRANSFER,
  SAVE_DETAIL_ASSETMENT_TRANSFER,
  SAVE_LIST_ASSETMENT_TRANSFER,
  CLEAR_DETAIL_ASSETMENT_TRANSFER,
  UPDATE_ASSETMENT_TRANSFER,
  DELETE_ASSETMENT_TRANSFER,
  DELETE_ASSETMENT_TRANSFER_REDUCER
} from "./enum/TypeAssetmentTransferAction";

const AssetmentTransferListAction = {
  getListAssetmentTransfer: (request) => {
    return {
      type: GET_LIST_ASSETMENT_TRANSFER,
      request: request,
    };
  },
  saveListAssetmentTransfer: (data) => {
    return {
      type: SAVE_LIST_ASSETMENT_TRANSFER,
      data: data,
    };
  },
  getDetailAssetmentTransfer: (request) => {
    return {
      type: GET_DETAIL_ASSETMENT_TRANSFER,
      request: request,
    };
  },
  saveDetailAssetmentTransfer: (data) => {
    return {
      type: SAVE_DETAIL_ASSETMENT_TRANSFER,
      data: data,
    };
  },
  clearDetailAssetmentTransfer: () => {
    return {
      type: CLEAR_DETAIL_ASSETMENT_TRANSFER,
      data: null,
    };
  },
  createAssetmentTransfer: (data, onCreated) => {
    return {
      type: CREATE_ASSETMENT_TRANSFER,
      data: data,
      onCreated,
    };
  },
  updateAssetmentTransfer: (data, onUpdated) => {
    return {
      type: UPDATE_ASSETMENT_TRANSFER,
      data: data,
      onUpdated
    };
  },
  deleteAssetmentTransfer: (data) => {
    return {
      type: DELETE_ASSETMENT_TRANSFER,
      data: data,
    };
  },
  deleteAssetmentTransferReducer: () => {
    return {
      type: DELETE_ASSETMENT_TRANSFER_REDUCER,
    };
  },
};

export default AssetmentTransferListAction;
