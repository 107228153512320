import * as type from "./enum/RepairTypeAction"
const stateDefault = {
    listDataRepair: {},
    dataDetail: {},
    dropdownStepRepair:[],
    dataByItemCode: null,
    callResultFromReducer: false,
    callResultActionFromReducer: false,
    callFalseDataByItemCode: false,
}
const RepairReducer = (
    state = stateDefault, action
) => {
    switch (action.type) {
        case type.SET_PAGING_REDUCER:
            state.listDataRepair = action.data;
            return {
                ...state,
            }
        case type.SET_DETAIL_REPAIR_REDUCER:
            state.dataDetail = action.data;
            return {
                ...state,
            }
        case type.SET_DATA_BY_ITEM_CODE_REDUCER:
            state.dataByItemCode = action.data;
            return {
                ...state,
            }
        case type.CALL_FALSE_DATA_BY_ITEM_CODE_REDUCER:
            state.callFalseDataByItemCode = true;
            return {
                ...state,
            }
        case type.CLEAR_DATA_BY_ITEM_CODE_REPAIR_REDUCER:
            state.dataByItemCode = null;
            return {
                ...state,
            }
        case type.SET_DROPDOWN_STEP_REPAIR_REDUCER:
            state.dropdownStepRepair = action.data;
            return {
                ...state,
            }
        case type.CALL_RESULT_FROM_REDUCER:
            state.callResultFromReducer = true;
            return {
                ...state,
            }
        case type.CALL_DEFAULT_RESULT_FROM_REDUCER:
            state.callResultFromReducer = false;
            return {
                ...state,
            }
        case type.CALL_RESULT_ACTION_FROM_REDUCER:
            state.callResultActionFromReducer = true;
            return {
                ...state,
            }
        case type.CALL_DEFAULT_RESULT_ACTION_FROM_REDUCER:
            state.callResultActionFromReducer = false;
            return {
                ...state,
            }
        case type.CALL_DEFAULT_RESULT_REDUCER:
            state.callResultFromReducer= false;
            state.callResultActionFromReducer= false;
            state.callFalseDataByItemCode= false;
            return {
                ...state,
            }
        default:
            return {
                ...state,
            };
    }
}
export default RepairReducer;