import {
    CALL_DEFAULT_ITEM_ALLOCATION_REDUCER,
    CALL_RESULT_ITEM_ALLOCATION_REDUCER, GET_ITEMCODE_BY_REDUCER, GET_ITEMCODE_BY_SAGA, GET_PAGING_ITEM_ALLOCATION_REDUCER, GET_PAGING_ITEM_ALLOCATION_SAGA, START_ALLOCATION_ITEMCODE_REDUCER, START_ALLOCATION_ITEMCODE_SAGA
} from "./enum/ItemAllocationTypeAction";
const ItemAlloctionAction = {
    // ham truyen bien de goi data
    getPagingSaga: () => {
        return {
            type: GET_PAGING_ITEM_ALLOCATION_SAGA,
        };
    },
    // ham de xet data vao reducer
    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_ITEM_ALLOCATION_REDUCER,
            data: data
        };
    },

    getItemCodeByWoSaga: (data) => {
        return {
            type: GET_ITEMCODE_BY_SAGA,
            data: data
        };
    },

    getItemCodeByWoReducer: (response) => {
        return {
            type: GET_ITEMCODE_BY_REDUCER,
            response
        };
    },


    startAllocationSaga: (data) => {
        return {
            type: START_ALLOCATION_ITEMCODE_SAGA,
            data: data
        };
    },

    startAllocationReducer: (data) => {
        return {
            type: START_ALLOCATION_ITEMCODE_REDUCER,
            data
        };
    },


    // ham kiem tra ket qua sau khi goi api
    callResultFromApiReducer: () => {
        return {
            type: CALL_RESULT_ITEM_ALLOCATION_REDUCER,
        };
    },
    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_ITEM_ALLOCATION_REDUCER
        }
    }
}
export default ItemAlloctionAction;