export const workUnitLag = {
    language: "vn",
    workUnit: {
        workTitle: "Đơn vị sản xuất",
        table: {
            code: "Mã ĐVSX",
            name: "Tên ĐVSX",
            nameWorker: "Người quản lý",
            status: "Đang hoạt động",
            note: "Ghi chú",
            affiliatedCenter: "TTSX trực thuộc",
            action: "Thao tác",
            noneDataTable: "Không có dữ liệu hiển thị",
        },
        search: {
            placeHolder: {
                code: "Mã ĐVSX",
                name: "Tên ĐVSX",
                nameWorker: "Người quản lý",
                note: "Ghi chú",
                affiliatedCenter: "TTSX trực thuộc",
            },
        },
        form: {
            add_new: "Thêm đơn vị sản xuất",
            update: "Cập nhật đơn vị sản xuất",
            see_detail: "Thông tin đơn vị sản xuất",
            code: "Mã đơn vị sản xuất",
            name: "Tên đơn vị sản xuất",
            nameWorker: "Người quản lý",
            affiliatedCenter: "Trực thuộc trung tâm sản xuất",
            note: "Ghi chú",
            status: "Đang hoạt động",
            listWorker: {
                listWorkerName: "Danh sách công nhân",
                code: "MÃ CÔNG NHÂN",
                name: "HỌ TÊN",
                position: "CHỨC VỤ",
                work: "CÔNG VIỆC",
                active: "ĐANG HOẠT ĐỘNG",
                note: "GHI CHÚ",
                noneDataTable: "Không có dữ liệu hiển thị"
            },
            listMachine: {
                listMachineName: "Danh sách máy móc",
                code: "MÃ MÁY MÓC",
                name: "TÊN MÁY MÓC",
                wattage: "CÔNG SUẤT",
                active: "ĐANG HOẠT ĐỘNG",
                note: "GHI CHÚ",
                noneDataTable: "Không có dữ liệu hiển thị"
            }
        },
        validate: {
            name: {
                required: "Tên đơn vị sản xuất không được bỏ trống",
                maxLength: "Tên đơn vị sản xuất không được quá 100 ký tự",
            },
            code: {
                type: "Mã đơn vị sản xuất không chứa kí tự đặc biệt hoặc khoảng trống",
                text: "Mã ĐVSX độ dài tối đa 9 ký tự UPPER,chỉ gồm số và chữ, ko khoảng trắng",
                required: "Mã đơn vị sản xuất không được bỏ trống",
                maxLength: "Mã đơn vị sản xuất không được quá 9 ký tự",
            },
            workercenter: {
                required: "Trực thuộc trung tâm sản xuất không được bỏ trống",
            },
            note: {
                maxLength: "Ghi chú không được quá 255 ký tự",
            },
        },
        delete: {
            title: "Xoá đơn vị sản xuất",
            description: "Bạn có chắc chắn muốn xóa đơn vị sản xuất này?",
        },
        btn: {
            add_new: "Thêm mới",
            close: "Đóng",
            cancel: "Huỷ",
            add: "Thêm mới",
            update: "Cập nhật"
        },
        toastjs: {
            createSuccess: "Tạo mới ĐVSX thành công",
            editSuccess: "Chỉnh sửa ĐVSX thành công",
            deleteSuccess: "Xoá ĐVSX thành công",
            codeRepeat: "Mã ĐVSX đã bị trùng, không thể lấy mã ĐVSX này",
            codeCharacters: "Mã ĐVSX độ dài tối đa 9 ký tự UPPER,chỉ gồm số và chữ, ko khoảng trắng"
        },
        confirm: {
            createSuccess: "Tạo mới ĐVSX thành công",
            updateSuccess: "Cập nhật ĐVSX thành công",
            deleteSuccess: "Xóa thành công ĐVSX",
            //comfirmText: "Version đang được sử dụng, không được xóa",
            comfirmTextDeleted: "Đơn vị sản xuất đã có dữ liệu liên kết, không thể xóa",
        }
    },
};