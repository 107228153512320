export const GET_PAGING_CAT_BARCODE_API = "/api/service/CatBarcode/Search";
export const DELETE_CAT_BARCODE_API = "/api/service/CatBarcode/Delete?Id=";
export const CREATE_CAT_BARCODE_API = "/api/service/CatBarcode/Create";
export const CREATE_LIST_CAT_BARCODE_API = "/api/service/CatBarcode/CreateList";
export const UPDATE_CAT_BARCODE_API = "/api/service/CatBarcode/Update";
export const ISEXIST_CAT_BARCODE_API = "/api/service/CatBarcode/IsExist";

export const ISEXIST_BARCODE_CAT_BARCODE_API = "/api/service/CatBarcode/IsExistBarcode";

export const GET_LIST_DATA_UNIT_API = "/api/service/CatBarcode/GetListData";
export const DOWNLOAD_TEMPLATE_CAT_BARCODE_API = "/api/service/CatBarcode/downloadTemplate";

export const IMPORT_TEMPLATE_CAT_BARCODE_API = "/api/service/CatBarcode/ImportTemplate";

export const GET_LIST_FIELD_CAT_BARCODE_API = "/api/service/CatBarcode/GetAllFieldBarcode";