import { menuTranslation } from "./vi/menu-translation";
// import { worker } from "./vi/Worker";
import { base } from "./vi/Base";
import { baseExampleLag } from "./en/baseExampleLag";
import { delivery } from "./vi/Delivery";
import { ApproveStepLaguage } from "src/shared/constants/locales/vi/MMProduction/ApproveStep";
import { sopLag } from "./vi/MMProduction/SOP/sopLag";
import { navbarTime } from "./vi/MMProduction/TimeManager/navbarTime";
import { calendarManager } from "./vi/MMProduction/TimeManager/CalendarManager";
import { BomLag } from 'src/shared/constants/locales/vi/MMProduction/BOM/BomLag';
import { requirementLag } from './vi/MMProduction/ProductionRequirement/requirementLag'
import { WorkareaLinkProccessLag } from './vi/MMProduction/WorkareaLinkProccess/WorkareaLinkProccessLag';
import { RescheduleProductionVI } from './vi/MMProduction/RescheduleProduction/RescheduleProductionVI';
import { Machine } from './vi/MaterialManager/Machine/MachineVI';
import { workAreaLag } from './vi/MMProduction/ProductionManagement/WorkAreaLag';
import { workCenterLag } from "./vi/MMProduction/ProductionManagement/WorkCenterLag";
import { workUnitLag } from "./vi/MMProduction/ProductionManagement/WorkUnitLag";
import { timeScheduleManager } from "./vi/MMProduction/TimeManager/TimeScheduleManager";
import { timeShiftManager } from "./vi/MMProduction/TimeManager/TimeShiftManager";
import { timeManagerType } from "./vi/MMProduction/TimeManager/TimeManagerType";
import { timeGroupWorkDay } from "./vi/MMProduction/TimeManager/TimeGroupWorkDay";
import { approval } from './vi/MMProduction/Approval/approval';
import { sidebar } from "./vi/layout/sidebar";
import { packingLevelLag } from "./vi/MMProduction/ProductManager/Pack/packingLevelLag";
import { packingProcessLag } from "./vi/MMProduction/ProductManager/Pack/packingProcessLag";
import { reprintStampsLag } from "./vi/MMProduction/ProductManager/Pack/ReprintStampsLag";
import { cancelStampLag } from "./vi/MMProduction/ProductManager/Pack/cancelStampLag";
import { ProductPackagingLag } from "./vi/MMProduction/ProductManager/Pack/productPackagingLag";
import { ProcessLag } from "./vi/MMProduction/Process/ProcessLag";
import { ProductIdentifier } from "./vi/MMProduction/ProductIdentifier/ProductIdentifier";
import { LotQA } from "./vi/QuanlityManager/LotQA";
import { checkListLg } from "./vi/QuanlityManager/checklist";
import { recordResultLag } from "./vi/QuanlityManager/RecordResult";
import { checkpointlg } from "./vi/QuanlityManager/checkpoint";
import { repairLag } from "./vi/QuanlityManager/repair";
import { productionCoordinator } from "./vi/MMProduction/productionCoordinator/productionCoordinator";
import { recordResult } from "./vi/MMProduction/resultRecord";
import { ProductionLineLaguage } from "./vi/MMProduction/ProductionLine/ProductionLine";
import { PeriodLag } from "./vi/Maintaince/PeriodLag";
import { machineryRepairLag } from "./vi/QuanlityManager/machineryRepairLag";
import { skillLag } from "./vi/MMProduction/Worker/SkillLag";
import { worker } from "./vi/MMProduction/Worker/Worker";
import { NCCInformation } from "./vi/MaterialManager/NCCInformation/NCCInformationVI";
import { LocationLag } from "./vi/MaterialManager/Location/LocationVI";
import { UnitVI } from "./vi/MaterialManager/Unit/UnitVI";
import { PackagetypeManager } from "./vi/MaterialManager/PackagetypeManager/PackagetypeManager";
import { PackageInforLag } from "./vi/MaterialManager/PackageInfor/PackageInforLag";
import { accessoriesLag } from "./vi/MaterialManager/AccessoriesManager/AccessoriesLag";
import { poLag } from "./vi/MaterialManager/PO/poLag";
import { movementType } from "./vi/MaterialManager/MovementType/MovementTypeVN";
import { Standardpacking } from "./vi/MaterialManager/Standardpacking/StandardpackingVI";
import { accessoriesTypeLag } from "./vi/MaterialManager/AccessoriesManager/AccessoriesTypeLag";
import { UnitCoversionLag } from "./vi/MaterialManager/AccessoriesManager/UnitConversionLag";
import { tranferSloc } from "./vi/MaterialManager/TranferSloc/TranferSloc";
import { StoreLocationScreen } from "./vi/MaterialManager/StorageLocationManager/StorageLocationScreen";
import { RequestFormManager } from "./vi/MaterialManager/RequestFormManager/RequestFormManagerVI";
import { TakenPackageTicketVI } from "./vi/MaterialManager/TakenPackageTicket/TakenPackageTicketVI";
import { takenPackagePageLag } from "./vi/MaterialManager/TakenPackagePage/TakenPackagePageLag";
import { Productmm } from "./vi/MMProduction/ProductManager/Product/ProductVi";
import { Modelmm } from "./vi/MMProduction/ProductManager/Model/ModelVi";
import { ItemAllocation } from "./vi/MMProduction/ItemAllocation/ItemAllocation";
import { PetitionerManager } from "./vi/MaterialManager/PetitionerManager/PetitionerManagerVI";
import { MaintenanceScheduleLag } from "./vi/Maintaince/MaintenanceScheduleLag";
import { inventoryCheckLag } from "./vi/MaterialManager/InventoryCheck/InventoryCheckLag";
import { approvalList } from "./vi/Administration/ApprovalsList/approvalsListVI";
import { ApprovalConfig } from "./vi/Administration/ApprovalSetting/ApprovalConfig/ApprovalConfig";
import { ApprovalProcess } from "./vi/Administration/ApprovalSetting/ApprovalProcess/ApprovalProcess";
export const translationVI = Object.assign(
  {},
  menuTranslation,
  worker,
  delivery,
  base,
  baseExampleLag,
  sopLag,
  ApproveStepLaguage,
  BomLag,
  requirementLag,
  calendarManager,
  navbarTime,
  WorkareaLinkProccessLag,
  RescheduleProductionVI,
  Machine,
  workAreaLag,
  workCenterLag,
  workUnitLag,
  timeScheduleManager,
  timeShiftManager,
  timeManagerType,
  timeGroupWorkDay,
  approval,
  packingLevelLag,
  packingProcessLag,
  reprintStampsLag,
  cancelStampLag,
  ProductPackagingLag,
  ProcessLag,
  sidebar,
  ProductIdentifier,
  LotQA,
  checkListLg,
  recordResultLag,
  checkpointlg,
  repairLag,
  productionCoordinator,
  recordResult,
  ProductionLineLaguage,
  PeriodLag,
  machineryRepairLag,
  skillLag,
  NCCInformation,
  LocationLag,
  UnitVI,
  PackagetypeManager,
  PackageInforLag,
  accessoriesLag,
  poLag,
  movementType,
  Standardpacking,
  accessoriesTypeLag,
  UnitCoversionLag,
  tranferSloc,
  StoreLocationScreen,
  RequestFormManager,
  TakenPackageTicketVI,
  takenPackagePageLag,
  Productmm,
  Modelmm,
  ItemAllocation,
  PetitionerManager,
  MaintenanceScheduleLag,
  inventoryCheckLag,
  approvalList,
  ApprovalConfig,
  ApprovalProcess,
);
