import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import * as type from "./enum/CheckListTypeApi";
export async function getDataPagingCheckListService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DATA_PAGING_CHECKLIST_API, data);
}
export async function getDetailDataCheckListService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DETAIL_DATA_CHECKLIST_API + data);
}
export async function createCheckListService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.CREATE_CHECKLIST_API, data);
}
export async function updateCheckListService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.UPDATE_CHECKLIST_API, data);
}