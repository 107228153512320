import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_ASSETMENT_INVENTORY_RESULTS_API,
    GET_DETAIL_INVENTORY_RESULTS_API,
    GET_ASSETMENT_INVENTORY_INFO_API,
    UPDATE_ASSETMENT_INVENTORY_INFO_API,
    GET_SUMMARY_RESULT_INVENTORY_API,
    GET_INVENTORY_ASSET_SCAN_API,
    INVENTORY_ASSET_SUBMIT_REPORT_API,
    INVENTORY_COMPLETION_CONFIRMATION_API,
    CREATE_MANUAL_INVENTORY_ASSET_API,
    EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_API,
    EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_API
} from "./enum/TypeResultInventoryApi.js";

export async function getAssetmentInventoryResultService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_ASSETMENT_INVENTORY_RESULTS_API, request)
}
export async function getDetailInventoryResultService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_DETAIL_INVENTORY_RESULTS_API, request)
}
export async function getAssetmentInventoryInfoService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_ASSETMENT_INVENTORY_INFO_API, request)
}
export async function updateAssetmentInventoryInfoService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + UPDATE_ASSETMENT_INVENTORY_INFO_API, request)
}
export async function getSummaryResultInventoryService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_SUMMARY_RESULT_INVENTORY_API + "?inventoryPlanSessionItemId=" + request)
}
export async function getInventoryAssetScanService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_INVENTORY_ASSET_SCAN_API + "?inventoryPlanSessionItemId=" + request.inventoryPlanSessionItemId + "&assetId=" + request.assetId + "&type=" + request.type)
}
export async function confirmationInventoryCompletionService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + INVENTORY_COMPLETION_CONFIRMATION_API, request)
}
export async function submitReportService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + INVENTORY_ASSET_SUBMIT_REPORT_API, request)
}
export async function createManualInventoryAssetService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + CREATE_MANUAL_INVENTORY_ASSET_API, request)
}
export async function exportExcelInventoryResultDetailService(request) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + EXPORT_EXCEL_INVENTORY_RESULT_DETAIL_API, request
    );

}
export async function exportExcelInventoryAssetInformationService(request) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + EXPORT_EXCEL_INVENTORY_ASSET_INFORMATION_API, request
    );

}