import * as type from "./enum/PackingLevelTypeAction";
const stateDefault = {
    listData: [],
    packingLevel:{},
    callResultFromApi: false,
    callApiPackingLevelById:false,
}

const PackingLevelReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case type.SET_PAGING_PACKING_LEVEL_REDUCER:
            if (action.data) {                
                state.listData = action.data;
            }
            return {
                ...state,
            };
        case type.SET_PACKING_LEVEL_BY_ID_REDUCER:
            if (action.data) {
                state.packingLevel = action.data;
                state.callApiPackingLevelById = true;
            }
            return {
                ...state,
            }
        case type.CALL_RESULT_PACKING_LEVEL_REDUCER:
            state.callResultFromApi = true;
            return {
                ...state,
            };
        case type.CALL_DEFAULT_PACKING_LEVEL_REDUCER:
            state.callResultFromApi = false;
            state.callApiPackingLevelById = true;
            return {
                ...state,
            };
        case type.CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };
        default:
            return {
                ...state,
            };
    }
};
export default PackingLevelReducer;