export const GET_DATA_MAINTAINCE_CALENDAR_API = "/api/maintenance-management-service/period/get-paging";
export const DELETE_PERIOD_MAINTANCE_API = "/api/maintenance-management-service/period/delete?Periodcode=";
export const GET_DETAIL_PERIOD_BY_CODE_API = "/api/maintenance-management-service/period/get-detailt?Periodcode=";
export const CREATE_PERIOD_MAINTANCE_API = "/api/maintenance-management-service/period/insert-period";
export const UPDATE_PERIOD_MAINTANCE_API = "/api/maintenance-management-service/period/update-period";
export const GET_DATA_PAGING_MAINTANCE_SCHEDULE_API = "/api/maintenance-management-service/maintenances-chedule/get-paging";
export const CREATE_PERIOD_SCHEDULE_API = "/api/maintenance-management-service/maintenances-chedule/get-period-machine-by-month?date=";
export const CREATE_MAINTANCE_SCHEDULE_API = "/api/maintenance-management-service/maintenances-chedule/maintenance-schedule-create";
export const GET_DETAIL_MAINTANCE_SCHEDULE_API = "/api/maintenance-management-service/maintenances-chedule/maintenance-get-detailt";
export const CARRY_MAINTANCE_SCHEDULE_API = "/api/maintenance-management-service/maintenances-chedule/carry-out-maintenance";
export const SKIP_MAINTANCE_SCHEDULE_API = "/api/maintenance-management-service/maintenances-chedule/skip-maintenance";