export const PeriodLag = {
    language: "en",
    periodlag: {
        placeholdersearchperiod: "Cycle code, cycle name, machine code, machine name",
        cycledeclaration: "Declare a cycle",
        cycledetail: "Cycle details",
        addnvl: "Add components",
        table: {
            cyclecode: "Cycle Code",
            cyclename: "Cycle name",
            machinecode: "Machine code",
            machinename: "Machine name",
            maintenancetype: "Maintenance type",
            cycletime: "Cycle time",
            cycleunit: "Cycle unit",
            nearestmaintenance: "Recent maintenance",
            nextmaintenance: "Next Maintenance",
        },
        form: {
            confirmdelete: "Confirm delete maintenance cycle",
            confirmdeletetrue: "Are you sure you want to delete this maintenance cycle?",
            cycletime0: "Cycle time must be greater than 0",
            timemaintance0: "Time to perform maintenance must be greater than 0",
            qtymaterial0: "Number of components must be greater than 0",
            entercyclecode: "Enter cycle code",
            entercyclename: "Enter cycle name",
            selectmachine: "Select machine",
            selectmaintenancetype: "Select maintenance type",
            entercycle: "Enter cycle",
            selectunit: "Select units",
            maintenancepurpose: "Maintenance purpose",
            entermaintenancepurpose: "Enter maintenance purpose",
            timemaintance: "Estimated time to perform maintenance (hours)",
            enterhours: "Enter hours",
            consumablesupplies: "Consumables",
            accessory: "Components",
            amount: "Amount",
            selectaccessory: "Select components",
            enterquantity: "Enter quantity",
            nodata: "No data",
        },
        rules: {
            quantityinterger: "Quantity must be a positive integer",
            quantityrequired: "Quantity cannot be left blank",
            accessoryrequired: "Component cannot be empty",
            coderequired: "Cycled code cannot be empty",
            namerequired: "Cycle name cannot be empty",
            machinerequired: "Machine cannot be blank",
            maintancetyperequired: "Maintenance type cannot be left blank",
            periodinterger: "The period must be a positive integer",
            cyclerequired: "Cycle cannot be empty",
            unitrequired: "The unit of time cannot be left blank",
            mantaincetimeinterger: "Maintenance time is a positive integer",
            maitancetimerequired: "Maintenance time cannot be empty",
            maitancepurposerequired: "Maintenance purpose cannot be left blank",
        },
        message: {
            deletesuccess: "The maintenance cycle has been deleted",
            createSuccess: "New maintenance cycle successful",
            updatemaintance: "Update maintenance cycle successful",
            blankreasonrequired: "The reason for skipping cannot be left blank",
            reasonmax: "Ignore reason cannot exceed 500 characters",
            receiverrequired: "Do not leave the operator blank",
            dateactionrequired: "Do not leave execution date blank",
            excuiterequired: "Do not leave execution time blank",
            pricerequired: "Maintenance costs cannot be left blank",
            exqtyrequired: "The expected count cannot be left blank",
            realqty: "Actual count cannot be empty",
            namemaxlength: "The period name cannot exceed 150 characters",
            mucdich: "Purpose cannot exceed 500 characters",
        }
    }
};