import {
    GET_DATA_MAINTAINCE_CALENDAR_SAGA, SET_DATA_MAINTAINCE_CALENDAR_REDUCER, DELETE_PERIOD_MAINTANCE_SAGA, CALL_DELETE_PERIOD_SUCCESS_REDUCER,
    GET_DETAIL_PERIOD_SAGA, SET_OBJ_DETAIL_PERIOD, CREATE_PERIOD_MAINTANCE_SAGA, CALL_CREATE_OR_UPDATE_PERIOD_SUCCESS_REDUCER, UPDATE_PERIOD_MAINTANCE_SAGA,
    GET_DATA_MAINTAINCE_SCHEDULE_SAGA, SET_DATA_MAINTAINCE_SCHEDULE_REDUECR, CREATE_PERIOD_SCHEDULE_SAGA, SET_LIST_DATA_PERIOD_SCHEDULE_REDUCER,
    CREATE_MAINTAINCE_SCHEDULE_SAGA, CREATE_UPDATE_SCHEDULE_SUCCESS_REDUCER, GET_DETAIL_MAINTANCE_SCHEDULE_SAGA, SET_DETAIL_MAINTANCE_SCHEDULE_REDUCER,
    CARRY_MAINTANCE_SCHEDULE_SAGA, SKIP_MAINTANCE_SCHEDULE_SAGA, CHANGE_DATA_MAINTAIN_REDUCER
} from "./enum/MaintainTypeAction"
const MaintainceAction = {
    getDataMaintainceCalendarSaga: (search, index, size) => {
        return {
            type: GET_DATA_MAINTAINCE_CALENDAR_SAGA,
            search: search,
            index: index,
            size: size,
        }
    },
    setDataMaintainceCalendarReducer: (data) => {
        return {
            type: SET_DATA_MAINTAINCE_CALENDAR_REDUCER,
            data: data,
        }
    },
    deletePeriodMaintance: (Periodcode, dataPage) => {
        return {
            type: DELETE_PERIOD_MAINTANCE_SAGA,
            Periodcode: Periodcode,
            dataPage: dataPage,
        }
    },
    callDeletePeriodSuccess: () => {
        return {
            type: CALL_DELETE_PERIOD_SUCCESS_REDUCER,
        }
    },
    getDetailDataMaintainceCalendarSaga: (Periodcode) => {
        return {
            type: GET_DETAIL_PERIOD_SAGA,
            Periodcode: Periodcode,
        }
    },
    callGetDetailPeriodSuccess: (obj) => {
        return {
            type: SET_OBJ_DETAIL_PERIOD,
            obj: obj,
        }
    },
    createPeriodMaintance: (dataRequest, dataPage) => {
        return {
            type: CREATE_PERIOD_MAINTANCE_SAGA,
            dataRequest: dataRequest,
            dataPage: dataPage,
        };
    },
    updatePeriodMaintance: (dataRequest, dataPage) => {
        return {
            type: UPDATE_PERIOD_MAINTANCE_SAGA,
            dataRequest: dataRequest,
            dataPage: dataPage,
        };
    },
    callCreateOrUpdateSuccess: () => {
        return {
            type: CALL_CREATE_OR_UPDATE_PERIOD_SUCCESS_REDUCER,
        };
    },
    getDataPagingMaintanceSchedule: (dataPage) => {
        return {
            type: GET_DATA_MAINTAINCE_SCHEDULE_SAGA,
            dataPage: dataPage,
        }
    },
    setDataPagingMaintanceSchedule: (data) => {
        return {
            type: SET_DATA_MAINTAINCE_SCHEDULE_REDUECR,
            data: data,
        };
    },
    createPeriodScheduleSaga: (date) => {
        return {
            type: CREATE_PERIOD_SCHEDULE_SAGA,
            date: date,
        };
    },
    setListPeriodScheduleReducer: (data) => {
        return {
            type: SET_LIST_DATA_PERIOD_SCHEDULE_REDUCER,
            data: data,
        }
    },
    createMaintanceSchedule: (dataRequest) => {
        return {
            type: CREATE_MAINTAINCE_SCHEDULE_SAGA,
            dataRequest: dataRequest,
        };
    },
    callCreateOrUpdateSchedule: () => {
        return {
            type: CREATE_UPDATE_SCHEDULE_SUCCESS_REDUCER,
        };
    },
    getDetailMaintanceSchedule: (code, detail) => {
        return {
            type: GET_DETAIL_MAINTANCE_SCHEDULE_SAGA,
            code: code,
            detail: detail,
        };
    },
    setDetailMaintanceSchedule: (data, detail) => {
        return {
            type: SET_DETAIL_MAINTANCE_SCHEDULE_REDUCER,
            data: data,
            detail: detail,
        };
    },
    carryMaintanceSchedule: (dataModal) => {
        return {
            type: CARRY_MAINTANCE_SCHEDULE_SAGA,
            dataModal: dataModal,
        };
    },
    skipMaintanceSchedule: (maintanceScheduleCode, noteSkipMaintance) => {
        return {
            type: SKIP_MAINTANCE_SCHEDULE_SAGA,
            maintanceScheduleCode: maintanceScheduleCode,
            note: noteSkipMaintance,
        };
    },
    setChangedata: () => {
        return {
            type: CHANGE_DATA_MAINTAIN_REDUCER,
        }
    },
    callDefault: () => {
        return {
            type: 'default',
        }
    }
}
export default MaintainceAction