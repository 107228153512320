import * as type from "./enum/RecordResultTypeAction";
const stateDefault = {
    callResultFromApi: false,
    listSampleDetail: [],
    listSample: [],
    isCreateData: false,
    isCallDropDown: false,
    dropDown: [],
    isSaveSuccess:false,
}
const RecordResultReducer = (
    state = stateDefault,
    action
) => {
    switch (action.type) {
        case type.CALL_RESULT_RECORD_FROM_API:
            state.callResultFromApi = true
            return {
                ...state,
            }
        case type.CALL_DEFAULT_RECORD_FROM_API:
            state.callResultFromApi = false;
            state.isSaveSuccess=false;
            state.listSample = [];
            state.listSampleDetail = [];
            state.dropDown = [];
            state.isCallDropDown = false;
            return {
                ...state,
            }
        case type.GET_LIST_SAMPLE_DETAIL_REDUCER:
            if (action.data) {
                state.listSampleDetail = action.data;
            }
            return {
                ...state,
            };
        case type.GET_LIST_SAMPLE_REDUCER:
            if (action.data) {
                state.listSample = action.data;
            }
            return {
                ...state,
            };
        case type.DROPDOWN_BY_CODE_REDUCER:
            state.isCallDropDown = true;
            if (action.data) {
                state.dropDown = action.data;
            }
            return {
                ...state,
            };
        case type.CALL_CHANGE_SUCCESS:
            state.isSaveSuccess=true;
            return {
                ...state,
            }
        default:
            return {
                ...state,
            };
    }
}
export default RecordResultReducer;