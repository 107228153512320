import {
  GET_PAGING_WAREHOUSE_SAGA,
  GET_PAGING_WAREHOUSE_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_WAREHOUSE_SAGA,
  DELETE_WAREHOUSE_REDUCER,
  UPDATE_WAREHOUSE_SAGA,
  CALL_CREATE_UPDATE_SUCCESS_REDUCER,
  CREATE_WAREHOUSE_SAGA,
  GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_SAGA,
  GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_REDUCER,
  GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_SAGA,
  GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_REDUCER,
  GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_REDUCER,
  GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_SAGA,
} from "./enum/TypeCatWarehouseAction";

const CatWarehouseAction = {
  //#region Lấy danh sách kho theo 1 mã bệnh viện
  getListDataWarehouseByHospitalIdSaga: (data) => {
    return {
      type: GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_SAGA,
      data: data
    };
  },

  getListDataWarehouseByHospitalIdReducer: (data) => {
    return {
      type: GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_REDUCER,
      data: data,
    };
  },

  getMainWarehouseByHospitalIdSaga: (hospitalId) => {
    return {
      type: GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_SAGA,
      hospitalId: hospitalId
    };
  },

  getMainWarehouseByHospitalIdReducer: (data) => {
    console.log(("gia trị action.data", data));

    return {
      type: GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_REDUCER,
      data: data,
    };
  },
  //#endregion
  
  //#region  Lấy danh sách kho theo danh sách mã bệnh viện
  getListWarehouseByListHospitalIdSaga: (data) => {
    console.log("action",data);
    return {
      type: GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_SAGA,
      data: data,
    };
  },

  getListWarehouseByListHospitalIdReducer: (data) => {
    return {
      type: GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_REDUCER,
      data: data,
    };
  },
  //#endregion

  getPagingSaga: (pageIndex, pageSize, searchTerm = "", catHospitalIDList = [], listType = [], listStatus = []) => {
    return {
      type: GET_PAGING_WAREHOUSE_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
      catHospitalIDList: catHospitalIDList,
      listType: listType,
      listStatus: listStatus
    };
  },

  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_WAREHOUSE_REDUCER,
      data: data,
    };
  },
  deleteDataSaga: (id) => {
    return {
      type: DELETE_WAREHOUSE_SAGA,
      id: id,
    };
  },

  deleteDataReducer: () => {
    return {
      type: DELETE_WAREHOUSE_REDUCER,
    };
  },
  createDataSaga: (data) => {
    return {
      type: CREATE_WAREHOUSE_SAGA,
      data: data,
    };
  },
  updateDataSaga: (data) => {
    return {
      type: UPDATE_WAREHOUSE_SAGA,
      data: data,
    };
  },
  callCreateUpdateSuccessReducer: () => {
    return {
      type: CALL_CREATE_UPDATE_SUCCESS_REDUCER,
    };
  },

  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },
};

export default CatWarehouseAction;
