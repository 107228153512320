export const GET_LIST_INVENTORY_AUDIT_PLAN_API = "/api/service/InventoryAuditPlan/Search";
export const DELETE_INVENTORY_AUDIT_PLAN_API = "/api/service/InventoryAuditPlan/Delete";
export const CREATE_INVENTORY_AUDIT_PLAN_API = "/api/service/InventoryAuditPlan/Create";
export const UPDATE_INVENTORY_AUDIT_PLAN_API = "/api/service/InventoryAuditPlan/Update";
export const GET_DETAIL_INVENTORY_AUDIT_PLAN_API = "/api/service/InventoryAuditPlan/GetById";
export const GET_DETAIL_INVENTORY_AUDIT_PLAN_BY_CODE_API = "/api/service/InventoryAuditPlan/GetByCode";
export const IMPORT_INVENTORY_AUDIT_PLAN_API = "/api/service/InventoryAuditPlan/ImportPlan";

export const GET_LIST_INVENTORY_AUDIT_DETAIL_API = "/api/service/InventoryAuditDetail/Search";
export const EXPORT_LIST_DETAIL_BY_AUDIT_PLAN_API = "/api/service/InventoryAuditDetail/ExportListByAuditPlan";
