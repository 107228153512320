import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_PAGING_WAREHOUSE_API,
  DELETE_WAREHOUSE_API,
  CREATE_WAREHOUSE_API,
  UPDATE_WAREHOUSE_API,
  GET_LIST_DATA_BY_HOSPITAL_ID_API,
  GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_API,
  GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_API,
} from "./enum/TypeCatWarehouseApi.js";

//API lấy danh sách kho theo 1 mã bệnh viện truyền vào
export async function getListDataWarehouseByHospitalIdService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_DATA_BY_HOSPITAL_ID_API + "?CatHospitalId=" + data?.hospitalId + "&&isGetAll=" + data?.isGetAll
  );
}

//API lấy danh sách kho theo 1 mã bệnh viện truyền vào
export async function getMainWarehouseByHospitalIdService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_API + "?CatHospitalId=" + data
  );
}

//API lấy danh sách kho theo danh sách mã bệnh viện truyền vào
export async function getListWarehouseByListHospitalIdService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_API + "?isGetAll=" + data.isGetAll,
     data.listHospitalId
  );
}

export async function getPagingService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_PAGING_WAREHOUSE_API,
    data
  );
}

export async function deleteCatWarehouseService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_WAREHOUSE_API + data
  );
}
export async function updateCatWarehouseService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_WAREHOUSE_API,
    data
  );
}
export async function createCatWarehouseService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_WAREHOUSE_API,
    data
  );
}
