import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_DATA_PAGING_API, GET_DROPDOWN_PRODUCT_PROCESS_API, GET_DROPDOWN_BOMSLOT_PROCESS_API, GET_DROPDOWN_SOP_PROCESS_API, CREATE_PROCESS_API,
    GET_DETAIL_VERSION_PROCESS_API, GET_DROPDOWN_VERSION_PROCESS_API, DELETE_VERSION_PROCESS_API, CHANGE_VERSION_PROCESS_API
} from "./enum/ProcessTypeService"
export async function getDataPagingService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DATA_PAGING_API, data);
}
export async function getDropdownProductService(request) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DROPDOWN_PRODUCT_PROCESS_API + request);
}
export async function getDropdownBomslotService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DROPDOWN_BOMSLOT_PROCESS_API + data);
}
export async function getDropdownSopService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DROPDOWN_SOP_PROCESS_API + data);
}
export async function createProcessService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + CREATE_PROCESS_API, request);
}
export async function getDetailVersionProcessService(request) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_DETAIL_VERSION_PROCESS_API, request);
}
export async function getDropdownVersionProcessService(id) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_DROPDOWN_VERSION_PROCESS_API + id);
}
export async function deleteVersionProcessService(id) {
    return await CommonBase.postAsync(configApp.apiGateWay + DELETE_VERSION_PROCESS_API + id);
}
export async function changeVersionProcessService(id) {
    return await CommonBase.getAsync(configApp.apiGateWay + CHANGE_VERSION_PROCESS_API + id);
}