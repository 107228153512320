import { CALL_DEFAULT_DEMO_HOOK_REDUCER, CALL_RESULT_DEMO_HOOK_REDUCER, DELETE_DATA_REDUCER, GET_DETAIL_DATA_REDUCER, GET_PAGING_DEMO_HOOK_REDUCER, CLEAR_ALL_REDUCER_STATE } from "./enum/hookDemoTypeAction";

const stateDefault = {
  isFetching: false,
  isFetchingMore: false,
  listData: [],
  objectData: {},
  callResultFromApi: false,
  callResultDetail: false,
  isDeleteSuccess: false,
}

const ReactHookDemoReducer = (
  state = stateDefault,
  action
) => {
  switch (action.type) {
    case GET_PAGING_DEMO_HOOK_REDUCER:
      if (action.data) {
        state.listData = action.data;
      }
      return {
        ...state,
      };

    case GET_DETAIL_DATA_REDUCER:
      state.callResultDetail = true;
      if (action.obj) {
        state.objectData = action.obj.data;
      }
      return {
        ...state,
      };

    case CALL_RESULT_DEMO_HOOK_REDUCER:
      state.callResultFromApi = true;
      return {
        ...state,
      };

    case CALL_DEFAULT_DEMO_HOOK_REDUCER:
      state.callResultFromApi = false;
      state.callResultDetail = false;
      state.isDeleteSuccess = false;

      state.objectData = null;
      return {
        ...state,
      };
    case DELETE_DATA_REDUCER:
      state.isDeleteSuccess = true;
      return {
        ...state,
      };
    case CLEAR_ALL_REDUCER_STATE:
      return {
        state : stateDefault,
      }
    default:
      return {
        ...state,
      };
  }
};

export default ReactHookDemoReducer;
