export const workCenterLag = {
    language: "vn",
    workCenter: {
        workTitle: "Trung tâm sản xuất",
        table: {
            code: "Mã TTSX",
            name: "Tên TTSX",
            SlocworkcenterCode: "Mã kho TTSX",
            nameWorker: "Người quản lý",
            type: "Loại",
            status: "Đang hoạt động",
            note: "Ghi chú",
            affiliatedArea: "KVSX trực thuộc",
            action: "Thao tác",
            dropdownStatus: {
                all: "Tất cả",
                active: "Đang hoạt động",
                nonActive: "Không hoạt động",
            },
            noneDataTable: "Không có dữ liệu hiển thị",
        },
        search: {
            placeHolder: {
                code: "Mã TTSX",
                name: "Tên TTSX",
                SlocworkcenterCode: "Mã kho TTSX",
                nameWorker: "Người quản lý",
                type: "Loại",
                note: "Ghi chú",
                affiliatedArea: "KVSX trực thuộc",
            },
        },
        form: {
            add_new: "Thêm trung tâm sản xuất",
            update: "Cập nhật trung tâm sản xuất",
            see_detail: "Thông tin trung tâm sản xuất",
            code: "Mã trung tâm sản xuất",
            name: "Tên trung tâm sản xuất",
            nameWorker: "Người quản lý",
            affiliatedArea: "Trực thuộc khu vực sản xuất",
            type: "Loại",
            note: "Ghi chú",
            status: "Đang hoạt động",
            listProduct: {
                listProductName: "Danh sách sản phẩm",
                code: "Mã sản phẩm",
                name: "Tên sản phẩm",
                processName: "Tên quy trình",
                productivity: "Năng suất",
                time: "Thời gian setup( giờ)",
                noneDataTable: "Không có dữ liệu hiển thị",
            },
        },
        validate: {
            name: {
                required: "Tên trung tâm sản xuất không được bỏ trống",
                maxLength: "Tên không được quá 100 ký tự",
            },
            code: {
                type: "Mã TTSX không chứa kí tự đặc biệt hoặc khoảng trống",
                text: "Mã TTSX độ dài tối đa 9 ký tự UPPER, gồm số và chữ, không khoảng trắng",
                required: "Mã TTSX không được bỏ trống",
                maxLength: "Mã TTSX không được quá 9 ký tự",
            },
            worker: {
                required: "Người quản lý không được bỏ trống",
            },
            workerarea: {
                required: "Khu vực sản xuất không được bỏ trống",
            },
            type: {
                required: "Loại không được bỏ trống",
            },
            note: {
                maxLength: "Ghi chú không được quá 255 ký tự",
            },
        },
        delete: {
            title: "Xoá trung tâm sản xuất",
            description: "Bạn có chắc chắn muốn xóa trung tâm sản xuất này?",
        },
        btn: {
            add_new: "Thêm mới",
            close: "Đóng",
            cancel: "Huỷ",
            add: "Thêm mới",
            update: "Cập nhật"
        },
        toastjs: {
            createSuccess: "Tạo mới TTSX thành công",
            editSuccess: "Sửa TTSX thành công",
            deleteSuccess: "Xoá TTSX thành công",
            codeRepeat: "Mã TTSX đã bị trùng, không thể lấy mã KVSX này",
            codeCharacters: "Mã TTSX độ dài tối đa 9 ký tự UPPER,chỉ gồm số và chữ, ko khoảng trắng"
        },
        confirm: {
            createSuccess: "Tạo mới TTSX thành công",
            updateSuccess: "Cập nhật TTSX thành công",
            deleteSuccess: "Xóa thành công TTSX",
            //comfirmText: "Version đang được sử dụng, không được xóa",
            comfirmTextDeleted: "Trung tâm sản xuất đã có dữ liệu liên kết, không thể xóa",
        }
    },
};