export const takenPackagePageLag = {
    language: "en",
    takenPackagePage: {
        nodataTable: "No data to display",
        scanPackageCode: {
            placeHolder: "Coupon code",
            Or: "Or",
            FormPickingTicket: {
                title: "Choose a voucher",
                btn: "Select the vote from the list",
                Pickinglistcode: "Coupon code",
                Sloctransferto: "Delivery area to",
                Action: "Manipulation",
                placeHolder: {
                    Pickinglistcode: "Enter the coupon code",
                    Sloctransferto: "Enter the shipping area to",
                    btn: "Search",
                }
            },
        },
        ListPicking: {
            titleInputPositionTakenPackage: "Scan pick up location",
            placeHolder: {
                positionTakenPackage: "Pick-up location",
                packageCode: "Enter the package code",
                qtyTaken: "Enter the pick up quantity",
            },
            btn: {
                endLocation: "Finish picking up at the location",
                moveToNextLocation: "Move to a new location",
                checkBoxGetAll: "Take it all",
                confirmDoneLocation: "Finish picking up",
            },
            table: {
                Sloctakegood: "Slot pick up goods",
                Goodscode: "Commodity codes",
                Qtypicking: "Quantity to take",
                Qtytaken: "Amount taken",
                Unit: "Unit",
                Action: "Manipulation",
            },
            error: {
                errorQty: "Invalid quantity!",
            },
            toastJs: {
                isDisablePositionsTakenInput: "Pick up location is vacant",
                isDisablePackageCodeInput: "Package code is empty",
                positionTakenPackage: "Pick up location is vacant",
                isAvaibleToTakePostion: "Pick up location cannot be left empty",
                postionEmpty: "This position has been taken before",
                isLocationExist: "The position entered does not exist in the list of votes",
                positionTakenPackageEmpty: "Pick up location cannot be left empty",
                packageCode: "Package code cannot be left blank",
                qtyTaken: "Quantity cannot be blank !",
                qtyValue: "Invalid number !",
                packageCodeAfterScan: "The number of imports is larger than the number in package",
                qtySum: "The number of items taken will exceed the required quantity",
                isAvailbleQtyToUpdate: "Quantity taken exceeds the Quantity to be taken",
                Sloctransferto: "Invalid storage location",
                Slocstored: "This package does not belong to the pickup location",
                isGoodCodeAvaible: "The item code of the package does not match the pick up ticket at the current location",
                isPackageInPickingList: "Quantity is not eligible to take",
                isPackageInPickingListError: "Package is not in the pick up slot",
                Package: "Package is not valid",
                callPickingNextLocation: "Move location successfully",
                callPickingNextLocationTrue: "Pick up goods according to successful coupons",
            }
        },
        PopUpComfirmIfExistPackageNotTaken: {
            title: "The list of items to be picked up at the location is not complete",
            table: {
                Goodscode: "Code",
                Qtypicking: "Number of requests",
                Qtytaken: "Amount taken",
                Des: "Explanation",
                placeHolder: {
                    Des: "Enter cause",
                },
            },
            error: {
                Des: "Explanation cannot be left blank",
            },
        },
        PopUpComfirmAllPackage: {
            titleNotgetAll: "Confirm not taking all packages",
            titleGetAll: "Confirm to get all packages",
            comfirmNotgetAll: "Are you sure you don't get all packages ?",
            comfirmGetAll: "Did you confirm getting all the packages ?",
        },
        LocationInput: {
            slocTranferTo: "Enter storage location",
        },
        DeletePackageForm: {
            title: "Delete the information you just entered",
            table: {
                Sloctakegood: "Slot pick up goods",
                Goodscode: "Commodity codes",
                Takeallpackage: "Get the whole package",
                Qtytaken: "Amount taken",
                Action: "Manipulation",
            },
            toast: {
                deleteSucces: "Successful deletion of information",
            }
        },
        BtnConfirmTicket: {
            btn: "Choose",
            title: "Confirm pick up receipt",
            confirm: "Are you sure you want to choose this coupon?"
        }
    },
}