
export const ProcessLag = {
    language: "vi",
    process: {
        productplace: 'Chọn sản phẩm',
        seconds: "Giây",
        deleteall: "Xóa hết",
        stepnamebesame: "Tên bước không thể trùng nhau",
        nonestep: "Chưa có bước nào được tạo",
        havestepinfor: "Chưa nhập đầy đủ thông tin cho bước ",
        notlatestversion: "Quy trình không có version mới nhất",
        latestversion: "Version mới nhất chưa được phê duyệt",
        addnew: "Thêm mới",
        changeversion: "Đổi version",
        processname: "Tên quy trình",
        product: "Sản phẩm",
        browser: "Trình duyệt",
        versionbrowse: 'Không có version mới nhất ở trạng thái "Tạo mới" để trình duyệt',
        placeholder: "Tên quy trình, sản phẩm, version đang sử dụng, version mới nhất, trạng thái version mới nhất",
        newversionexist: "Đã có version mới nhất",
        form: {
            machinedulicape: "Không thể chọn máy móc trùng lặp",
            productrequired: "Sản phẩm không được để trống",
            addnewprocess: "Thêm mới quy trình sản xuất",
            addnewver: "Thêm mới version",
            reviewver: "Xem lại version quy trình",
            infor: "Thông tin chung",
            accessory: "Linh kiện",
            labor: "Nhân công",
            machine: "Máy móc",
            declare: "Khai báo version",
            document: "Tài liệu",
            close: "Đóng",
            namestep: "Chưa nhập tên bước",
            time: "Thời gian thao tác",
            condition: "Điều kiện để thực hiện ",
            stepthatall: ": Các bước đáp ứng TẤT CẢ điều kiện:",
            conditionand: 'Nhóm điều kiện ',
            conditiontype: "Loại điều kiện",
            step: "Bước",
            value: "Giá trị (phút)",
            valuestep: "Giá trị",
            addconand: 'Thêm nhóm "VÀ"',
            addconor: 'Thêm nhóm "HOẶC"',
            stepblank: "Tên bước không được bỏ trống",
            stepname: "Tên bước",
            addstep: "Thêm bước",
            deletestep: "Xóa bước",
            totaldoc: "Tổng số tài liệu: ",
            filename: "Tên tài liệu",
            docdes: "Mô tả",
            add: "Thêm dòng",
            totalmachine: "Tổng số máy móc: ",
            machinetype: "Loại máy móc",
            qty: "Số lượng",
            totalmaterial: "* Số lượng tiêu hao nguyên liệu sau khi trải qua bước hiện tại",
            codematerial: "Mã SP hoặc NVL",
            qtyma: "Lượng sử dụng tại bước",
            unit: "Đơn vị",
            totalworker: "Tổng số nhân công tại bước sản xuất: ",
            skill: "Kỹ năng",
            checkslot: "Dòng trước đó chưa được nhập đủ dữ liệu",
            and: "VÀ",
            or: "HOẶC",
        },
        message: {
            createsuccess: "Tạo mới quy trình thành công",
            deleteversion: "Xóa version thành công",
            changeversion: "Đổi version mới thành version hiện hành thành công",
        },
    }
}