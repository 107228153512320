import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import { toast } from 'react-toastify';
import * as type from "./enum/ProductPackagingTypeAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import ProductPackagingAction from "./ProductPackagingAciton";
import {
    getDropdownProductPackagingService,
    getDropDownPackingLevelService,
    getPackageInfoService,
    getPackageDetailService,
    getBelongInfoService,
    deletePackageService
} from "./ProductPackagingService";
export function* getDropdownProductPackaging() {
    try {
        const response = yield call(getDropdownProductPackagingService)
        if (response?.isSuccess) {
            yield put(ProductPackagingAction.setDropdownProductPackaging(response.data))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDropdownPackingLevel(data) {
    try {
        const response = yield call(getDropDownPackingLevelService, data.request);
        if (response?.isSuccess) {
            yield put(ProductPackagingAction.setDropdownPackingLevelReducer(response.data))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getPackageInfoSaga(data) {
    try {
        const result = yield call(getPackageInfoService, data.request);
        if (result.isSuccess) {
            if (data.request.Repalacetem) {
                toast.success(i18next.t("ProductPackaging.toast.reprintSuccess"), {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.success(i18next.t("ProductPackaging.toast.createSuccess"), {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            yield put(ProductPackagingAction.isCallSuccessFromApi());
        }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
}
export function* getPackageDetailSaga(data) {
    try {
        const response = yield call(getPackageDetailService, data.request);
        if (response?.isSuccess) {
            yield put(ProductPackagingAction.setPackageDetailReducer(response.data));
            yield put(ProductPackagingAction.isCallSuccessFromApi());
        } else {
            yield put(ProductPackagingAction.callFalseFromApi());
        }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
}
export function* getBelongInfoSaga(data) {
    try {
        const response = yield call(getBelongInfoService, data.beLongCode, data.level);
        if (response?.isSuccess) {
            yield put(ProductPackagingAction.setBelongInfoReducer(response.data));
            yield put(ProductPackagingAction.callResultFromApiReducer());
            closeLoading();
        } else {
            yield put(ProductPackagingAction.callFalseFromApi());
        }
    } catch (error) {
        closeLoading();

    }
}
export function* deletePackageSaga(data) {
    try {
        const result = yield call(deletePackageService, data.request);
        if (result.isSuccess) {
            toast.success(i18next.t("ProductPackaging.toast.deleteSuccess"), {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(ProductPackagingAction.isCallSuccessFromApi());
            closeLoading();
        }
    } catch (error) {
        closeLoading();

    }
}
export default function* ProductPackagingSaga() {
    yield takeEvery(type.GET_DROPDOWN_PRODUCT_PACKAGING_SAGA, getDropdownProductPackaging);
    yield takeEvery(type.GET_DROPDOWN_PACKING_LEVEL_SAGA, getDropdownPackingLevel);
    yield takeEvery(type.GET_PACKAGE_INFO_SAGA, getPackageInfoSaga);
    yield takeEvery(type.GET_PACKAGE_DETAIL_SAGA, getPackageDetailSaga);
    yield takeEvery(type.GET_BELONG_INFO_SAGA, getBelongInfoSaga);
    yield takeEvery(type.DELETE_PACKAGE_SAGA, deletePackageSaga);
}