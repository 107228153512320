export const sidebar = {
    language: "vn",
    sidebarItem: {
        homePage: 'Trang chủ',
        administration: {
            title: 'Quản trị',
            decentralization: 'Phân quyền',
            approve: 'Phê duyệt',
            approvalProcess: 'Quy trình phê duyệt',
            approveInfo: 'Thông tin phê duyệt',
        },

        productManagement: {
            title: 'Quản lý sản xuất',
            baseInfomation: {
                title: 'Dữ liệu chung',
                workarea: 'Trung tâm sản xuất',
                linkingProcessWithArea: 'Liên kết TTSX và quy trình',
                workCalendar: 'Lịch làm việc',
                productionProcess: 'Quy trình sản xuất',
                bom: 'Bom',
                sop: 'Sop',
                colectInfomation: 'Dữ liệu thu thập',
            },
            productPlan: {
                title: 'Kế hoạch sản xuất',
                productionOrder: 'Yêu cầu sản xuất',
                makePlanProduction: 'Lập kế hoạch sản xuất',
                remakePlanProduction: 'Lập lịch sản xuất lại',
            },
            coordinateProduction: {
                title: 'Điều phối sản xuất',
                productionOrder: 'Yêu cầu sản xuất',
            },
            activeProduction: {
                title: 'Thực thi sản xuất',
                product: 'Sản phẩm',
                nameTheProduct: 'Định danh sản phẩm',
                recordResult: 'Ghi nhận kết quả',
                paking: 'Đóng gói',
                recordError: 'Ghi nhận lỗi',
            },
        },
        warehouseManagement: {
            title: 'Quản lý kho',
            baseInfomation: {
                title: 'Dữ liệu chung',
                machine: 'Máy móc',
                location: 'Location',
                movementType: 'Movement type',
                accessories: 'Linh kiện',
                unit: 'Đơn vị',
                partner: 'Đối tác',
                package: 'Package',
                standardPackage: 'Tiêu chuẩn đóng gói',
                packageType: 'Kiểu đóng gói',
                productSource: 'Nguồn yêu cầu xuất - chuyển kho',
            },
            podo: {
                title: 'PO/DO',
                po: 'Đơn đặt hàng PO',
                do: 'Đơn giao hàng DO'
            },
            storePackage: {
                title: 'Nhập kho',
                takingPackage: 'Nhận hàng',
                createTicketStorePackage: 'Tạo phiếu cất hàng',
                storePackageIntoWarehouse: 'Cất hàng đã nhận vào kho'
            },
            changeWarehouse: {
                title: 'Chuyển kho',
                changeWarehouse: 'Chuyển hàng nội bộ kho',
            },
            exportPackage: {
                title: 'Xuất kho',
                packageOrderMove: 'Quản lý yêu cầu xuất - chuyển kho',
                moveOrderTicket: 'Phiếu xuất - chuyển kho',
                movePackageByTicket: 'Xuất - chuyển kho theo phiếu'
            },
            checking: {
                title: 'Kiểm kê / kiểm đếm',
                checking: 'Kiểm kê / kiểm đếm',
                doingChecking: 'Thực hiện kiểm kê / kiểm đếm',
            },
            report: {
                title: 'Báo cáo',
                inventoryReport: 'Báo cáo tồn kỳ',
                inventoryReportWH: 'Báo cáo tồn kho',
            },
        },
    }
};
