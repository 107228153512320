export const packingProcessLag = {
    language: "en",
    packingProcess: {
        packTitle: "Packing Specifications",
        table: {
            code: "Finished product code",
            name: "Finished product name",
            packingProcess: "Packing Specifications",
            status: "Status",
            action: "Action",
            nodata: "No data to show!"
        },
        form: {
            add_new: "Add new packing specifications",
            update: "Editing packing specifications",
            edit: "Packaging Details",
            finishedProductPack: "Finished product packaging",
            table: {
                packingLevel: "Packing level",
                quota: "Quota",
                unit: "Unit",
            },
            btn_add: "Add new level",
            status: "Active",
            delete: {
                title: "Clear packing level",
                confirm: "Are you sure you want to remove this packing level?",
            },
            error_add: "Need to add packing level before creating new",
        },
        placeHolder: {
            search: "Finished product code, finished product name",
            finishedProductPack: "Finished product packaging",
            packingLevel: "Packing level",
            quota: "Quota",
            unit: "Unit",
        },
        validate: {
            productCode: {
                required: "Finished products cannot be left blank",
            },
            packingid: {
                required: "Packing level cannot be left blank",
            },
            qty: {
                required: "Quantity cannot be left blank",
                numberOnly: "The required quantity must be of type positive integer",
                numberBiggerZero: "The number of requests must be greater than 0",
                maxLength: "No more than 10 characters",
            },
            status: {
                change: "The finished product already exists in an active state, the active state needs to be turned off before creating a new one",
            }
        },
        toast: {
            createSuccess: "Successfully created new packing specification",
            updateSuccess: "Update packing specification successfully",
            deleteSuccess: "Clear the packing specification successfully",
        },
        btn: {
            add_new: "Create new",
            add: "Create",
            update: "Update",
            close: "Cancel",
            cancel: "Close",
        },
        delete: {
            title: "Clear packing specification",
            description: "Are you sure you want to remove this packing specification?",
        },
    }
}