export const ProcessLag = {
    language: "en",
    process: {
        productplace: 'Select product',
        seconds: "Seconds",
        deleteall: "Delete all",
        stepnamebesame: "Step names cannot be the same",
        nonestep: "No steps have been created yet",
        havestepinfor: "Did not enter enough information for ",
        notlatestversion: "The process does not have the latest version",
        latestversion: "The latest version has not been approved yet",
        addnew: "Add new",
        changeversion: "Change version",
        processname: "Process name",
        product: "Product",
        browser: "Browser",
        versionbrowse: 'There is no latest version in "Tạo mới" state for browsing',
        placeholder: "Process name, product, version in use, latest version, latest version status",
        newversionexist: "The latest version is available",
        form: {
            machinedulicape: "Cannot select duplicate machines",
            productrequired: "Product cannot be left blank",
            addnewprocess: "Add new production process",
            addnewver: "Add new version",
            reviewver: "Review process version",
            infor: "General information",
            accessory: "Accessory",
            labor: "Labor",
            machine: "Machine",
            declare: "Declare version",
            close: "Close",
            namestep: "No step name entered",
            time: "Operation time",
            condition: "Conditions for performing ",
            stepthatall: ": Steps that meet ALL conditions:",
            conditionand: 'Condition group ',
            conditiontype: "Condition type",
            step: "Step",
            value: "Value (minutes)",
            valuestep: "Value",
            addconand: 'Add group "AND"',
            addconor: 'Add group "OR"',
            stepblank: "The step name cannot be left blank",
            stepname: "Step name",
            addstep: "Add new step",
            deletestep: "Delete step",
            totaldoc: "Total number of documents: ",
            filename: "Document name",
            docdes: "Description",
            document: "Document",
            add: "Add line",
            totalmachine: "Total number of machines: ",
            machinetype: "Type of machine",
            qty: "Amount",
            totalmaterial: "* Material consumption amount after going through the current step",
            codematerial: "Product or material code",
            qtyma: "Amount used at step",
            unit: "Unit",
            totalworker: "Total number of workers at production step: ",
            skill: "Skill",
            checkslot: "The previous line has not been entered enough data",
            and: "AND",
            or: "OR",
        },
        message: {
            createsuccess: "Create a new success process",
            deleteversion: "Delete version successfully",
            changeversion: "Change the new version to the current version successfully",
        },
    }
}