export const ProductIdentifier = {
    language: "en",
    productidentifier: {
        batchidentified: "Products cannot be batch-identified",
        shipmentrequired: "The number of finished products in a batch must be more than 1",
        dinhdanhsanpham: "Print identification stamps",
        manageridentifier: "Manage identifiers",
        search1: "Enter workorder code, workorder name",
        prtintbyproduct: "Print stamps according to products",
        prtintbyshipmnet: "Print stamps in batches",
        productinlot: "Quantity of finished products/lot: ",
        workorderdetail: "Work order identifier details",
        workordercode: "Workorder code",
        workordername: "Workorder name",
        numberhasidentifier: "Number of products identified",
        identifiercode: "Identifier code",
        numberproductincode: "Product number/ code",
        reprintnumber: "Number of reprints",
        notelast: "The reason for the last reprint",
        change: "Change",
        totalfg: "Total product",
        singleprint: "Single print",
        multiprint: "Print a lot",
        changeshipmentsuccess: "Change the shipment information of the product successfully",
        identifiersuccess: "Product identifier enforcement successful",
        reprintsuccess: "Execute stamp reprint successfully",
        explancemaxlength: "Explanation of the reason for reprinting should not exceed 255 characters",
        form: {
            explancerequired: "Explain why reprinting is required",
            confirmreprint: "Confirm reprint",
            back: "Come back",
            confirm: "Confirm",
            explanceting: "Explain the reason for reprinting",
            temrequired: "The number of stamps printed cannot be left blank",
            identifier: "Identifier code: ",
            temprint: "Enter the number of stamps to print",
            confirmprint: "Print confirmation",
            numberstamps: "Number of stamps to print",
            frintall: "Print all",
            temthan1: "The number of stamps to be printed must be greater than 1",
            temnumberrequired: "The number of stamps to be printed must be of positive integer type",
        }
    }
};