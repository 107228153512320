import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_PAGING_INVENTORY_USER_CONFIG_SAGA,
    CREATE_UPDATE_INVENTORY_USER_CONFIG_SAGA,
} from "./enum/TypeInventoryUserConfigAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import InventoryUserConfigAction from "./InventoryUserConfigAction";
import {
    getPagingService,
    createUpdateDataService
} from "./InventoryUserConfigService";


export function* getPagingSaga(data) {
    try {
        const request = {
            PageIndex: data?.request?.pageIndex,
            PageSize: data?.request?.pageSize,
            SegmentId: data?.request?.segmentId,
            CostCenterId: data?.request?.costCenterId,
        };
        const response = yield call(getPagingService, request);
        if (response) {
            if (response.Items.length <= 0 && request.PageIndex > 1) {
                yield put(InventoryUserConfigAction.getPagingSaga(request));
            } else {
                yield put(InventoryUserConfigAction.getPagingReducer(response));
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }

}
export function* CreateUpdateDataSaga(requet) {
    try {
        if (requet) {
            const requestAPI = {
                Id: requet?.data?.Id,
                CostCenterId: requet?.data?.CostCenterId,
                UserInventory: requet?.data?.UserInventory ? requet?.data?.UserInventory.join(",") : "",
                UserMonitor2: requet?.data?.UserMonitor2 ? requet?.data?.UserMonitor2 : ""
            }
            const response = yield call(createUpdateDataService, requestAPI);
            if (response.isSuccess) {
                yield put(InventoryUserConfigAction.CallCreateUpdateSuccessReducer());
            }
            closeLoading();
        }
    } catch (error) {
        closeLoading();
    }
}

export default function* InventoryUserConfigSaga(data) {
    yield takeEvery(GET_PAGING_INVENTORY_USER_CONFIG_SAGA, getPagingSaga);
    yield takeEvery(CREATE_UPDATE_INVENTORY_USER_CONFIG_SAGA, CreateUpdateDataSaga);
}
