export const approval = {
  language: "en",
  approval: {
    qtpdrequisred: "Approval cannot be blank",
    approvalrequired: "Must select at least 1 approval process",
    createapp: "Create new approval successfully",
    title: "Select approval process",
    textlabel_1: "Process name",
    placeholder_1: "Select process name",
    btn_add: "Add new",
    search: 'Search approval by name or status',
    status_paging: {
      all: 'All',
      wait: 'Waiting to approval',
      approved: 'Has already approval',
      reject: 'Reject approval'
    },
    status_Type: {
      wait: 'Waiting to approval',
      approved: 'Has already approval',
      approving: 'Approving',
      reject: 'Canceled',
    },
    table: {
      name: 'Document name',
      sending_date: 'Sending date',
      sending_by: 'Sending by',
      type: 'Type of approval',
      status: 'Status',
      action: "Action"
    },
    form: {
      back: 'Approval the document',
      version: 'Version',
      effected_date: 'Effective date',
      expired_date: 'Expiration date',
      product: 'File name',
      note: 'Note',
      file: 'Attached document file',
      btn_detail: 'See for more detail',
      btn_approval: 'Approve',
      btn_reject: 'Reject',
      btn_close: 'Back',
      approve_success: "Approve successful",
      popup: {
        approve: {
          title: 'Confirm to approve',
          placeHolder: 'Enter the reason of approved decision',
        },
        reject: {
          title: 'Confirm to reject',
          placeHolder: 'Enter the reason of rejected decision',
        },
      }
    },
    validate: {
      approveResult: "The reason for the approval cannot be left blank",
      approveLevel: "Level name cannot be left blank",
      department: "Department name cannot be left blank",
      approve: "Approved by cannot be left blank",
    }
  },
};
