import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { CHECK_DUPLICATED_MATERIAL_SAGA, SET_CHECK_DUPLICATED_ERROR_REDUCER, CHECK_DUPLICATED_PRODUCTION_SAGA } from "./enum/CheckduplicatedAction";
import { CHECK_DUPLICATED_API_MATERIAL, CHECK_DUPLICATED_API_PRODUCTION, CHECK_DUPLICATED_MAINTANCER_API } from "./enum/CheckduplicatedAPI";
const CommonCheckduplicatedAction = {
    checkDuplicatedMaterial: (request) => {
        return {
            type: CHECK_DUPLICATED_MATERIAL_SAGA,
            request: request,
        };
    },
    setCheckDuplicatedMaterial: (data) => {
        return {
            type: SET_CHECK_DUPLICATED_ERROR_REDUCER,
            data: data,
        };
    },
    checkDuplicatedProduction: (request) => {
        return {
            type: CHECK_DUPLICATED_PRODUCTION_SAGA,
            request: request,
        };
    },

    callDefault: () => {
        return {
            type: "default",
        };
    },

    async callActionToValidateDuplicateFormData(keyWord = "", errorText, nameColumnToCheck = "", value = "", typeTable = 0, typeDataBase = 0) {
        if (!keyWord || !nameColumnToCheck || !value || typeTable == 0 || typeDataBase == 0) {
            // neu cac thong tin param thieu se tra ve fail
            return false;
        }
        let checkErrorParam = {
            "table": typeTable, // enum bảng để check trùng
            "fieldname": nameColumnToCheck, // tên cột check trùng
            "valuecheck": value.trim(), // giá trị
            "key": keyWord // key để bắt lỗi và trả về
        }
        let apiDataBase = ""; // kiểu api để tra cứu thông tin check trùng
        if (typeDataBase == 1) {
            apiDataBase = CHECK_DUPLICATED_API_PRODUCTION;
        }
        else if (typeDataBase == 2) {
            apiDataBase = CHECK_DUPLICATED_API_MATERIAL;
        }
        else if (typeDataBase == 3) {
            apiDataBase = CHECK_DUPLICATED_MAINTANCER_API;
        }
        let errorDuplicate = {
            key: keyWord, // key
            errorText: errorText,
        }
        const callApiCheckDuplicate = await CommonBase.postAsync(configApp.apiGateWay + apiDataBase, checkErrorParam);
        if (callApiCheckDuplicate.isSuccess && callApiCheckDuplicate.data.isError) {
            return errorDuplicate;
        } else {
            return false;
        }
    }
}
export default CommonCheckduplicatedAction;