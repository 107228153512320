export const GET_DATA_PAGING_SAGA = "GET_DATA_PAGING_SAGA";
export const SET_DATA_PAGING_REDUCER = "SET_DATA_PAGING_REDUCER";
export const GET_DROPDOWN_PRODUCT_PROCESS_SAGA = 'GET_DROPDOWN_PRODUCT_PROCESS_SAGA';
export const GET_DROPDOWN_PRODUCT_PROCESS_REDUCER = 'GET_DROPDOWN_PRODUCT_PROCESS_REDUCER';
export const GET_DROPDOWN_BOMSLOT_SAGA = "GET_DROPDOWN_BOMSLOT_SAGA";
export const GET_DROPDOWN_BOMSLOT_PROCESS_REDUCER = "GET_DROPDOWN_BOMSLOT_PROCESS_REDUCER";
export const GET_DROPDOWN_SOP_SAGA = "GET_DROPDOWN_SOP_SAGA";
export const GET_DROPDOWN_SOP_PROCESS_REDUCER = "GET_DROPDOWN_SOP_PROCESS_REDUCER";
export const CREATE_PROCESS_SAGA = "CREATE_PROCESS_SAGA";
export const CALL_CREATE_UPDATE_SUCCESS = "CALL_CREATE_UPDATE_SUCCESS";
export const GET_DETAIL_VERSION_PROCESS = "GET_DETAIL_VERSION_PROCESS";
export const SET_DETAIL_VERSION_PROCESS_REDUCER = "SET_DETAIL_VERSION_PROCESS_REDUCER";
export const GET_DROPDOWN_VERSION_PROCESS = "GET_DROPDOWN_VERSION_PROCESS";
export const SET_DROPDOWN_VERSION_PROCESS = "SET_DROPDOWN_VERSION_PROCESS";
export const IS_CALL_RENDER = 'IS_CALL_RENDER';
export const DELETE_VERSION_PROCESS = "DELETE_VERSION_PROCESS";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const CHANGE_VERSION_PROCESS_SAGA = "CHANGE_VERSION_PROCESS_SAGA";