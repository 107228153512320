import {
    GET_LOG_JOB_DATA_PAGINATION_CLEAR,
    GET_LOG_JOB_DATA_PAGINATION_REDUCER,
    GET_LOG_JOB_DATA_PAGINATION_SAGA,
    CALL_DEFAULT_REDUCER, CLEAR_DATA_REDUCER
} from "./enum/LogJobTypeAction";
// import {CALL_DEFAULT_REDUCER, CLEAR_DATA_REDUCER} from "../InventoryManagement/Inventory/enum/TypeInventoryAction";

const LogJobAction = {

    getPaginationSaga: (params) => {
        return {
            type: GET_LOG_JOB_DATA_PAGINATION_SAGA,
            params
        };
    },

    getPaginationReducer: (response) => {
        return {
            type: GET_LOG_JOB_DATA_PAGINATION_REDUCER,
            response
        };
    },

    getPaginationClear: () => {
        return {
            type: GET_LOG_JOB_DATA_PAGINATION_CLEAR
        };
    },

    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_REDUCER
        };
    },

    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER
        };
    }
}

export default LogJobAction