import { Breadcrumb } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useEffect, useState } from "react";
import { pageData } from "../sidebar/pageData";
function BreadcrumbComp(props) {
    const [breadcrumbs, setBreadcrums] = useState([]);

    useEffect(() => {
        // Cap nhap thay doi breadcrumb khi can ghi de
        // data force update example: ["Quan ly tai san", "Danh sach phe duyet", "Chinh sua"]
        // data sample: [{label: "text", url: "/danh-sach-tai-san"}]
        if(props.dataBreadcrumb && props.dataBreadcrumb?.length) {
            const breadCrumbList = props.dataBreadcrumb?.map((breadcrumbItem, index) => {
                if(breadcrumbItem?.url && breadcrumbItem?.label) {
                    return {
                        title: <a href={breadcrumbItem?.url} className="breadcrumb-link" >{breadcrumbItem?.label}</a>
                    }
                } else {
                    if(index < props.dataBreadcrumb?.length - 1) {
                        return {
                            title: <span className="breadcrumb-link-inactive">{breadcrumbItem?.label}</span>
                        }
                    } else if(index === props.dataBreadcrumb?.length - 1) {
                        return {title: <span className="breadcrumb-text">{breadcrumbItem?.label}</span>}
                    }
                }
            })
            setBreadcrums(breadCrumbList)
        }
    }, [props.dataBreadcrumb])

    useEffect(() => {
        const currentPage = pageData.find(element => element.to == props.location.pathname); 
        let parentPage, ancestorPage;
        switch (currentPage?.level) {
            case 0: 
                setBreadcrums([{title: <span className="breadcrumb-link">{currentPage.title}</span>}]);
                break;
            case 1: 
                parentPage = pageData.find(element => element.id == currentPage.parentId);
                setBreadcrums([
                    {title: <span className="breadcrumb-link">{parentPage?.title}</span>}, 
                    {title: <span className="breadcrumb-text">{currentPage.title}</span>}
                ]);
                break;
            case 2: 
                parentPage = pageData.find(element => element.id == currentPage.parentId);
                ancestorPage = pageData.find(element => element.id == currentPage.ancestor);
                setBreadcrums([
                    {title: <span className="breadcrumb-link">{ancestorPage?.title}</span>},
                    {title: <span className="breadcrumb-link">{parentPage?.title}</span>}, 
                    {title: <span className="breadcrumb-text">{currentPage.title}</span>}
                ]);
            default:
                break;
        }
    }, [props.history.location]);

    return (
        <Breadcrumb style={{margin: "20px auto auto 26px"}} items={[...breadcrumbs]}/>
    );
}


const mapDispatchToProps = (dispatch) => ({
});
const mapStateToProps = (state) => {
    return {
        dataBreadcrumb: state.BreadcrumbReducer.dataBreadcrumb,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BreadcrumbComp));
