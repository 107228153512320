import {
    GET_COMMON_DROPDOWN_MMPRODUCTION,
    SET_COMMON_DROPDOWN_MMPRODUCTION,
    GET_COMMON_DROPDOWN_MATERIAL,
    SET_COMMON_DROPDOWN_MATERIAL,
    GET_COMMON_DROPDOWN_MATAINER,
    SET_COMMON_DROPDOWN_MATAINER,
    GET_WORK_COMMON_DROPDOWN_BY_CODE_SAGA,
    GET_WORK_COMMON_DROPDOWN_BY_CODE_REDUCER,
} from "./enum/CommonDropdownAction";

const CommonDropDownAction = {
    // phân hệ quản lý sản xuất 
    getCommonDropdownMMProduction: (typeDropdown, searchTerm = "", typeIndentifier = "") => {
        return {
            type: GET_COMMON_DROPDOWN_MMPRODUCTION,
            typeDropdown: typeDropdown,
            searchTerm: searchTerm,
            typeIndentifier
        };
    },

    setCommonDropDownMMProduction: (typeDropdown, data, typeIndentifier = "") => {
        return {
            type: SET_COMMON_DROPDOWN_MMPRODUCTION,
            response: data,
            typeDropdown: typeDropdown,
            typeIndentifier
        };
    },

    // Nguyên vật liệu
    getCommonDropdownMaterial: (typeDropdown, searchTerm = "") => {

        return {
            type: GET_COMMON_DROPDOWN_MATERIAL,
            typeDropdown: typeDropdown,
            searchTerm: searchTerm,
        };
    },
    setCommonDropDownMaterial: (typeDropdown, data) => {
        return {
            type: SET_COMMON_DROPDOWN_MATERIAL,
            response: data,
            typeDropdown: typeDropdown,
        };
    },
    // Bảo trì bảo dưỡng
    getCommonDropdownMatainer: (typeDropdown, searchTerm = "") => {
        return {
            type: GET_COMMON_DROPDOWN_MATAINER,
            typeDropdown: typeDropdown,
            searchTerm: searchTerm,
        };
    },

    setCommonDropDownMatainer: (typeDropdown, data) => {
        return {
            type: SET_COMMON_DROPDOWN_MATAINER,
            response: data,
            typeDropdown: typeDropdown,
        };
    },
    getCommonDropdownByCodeSaga: (typeDropdown, searchTerm = "", Code = "", Status = "", key = "") => {
        return {
            type: GET_WORK_COMMON_DROPDOWN_BY_CODE_SAGA,
            typeDropdown: typeDropdown,
            searchTerm: searchTerm,
            Code,
            Status,
            key
        };
    },

    getCommonDropdownByCodeReducer: (typeDropdown, data, key = "") => {
        return {
            type: GET_WORK_COMMON_DROPDOWN_BY_CODE_REDUCER,
            response: data,
            typeDropdown: typeDropdown,
            key
        };
    },
    default: () => {
        return {
            type: 'default',
        }
    }
}

export default CommonDropDownAction;