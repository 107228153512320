// packages
import React, { memo, useRef, useState, useEffect } from "react";
import bind from "classnames/bind";
import classNames from "classnames";
import _ from "lodash";
import { Tooltip } from "antd";

// styles
import styles from "./AutoTooltipText.module.scss";

const cx = bind.bind(styles);

const AutoTooltipText = memo(
  ({ type = "default", className, text, toolTipProps = {} }) => {
    const [title, setTitle] = useState();

    const rootRef = useRef();

    useEffect(() => {
      if (_.isNil(rootRef.current)) {
        return;
      }

      if (rootRef.current.scrollWidth > rootRef.current.clientWidth) {
        setTitle(text);
      } else {
        setTitle(undefined);
      }
    }, [text]);

    return type === "antd" ? (
      <Tooltip title={title} {...toolTipProps}>
        <span className={classNames(cx("root"), className)} ref={rootRef}>
          {text}
        </span>
      </Tooltip>
    ) : (
      <span className={classNames(cx("root"), className)} ref={rootRef} title={title}>
        {text}
      </span>
    );
  }
);

export default AutoTooltipText;
