export const GET_PAGING_WLP_SAGA = "GET_PAGING_WLP_SAGA";
export const GET_PAGING_WLP_REDUCER = "GET_PAGING_WLP_REDUCER";
export const GET_DROPDOWN_PROCESS_BY_PRODUCT_CODE_SAGA = "GET_DROPDOWN_PROCESS_BY_PRODUCT_CODE_SAGA";
export const GET_DROPDOWN_PROCESS_REDUCER = "GET_DROPDOWN_PROCESS_REDUCER";
export const GET_DROPDOWN_PRODUCT_PROCESS = "GET_DROPDOWN_PRODUCT_PROCESS";
export const GET_DROPDOWN_PRODUCT_PROCESS_REDUCER = "GET_DROPDOWN_PRODUCT_PROCESS_REDUCER";
export const GET_DATA_TABLE_SAGA = "GET_DATA_TABLE_SAGA";
export const GET_DATA_TABLE_REDUCER = "GET_DATA_TABLE_REDUCER";
export const CHANGE_DATA_REDUCER = "CHANGE_DATA_REDUCER";
export const CREATE_NEW_SAGA = "CREATE_NEW_SAGA";
export const CALL_API_CREATE_OR_UPDATE = "CALL_API_CREATE_OR_UPDATE";
export const GET_DETAIL_WLP_SAGA = "GET_DETAIL_WLP_SAGA";
export const SET_OBJ_DETAIL = "SET_OBJ_DETAIL";
export const CHANGE_DATA_DEFAULT_REDUCER = "CHANGE_DATA_DEFAULT_REDUCER";
export const DELETE_WLP_SAGA = "DELETE_WLP_SAGA";
export const UPDATE_LINK_SAGA = "UPDATE_LINK_SAGA";


