export const GET_PAGING_WAREHOUSE_SAGA = "GET_PAGING_WAREHOUSE_SAGA";
export const GET_PAGING_WAREHOUSE_REDUCER = "GET_PAGING_WAREHOUSE_REDUCER";

export const DELETE_WAREHOUSE_SAGA = "DELETE_WAREHOUSE_SAGA";
export const DELETE_WAREHOUSE_REDUCER = "DELETE_WAREHOUSE_REDUCER";

export const CREATE_WAREHOUSE_SAGA = "CREATE_WAREHOUSE_SAGA";
export const UPDATE_WAREHOUSE_SAGA = "UPDATE_WAREHOUSE_SAGA";
export const CALL_CREATE_UPDATE_SUCCESS_REDUCER =
  "CALL_CREATE_UPDATE_SUCCESS_REDUCER";

export const CALL_DEFAULT_REDUCER = "CALL_DEFAULT_REDUCER";
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";

export const GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_SAGA =
  "GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_SAGA";
export const GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_REDUCER =
  "GET_LIST_DATA_WAREHOUSE_BY_HOSPITAL_ID_REDUCER";

export const GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_SAGA =
  "GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_SAGA";
export const GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_REDUCER =
  "GET_MAIN_WAREHOUSE_BY_HOSPITAL_ID_REDUCER";

  export const GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_SAGA =
  "GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_SAGA";
export const GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_REDUCER =
  "GET_LIST_WAREHOUSE_BY_LIST_HOSPITAL_ID_REDUCER";