export const GET_PAGING_SOP_SAGA = "GET_PAGING_SOP_SAGA";
export const CREATE_SOP_SAGA = "CREATE_SOP_SAGA";
export const UPDATE_SOP_SAGA = "UPDATE_SOP_SAGA";
export const GET_DETAIL_SOP_SAGA = "GET_DETAIL_SOP_SAGA";
export const CHANGE_VERSION_SOP_SAGA = "CHANGE_VERSION_SOP_SAGA";
export const GET_DROPDOWN_VERSION_SAGA = "GET_DROPDOWN_VERSION_SAGA";
export const DELETED_VERSION_SAGA = "DELETED_VERSION_SAGA";
export const ADD_NEW_VESION_SAGA = "ADD_NEW_VESION_SAGA";

export const GET_PAGING_SOP_REDUCER = "GET_PAGING_SOP_REDUCER";
export const CALL_DEFAULT_SOP_REDUCER = "CALL_DEFAULT_SOP_REDUCER";
export const CALL_RESULT_SOP_CREATE_REDUCER = "CALL_RESULT_SOP_CREATE_REDUCER";
export const CALL_RESULT_SOP_UPDATE_REDUCER = "CALL_RESULT_SOP_UPDATE_REDUCER";
export const CALL_RESULT_ADD_VERSION_REDUCER = "CALL_RESULT_ADD_VERSION_REDUCER";
export const GET_DETAIL_SOP_REDUCER = "GET_DETAIL_SOP_REDUCER";
export const CALL_CHANGE_VERSION_REDUCER = "CALL_CHANGE_VERSION_REDUCER";
export const GET_DROPDOWN_VERSION_REDUCER = "GET_DROPDOWN_VERSION_REDUCER";
export const DELETE_VERSION_REDUCER = "DELETE_VERSION_REDUCER";
export const CLEAR_ALL_SOP_REDUCER_STATE = "CLEAR_ALL_SOP_REDUCER_STATE";




