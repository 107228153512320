import i18next from 'i18next';
import { call, put, takeEvery } from 'redux-saga/effects';
import { closeLoading } from 'src/shared/common/LoadingAction';
import toastJs from 'src/shared/common/toastJs';
import BomAction from './BOMAction';
// import { statusChangeVersion } from "src/components/page/MMProduction/BOM/constant";

import {
    addNewVersionBomService,
    changeVersionService,
    createDataService,
    deleteVersionService,
    getDetailService,
    getDropdownProductService,
    getDropdownVersionService,
    getPagingService,
    getDescriptionMarterialService
} from './BOMServices';
import {
    ADD_NEW_VESION_BOM_SAGA,
    CHANGE_VERSION_BOM_SAGA,
    CREATE_BOM_SAGA,
    DELETED_VERSION_BOM_SAGA,
    GET_DETAIL_BOM_SAGA,
    GET_DROPDOWN_PRODUCT_SAGA,
    GET_DROPDOWN_VERSION_BOM_SAGA,
    GET_PAGING_BOM_SAGA,
    GET_DESCRIPTION_MATERIAL_SAGA
} from './enum/BOMTypeAction';
export function* getPagingSaga(data) {
    try {
        const pageSize = data.pageSize;
        const pageIndex = data.pageIndex;
        const searchTerm = data.searchTerm ?? "";
        const request = {
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
        };
        const response = yield call(getPagingService, request);
        if (response.isSuccess) {
            if (response.data.Items.length <= 0 && request.pageIndex > 1) {
                yield put(BomAction.getPagingSaga(request.pageIndex - 1, request.pageSize, request.searchTerm));
            } else {
                yield put(BomAction.getPagingReducer(response.data))
            }
        } else { }
        closeLoading()
    } catch (error) {
        closeLoading()

    }
}
export function* getDetailDataSaga(data) {
    const request = {
        bomid: data.bomCode,
        verid: data.versionId,
        Keyseach: data.searchItem
    }
    try {
        const objData = yield call(getDetailService, request);
        if (objData.isSuccess) {
            yield put(BomAction.returnDetailReducer(objData));
        }
        closeLoading()
    } catch (error) {
        closeLoading();

    }
}
export function* createBomSaga(data) {
    try {
        const request = [];
        data.data.listSlot.forEach((item, index) => {
            request.push({
                Bomcode: "",
                Bomname: "",
                Productcode: data.data.product,
                Slotname: `${index + 1}`,
                Slotdescription: item.descriptionSlot,
                Codespnvl: item.materialCode,
                CodespnvlDes: item.descriptionMaterial,
                Consumptionamount: item.amount,
                Unit: item.unit,
                Qtyproductcreate: item.numberOfProducts
            })
        })
        const res = yield call(createDataService, request);
        if (res) {
            toastJs.success(i18next.t("bom.toastjs.create_success"));
            yield put(BomAction.callResultFromApiReducer());
        }
        closeLoading();
    } catch (error) {
        closeLoading()

    }
}
export function* getDropdownProductSaga(data) {
    try {
        const searchtemp = data.searchtemp;
        const response = yield call(getDropdownProductService, searchtemp);
        if (response?.isSuccess) {
            yield put(BomAction.setDropdownProduct(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDropdownVersionSaga(data) {
    try {
        const response = yield call(getDropdownVersionService, data.bomCode);
        if (response?.isSuccess) {
            yield put(BomAction.getDropdownVersionReducer(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* deletedVersionSaga(data) {
    try {
        const result = yield call(deleteVersionService, data.versionId);
        if (result.isSuccess) {
            yield put(BomAction.deleteVersionReducer());
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* addNewVersionSaga(data) {
    try {
        const dataAddNew = data.data;
        const ListVerDetail = [];
        dataAddNew.listSlot?.forEach((item, index) => {
            if (item.isActive) {
                if (item.isChange == true) {
                    if (item.isSlotOld == true) {
                        let dataItem1 = {
                            Bomcode: data.bomCode,
                            Bomname: data.bomName,
                            Productcode: dataAddNew.productCode,
                            Productname: "",
                            Slotname: item.nameSlot,
                            Slotdescription: item.descriptionSlotNew,
                            Codespnvl: item.materialCodeNew,
                            CodespnvlDes: "",
                            Consumptionamount: item.amountNew,
                            Unit: item.unitNew,
                            Qtyproductcreate: item.numberOfProductNew,
                        };
                        ListVerDetail.push(dataItem1);
                    }
                    else {
                        let dataItem2 = {
                            Bomcode: data.bomCode,
                            Bomname: data.bomName,
                            Productcode: dataAddNew.productCode,
                            Productname: "",
                            Slotname: `${index + 1}`,
                            Slotdescription: item.descriptionSlotNew,
                            Codespnvl: item.materialCodeNew,
                            CodespnvlDes: "",
                            Consumptionamount: item.amountNew,
                            Unit: item.unitNew,
                            Qtyproductcreate: item.numberOfProductNew,
                        };
                        ListVerDetail.push(dataItem2);
                    }
                }
                else {
                    let dataItem3 = {
                        Bomcode: data.bomCode,
                        Bomname: data.bomName,
                        Productcode: dataAddNew.productCode,
                        Productname: "",
                        Slotname: item.nameSlot,
                        Slotdescription: item.descriptionSlot,
                        Codespnvl: item.materialCode,
                        CodespnvlDes: "",
                        Consumptionamount: item.amount,
                        Unit: item.unit,
                        Qtyproductcreate: item.numberOfProducts,
                    };
                    ListVerDetail.push(dataItem3);
                }
            }
        });
        let ChangeVerType = {};
        let dataCondition = data.dataCondition.Conditiontype;
        // if (dataCondition == statusChangeVersion.Manipulation) {
        //     let change = {
        //         Conditiontype: 1,
        //     }
        //     ChangeVerType = change;
        // }
        // if (dataCondition == statusChangeVersion.Time) {
        //     let change1 = {
        //         Conditiontype: 2,
        //         Timechange: data.dataCondition.Timechange,
        //         ListTypeAccessories: [],
        //     }
        //     ChangeVerType = change1;
        // }
        // if (dataCondition == statusChangeVersion.Inventory) {
        //     let dataList = [];
        //     data.dataCondition.ListTypeAccessories.forEach(item => {
        //         let list = {
        //             controlverid: item.Id,
        //             codespnvl: item.materialVersion,
        //             qty: item.amountMaterials
        //         }
        //         dataList.push(list);
        //     })
        //     let change = {
        //         Conditiontype: 3,
        //         ListTypeAccessories: dataList
        //     }
        //     ChangeVerType = change;
        // }
        const request = {
            ListVerDetail: ListVerDetail,
            ChangeVerType: ChangeVerType
        }
        const result = yield call(addNewVersionBomService, request);
        if (result.isSuccess) {
            yield put(BomAction.callResultAddVersionReducer());
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* changeVersionSaga(data) {
    try {
        const result = yield call(changeVersionService, data.bomCode);
        if (result.isSuccess) {
            yield put(BomAction.callChangeVersionReducer());
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDescriptionMarterial(listMachineCode) {
    try {
        const request = listMachineCode.listMachineCode;
        const response = yield call(getDescriptionMarterialService, request);
        if (response?.isSuccess) {
            yield put(BomAction.setDescriptionMarterialReducer(response.data));
        }
        //closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export default function* BomSaga(data) {
    yield takeEvery(GET_PAGING_BOM_SAGA, getPagingSaga);
    yield takeEvery(CREATE_BOM_SAGA, createBomSaga);
    yield takeEvery(GET_DETAIL_BOM_SAGA, getDetailDataSaga);
    yield takeEvery(GET_DROPDOWN_PRODUCT_SAGA, getDropdownProductSaga);
    yield takeEvery(GET_DROPDOWN_VERSION_BOM_SAGA, getDropdownVersionSaga);
    yield takeEvery(DELETED_VERSION_BOM_SAGA, deletedVersionSaga);
    yield takeEvery(ADD_NEW_VESION_BOM_SAGA, addNewVersionSaga);
    yield takeEvery(CHANGE_VERSION_BOM_SAGA, changeVersionSaga);
    yield takeEvery(GET_DESCRIPTION_MATERIAL_SAGA, getDescriptionMarterial);
}
