import _ from "lodash";
import { approval } from "../constants/locales/en/MMProduction/Approval/approval";

export const hasPermission = (listActionAccessible, action) => {
  if (_.isArray(listActionAccessible) && !_.isEmpty(listActionAccessible)) {
    return (
      _.findIndex(
        listActionAccessible,
        (permission) => permission === action
      ) >= 0
    );
  }
};

// *** dữ liệu phân quyền không được chỉnh sửa ***
export const listActionEnum = {
  danhsachpheduyet: {
    view: "danhsachpheduyet/xemchitiet",
    pheduyet: {
      taomataisan: "taomataisan",
      dieuchuyentaisannoibo: "dieuchuyentaisannoibo",
      dieuchuyentaisanlienvien: "dieuchuyentaisanlienvien",
      kiemketaisan: "kiemketaisan",
      pheduyetbaocaokiemke: "pheduyetbaocaokiemke",
      yeucautangtaisan: "yeucautangtaisan",
    },
  },
  
  // *** dữ liệu phân quyền không được chỉnh sửa ***
  danhsachtaisan: {
    view: "danhsachtaisan/chitiet",
    edit: "danhsachtaisan/chinhsua",
    printStamp: "danhsachtaisan/intem",
    import: "danhsachtaisan/import",
    export: "danhsachtaisan/export",
    editMultiAsset: "danhsachtaisan/editMultiAsset",
    importFromSAP: "danhsachtaisan/importFromSAP",
  },
  dieuchuyentaisan: {
    list: "yeucaudieuchuyen/danhsach",
    view: "yeucaudieuchuyen/xemchitiet",
    edit: "yeucaudieuchuyen/chinhsua",
    delete: "yeucaudieuchuyen/xoayeucau",
    createInternal: "yeucaudieuchuyen/noibo/taomoi",
    createExternal: "yeucaudieuchuyen/lienvien/taomoi",
    trinhduyet: "yeucaudieuchuyen/trinhduyet",
    chinhsuayeucauchoduyet: "yeucaudieuchuyen/chinhsuachoduyet",
  },
  kiemketaisan: {
    xemphieukiemke: "kiemketaisan/xemphieukiemke",
    suaphieukiemke: "kiemketaisan/suaphieukiemke",
    taomoiphieu: "kiemketaisan/taomoiphieukiemke",
    xoaphieukiemmke: "kiemketaisan/xoaphieukiemke",
    trinhduyet: "kiemketaisan/trinhduyet",
    batdaukiemke: "kiemketaisan/batdaukiemke",
    chidinhgiamsat: "kiemketaisan/chidinhgiamsat",
  },
  // *** dữ liệu phân quyền không được chỉnh sửa ***
  quytrinhpheduyet: {
    create: "quytrinhpheduyet/them",
    edit: "quytrinhpheduyet/sua",
    delete: "quytrinhpheduyet/xoa",
  },
  cauhinhquytrinhpheduyet: {
    create: "cauhinhquytrinhpheduyet/taomoi",
    edit: "cauhinhquytrinhpheduyet/chinhsua",
    delete: "cauhinhquytrinhpheduyet/xoa",
  },
  quanlynguoidung: {
    view: "quanlynguoidung/xemchitiet",
    edit: "quanlynguoidung/chinhsua",
  },
  nguongoctaisan: {
    create: "nguongoctaisan/them",
    edit: "nguongoctaisan/sua",
    delete: "nguongoctaisan/xoa",
  },
  huongdansudung: {
    create: "huongdansudung/them",
    edit: "huongdansudung/sua",
    delete: "huongdansudung/xoa",
  },
  chidinhnguoikiemkegiamsat: {
    edit: "chidinhnguoikiemkegiamsat/sua",
  },
  phanquyendulieu: {
    view: "phanquyendulieu/xemchitiet",
    create: "phanquyendulieu/them",
    edit: "phanquyendulieu/sua",
    delete: "phanquyendulieu/xoa",
  },
  testbang: {
    view: "testbang/xemchitiet",
    create: "testbang/them",
    edit: "testbang/sua",
    delete: "testbang/xoa",
  },
  trungtamngansach: {
    list: "trungtamngansach/danhsach",
    view: "trungtamngansach/xemchitiet",
    edit: "trungtamngansach/chinhsua",
  },
  sodotochuc: {
    danhsach: "sodotochuc/danhsach",
    themphongban: "sodotochuc/themphongban",
    xoaphongban: "sodotochuc/xoaphongban",
    themnguoidung: "sodotochuc/themnguoidung",
    xoanguoidung: "sodotochuc/xoanguoidung",
  },
  baocaokiemke: {
    view: "baocaokiemke/xemchitiet",
    list: "baocaokiemke/xemdanhsach",
    export: "baocaokiemke/xuatbaocao",
  },
  ketquakiemke: {
    list: "ketquakiemke/danhsach",
    view: "ketquakiemke/chitiet",
    viewAsset: "ketquakiemke/chitiet/asset",
    edit: "ketquakiemke/chinhsua",
    export: "ketquakiemke/export",
  },
  nhacungcap: {
    add: "nhacungcap/taomoi",
    list: "nhacungcap/xemdanhsach",
    view: "nhacungcap/xemchitiet",
    edit: "nhacungcap/chinhsua",
    delete: "nhacungcap/xóa",
  },
  cauhinhdonvikho:{
    change_active_warehouse: "cauhinhdonvikho/thaydoikhohoatdong"
  },
  danhmuckho: {
    view: "danhmuckho/xemchitiet",
    create: "danhmuckho/them",
    edit: "danhmuckho/sua",
    delete: "danhmuckho/xoa",
    list:"danhmuckho/xemdanhsach",
    listbyhospital:"danhmuckho/danhsachtheobenhvien",
    listbyuser:"danhmuckho/khovabenhvientheouser",
    select_hospital:"danhmuckho/chonbenhvien",
    view_all_type_warehouse: "danhmuckho/xemtatcaloaikho"
  },
  danhmucdonvi: {
    view: "danhmucdonvi/xemchitiet",
    create: "danhmucdonvi/them",
    edit: "danhmucdonvi/sua",
    delete: "danhmucdonvi/xoa",
    list:"danhmucdonvi/xemdanhsach"
  },
  donvitinh: {
    add: "donvitinh/taomoi",
    list: "donvitinh/xemdanhsach",
    view: "donvitinh/xemchitiet",
    edit: "donvitinh/chinhsua",
    delete: "donvitinh/xóa",
  },
  vattuyte: {
    add: "vattuyte/taomoi",
    list: "vattuyte/xemdanhsach",
    view: "vattuyte/xemchitiet",
    edit: "vattuyte/chinhsua",
    delete: "vattuyte/xóa",
    printStamp:"vattuyte/PrintStamp"
  },
  danhmuctrongkho: {
    list: "danhmuccotrongkho/danhsach",
    edit: "danhmuctrongkho/capnhat",
    import: "danhmuctrongkho/import",
    export: "danhmuctrongkho/export",
    view_all_data:"danhmuccotrongkho/xemtatcakho",
    edit_all_base_number:"danhmuccotrongkho/capnhattatcacoso"
  },
  barcodevattu: {
    list: "danhsach/barcode",
    import: "barcode/taomoidanhsach",
    edit: "barcode/capnhat",
    delete: "barcode/xoa",
    downloadtemplate: "barcode/taitemplate",
  },
  dutrutoanvien:{
    list:"dutrutoanvien/danhsach",
    view: "dutrutoanvien/view",
    create: "dutrutoanvien/create",
    edit: "dutrutoanvien/edit",
    delete: "dutrutoanvien/delete",
    proposalsummary:"dutrutoanvien/xemdexuat",
    view_all_record: "dutrutoanvien/xemtatcabenhvien"

  },
  dutrukhole: {
    list: "dutrukhole/danhsach",
    view: "dutrukhole/view",
    create: "dutrukhole/create",
    edit: "dutrukhole/edit",
    delete: "dutrukhole/xoa",
    view_all_record: "dutrukhole/xemtatcabenhvien"

  },
  phieulinh: {
    list: "quanlyphieulinh/danhsach",
    view: "quanlyphieulinh/view",
    create: "quanlyphieulinh/themmoi",
    edit: "quanlyphieulinh/capnhat",
    delete: "quanlyphieulinh/xoa",
    export:"quanlyphieulinh/exportexcel",
    view_all_hospital: "quanlyphieulinh/danhsach/xembenhvien",
    select_required_warehouse: "quanlyphieulinh/danhsach/chonkhoyeucau",
    approve:"quanlyphieulinh/pheduyet",
    issuance_of_goods:"quanlyphieulinh/capphat",
    confirm_goods_receipt:"quanlyphieulinh/linhhang",
  },
  goitieuhao: {
    list: "goithieuhao/danhsach",
    view: "goithieuhao/chitiet",
    create: "goithieuhao/themmoi",
    edit: "goithieuhao/capnhat",
    delete: "goithieuhao/xoa",
    view_all_hospital:"goitieuhao/danhsach/xemdanhsachbenhvien"
  },
  phieuhoantra: {
    list: "quanlyphieuhoantra/danhsach",
    view: "quanlyphieuhoantra/view",
    create: "quanlyphieuhoantra/themmoi",
    edit: "quanlyphieuhoantra/capnhat",
    delete: "quanlyphieuhoantra/xoa",
    view_all_hospital: "quanlyphieuhoantra/danhsach/xembenhvien",
    select_return_warehouse: "quanlyphieuhoantra/danhsach/chonkhohoantra",
    reclaim:"quanlyphieuhoantra/thuhoi"
  },
  xuatsudung:{
    list: "xuatsudung/danhsach",
    create: "xuatsudung/themmoi",
    edit: "xuatsudung/capnhat",
    delete: "xuatsudung/xoa",
    view_all_hospital:"xuatsudung/danhsach/xemdanhsachbenhvien",
    select_warehouse:"xuatsudung/danhsach/chonkhoxuat",
    select_warehouse_for_patient:"xuatsudung/taomoi/chonkhoxuatbenhnhan",

  },
  kehoachkiemke:{
    list: "kehoachkiemke/xemdanhsach",
    view:"kehoachkiemke/xemchitiet",
    create: "kehoachkiemke/taomoi",
    edit: "kehoachkiemke/chinhsua",
    delete: "kehoachkiemke/xoa",
    import:"kehoachkiemke/import",
    approval_level_1:"kehoachkiemke/pheduyetcap1",
    approval_level_2:"kehoachkiemke/pheduyetcap2",
    completion_level_1:"kehoachkiemke/xacnhanhoanthanhcap1", 
    completion_level_2:"kehoachkiemke/xacnhanhoanthanhcap2", 
    sumary_report:"kehoachkiemke/baocao", 

  },
  phieukiemke:{
    list: "phieukiemke/danhsach",
    view:"phieukiemke/xemchitiet",
    create: "phieukiemke/taomoi",
    edit: "phieukiemke/chinhsua",
    delete: "phieukiemke/xoa",
    confirm_level_1: "phieukiemke/pheduyetcap1",
    confirm_level_2: "phieukiemke/pheduyetcap2",
    completion:"phieukiemke/xacnhanhoanthanh", 
    re_inventory_1_month:"phieukiemke/kiemkelai1thang",
    re_inventory_6_month:"phieukiemke/kiemkelai6thang",
  },
  dieuchinhadjustment:{
    list: "dieuchinhadjustment/danhsach",
    view:"dieuchinhadjustment/chitiet",
    create: "dieuchinhadjustment/themmoi",
    edit: "dieuchinhadjustment/capnhat",
    delete: "dieuchinhadjustment/xoa",
    view_all_hospital:"dieuchinhadjustment/danhsach/xemdanhsachbenhvien",
  },
  dieuchinhstockcheck:{
    list: "dieuchinhstockcheck/danhsach",
    view:"dieuchinhstockcheck/chitiet",
    create: "dieuchinhstockcheck/themmoi",
    edit: "dieuchinhstockcheck/capnhat",
    delete: "dieuchinhstockcheck/xoa",
    view_all_hospital:"dieuchinhstockcheck/danhsach/xemdanhsachbenhvien",

  }, 
  nhatkyjob:{
    list: 'nhatkyjob/danhsach',
    rerun: 'nhatkyjob/chaylaijob',
  },
  baocaogiaodichkho:{
    view_all_hospital:"baocaogiaodichkho/danhsach/xemdanhsachbenhvien",
  },
  baocaoreconcile:{
    view_all_hospital:"baocaoreconcile/danhsach/xemdanhsachbenhvien",
  },
  baocaotonkho:{
    view_all_hospital:"baocaotonkho/danhsach/xemdanhsachbenhvien",
  },
};
// *** dữ liệu phân quyền không được chỉnh sửa ***
