import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_INVENTORY_PLAN_REPORT_LIST,
    GET_INVENTORY_PLAN_REPORT_BY_ID,
    GET_ASSET_INVENTORY_GROUP_BY_ASSET_CLASS,
    GET_REPORT_INVENTORY_ASSET,
    GET_RESULT_REPORT_INVENTORY_ASSET,
    SEARCH_REPORT_INVENTORY_ASSET,
    EXPORT_DATA_INVENTORY_REPORT_ASSETMENT_API,
    EXPORT_DATA_INVENTORY_REPORT_INFO_API,
    EXPORT_DATA_INVENTORY_REPORT_BILL_API,
    GET_SUMMARY_RESULT_ASSET

} from "./enum/ReportApiEnum";

export async function getListPlanReport(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_INVENTORY_PLAN_REPORT_LIST, data)
}

export async function getInventoryPlanReportById(id) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_INVENTORY_PLAN_REPORT_BY_ID + `?inventoryPlanId=${id}`)
}

export async function getAssetInventoryGroupByAssetClass(id) {
    return await CommonBase.getAsync(configApp.apiGateWay + GET_ASSET_INVENTORY_GROUP_BY_ASSET_CLASS + `?inventoryPlanId=${id}`)
}

export async function getReportInventoryAsset(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_REPORT_INVENTORY_ASSET, data?.data)
}

export async function searchReportInventoryAsset(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_RESULT_REPORT_INVENTORY_ASSET, data?.data)
}

export async function exportDataInventoryReportBillService(inventoryPlanId) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + EXPORT_DATA_INVENTORY_REPORT_BILL_API + "?inventoryPlanId=" + inventoryPlanId
    );
}
export async function exportDataInventoryReportInfoService(inventoryPlanId) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + EXPORT_DATA_INVENTORY_REPORT_INFO_API + "?inventoryPlanId=" + inventoryPlanId
    );
}
export async function exportDataInventoryReportAssetmentService(data) {
    return await CommonBase.postAsyncExportExcel(
        configApp.apiGateWay + EXPORT_DATA_INVENTORY_REPORT_ASSETMENT_API,
        data?.data
    );
}
export async function getSummaryAssetReport(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + GET_SUMMARY_RESULT_ASSET, data)
} 
