export const LocationLag = {
    language: "vn",
    loaction: {
        rank: "Cấp bậc",
        rulescreatecode: "Quy tắc tạo sloc Code",
        subordinaterank: "Cấp bậc trực thuộc",
        notinanysubordinate: "Chưa thuộc cấp bậc trực thuộc nào",
        action: "Thao tác",
        seedetails: "Xem chi tiết",
        edit: "Chỉnh sửa",
        nodata: "Không có dữ liệu để hiển thị",
        confirmdelete: "Xoá cấp bậc location",
        confirmdeletetrue: "Bạn có chắc chắn muốn xóa cấp bậc location này?",
        slocname: "Tên sloc",
        slocbelong: "Sloc thuộc về",
        lenght: "Dài",
        width: "Rộng",
        height: "Cao",
        manager: "Người quản lý",
        asaliverepository: "Là nơi lưu trữ trực tiếp",
        codenamerank: "Mã, tên, cấp bậc",
        confirmdelete2: "Xoá location",
        deleteconfirm: "Bạn có chắc chắn muốn xóa location này?",
        form: {
            rankrequired: "Cấp bậc không được bỏ trống",
            rankmaxlength: "Cấp bậc không được quá 255 ký tự",
            detaillocation: "Chi tiết cấp bậc location",
            configgenrequired: "Quy tắc tạo mã sloc không được bỏ trống",
            addnewlocation: "Thêm cấp bậc location",
            updatelocation: "Cập nhật thông tin cấp bậc location",
            rulegencode: "Quy tắc tạo mã sloc",
            levelmother: "Cấp bậc trực thuộc",
            ranknotepmty: "Cấp bậc không được bỏ trống",
            sloccodeerrorformat: "Mã sloc không đúng định dạng",
            cloccodenotnull: "Mã sloc không được bỏ trống",
            cloccodemaxlength: "Mã sloc không được quá 50 kí tự",
            length0: "Chiều dài phải là số",
            height0: "Chiều cao phải là số",
            width0: "Chiều rộng phải là số",
            nameslocnotnull: "Tên sloc không được bỏ trống",
            nameslocmaxlength: "Tên sloc không được quá 255 ký tự",
            slocenamespecial: "Tên sloc không chứa ký hiệu đặc biệt",
            widthrequired: "Chiều rộng không được bỏ trống",
            heightrequired: "Chiều cao không được bỏ trống",
            lengthrequired: "Chiều dài không được bỏ trống",
            detailinforlocation: "Chi tiết thông tin location",
            addnewlocation2: "Thêm location",
            changelocationinfor: "Cập nhật thông tin location",
            sloccode: "Mã sloc",
        },
        toastJs: {
            createOK: "Tạo mới location thành công",
            updateOk: "Cập nhật location thành công",
            deleteOK: "Xóa location thành công",
            createLevelOK: "Tạo mới cấp bậc location thành công",
            updateLevelOK: "Cập nhật cấp bậc location thành công",
            deleteLevelOK: "Xóa thành công Cấp bậc location"
        }
    }
}