export const recordResultLag = {
    language: "vn",
    recordResultLOT: {
        record:"Ghi nhận kết quả",
        evaluation:"Đánh giá kết quả",
        realqty: "Số lượng thực tế",
        recordTitle: "Ghi nhận kết quả QA",
        infoData: {
            LotCode: "Số lot QA",
            qtyLot: "Số lượng trong lot",
            productCode: "Mã sản phẩm",
            sampleCount: "Số lượng mẫu",
            productOrder: "Lệnh sản xuất",
            quantityChecked: "Số lượng đã kiểm tra",
        },
        selectTest: {
            sampleProductCheck: "Mã sản phẩm mẫu kiểm tra",
            checkList: "Chọn bài kiểm tra",
            overallResults: "Kết quả đánh giá tổng thể",
        },
        btn: {
            save: "Lưu",
            close: "Đóng",
            form: {
                save: "Lưu lại",
                back: "Quay lại",
            }
        },
        table: {
            nameCheck: "Tên bài kiểm tra",
            result: "Kết quả",
            action: "Thao tác",
        },
        form: {
            formTable: {
                content: "Nội dung kiểm tra",
                desiredValue: "Giá trị mong muốn",
                realValue: "Giá trị thực tế",
                evaluate: "Đánh giá",
                Result: "Kết quả bài kiểm tra",
            },
        },
        toast: {
            createSuccess: "Lưu bài kiểm tra thành công",
        },
    }
};