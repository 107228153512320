import { Layout, Breadcrumb } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SidebarComp from "./layoutComponent/sidebarComp/Sidebar";
import Topbar from "./layoutComponent/topbarComp/Topbar";
import BreadcrumbComp from "./layoutComponent/breadcrumb";
import { bindActionCreators } from "redux";
import userManager, {
  loadUserFromStorage,
  signinRedirect,
} from "../../shared/oidc-client-base/userService";

import FormUserHospitalWarehouse from "../page/MedicalSuppliesManagement/UserHospitalWarehouse/form";
import UserHospitalWarehouseAction from "../../redux-saga-v2/MedicalSuppliesManagement/UserHospitalWarehouse/UserHospitalWarehouseAction";
const { Header, Content, Footer, Sider } = Layout;

class LayoutMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
      isLoading: false,
      sidebarOpen: false,
      isOpenForm: true,
    };
  }

  async componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      localStorage.setItem("sidebarOpen", false);
      this.setState({ sidebarOpen: false });
    });
  }
  closePopupCreateUpdate = (isCancel) => {
    this.setState({ isOpenForm: false });
  };
  render() {
    let { history, listMenu } = this.props;
    let { sidebarOpen } = this.state;
    return this.state.isLoading ? null : (
      <div className="evomes-wrapper">
        <Layout>
          <SidebarComp open={sidebarOpen} />
          <Layout>
            {/* {
              <>
                {this.props.dataUserHospitalWarehouse === null ? (
                  <FormUserHospitalWarehouse
                    isOpen={this.state.isOpenForm}
                    closePopupCreateUpdate={this.closePopupCreateUpdate}
                  />
                ) : null}
              </>
            }  */}
            <Header
              className="topbar-mes px-0"
              style={{ position: "sticky", top: 0, zIndex: 1, width: "100%" }}
            >
              <Topbar
                history={history}
                url={this.props.path}
                sidebarOpen={this.state.sidebarOpen}
                activeSideBar={() => {
                  this.setState({ sidebarOpen: !this.state.sidebarOpen });
                  localStorage.setItem("sidebarOpen", !this.state.sidebarOpen);
                }}
              />
            </Header>
            <BreadcrumbComp />
            <Content>
              <div id="page-display">{this.props.children}</div>
            </Content>
          </Layout>
        </Layout>
        {/* {
          listMenu && listMenu.length > 0 ? (
            <Layout >
              <SidebarComp open={sidebarOpen} />
              <Layout>
                <Header className="topbar-mes px-0" style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
                  <Topbar
                    history={history}
                    url={this.props.path}
                    sidebarOpen={this.state.sidebarOpen}
                    activeSideBar={() => {
                      this.setState({ sidebarOpen: !this.state.sidebarOpen });
                      localStorage.setItem("sidebarOpen", !this.state.sidebarOpen);
                    }}
                  /></Header>
                <Content> {this.props.children}</Content>
              </Layout>
            </Layout>
          )
            :
            null
        } */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  UserHospitalWarehouseAction: bindActionCreators(
    UserHospitalWarehouseAction,
    dispatch
  ),
});
const mapStateToProps = (state) => {
  return {
    listMenu: state.PermissionReducer.listMenu,
    dataUserHospitalWarehouse: state.UserHospitalWarehouseReducer.dataUserHospitalWarehouse,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LayoutMain));
