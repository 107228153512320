import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // Plugin hỗ trợ thao tác với múi giờ UTC
import timezone from 'dayjs/plugin/timezone'; // Plugin hỗ trợ chuyển múi giờ
import 'dayjs/locale/vi';
import minMax from "dayjs/plugin/minMax";
import _ from "lodash"; // Import ngôn ngữ tiếng Việt

//Function hỗ trợ convert từ giá trị ngày tháng UTC sang múi giờ Việt Nam
export default function ConverDateToString(data) {
    // console.log("giá trị convert", test);
    // Sử dụng các plugin cần thiết của Day.js
    dayjs.extend(utc);
    dayjs.extend(timezone);
    // Chuyển đổi thời gian sang múi giờ Việt Nam
    return data != null ? dayjs.utc(data).tz('Asia/Ho_Chi_Minh').format("YYYY-MM-DD HH:mm:ss") : null;
}


export function getMinMaxDateInList(dateList) {
    dayjs.extend(minMax)
    const dates = Object.values(dateList)
        .filter(x => !_.isNull(x) || !_.isEmpty(x))
        .map(dateRange => ({
            startDate: dateRange[0],
            endDate: dateRange[1]
        }));
    const listStartDate = dates.map(dateRange => dateRange.startDate)
    const listEndDate = dates.map(dateRange => dateRange.endDate)

    const min = dayjs.min(listStartDate);
    const max = dayjs.max(listEndDate);

    return {min, max}
}

/**
 * Hàm dùng cho antd datepicker disable các ngày tháng quá khứ ( nhỏ hơn ngày hôm nay)
 * */
export const disabledDate = (current) => {
    return current && current < dayjs().subtract(1, 'day')
};