export const worker = {
  language: "vn",
  worker: {
    create_title: "Tạo mới người lao động",
    update_title: "Chỉnh sửa thông tin người lao động",
    create_button: "Thêm",
    update_button: "Lưu",
    Code: "Mã nhân viên",
    Name: "Tên",
    Surname: "Họ",
    PositionName: "Chức vụ",
    Phone: "Số điện thoại",
    Email: "Email",
    Isquitwork: "Đã thôi việc",
    Dateofbirth: "Ngày sinh",
    Gender: "Giới tính",
    Address: "Địa chỉ",
    HomeTown: "Quê quán",
    Maritalstatus: "Tình trạng hôn nhân",
    provincescode: "Tỉnh/Thành phố",
    districtscode: "Quận huyện",
    Addressdetails: "Địa chỉ chi tiết",
    Usercontactrelationship: "Quan hệ",
    Quitworkdate: "Ngày thôi việc",
    EmergencyContactPerson: "Người liên hệ khẩn cấp",
    UserContactName: "Họ tên",
    selectprovinces: "Chọn Tỉnh / thành phố",
    selectDistrict: "Chọn Quận huyện",
    selectRelationShip: "Chọn mối quan hệ",
    selectPosition: "Chọn chức vụ",
    selectStatusMarry: "Chọn tình trạng hôn nhân",
    workAreaSelectTitle: "Khu vực làm việc",
    workAreaSelectPlaceHolder: "Chọn khu vực làm việc",
    table: {
      code: "Mã nhân viên",
      name: "Họ tên",
      Tel: "SĐT",
      Email: "Email",
      location: "Vị trí làm việc",
      isQuit: "Đã thôi việc",
      dropdown: {
        edit: "Chỉnh sửa thông tin",
        Degrees: "Bằng cấp, kỹ năng",
        password: "Đổi mật khẩu",
        account: "Tạo đăng nhập"
      }
    },
    form: {
      view: "quay lại danh sách người lao động",
      edit: "Tạo mới thông tin người lao động",
      add: "Cập nhật thông tin người lao động",
      information: "Thông tin cơ bản",
      address: "Địa chỉ",
      homeTown: "quê quán",
      contact: "Người liên hệ khẩn cấp",
      edit_image: "Chỉnh sửa",
      male: "Nam",
      female: "Nữ",
      informationForm: "Thông tin người lao động"
    },
    placeHolder: {
      search: "Tìm kiếm theo mã , tên , số đt , hoặc email người lao động",
      Surname: "Nhập họ",
      Name: "Nhập Tên",
      Code: "Nhập mã code",
      Dateofbirth: "Chọn ngày sinh",
      Maritalstatus: "Chọn tình trạng hôn nhân",
      Phone: "Nhập số điên thoại",
      Email: "Nhập email",
      Positionid: "Chọn vị trí",
      Workareaid: "Chọn khu vực",
      Addressprovincescode: "Chọn tỉnh hoặc thành phố",
      Addressdistrictscode: "Chọn quận hoặc huyện",
      Addressdetails: "Nhập địa chỉ chi tiết",
      Usercontactname: "Nhập họ tên người liên hệ",
      Usercontactrelationship: "Chọn mối quan hệ",
      Usercontactphone: "Nhập số điên thoại người liên hệ",
      Usercontactaddress: "Nhập địa chỉ người liên hệ",
      Quitworkdate: "Chọn ngày nghỉ việc",
    },
    dropdown: {
      relation: {
        f1: "Ông",
        f2: "Bà",
        f3: "Bố",
        f4: "Mẹ",
        f5: "Chú",
        f6: "Cô dì",
        f7: "Anh, Em",
        f8: "Chị, Em",
        f9: "Con",
        f10: "cháu",
        f11: "Vợ",
        f12: "Chồng",
      },
      maried: {
        f1: "Chưa kết hôn",
        f2: "Đã kết hôn",
      }
    },
    validation: {
      f1: "Họ không được bỏ trống",
      f2: "Họ không được quá 20 ký tự",
      f3: "Tên không được bỏ trống",
      f4: "Tên không được quá 50 ký tự",
      f5: "Mã không được bỏ trống",
      f6: "Mã không được quá 20 ký tự",
      f7: "Ngày sinh không được bỏ trống",
      f8: "Ngày thôi việc không được bỏ trống",
      f9: "Giới tính không được bỏ trống",
      f10: "Số điện thoại không đúng định dạng",
      f11: "Số điện thoại không được bỏ trống",
      f12: "Số điện thoại không được quá 50 ký tự",
      f13: "Email không được bỏ trống",
      f14: "Email không quá 50 ký tự",
      f15: "Địa chỉ Tỉnh/Thành phố không được bỏ trống",
      f16: "Địa chỉ Huyện không được bỏ trống",
      f17: "WorkArea không được bỏ trống",
      f18: "Địa chỉ chi tiết không được quá 200 ký tự",
      f21: "Họ tên không được bỏ trống",
      f22: "Họ tên không được quá 100 ký tự",
      f23: "Mối liên hệ không được bỏ trống",
      f24: "Địa chỉ không được bỏ trống",
      f25: "Địa chỉ không được quá 100 ký tự",
      f26: "Số điện thoại không được bỏ trống",
      f27: "Số điện thoại không được quá 50 ký tự",
      f28: "Mã nhân viên không đúng định dạng",
      f29: "Email không đúng định dạng",
      f30: "Ngày sinh không hợp lệ",
      f31: "Ngày nghỉ việc không hợp lệ",
      f32: "Lỗi tải file không đúng định dạng",
      f33: "Số điên thoại người lao động không được trùng với số điện thoại người liên hệ khẩn cấp"
    },
    delete: {
      title: "Xoá người lao động",
      confirmText: "Bạn có chắc chắn muốn xóa người lao động này?",
    },
    err: {
      codeexist: "Mã nhân viên đã tồn tại",
      err: "Lỗi",
      employeeCode: "Mã số nhân viên không tồn tại",
      accessAccount: "Tài khoản truy cập đã tồn tại",
      accessAccountNo: "Tài khoản truy cập chưa tồn tại",
      creatAccount: "Tạo tài khoản truy cập lỗi",
      changePassword: "Thay đổi mật khẩu không thành công",
      birthDayMax: "Ngày sinh không được lớn hơn ngày hiện tại",
      birthDay: "Ngày thôi việc không hợp lệ",
      phone: "Số điên thoại người lao động không được trùng với số điện thoại người liên hệ khẩn cấp",
      characters: "không chứa ký tự đặc biệt",
      nameCharacters: "Họ tên người liên hệ Không chứa ký hiệu đặc biệt",
      uploadFile: "Lỗi tải file không đúng định dạng"
    },
    success: {
      creatAccount: "Tạo tài khoản truy cập thành công",
      changePassword: "Thay đổi mật khẩu thành công",
      createWorker: "Tạo mới người lao động thành công",
      updateWorker: "Cập nhật thông tin người lao động thành công",
      deleteWorker: "Xóa người người lao động thành công",
    }
  },
};
