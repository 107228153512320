export const ProductionLineLaguage = {
    language: "en",
    ProductionLineLaguage: {
        workCenter: "Work Center",
        palaceHolerWorkCenter: 'Choose work center',
        workUnit: 'Work unit',
        workOrder: 'Work order',
        palaceHolerWorkOrder: 'Choose work order',
        resetWorkCenter: 'Resetting work center',
        resetWorkUnit: 'Resetting work unit',
        stage: 'Stage',
        labelWorkUnit: 'Enter the code of employee or machine to setting',
        palaceHolderWorkUnit: 'Enter work unit code',
        quantityWorker: 'Quantity worker',
        quantityMachine: 'Quantity Machine',
        nameListSkill: 'List of skills needed for the stage',
        nameListMachine: 'List of machines needed for the process',
        mesEmptyCode: 'Code already exists, please enter another code',
        mesNotFoundUnit: 'Not found work unit in list',
        mesEmptyObject: 'No found human or machine',
        machine: "Machine ",
        inunit: " in the unit ",
        ofcenter: " of center ",
        wantchange: " do you want to change?",
        worker: "Worker ",
        table: {
            quantityNeed: 'Quantity need',
            quantityRealy: 'Registered',
            typeMachine: 'Type machine',
        },
        tableWorker: {
            code: 'Code',
            nameWorker: 'Worker Name',
            skill: 'Skill',
            action: 'Action'
        },
        tableMachine: {
            code: 'Code',
            nameMachine: 'Name machine',
            nameTypeMachine: 'Type machine',
        }
    }
}