import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import ProcessAction from "./ProcessAction";
import {
    GET_DATA_PAGING_SAGA, GET_DROPDOWN_PRODUCT_PROCESS_SAGA, GET_DROPDOWN_BOMSLOT_SAGA, GET_DROPDOWN_SOP_SAGA, CREATE_PROCESS_SAGA, GET_DETAIL_VERSION_PROCESS,
    GET_DROPDOWN_VERSION_PROCESS, DELETE_VERSION_PROCESS, CHANGE_VERSION_PROCESS_SAGA
} from "./enum/ProcessTypeAction";
import {
    getDataPagingService, getDropdownProductService, getDropdownBomslotService, getDropdownSopService, createProcessService, getDetailVersionProcessService,
    getDropdownVersionProcessService, deleteVersionProcessService, changeVersionProcessService
} from "./ProcessService";
import toastJs from "src/shared/common/toastJs";
import { v4 as uuidv4 } from "uuid";
import i18next from "i18next";
export function* getDataPagingSaga(data) {
    try {
        const request = {
            PageIndex: data.PageIndex,
            PageSize: data.PageSize,
            SearchTerm: data.SearchTerm,
        };
        const response = yield call(getDataPagingService, request);
        if (response?.isSuccess) {
            yield put(ProcessAction.getDataPagingReducer(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDropdownProduct(data) {
    try {
        const response = yield call(getDropdownProductService, data.request);
        if (response.isSuccess) {
            yield put(ProcessAction.setDropdownProduct(response.data));
        }
    } catch (error) {

    }
}
export function* getDropdownBomslot(data) {
    try {
        const response = yield call(getDropdownBomslotService, data.productcode);
        if (response.isSuccess) {
            yield put(ProcessAction.setDropdownBomslot(response.data));
        }
    } catch (error) {

    }
}
export function* getDropdownSop(data) {
    try {
        const response = yield call(getDropdownSopService, data.productcode);
        if (response.isSuccess) {
            yield put(ProcessAction.setDropdownSop(response.data));
        }
    } catch (error) {

    }
}
export function* createProcess(data) {
    try {
        let request = data.request;
        request.ListStepversionprocessRequest = data.request.ListStepversionprocessGetMap;
        request.ListStepversionprocessRequest.forEach((item) => {
            item.ListGroupstepversionprocessRequest = item.ListGroupstepversionprocessGetMap;
            item.ListGroupstepversionprocessRequest.forEach((temi) => {
                temi.ListGroupdetailstepversionprocessRequest = temi.ListGroupdetailstepversionprocessGetMap;
            })
            item.ListStepversionprocessbomslotRequest = item.ListStepversionprocessbomslotGetMap;
            item.ListStepversionprocessskillRequest = item.ListStepversionprocessskillGetMap;
            item.ListStepversionprocessmachineRequest = item.ListStepversionprocessmachineGetMap;
            item.ListStepversionprocesssopRequest = item.ListStepversionprocesssopGetMap;
        })
        const response = yield call(createProcessService, request);
        if (response.isSuccess) {
            toastJs.success(i18next.t("process.message.createsuccess"));
            yield put(ProcessAction.callAPICreateOrUpdateSuccess());
            yield put(ProcessAction.getDataPagingSaga(1, 10));
        }
    } catch (error) {

    }
}
export function* getDetailVerProcess(data) {
    try {
        let request = { Versionid: data.versionid }
        const response = yield call(getDetailVersionProcessService, request);
        if (response.isSuccess) {
            let responseData = response.data;
            responseData.ListStepversionprocessGetMap.forEach((item) => {
                item.Id = uuidv4();
                item.ListGroupstepversionprocessGetMap.forEach((temi) => {
                    temi.Id = uuidv4();
                    temi.ListGroupdetailstepversionprocessGetMap.forEach((itemtemp) => {
                        itemtemp.gdsId = uuidv4();
                    })
                });
                item.ListStepversionprocessbomslotGetMap.forEach((temi) => {
                    temi.idNVL = uuidv4();
                });
                item.ListStepversionprocessskillGetMap.forEach((temi) => {
                    temi.idWorker = uuidv4();
                });
                item.ListStepversionprocessmachineGetMap.forEach((temi) => {
                    temi.idMachine = uuidv4();
                });
                item.ListStepversionprocesssopGetMap.forEach((temi) => {
                    temi.idSop = uuidv4();
                });
            })
            yield put(ProcessAction.setDetailVersion(responseData));
        }
    } catch (error) {

    }
}
export function* getDropdownVersion(data) {
    try {
        const response = yield call(getDropdownVersionProcessService, data.id);
        if (response.isSuccess) {
            yield put(ProcessAction.setDropdownVersionProcess(response.data));
        }
    } catch (error) {

    }
}
export function* deleteVersionProcess(data) {
    try {
        const response = yield call(deleteVersionProcessService, data.id);
        if (response.isSuccess) {
            toastJs.success(i18next.t("process.message.deleteversion"));
            yield put(ProcessAction.callAPIDeleteSuccess());
            yield put(ProcessAction.setDropdownVersionProcess(response.data));
            yield put(ProcessAction.getDataPagingSaga(1, 10));
        }
    } catch (error) {

    }
}
export function* changeVersionProcess(data) {
    try {
        const response = yield call(changeVersionProcessService, data.processid);
        if (response.isSuccess) {
            toastJs.success(i18next.t("process.message.changeversion"));
            yield put(ProcessAction.getDataPagingSaga(1, 10));
        }
    } catch (error) {

    }
}
export default function* ProcessSaga() {
    yield takeEvery(GET_DATA_PAGING_SAGA, getDataPagingSaga);
    yield takeEvery(GET_DROPDOWN_PRODUCT_PROCESS_SAGA, getDropdownProduct);
    yield takeEvery(GET_DROPDOWN_BOMSLOT_SAGA, getDropdownBomslot);
    yield takeEvery(GET_DROPDOWN_SOP_SAGA, getDropdownSop);
    yield takeEvery(CREATE_PROCESS_SAGA, createProcess);
    yield takeEvery(GET_DETAIL_VERSION_PROCESS, getDetailVerProcess);
    yield takeEvery(GET_DROPDOWN_VERSION_PROCESS, getDropdownVersion);
    yield takeEvery(DELETE_VERSION_PROCESS, deleteVersionProcess);
    yield takeEvery(CHANGE_VERSION_PROCESS_SAGA, changeVersionProcess);
}
