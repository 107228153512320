import {
    GET_PAGING_PERMISSION_DATA_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    DELETE_PERMISSION_DATA_REDUCER,
    CALL_CREATE_UPDATE_SUCCESS_REDUCER,
    GET_DETAIL_PERMISSION_DATA_REDUCER
} from "./enum/TypePermissionDataAction";

const stateDefault = {
    listData: [],
    isGetLstData: false,
    isDeleteSuccess: false,
    isCreateUpdateSuccess: false,
    detailData: {},
    isGetDetailData: {},
    listDataCreateError: []
}
const PermissionDataReducer = (
    state = stateDefault,
    action
) => {

    switch (action.type) {
        case GET_PAGING_PERMISSION_DATA_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.isGetLstData = true;
            }
            return { ...state };
        case DELETE_PERMISSION_DATA_REDUCER:
            state.isDeleteSuccess = true;
            return { ...state };
        case CALL_CREATE_UPDATE_SUCCESS_REDUCER:
            if (action.data) {
                state.listDataCreateError = action.data;
                state.isCreateUpdateSuccess = true;
            }
            return { ...state };
        case GET_DETAIL_PERMISSION_DATA_REDUCER:
            if (action.data) {
                state.detailData = action.data;
                state.isGetDetailData = true;
            }
            return { ...state };
        case CALL_DEFAULT_REDUCER:
            state.isGetLstData = false;
            state.isDeleteSuccess = false;
            state.isCreateUpdateSuccess = false;
            state.isGetDetailData = false;
            return {
                ...state,
            };
        case CLEAR_DATA_REDUCER:
            return {
                state: stateDefault,
            };

        default:
            return { ...state };
    }
};

export default PermissionDataReducer;
