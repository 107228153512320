export const ApprovalConfig = {
    language: "vn",
    approvalConfig: {
        search: {
            placeHolder:
                "Tìm kiếm theo tên cấu hình",
        },
        table: {
            name: 'Tên cấu hình',
            description: "Mô tả",
            createdBy: "Người tạo",
            createdAt: "Ngày tạo"
        },
        form: {
            title: {
                create: 'Tạo mới cấu hình',
                update: 'Chỉnh sửa cấu hình',
                detail: 'Thông tin chi tiết',
                approvalFlow: 'Luồng phê duyệt'
            },
            content: {
                name: {
                    title: "Tiêu đề",
                    placeHolder: "Nhập tiêu đề"
                },
                typeApprovalFlow: {
                    title: "Loại cấu hình",
                    placeHolder: "Chọn loại cấu hình"
                },
                approvalFlowTemplate: {
                    title: "Quy trình phê duyệt",
                    placeHolder: "Chọn quy trình"
                },
                description: {
                    title: "Mô tả",
                    placeHolder: "Nhập nội dung..."
                },
                duration: {
                    hour: "h"
                }
            },

            rule: {
                name: {
                    required: "Tiêu đề không được bỏ trống",
                    maxLength: "Tiêu đề không được vượt quá 250 ký tự"
                },
                typeApprovalFlow: {
                    required: "Loại cấu hình không được bỏ trống",
                },
                approvalFlowTemplate: {
                    required: "Quy trình phê duyệt không được bỏ trống",
                },
                description: {
                    maxLength: "Mô tả không được vượt quá 500 ký tự"
                },
            }
        },
        popup: {
            delete: {
                title: 'Xoá cấu hình quy trình phê duyệt',
                content: 'Bạn có chắc chắn muốn xoá cấu hình quy trình phê duyệt không?',
            }
        },
        toastjs: {
            createSuccess: "Tạo mới cấu hình quy trình phê duyệt thành công",
            updateSuccess: "Cập nhật cấu hình quy trình phê duyệt thành công",
            deleteSuccess: "Xoá cấu hình quy trình phê duyệt thành công",
        },
    },
};
