import { call, put, takeEvery } from "redux-saga/effects";
import {
  CREATE_DELIVERY_SAGA,
  DELETE_DELIVERY_SAGA,
  GET_INFO_DELIVERY_BY_ID_SAGA,
  GET_PAGING_DELIVERY_SAGA,
  GET_PAGING_RETAIL_DELIVERY_SAGA,
  UPDATE_DELIVERY_SAGA,
  DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA,
  DOWNLOAD_EXCEL_SAGA
} from "./enum/TypeDeliveryAction";
import { closeLoading, openLoading } from "src/shared/common/LoadingAction";
import DeliveryAction from "./DeliveryAction";
import {
  getListDeliveryService, deleteDeliveryService, createDeliveryService, updateDeliveryService, getInfoDeliveryByIdService, DeliveryPredictabilityRetailByListProductService,
  downloadExcelService
} from "./DeliveryService";
import { downloadFile, getFileNameWithDate } from "src/shared/utils/file";
import { TYPE_EXPORT } from "src/components/page/DeliveryRefund/VoucherManagement/enum";

export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    let hospitalId = data.hospitalId
    let warehouseId = data.warehouseId? data.warehouseId : []
    let status = data.status ? data.status : []
    let typeDelivery = data.typeDelivery ? data.typeDelivery : ""
    let approveWarehouseId = data.approveWarehouseId ? data.approveWarehouseId : ""
    let startDateExport = data.startDateExport ? data.startDateExport : ""
    let endDateExport = data.endDateExport ? data.endDateExport : ""
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      HospitalId: hospitalId,
      ListWarehouseId: warehouseId,
      ApproveWarehouseId: approveWarehouseId,
      Status: status,
      TypeDelivery: typeDelivery,
      StartDateExport: startDateExport,
      EndDateExport: endDateExport,
      SearchTerm: searchTerm,
    };
    const response = yield call(getListDeliveryService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        openLoading();
        yield put(
          DeliveryAction.getPagingSaga(
            request.PageIndex -1,
            request.PageSize,
            request.HospitalId,
            request.ListWarehouseId,
            request.ApproveWarehouseId,
            request.Status,
            request.TypeDelivery,
            request.StartDateExport,
            request.EndDateExport,
            request.SearchTerm,
          )
        );
      } else {
        yield put(DeliveryAction.getPagingReducer(response));
        closeLoading();
      }
    }
  } catch (error) {
    closeLoading();
  }
}

export function* getRetailDeliveryPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm : "";
    let hospitalId = data.hospitalId
    let warehouseId = data.warehouseId
    let approveWarehouseId = data.ApproveWarehouseId ? data.ApproveWarehouseId : ""
    let status = data.status ? data.status : []
    let typeDelivery = data.typeDelivery ? data.typeDelivery : ""
    let startDateExport = data.startDateExport ? data.startDateExport : ""
    let endDateExport = data.endDateExport ? data.endDateExport : ""
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      hospitalId: hospitalId,
      WarehouseId: warehouseId,
      ApproveWarehouseId: approveWarehouseId,
      status: status,
      TypeDelivery: typeDelivery,
      StartDateExport: startDateExport,
      EndDateExport: endDateExport,
      searchTerm: searchTerm,
    };
    const response = yield call(getListDeliveryService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          DeliveryAction.getPagingRetailDeliveryReducer(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm
          )
        );
      } else {
        yield put(DeliveryAction.getPagingRetailDeliveryReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* deleteDeliverySaga(request) {
  try {
    if (request.id) {
      const response = yield call(deleteDeliveryService, request.id);
      if (response.isSuccess) {
        yield put(DeliveryAction.deleteDataReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* DeliveryPredictabilityRetailByListProductSaga(requet) {
  let data = {
    warehouseId: requet.warehouseId,
    approveWarehouseId: requet.approveWarehouseId,
    listProductId: requet.listProductId
  }
  try {
    const response = yield call(DeliveryPredictabilityRetailByListProductService, data);
    if (response.isSuccess) {
      yield put(DeliveryAction.DeliveryPredictabilityRetailByListProductReducer(response.data));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}


export function* createDeliverySaga(requet) {
  try {
    if (requet.data) {
      requet.data.Status = requet.statusSave;
      const response = yield call(createDeliveryService, requet.data);
      if (response) {
        yield put(DeliveryAction.createDataReducer(response));
      }
      // closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* updateDeliverySaga(requet) {
  try {
    if (requet.data) {
    requet.data.Status = requet.statusSave;
      const response = yield call(updateDeliveryService, requet.data);
      if (response) {
        yield put(DeliveryAction.updateDataReducer(response));
      }
      // closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export function* getInfoDeliveryByIdSaga(requet) {
  try {
    if (requet.data) {
      const response = yield call(getInfoDeliveryByIdService, requet.data);
      if (response.isSuccess) {
        yield put(DeliveryAction.getInfoDeliveryReducer(response.data));
      }
      // closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export function* downloadExcelSaga(data){
  let request = {
    Id: data.data.Id,
    typeExport: data.data.typeExport
  };
  
  const response = yield call(downloadExcelService, request);
  //Xử lý nếu typeExport là tải Excel
  if (request.typeExport === TYPE_EXPORT.EXCEL) {
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const fileName = getFileNameWithDate({
      prefix: "",
      fileNameWithoutExtension: "Phieu_linh",
      extension: ".xlsx",
    });
    downloadFile(fileUrl, fileName);
  }
  else if (request.typeExport === TYPE_EXPORT.VIEW_PDF) {
    const fileBlob = new Blob([response.data], { type: 'application/pdf' });
    const fileUrl = URL.createObjectURL(fileBlob);
    window.open(fileUrl, '_blank');
    URL.revokeObjectURL(fileUrl); //Giải phóng object URL sau khi dùng xong.
  }
  closeLoading();

}
export default function* DeliverySaga(data) {
  yield takeEvery(GET_PAGING_DELIVERY_SAGA, getPagingSaga);
  yield takeEvery(DELETE_DELIVERY_SAGA, deleteDeliverySaga);
  yield takeEvery(CREATE_DELIVERY_SAGA, createDeliverySaga);
  yield takeEvery(UPDATE_DELIVERY_SAGA, updateDeliverySaga);
  yield takeEvery(GET_INFO_DELIVERY_BY_ID_SAGA, getInfoDeliveryByIdSaga);
  yield takeEvery(GET_PAGING_RETAIL_DELIVERY_SAGA, getRetailDeliveryPagingSaga);
  yield takeEvery(DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA, DeliveryPredictabilityRetailByListProductSaga);
  yield takeEvery(DOWNLOAD_EXCEL_SAGA, downloadExcelSaga);

  //getInfoDeliverySaga
}
