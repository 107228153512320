import {
    GET_WORKER_BY_CODE_SAGA,
    GET_WORKER_BY_CODE_REDUCER,
    GET_WORK_UNIT_REDUCER,
    GET_WORK_UNIT_PRODUCTION_SAGA,
    CREATE_WORKER_MACHINE,
    GET_WORKER_MACHINE_SAGA,
    SET_WORKER_MAICHINE_REDUCER,
    GET_WORKUNIT_WITH_WORKCENTER_AND_WO,
    SET_WORKUNIT_WITH_WC_WO,
    CALL_SAVE_WORKUNIT_SUCCESS,
    SAVE_TRANFER_WORKUNIT_WCMC,
    CALL_CHANGE_WORK_MACHINE_SUCCESS,
    DEFAULT
} from './enum/typeProductionLineAction';
const ProductionLineAction = {
    getWorkerByCodeSaga(request) {
        return {
            type: GET_WORKER_BY_CODE_SAGA,
            request: request
        }
    },
    setWorkerReducer(data) {
        return {
            type: GET_WORKER_BY_CODE_REDUCER,
            data: data
        }
    },
    getWorkUnitSaga(request) {
        return {
            type: GET_WORK_UNIT_PRODUCTION_SAGA,
            request: request
        }
    },
    setWorkUnitReducer(data) {
        return {
            type: GET_WORK_UNIT_REDUCER,
            data: data,
        }
    },
    getWorkerMachineSaga(code) {
        return {
            type: GET_WORKER_MACHINE_SAGA,
            code: code,
        }
    },
    setWorkerMachieReducer(data) {
        return {
            type: SET_WORKER_MAICHINE_REDUCER,
            data: data
        }
    },
    getWorkUnitWithWCWO(request) {
        return {
            type: GET_WORKUNIT_WITH_WORKCENTER_AND_WO,
            request: request
        }

    },
    setWorkUnitWithWCWOReducer(data) {
        return {
            type: SET_WORKUNIT_WITH_WC_WO,
            data: data
        }
    },
    updateProductionLine(request) {
        return {
            type: CREATE_WORKER_MACHINE,
            request: request
        }
    },
    callSaveSuccess() {
        return {
            type: CALL_SAVE_WORKUNIT_SUCCESS
        }
    },
    changeWorkerMachine(request) {
        return {
            type: SAVE_TRANFER_WORKUNIT_WCMC,
            request: request
        }
    },
    callReducerChangeSuccess() {
        return {
            type: CALL_CHANGE_WORK_MACHINE_SUCCESS
        }
    },
    callDefault() {
        return {
            type: DEFAULT
        }
    }
};
export default ProductionLineAction;