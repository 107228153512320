import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_PAGING_CAT_HOSPITAL_API,
  DELETE_CAT_HOSPITAL_API,
  CREATE_CAT_HOSPITAL_API,
  UPDATE_CAT_HOSPITAL_API,
  GET_LIST_DATA_UNIT_API,
} from "./enum/TypeCatHospitalApi.js";

export async function getPagingService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_PAGING_CAT_HOSPITAL_API,
    data
  );
}

export async function deleteCatHospitalService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + DELETE_CAT_HOSPITAL_API + data
  );
}
export async function updateCatHospitalService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + UPDATE_CAT_HOSPITAL_API,
    data
  );
}
export async function createCatHospitalService(data) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + CREATE_CAT_HOSPITAL_API,
    data
  );
}

export async function getListUnitCategoryService() {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_LIST_DATA_UNIT_API
  );
}
