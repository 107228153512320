import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";

import {
  GET_ALL_MASTER_DATA_BY_GROUP_CODE_API,
  GET_APPROVAL_FLOW_TEMPLATE_API,
  GET_LIST_USER_API,
  GET_LIST_ROLE_API,
  GET_APPROVAL_FLOW_TEMPLATE_BY_ID_API,
  GET_LIST_FOCAL_DEPARTMENT_G5_API,
  GET_LIST_BP_THE_LEDGER_API,
  GET_LIST_ASSET_CLASS_API,
  GET_LIST_SEGMENT_FUND_CENTER_API,
  GET_LIST_STANDARD_ASSET_NAME_API,
  GET_LIST_BUDGET_CI_API,
  GET_LIST_EVALUATION_GROUP_1_API,
  GET_LIST_EVALUATION_GROUP_2_API,
  GET_LIST_EVALUATION_GROUP_3_API,
  GET_LIST_EVALUATION_GROUP_4_API,
  GET_LIST_EVALUATION_GROUP_5_API,
  GET_ALL_FUND_CENTER_API,
  GET_GROUP_BPC_API,
  GET_LIST_VENDOR_API,
  GET_LIST_ASSET_API,
  GET_LIST_ASSET_NUMBER_SUPPER_API,
  GET_TRANSFER_ASSET_API,
  GET_LIST_ALL_SEGMENT_API,
  GET_LIST_COST_CENTER_BY_SEGMENT_API,
  GET_LIST_INVENTORY_CODE_API,
  GET_USER_SEGMENT_API,
  GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_API,
  GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_API,
  GET_ALL_ASSET_SOURCE_API,
  GET_ALL_SEGMENT_INVENTORY_PLAN_API,
  GET_USER_MONITOR1_API,
  GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_API,
} from "./enum/TypeCommonDropdownVinmecAPI";

export async function getAllMasterDataByGroupCodeService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_ALL_MASTER_DATA_BY_GROUP_CODE_API + data
  );
}
export async function getApprovalFlowTemplateService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_APPROVAL_FLOW_TEMPLATE_API + data
  );
}
export async function getListUserService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_USER_API + data
  );
}
// export async function getListRoleService(data) {
//     return await CommonBase.getAsync(
//         configApp.apiGateWay + GET_LIST_ROLE_API + data
//     );
// }
export async function getApprovalFlowTemplateByIdService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_APPROVAL_FLOW_TEMPLATE_BY_ID_API + data
  );
}
export async function getListFocalDepartmentG5Service() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_FOCAL_DEPARTMENT_G5_API
  );
}
export async function getListBPOnTheLedgerService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_BP_THE_LEDGER_API + request
  );
}
export async function getListAssetClassService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_ASSET_CLASS_API + request
  );
}
export async function getListAssetService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_ASSET_API + request
  );
}

export async function getListTransferAssetService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_TRANSFER_ASSET_API + request
  );
}

export async function getListSegmentFundCenterService() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_SEGMENT_FUND_CENTER_API
  );
}

// export async function getListUnitService() {
//     return await CommonBase.getAsync(
//         configApp.apiGateWay + GET_LIST_UNIT_API
//     );
// }
export async function getListStandardAssetNameService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_STANDARD_ASSET_NAME_API + request
  );
}
export async function getListBudgetCIService() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_BUDGET_CI_API
  );
}
export async function getListEvaluationGroup_1_Service() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_EVALUATION_GROUP_1_API
  );
}
export async function getListEvaluationGroup_2_Service() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_EVALUATION_GROUP_2_API
  );
}
export async function getListEvaluationGroup_3_Service() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_EVALUATION_GROUP_3_API
  );
}
export async function getListEvaluationGroup_4_Service() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_EVALUATION_GROUP_4_API
  );
}
export async function getListEvaluationGroup_5_Service() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_EVALUATION_GROUP_5_API
  );
}
export async function getAllFundCenterService() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_ALL_FUND_CENTER_API
  );
}

export async function getGroupBPCService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_GROUP_BPC_API + request
  );
}
export async function getListVendorService() {
  return await CommonBase.getAsync(configApp.apiGateWay + GET_LIST_VENDOR_API);
}
export async function getListAssetSuperNumberService() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_ASSET_NUMBER_SUPPER_API
  );
}

export async function getListAllSegment() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_ALL_SEGMENT_API
  );
}
export async function getListCostCenterbySegmentService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay +
      GET_LIST_COST_CENTER_BY_SEGMENT_API +
      "?SegmentId=" +
      request.SegmentId
  );
}
export async function getListCostCenterbySegmentFundCenterService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay +
      GET_LIST_COST_CENTER_BY_SEGMENT_FUNDCENTER_API +
      "?SegmentId=" +
      request.SegmentId
  );
}
export async function getListInventoryCodeService(request) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_LIST_INVENTORY_CODE_API + request
  );
}

export async function getUserSegmentService() {
  return await CommonBase.getAsync(configApp.apiGateWay + GET_USER_SEGMENT_API);
}

export async function getAllEvaluationGroup5BySegmentService(segmentId) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_ALL_EVALUATION_GROUP_5_BY_SEGMENT_API + segmentId
  );
}
export async function getListSegmentFundCenterCodeRequestService() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_ALL_COSTCENTER_SEGMENT_REQUEST_CODE_API
  );
}

export async function getAllAssetSourceService() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_ALL_ASSET_SOURCE_API
  );
}
export async function getAllSegmentInventoryPlanService() {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_ALL_SEGMENT_INVENTORY_PLAN_API
  );
}

export async function getUserMonitor1Service(
  segmentId,
  evaluationGroup5Id,
  searchTerm
) {
  return await CommonBase.getAsync(
    configApp.apiGateWay +
      GET_USER_MONITOR1_API +
      "?SegmentId=" +
      segmentId +
      "&EvaluationGroup5Id=" +
      evaluationGroup5Id +
      "&SearchTerm=" +
      searchTerm
  );
}
