import {
    CALL_DEFAULT_LOTQA_REDUCER,
    GET_DETAIL_LOTQA_REDUCER,
    SET_DROPDOWN_WO_REDUCER,
    CALL_RESULT_LOTQA_REDUCER,
    GET_PAGING_LOTQA_REDUCER,
    CALL_CHANGE_DATA,
    GET_CODE_FROM_API_REDUCER,
    GET_LIST_ITEM_CODE_REDUCER,
} from "./enum/LotQATypeAction";
const LOTQAReducer = (
    state = {
        isFetching: false,
        isFetchingMore: false,
        listData: [],
        objectData: {},
        callResultFromApi: false,
        callResultFromDetail: false,
        dropdownWO: [],
        isCallDropdownWO: false,
        changeData: false,
        code: null,
        listItemCode: [],
    },
    action
) => {
    switch (action.type) {
        case GET_PAGING_LOTQA_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.callResultFromApi = true;
            }
            return {
                ...state,
            };
        case GET_DETAIL_LOTQA_REDUCER:
            state.callResultFromDetail = true;
            if (action.obj) {
                state.objectData = action.obj;
            }
            return {
                ...state,
            };
        case SET_DROPDOWN_WO_REDUCER:
            state.isCallDropdownWO = true;
            if (action.data) {
                state.dropdownWO = action.data;
            }
            return {
                ...state,
            };
        case CALL_RESULT_LOTQA_REDUCER:
            state.callResultFromApi = true;
            return {
                ...state,
            };
        case CALL_CHANGE_DATA:
            state.changeData = true;
            return {
                ...state,
            };
        case CALL_DEFAULT_LOTQA_REDUCER:
            state.changeData = false;
            state.callResultFromApi = false;
            state.callResultFromDetail = false;
            state.objectData = null;
            state.isCallDropdownWO = false;
            state.listItemCode=[];
            return {
                ...state,
            }
        case GET_CODE_FROM_API_REDUCER:
            state.callResultFromApi = true;
            if (action.data) {
                state.code = action.data;
            }
            return {
                ...state,
            }
        case GET_LIST_ITEM_CODE_REDUCER:
            state.callResultFromApi = true;
            if (action.data) {
                state.listItemCode = action.data;
            }
            return {
                ...state,
            }
        default:
            return {
                ...state,
            };
    }
}
export default LOTQAReducer;