export const Machine = {
    language: "vn",
    machine: {
        //index
        classcodeexist: "Mã phân loại đã tồn tại",
        classnameexist: "Tên phân loại đã tồn tại",
        machinetitle: "Quản lý máy móc",
        machinetypetitle: "Quản lý phân loại máy móc",
        searchplaceholder: "Mã máy, tên máy, phân loại, model, hãng sản xuất, xuất xứ, vị trí máy móc",
        addnew: "Thêm mới",
        excel: "Xuất file Excel",
        searchplaceholder2: "Mã phân loại, tên phân loại",
        //title table
        description: "Mô tả",
        classificationcode: "Mã phân loại",
        machinecode: "Mã máy",
        machinename: "Tên máy",
        unit: "Đơn vị",
        classification: "Phân loại",
        manufacturer: "Hãng sản xuất",
        origin: "Xuất xứ",
        status: "Trạng thái",
        action: "Thao tác",
        machinelocation: "Vị trí máy móc",
        nodata: "Không có dữ liệu",
        deletetitle: "Xoá máy móc",
        deletequestion: "Bạn có chắc chắn muốn xóa máy móc này?",
        deletetitletype: "Xoá phân loại máy móc",
        deleteques: "Bạn có chắc chắn muốn xóa phân loại máy móc này?",
        //form
        imagemax: "Tải lên quá 3 ảnh",
        fileformat: "Định dạng file không đúng",
        filesizemax5: "Dung lượng file lớn hơn 5MB",
        edittype: "Chỉnh sửa loại máy móc",
        viewtype: "Thông tin  loại máy móc",
        newtype: "Tạo mới loại máy móc",
        createnewmachine: "Tạo mới máy móc",
        machineupdate: "Cập nhật máy móc",
        machineinformation: "Thông tin máy móc",
        technicaldocument: "Tài liệu kỹ thuật",
        size: "Kích thước ",
        wattage: "Công suất ",
        supplier: "Nhà cung cấp",
        machineimages: "Hình ảnh máy (tối đa 3 hình, tối đa 5 MB)",
        maintancetime: "Thời gian bảo dưỡng định kỳ",
        month: "tháng",
        depreciationperiod: "Thời gian khấu hao",
        statusmachine: "Trạng thái máy móc",
        entryprice: "Giá nhập",
        seri: "Số seri",
        specifications: "Thông số kỹ thuật",
        generalinfor: "Thông tin chung",
        validate: {
            descriptionrequired: "Mô tả không được vượt quá 255 ký tự",
            typenamelength: "Tên phân loại không được vượt quá 255 ký tự",
            typenamerequired: "Tên phân loại không được bỏ trống",
            typecodelength: "Mã phân loại không được vượt quá 50 ký tự",
            typecoderequired: "Mã phân loại không được bỏ trống",
            coderequired: "Mã máy không được bỏ trống",
            namerequired: "Tên máy không được bỏ trống",
            namelength: "Tên máy không được quá 255 ký tự",
            statusrequired: "Trạng thái máy móc không được bỏ trống",
            unitrequired: "Tên đơn vị không được bỏ trống",
            unitlength: "Tên đơn vị không được quá 200 ký tự",
            serirequired: "Số seri không được bỏ trống",
            serilength: "Số seri không được quá 50 ký tự",
            machineclassrequired: "Phân loại máy móc không được bỏ trống",
            workarearequired: "Vị trí máy không được bỏ trống",
            numbervalid: "Không đúng định dạng là kiểu số",
            depreciationtimerequired: "Thời gian khấu hao không được bỏ trống",
            maintancetimerequired: "Thời gian bảo dưỡng định kỳ không được bỏ trống",
            sizerequired: "Kích thước thiết bị không được bỏ trống",
            deviceproviderrequired: "Nhà cung cấp thiết bị không được bỏ trống",
            originrequired: "Xuất xứ không được bỏ trống",
            originlength: "Xuất xứ không được quá 200 ký tự",
            modelrequired: "Model không được bỏ trống",
            modellength: "Model không được quá 200 ký tự",
            manufacturerrequired: "Hãng sản xuất không được bỏ trống",
            manufacturerlength: "Hãng sản xuất không được quá 200 ký tự",
            capacity: "Công suất không được bỏ trống",
            machineimg: 'Hình ảnh máy móc không được bỏ trống',
        },
        machineType_createTitle: "Thêm phân loại máy móc",
        machineType_editTitle: "Cập nhật phân loại máy móc",
        machineType_seeDetailTitle: "Thông tin phân loại máy móc",
    },

}