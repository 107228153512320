import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    getDetailLocation,
    getChecklistByItemCode,
    recordProducttionExecute,
    getListMachine,
} from "./enum/apiEnum";

export async function getDetailLocationService() {
    return await CommonBase.postAsync(
        configApp.apiGateWay + getDetailLocation,
        ""
    )
};
export async function getChecklistByItemCodeService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + getChecklistByItemCode,
        request
    )
};
export async function recordProducttionExecuteService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + recordProducttionExecute,
        request
    )
};

export async function getDetailWorkorderByCode(workorderCode) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + getDetailWorkorderByCode + workorderCode
    )
};
export async function getMachineList(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + getListMachine,
        request
    )
};