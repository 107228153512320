import * as type from "./enum/PackingProcessTypeAction"
const PackingProcessAction = {
    getAllPagingPackingProcessSaga: (pageIndex, pageSize, searchTerm = "") => {
        return {
            type: type.GET_ALL_PACKING_PROCESS,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm:searchTerm,
        };
    },
    getPackingProcessById: (request) => {
        return {
            type: type.GET_PACKING_PROCESS_ID_SAGA,
            request: request,
        };
    },
    setPackingProcessById:(data) => {
        return {
            type: type.SET_PACKING_PROCESS_ID_REDUCER,
            data: data,
        };
    },
    setAllReducer: (data) => {
        return {
            type: type.SET_ALL_PACKING_PROCESS_REDUCER,
            data:data,
        }
    },
    createDataSaga: (request, pageIndex, pageSize) => {
        return {
            type: type.CREATE_PACKING_PROCESS_SAGA,
            request: request,
            pageIndex: pageIndex,
            pageSize:pageSize,
        }
    },
    updateDataSaga: (request,pageIndex, pageSize) => {
        return {
            type: type.UPDATE_PACKING_PROCESS_SAGA,
            request: request,
            pageIndex: pageIndex,
            pageSize:pageSize,
        }
    },
    deleteDataSaga: (request,pageIndex, pageSize) => {
        return {
            type: type.DELETE_PACKING_PROCESS_SAGA,
            request: request,
            pageIndex: pageIndex,
            pageSize:pageSize,
        }
    },
    getDropdownPackingLevelSaga: (request) => {
        return {
            type: type.GET_DROP_DOWN_PACKING_LEVEL_SAGA,
            request: request,
        }
    },
    setDropdownPackingLevelReducer: (data) => {
        return {
            type: type.SET_DROP_DOWN_PACKING_LEVEL_REDUCER,
            data: data,
        }
    },
    callResultFromApiReducer: () => {
        return {
            type: type.CALL_RESULT_PACKING_PROCESS_REDUCER,
        };
    },
    callFalseFromReducer: () => {
        return {
            type: type.CALL_FALSE_FROM_REDUCER,
        };
    },
    setErrorToReducer: () => {
        return {
            type: type.SET_ERROR_TO_REDUCER,
        }
    },
    setDefaultErrorFromReducer: () => {
        return {
            type: type.SET_DEFAULT_ERROR_FROM_REDUCER,
        }
    },
    clearDataReducer: () => {
        return {
            type: type.CLEAR_DATA_REDUCER,
        };
    },
    callDefault: () => {
        return {
            type: type.CALL_DEFAULT_PACKING_PROCESS_REDUCER,
        }
    }
}
export default PackingProcessAction;