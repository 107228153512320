import { call, put, takeEvery } from "redux-saga/effects";
import _ from "lodash";
import {
    GET_REQUEST_INCREASE_ASSET_SAGA
} from "./enum/TypeRequestIncreaseAssetAction.js";
import { closeLoading } from "src/shared/common/LoadingAction";
import RequestIncreaseAssetAction from "./RequestIncreaseAssetAction";
import {
    getRequestAssetIncreaseService,
} from "./RequestIncreaseAssetService.js";

export function* getRequestAssetIncreaseSaga(data) {
  try {
    const request = {
      PageSize: data?.PageSize,
      PageIndex: data?.PageIndex,
      InventoryPlanCode: data?.objectFilter?.InventoryPlanCode,
      ListStatus: data?.objectFilter?.Status
    }
    const response = yield call(getRequestAssetIncreaseService, request);
    if (response) {
      yield put(RequestIncreaseAssetAction.getRequestAssetIncreaseReducer(response));
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}

export default function* RequestIncreaseAssetSaga(data) {
  yield takeEvery(GET_REQUEST_INCREASE_ASSET_SAGA, getRequestAssetIncreaseSaga);
  
}
