export const Standardpacking = {
    language: "en",
    standardPacking: {
        search: "Product name, partner name, package type",
        table: {
            productName: "Product name",
            partner: "Partner",
            packageType: "Package type",
            amount: "Amount",
            unit: "Unit",
            height: "height (cm)",
            length: "Length (cm)",
            width: "Width (cm)",
            stacking: "Stacking",
        },
        formSP: {
            stackingQuantity: "Stacking quantity",
        },
        deleteSP: {
            title: "Confirm delete standard packing",
            description: "Are you sure you want to delete this standard packing?"
        },
        ruleForm: {
            goodsCode: "Product name is required",
            partnerCode: "Partner infomation is required",
            packageType: "Partner type is required",
            numberErr: "Wrong format of natural numbers",
            qtyPackRequire: "Quantity is required",
            standardHeight: "Height is required",
            standardLength: "Length is required",
            standardWidth: "Width is required",
            stakingNumber: "Quantity stacking is required",
            numberMin: "The number must be greater than 0",
            stakingNumberMin: "Quantity stacking must be greater than 0",
            heightMin: "Height must be greater than 0",
            lengthMin: "Length must be greater than 0",
            widthMin: "Width must be greater than 0",
        },
        titleForm: {
            create: "Create packing standard information",
            update: "Update packing standard information",
            view: "Detail packing standard information"
        },
        placeholder: {
            productName: "Enter product name",
            partner: "Enter partner",
            packageType: "Enter package type",
            amount: "Enter quantity",
            unit: "Enter unit",
            height: "Enter height (cm)",
            length: "Enter length (cm)",
            width: "Enter width (cm)",
            stackingNumber: "Enter the amount of stacking"
        },
        toastJs: {
            createOK: "Create new packing standard data successfully",
            updateOk: "Packing standard data update successfully",
            deleteOK: "Deletion of packing standard data successfully"
        }
    }
}