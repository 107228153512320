import {
    GET_PAGING_PERMISSION_DATA_SAGA,
    GET_PAGING_PERMISSION_DATA_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,
    DELETE_PERMISSION_DATA_SAGA,
    DELETE_PERMISSION_DATA_REDUCER,
    UPDATE_PERMISSION_DATA_SAGA,
    CALL_CREATE_UPDATE_SUCCESS_REDUCER,
    CREATE_PERMISSION_DATA_SAGA,
    GET_DETAIL_PERMISSION_DATA_SAGA,
    GET_DETAIL_PERMISSION_DATA_REDUCER

} from "./enum/TypePermissionDataAction";

const PermissionDataAction = {
    getPagingSaga: (request) => {
        return {
            type: GET_PAGING_PERMISSION_DATA_SAGA,
            request: request
        };
    },

    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_PERMISSION_DATA_REDUCER,
            data: data,
        };
    },
    getDetailDataSaga: (id) => {
        return {
            type: GET_DETAIL_PERMISSION_DATA_SAGA,
            id: id
        };
    },

    getDetailDataReducer: (data) => {
        return {
            type: GET_DETAIL_PERMISSION_DATA_REDUCER,
            data: data
        };
    },
    deleteDataSaga: (id) => {
        return {
            type: DELETE_PERMISSION_DATA_SAGA,
            id: id
        };
    },

    deleteDataReducer: () => {
        return {
            type: DELETE_PERMISSION_DATA_REDUCER,
        };
    },
    CreateDataSaga: (data) => {
        return {
            type: CREATE_PERMISSION_DATA_SAGA,
            data: data
        };
    },
    UpdateDataSaga: (data) => {
        return {
            type: UPDATE_PERMISSION_DATA_SAGA,
            data: data
        };
    },
    CallCreateUpdateSuccessReducer: (data) => {
        return {
            type: CALL_CREATE_UPDATE_SUCCESS_REDUCER,
            data: data
        };
    },

    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER,
        };
    },
};

export default PermissionDataAction;
