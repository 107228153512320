export const RequestFormManager = {
    language: "en",
    manager: {
        table: {
            code: "Code",
            petiton: "Petitioner name",
            transfer: "Transfer to",
            reason: "Reason",
            delivery: "D/O code",
            status: "Status",
            pickingList: "Picking list",
            action: "Action",
        },
        searchlabel: "Enter ticket information",
        addForm: {
            tittleLabel: "Add new ticket information",
            orderby: "Order by",
            exportCode: "Coupon code",
            docode: "Delivery coupon code",
            reason: "Reason",
            orderCode: "Order code",
            description: "Description",
            number: "Export number",
            unit: "Unit",
            action: "Action",
            addbutton: "Add",
            addlabel: "Enter request code"
        },
        updateForm: {
            tittleLabel: "Update ticket information",
        },
        deleteForm: {
            title: "Confirm to delete this ticket information",
            label: "Are you sure want to delete this?",
            titletable: "Confirm to delete this request code",
            labletable: "Are you sure want to delete this request code ?"
        },
        detailForm: "Ticket Information",
        errorToast: "The delivery note cannot be edited due to the data available for collection",
        errorReason: "Reason request not over 255 character",
        domaxlength: "Coupon code must not exceed 255 characters",
        errorForm: {
            errorValidateTranfer: "Incorrect code",
            errorRequiredTranfer: "The ticket information code cannot be blank",
            errorMaxLengthTranfer: "The ticket information code cannot be more than 50 characters",
            errorRequiredPetition: "Pettion name cannot be blank",
            errorRequiredQty: "Quantity cannot be blank",
            errorRequiredGoodCode: "The requested item number cannot be blank",
            errorTextQty: "Order quantity must be a number",
            errorTextQty2: "Order quantity must be greater than or equal to 0",
            errorToast: "The request form must have the item codes",
        },
        toastJs: {
            createOK: "Create new ticket successfully",
            updateOK: "Update request ticket successfully",
            deleteOK: "Delete request ticket successfully"
        }
    }
}