import {
    GET_PAGING_WLP_REDUCER, GET_DROPDOWN_PROCESS_REDUCER, GET_DROPDOWN_PRODUCT_PROCESS_REDUCER, GET_DATA_TABLE_REDUCER,
    CHANGE_DATA_REDUCER, CALL_API_CREATE_OR_UPDATE, SET_OBJ_DETAIL, CHANGE_DATA_DEFAULT_REDUCER
} from "./enum/WorkareaLinkProccessTypeAction";
const stateDefault = {
    listData: {},
    dropdownProcess: [],
    dropdownProduct: [],
    dataTable: {},
    callAPIsuccess: false,
    callAPICreOrUpsuccess: false,
    objDetail: {},
    isLoading: true,
}
const WorkareaLinkProccessReducer = (
    state = stateDefault, action
) => {
    switch (action.type) {
        case GET_PAGING_WLP_REDUCER:
            if (action.data) {
                state.listData = action.data;
                state.isLoading = false;
            }
            return {
                ...state,
            };
        case SET_OBJ_DETAIL:
            if (action.data) {
                state.objDetail = action.data;
            }
            return {
                ...state,
            };
        case GET_DROPDOWN_PROCESS_REDUCER:
            if (action.data) {
                state.dropdownProcess = action.data;
            }
            return {
                ...state,
            };
        case GET_DROPDOWN_PRODUCT_PROCESS_REDUCER:
            if (action.data) {
                state.dropdownProduct = action.data;
            }
            return {
                ...state,
            };
        case GET_DATA_TABLE_REDUCER:
            if (action.data) {
                state.dataTable = action.data;
                state.callAPIsuccess = true;
            }
            return {
                ...state,
            };
        case CHANGE_DATA_REDUCER:
            if (action.data) {
                state.dataTable = action.data;
                state.callAPIsuccess = true;
            }
            return {
                ...state,
            };
        case CHANGE_DATA_DEFAULT_REDUCER:
            state.callAPIsuccess = false;
            state.callAPICreOrUpsuccess = false;
            state.isLoading = true;
            return {
                ...state,
            };
        case CALL_API_CREATE_OR_UPDATE:
            state.callAPICreOrUpsuccess = true;
            return {
                ...state,
            };
        case "default":
            state.callAPIsuccess = false;
            state.callAPICreOrUpsuccess = false;
            state.isLoading = true;
            return {
                ...state,
            };
        default:
            return {
                ...state,
            };
    }
};

export default WorkareaLinkProccessReducer;