const FORCE_UPDATE_BREADCRUMB = "FORCE_UPDATE_BREADCRUMB";


const stateDefault = {
    dataBreadcrumb: []
}

const BreadcrumbReducer = (
    state = stateDefault,
    action
) => {

    switch (action.type) {

        case FORCE_UPDATE_BREADCRUMB:
            if (action.dataBreadcrumb) {
                state.dataBreadcrumb = action.dataBreadcrumb;
            }
            return { ...state };
        default:
            return { ...state };
    }
};

export default BreadcrumbReducer;
