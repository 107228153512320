import {
  SET_DATA_TO_REDUCER,
  RESET_DATA_REDUCER,
  CALL_DELETE_CHECKPOINT_DONE,
  SET_DETAIL_CHECK_POINT_REDUCER,
  CALL_CREATE_OR_UPDATE_CHECKPOINT_REDUCER,
  UPDATE_TABLE_BY_TYPE_CHECK_POINT_SCREEN,
  SAVE_LIST_INDEX_SIZE
} from "./enum/actionEnum";

const stateDefault = {
  // state chứa thông tin data
  checkpointData: {},
  // state nhận biết danh sách data có gọi thành công hay không
  isCallDataCheckpointSuccess: false,
  // trạng thái để kiểm tra xóa checkpoint có thành công hay không
  isCallDeleteCheckpointSuccess: false,
  // chi tiet checkpoint 
  checkpointDetail: {},
  // trang thai check neu checkpoint lay du lieu thanh cong
  isCheckpointDetailGetSuccess: false,
  // trạng thái check nếu tạo mới hoặc update thành công
  isCreateOrUpdateSuccess: false,
  // trang thai check neu trang đã được cap nhat khi tao hoac cap nhat page
}

const CheckpointReducer = (
  state = {
    ...stateDefault,
    pageIndex: null,
    pageSize: null,
    isTableUpdateAfterCreateUpdate: false,
  },
  action
) => {
  switch (action.type) {
    case SET_DATA_TO_REDUCER:
      state.checkpointData = action.data;
      state.isCallDataCheckpointSuccess = true;

      return {
        ...state,
      };
    case RESET_DATA_REDUCER:
      state = stateDefault;

      return {
        ...state,
      }
    case CALL_DELETE_CHECKPOINT_DONE:
      state.isCallDeleteCheckpointSuccess = true;

      return {
        ...state,
      }
    case SET_DETAIL_CHECK_POINT_REDUCER:
      state.isCheckpointDetailGetSuccess = true;
      state.checkpointDetail = action.detailData;
      return {
        ...state,
      }
    case CALL_CREATE_OR_UPDATE_CHECKPOINT_REDUCER:
      state.isCreateOrUpdateSuccess = true;
      return {
        ...state,
      }

    case UPDATE_TABLE_BY_TYPE_CHECK_POINT_SCREEN:
      state.isTableUpdateAfterCreateUpdate = action.status;
      return {
        ...state,
      }
    default:
      state = stateDefault;

      return {
        ...state,
      };
  }
};

export default CheckpointReducer;
