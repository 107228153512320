export const skillLag = {
    language: "en",
    skill: {
        table: {
            name: "Name",
            action: "Action",
        },
        search: {
            placeHolder: "Search skill",
        },
        form: {
            addNew: "Create new skill",
            update: "Update skill",
            name: "Name of major/ skill",
            placeHolder: "Enter the name of the major/ skill",
        },
        delete: {
            title: "Clear skill",
            description: "Are you sure you want to delete this skill?",
        },
        btn: {
            add: "Create new",
            addNew: "Add",
            save: "Save",
            cancel: "Cancel",
            delete: "Delete",
            back: "Back"
        },
        error: {
            required: "Name of major/ skill cannot be left blank",
            maxLength: "Name of major/ skill must not exceed 255 characters",
            Name_exist: "Name of major/ skill has exist"
        },
        toastJS: {
            creatSuccess: "Create new skills for success",
            updateSuccess: "Update skill information successfully",
            deleteSuccess: "Successfully erase the skill",
        }
    }
}