import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import toastJs from "src/shared/common/toastJs";
import ReactHookDemoAction from "./reactHookDemoAction";
import { getPagingService, createDataService, updateDataService, deleteDataService, getDetailService } from "./reactHookDemoService";
import { CREATE_DATA_SAGA, DELETE_DATA_SAGA, GET_DETAIL_DATA_SAGA, GET_DROP_DOWN_UNIT, GET_PAGING_DEMO_HOOK_SAGA, UPDATE_DATA_SAGA } from "./enum/hookDemoTypeAction";

export function* getPagingDemoHookSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ?? " ";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      SearchTerm: searchTerm,
    };


    const response = yield call(getPagingService, request);
    if (response?.isSuccess) {
      yield put(ReactHookDemoAction.getPagingReducer(response.data));
    }
    closeLoading()
  } catch (error) {
    closeLoading()

  }
}
export function* getDropDownUnit() {
  try {
    const request = {};
    const response = yield call(getPagingService, request);
    if (response.isSuccess) {
      for (let i = 0; i < response.data.Items.length; i++) {
        response.data.Items[i].id = i
      }
      yield put(ReactHookDemoAction.returnDataReducer(response.data));
    }
  } catch (error) {
    closeLoading();

  }
}

export function* createDataSaga(data) {
  try {
    const result = yield call(createDataService, data.request);
    if (result) {
      toastJs.success("Tạo mới đơn vị thành công");
      yield put(ReactHookDemoAction.callResultFromApiReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}

export function* updateDataSaga(data) {
  try {
    const result = yield call(updateDataService, data.request);
    if (result) {
      yield put(ReactHookDemoAction.callResultFromApiReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}

export function* deleteDataSaga(data) {
  try {
    const result = yield call(deleteDataService, data.modelCode);

    if (result) {
      toastJs.success("Xóa thành công đơn vị");
      yield put(ReactHookDemoAction.deleteDataReducer());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}

export function* getDetailDataSaga(data) {
  try {
    const objectData = yield call(getDetailService, data.modelCode);
    if (objectData) {
      yield put(ReactHookDemoAction.returnDetailReducer(objectData));
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}

export default function* ReactHookDemoSaga() {
  yield takeEvery(GET_PAGING_DEMO_HOOK_SAGA, getPagingDemoHookSaga);
  yield takeEvery(CREATE_DATA_SAGA, createDataSaga);
  yield takeEvery(UPDATE_DATA_SAGA, updateDataSaga);
  yield takeEvery(DELETE_DATA_SAGA, deleteDataSaga);
  yield takeEvery(GET_DETAIL_DATA_SAGA, getDetailDataSaga);
  yield takeEvery(GET_DROP_DOWN_UNIT, getDropDownUnit)
}
