export const navbarTime = {
    language: "en",
    navbartime: {
        navbar: {
            work_schedule_management: "Work schedule management",
            set_up_a_schedule: "Set up a schedule",
            working_shift: "Working shift",
            type_of_working_day: "Type of working day",
            group_of_working_days: "Group of working days"
        }
    },
};
