import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
    GET_PAGING_CATMEDICALMATERIAL_API,
    DELETE_CATMEDICALMATERIAL_API,
    CREATE_CATMEDICALMATERIAL_API,
    UPDATE_CATMEDICALMATERIAL_API,
    GET_DETAIL_CATMEDICALMATERIAL_API,GET_LIST_CATMEDICALMATERIAL_API
} from "./enum/TypeCatMedicalMaterialAPI.js";
import { toast } from "react-toastify";

export async function getPagingCatMedicalMaterialService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_PAGING_CATMEDICALMATERIAL_API,
        data
    )
}


export async function createCatMedicalMaterialService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_CATMEDICALMATERIAL_API,
        data
    )
}
export async function updateCatMedicalMaterialService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_CATMEDICALMATERIAL_API,
        data
    )
}
export async function deleteCatMedicalMaterialService(deleteId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        DELETE_CATMEDICALMATERIAL_API +
        "?Id=" + deleteId
    )
}
export async function getDetailCatMedicalMaterialService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_DETAIL_CATMEDICALMATERIAL_API +
        "?Id=" + Id
    )
}