import React, { createContext, useContext, useEffect, useState } from "react";
export const AppContext = createContext(null);
const AppContextProvider = (props) => {
    const [pageIndex, setPageIndex] = useState("");
    const [pageSize, setPageSize] = useState("");
    const [isNavBarExist, setIsNavbarExist] = useState(false);
    const heightMasterData = useWindowSize(isNavBarExist);
    const contextValue = { pageIndex, setPageIndex, pageSize, setPageSize, heightMasterData, isNavBarExist, setIsNavbarExist };

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider>
    )
}


export function useWindowSize(isNavBarExist) {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {

        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight - 260,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures that effect is only run on mount
    useEffect(() => {

        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight - (isNavBarExist ? 320 : 260),
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNavBarExist]); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export default AppContextProvider;