export const timeManagerType = {
    language: 'en',
    table_time_type: {
        name: "Name type of working day",
        percent: "Salary percentage",
        enjoy_bhxh: "Enjoy BHXH",
        color: "Instead color",
        group_day: "Group of woking day"
    },
    placeholder_type_time: "Enter name type of working day",
    form_type_time: {
        title_create: "Create new type of working day",
        title_infor: "Type of working day information",
        title_edit: "Update type of working day",
        title_delete: "Delete type of working day",
        text_confirm_delete: "Do you want to remove type of working day?",
        describe: "Describe",
        placeholder: "Enter describe",
        start_date: "Apply from the date",
        end_date: "Apply to the date",
        title_form_time_shif: "Choose time shift",
        btn_add_time_shift: "Add time shift"
    },
    mes_type_time: {
        required_name: "Name cannot be empty",
        maxLenght_name: "Name no longer than 100 characters",
        maxLenght_sign: "Sign no longer than 20 characters",
        required_sign: "Sign cannot be empty",
        required_group_day: "Group day cannot be empty",
        maxLenght_percent: "Salary percentage no longer than 100 characters",
        required_percent: "Salary percentage cannot be empty",
        create_success: "Create type of working day successfully",
        update_success: "Update type of working day successfully",
        delete_success: "Delete type of working day successfully",
        Enddate_required: "Applicability end date cannot be left blank",
        startdate_required: "Applicable start date cannot be blank",
    }
}