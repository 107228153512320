import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PAGING_SUPPLIER_CATEGORY_SAGA,
  DELETE_SUPPLIER_CATEGORY_SAGA,
  CREATE_SUPPLIER_CATEGORY_SAGA,
  UPDATE_SUPPLIER_CATEGORY_SAGA,
} from "./enum/TypeCatSupplierAction";
import { closeLoading } from "src/shared/common/LoadingAction";
import CatSupplierAction from "./CatSupplierAction";
import {
  getPagingService,
  deleteCatSupplierService,
  createCatSupplierService,
  updateCatSupplierService,
} from "./CatSupplierService";

export function* getPagingSaga(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ? data.searchTerm.trim() : "";

    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      searchTerm: searchTerm,
    };
    const response = yield call(getPagingService, request);
    if (response) {
      if (response.Items.length <= 0 && request.PageIndex > 1) {
        yield put(
          CatSupplierAction.getPagingSaga(
            request.PageIndex - 1,
            request.PageSize,
            request.searchTerm,
            request.unitCode
          )
        );
      } else {
        yield put(CatSupplierAction.getPagingReducer(response));
      }
    }
    closeLoading();
  } catch (error) {
    closeLoading();
  }
}
export function* deleteCatSupplierSaga(request) {
  try {
    if (request.id) {
      const response = yield call(deleteCatSupplierService, request.id);
      if (response.isSuccess) {
        yield put(CatSupplierAction.deleteDataReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export function* createCatSupplierSaga(requet) {
  try {
    if (requet) {
      const requestAPI = {
        Id: requet?.data?.Id,
        Code: requet?.data?.Code?.trim(),
        Name: requet?.data?.Name?.trim(),
      };
      const response = yield call(createCatSupplierService, requestAPI);
      if (response.isSuccess) {
        yield put(CatSupplierAction.callCreateUpdateSuccessReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}
export function* updateCatSupplierSaga(requet) {
  try {
    if (requet?.data) {
      const requestAPI = {
        Id: requet?.data?.Id,
        Code: requet?.data?.Code?.trim(),
        Name: requet?.data?.Name?.trim(),
      };
      const response = yield call(updateCatSupplierService, requestAPI);
      if (response.isSuccess) {
        yield put(CatSupplierAction.callCreateUpdateSuccessReducer());
      }
      closeLoading();
    }
  } catch (error) {
    closeLoading();
  }
}

export default function* CatSupplierManagementSaga(data) {
  yield takeEvery(GET_PAGING_SUPPLIER_CATEGORY_SAGA, getPagingSaga);
  yield takeEvery(DELETE_SUPPLIER_CATEGORY_SAGA, deleteCatSupplierSaga);
  yield takeEvery(CREATE_SUPPLIER_CATEGORY_SAGA, createCatSupplierSaga);
  yield takeEvery(UPDATE_SUPPLIER_CATEGORY_SAGA, updateCatSupplierSaga);
}
