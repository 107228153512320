import {
  GET_MENU_PERMISSION_REDUCER,
  SET_MENU_SELECT_PERMISSION_REDUCER,
} from "./enum/permissionEnumAction";
import { uniq } from "lodash";
// to: null,
// icon: '/assets/img/logo/homeIconSvg.svg',
// title: 'control chung dự án',
// id: 'controlChung',
// parentId: null,
// level: 0,
// "MenuId": "63ca6efa-e8e4-4a9b-b60e-0570c76706ce",
// "MenuName": "Báo cáo đầu chuyền",
// "MenuTitle": "Báo cáo đầu chuyền",
// "MenuType": 1,
// "Parent": "c268a4ae-ea81-40e6-a253-6f424ba9d9ad",
// "ActionApi": 0,
// "ActionCode": "",
// "Status": 0,
// "IsMenuAction": true,
// "IsHorizontal": false,
// "Islevel": 2,
// "IsDefault": false,
// "comPath": "src/components/page/QuanlityManager/Report/HeadlineReport/Index",
// "path": "/bao-cao-day-chuyen",
// "imagePath": "",
// "sortOrder": 1,
// "permissionMenus": null,
// "actionMenus": null

const stateDefault = {
  listMenu: [], // get server
  listMenuShow: [], // convert form listMenu => data show menu
  listActionForMenu: [], // get server
  listDataCompData: [],
  menuDefaultShow: {}, // Selected menu
  listActionForMenuSelected: [], // Selected menu
  listMenuHorizontal: [],
  listMenuHorizontalShowAnt: [],
  isGetApiMenuPermission: false,
  listActionAccessible: [],
};

const PermissionReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_MENU_PERMISSION_REDUCER:
      if (action.response) {
        state.listMenu = action.response?.listMenu;
        if (
          action.response &&
          action.response?.listMenu &&
          action.response?.listMenu.length > 0
        ) {
          let menuShow = [];
          action.response?.listMenu.map((itemMenu) => {
            if (itemMenu.IsHorizontal != true) {
              // chi day nhung thang menu dọc, nhung thang menu ngang select menu doc moi lay
              let itemMenuShow = {
                to: itemMenu.path ? itemMenu.path : null,
                icon: itemMenu.Islevel == 0 ? itemMenu?.imagePath : "",
                title: itemMenu.MenuTitle,
                id: itemMenu.MenuId,
                parentId: itemMenu.Parent ? itemMenu.Parent : null,
                level: itemMenu.Islevel ? itemMenu.Islevel : 0,
              };
              menuShow.push(itemMenuShow);
            }
          });
          state.listMenuShow = menuShow;
        }
        state.listActionMenu = action.response?.listActionMenu;
        if (action.response?.listMenu && action.response?.listMenu.length > 0) {
          state.listDataCompData = action.response?.listMenu.filter(
            (x) => x.comPath != "" && x.comPath != null
          );
        }
        if (
          action.response?.listActionMenu &&
          action.response?.listActionMenu?.length > 0
        ) {
          state.listActionAccessible = uniq(
            action.response.listActionMenu.map((action) => action.ActionName)
          );
        }
      }
      return {
        ...state,
        isGetApiMenuPermission: true,
      };
    case SET_MENU_SELECT_PERMISSION_REDUCER:
      state.listMenuHorizontal = action.data.menuChildens;
      let listMenuHorizontalShowAntNew = [];
      if (state.listMenuHorizontal && state.listMenuHorizontal.length > 0) {
        state.listMenuHorizontal.forEach((item) => {
          let dataShowAnt = {
            key: item.path,
            label: item.MenuTitle,
          };
          listMenuHorizontalShowAntNew.push(dataShowAnt);
        });
      }
      state.listMenuHorizontalShowAnt = listMenuHorizontalShowAntNew;
      state.menuDefaultShow = action.data.menuDefaultShow;
      if (state.menuDefaultShow.IsMenuAction == true) {
        // let acitonByMenuShow = state.listActionForMenu?.filter(x => x.MenuId == state.menuDefaultShow?.MenuId);
        // if (acitonByMenuShow && acitonByMenuShow.length > 0) {
        state.listActionForMenuSelected =
          action.data?.menuDefaultShow?.actionMenus;
        // }
      }
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
};

export default PermissionReducer;
