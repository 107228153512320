import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import { GET_WORK_UNIT_API, CREATE_WORKER_MACHINE_API, GET_WORKER_MACHINE_API, GET_WORKUNIT_WITH_WORKCENTER_WO,CHANGE_WORKER_MACHINE } from "./enum/typeProductionLineApi"
export async function getWorkerByCodeSerVice(data) {
    let api = "";
    return await CommonBase.getAsync(
        configApp.apiGateWay + api,
        data
    )
};
export async function getWorkUnitService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + GET_WORK_UNIT_API,
        data
    )
};
export async function getWorkerMachineService(code) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_WORKER_MACHINE_API,code
    )
};
export async function updateProductionLine(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + CREATE_WORKER_MACHINE_API, request
    )
};
export async function getWorkUnitWithWCWOService(request) {
    return await CommonBase.getAsync(
        configApp.apiGateWay + GET_WORKUNIT_WITH_WORKCENTER_WO,request
    )
};
export async function changeWorkerMachineService(request) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + CHANGE_WORKER_MACHINE,request
    )
};