import {
  GET_PAGING_SOP_REDUCER,
  CALL_DEFAULT_SOP_REDUCER,
  CALL_RESULT_SOP_CREATE_REDUCER,
  GET_DETAIL_SOP_REDUCER,
  CALL_CHANGE_VERSION_REDUCER,
  GET_DROPDOWN_VERSION_REDUCER,
  CALL_RESULT_SOP_UPDATE_REDUCER,
  CALL_RESULT_ADD_VERSION_REDUCER,
  DELETE_VERSION_REDUCER,
  CLEAR_ALL_SOP_REDUCER_STATE
} from "./enum/SOPTypeAction";
const stateDefault = {
  isFetching: false,
  isFetchingMore: false,
  listData: [],
  objectData: {},
  callResultFromApi: false,
  callResultFromApiCreate: false,
  callResultFromApiUpdate: false,
  callResultDetail: false,
  isDeleteSuccess: false,
  isChangeVersionSop: false,
  isAddVersionSop: false,
  isCallDropdownVersion: false,
  DropdownVersion: [],

}
const SOPReducer = (
  state = stateDefault,
  action
) => {
  switch (action.type) {
    case GET_PAGING_SOP_REDUCER:
      if (action.data) {
        state.listData = action.data;
        state.callResultFromApi = true;
      }
      return {
        ...state,
      };
    case CALL_DEFAULT_SOP_REDUCER:
      state.callResultFromApiCreate = false;
      state.callResultFromApiUpdate = false;
      state.objectData = null;
      state.isChangeVersionSop = false;
      state.callResultDetail = false;
      state.isCallDropdownVersion = false;
      state.isDeleteSuccess = false;
      state.isAddVersionSop = false;
      state.callResultFromApi = false;

      return {
        ...state,
      };

    case GET_DETAIL_SOP_REDUCER:
      state.callResultDetail = true;
      if (action.obj) {
        state.objectData = action.obj.data;
      }
      return {
        ...state,
      };
    case CALL_RESULT_SOP_CREATE_REDUCER:
      state.callResultFromApiCreate = true;
      return {
        ...state,
      };
    case CALL_RESULT_SOP_UPDATE_REDUCER:
      state.callResultFromApiUpdate = true;
      return {
        ...state,
      };
    case CALL_RESULT_ADD_VERSION_REDUCER:
      state.isAddVersionSop = true;
      return {
        ...state,
      };

    case CALL_CHANGE_VERSION_REDUCER:
      state.isChangeVersionSop = true;
      return {
        ...state,
      };
    case GET_DROPDOWN_VERSION_REDUCER:
      state.isCallDropdownVersion = true;
      state.DropdownVersion = action.data;
      return {
        ...state,
      };
    case DELETE_VERSION_REDUCER:
      state.isDeleteSuccess = true;
      return {
        ...state,
      };
    case CLEAR_ALL_SOP_REDUCER_STATE:
      return {
        state: stateDefault,
      }
    default:
      return {
        ...state,
      };
  }
};

export default SOPReducer;
