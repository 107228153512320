import i18next from "i18next";
import { call, put, takeEvery } from "redux-saga/effects";
import { closeLoading } from "src/shared/common/LoadingAction";
import toastJs from "src/shared/common/toastJs";
import {
    CREATE_MAINTAINCE_SCHEDULE_SAGA, CREATE_PERIOD_MAINTANCE_SAGA, CREATE_PERIOD_SCHEDULE_SAGA, DELETE_PERIOD_MAINTANCE_SAGA, GET_DATA_MAINTAINCE_CALENDAR_SAGA,
    GET_DATA_MAINTAINCE_SCHEDULE_SAGA, GET_DETAIL_PERIOD_SAGA, UPDATE_PERIOD_MAINTANCE_SAGA, GET_DETAIL_MAINTANCE_SCHEDULE_SAGA, CARRY_MAINTANCE_SCHEDULE_SAGA,
    SKIP_MAINTANCE_SCHEDULE_SAGA
} from "./enum/MaintainTypeAction";
import MaintainceAction from "./MaintainceAction";
import {
    createMaintainScheduleService, createPeriodScheduleService, createPeriodService, deletePeriodMaintanceService, getDataMaintainceCalendarService,
    getDataPagingMaintanceScheduleService, getDataPeriodByCodeService, updatePeriodService, getDetailMaintanceScheduleService, carryMaintanceScheduleService,
    skipMaintanceScheduleService
} from "./MaintainceService";
import { v4 as uuidv4 } from "uuid";
export function* getDataMaintainceCalendarSaga(data) {
    try {
        const request = {
            SearchTerm: data.search,
            PageSize: data.size,
            PageIndex: data.index,
        }
        const response = yield call(getDataMaintainceCalendarService, request);
        if (response.isSuccess) {
            if (response.data.Items.length <= 0 && request.PageIndex > 1) {
                yield put(MaintainceAction.getDataMaintainceCalendarSaga(request.SearchTerm, request.PageIndex - 1, request.PageSize));
            } else {
                yield put(MaintainceAction.setDataMaintainceCalendarReducer(response.data));
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* deletePeriodMaintance(data) {
    try {
        const dataPage = data.dataPage;
        const response = yield call(deletePeriodMaintanceService, data.Periodcode)
        if (response.isSuccess) {
            yield put(MaintainceAction.callDeletePeriodSuccess());
            yield put(MaintainceAction.getDataMaintainceCalendarSaga(dataPage.searchKey, dataPage.pageIndex, dataPage.pageSize));
            toastJs.success(i18next.t("periodlag.message.deletesuccess"));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDataPeriodByCode(data) {
    try {
        const response = yield call(getDataPeriodByCodeService, data.Periodcode)
        if (response.isSuccess) {
            yield put(MaintainceAction.callGetDetailPeriodSuccess(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* createPeriodMaintance(data) {
    try {
        const dataPage = data.dataPage;
        const request = data.dataRequest;
        request.MaterialExpectedMaintenanceRequests = request.MaterialExpectedMaintenanceGetMaps;
        const response = yield call(createPeriodService, request)
        if (response.isSuccess) {
            toastJs.success(i18next.t("periodlag.message.createSuccess"));
            yield put(MaintainceAction.callCreateOrUpdateSuccess());
            yield put(MaintainceAction.getDataMaintainceCalendarSaga(dataPage.searchKey, 1, dataPage.pageSize));
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* updatePeriodMaintance(data) {
    try {
        const dataPage = data.dataPage;
        const request = data.dataRequest;
        request.UpdateMaterialExpectedMaintenanceRequests = request.MaterialExpectedMaintenanceGetMaps;
        const response = yield call(updatePeriodService, request)
        if (response.isSuccess) {
            toastJs.success(i18next.t("periodlag.message.updatemaintance"));
            yield put(MaintainceAction.callCreateOrUpdateSuccess());
            yield put(MaintainceAction.getDataMaintainceCalendarSaga(dataPage.searchKey, dataPage.pageIndex, dataPage.pageSize));
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* getDataPagingMaintanceSchedule(data) {
    try {
        const request = {
            SearchTerm: data.dataPage.searchKey ?? "",
            PageSize: data.dataPage.pageSize,
            PageIndex: data.dataPage.pageIndex,
            Status: data.dataPage.Status,
            dateSearch: data.dataPage.dateSearch != null && data.dataPage.dateSearch != '' ? new Date(data.dataPage.dateSearch) : null,
        }
        const response = yield call(getDataPagingMaintanceScheduleService, request);
        if (response.isSuccess) {
            if (response.data.Items.length <= 0 && request.PageIndex > 1) {
                let dataPage = data.dataPage;
                dataPage.pageIndex--;
                yield put(MaintainceAction.getDataPagingMaintanceSchedule(dataPage));
            } else {
                yield put(MaintainceAction.setDataPagingMaintanceSchedule(response.data));
            }
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error);
    }
}
export function* createPeriodSchedule(data) {
    try {
        const response = yield call(createPeriodScheduleService, data.date);
        if (response.isSuccess) {
            yield put(MaintainceAction.setListPeriodScheduleReducer(response.data));
        }
        else {
            yield put(MaintainceAction.callDefault());
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* createMaintainScheduleSaga(data) {
    try {
        const response = yield call(createMaintainScheduleService, data.dataRequest);
        if (response.isSuccess) {
            yield put(MaintainceAction.callCreateOrUpdateSchedule());
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* getDetailMaintanceSchedule(data) {
    try {
        let request = {
            Maintenanceschedulecode: data.code,
            type: data.detail,
        }
        const response = yield call(getDetailMaintanceScheduleService, request);
        if (response.isSuccess) {
            if (response.data.MaterialExpectedMaintenances && response.data.MaterialExpectedMaintenances.length > 0) {
                response.data.MaterialExpectedMaintenances.forEach(item => {
                    item.Id = uuidv4();
                });
            }
            yield put(MaintainceAction.setDetailMaintanceSchedule(response.data, request.type));
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* carryMaintanceSchedule(data) {
    try {
        let request = data.dataModal;
        request.Listconsumables = request.MaterialExpectedMaintenances;
        const response = yield call(carryMaintanceScheduleService, request);
        if (response.isSuccess) {
            toastJs.success("Thực hiện lệnh thành công");
            yield put(MaintainceAction.getDataMaintainceCalendarSaga('', 1, 10));
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* skipMaintanceSchedule(data) {
    try {
        let request = {
            Maintenanceschedulecode: data.maintanceScheduleCode,
            note: data.note,
        }
        const response = yield call(skipMaintanceScheduleService, request);
        if (response.isSuccess) {
            toastJs.success("Bỏ qua lệnh thành công");
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export default function* MaintainceSaga() {
    yield takeEvery(GET_DATA_MAINTAINCE_CALENDAR_SAGA, getDataMaintainceCalendarSaga);
    yield takeEvery(DELETE_PERIOD_MAINTANCE_SAGA, deletePeriodMaintance);
    yield takeEvery(GET_DETAIL_PERIOD_SAGA, getDataPeriodByCode);
    yield takeEvery(CREATE_PERIOD_MAINTANCE_SAGA, createPeriodMaintance);
    yield takeEvery(UPDATE_PERIOD_MAINTANCE_SAGA, updatePeriodMaintance);
    yield takeEvery(GET_DATA_MAINTAINCE_SCHEDULE_SAGA, getDataPagingMaintanceSchedule);
    yield takeEvery(CREATE_PERIOD_SCHEDULE_SAGA, createPeriodSchedule);
    yield takeEvery(CREATE_MAINTAINCE_SCHEDULE_SAGA, createMaintainScheduleSaga);
    yield takeEvery(GET_DETAIL_MAINTANCE_SCHEDULE_SAGA, getDetailMaintanceSchedule);
    yield takeEvery(CARRY_MAINTANCE_SCHEDULE_SAGA, carryMaintanceSchedule);
    yield takeEvery(SKIP_MAINTANCE_SCHEDULE_SAGA, skipMaintanceSchedule);
}