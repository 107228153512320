export const delivery = {
  language: "vn",
  delivery_form: {
    information: "Thông tin giao hàng",
    create_title: "Tạo mới thông tin giao hàng",
    update_title: "Chỉnh sửa thông tin giao hàng",
    select_PO: "Chọn PO",
    create_PO: "Tạo DO độc lập",
    delivery_code: "Mã phiếu giao hàng",
    delivery_use: "Người giao hàng",
    partner_code: "Mã đối tác",
    date_of_inventory: "Ngày xuất kho",
    delivery_date: "Ngày giao hàng",
    active: "Trạng thái",
  },
};
