// packages
import _ from "lodash";
import bind from "classnames/bind";

// constants
import { ALIAS_PATHS } from "./constants";

// components
import AutoTooltipText from "src/components/base/AutoTooltipText/AutoTooltipText";

// styles
import styles from "./Sidebar.module.scss";

const cx = bind.bind(styles);

export const getAncestorsFromHiddenMenus = ({
  currentPath,
  menus,
  hiddenMenus,
}) => {
  const matchedParentPath = hiddenMenus.find(
    (hiddenMenu) => currentPath === hiddenMenu.path
  )?.parentPath;

  if (_.isNil(matchedParentPath)) {
    return [];
  }

  const ancestors = [];
  getMatchedMenuKeys({
    menus: menus,
    path: matchedParentPath,
    keys: ancestors,
  });
  return ancestors;
};

const getMatchedMenuKeys = ({ menus = [], path = "", keys = [] }) => {
  for (let i = 0; i < menus.length; i++) {
    const menu = menus[i];
    const key = menu?.key ?? i;
    if (!_.isNil(menu?.to) && menu.to === path) {
      keys.push(key);
      return true;
    }

    if (Array.isArray(menu?.children)) {
      if (
        getMatchedMenuKeys({
          menus: menu?.children,
          path,
          keys,
        })
      ) {
        keys.splice(keys.length - 1, 0, key);
        return true;
      }
    }
  }

  return false;
};

export const getAliasPath = ({ path, query }) => {
  const matchedItem = ALIAS_PATHS.find((item) => path === item.originPath);

  if (!_.isNil(matchedItem)) {
    return matchedItem.getAliasPath({
      query,
      defaultPath: path,
    });
  }

  return path;
};

// convert dữ liệu để hiển thị trên menu
export function getItem({ label, key, icon, children, to, level = 0 }) {
  return {
    key,
    icon,
    children,
    label,
    to,
    level,
    popupClassName: level > 0 ? "submenu-flight" + level.toString() : undefined,
  };
}

const clampId = (oldId, minLength) => {
  return oldId.substring(0, minLength);
};

const compareKeys = (key1, key2) => {
  if (key1 === key2) {
    return true;
  }

  if (typeof key1 !== "string" || typeof key2 !== "string") {
    return false;
  }

  const minLength = Math.min(key1.length, key2.length);
  const newKey1 = clampId(key1, minLength);
  const newKey2 = clampId(key2, minLength);

  return newKey1 === newKey2;
};

// convert dữ liệu sidebar để hiển thị trong menu
export const getMenuItems = ({ treeData, selectedKeys, collapsed }) => {
  if (!Array.isArray(treeData)) {
    return null;
  }

  if (treeData.length === 0) {
    return null;
  }

  return treeData.map((subMenu, subMenuindex) => {
    const inSelectedPath = selectedKeys.some((selectedKey) => {
      return compareKeys(selectedKey, subMenu.id);
    });

    return getItem({
      label: collapsed ? null : (
        <AutoTooltipText
          className={cx("menu-label", {
            "menu-label-selected": inSelectedPath,
          })}
          text={subMenu.title}
          type="default"
        />
      ),
      key: subMenu.id + subMenuindex,
      icon: subMenu.icon && (
        <img
          className={cx("menu-icon", { "menu-icon-selected": inSelectedPath })}
          src={subMenu.icon}
        />
      ),
      children: getMenuItems({
        treeData: subMenu.children,
        selectedKeys,
      }),
      to: subMenu.to,
      level: subMenu.level,
    });
  });
};

// chuyển đổi dữ liệu từ dạng array sang tree
export const getTreeData = (
  arr,
  parentId = null,
  ancestorId = null,
  collapsed = false
) => {
  return arr
    .filter((item) => item.parentId === parentId && !item?.invisible)
    .map((item) => ({
      level: item.level,
      to: item.to,
      icon:
        item.id === ancestorId &&
        item?.activeIcon &&
        (collapsed || item?.id === "trangChu")
          ? item.activeIcon
          : item.id === ancestorId &&
            item?.activeIconCollapse &&
            (!collapsed || item?.id !== "trangChu")
          ? item.activeIconCollapse
          : item.icon,
      title: item.title,
      id: item.id,
      parentId: item.parentId,
      children: getTreeData(arr, item.id, ancestorId, collapsed),
    }));
};

// tìm kiếm object con theo tên url và trả về object
export const getAncestors = (target, children = [], ancestors = []) => {
  for (let node of children) {
    // let keyArr = (node.key).split("_url:");
    if (node.to == target) {
      return ancestors.concat(node.key);
    } else if (node.children?.length > 0) {
      const found = getAncestors(
        target,
        node.children,
        ancestors.concat(node.key)
      );
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};
