import { call, put, takeEvery } from "redux-saga/effects";
import AccessoriesAction from "./AccessoriesAction";
import * as AccessoriesService from "./AccessoriesService";
import * as types from "./enum/AccessoriesTypeAction";
import { closeLoading } from 'src/shared/common/LoadingAction';
import toastJs from 'src/shared/common/toastJs';
import i18next, { t } from 'i18next';
//Worker saga
// lấy danh sách
export function* getAll(data) {
  try {
    const pageSize = data.pageSize;
    const pageIndex = data.pageIndex;
    const searchTerm = data.searchTerm ?? " ";
    const request = {
      PageIndex: pageIndex,
      PageSize: pageSize,
      SearchTerm: searchTerm,
    };
    const response = yield call(AccessoriesService.getAll, request);
    if (response.isSuccess) {
      for (let i = 0; i < response.data.Items.length; i++) {
        response.data.Items[i].id = i
      }
      yield put(AccessoriesAction.returnData(response.data));
    }
    yield put(AccessoriesAction.closeLoading(true));
    closeLoading()
  } catch (error) {

    closeLoading()
  }
}
// tạo mới
export function* createData(data) {
  try {

    const result = yield call(AccessoriesService.createData, data.request);
    if (result.isSuccess) {
      // chuyển trạng thái gọi api thành công
      toastJs.success(t("accessories.toastJS.creatSuccess"));
      yield put(AccessoriesAction.callResultFromApi());
    }
    else {
      yield put(AccessoriesAction.getCode());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
// cập nhật
export function* updateData(data) {
  try {
    const result = yield call(AccessoriesService.updateData, data.request);
    if (result.isSuccess) {
      toastJs.success(t("accessories.toastJS.updateSuccess"));
      yield put(AccessoriesAction.callResultFromApi());
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
// xóa dữ liệu
export function* deleteData(data) {
  try {
    const result = yield call(AccessoriesService.deleteData, data.productCode);
    if (result.isSuccess) {
      toastJs.success(t("accessories.toastJS.deleteSuccess"));
      yield put(AccessoriesAction.closePopupDelete(true));
    }
    closeLoading();
  } catch (error) {

  }
}
// lấy chi tiết
export function* getDetail(data) {
  try {
    const objectData = yield call(AccessoriesService.getDetail, data.productCode);
    if (objectData.isSuccess) {
      yield put(AccessoriesAction.returnDetail(objectData));
    }
    closeLoading();
  } catch (error) {
    closeLoading();

  }
}
export default function* AccessoriesSaga() {
  yield takeEvery(types.GET_ALL_ACCESSORIES, getAll);
  yield takeEvery(types.CREATE_ACCESSORIES, createData);
  yield takeEvery(types.UPDATE_ACCESSORIES, updateData);
  yield takeEvery(types.DELETE_ACCESSORIES, deleteData);
  yield takeEvery(types.GET_DETAIL_ACCESSORIES, getDetail);
}
