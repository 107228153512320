export const ApprovalConfig = {
    language: "en",
    approvalConfig: {
        search: {
            placeHolder:
                "Search by configuration name",
        },
        table: {
            name: 'Configuration name',
            description: "Description",
            createdBy: "Creator",
            createdAt: "Created date"
        },
        form: {
            title: {
                create: 'Create a new configuration',
                update: 'Edit configuration',
                detail: 'Detail',
                approvalFlow: 'Approval flow'
            },
            content: {
                name: {
                    title: "Title",
                    placeHolder: "Enter title"
                },
                typeApprovalFlow: {
                    title: "Configuration type",
                    placeHolder: "Select configuration type"
                },
                approvalFlowTemplate: {
                    title: "Approval Process",
                    placeHolder: "Select approval process"
                },
                description: {
                    title: "Description",
                    placeHolder: "Enter description..."
                },
                duration: {
                    hour: "h"
                }
            },

            rule: {
                name: {
                    required: "Name cannot be left blank",
                    maxLength: "Name cannot exceed 250 characters"
                },
                typeApprovalFlow: {
                    required: "Configuration type cannot be left blank",
                },
                approvalFlowTemplate: {
                    required: "Approval process cannot be left blank",
                },
                description: {
                    maxLength: "Description cannot exceed 500 characters"
                },
            }
        },
        popup: {
            delete: {
                title: 'Clear the approval process configuration',
                content: 'Are you sure you want to delete the approval process configuration?',
            }
        },
        toastjs: {
            createSuccess: "Successfully created new approval process configuration",
            updateSuccess: "Approval configuration update successful",
            deleteSuccess: "Delete the configuration of the approval process successfully",
        },
    },
};
