export const accessoriesTypeLag = {
    language: "vn",
    accessoriesType: {
        title:"Quản lý kiểu linh kiện",
        table: {
            Typename:"Kiểu linh kiện",
            Description:"Mô tả",
            Status:"Đang hoạt động",
            Action:"Thao tác",
        },
        btnTitle:{
            seeDetail:"Xem chi tiết",
            edit:"Chỉnh sửa",
            delete:"Xoá",
        },
        search:{
            placeHolder:"Kiểu linh kiện, mô tả"
        },
        form: {
            create:"Thêm mới kiểu linh kiện",
            update:"Cập nhật kiểu linh kiện",
            getDetail:"Chi tiết kiểu linh kiện",
            Typename:"Tên kiểu linh kiện",
            Description:"Mô tả",
            Status:"Đang hoạt động",
            placeHolder:{
                Typename:"Nhập tên kiểu linh kiện",
                Description:"Nhập mô tả"
            }
        },
        delete: {
            title: "Xoá kiểu linh kiện",
            description: "Bạn có chắc chắn muốn xoá kiểu linh kiện này?",
        },
        error: {
            Typename: {
                specialCharacters:"Tên kiểu linh kiện Không chứa ký hiệu đặc biệt",
                required: "Tên kiểu linh kiện không được bỏ trống",
                maxLength: "Tên kiểu linh kiện không được quá 255 ký tự",
            },
            Description:{
                maxLength: "Mô tả không được quá 255 ký tự",
            },
        },
        toastJS: {
            creatSuccess:"Tạo mới kiểu linh kiện thành công",
            updateSuccess:"Cập nhật kiểu linh kiện thành công",
            deleteSuccess:"Xóa thành công kiểu linh kiện",
        }
    }
}