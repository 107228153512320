import { SET_LIST_USER, SAVE_LIST_USER_LOOKUP_LDAP } from "./enum/UserManagementAction";


const UserSyncReducer = (
    state = {
        listUserData: [],
    },
    action
) => {
    switch (action.type) {
        case SET_LIST_USER:
            if (action.data && action.data?.Data?.listData) {
                state.listUserData = action.data?.Data?.listData;
            }
            return { ...state }
        case SAVE_LIST_USER_LOOKUP_LDAP: 
            if(action) {
                console.log(1111111, action)
            }
            return {...state}
        default:
            return { ...state }
    }
}

export default UserSyncReducer
