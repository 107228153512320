import i18next from 'i18next';
import { call, put, take, takeEvery } from 'redux-saga/effects';
import { closeLoading } from 'src/shared/common/LoadingAction';
import ProductionLineAction from "./ProdcutionLineAction";
import { getWorkerByCodeSerVice, getWorkUnitService, getWorkerMachineService, updateProductionLine, getWorkUnitWithWCWOService, changeWorkerMachineService } from "./ProductionLineSevice";
import { GET_WORKER_BY_CODE_SAGA, GET_WORK_UNIT_PRODUCTION_SAGA, GET_WORKER_MACHINE_SAGA, GET_WORKUNIT_WITH_WORKCENTER_AND_WO, CREATE_WORKER_MACHINE, SAVE_TRANFER_WORKUNIT_WCMC } from "./enum/typeProductionLineAction";
import toastJs from 'src/shared/common/toastJs';
export function* getPagingSaga(data) {
    try {
        let request = data.request;
        let response = yield call(getWorkerByCodeSerVice, request);
        if (response.isSuccess) {
            yield put(ProductionLineAction.setWorkerReducer(response.data));
        }
    } catch (error) {
        closeLoading();

    }
};
export function* getWorkUnitSaga(data) {
    try {
        let request = data.request;
        let response = yield call(getWorkUnitService, request);
        if (response.isSuccess) {
            yield put(ProductionLineAction.setWorkUnitReducer(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
};
export function* getWorkerMachineSaga(request) {
    try {
        let code = request.code;
        let response = yield call(getWorkerMachineService, code);
        if (response.isSuccess) {
            yield put(ProductionLineAction.setWorkerMachieReducer(response.data));
        }
        else toastJs.error(i18next.t("ProductionLineLaguage.mesEmptyObject"))
        closeLoading();
    } catch (error) {
        closeLoading();

    }
};
export function* updateProductionLineSaga(data) {
    try {
        let request = data.request;
        let response = yield call(updateProductionLine, request);
        if (response.isSuccess) {
            toastJs.success("Cập nhật thành công");
            yield put(ProductionLineAction.callSaveSuccess());
        }
        closeLoading();
    } catch (error) {
        console.log(error);
        closeLoading();
    }
};
export function* getWorkUnitWithWCWO(data) {
    try {
        let request = data.request;
        let response = yield call(getWorkUnitWithWCWOService, request);
        if (response.isSuccess) {
            yield put(ProductionLineAction.setWorkUnitWithWCWOReducer(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error);

    }
}
export function* changeWorkerMachineSaga(data) {
    try {
        let request = data.request;
        let response = yield call(changeWorkerMachineService, request);
        if (response.isSuccess) {
            yield put(ProductionLineAction.callReducerChangeSuccess());
            toastJs.success("Chuyển con người máy móc thành công");
        }
        closeLoading();
    } catch (error) {
        closeLoading();
        console.log(error);
    }
}
export default function* ProdcutionLineSaga() {
    yield takeEvery(GET_WORKER_BY_CODE_SAGA, getPagingSaga);
    yield takeEvery(GET_WORK_UNIT_PRODUCTION_SAGA, getWorkUnitSaga);
    yield takeEvery(GET_WORKER_MACHINE_SAGA, getWorkerMachineSaga);
    yield takeEvery(GET_WORKUNIT_WITH_WORKCENTER_AND_WO, getWorkUnitWithWCWO);
    yield takeEvery(CREATE_WORKER_MACHINE, updateProductionLineSaga);
    yield takeEvery(SAVE_TRANFER_WORKUNIT_WCMC, changeWorkerMachineSaga)
}