import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
    GET_LIST_PLANNING_DETAIL_API,
    DELETE_PLANNING_DETAIL_API,
    CREATE_PLANNING_DETAIL_API,
    UPDATE_PLANNING_DETAIL_API,
    GET_DETAIL_PLANNING_DETAIL_API
} from "./enum/TypePlanningDetailAPI";
export async function getListPlanningDetailService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_LIST_PLANNING_DETAIL_API,
        data
    )
}
export async function createPlanningDetailService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_PLANNING_DETAIL_API,
        data
    )
}
export async function updatePlanningDetailService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_PLANNING_DETAIL_API,
        data
    )
}
export async function deletePlanningDetailService(deleteId) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        DELETE_PLANNING_DETAIL_API +
        "?Id=" + deleteId
    )
}
export async function getDetailPlanningDetailService(Id) {
    return await CommonBase.getAsync(
        configApp.apiGateWay +
        GET_DETAIL_PLANNING_DETAIL_API +
        "?Id=" + Id
    )
}