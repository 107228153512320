export const repairLag = {
    language: "vn",
    repair: {
        table: {
            itemCode: "Item code",
            stepProcessName: "Công đoạn ghi nhận lỗi",
            workUnitName: "Dây chuyền ghi nhận lỗi",
            userRecord: "Người ghi nhận lỗi",
            workOderCode: "WO trực thuộc",
            decription: "Mô tả",
            timeRecord: "Thời gian ghi nhận",
            errorTypeName: "Nguyên nhân",
            status: "Trạng thái",
            action: "Thao tác",
            noData: "Không có dữ liệu để hiển thị"
        },
        placeholder: {
            searchTerm: "Mã lệnh bảo dưỡng, mã máy, tên máy",
            status: "Trạng thái",
            typeError: "Phân loại",
            timeSearch: "Thời gian ghi nhận lỗi",
        },
        btn: {
            create: "Thêm mới",
        },
        form: {
            formTitle: "Ghi nhận lỗi",
            seeDetailForm: "Chi tiết ghi nhận lỗi",
            createTitleForm: "Thông tin lỗi",
            itemCode: "Nhập mã barcode item",
            productcode: "Mã sản phẩm:",
            productname: "Tên sản phẩm:",
            timeRecord: "Thời gian ghi nhận lỗi",
            receiver: "Người ghi nhận lỗi",
            workcenter: "Vị trí ghi nhận lỗi",
            stepprocess: "Công đoạn",
            errortype: "Loại lỗi",
            description: "Mô tả lỗi",
            infoTitleForm: "Thông tin sửa lỗi",
            timerecordRepair: "Ngày giờ sửa lỗi",
            receiverNameRepair: "Người sửa lỗi",
            hangdingAction: "Hành động xử lý",
            editContent: "Nội dung sửa lỗi",
            reason: "Nguyên nhân",
            errorRepairType: "Loại lỗi",
            InfoTableMaterial: "Thông tin NVL",
            table: {
                material: "Thông tin linh kiện",
                qtyToUse: "Số lượng LK sử dụng",
                qtyRepair: "Số lượng LK trả lại kho",
            },
            btn: {
                createTable: "Thêm mới",
            },
            steoversion: "Công đoạn trả lại dây truyền sản xuất",
            placeholder: {
                itemCode: "Nhập mã barcode item",
                timeRecord: "Thời gian ghi nhận lỗi",
                receiver: "Người ghi nhận lỗi",
                workcenter: "Trung tâm SX",
                workunit: "Đơn vị SX",
                stepprocess: "Công đoạn",
                errortype: "Loại lỗi",
                description: "Mô tả lỗi",
                hangdingAction: "Hành động xử lý",
                editContent: "Nội dung sửa lỗi",
                reason: "Nguyên nhân",
                errorRepairType: "Loại lỗi",
                material: "Thông tin linh kiện",
                qtyToUse: "Số lượng LK sử dụng",
                qtyRepair: "Số lượng LK trả lại kho",
                steoversion: "Dây chuyền sản xuất",
            }
        },
        toast: {
            actionError: "Lỗi sản phẩm đã được xử lý, không thể tiến hành",
            createSuccess: "Tạo mới ghi nhận lỗi thành công",
            actionSuccess: "Thực thi sửa lỗi thành công",
        },
        rule: {
            itemcode: {
                required: "Mã barcode không được bỏ trống",
            },
            receiverid: {
                required: "Người ghi nhận lỗi không được bỏ trống",
            },
            workcenterid: {
                required: "Trung tâm sx không được bỏ trống",
            },
            workunitid: {
                required: "Đơn vị sx không được bỏ trống",
            },
            stepprocessid: {
                required: "Công đoạn không được bỏ trống",
            },
            initialerrortype: {
                required: "Loại lỗi không được bỏ trống",
            },
            decription: {
                required: "Mô tả không được bỏ trống",
                maxLength: "Mô tả không được quá 255 kí tự"
            },
            handingaction: {
                required: "Hành động xử lý không được bỏ trống",
            },
            editcontent: {
                required: "Nội dung sửa lỗi không được bỏ trống",
                maxLength: "Nội dung sửa lỗi không được quá 255 kí tự",
            },
            reason: {
                required: "Nguyên nhân không được bỏ trống",
            },
            errorrepaitype: {
                required: "Loại lỗi không được bỏ trống",
            },
            stepversionid: {
                required: "Dây chuyền sản xuất không được bỏ trống",
            },
            qtyToUse: {
                numberOnly: "Số lượng LK phải là số",
                required: "Số lượng LK không được bỏ trống",
                maxLength: "Số lượng LK không được bỏ trống",
            },
            qtyRepair: {
                numberOnly: "Số lượng LK phải là số dương",
                required: "Số lượng LK không được bỏ trống",
                maxLength: "Số lượng LK không được bỏ trống",
            },
            materialcode: {
                required: "Thông tin linh kiện không được bỏ trống",
            },
        }

    }
}