export const checkListLg = {
    language: "vn",
    checklist: {
        btn: {
            add_btn_title:"Thêm mới",
        },
        table: {            
            code: 'Mã checklist',
            name: 'Tên checklist',
            product: 'Sản phẩm',
            process: 'Quy trình',
            stage: 'Công đoạn',
            oqc: 'Cho OQC',
            iqc: 'Cho IQC',
            status: 'Trạng thái',
        },
        form: {
            title: {
                title_create:"Tạo mới checklist",
                title_update:"Cập nhật checklist",
                title_see_detail:"Chi tiết checklist",
            },
            lable: {
                code: "Mã code",
                name:"Tên",
                product:"Sản phẩm"
            },
            placeHolder: {
                checklist_name:"Nhập tên checklist",
                product:"Chọn sản phẩm",
            },
            code:"Mã checkpoint",
            name:"Tên checkpoint",
            action:"Thao tác",
            checklist_for: {
                checklist_for_title:"Checklist dùng cho",
                stage: 'Cho công đoạn',
                stage_option:{
                    stage_option_process_label:"Quy trình",
                    stage_option_step_process_label:"Công đoạn",
                },
                placeHolder:{
                    stage_option_process:"Chọn quy trình",
                    stage_option_step_process:"Chọn công đoạn",
                },
                oqc: 'Cho OQC',
                iqc: 'Cho IQC',
            },
            table: {
                placeHolder:"Chọn checkpoint",
            },
            btn: {
                add:"Thêm checkpoint",
                save:"Lưu",
                back:"Quay lại",
            },
            status: "Trạng thái",
        },
        search: {
            placeHolder: 'Tìm kiếm theo mã checklist , tên checklist , mã sản phẩm , tên sản phẩm , cho OQC , trạng thái'
        },
        toast:{
            create_sucess:"Tạo mới checklist thành công",
            update_sucess:"Cập nhật checklist thành công",
        },
        rule: {
            checklist_code:{
                required:"Mã checklist không được bỏ trống",
            },
            product_code:{
                required:"Sản phẩm không được bỏ trống",
            },
            checklist_name:{
                required:"Tên checklist không được bỏ trống",
                max_length:"Tên checklist không được quá 150 kí tự",
            },
            processid:{
                required:"Quy trình không được bỏ trống",
            },
            stepid:{
                required:"Công đoạn không được bỏ trống",
            },
            checkpoint_code:{
                required:"Checkpoint không được bỏ trống",
            },
            approvefor:{
                required:"Vui lòng chọn checkbox",
            },
        }
    }
};