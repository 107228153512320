export const tranferSloc = {
    language: "en",
    tranfersloc: {
        qtylargerthanqty: "The quantity taken out is larger than the quantity already in stock",
        packagecodepick: "Package code to pick up goods",
        goodcode: "Commodity codes",
        goodname: "Goods name",
        locationcurrent: "The current location of the package",
        amounttransfer: "Amount taken to transfer",
        note: "Note",
        slocpickup: "Sloc pick up goods",
        pickup: "Pickup",
        storage: 'Storage',
        scantopick: "Scan package code to pick up goods",
        goodinpackage: "Commodity code in the package:",
        locationpackagecurrent: "Current package location:",
        number0: 'Number must be greater than 0',
        takeall: "Take it all",
        amounttakaout: "Amount taken out",
        finish: "Finish picking up",
        deletepackagetransfer: "Delete repositories package information",
        confirmdeletepack: "Are you sure you want to delete this package information?",
        removerow: "Remove odd row from ticket",
        deletetiltle: "Delete repositories package information",
        youaresuredelete: "Are you sure you want to delete the taken goods?",
        notisstorage: ' not in storage',
        sameitemcode: "Package does not have the same item code!",
        takecomplete: "Items in the package have been taken",
        storagelocationrequired: "Storage position cannot be left empty",
        pleaseputall: "Unable to complete the stocking! Please put all the collected goods",
        qtystored: "Quantity Stored",
        locationstorage: "Storage location - Package storage",
        scanstorage: "Scan storage location",
        storagelocation: "Storage Location",
        packputinplace: "*Package put in place or package to store retail items",
        quantitystoredinpack: "Quantity stored in the package",
        finishstocking: "Finished stocking",
    },
};
