import _ from "lodash";

export const ALIAS_PATHS = [
  {
    originPath: "/trinh-duyet/:WorkFlowId",
    getAliasPath: function ({ query, defaultPath }) {
      const flowtype = query.flowtype;
      if (_.isNil(flowtype)) {
        return defaultPath;
      }

      switch (flowtype) {
        case "1":
          return "/trinh-duyet/yeu-cau-tao-moi-ma-tai-san";
        case "2":
          return "/trinh-duyet/yeu-cau-dieu-chuyen";
        case "3":
          return "/trinh-duyet/yeu-cau-dieu-chuyen";
        case "4":
          return "/trinh-duyet/yeu-cau-kiem-ke-tai-san";
        case "5":
          return "/trinh-duyet/bao-cao-kiem-ke";
        case "6":
          return "/trinh-duyet/tang-tai-san";
        default:
          return defaultPath;
      }
    },
  },
];

export const HIDDEN_MENUS = [
  // danh-sach-phe-duyet
  {
    path: "/danh-sach-phe-duyet/xu-ly-phe-duyet/:id",
    parentPath: "/danh-sach-phe-duyet",
  },

  // danh sach ma tai san
  {
    path: "/ma-tai-san/xem-chi-tiet/:id",
    parentPath: "/danh-sach-ma-tai-san",
  },
  {
    path: "/ma-tai-san/chinh-sua/:id",
    parentPath: "/danh-sach-ma-tai-san",
  },
  {
    path: "/ma-tai-san/tao-moi",
    parentPath: "/danh-sach-ma-tai-san",
  },
  {
    path: "/trinh-duyet/yeu-cau-tao-moi-ma-tai-san",
    parentPath: "/danh-sach-ma-tai-san",
  },

  // danh-sach-tai-san
  {
    path: "/danh-sach-tai-san/chinh-sua/:id",
    parentPath: "/danh-sach-tai-san",
  },
  {
    path: "/danh-sach-tai-san/chi-tiet/:id",
    parentPath: "/danh-sach-tai-san",
  },

  // dieu-chuyen-tai-san
  {
    path: "/yeu-cau-dieu-chuyen/noi-bo/tao-moi",
    parentPath: "/dieu-chuyen-tai-san",
  },
  {
    path: "/yeu-cau-dieu-chuyen/lien-vien/tao-moi",
    parentPath: "/dieu-chuyen-tai-san",
  },
  {
    path: "/yeu-cau-dieu-chuyen/noi-bo/:id",
    parentPath: "/dieu-chuyen-tai-san",
  },
  {
    path: "/yeu-cau-dieu-chuyen/lien-vien/:id",
    parentPath: "/dieu-chuyen-tai-san",
  },
  {
    path: "/yeu-cau-dieu-chuyen/noi-bo/chi-tiet/:id",
    parentPath: "/dieu-chuyen-tai-san",
  },
  {
    path: "/yeu-cau-dieu-chuyen/lien-vien/chi-tiet/:id",
    parentPath: "/dieu-chuyen-tai-san",
  },
  {
    path: "/trinh-duyet/yeu-cau-dieu-chuyen",
    parentPath: "/dieu-chuyen-tai-san",
  },

  // ke-hoach-kiem-ke
  {
    path: "/tao-moi-phieu-kiem-ke",
    parentPath: "/ke-hoach-kiem-ke",
  },
  {
    path: "/xem-phieu-kiem-ke/:id",
    parentPath: "/ke-hoach-kiem-ke",
  },
  {
    path: "/sua-phieu-kiem-ke/:id",
    parentPath: "/ke-hoach-kiem-ke",
  },
  {
    path: "/trinh-duyet/yeu-cau-kiem-ke-tai-san",
    parentPath: "/ke-hoach-kiem-ke",
  },
  {
    path: "/ke-hoach-kiem-ke/xem-chi-tiet/:id",
    parentPath: "/ke-hoach-kiem-ke",
  },

  // ket-qua-kiem-ke
  {
    path: "/chi-tiet-ket-qua-kiem-ke/:id",
    parentPath: "/ket-qua-kiem-ke",
  },

  // bao-cao-kiem-ke
  {
    path: "/chi-tiet-bao-cao-kiem-ke/:id",
    parentPath: "/bao-cao-kiem-ke",
  },
  {
    path: "/trinh-duyet/bao-cao-kiem-ke",
    parentPath: "/bao-cao-kiem-ke",
  },

  // cau-hinh-quy-trinh-phe-duyet
  {
    path: "/cau-hinh-quy-trinh-phe-duyet/chinh-sua/:id",
    parentPath: "/cau-hinh-quy-trinh-phe-duyet",
  },
  {
    path: "/cau-hinh-quy-trinh-phe-duyet/tao-moi",
    parentPath: "/cau-hinh-quy-trinh-phe-duyet",
  },

  // yeu-cau-tang-tai-san
  {
    path: "/trinh-duyet/tang-tai-san",
    parentPath: "/yeu-cau-tang-tai-san",
  },
];
