import i18next from 'i18next';
import { call, put, take, takeEvery } from 'redux-saga/effects';
import { closeLoading } from 'src/shared/common/LoadingAction';
import toastJs from 'src/shared/common/toastJs';
import LOTQAAction from './LotQAAction';
import { getPagingService, createDataService, getDetailService, getDropdownWOService, updateDataService, getCodeFromAPI, getListItemCodeService } from './LotQAService';
import { CREATE_LOTQA_SAGA, GET_DETAIL_LOTQA_SAGA, GET_DROPDOWN_WO_SAGA, GET_PAGING_LOTQA_SAGA, UPDATE_LOTQA_SAGA, GET_CODE_FROM_API_SAGA, GET_LIST_ITEM_CODE_SAGA } from './enum/LotQATypeAction';
import moment from 'moment';
export function* getPagingSaga(data) {
    try {
        const pageIndex = data.pageIndex;
        const pageSize = data.pageSize;
        const searchTerm = data.searchTerm;
        const request = {
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
        };
        if (data.fromTime != null && data.fromTime != "") {
            request.fromTime = moment(data.fromTime).format("YYYY-MM-DD");
        }
        if (data.toTime != null && data.toTime != "") {
            request.toTime = moment(data.toTime).format("YYYY-MM-DD");
        }
        if (data.status != 0) {
            request.status = data.status;
        }
        const response = yield call(getPagingService, request);
        if (response.isSuccess == true) {
            if (response.data.Items.length <= 0 && request.pageIndex > 1) {
                yield put(LOTQAAction.getPagingSaga(request.pageIndex - 1, request.pageSize, request.searchTerm));
            } else {
                yield put(LOTQAAction.getPagingReducer(response.data))
            }
        }
        closeLoading()

    } catch (error) {
        closeLoading();

    }
};
export function* getDetailDataSaga(data) {
    try {
        const lotcode = data.lotcode;
        const forConductEvaluation = data.forConductEvaluation
        const request = {
            lotcode: lotcode,
            forConductEvaluation: forConductEvaluation
        };
        const objData = yield call(getDetailService, request);
        if (objData) {
            yield put(LOTQAAction.returnDetailReducer(objData))
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* createLOTQASaga(data) {
    try {
        let entity = data.data;
        const request = {
            lotcode: entity.lotcode,
            workordercode: entity.workordercode,
            qtyItem: entity.qtyItem,
            qtySample: entity.qtySample,
            isRandom: entity.isRandom,
            listItemCodes: entity.listItemCodes
        };
        const res = yield call(createDataService, request);
        if (res.isSuccess) {
            toastJs.success(i18next.t("lotQA.createcompleted"));
            yield put(LOTQAAction.callChangeData());
        }
        closeLoading();
    } catch (error) {
        closeLoading()

    }
}
export function* updateLOTQASaga(obj) {
    try {
        let entity = obj.data;
        const request = {
            lotcode: entity.lotcode,
            workordercode: entity.workordercode,
            qtyItem: entity.qtyItem,
            qtySample: entity.qtySample,
            isRandom: entity.isRandom,
            listItemCodes: entity.listItemCodes
        };
        const res = yield call(updateDataService, request);
        if (res.isSuccess) {
            toastJs.success(i18next.t("lotQA.updatecompleted"));
            yield put(LOTQAAction.callChangeData());
        }
        closeLoading();
    } catch (error) {
        closeLoading()

    }
}
export function* getDropdownWOSaga(data) {
    try {
        const searchtemp = data.searchtemp;
        const response = yield call(getDropdownWOService, searchtemp);
        if (response?.data) {
            yield put(LOTQAAction.setDropdownWO(response.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getCodeSaga(data) {
    try {
        const searchtemp = data.searchtemp;
        const response = yield call(getCodeFromAPI, searchtemp);
        if (response.isSuccess) {
            yield put(LOTQAAction.getCodeReducer(response.data));
        }
        closeLoading();
    }
    catch (error) {
        closeLoading();

    }
}
export function* getListItemCode(data) {
    try {
        let code = data.code;
        let workordercode = data.workordercode;
        let request = {
            code: code,
            workordercode: workordercode
        }
        const response = yield call(getListItemCodeService, request);
        if (response?.isSuccess) {
            yield put(LOTQAAction.getListItemCodeReducer(response.data));
        }
        closeLoading();
    }
    catch (error) {
        closeLoading();

    }
}
export default function* LotQASaga() {
    yield takeEvery(GET_PAGING_LOTQA_SAGA, getPagingSaga);
    yield takeEvery(CREATE_LOTQA_SAGA, createLOTQASaga);
    yield takeEvery(GET_DETAIL_LOTQA_SAGA, getDetailDataSaga);
    yield takeEvery(GET_DROPDOWN_WO_SAGA, getDropdownWOSaga);
    yield takeEvery(UPDATE_LOTQA_SAGA, updateLOTQASaga);
    yield takeEvery(GET_CODE_FROM_API_SAGA, getCodeSaga);
    yield takeEvery(GET_LIST_ITEM_CODE_SAGA, getListItemCode);
}
