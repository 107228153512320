import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
    GET_LIST_CONSUMABLE_PACKAGE_API,
    CREATE_CONSUMABLE_PACKAGE_API,
    UPDATE_CONSUMABLE_PACKAGE_API,
    GET_DETAIL_CONSUMABLE_PACKAGE_API,
    REQUEST_PRINT_CONSUMABLE_PACKAGE_API
} from "./enum/TypeConsumablePackageAPI";
import { toast } from "react-toastify";

export async function getListConsumablePackagesService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_LIST_CONSUMABLE_PACKAGE_API,
        data
    )
}
export async function createConsumablePackageService(data) {
    console.log("Type Service: ",data.Type);
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        CREATE_CONSUMABLE_PACKAGE_API,
        data
    )
}
export async function updateConsumablePackageService(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        UPDATE_CONSUMABLE_PACKAGE_API,
        data
    )
}
export async function getDetailConsumablePackageService(Id) {
    return await CommonBase.postAsync(
        configApp.apiGateWay +
        GET_DETAIL_CONSUMABLE_PACKAGE_API +
        "?Id=" + Id
    )
}
export async function getPrintConsumable(data) {
    return await CommonBase.postAsync(
        configApp.apiGateWay + REQUEST_PRINT_CONSUMABLE_PACKAGE_API, data
    )
}