import { call, put, takeEvery } from "redux-saga/effects";
import toastJs from 'src/shared/common/toastJs';
import { closeLoading } from "src/shared/common/LoadingAction";
import MachineryRepairAcion from "./MachineryRepairAction";
import i18next from 'i18next';
import {getDataPaingMachineryRepairService,getDetailMachineryRepairService,
    getDetailMachineryRepairAcceptanceService,saveMachineryRepairService,updateMachineryRepairService,
saveAcceptanceAndRepairMachineryService,deleteMachineryRepairService} from "./MachineryRepairService"
import * as type from "./enum/MachineryRepairTypeAction";
export function* getDataPaingMachineryRepairSaga(request) {
    try {
        const response = yield call(getDataPaingMachineryRepairService, request.data)
        if (response.isSuccess) {
            yield put(MachineryRepairAcion.setDataPaingMachineryRepairReducer(response.data))
        }
        closeLoading()
     } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* getDetailMachineryRepairSaga(request) {
    try {
        const response = yield call(getDetailMachineryRepairService, request.data)
        if (response.isSuccess) {
            yield put(MachineryRepairAcion.setDetailMachineryRepairReducer(response.data))
        }
        closeLoading()
     } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* getDetailMachineryRepairAcceptanceSaga(request) {
    try {
        const response = yield call(getDetailMachineryRepairAcceptanceService, request.data)
        if (response.isSuccess) {
            yield put(MachineryRepairAcion.getDetailMachineryRepairAcceptanceReducer(response.data))
        }
        closeLoading()
     } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* saveMachineryRepairSaga(request) {
    try {
        const response = yield call(saveMachineryRepairService, request.data)
        if (response.isSuccess) {
            yield put(MachineryRepairAcion.callResultFromReducerMachineryRepair())
            toastJs.success(i18next.t("machineryRepair.toast.createSuccess"));
            if (response.status == 1) {
                toastJs.warning(i18next.t("machineryRepair.toast.warnningCreate"));
            }
        }
        closeLoading()
     } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* updateMachineryRepairSaga(request) {
    try {
        const response = yield call(updateMachineryRepairService, request.data)
        if (response.isSuccess) {
            yield put(MachineryRepairAcion.callResultFromReducerMachineryRepair())
            toastJs.success(i18next.t("machineryRepair.toast.updateSuccess"));
        }
        closeLoading()
     } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* saveAcceptanceAndRepairMachinerySaga(request) {
    try {
        const response = yield call(saveAcceptanceAndRepairMachineryService, request.data)
        if (response.isSuccess) {
            toastJs.success(i18next.t("machineryRepair.toast.acceptanceSuccess"));
            yield put(MachineryRepairAcion.callResultFromReducerMachineryRepair())
        }
        closeLoading()
     } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export function* deleteMachineryRepairSaga(request) {
    try {
        const response = yield call(deleteMachineryRepairService, request.data)
        if (response.isSuccess) {
            toastJs.success(i18next.t("machineryRepair.toast.deleteSuccess"));
            yield put(MachineryRepairAcion.getDataPagingMachineryRepairSaga(request.dataPage))
        }
        closeLoading()
     } catch (error) {
        closeLoading();
        console.log(error)
    }
}
export default function* MachineryRepairSaga() {    
    yield takeEvery(type.GET_DATA_PAING_MACHINERY_REPAIR_SAGA, getDataPaingMachineryRepairSaga);
    yield takeEvery(type.GET_DETAIL_MACHINERY_REPAIR_SAGA, getDetailMachineryRepairSaga);
    yield takeEvery(type.GET_DETAIL_MACHINERY_REPAIR_ACCEPTANCE_SAGA,getDetailMachineryRepairAcceptanceSaga);
    yield takeEvery(type.SAVE_MACHINERY_REPAIR_SAGA,saveMachineryRepairSaga);
    yield takeEvery(type.UPDATE_MACHINERY_REPAIR_SAGA,updateMachineryRepairSaga);
    yield takeEvery(type.SAVE_ACCEPTANCE_AND_REPAIR_MACHINERY_SAGA,saveAcceptanceAndRepairMachinerySaga);
    yield takeEvery(type.DELETE_MACHINERY_REPAIR_SAGA,deleteMachineryRepairSaga);
}