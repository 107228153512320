import {
  GET_PAGING_PRODUCT_SAGA,
  GET_PAGING_MATERIAL_BY_ROLE_USER_SAGA,
  GET_PAGING_PRODUCT_REDUCER,
  GET_PAGING_MATERIAL_BY_ROLE_USER_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_PRODUCT_SAGA,
  DELETE_PRODUCT_REDUCER,
  UPDATE_PRODUCT_SAGA,
  CALL_CREATE_UPDATE_SUCCESS_REDUCER,
  CREATE_PRODUCT_SAGA,
  GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_SAGA,
  GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_REDUCER,GET_LIST_PRODUCT_SAGA,GET_LIST_PRODUCT_REDUCER,
  GET_LIST_PRODUCT_NOT_IN_BARCODE_REDUCER,GET_LIST_PRODUCT_NOT_IN_BARCODE_SAGA
} from "./enum/TypeCatProductAction";

const CatProductAction = {
  getListDataProductByUnitCodeSaga: (unitCode) => {
    return {
      type: GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_SAGA,
      data: unitCode,
    };
  },

  getListDataProductByUnitCodeReducer: (data) => {
    return {
      type: GET_LIST_DATA_PRODUCT_BY_UNIT_CODE_REDUCER,
      data: data,
    };
  },
  getPagingSaga: (pageIndex, pageSize, searchTerm = "", productGroupId = [], isActive = [], productType = [], planningId) => {
    return {
      type: GET_PAGING_PRODUCT_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
      productGroupId: productGroupId,
      isActive: isActive,
      productType: productType,
      planningId:planningId
    };
  },
  getPagingSaga1: (pageIndex, pageSize, searchTerm = "", listIdNotSelect = []) => {
    return {
      type: GET_PAGING_PRODUCT_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
      listIdNotSelect:listIdNotSelect
    };
  },
  getPagingByRoleUserSaga: (pageIndex, pageSize, searchTerm = "" ) => {
    return {
      type: GET_PAGING_MATERIAL_BY_ROLE_USER_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm
    };
  },
  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_PRODUCT_REDUCER,
      data: data,
    };
  },
  getPagingByRoleUserReducer: (data) => {
    return {
      type: GET_PAGING_MATERIAL_BY_ROLE_USER_REDUCER,
      data: data,
    };
  },
  deleteDataSaga: (id) => {
    return {
      type: DELETE_PRODUCT_SAGA,
      id: id,
    };
  },

  deleteDataReducer: () => {
    return {
      type: DELETE_PRODUCT_REDUCER,
    };
  },
  createDataSaga: (data) => {
    return {
      type: CREATE_PRODUCT_SAGA,
      data: data,
    };
  },
  updateDataSaga: (data) => {
    return {
      type: UPDATE_PRODUCT_SAGA,
      data: data,
    };
  },
  callCreateUpdateSuccessReducer: () => {
    return {
      type: CALL_CREATE_UPDATE_SUCCESS_REDUCER,
    };
  },

  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },

  getListSaga: (id,listIdNotSelect,searchTerm = "") => {
    return {
      type: GET_LIST_PRODUCT_SAGA,
      searchTerm: searchTerm,
      listIdNotSelect:listIdNotSelect,
      id:id
    };
  },
  getListProductNotInBarcodeSaga: (id,listIdNotSelect,searchTerm = "",pageIndex = 1,pageSize=10) => {
    return {
      type: GET_LIST_PRODUCT_NOT_IN_BARCODE_SAGA,
      searchTerm: searchTerm,
      listIdNotSelect:listIdNotSelect,
      id:id,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
  },
  getListReducer: (data) => {
    return {
      type: GET_LIST_PRODUCT_REDUCER,
      data: data,
    };
  },
};

export default CatProductAction;
