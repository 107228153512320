export const GET_LIST_DELIVERY_API = "/api/service/Delivery/Search";
export const DELETE_DELIVERY_API = "/api/service/Delivery/Delete";
export const CREATE_DELIVERY_API = "/api/service/Delivery/Create";
export const UPDATE_DELIVERY_API = "/api/service/Delivery/Update";
export const GET_DETAIL_DELIVERY_API = "/api/service/Delivery/GetById";
export const ISEXIST_DELIVERY_API =
    "/api/service/Delivery/IsExist";

export const GET_INFO_DELIVERY_BY_ID = '/api/service/Delivery/GetInfoDeliveryById'
export const DOWNLOAD_EXCEL_API = '/api/service/Delivery/ExportReport'
export const DELIVERY_PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_API = '/api/service/Planning/PredictabilityRetailByListProduct'