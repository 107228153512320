import {
    GET_PAGING_INVENTORY_USER_CONFIG_SAGA,
    GET_PAGING_INVENTORY_USER_CONFIG_REDUCER,
    CREATE_UPDATE_INVENTORY_USER_CONFIG_SAGA,
    CALL_CREATE_UPDATE_SUCCESS_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,


} from "./enum/TypeInventoryUserConfigAction";

const InventoryUserConfigAction = {
    getPagingSaga: (request) => {
        return {
            type: GET_PAGING_INVENTORY_USER_CONFIG_SAGA,
            request: request
        };
    },

    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_INVENTORY_USER_CONFIG_REDUCER,
            data: data,
        };
    },

    CreateUpdateDataSaga: (data) => {
        return {
            type: CREATE_UPDATE_INVENTORY_USER_CONFIG_SAGA,
            data: data
        };
    },

    CallCreateUpdateSuccessReducer: () => {
        return {
            type: CALL_CREATE_UPDATE_SUCCESS_REDUCER,
        };
    },

    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER,
        };
    },
};

export default InventoryUserConfigAction;
