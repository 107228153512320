import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_PAGING_RESCHEDULE_PRODUCT_SAGA, CREATE_RESCHEDULE_PRODUCT_SAGA, GET_ALL_DROP_DOWN_SAGA, GET_DROPDOWN_PROCESS_REWORK
} from "./enum/typeRescheduleProductionAction";
import { closeLoading } from "../../../shared/common/LoadingAction";
import toastJs from "../../../shared/common/toastJs";
import RescheduleProductionAction from "./RescheduleProductionAction";
import { getPagingService, createDataService, getDropdownService, getDropdownProcessService } from "./RescheduleProductionService";
import i18next from "i18next";
export function* getPagingRescheduleProductionSaga(data) {
    try {
        let request = data.request;
        request.WorkorderCode = data.request.WorkorderCode ?? "";
        request.ProductCode = data.request.ProductCode ?? "";
        request.Itemcode = data.request.Itemcode ?? "";
        request.Workcentername = data.request.Workcentername ?? "";
        const response = yield call(getPagingService, request);
        if (response.isSuccess) {
            if (response.data.Items.length <= 0 && data.request.PageIndex > 1) {
                data.request.PageIndex--;
                yield put(RescheduleProductionAction.getPagingSaga(data.request));
            } else {
                yield put(RescheduleProductionAction.getPagingReducer(response.data));
            }
        } else { }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}

export function* createRescheduleProductionSaga(data) {
    try {
        const res = yield call(createDataService, data.dataModal);
        if (res) {
            toastJs.success(i18next.t("requirement.toastjs.createSuccess"));
            yield put(RescheduleProductionAction.getPagingSaga(data.requestPaging));
            yield put(RescheduleProductionAction.callResultFromApiReducer());
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDropdownSaga() {
    try {
        const res = yield call(getDropdownService);
        if (res) {
            yield put(RescheduleProductionAction.setDropdownReducer(res.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export function* getDropdownProcessRework(data) {
    try {
        const res = yield call(getDropdownProcessService, data.productcode);
        if (res) {
            yield put(RescheduleProductionAction.setDropdownProcessReworkReducer(res.data));
        }
        closeLoading();
    } catch (error) {
        closeLoading();

    }
}
export default function* RescheduleProductionSaga() {
    yield takeEvery(GET_PAGING_RESCHEDULE_PRODUCT_SAGA, getPagingRescheduleProductionSaga);
    yield takeEvery(CREATE_RESCHEDULE_PRODUCT_SAGA, createRescheduleProductionSaga);
    yield takeEvery(GET_ALL_DROP_DOWN_SAGA, getDropdownSaga);
    yield takeEvery(GET_DROPDOWN_PROCESS_REWORK, getDropdownProcessRework);
}
