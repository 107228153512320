export const ProductIdentifier = {
    language: "vi",
    productidentifier: {
        batchidentified: "Sản phẩm không thể định danh theo lô",
        shipmentrequired: "Số lượng thành phẩm trong một lô phải lớn hơn 1",
        dinhdanhsanpham: "In tem định danh",
        manageridentifier: "Quản lý mã định danh",
        search1: "Nhập mã, tên lệnh sản xuất",
        prtintbyproduct: "In tem theo sản phẩm",
        prtintbyshipmnet: "In tem theo lô",
        productinlot: "Số lượng thành phẩm/lô: ",
        workorderdetail: "Chi tiết mã định danh theo lệnh sản xuất",
        workordercode: "Mã WO",
        workordername: "Tên WO",
        numberhasidentifier: "Số sản phẩm đã định danh",
        identifiercode: "Mã định danh",
        numberproductincode: "Số sản phẩm/ mã",
        reprintnumber: "Số lần in lại",
        notelast: "Lý do in lại gần nhất",
        change: "Thay đổi",
        totalfg: "Tổng sản phẩm",
        singleprint: "In lẻ",
        multiprint: "In nhiều",
        changeshipmentsuccess: "Thay đổi thông tin lô hàng của sản phẩm thành công",
        identifiersuccess: "Thực thi định danh sản phẩm thành công",
        reprintsuccess: "Thực thi in lại tem thành công",
        explancemaxlength: "Giải trình lý do in lại không được vượt quá 255 kí tự",
        form: {
            explancerequired: "Giải trình lý do in lại là bắt buộc",
            confirmreprint: "Xác nhận in lại",
            back: "Quay lại",
            confirm: "Xác nhận",
            explanceting: "Giải trình lý do in lại",
            temrequired: "Số lượng tem in không được để trống",
            identifier: "Mã định danh: ",
            temprint: "Nhập số lượng tem cần in",
            confirmprint: "Xác nhận in",
            numberstamps: "Số lượng tem cần in",
            frintall: "In tất cả",
            temthan1: "Số lượng tem cần in phải lớn hơn 1",
            temnumberrequired: "Số lượng tem cần in phải là kiểu số nguyên dương",
        }
    }
};