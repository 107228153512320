export const GET_PAGING_BOM_SAGA = "GET_PAGING_BOM_SAGA";
export const GET_PAGING_BOM_REDUCER = "GET_PAGING_BOM_REDUCER";
export const CREATE_BOM_SAGA = "CREATE_BOM_SAGA";
export const CALL_RESULT_BOM_REDUCER = "CALL_RESULT_BOM_REDUCER";
export const UPDATE_BOM_SAGA = "UPDATE_BOM_SAGA";
export const DELETE_BOM_SAGA = "DELETE_BOM_SAGA";
export const DELETE_BOM_REDUCER = "DELETE_BOM_REDUCER";
export const GET_DETAIL_BOM_SAGA = "GET_DETAIL_BOM_SAGA";
export const GET_DETAIL_BOM_REDUCER = "GET_DETAIL_BOM_REDUCER";
export const CALL_DEFAULT_BOM_REDUCER = "CALL_DEFAULT_BOM_REDUCER";
export const SET_DROPDOWN_PRODUCT_REDUCER = "SET_DROPDOWN_PRODUCT_REDUCER";
export const GET_DROPDOWN_PRODUCT_SAGA = "GET_DROPDOWN_PRODUCT_SAGA";
export const SET_DROPDOWN_VERSION_BOM_REDUCER = "SET_DROPDOWN_VERSION_BOM_REDUCER";
export const GET_DROPDOWN_VERSION_BOM_SAGA = "GET_DROPDOWN_VERSION_BOM_SAGA";
export const DELETED_VERSION_BOM_SAGA = "DELETED_VERSION_BOM_SAGA";
export const DELETE_VERSION_BOM_REDUCER = "DELETE_VERSION_BOM_REDUCER";
export const CHANGE_DATA_BOM_REDUCER = "CHANGE_DATA_BOM_REDUCER";
export const ADD_NEW_VESION_BOM_SAGA = "ADD_NEW_VESION_BOM_SAGA";
export const CHANGE_VERSION_BOM_SAGA = "CHANGE_VERSION_BOM_SAGA";
export const CALL_CHANGE_VERSION_BOM_REDUCER = "CALL_CHANGE_VERSION_BOM_REDUCER";
export const SET_DESCRIPTION_MATERIAL_REDUCER = "SET_DESCRIPTION_MATERIAL_REDUCER";
export const GET_DESCRIPTION_MATERIAL_SAGA = "GET_DESCRIPTION_MATERIAL_SAGA";
export const ADD_MORE_VERSION_BOM_REDUCER = "ADD_MORE_VERSION_BOM_REDUCER";





















