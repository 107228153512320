export const ItemAllocation = {
    language: "vn",
    itemallocation: {
        messtatus: "Trạng thái MES",
        granted: "Đã cấp",
        printerstatus: "Trạng thái printer",
        waitingin: "Chờ in",
        esoutput: "Sản lượng dự tính: ",
        selectallo: 'Chọn lệnh cấp phát',
        tatolqty: "Tổng BarCode đã cấp phát: ",
        printed: "Số lượng BarCode đã in: ",
        nodata: "Hiện tại không có dữ liệu!",
        startallow: "Bắt đầu cấp phát",
        stopallow: "Dừng cấp phát",
    }
};
