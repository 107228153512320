import * as types from "./enum/AccessoriesTypeAction";

const AccessoriesAction = {
    //lấy danh sách
    getAll: (pageIndex, pageSize, searchTerm = '') => {
        return {
            type: types.GET_ALL_ACCESSORIES,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
        }
    },
    // trả về danh sách
    returnData: (data) => {
        return {
            type: types.RETURN_DATA_ACCESSORIES,
            data: data,
        }
    },
    // lấy chi tiết 
    getDetail: (productCode) => {

        return {
            type: types.GET_DETAIL_ACCESSORIES,
            productCode: productCode
        }
    },

    // trả về chi tiết
    returnDetail: (obj) => {
        return {
            type: types.RETURN_OBJECT_ACCESSORIES,
            obj: obj,
        }
    },
    // tạo mới dữ liệu 
    createData: (request, pageIndex, pageSize) => {
        return {
            type: types.CREATE_ACCESSORIES,
            request: request,
            pageIndex: pageIndex,
            pageSize: pageSize,
        }
    },
    // cập nhật dữ liệu 
    updateData: (request, pageIndex, pageSize) => {
        return {
            type: types.UPDATE_ACCESSORIES,
            request: request,
            pageIndex: pageIndex,
            pageSize: pageSize,
        }
    },
    // xoa du lieu 
    deleteData: (productCode, pageIndex, pageSize) => {
        return {
            type: types.DELETE_ACCESSORIES,
            productCode: productCode,
            pageIndex: pageIndex, pageSize: pageSize,
        }
    },
    callResultFromApi: () => {
        return {
            type: types.CALL_RESULT_FROM_ACCESSORIES_API,
        }
    },

    callDefault: () => {
        return {
            type: types.CALL_DEFAULT_FROM_ACCESSORIES,
        }
    },
    closePopupDelete: (status = false) => {
        return {
            type: types.CLOSE_DELETE_POPUP,
            status
        }
    },
    closeLoading: (status = false) => {
        return {
            type: types.CLOSE_LOADING_TABLE,
            status
        }
    },
}

export default AccessoriesAction;