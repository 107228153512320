import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import * as type from "./enum/RepairTypeApi";

export async function getDataPaging(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DATA_REPAIR_API , data)
}
export async function getChangeStatusRepairService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.CHANGE_STATUS_REPAIR_API+"/"+ data)
}
export async function getDataRepairDetailService(value, action) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DATA_REPAIR_DETAIL_API+"/"+ value+ "/"+action)
}
export async function getDataByItemCodeService(id) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DATA_BY_ITEM_CODE_API + "/" + id)
}
export async function getDropdownStepRepairService(data) {
    return await CommonBase.getAsync(configApp.apiGateWay + type.GET_DROPDOWN_STEP_REPAIR_API+ data);
}
export async function createErrorRecodingService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.CREATE_ERROR_RECODING_API, data);
}
export async function createRepairActionService(data) {
    return await CommonBase.postAsync(configApp.apiGateWay + type.CREATE_REPAIR_ACTION_API, data);
}