import {
  UPDATE_ITEM_CODE_REDUCER,
  UPDATE_ITEM_CODE_DONE,
  SET_UP_ERROR,
  SET_UP_ERROR_DONE,
  SET_UP_DATA_RESULT,
  SET_UP_DATA_RESULT_DONE,

  // api data
  CALL_API_TO_GET_INFORMATION_DATA_REDUCER,
  CALL_API_TO_GET_INFORMATION_DATA_DONE,

  SCAN_DATA_AFTER_SCAN_ITEM_CODE_REDUCER,
  SCAN_ITEM_CODE_SAGA_DONE,
  SCAN_ITEM_CODE_UPDATE_TABLE,
  SET_REDUCER_TO_DEFAULT,
  CALL_API_RECORD_PRODUCT_EXCUTE_DATA_REDUCER,
  CALL_API_RECORD_PRODUCT_EXCUTE_DATA_DONE,
  GET_WORK_ORDER_BY_CODE_REDUCER,
  GET_WORK_ORDER_BY_CODE_DONE,
  GET_MACHINE_REDUCER,
  GET_MACHINE_DONE,
  SET_UPDATE_INFORMATION
} from "./enum/actionEnum";

const stateDefault = {
  code: "", // mã định danh
  isGetCode: false,

  errorRow: [], // mã định danh
  isErrorRow: false,
  // data row result
  rowResult: [], // mã định danh
  isRowResultUpdate: false,

  // du lieu thong tin chung
  inforData: {},
  isGetInfoData: false,
  infoDetail: {},
  isTableDataCallApi: false,
  // du lieu sau khi scan thanh cong
  resultRecordScan: [],
  resultRecordScanStatus: null,
  isRecordResultCall: false,
  isRecordProductExcute: false,

  //-------------------
  isgetwo: false,
  resWoData: null,
  //-------------------
  ismachineCall: false,
  machineList: [],

  informationData: {},
}

const recordResultV2Reducer = (
  state = stateDefault,
  action
) => {
  switch (action.type) {
    case UPDATE_ITEM_CODE_REDUCER:
      state.code = action.code;
      state.isGetCode = true;
      return {
        ...state,
      };
    case UPDATE_ITEM_CODE_DONE:
      state.isGetCode = false;
      return {
        ...state,
      };
    //-------------------------------------------------------------------

    case SET_UP_ERROR:
      state.errorRow = action.error;
      state.isErrorRow = true;
      return {
        ...state,
      };
    case SET_UP_ERROR_DONE:
      state.isErrorRow = false;
      return {
        ...state,
      };
    //-------------------------------------------------------------------

    case SET_UP_DATA_RESULT:
      state.resultRecordScan = action.data;
      state.isRowResultUpdate = true;
      return {
        ...state,
      };
    case SET_UP_DATA_RESULT_DONE:
      state.isRowResultUpdate = false;
      return {
        ...state,
      };

    //-------------------------------------------
    // lưu thông tin dữ liệu chung
    case CALL_API_TO_GET_INFORMATION_DATA_REDUCER:

      state.isGetInfoData = true;
      action.data.info = {
        productCode: action.data.productCode,
        productName: action.data.productName,
        stepId: action.data.stepId,
        stepName: action.data.stepName,
        userId: action.data.userId,
        userName: action.data.userName,
        workCenterId: action.data.workCenterId,
        workCenterName: action.data.workCenterName,
        workOrderCode: action.data.workOrderCode,
        workOrderName: action.data.workOrderName,
        workUnitId: action.data.workUnitId,
        workUnitName: action.data.workUnitName,
      }
      state.inforData = action.data;
      state.infoDetail = action.data.info;
      return {
        ...state,
      };
    // gọi dữ liệu thông tin chung và hiển thị thành công
    case CALL_API_TO_GET_INFORMATION_DATA_DONE:
      state.isGetInfoData = false;
      return {
        ...state,
      };
    //----------------------------------------------
    // dữ liệu sau khi scan mã định danh
    case SCAN_DATA_AFTER_SCAN_ITEM_CODE_REDUCER:
      state.isRecordResultCall = true;
      state.resultRecordScan = action.data.listChecklistSamples;
      state.resultRecordScanStatus = action.data.status;
      return {
        ...state,
      };

    case SCAN_ITEM_CODE_UPDATE_TABLE:
      state.isTableDataCallApi = action.status;
      return {
        ...state,
      };

    // gọi dữ sau khi scan và hiển thị thành công
    case SCAN_ITEM_CODE_SAGA_DONE:
      state.isRecordResultCall = false;
      return {
        ...state,
      };

    //------------------------------------------------
    case CALL_API_RECORD_PRODUCT_EXCUTE_DATA_REDUCER:
      state.isRecordProductExcute = true;
      state.isRecordResultCall = false;
      return {
        ...state,
      };
    case CALL_API_RECORD_PRODUCT_EXCUTE_DATA_DONE:
      state.isRecordProductExcute = false;
      return {
        ...state,
      };
    //------------------------------------------------
    case GET_WORK_ORDER_BY_CODE_REDUCER:
      state.isgetwo = true;
      state.resWoData = action.data;
      return {
        ...state,
      };
    case GET_WORK_ORDER_BY_CODE_DONE:
      state.isgetwo = false;
      return {
        ...state,
      };
    //------------------------------------------------
    case GET_MACHINE_REDUCER:
      state.ismachineCall = true;
      state.machineList = action.data;
      return {
        ...state,
      };
    case GET_MACHINE_DONE:
      state.ismachineCall = true;
      return {
        ...state,
      };

    //------------------------------------------------
    case SET_UPDATE_INFORMATION:
      state.informationData = action.data;
      return {
        ...state,
      };

    case SET_REDUCER_TO_DEFAULT:
      state = stateDefault;
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default recordResultV2Reducer;
