import { type } from "@testing-library/user-event/dist/type";
import {
  GET_PAGING_PLANNING_SAGA,

  GET_PAGING_PLANNING_REDUCER,
  CALL_DEFAULT_REDUCER,
  CLEAR_DATA_REDUCER,
  DELETE_PLANNING_SAGA,
  DELETE_PLANNING_REDUCER,
  CREATE_PLANNING_REDUCER,
  CREATE_PLANNING_SAGA,
  UPDATE_PLANNING_REDUCER,
  UPDATE_PLANNING_SAGA,
  GET_INFO_PLANNING_BY_ID_REDUCER,
  GET_INFO_PLANNING_BY_ID_SAGA,
  GET_PAGING_RETAIL_PLANNING_SAGA,
  GET_PAGING_RETAIL_PLANNING_REDUCER,
  PREDICTABILITY_RETAIL_AUTO_SAGA,
  PREDICTABILITY_RETAIL_AUTO_REDUCER,
  PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA,
  PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER,
  PREDICTABILITY_BUDGET_AUTO_SAGA,
  PREDICTABILITY_BUDGET_AUTO_REDUCER,
  PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_SAGA,
  PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_REDUCER,
  RESET_INFO_PLANNING_BY_ID_REDUCER,
  PLANNING_DOWNLOAD_EXCEL_SAGA,
  GET_PAGING_DETAIL_BY_ID_SAGA,
  GET_PAGING_DETAIL_BY_ID_REDUCER,
  UPDATE_A_DETAIL_PLANNING_SAGA,
  UPDATE_RANGE_DETAIL_PLANNING_REDUCER,
  GET_INFO_PLANNING_PAGING_BY_ID_SAGA,
  GET_INFO_PLANNING_PAGING_BY_ID_REDUCER,
  DELETE_RANGE_PLANNING_DETAIL_SAGA,
  DELETE_RANGE_PLANNING_DETAIL_REDUCER,
  PROPOSAL_SUMMARY_SAGA,
  PROPOSAL_SUMMARY_REDUCER,
  RECORD_NOT_FOUND_REDUCER,
  RESET_RECORD_NOT_FOUND_REDUCER
} from "./enum/TypePlanningAction";

const BudgetManagementAction = {
  getPagingSaga: (pageIndex, pageSize, listhospitalId = [], warehouseId= [], monthPlanning, listStatus = [], searchTerm = "", IsWhole = false, ListDataUser = [], startDateExport, endDateExport) => {
    return {
      type: GET_PAGING_PLANNING_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      listhospitalId: listhospitalId,
      warehouseId: warehouseId,
      monthPlanning: monthPlanning,
      listStatus: listStatus,
      searchTerm: searchTerm,
      isWhole: IsWhole,
      listDataUser : ListDataUser,
      startDateExport: startDateExport,
      endDateExport: endDateExport
    };
  },
  getPagingRetailPlanningSaga: (pageIndex, pageSize, listhospitalId = [], warehouseId, monthPlanning, listStatus = [], searchTerm = "", IsWhole = false) => {
    return {
      type: GET_PAGING_RETAIL_PLANNING_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      listhospitalId: listhospitalId,
      warehouseId: warehouseId,
      monthPlanning: monthPlanning,
      listStatus: listStatus,
      searchTerm: searchTerm,
      isWhole: IsWhole
    };
  },
  getPagingRetailPlanningReducer: (data) => {
    return {
      type: GET_PAGING_RETAIL_PLANNING_REDUCER,
      data: data,
    };
  },
  deleteDataSaga: (id) => {
    return {
      type: DELETE_PLANNING_SAGA,
      id: id,
    };
  },
  deleteDataReducer: () => {
    return {
      type: DELETE_PLANNING_REDUCER,
    };
  },

  //#region Dự trù kho lẻ

  //Paging chi tiết phiếu dự trù kho lẻ
  getPagingDetailByIdSaga: (pageIndex, pageSize, planningId, listDetailUpdate = []) => {
    return {
      type: GET_PAGING_DETAIL_BY_ID_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      planningId: planningId,
      listDetailUpdate : listDetailUpdate
    };
  },
  getPagingDetailByIdReducer: (data) => {
    return {
      type: GET_PAGING_DETAIL_BY_ID_REDUCER,
      data: data,
    };
  },
  // tính toán dự trù kho lẻ nếu không chọn vậy tư y tế
  PredictabilityRetailAutoSaga: (data) => {
    return {
      type: PREDICTABILITY_RETAIL_AUTO_SAGA,
      data: data,
    };
  },
  // tính toán dự trù kho lẻ nếu không chọn vậy tư y tế
  PredictabilityRetailAutoReducer: (data) => {
    return {
      type: PREDICTABILITY_RETAIL_AUTO_REDUCER,
      data: data
    };
  },
  // tính toán dự trù kho lẻ nếu chọn vậy tu y tế
  PredictabilityRetailByListProductSaga: (data) => {
    return {
      type: PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_SAGA,
      data: data
    };
  },
  // tính toán dự trù kho lẻ nếu chọn vậy tu y tế
  PredictabilityRetailByListProductReducer: (data) => {
    return {
      type: PREDICTABILITY_RETAIL_BY_LIST_PRODUCT_REDUCER,
      data: data
    };
  },

  //Đánh dấu bản ghi đã bị xóa => không hiển thị form chi tiết phiếu
  RecordNotFound: () => {
    return {
      type: RECORD_NOT_FOUND_REDUCER,
    };
  },
  //Đánh dấu bản ghi đã bị xóa => không hiển thị form chi tiết phiếu
  ResetRecordNotFound: () => {
    return {
      type: RESET_RECORD_NOT_FOUND_REDUCER,
    };
  },
  //#endregion

  //#region Dự trù toàn viện

  // tính toán dự trù toàn viện nếu không chọn vật tư y tế
  PredictabilityBudgetAutoSaga: (data) => {
    return {
      type: PREDICTABILITY_BUDGET_AUTO_SAGA,
      data: data
    };
  },

  // tính toán dự trù toàn viện nếu không chọn vật tư y tế
  PredictabilityBudgetAutoReducer: (data) => {
    return {
      type: PREDICTABILITY_BUDGET_AUTO_REDUCER,
      data: data
    };
  },

  // tính toán dự trù toàn viện nếu chọn vậy tu y tế
  PredictabilityBudgetByListProductSaga: (data) => {
    return {
      type: PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_SAGA,
      data: data
    };
  },

  // tính toán dự trù kho lẻ nếu chọn vậy tu y tế
  PredictabilityBudgetByListProductReducer: (data) => {
    return {
      type: PREDICTABILITY_BUDGET_BY_LIST_PRODUCT_REDUCER,
      data: data
    };
  },

  //Tổng hợp đề xuất kho lẻ 
  ProposalSummarySaga: (data) =>{
    return{
      type: PROPOSAL_SUMMARY_SAGA,
      data:data
    }
  },

  ProposalSummaryReducer: (data) =>{
    return{
      type: PROPOSAL_SUMMARY_REDUCER,
      data:data
    }
  },
  //#endregion
  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_PLANNING_REDUCER,
      data: data,
    };
  },
  createDataReducer: (data) => {
    return {
      type: CREATE_PLANNING_REDUCER,
      data: data,
    };
  },
  createDataSaga: (data) => {
    return {
      type: CREATE_PLANNING_SAGA,
      data: data,
    };
  },
  updateDataReducer: (data) => {
    return {
      type: UPDATE_PLANNING_REDUCER,
      data: data,
    };
  },
  updateDataSaga: (data) => {
    return {
      type: UPDATE_PLANNING_SAGA,
      data: data,
    };
  },
  updateRangeDetailSaga: (data) => {
    return {
      type: UPDATE_A_DETAIL_PLANNING_SAGA,
      data: data,
    };
  },
  updateRangeDetailReducer: (data) => {
    return {
      type: UPDATE_RANGE_DETAIL_PLANNING_REDUCER,
      data: data,
    };
  },
  getInfoPlanningReducer: (data) => {
    return {
      type: GET_INFO_PLANNING_BY_ID_REDUCER,
      data: data,
    };
  },
  getInfoPlanningSaga: (data) => {
    return {
      type: GET_INFO_PLANNING_BY_ID_SAGA,
      data: data,
    };
  },
  getInfoPlanningPagingReducer: (data) => {
    return {
      type: GET_INFO_PLANNING_PAGING_BY_ID_REDUCER,
      data: data,
    };
  },
  getInfoPlanningPagingSaga: (data) => {
    return {
      type: GET_INFO_PLANNING_PAGING_BY_ID_SAGA,
      data: data,
    };
  },

  resetInfoPlanningReducer: () => {
    return {
      type: RESET_INFO_PLANNING_BY_ID_REDUCER
    };
  },
  callDefaultReducer: () => {
    return {
      type: CALL_DEFAULT_REDUCER,
    };
  },
  clearDataReducer: () => {
    return {
      type: CLEAR_DATA_REDUCER,
    };
  },
  downloadExcelSaga: (data) => {
    return {
      type: PLANNING_DOWNLOAD_EXCEL_SAGA,
      data: data,
    };
  },
  deleteRangeDetailSaga: (data) => {
    return {
      type: DELETE_RANGE_PLANNING_DETAIL_SAGA,
      data: data,
    };
  },
  //Hàm xóa nhiều item của một phiếu dự trù
  deleteRangeDetailReducer: (data) => {
    return {
      type: DELETE_RANGE_PLANNING_DETAIL_REDUCER,
      data: data,
    };
  },
}

export default BudgetManagementAction;
