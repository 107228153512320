import CommonBase from "src/shared/common/base";
import configApp from "src/shared/configApp/configApp";
import {
  GET_PAGING_APPROVAL_API,
  GET_APPROVAL_DETAIL_API,
  APPROVE_REJECT_API,
  GET_WORKER_FROM_WORKAREA_API
} from "./enum/ApprovalTypeApi";


export async function getApprovalPagingService(data) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_PAGING_APPROVAL_API,
    data
  );
}

export async function getDetailApproval(id) {
  return await CommonBase.getAsync(
    configApp.apiGateWay + GET_APPROVAL_DETAIL_API + id,
    ''
  );
}

export async function approveRejectService(request) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + APPROVE_REJECT_API,
    request
  );
}

export async function getWorkerFromWorkArea(request) {
  return await CommonBase.postAsync(
    configApp.apiGateWay + GET_WORKER_FROM_WORKAREA_API + request.workareaId,
    request
  );
}