
import React, { createContext, useEffect, useState } from "react";
export const MenuContext = createContext(null);
export const MenuContextProvider = (props) => {
    const [navBarArray, setNavBarArray] = useState([]);
    const contextValue = { navBarArray, setNavBarArray };
    return (
        <MenuContext.Provider value={contextValue}>
            {props.children}
        </MenuContext.Provider>
    )
}
