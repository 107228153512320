export const timeGroupWorkDay = {
    language:'vn',
    table_group_day:{
        name:"Tên nhóm",
        color:"Màu sắc",
        status:"Bắt buộc chọn khoảng ngày"
    },
    placeholder_group:"Nhập tên nhóm ngày muốn tìm",
    form_group_day: {
        title_create: "Thêm mới nhóm ngày làm việc",
        title_edit: "Cập nhật nhóm ngày làm việc",
        title_delete: "Xóa nhóm ngày làm việc",
        text_confirm_delete: "Bạn có muốn xóa nhóm ngày làm việc này?",
    },
    mes_group_day: {
        required_name: "Tên không được để trống",
        maxLenght_name: "Tên không được dài quá 100 kí tự",
        maxLenght_sign: "Ký hiệu không dài quá 20 kí tự",
        required_sign: "Ký hiệu không được để trống",
        create_success: "Tạo mới thành công",
        update_success: "Sửa nhóm ngày thành công",
        delete_success: "Xóa nhóm ngày thành công",
    }
}