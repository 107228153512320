import { closeLoading } from "src/shared/common/LoadingAction";
import {GET_LOG_JOB_DATA_PAGINATION_SAGA} from "./enum/LogJobTypeAction";
import {call, put, takeEvery} from "redux-saga/effects";
import LogJobAction from "./LogJobAction";
import {getListLogJobDataPaginationService} from "./LogJobService";

export function* getLogJobDataPagination(body) {

    try {
    // debugger;
    const params = 
            {
                SearchTerm: body.params.SearchTerm ? body.params.SearchTerm : "",
                PageIndex: body.params.PageIndex ? body.params.PageIndex : 1,
                PageSize: body.params.PageSize ? body.params.PageSize : 20
            }

        const response = yield call(getListLogJobDataPaginationService, params);
        // if (!response.isSuccess && !response.Items) {
        //     yield put(LogManagementAction.getPaginationClear());
        //     yield call(closeLoading)
        //     closeLoading()
        //     return
        // }
        yield put(LogJobAction.getPaginationReducer(response));
    } catch (e) {
        console.log("getLogJobDataPagination", e)
    }

    yield put(LogJobAction.getPaginationClear());
    yield call(closeLoading)
}

export default function* LogJobSaga() {
    yield takeEvery(GET_LOG_JOB_DATA_PAGINATION_SAGA, getLogJobDataPagination);
}
