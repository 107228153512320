import {
  CALL_DEFAULT_PRODUCTION_REQUIRMENT_REDUCER,
  CREATE_PRODUCTION_REQUIRMENT_SAGA,
  DELETE_PRODUCTION_REQUIRMENT_SAGA,
  GET_DETAIL_PRODUCTION_REQUIRMENT_REDUCER,
  GET_PAGING_PRODUCTION_REQUIRMENT_REDUCER,
  GET_PAGING_PRODUCTION_REQUIRMENT_SAGA,
  UPDATE_PRODUCTION_REQUIRMENT_SAGA,
  DELETE_PRODUCTION_REQUIRMENT_REDUCER,
  GET_DETAIL_PRODUCTION_REQUIRMENT_SAGA,
  CALL_RESULT_PRODUCTION_REQUIRMENT_REDUCER,
  FORCE_POPUP_UPDATE_DATA,
  CLOSE_POPUP_DELETE,
} from "./enum/typeProductRequirementAction";

const ProductRequirmentAction = {
  getPagingSaga: (pageIndex, pageSize, searchTerm = "") => {
    return {
      type: GET_PAGING_PRODUCTION_REQUIRMENT_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      searchTerm: searchTerm,
    };
  },

  getPagingReducer: (data) => {
    return {
      type: GET_PAGING_PRODUCTION_REQUIRMENT_REDUCER,
      data: data,
    };
  },

  createDataSaga: (pageIndex, pageSize, data) => {
    return {
      type: CREATE_PRODUCTION_REQUIRMENT_SAGA,
      pageIndex: pageIndex,
      pageSize: pageSize,
      data: data,
    };
  },

  updateDataSaga: (request, pageIndex, pageSize) => {
    return {
      type: UPDATE_PRODUCTION_REQUIRMENT_SAGA,
      request: request,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
  },

  deleteDataSaga: (codeId, pageIndex, pageSize) => {
    return {
      type: DELETE_PRODUCTION_REQUIRMENT_SAGA,
      codeId: codeId,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
  },

  deleteDataReducer: () => {
    return {
      type: DELETE_PRODUCTION_REQUIRMENT_REDUCER,
    };
  },

  getDetailSaga: (codeId) => {
    return {
      type: GET_DETAIL_PRODUCTION_REQUIRMENT_SAGA,
      codeId: codeId,
    };
  },

  returnDetailReducer: (obj) => {
    return {
      type: GET_DETAIL_PRODUCTION_REQUIRMENT_REDUCER,
      obj: obj,
    };
  },

  callResultFromApiReducer: () => {
    return {
      type: CALL_RESULT_PRODUCTION_REQUIRMENT_REDUCER,
    };
  },

  callDefault: () => {
    return {
      type: CALL_DEFAULT_PRODUCTION_REQUIRMENT_REDUCER,
    };
  },

  setPopupUpdate: (status) => {
    return {
      type: FORCE_POPUP_UPDATE_DATA,
      status
    }
  },
  closePopupDelete: (status = false) => {
    return {
      type: CLOSE_POPUP_DELETE,
      status
    }
  }
};

export default ProductRequirmentAction;
