import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import loginAction from "../../../../redux-saga/action/Login/loginAction";
import ChangeLaguageComp from "./ChangeLaguageComp";
// import NavBarComp from "./Navbar";`
import Notification from "./Notification";
import { pageData } from "../sidebar/pageData";
import i18n from 'i18next';
import AvantarCom from "./AvantarCom";
import ImageComponent from "src/components/base/ControllerVer2/image/ImageComponent";
import { topbarIconByModule } from "./topbarData";
import "../../../../shared/scss/layout/topbar/topbar.scss";

function Topbar(props) {

  let currentPage = pageData.find(element => element.to == props.location.pathname);
  let ancestorPage = currentPage?.level == 0 ? currentPage.title : pageData.find(element => element.id == currentPage?.ancestor);

  const mapModuleNameToIcon = () => {
    switch (ancestorPage?.id) {
      case topbarIconByModule.trangchu.id:
        return topbarIconByModule.trangchu.icon;
      case topbarIconByModule.quanlytaisan.id: 
        return topbarIconByModule.quanlytaisan.icon;
      case topbarIconByModule.quantri.id:
        return topbarIconByModule.quantri.icon;
      default: 
        return topbarIconByModule.trangchu.icon;
    }
  }
  return (
    <>
      <>
        <style>{`@media print {.item-2{display: none;}}`}</style>
        <div className="w-100 d-flex topbar">
          <div className="left d-flex  py-1 w-25 ps-3">
            <ImageComponent className="ml-3" url={mapModuleNameToIcon()} />
            <h6 className="topbar-title-side d-none d-lg-block d-xl-block mb-0 ml-2 text-white ">
              {ancestorPage?.title || "Hệ thống quản lý kho vật tư y tế tiêu hao"}
            </h6>
          </div>

          <div className="right d-flex py-2 w-75 pe-4">
            {/* <Notification /> */}
            {/* <ChangeLaguageComp /> */}
            <AvantarCom/>
          </div>
        </div>
      </>
      {/* <NavBarComp path={props.sitePage} />s */}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  loginAction: bindActionCreators(loginAction, dispatch),
  // notificationAction: bindActionCreators(NotificationAction, dispatch),
});

const mapStateToProps = (state) => {
  return {
    redecersLogin: {
      authenticated: state.loginReducer.authenticated,
      userInfo: state.loginReducer.userInfo,
      listNotificaltion: state.NotificaionReducer.listNotificaltion,
    },
    sitePage: state.loginReducer.sitePage,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Topbar)));
