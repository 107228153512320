export const workAreaLag = {
    language: "en",
    workArea: {
        workTitle: "Work Area",
        table: {
            code: "Work area code",
            name: "Work area name",
            slocWorkAreaCode: "Work area store code",
            nameWorker: "Manager",
            note: "Note",
            active: "Active",
            action: "Action",
            dropdownStatus: {
                all: "All",
                active: "Active",
                nonActive: "Non Active",
            },
            noneDataTable: "No data to show",
        },
        search: {
            placeHolder: {
                code: "Work area code",
                name: "Work area name",
                slocWorkAreaCode: "Work area store code",
                nameWorker: "Manager",
                note: "Note",
            },
        },
        form: {
            add_new: "Create new work area",
            update: "Update work area",
            see_detail: "Work area information",
            code: "Work area code",
            name: "Work area name",
            nameWorker: "Manager",
            note: "Note",
            status: "Active"
        },
        validate: {
            name: {
                required: "Work area name cannot be empty",
                maxLength: "Work area name should not exceed 100 characters",
            },
            code: {
                type: "Work area code cannot contains special characters or spaces",
                text: "Work area code is up to 9 characters long UPPER, only numbers and letters, no spaces",
                required: "Work area code cannot be empty",
                maxLength: "Work area code should not exceed 9 characters",
            },
            note: {
                maxLength: "Work area node should not exceed 255 characters",
            },
        },
        delete: {
            title: "Delete work area",
            description: "Are you sure you want to delete this work area?",
        },
        btn: {
            add_new: "Create new",
            close: "Close",
            cancel: "Cancel",
            add: "Create new",
            update: "Update"
        },
        toastjs: {
            createSuccess: "Create Work Area successfully",
            editSuccess: "Update Work Area successfully",
            deleteSuccess: "Delete Work Area successfully",
            codeRepeat: "Work Area code has been duplicated, can't get this Work Area code",
            codeCharacters: "Work Area code maximum length 9 characters UPPER, only numbers and letters, no spaces"
        },
        confirm: {
            createSuccess: "Successfully created new work area",
            updateSuccess: "Work area update successful",
            deleteSuccess: "Successfully deleted work area",
            //comfirmText: "Version đang được sử dụng, không được xóa",
            comfirmTextDeleted: "The work area already has linked data, which cannot be deleted",
        }
    },
};