import {
    GET_PAGING_PLANNING_DETAIL_SAGA,
    GET_PAGING_PLANNING_DETAIL_REDUCER,
    CALL_DEFAULT_REDUCER,
    CLEAR_DATA_REDUCER,SET_LIST_RETAIL_PLANNING_DETAIL_REDUCER
} from "./enum/TypePlanningDetailAction";

const PlanningDetailAction = {
    getPagingSaga: (pageIndex, pageSize, searchTerm = "",planningId, typeOverLimit, hospitalId, warehouseId) => {
        return {
            type: GET_PAGING_PLANNING_DETAIL_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
            planningId:planningId,
            typeOverLimit:typeOverLimit,
            hospitalId:hospitalId,
            warehouseId: warehouseId
        };
    },
    
    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_PLANNING_DETAIL_REDUCER,
            data: data,
        };
    },
    callDefaultReducer: () => {
        return {
            type: CALL_DEFAULT_REDUCER,
        };
    },
    clearDataReducer: () => {
        return {
            type: CLEAR_DATA_REDUCER,
        };
    },

}

export default PlanningDetailAction;
