
//Lay danh sach bao cao kiem ke
export const GET_LIST_REPORT = "GET_LIST_REPORT";
export const SET_LIST_REPORT = "SET_LIST_REPORT";
//Lay thong tin phieu
export const GET_BILL_INFO = "GET_BILL_INFO";
export const SET_BILL_INFO = "SET_BILL_INFO";
//Lay thong tin bao cao
export const GET_REPORT_INFO = "GET_REPORT_INFO";
export const SET_REPORT_INFO = "SET_REPORT_INFO";
//Lay thong tin tai san
export const GET_ASSET_INFO = "GET_ASSET_INFO";
export const SET_ASSET_INFO = "SET_ASSET_INFO";
// Clear data reducer
export const CLEAR_DATA_REDUCER = "CLEAR_DATA_REDUCER";
// get thong tin tab 3
export const GET_RESULT_REPORT = "GET_RESULT_REPORT";
export const SET_RESULT_REPORT = "SET_RESULT_REPORT";
export const GET_SUMMARY_REPORT = "GET_SUMMARY_REPORT";
export const SET_SUMMARY_REPORT = "SET_SUMMARY_REPORT";

// export data report

export const EXPORT_DATA_INVENTORY_REPORT_BILL = "EXPORT_DATA_INVENTORY_REPORT_BILL";
export const EXPORT_DATA_INVENTORY_REPORT_INFO = "EXPORT_DATA_INVENTORY_REPORT_INFO";
export const EXPORT_DATA_INVENTORY_REPORT_ASSETMENT = "EXPORT_DATA_INVENTORY_REPORT_ASSETMENT";
