import {
    GET_PAGING_APPROVE_STEP_SAGA,
    GET_PAGING_APPROVE_STEP_REDUCER,
    CREATE_APPROVE_SAGA,
    UPDATE_APPROVE_SAGA,
    CALL_CREATE_OR_UPDATE,
    DELETE_APPROVE_SAGA,
    CALL_DELETE_APPROVE_SAGA,
    GET_DETAIL_APPROVE_DEFAULT,
    SET_DETAIL_APPROVE_DEFAULT,
    CREATE_APPROVAL_SAGA,
    CREATE_APPROVAL_REDUCER,
    UPDATE_ID_ROW
} from "./enum/ApproveStepTypeAction";

const ApproveStepAction = {
    getPagingSaga: (pageIndex, pageSize, searchTerm = '') => {
        return {
            type: GET_PAGING_APPROVE_STEP_SAGA,
            pageIndex: pageIndex,
            pageSize: pageSize,
            searchTerm: searchTerm,
        }
    },
    getPagingReducer: (data) => {
        return {
            type: GET_PAGING_APPROVE_STEP_REDUCER,
            data: data,
        }
    },
    createApproveDefaultSaga: (data) => {
        return {
            type: CREATE_APPROVE_SAGA,
            data: data,
        }
    },
    updateApproveDefaultSaga: (data) => {
        return {
            type: UPDATE_APPROVE_SAGA,
            data: data,
        }
    },
    callCreateOrUpdateReducer: () => {
        return {
            type: CALL_CREATE_OR_UPDATE,
        }
    },
    deleteApproveDefaultSaga: (Id) => {
        return {
            type: DELETE_APPROVE_SAGA,
            Id: Id,
        }
    },
    callDeleteDefaultReducer: () => {
        return {
            type: CALL_DELETE_APPROVE_SAGA,
        }
    },
    getDetailApproveDefault: (id) => {
        return {
            type: GET_DETAIL_APPROVE_DEFAULT,
            id: id,
        }
    },
    setDetailApproveDefault: (data) => {
        return {
            type: SET_DETAIL_APPROVE_DEFAULT,
            data: data,
        }
    },
    createApprovalSaga: (req) => {
        return {
            type: CREATE_APPROVAL_SAGA,
            req: req,
        }
    },
    createApprovalReducer: () => {
        return {
            type: CREATE_APPROVAL_REDUCER,
        }
    },
    default: () => {
        return {
            type: 'default',
        }
    },
    clearDataReducer: () => {
        return {
            type: 'CLEAR_DATA_REDUCER',
        }
    },
    updateIdRowReducer: (id = "") => {
        return {
            type: UPDATE_ID_ROW,
            id,
        }
    }
}

export default ApproveStepAction;