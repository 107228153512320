import { menuTranslation } from "./en/menu-translation";
// import { worker } from "./en/Worker";
import { base } from "./en/Base";
import { baseExampleLag } from "./en/baseExampleLag";
import { ApproveStepLaguage } from "src/shared/constants/locales/en/MMProduction/ApproveStep";
import { navbarTime } from "./en/MMProduction/TimeManager/navbarTime";
import { calendarManager } from "./en/MMProduction/TimeManager/CalendarManager";
import { sopLag } from "./en/MMProduction/SOP/sopLag";
import { BomLag } from 'src/shared/constants/locales/en/MMProduction/BOM/BomLag'
import { requirementLag } from './en/MMProduction/ProductionRequirement/requirementLag'
import { WorkareaLinkProccessLag } from './en/MMProduction/WorkareaLinkProccess/WorkareaLinkProccessLag';
import { RescheduleProductionEN } from './en/MMProduction/RescheduleProduction/RescheduleProductionEN';
import { Machine } from './en/MaterialManager/Machine/MachineEN';
import { approval } from './en/MMProduction/Approval/approval';
import { timeScheduleManager } from "./en/MMProduction/TimeManager/TimeScheduleManager";
import { timeShiftManager } from "./en/MMProduction/TimeManager/TimeShiftManager";
import { timeManagerType } from "./en/MMProduction/TimeManager/TimeManagerType";
import { timeGroupWorkDay } from "./en/MMProduction/TimeManager/TimeGroupWorkDay";
import { workAreaLag } from "./en/MMProduction/ProductionManagement/WorkAreaLag";
import { workCenterLag } from "./en/MMProduction/ProductionManagement/WorkCenterLag";
import { workUnitLag } from "./en/MMProduction/ProductionManagement/WorkUnitLag";
import { sidebar } from "./en/layout/sidebar";
import { packingLevelLag } from "./en/MMProduction/ProductManager/Pack/packingLevelLag";
import { packingProcessLag } from "./en/MMProduction/ProductManager/Pack/packingProcessLag";
import { reprintStampsLag } from "./en/MMProduction/ProductManager/Pack/ReprintStampsLag";
import { cancelStampLag } from "./en/MMProduction/ProductManager/Pack/cancelStampLag";
import { ProductPackagingLag } from "./en/MMProduction/ProductManager/Pack/productPackagingLag";
import { ProcessLag } from "./en/MMProduction/Process/ProcessLag";
import { ProductIdentifier } from "./en/MMProduction/ProductIdentifier/ProductIdentifier";
import { LotQA } from "./en/QuanlityManager/LotQA";
import { checkListLg } from "./en/QuanlityManager/checklist";
import { recordResultLag } from "./en/QuanlityManager/RecordResult";
import { checkpointlg } from "./en/QuanlityManager/checkpoint";
import { repairLag } from "./en/QuanlityManager/repair";
import { productionCoordinator } from "./en/MMProduction/productionCoordinator/productionCoordinator";
import { recordResult } from "./en/MMProduction/resultRecord";
import { ProductionLineLaguage } from "./en/MMProduction/ProductionLine/ProductionLine";
import { PeriodLag } from "./en/Maintaince/PeriodLag";
import { machineryRepairLag } from "./en/QuanlityManager/machineryRepairLag";
import { skillLag } from "./en/MMProduction/Worker/SkillLag";
import { worker } from "./en/MMProduction/Worker/Worker";
import { LocationLag } from "./en/MaterialManager/Location/LocationEN"
import { NCCInformation } from "./en/MaterialManager/NCCInformation/NCCInformationEN";
import { UnitEN } from "./en/MaterialManager/Unit/UnitEN";
import { PackagetypeManager } from "./en/MaterialManager/PackagetypeManager/PackageTypeManager";
import { PackageInforLag } from "./en/MaterialManager/PackageInfor/PackageInforLag";
import { accessoriesLag } from "./en/MaterialManager/AccessoriesManager/AccessoriesLag";
import { poLag } from "./en/MaterialManager/PO/poLag";
import { movementType } from "./en/MaterialManager/MovementType/MovementTypeEN";
import { Standardpacking } from "./en/MaterialManager/Standardpacking/StandardpackingEN";
import { accessoriesTypeLag } from "./en/MaterialManager/AccessoriesManager/AccessoriesTypeLag";
import { UnitCoversionLag } from "./en/MaterialManager/AccessoriesManager/UnitConversionLag";
import { tranferSloc } from "./en/MaterialManager/TranferSloc/TranferSloc";
import { StoreLocationScreen } from "./en/MaterialManager/StoraLocationManager/StoraLocationScreen";
import { RequestFormManager } from "./en/MaterialManager/RequestFormManager/RequestFormManagerEN";
import { TakenPackageTicketEN } from "./en/MaterialManager/TakenPackageTicket/TakenPackageTicketEN";
import { takenPackagePageLag } from "./en/MaterialManager/TakenPackagePage/TakenPackagePageLag";
import { Productmm } from "./en/MMProduction/ProductManager/Product/ProductEN";
import { Modelmm } from "./en/MMProduction/ProductManager/Model/ModelEN";
import { ItemAllocation } from "./en/MMProduction/ItemAllocation/ItemAllocation";
import { PetitionerManager } from "./en/MaterialManager/PetitionerManager/PetitionerManagerEN";
import { MaintenanceScheduleLag } from "./en/Maintaince/MaintenanceScheduleLag";
import { inventoryCheckLag } from "./en/MaterialManager/InventoryCheck/InventoryCheckLag";
import { approvalList } from "./en/Administration/ApprovalsList/approvalListEN";
import { ApprovalConfig } from "./en/Administration/ApprovalSetting/ApprovalConfig/ApprovalConfig";
import { ApprovalProcess } from "./en/Administration/ApprovalSetting/ApprovalProcess/ApprovalProcess";
export const translationEN = Object.assign(
  {},
  menuTranslation,
  worker,
  base,
  baseExampleLag,
  sopLag,
  ApproveStepLaguage,
  BomLag,
  requirementLag,
  navbarTime,
  calendarManager,
  WorkareaLinkProccessLag,
  RescheduleProductionEN,
  Machine,
  workAreaLag,
  workCenterLag,
  workUnitLag,
  approval,
  timeScheduleManager,
  timeShiftManager,
  timeManagerType,
  timeGroupWorkDay,
  sidebar,
  packingLevelLag,
  packingProcessLag,
  reprintStampsLag,
  cancelStampLag,
  ProductPackagingLag,
  ProcessLag,
  ProductIdentifier,
  LotQA,
  checkListLg,
  recordResultLag,
  checkpointlg,
  repairLag,
  productionCoordinator,
  recordResult,
  ProductionLineLaguage,
  PeriodLag,
  machineryRepairLag,
  skillLag,
  NCCInformation,
  LocationLag,
  UnitEN,
  PackagetypeManager,
  PackageInforLag,
  accessoriesLag,
  poLag,
  movementType,
  Standardpacking,
  accessoriesTypeLag,
  UnitCoversionLag,
  tranferSloc,
  StoreLocationScreen,
  RequestFormManager,
  TakenPackageTicketEN,
  takenPackagePageLag,
  Productmm,
  Modelmm,
  ItemAllocation,
  PetitionerManager,
  MaintenanceScheduleLag,
  inventoryCheckLag,
  approvalList,
  ApprovalConfig,
  ApprovalProcess,
);
