export const skillLag = {
    language: "vn",
    skill: {
        table: {
            name: "Tên",
            action: "Thao tác",
        },
        search: {
            placeHolder: "Tìm kiếm kỹ năng",
        },
        form: {
            addNew: "Thêm mới kỹ năng",
            update: "Cập nhật kỹ năng",
            name: "Tên chuyên ngành/ kỹ năng",
            placeHolder: "Nhập tên chuyên ngành/ kỹ năng",
        },
        delete: {
            title: "Xoá kỹ năng",
            description: "Bạn có chắc chắn muốn xóa kỹ năng này?",
        },
        btn: {
            add: "Thêm mới",
            addNew: "Thêm",
            save: "Lưu",
            cancel: "Huỷ",
            delete: "Xoá",
            back: "Quay lại"
        },
        error: {
            required: "Tên chuyên ngành/ kỹ năng không được bỏ trống",
            maxLength: "Tên chuyên ngành/ kỹ năng không được quá 255 kí tự",
            Name_exist: "Tên chuyên ngành/ kỹ năng đã tồn tại"
        },
        toastJS: {
            creatSuccess: "Tạo mới kỹ năng thành công",
            updateSuccess: "Cập nhật thông tin kỹ năng thành công",
            deleteSuccess: "Xoá thành công kỹ năng",
        }
    }
}