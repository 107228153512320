import {
    CALL_DEFAULT_ITEM_ALLOCATION_REDUCER, CALL_RESULT_ITEM_ALLOCATION_REDUCER,
    GET_ITEMCODE_BY_REDUCER, GET_PAGING_ITEM_ALLOCATION_REDUCER
} from "./enum/ItemAllocationTypeAction";


// public string Workordercode { get; set; }
// public int? Status { get; set; }
// public DateTime? Dateprint { get; set; }
// public int? Qty { get; set; }
// public string Itemcode { get; set; }


const ItemAllocationReducer = (
    state = {
        listData: [],
        dataItemCode: {

        },

        isFetching: false,
        isFetchingMore: false,
        callResultFromApi: false,
        callResultFromDetail: false,
        changeData: false,
        is_call_get_item_code: false
    },
    action
) => {
    state.is_call_get_item_code = false;
    switch (action.type) {
        case GET_PAGING_ITEM_ALLOCATION_REDUCER:
            if (action.data) {
                state.listData = action.data?.Data;
            }
            return {
                ...state
            };
        case CALL_RESULT_ITEM_ALLOCATION_REDUCER:
            state.listData = null;
            state.dataItemCode = null;
            return {
                ...state
            };

        case GET_ITEMCODE_BY_REDUCER:
            state.dataItemCode = action.response;
            state.is_call_get_item_code = true;
            return {
                ...state
            };
        case CALL_DEFAULT_ITEM_ALLOCATION_REDUCER:
            state.is_call_get_item_code = false;
            return {
                ...state,
            }
        default:
            return {
                ...state
            };
    }
}
export default ItemAllocationReducer;
