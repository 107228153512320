export const machineryRepairLag = {
    language: "en",
    machineryRepair: {
        table: {
            machineCode: "Machine code",
            declarationDate: "Repair report date",
            acceptanceDay: "Date of acceptance",
            declarantWorkerid: "Declarant",
            acceptanceWorkerid: "Acceptance person",
            costs: "Cost (VND)",
            action: "Action",
            noData: "No data to display",
        },
        placeholder: {
            searchTerm: "Search",
            status: "Status",
            typeError: "Classify",
            timeSearch: "Error recording time",
        },
        btn: {
            create: "Create new",
            save: "Save",
        },
        deleteForm: {
            title: "Delete machine repair",
            confirm: "Are you sure you want to delete this repair machine?",
        },
        form: {
            createTitle: "Create new repair information",
            editTitle: "Change repair information",
            seeDetailTitle: "Details of repair information",
            machineCode: "Machine code",
            machineName: "Machine name",
            workerId: "Declarant",
            declarationDate: "Declaration date",
            repairReson: "Repair reason",
            placeholder: {
                machineCode: "Select the machine code",
                machineName: "Machine name",
                workerId: "Choose a reporter",
                declarationDate: "Time",
                repairReson: "Enter the reason for the repair",
            }
        },
        acceptanceForm: {
            title: "Acceptance and repair",
            machineCode: "Machine code:",
            machineName: "Machine name:",
            workerId: "Declarant:",
            declarationDate: "Declaration date:",
            repairReson: "Repair reason:",
            infoTitle: "Information about acceptance",
            acceptanceWorkerid: "Acceptance person",
            acceptanceDay: "Date of acceptance",
            acceptanceVerification: "Confirm",
            dropdownStatusRepair: "Machine status after repair",
            cost: "Cost (VND)",
            placeholder: {
                acceptanceWorkerid: "Choose a tester",
                acceptanceDay: "Time",
                acceptanceVerification: "Enter acceptance confirmation",
                dropdownStatusRepair: "Select machine status after repair",
            }
        },
        toast: {
            createSuccess: "Create new repair machines successfully",
            warnningCreate: "Unlinked machines with production units",
            updateSuccess: "Updating repairing machines successfully",
            acceptanceSuccess: "Acceptance and repair of machines successfully",
            deleteSuccess: "Delete and repair machinery successfully",
        },
        rule: {
            MachineId: {
                required: "The machine code cannot be left blank",
            },
            WorkerId: {
                required: "Declarant cannot be left blank",
            },
            DeclarationDate: {
                required: "Declaration date cannot be left blank",
            },
            RepairReason: {
                maxLength: "The reason for correction must not exceed 255 characters"
            },
            acceptanceVerification: {
                maxLength: "Confirmation must not exceed 255 characters"
            },
            acceptanceWorkerid: {
                required: "Acceptance testers cannot be left blank",
            },
            acceptanceDay: {
                required: "Acceptance date cannot be left blank",
            },
            machineStateAfterRepair: {
                required: "Machine status after repair cannot be left blank",
            },
            costs: {
                numberOnly: "Cost must be numeric",
                numberBiggerZero: "Cost must be numeric",
                required: "Expense cannot be left blank",
                maxLength: "The cost cannot exceed 10 characters"
            },
        }
    },
}
